import { useTranslation } from 'react-i18next'

import example1 from '../../assets/examples/electricalMeter1.png'
import example2 from '../../assets/examples/electricalMeter2.png'
import { MobileUploadContent } from '../../components/upload/MobileUploadContent'
import { MobileUploadLoader } from '../../components/upload/MobileUploadLoader'
import { MobileUploadSuccess } from '../../components/upload/MobileUploadSuccess'
import { useImagePolling } from '../../hooks/useImagePolling'
import { useMultiImageUpload } from '../../hooks/useMultiImageUpload'
import { useOnboarding } from '../../hooks/useOnboarding'

import { TOTAL_STEPS } from './ExtraImagesStep'

const CURRENT_STEP = 3
const examples = [example1, example2]

export const ElectricityMeterImageStep = () => {
  // -- Hooks --
  const { values, handleNext } = useOnboarding()
  const { t } = useTranslation()
  useImagePolling()

  const { loading, handleAddImage, handleRemoveImage } = useMultiImageUpload(
    'electricityMeter',
    'electricityMeterImageIds'
  )

  // -- Handlers --
  const handleSubmit = () => {
    handleNext(values)
  }

  // -- Render --
  if (loading) {
    return (
      <MobileUploadLoader currentStep={CURRENT_STEP} totalSteps={TOTAL_STEPS} />
    )
  }

  if (values.electricityMeterImageIds?.length) {
    return (
      <MobileUploadSuccess
        imageIds={values.electricityMeterImageIds}
        currentStep={CURRENT_STEP}
        totalSteps={TOTAL_STEPS}
        title={t('onboarding.electricityMeter-image.title')}
        onSubmit={handleSubmit}
        onDelete={handleRemoveImage}
        onAddImage={
          values.electricityMeterImageIds.length >= 5
            ? undefined
            : handleAddImage
        }
      />
    )
  }

  return (
    <MobileUploadContent
      uploadPicture={handleAddImage}
      title={t('onboarding.electricityMeter-image.title')}
      description={t('onboarding.electricityMeter-image.description')}
      currentStep={CURRENT_STEP}
      totalSteps={TOTAL_STEPS}
      examples={examples}
    />
  )
}
