import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { Dialog } from '../../../components/general/Dialog'
import { BodyMediumRegular } from '../../../components/typography'

import type { UserWithContractAndCards } from 'types'

type DeleteEmployeeDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  handleDelete: () => void
  employee: UserWithContractAndCards
}

export const DeleteEmployeeDialog = ({
  open,
  setOpen,
  handleDelete,
  employee,
}: DeleteEmployeeDialogProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()

  // -- Vars --
  const blockDeletion = ![
    'Inapplicable',
    'Registered',
    'Invited',
    'Onboarding',
    'OnboardingRejected',
    'OnboardingCompleted',
    'QuotationPendingApproval',
    'QuotationApprovedByEmployer',
    'QuotationApprovedByEmployee',
  ].includes(employee.hcpStatus.status)

  const softDeleteEmployee = employee.members.some(
    (member) => member.mspStatus === 'Active'
  )

  // Descriptions
  const description1 = blockDeletion
    ? t('admin.employee-detail.dialog.block-delete-description1')
    : softDeleteEmployee
    ? t('admin.employee-detail.dialog.soft-delete-description1')
    : t('admin.employee-detail.dialog.hard-delete-description1')

  const description2 =
    !blockDeletion &&
    !softDeleteEmployee &&
    t('admin.employee-detail.dialog.hard-delete-description2')

  // -- Handlers --
  const closeDialog = () => {
    setOpen(false)
    trackEvent(EventType.Close, 'close_delete_member_modal')
  }

  // -- Render --
  return (
    <Dialog
      open={open}
      onClickCloseButton={() => setOpen(false)}
      title={
        blockDeletion
          ? t('admin.employee-detail.dialog.delete-title')
          : t('admin.employee-detail.dialog.title')
      }
      description={description1}
      primaryButtonText={
        blockDeletion
          ? t('admin.employee-detail.dialog.close')
          : t('admin.employee-detail.dialog.confirm')
      }
      secondaryButtonText={
        blockDeletion ? undefined : t('admin.employee-detail.dialog.cancel')
      }
      onClickPrimaryButton={blockDeletion ? closeDialog : handleDelete}
      onClickSecondaryButton={blockDeletion ? undefined : closeDialog}
    >
      <StDescription>{description2}</StDescription>
    </Dialog>
  )
}

const StDescription = styled(BodyMediumRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};
`
