import type { CountryData } from 'react-international-phone'

// Country model:
// [
//    Country name,
//    iso2 code,
//    International dial code,
//    Format (if available),
//    Order priority (if >1 country with same dial code),
//    Area codes (if >1 country with same dial code)
// ]

export const frenchCountries: CountryData[] = [
  ['Afghanistan', 'af', '93'],
  ['Albanie', 'al', '355'],
  ['Algérie', 'dz', '213'],
  ['Andorre', 'ad', '376'],
  ['Angola', 'ao', '244'],
  ['Antigua-et-Barbuda', 'ag', '1268'],
  ['Argentine', 'ar', '54', '(..) ........', 0],
  ['Arménie', 'am', '374', '.. ......'],
  ['Aruba', 'aw', '297'],
  [
    'Australie',
    'au',
    '61',
    {
      default: '. .... ....',
      '/^4/': '... ... ...',
      '/^5(?!50)/': '... ... ...',
      '/^1(3|8)00/': '.... ... ...',
      '/^13/': '.. .. ..',
      '/^180/': '... ....',
    },
    0,
    [],
  ],
  ['Autriche', 'at', '43'],
  ['Azerbaïdjan', 'az', '994', '(..) ... .. ..'],
  ['Bahamas', 'bs', '1242'],
  ['Bahreïn', 'bh', '973'],
  ['Bangladesh', 'bd', '880'],
  ['Barbade', 'bb', '1246'],
  ['Biélorussie', 'by', '375', '(..) ... .. ..'],
  ['Belgique', 'be', '32', '... .. .. ..'],
  ['Belize', 'bz', '501'],
  ['Bénin', 'bj', '229'],
  ['Bhoutan', 'bt', '975'],
  ['Bolivie', 'bo', '591'],
  ['Bosnie-Herzégovine', 'ba', '387'],
  ['Botswana', 'bw', '267'],
  ['Brésil', 'br', '55', '(..) .........'],
  ['Territoire britannique de l’océan Indien', 'io', '246'],
  ['Brunéi', 'bn', '673'],
  ['Bulgarie', 'bg', '359'],
  ['Burkina Faso', 'bf', '226'],
  ['Burundi', 'bi', '257'],
  ['Cambodge', 'kh', '855'],
  ['Cameroun', 'cm', '237'],
  [
    'Canada',
    'ca',
    '1',
    '(...) ...-....',
    1,
    [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  ],
  ['Cap-Vert', 'cv', '238'],
  ['Pays-Bas caribéens', 'bq', '599', '', 1],
  ['Îles Caïmans', 'ky', '1', '... ... ....', 4, ['345']],
  ['République centrafricaine', 'cf', '236'],
  ['Tchad', 'td', '235'],
  ['Chili', 'cl', '56'],
  ['Chine', 'cn', '86', '... .... ....'],
  ['Colombie', 'co', '57', '... ... ....'],
  ['Comores', 'km', '269'],
  ['République démocratique du Congo', 'cd', '243'],
  ['République du Congo', 'cg', '242'],
  ['Costa Rica', 'cr', '506', '....-....'],
  ["Côte d'Ivoire", 'ci', '225', '.. .. .. .. ..'],
  ['Croatie', 'hr', '385'],
  ['Cuba', 'cu', '53'],
  ['Curaçao', 'cw', '599', '', 0],
  ['Chypre', 'cy', '357', '.. ......'],
  ['République tchèque', 'cz', '420', '... ... ...'],
  ['Danemark', 'dk', '45', '.. .. .. ..'],
  ['Djibouti', 'dj', '253'],
  ['Dominique', 'dm', '1767'],
  [
    'République dominicaine',
    'do',
    '1',
    '(...) ...-....',
    2,
    ['809', '829', '849'],
  ],
  ['Équateur', 'ec', '593'],
  ['Égypte', 'eg', '20'],
  ['El Salvador', 'sv', '503', '....-....'],
  ['Guinée équatoriale', 'gq', '240'],
  ['Érythrée', 'er', '291'],
  ['Estonie', 'ee', '372', '.... ......'],
  ['Éthiopie', 'et', '251'],
  ['Fidji', 'fj', '679'],
  ['Finlande', 'fi', '358', '.. ... .. ..'],
  ['France', 'fr', '33', '. .. .. .. ..'],
  ['Guyane française', 'gf', '594'],
  ['Polynésie française', 'pf', '689'],
  ['Gabon', 'ga', '241'],
  ['Gambie', 'gm', '220'],
  ['Géorgie', 'ge', '995'],
  ['Allemagne', 'de', '49', '... .........'],
  ['Ghana', 'gh', '233'],
  ['Grèce', 'gr', '30'],
  ['Groenland', 'gl', '299', '.. .. ..'],
  ['Grenade', 'gd', '1473'],
  ['Guadeloupe', 'gp', '590', '', 0],
  ['Guam', 'gu', '1671'],
  ['Guatemala', 'gt', '502', '....-....'],
  ['Guinée', 'gn', '224'],
  ['Guinée-Bissau', 'gw', '245'],
  ['Guyana', 'gy', '592'],
  ['Haïti', 'ht', '509', '....-....'],
  ['Honduras', 'hn', '504'],
  ['Hong Kong', 'hk', '852', '.... ....'],
  ['Hongrie', 'hu', '36'],
  ['Islande', 'is', '354', '... ....'],
  ['Inde', 'in', '91', '.....-.....'],
  ['Indonésie', 'id', '62'],
  ['Iran', 'ir', '98', '... ... ....'],
  ['Irak', 'iq', '964'],
  ['Irlande', 'ie', '353', '.. .......'],
  ['Israël', 'il', '972', '... ... ....'],
  ['Italie', 'it', '39', '... .......', 0],
  ['Jamaïque', 'jm', '1876'],
  ['Japon', 'jp', '81', '.. .... ....'],
  ['Jordanie', 'jo', '962'],
  ['Kazakhstan', 'kz', '7', '... ...-..-..', 0],
  ['Kenya', 'ke', '254'],
  ['Kiribati', 'ki', '686'],
  ['Kosovo', 'xk', '383'],
  ['Koweït', 'kw', '965'],
  ['Kirghizistan', 'kg', '996', '... ... ...'],
  ['Laos', 'la', '856'],
  ['Lettonie', 'lv', '371', '.. ... ...'],
  ['Liban', 'lb', '961'],
  ['Lesotho', 'ls', '266'],
  ['Libéria', 'lr', '231'],
  ['Libye', 'ly', '218'],
  ['Liechtenstein', 'li', '423'],
  ['Lituanie', 'lt', '370'],
  ['Luxembourg', 'lu', '352'],
  ['Macao', 'mo', '853'],
  ['Macédoine du Nord', 'mk', '389'],
  ['Madagascar', 'mg', '261'],
  ['Malawi', 'mw', '265'],
  ['Malaisie', 'my', '60', '..-....-....'],
  ['Maldives', 'mv', '960'],
  ['Mali', 'ml', '223'],
  ['Malte', 'mt', '356'],
  ['Îles Marshall', 'mh', '692'],
  ['Martinique', 'mq', '596'],
  ['Mauritanie', 'mr', '222'],
  ['Maurice', 'mu', '230'],
  ['Mexique', 'mx', '52', '... ... ....', 0],
  ['Micronésie', 'fm', '691'],
  ['Moldavie', 'md', '373', '(..) ..-..-..'],
  ['Monaco', 'mc', '377'],
  ['Mongolie', 'mn', '976'],
  ['Monténégro', 'me', '382'],
  ['Maroc', 'ma', '212'],
  ['Mozambique', 'mz', '258'],
  ['Myanmar (Birmanie)', 'mm', '95'],
  ['Namibie', 'na', '264'],
  ['Nauru', 'nr', '674'],
  ['Népal', 'np', '977'],
  [
    'Pays-Bas',
    'nl',
    '31',
    {
      '/^06/': '(.). .........',
      '/^6/': '. .........',
      '/^0(10|13|14|15|20|23|24|26|30|33|35|36|38|40|43|44|45|46|50|53|55|58|70|71|72|73|74|75|76|77|78|79|82|84|85|87|88|91)/':
        '(.).. ........',
      '/^(10|13|14|15|20|23|24|26|30|33|35|36|38|40|43|44|45|46|50|53|55|58|70|71|72|73|74|75|76|77|78|79|82|84|85|87|88|91)/':
        '.. ........',
      '/^0/': '(.)... .......',
      default: '... .......',
    },
  ],
  ['Nouvelle-Calédonie', 'nc', '687'],
  ['Nouvelle-Zélande', 'nz', '64', '...-...-....'],
  ['Nicaragua', 'ni', '505'],
  ['Niger', 'ne', '227'],
  ['Nigeria', 'ng', '234'],
  ['Corée du Nord', 'kp', '850'],
  ['Norvège', 'no', '47', '... .. ...'],
  ['Oman', 'om', '968'],
  ['Pakistan', 'pk', '92', '...-.......'],
  ['Palaos', 'pw', '680'],
  ['Palestine', 'ps', '970'],
  ['Panama', 'pa', '507'],
  ['Papouasie-Nouvelle-Guinée', 'pg', '675'],
  ['Paraguay', 'py', '595'],
  ['Pérou', 'pe', '51'],
  ['Philippines', 'ph', '63', '.... .......'],
  ['Pologne', 'pl', '48', '...-...-...'],
  ['Portugal', 'pt', '351'],
  ['Porto Rico', 'pr', '1', '(...) ...-....', 3, ['787', '939']],
  ['Qatar', 'qa', '974'],
  ['Réunion', 're', '262'],
  ['Roumanie', 'ro', '40'],
  ['Russie', 'ru', '7', '(...) ...-..-..', 1],
  ['Rwanda', 'rw', '250'],
  ['Saint-Christophe-et-Niévès', 'kn', '1869'],
  ['Sainte-Lucie', 'lc', '1758'],
  ['Saint-Vincent-et-les-Grenadines', 'vc', '1784'],
  ['Samoa', 'ws', '685'],
  ['Saint-Marin', 'sm', '378'],
  ['Sao Tomé-et-Principe', 'st', '239'],
  ['Arabie saoudite', 'sa', '966'],
  ['Sénégal', 'sn', '221'],
  ['Serbie', 'rs', '381'],
  ['Seychelles', 'sc', '248'],
  ['Sierra Leone', 'sl', '232'],
  ['Singapour', 'sg', '65', '....-....'],
  ['Slovaquie', 'sk', '421'],
  ['Slovénie', 'si', '386'],
  ['Îles Salomon', 'sb', '677'],
  ['Somalie', 'so', '252'],
  ['Afrique du Sud', 'za', '27'],
  ['Corée du Sud', 'kr', '82', '... .... ....'],
  ['Soudan du Sud', 'ss', '211'],
  ['Espagne', 'es', '34', '... ... ...'],
  ['Sri Lanka', 'lk', '94'],
  ['Soudan', 'sd', '249'],
  ['Suriname', 'sr', '597'],
  ['Eswatini', 'sz', '268'],
  ['Suède', 'se', '46', '... ... ...'],
  ['Suisse', 'ch', '41', '.. ... .. ..'],
  ['Syrie', 'sy', '963'],
  ['Taïwan', 'tw', '886'],
  ['Tadjikistan', 'tj', '992'],
  ['Tanzanie', 'tz', '255'],
  ['Thaïlande', 'th', '66'],
  ['Timor oriental', 'tl', '670'],
  ['Togo', 'tg', '228'],
  ['Tonga', 'to', '676'],
  ['Trinité-et-Tobago', 'tt', '1868'],
  ['Tunisie', 'tn', '216'],
  ['Turquie', 'tr', '90', '... ... .. ..'],
  ['Turkménistan', 'tm', '993'],
  ['Tuvalu', 'tv', '688'],
  ['Ouganda', 'ug', '256'],
  ['Ukraine', 'ua', '380', '(..) ... .. ..'],
  ['Émirats arabes unis', 'ae', '971'],
  ['Royaume-Uni', 'gb', '44', '.... ......'],
  ['États-Unis', 'us', '1', '(...) ...-....', 0],
  ['Uruguay', 'uy', '598'],
  ['Ouzbékistan', 'uz', '998', '.. ... .. ..'],
  ['Vanuatu', 'vu', '678'],
  ['Vatican', 'va', '39', '.. .... ....', 1],
  ['Venezuela', 've', '58'],
  ['Viêt Nam', 'vn', '84'],
  ['Yémen', 'ye', '967'],
  ['Zambie', 'zm', '260'],
  ['Zimbabwe', 'zw', '263'],
]
