import { App, type AppInfo } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { useEffect, useState } from 'react'

export const useAppInfo = () => {
  const [appInfo, setAppInfo] = useState<AppInfo>()

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const getAppInfo = async () => {
        const info = await App.getInfo()
        setAppInfo(info)
      }

      getAppInfo()
    }
  }, [])

  return appInfo
}
