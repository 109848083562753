import { useEffect } from 'react'
import { useInterval } from 'usehooks-ts'

import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'

import { useOnboarding } from './useOnboarding'

const POLLING_INTERVAL = 5000

export const useImagePolling = () => {
  // -- Hooks --
  const { setValues, values } = useOnboarding()

  // -- Data --
  const [{ data }, refetch] = useAuthMemberAxios({
    url: Urls.OnboardingImagesPolling,
  })

  // -- Interval --
  useInterval(refetch, POLLING_INTERVAL)

  // -- Effect --
  useEffect(() => {
    if (data && Object.values(data).length > 0) {
      setValues({
        ...values,
        ...data,
      })
    }
  }, [data])
}
