import { startOfMonth } from 'date-fns'
import { t } from 'i18next'
import { useMemo } from 'react'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { StatisticCard } from '../../../components/general/StatisticCard'
import { H4 } from '../../../components/typography'
import { UsageFractions } from '../../usage/components/UsageFractions'

export const EmployerHomeStatistics = () => {
  // -- Data --
  const statisticsFilters = useMemo(
    () => ({
      dateFrom: startOfMonth(new Date()),
      dateTo: new Date(),
    }),
    []
  )

  const [{ data: statistics, loading }] = useAuthMemberAxios({
    url: Urls.employerChargingSessionsStatistics,
    params: statisticsFilters,
  })

  if (loading || !statistics) return null

  return (
    <div>
      <StTitle>{t('employer.home.overview')}</StTitle>
      <StStatistics>
        <StatisticCard
          title={t('employer.chargingSessions.stats.usage')}
          subTitle={t('employer.home.this-month')}
          appendValue=" kWh"
          value={statistics?.totalUsage ?? 0}
        />
        <StatisticCard
          title={t('employer.chargingSessions.stats.charging-behaviour')}
          subTitle={t('employer.home.this-month')}
        >
          <UsageFractions statistics={statistics} />
        </StatisticCard>
        <StatisticCard
          title={t('employer.chargingSessions.stats.cost')}
          subTitle={t('employer.home.this-month')}
          prependValue="€"
          value={statistics?.totalCost ?? 0}
        />
      </StStatistics>
    </div>
  )
}

const StStatistics = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  > * {
    flex: 1 0 0;
  }
`

const StTitle = styled(H4)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
`
