import { styled } from 'styled-components'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { H3Css, H4 } from '../../../components/typography'

export const OnboardingStepTitle = styled(H4)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};

  @media ${breakpoints.desktop} {
    ${H3Css}
  }
`
