export const IntercomLogoDark = () => {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8439 26.354C23.8439 26.354 19.9131 24.8604 16.7328 23.6639H3.35872C1.77526 23.6639 0.492188 22.3492 0.492188 20.7283V3.52071C0.492188 1.90063 1.77526 0.585938 3.35872 0.585938H20.9757C22.5591 0.585938 23.843 1.90063 23.843 3.52071V18.341L23.8439 18.3402V26.354ZM20.3924 16.7051C20.1178 16.3773 19.6343 16.3398 19.3148 16.6202C19.2899 16.6419 16.8002 18.7803 12.1664 18.7803C7.59155 18.7803 5.06367 16.6569 5.0179 16.6186C4.69755 16.339 4.21661 16.3781 3.94202 16.7051C3.6666 17.0338 3.70487 17.5272 4.02523 17.8093C4.14421 17.9125 6.9758 20.3455 12.1664 20.3455C17.3594 20.3455 20.1918 17.9125 20.3092 17.8093C20.6295 17.5272 20.6678 17.0338 20.3924 16.7051Z"
        fill="black"
      />
    </svg>
  )
}
