import * as turf from '@turf/turf'

import type { BBox } from '@turf/turf'

export const createBBoxAroundPoint = (
  {
    longitude,
    latitude,
  }: {
    longitude: number
    latitude: number
  },
  km: number
) => {
  const point = turf.point([longitude, latitude])
  const buffer = turf.buffer(point, km, { units: 'kilometers' })
  const bbox = turf.bbox(buffer)

  return turf.bboxPolygon(bbox).bbox as BBox
}
