import ContentLoader from 'react-content-loader'
import { styled } from 'styled-components'

import { ContentContainer } from '../../../components/page-layout'

export const ContractLoader = () => {
  return (
    <ContentContainer>
      <StLoaderContainer>
        <ContentLoader
          speed={2}
          width={160}
          height={41}
          viewBox="0 0 160 41"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="1" y="0" rx="4" ry="4" width="159" height="41" />
        </ContentLoader>

        <ContentLoader
          speed={2}
          width={200}
          height={41}
          viewBox="0 0 200 41"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="1" y="0" rx="4" ry="4" width="199" height="41" />
        </ContentLoader>

        <StContractLoader>
          <ContentLoader
            speed={2}
            width={'100%'}
            height={228}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="4" ry="4" width="10%" height="26" />
            <rect x="88%" y="0" rx="4" ry="4" width="12%" height="26" />
            <rect x="0" y="48" rx="4" ry="4" width="12%" height="22" />
            <rect x="0" y="76" rx="4" ry="4" width="100%" height="52" />
            <rect x="0" y="148" rx="4" ry="4" width="12%" height="22" />
            <rect x="0" y="176" rx="4" ry="4" width="48%" height="52" />
            <rect x="50%" y="148" rx="4" ry="4" width="12%" height="22" />
            <rect x="50%" y="176" rx="4" ry="4" width="50%" height="52" />
          </ContentLoader>
        </StContractLoader>
      </StLoaderContainer>
    </ContentContainer>
  )
}

const StLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  > :first-child {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space1};
  }
`

const StContractLoader = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
`
