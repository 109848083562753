import { Form, Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { css, styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'
import * as Yup from 'yup'

import { EmployeeRoutes } from '../../../../../routing/routes'
import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { ControlledSelect } from '../../../../components/form/Select'
import {
  BodyMediumRegular,
  BodyMediumRegularCss,
  BodyMediumSemiBold,
} from '../../../../components/typography'
import { shallowEqual } from '../../../../core/lib/shallowEqual'
import { onHover } from '../../../../core/lib/styleHelpers'
import { HcpStatusType } from '../../../../employer/employees/components/EmployeesDataTable'
import { useUpdateStatus } from '../../../../member/hooks/useUpdateStatus'
import { useUser } from '../../../../user/hooks/useUser'
import { FloatingContainerButton } from '../../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { useOnboarding } from '../../hooks/useOnboarding'

import type { SelectOption } from '../../../../components/form/Select'

export enum UtpConnection {
  'Within 1.5m' = 'Within 1.5m',
  'Beyond 1.5m' = 'Beyond 1.5m',
  'Not Available' = 'Not Available',
}

export enum WifiSignal {
  'Strong' = 'Strong',
  'Weak' = 'Weak',
  'No' = 'No',
}

export enum Socket {
  'Yes' = 'Yes',
  'No' = 'No',
}

export enum P1PortActivated {
  Yes = 'Yes',
  No = 'No',
  'Request Submitted' = 'Request Submitted',
}

export function DigitalMeterEnvironmentStep() {
  const { t } = useTranslation()
  const {
    values: onboardingValues,
    handleNext,
    setLoading,
    loading,
  } = useOnboarding()
  const { updateStatus } = useUpdateStatus()
  const { user } = useUser()
  const navigate = useNavigate()

  const isDesktop = useMediaQuery(breakpoints.desktop)

  const [, execute] = useAuthMemberAxios(
    { url: Urls.OnboardingData, method: 'POST' },
    { manual: true }
  )

  const utpConnectionOptions: SelectOption[] = [
    {
      key: UtpConnection['Within 1.5m'],
      label: t(
        'onboarding.digital-meter-environment.utp-connection.option.within-1.5m'
      ),
    },
    {
      key: UtpConnection['Beyond 1.5m'],
      label: t(
        'onboarding.digital-meter-environment.utp-connection.option.beyond-1.5m'
      ),
    },
    {
      key: UtpConnection['Not Available'],
      label: t(
        'onboarding.digital-meter-environment.utp-connection.option.not-available'
      ),
    },
  ]

  const wifiSignalOptions: SelectOption[] = [
    {
      key: WifiSignal.Strong,
      label: t(
        'onboarding.digital-meter-environment.wifi-signal.option.strong'
      ),
    },
    {
      key: WifiSignal.Weak,
      label: t('onboarding.digital-meter-environment.wifi-signal.option.weak'),
    },
    {
      key: WifiSignal.No,
      label: t('onboarding.digital-meter-environment.wifi-signal.option.no'),
    },
  ]

  const socketOptions: SelectOption[] = [
    {
      key: Socket.Yes,
      label: t('onboarding.digital-meter-environment.socket.option.yes'),
    },
    {
      key: Socket.No,
      label: t('onboarding.digital-meter-environment.socket.option.no'),
    },
  ]

  const p1PortActivatedOptions: SelectOption[] = [
    {
      key: P1PortActivated.Yes,
      label: t('onboarding.digital-meter-environment.p1-port.option.yes'),
    },
    {
      key: P1PortActivated.No,
      label: t('onboarding.digital-meter-environment.p1-port.option.no'),
    },
    {
      key: P1PortActivated['Request Submitted'],
      label: t(
        'onboarding.digital-meter-environment.p1-port.option.request-submitted'
      ),
    },
  ]

  const validationSchema = Yup.object().shape({
    utpConnectionKey: Yup.mixed<UtpConnection>()
      .oneOf(Object.values(UtpConnection))
      .required(),
    wifiSignalKey: Yup.mixed<WifiSignal>()
      .oneOf(Object.values(WifiSignal))
      .required(),
    socketKey: Yup.mixed<Socket>().oneOf(Object.values(Socket)).required(),
    p1PortActivatedKey: Yup.mixed<P1PortActivated>()
      .oneOf(Object.values(P1PortActivated))
      .required(),
  })

  const handleSubmit = async (
    values: typeof onboardingValues.digitalMeterEnvironment
  ) => {
    setLoading(true)

    try {
      let status: number | undefined = undefined

      if (!shallowEqual(values, onboardingValues.bankDetails)) {
        const response = await execute({
          data: {
            ...values,
          },
        })

        status = response.status
      }

      if (user.hcpStatus.hasExistingDevice) {
        await updateStatus(HcpStatusType.OnboardingCompleted)

        navigate(EmployeeRoutes.Root, { replace: true })
      } else if (status === 201) {
        handleNext({
          ...onboardingValues,
          digitalMeterEnvironment: {
            utpConnectionKey: values.utpConnectionKey as UtpConnection,
            wifiSignalKey: values.wifiSignalKey as WifiSignal,
            socketKey: values.socketKey as Socket,
            p1PortActivatedKey: values.p1PortActivatedKey as P1PortActivated,
          },
        })
      }
    } catch {
      console.error('Failed to save digital meter environment data')
    } finally {
      setLoading(false)
    }
  }

  const formContent = (
    <StFormContent>
      <Formik
        validateOnMount
        initialValues={{
          utpConnectionKey:
            onboardingValues.digitalMeterEnvironment.utpConnectionKey,
          wifiSignalKey: onboardingValues.digitalMeterEnvironment.wifiSignalKey,
          socketKey: onboardingValues.digitalMeterEnvironment.socketKey,
          p1PortActivatedKey:
            onboardingValues.digitalMeterEnvironment.p1PortActivatedKey,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => {
          return (
            <>
              <Form>
                <StQuestionContainer>
                  <ControlledSelect
                    name="utpConnectionKey"
                    options={utpConnectionOptions}
                    label={t(
                      'onboarding.digital-meter-environment.utp-connection.label'
                    )}
                    placeholder={t(
                      'onboarding.digital-meter-environment.utp-connection.placeholder'
                    )}
                  />
                  <ControlledSelect
                    name="wifiSignalKey"
                    options={wifiSignalOptions}
                    label={t(
                      'onboarding.digital-meter-environment.wifi-signal.label'
                    )}
                    placeholder={t(
                      'onboarding.digital-meter-environment.wifi-signal.placeholder'
                    )}
                  />
                  <ControlledSelect
                    name="socketKey"
                    options={socketOptions}
                    label={t(
                      'onboarding.digital-meter-environment.socket.label'
                    )}
                    placeholder={t(
                      'onboarding.digital-meter-environment.socket.placeholder'
                    )}
                  />
                  <ControlledSelect
                    name="p1PortActivatedKey"
                    options={p1PortActivatedOptions}
                    label={t(
                      'onboarding.digital-meter-environment.p1-port.label'
                    )}
                    placeholder={t(
                      'onboarding.digital-meter-environment.p1-port.placeholder'
                    )}
                  />
                </StQuestionContainer>
                <FloatingContainerButton
                  title={t('onboarding.account.submit')}
                  disabled={!isValid || loading}
                />
              </Form>
            </>
          )
        }}
      </Formik>
    </StFormContent>
  )

  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.digital-meter-environment.title')}
      </OnboardingStepTitle>
      <StContentContainer>
        {isDesktop ? (
          <>
            {formContent}
            <StDesktopInfoContainer>
              <WhyCard />
              <Tips />
            </StDesktopInfoContainer>
          </>
        ) : (
          <>
            <WhyCard />
            {formContent}
            <Tips />
          </>
        )}
      </StContentContainer>
    </StContainer>
  )
}

function WhyCard() {
  const { t } = useTranslation()

  return (
    <StWhyContainer>
      <StInfoTitle>
        {t('onboarding.digital-meter-environment.info.title')}
      </StInfoTitle>
      <BodyMediumRegular>
        {t('onboarding.digital-meter-environment.info.description')}
      </BodyMediumRegular>
    </StWhyContainer>
  )
}

function Tips() {
  const { t } = useTranslation()

  return (
    <StTipContainer>
      <BodyMediumRegular>
        {t('onboarding.digital-meter-environment.tip.title')}
      </BodyMediumRegular>
      <StUl>
        <StLi>
          <Trans
            i18nKey="onboarding.digital-meter-environment.tip1.description"
            components={{
              linkFluvius: (
                <StInlineLink
                  href={t(
                    'onboarding.digital-meter-environment.tip1.link.fluvius'
                  )}
                  target="_blank"
                />
              ),
              linkSibelga: (
                <StInlineLink
                  href={t(
                    'onboarding.digital-meter-environment.tip1.link.sibelga'
                  )}
                  target="_blank"
                />
              ),
              linkAIEG: (
                <StInlineLink
                  href={t(
                    'onboarding.digital-meter-environment.tip1.link.aieg'
                  )}
                  target="_blank"
                />
              ),
            }}
          />
        </StLi>
        <StLi>
          {t('onboarding.digital-meter-environment.tip2.description')}
        </StLi>
      </StUl>
    </StTipContainer>
  )
}

const StContainer = styled.div`
  height: 100%;
  padding-bottom: ${({ theme }) =>
    `calc(var(--sticky-button-container-height) + ${theme.UI.SpacingPx.Space6})`};

  @media ${breakpoints.desktop} {
    margin-top: 0;
    padding-bottom: ${({ theme }) =>
      `calc(var(--sticky-button-container-height) + ${theme.UI.SpacingPx.Space6})`};
  }
`

const StContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};

  @media ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space12};

    & > * {
      min-width: 0;
    }
  }
`

const StQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StDesktopInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  min-width: 0;
`

const StFormContent = styled.div``

const StWhyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};

  background-color: ${({ theme }) => theme.components.menu.default.border};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space7};
  }
`

const StInfoTitle = styled(BodyMediumSemiBold)`
  font-weight: 700;
`

const StUl = styled.ul`
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space6};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  list-style: disc !important;

  @media ${breakpoints.desktop} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StLi = styled.li`
  ${BodyMediumRegularCss}

  line-height: 1.8;
`

const StInlineLink = styled.a`
  background: none;
  outline: none;
  border: none;
  padding: 0;

  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.theme.colors.black};

  ${onHover(css`
    opacity: 0.6;
  `)}
`

const StTipContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`
