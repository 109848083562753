import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link, Navigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { useAuthentication } from '../../authentication/hooks/useAuthentication'
import { useSplashScreen } from '../../authentication/hooks/useSplashScreen'
import { detectDevice } from '../../core/lib/detectDevice'
import { ButtonPrimary } from '../button/ButtonPrimary'
import { BottomNavigation } from '../navigation/BottomNavigation'
import { Navigation } from '../navigation/Navigation'
import { ContentContainer } from '../page-layout'
import { BodyLargeSemiBold, H3 } from '../typography'

export const DebugScreen = () => {
  // -- State --
  const [trigerError, setTriggerError] = useState(false)
  const { hideSplashScreen } = useSplashScreen()

  useEffect(() => {
    hideSplashScreen()
  }, [])

  // -- Hooks --
  const { t } = useTranslation()
  const { user } = useAuthentication()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Vars --
  const environment = {
    backendUrl: import.meta.env.VITE_BACKEND_URL,
    environment: import.meta.env.VITE_ENV,
    isNative: Capacitor.isNativePlatform(),
    platform: Capacitor.getPlatform(),
    deviceOS: detectDevice(),
  }

  // -- handlers --
  const hapticsImpactHeavy = async () => {
    await Haptics.impact({ style: ImpactStyle.Heavy })
  }

  const hapticsImpactMedium = async () => {
    await Haptics.impact({ style: ImpactStyle.Medium })
  }

  const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light })
  }

  const hapticsVibrate = async () => {
    await Haptics.vibrate({ duration: 2000 })
  }

  const hapticNotification = async (notificationType: NotificationType) => {
    await Haptics.notification({ type: notificationType })
  }

  const hapticsSelectionStart = async () => {
    await Haptics.selectionStart()
  }

  const hapticsSelectionEnd = async () => {
    await Haptics.selectionEnd()
  }

  // -- Render --
  // If not development or staging, redirect to root
  if (!['local', 'staging', 'development'].includes(import.meta.env.VITE_ENV)) {
    return <Navigate to={'/'} />
  }

  if (trigerError) {
    throw new Error('test')
  }

  return (
    <StContainer>
      {isDesktop ? (
        <Navigation
          options={{
            logo: true,
            navigationItems: [{ name: t('admin.navigation.home'), url: '/' }],
          }}
        />
      ) : null}

      <ContentContainer>
        <StContent>
          <H3>{t('debug.title')}</H3>

          {user ? (
            <div>
              <BodyLargeSemiBold>Auth0 User</BodyLargeSemiBold>
              <pre>{JSON.stringify(user, null, 4)}</pre>
            </div>
          ) : null}

          <div>
            <BodyLargeSemiBold>Environment</BodyLargeSemiBold>
            <pre>{JSON.stringify(environment, null, 4)}</pre>
          </div>

          <ButtonPrimary compact onClick={() => setTriggerError(true)}>
            Trigger error screen
          </ButtonPrimary>

          <ButtonPrimary compact onClick={() => toast.success('Success toast')}>
            Show success toast
          </ButtonPrimary>
          <ButtonPrimary compact onClick={() => toast.error('Error toast')}>
            Show error toast
          </ButtonPrimary>

          <BodyLargeSemiBold>Embedded map</BodyLargeSemiBold>
          <StIframe
            title="embedded-map"
            src={`${location.origin}/embedded/map`}
          />

          {Capacitor.isNativePlatform() ? (
            <>
              <ButtonPrimary compact onTouchStart={hapticsImpactLight}>
                Test Light haptic impact
              </ButtonPrimary>
              <ButtonPrimary compact onTouchStart={hapticsImpactMedium}>
                Test medium haptic impact
              </ButtonPrimary>
              <ButtonPrimary compact onTouchStart={hapticsImpactHeavy}>
                Test heavy haptic impact
              </ButtonPrimary>
              <ButtonPrimary compact onClick={hapticsVibrate}>
                Test vibration
              </ButtonPrimary>
              <ButtonPrimary
                compact
                onTouchStart={() =>
                  hapticNotification(NotificationType.Success)
                }
              >
                Test notification success haptics
              </ButtonPrimary>
              <ButtonPrimary
                compact
                onTouchStart={() =>
                  hapticNotification(NotificationType.Warning)
                }
              >
                Test notification warning haptics
              </ButtonPrimary>
              <ButtonPrimary
                compact
                onTouchStart={() => hapticNotification(NotificationType.Error)}
              >
                Test notification error haptics
              </ButtonPrimary>

              {/* Doesn't seem to work on my phone (Nothing Phone 2) */}
              <ButtonPrimary
                compact
                onMouseDown={hapticsSelectionStart}
                onMouseUp={hapticsSelectionEnd}
                onTouchStart={hapticsSelectionStart}
                onTouchEnd={hapticsSelectionEnd}
              >
                Test haptic press
              </ButtonPrimary>
            </>
          ) : null}

          <Link to="/embedded/map">Go to embedded map</Link>
        </StContent>
      </ContentContainer>

      {isDesktop ? null : (
        <BottomNavigation
          navigationItems={[{ name: t('admin.navigation.home'), url: '/' }]}
        />
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
`

const StContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const StIframe = styled.iframe`
  border: none;
  border-radius: 4px;
  height: 50vh;
`
