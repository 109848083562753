import { Formik, Form } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import * as Yup from 'yup'

import { DateInput } from '../../../components/form/date-input/DateInput'
import { Input } from '../../../components/form/Input'
import {
  Select,
  StError,
  type SelectOption,
} from '../../../components/form/Select'
import { ButtonGroup } from '../../../components/general/ButtonGroup'
import {
  BodyMediumRegular,
  BodySmallRegular,
  BodySmallSemiBoldCss,
} from '../../../components/typography'

import type { EmployeeInformation } from '../screens/InviteEmployeesScreen'
import type { FormikHelpers } from 'formik'

type CreateOrEditEmployeeProps = {
  hcpContractOptions: SelectOption[]
  mspContractOptions: SelectOption[]
  mspDeliveryOptions: SelectOption[]
  onUpsertEmployee: (values: EmployeeInformation, shouldClose: boolean) => void
  onRemoveEmployee: (employee: EmployeeInformation) => void
  existingEmailAddresses: string[]
  onClose: () => void
  initialValues?: EmployeeInformation
}

export const CreateOrEditEmployee = ({
  hcpContractOptions,
  mspContractOptions,
  mspDeliveryOptions,
  onUpsertEmployee,
  onRemoveEmployee,
  existingEmailAddresses,
  onClose,
  initialValues,
}: CreateOrEditEmployeeProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- State --
  const [shiftHeld, setShiftHeld] = useState(false)

  const firstInputRef = useRef<HTMLInputElement>(null)

  // -- Validation --
  const employeeValidation = Yup.object().shape({
    email: Yup.string()
      .required(t('admin.invite-employees.information.error.required'))
      .email(t('admin.invite-employees.information.error.email'))
      .test(
        'email-not-unique',
        t('admin.invite-employees.error.email-not-unique'),
        function (value: string, context: Yup.TestContext) {
          const formValues = context.options.context as {
            email: string
          }

          return !existingEmailAddresses.includes(formValues.email)
        }
      ),
    hcpContractId: Yup.string(),
    mspContractId: Yup.string().when(
      'hcpContractId',
      (hcpContract: string[]) => {
        if (hcpContract[0] === 'NULL') {
          return Yup.string().notOneOf(
            ['NULL'],
            t(
              'admin.invite-employees.information.error.contract-or-charge-card'
            )
          )
        }

        return Yup.string()
      }
    ),
    activationDate: Yup.date(),
    internalId: Yup.string(),
    hasExistingDevice: Yup.string().oneOf(['true', 'false']).required(),
    manualMspHandover: Yup.string().oneOf(['true', 'false']).required(),
  })

  // -- Data --
  const hasExistingDeviceOptions: SelectOption[] = [
    {
      key: 'false',
      label: t('admin.invite-employees.information.new'),
    },
    {
      key: 'true',
      label: t('admin.invite-employees.information.existing'),
    },
  ]

  // -- Shift Logic --
  function downHandler({ key }: KeyboardEvent) {
    if (key === 'Shift') {
      setShiftHeld(true)
    }
  }

  function upHandler({ key }: KeyboardEvent) {
    if (key === 'Shift') {
      setShiftHeld(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  // -- Effects --
  useEffect(() => {
    firstInputRef.current?.focus()
  }, [])

  // -- Handlers --
  const handleSubmit = (
    values: EmployeeInformation,
    actions: FormikHelpers<EmployeeInformation>
  ) => {
    onUpsertEmployee(values, !!initialValues || !shiftHeld)

    // Reset form
    actions.resetForm()

    if (shiftHeld) {
      firstInputRef.current?.focus()
    }
  }

  return (
    <StContainer>
      <BodyMediumRegular>
        {t('admin.invite-employees.employee-form.description')}
      </BodyMediumRegular>

      <Formik
        initialValues={
          initialValues ?? {
            email: '',
            hcpContractId: 'NULL',
            mspContractId: 'NULL',
            activationDate: new Date(),
            internalId: '',
            hasExistingDevice: 'false',
            manualMspHandover: 'false',
          }
        }
        onSubmit={handleSubmit}
        validationSchema={employeeValidation}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          values,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <StFormContainer>
              <StFormRow>
                <Input
                  ref={firstInputRef}
                  type="text"
                  name={'email'}
                  label={t('admin.invite-employees.employee-form.email')}
                  placeholder={t(
                    'admin.invite-employees.information.email.placeholder'
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email}
                  value={values.email}
                  touched={touched.email}
                />
                <Input
                  type="text"
                  label={t('admin.invite-employees.employee-form.internal-id')}
                  name={'internalId'}
                  placeholder={t('admin.invite-employees.information.optional')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.internalId}
                  value={values.internalId}
                  touched={touched.internalId}
                />
              </StFormRow>
              <StFormRow>
                <DateInput
                  label={t('admin.invite-employees.employee-form.start-date')}
                  onChange={(value) => {
                    setFieldValue('activationDate', value)
                  }}
                  error={errors.activationDate}
                  touched={touched.activationDate}
                  value={values.activationDate}
                  minDate={new Date()}
                />
                <Select
                  label={t('admin.invite-employees.employee-form.hcp')}
                  disabled={!hcpContractOptions}
                  options={hcpContractOptions}
                  onChange={(value) => {
                    setFieldValue('hcpContractId', value ?? 'NULL')
                    setTimeout(() => {
                      setFieldTouched('hcpContractId', true)
                      setFieldTouched('mspContractId', true)
                    }, 0)
                  }}
                  hasError={!!errors.hcpContractId || !!errors.mspContractId}
                  touched={touched.hcpContractId}
                  value={values.hcpContractId}
                />
                <Select
                  label={t('admin.invite-employees.employee-form.existing-hcp')}
                  disabled={
                    values.hcpContractId === 'NULL' || !hcpContractOptions
                  }
                  options={hasExistingDeviceOptions}
                  value={values.hasExistingDevice}
                  onChange={(value) => {
                    setFieldValue('hasExistingDevice', value ?? 'NULL')
                  }}
                  touched={touched.hasExistingDevice}
                  icon={['fass', 'charging-station']}
                />
              </StFormRow>
              <div>
                <StFormRow>
                  <Select
                    label={t('admin.invite-employees.employee-form.msp')}
                    disabled={!hcpContractOptions}
                    options={mspContractOptions}
                    onChange={(value) => {
                      setFieldValue('mspContractId', value ?? 'NULL')
                      setTimeout(() => {
                        setFieldTouched('mspContractId', true)
                        setFieldTouched('hcpContractId', true)
                      }, 0)
                    }}
                    hasError={!!errors.mspContractId}
                    touched={touched.mspContractId}
                    value={values.mspContractId}
                    icon={['fass', 'credit-card-front']}
                  />
                  <Select
                    label={t(
                      'admin.invite-employees.employee-form.msp-postage'
                    )}
                    disabled={
                      !values.mspContractId || values.mspContractId === 'NULL'
                    }
                    options={mspDeliveryOptions}
                    onChange={(value) => {
                      setFieldValue('manualMspHandover', value ?? 'NULL')
                    }}
                    hasError={!!errors.manualMspHandover}
                    touched={touched.manualMspHandover}
                    value={values.manualMspHandover}
                    icon={['fass', 'truck-fast']}
                  />
                </StFormRow>
                {(touched.mspContractId || touched.hcpContractId) &&
                  errors.mspContractId && (
                    <StError>{errors.mspContractId}</StError>
                  )}
              </div>
            </StFormContainer>

            <ButtonGroup
              secondaryButtonText={
                initialValues
                  ? t('admin.invite-employees.employee-form.remove')
                  : t('admin.invite-employees.employee-form.cancel')
              }
              primaryButtonText={
                initialValues
                  ? t('admin.invite-employees.employee-form.save')
                  : shiftHeld
                  ? t('admin.invite-employees.employee-form.add-and-continue')
                  : t('admin.invite-employees.employee-form.add')
              }
              buttonGroupHint={
                initialValues ? undefined : (
                  <BodySmallRegular>
                    <Trans
                      t={t}
                      i18nKey="admin.invite-employees.employee-form.hint"
                      components={{ bold: <StBold /> }}
                    />
                  </BodySmallRegular>
                )
              }
              onClickSecondaryButton={() =>
                initialValues ? onRemoveEmployee(initialValues) : onClose()
              }
            ></ButtonGroup>
          </Form>
        )}
      </Formik>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StFormRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  & > * {
    flex: 1;
  }
`

const StBold = styled.span`
  ${BodySmallSemiBoldCss}
`
