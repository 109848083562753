export enum ChargingSessionType {
  HOME = 'Home',
  PUBLIC = 'Public',
  WORK = 'Work',
}

export type ChargingSessionStatistics = {
  totalUsage: number
  totalCost: number
  totalUsagePerType: Record<ChargingSessionType, number>
}
