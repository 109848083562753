import type { SVGProps } from 'react'

export const HemsBackgroundPattern = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="336"
      height="209"
      viewBox="0 0 336 209"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="336"
        height="336"
        transform="translate(0 -127.828)"
        fill="white"
      />
      <mask
        id="mask0_14521_36097"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="-128"
        width="336"
        height="337"
      >
        <rect
          width="336"
          height="336"
          transform="translate(0 -127.828)"
          fill="url(#paint0_radial_14521_36097)"
        />
      </mask>
      <g mask="url(#mask0_14521_36097)">
        <g clipPath="url(#clip0_14521_36097)">
          <g clipPath="url(#clip1_14521_36097)">
            <line
              x1="0.5"
              y1="-127.828"
              x2="0.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="24.5"
              y1="-127.828"
              x2="24.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="48.5"
              y1="-127.828"
              x2="48.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="72.5"
              y1="-127.828"
              x2="72.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="96.5"
              y1="-127.828"
              x2="96.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="120.5"
              y1="-127.828"
              x2="120.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="144.5"
              y1="-127.828"
              x2="144.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="168.5"
              y1="-127.828"
              x2="168.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="192.5"
              y1="-127.828"
              x2="192.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="216.5"
              y1="-127.828"
              x2="216.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="240.5"
              y1="-127.828"
              x2="240.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="264.5"
              y1="-127.828"
              x2="264.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="288.5"
              y1="-127.828"
              x2="288.5"
              y2="208.172"
              stroke="#EAECF0"
            />
            <line
              x1="312.5"
              y1="-127.828"
              x2="312.5"
              y2="208.172"
              stroke="#EAECF0"
            />
          </g>
          <rect
            x="0.5"
            y="-127.328"
            width="335"
            height="335"
            stroke="#EAECF0"
          />
          <g clipPath="url(#clip2_14521_36097)">
            <line y1="15.6719" x2="336" y2="15.6719" stroke="#EAECF0" />
            <line y1="39.6719" x2="336" y2="39.6719" stroke="#EAECF0" />
            <line y1="63.6719" x2="336" y2="63.6719" stroke="#EAECF0" />
            <line y1="87.6719" x2="336" y2="87.6719" stroke="#EAECF0" />
            <line y1="111.672" x2="336" y2="111.672" stroke="#EAECF0" />
            <line y1="135.672" x2="336" y2="135.672" stroke="#EAECF0" />
            <line y1="159.672" x2="336" y2="159.672" stroke="#EAECF0" />
            <line y1="183.672" x2="336" y2="183.672" stroke="#EAECF0" />
            <line y1="207.672" x2="336" y2="207.672" stroke="#EAECF0" />
          </g>
          <rect
            x="0.5"
            y="-127.328"
            width="335"
            height="335"
            stroke="#EAECF0"
          />
        </g>
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_14521_36097"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(168 168) rotate(90) scale(168 168)"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_14521_36097">
          <rect
            width="336"
            height="336"
            fill="white"
            transform="translate(0 -127.828)"
          />
        </clipPath>
        <clipPath id="clip1_14521_36097">
          <rect y="-127.828" width="336" height="336" fill="white" />
        </clipPath>
        <clipPath id="clip2_14521_36097">
          <rect y="-127.828" width="336" height="336" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
