import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../button/ButtonPrimary'
import { Navigation } from '../navigation/Navigation'
import { NavigationLogo } from '../navigation/NavigationLogo'
import { BodyMediumRegular, H4, H5Css } from '../typography'

import type { ReactNode } from 'react'

type FullScreenErrorProps = {
  svg: ReactNode
  title: ReactNode
  subTitle?: ReactNode
  buttonText: string
  onClick: () => void
}

export const FullScreenError = ({
  svg,
  title,
  subTitle,
  buttonText,
  onClick,
}: FullScreenErrorProps) => {
  // -- Hooks --
  const isTablet = useMediaQuery(breakpoints.desktop)

  // -- Render --
  return (
    <StContainer>
      {isTablet ? (
        <Navigation options={{ logo: true }} />
      ) : (
        <StNavlogoContainer>
          <NavigationLogo />
        </StNavlogoContainer>
      )}

      <StContentContainer>
        <StSvgContainer>{svg}</StSvgContainer>
        <StTitle>{title}</StTitle>
        {subTitle && <BodyMediumRegular>{subTitle}</BodyMediumRegular>}
        <ButtonPrimary compact={isTablet} onClick={onClick}>
          {buttonText}
        </ButtonPrimary>
      </StContentContainer>
    </StContainer>
  )
}

const StNavlogoContainer = styled.div`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space4};
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space2};

  a {
    justify-content: flex-start;
  }

  img {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
    padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
    padding-top: var(--inset-top, ${({ theme }) => theme.UI.SpacingPx.Space4});
  }
`

const StContainer = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
`

const StContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  flex: 1;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});

  margin: 0 auto;

  padding-bottom: var(--content-bottom-inset);

  @media ${breakpoints.desktop} {
    align-items: center;
    justify-content: center;

    width: 355px;
    margin: auto;

    text-align: center;
  }
`

const StSvgContainer = styled.div`
  width: 100%;
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.desktop} {
    flex: 0;
  }
`

const StTitle = styled(H4)`
  @media ${breakpoints.desktop} {
    ${H5Css}
  }
`
