// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line import/named
import { useUpdateEffect } from 'usehooks-ts'

import { useMember } from '../../member/hooks/useMember'

import { useAuthAxios } from './useAuthAxios'

import type { Options, UseAxiosResult } from './types'
import type { Optional } from './useAuthAxios'
import type { ConfigWithCache } from 'axios-cache-interceptor'

export const useAuthMemberAxios = <TResponse = any, TBody = any, TError = any>(
  config: Optional<ConfigWithCache<TBody>, 'cache'>,
  options?: Options
): UseAxiosResult<TResponse, TBody, TError> => {
  const { currentMember } = useMember()

  const [axiosReturnBody, refetch] = useAuthAxios<TResponse>(
    {
      ...config,
      params: { memberId: currentMember.id, ...config.params },
    },
    options
  )

  useUpdateEffect(() => {
    if (options?.manual || axiosReturnBody.loading) {
      return
    }

    refetch()
  }, [currentMember.id])

  return [axiosReturnBody, refetch]
}
