import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as RadixDialog from '@radix-ui/react-dialog'
import { styled } from 'styled-components'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { BodyMediumRegular, H4 } from '../typography'

import { ButtonGroup } from './ButtonGroup'

import type { PropsWithChildren, ReactNode } from 'react'

type DialogPortalProps = {
  title?: string
  description?: string
  primaryButtonText?: string
  secondaryButtonText?: string
  onClickPrimaryButton?: () => void
  onClickSecondaryButton?: () => void
  onClickCloseButton?: () => void
  disablePrimaryButton?: boolean
  disableSecondaryButton?: boolean
  showHeader?: boolean
  width?: string
  height?: string
  buttonGroupHint?: string | ReactNode
} & PropsWithChildren

export const DialogPortal = ({
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  onClickPrimaryButton,
  onClickSecondaryButton,
  onClickCloseButton,
  disablePrimaryButton,
  disableSecondaryButton,
  children,
  showHeader,
  width,
  height,
  buttonGroupHint,
}: DialogPortalProps) => {
  return (
    <RadixDialog.Portal>
      <StOverlay />
      <StContentContainer $width={width} $height={height}>
        {showHeader && (
          <div>
            <StTitleRow>
              <StTitle>{title && <StH4>{title}</StH4>}</StTitle>
              <RadixDialog.Close asChild>
                <StClose onClick={onClickCloseButton}>
                  <FontAwesomeIcon icon={['fasr', 'xmark-large']} />
                </StClose>
              </RadixDialog.Close>
            </StTitleRow>
            {description && <StDescription>{description}</StDescription>}
          </div>
        )}
        {children}
        <ButtonGroup
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          onClickPrimaryButton={onClickPrimaryButton}
          onClickSecondaryButton={onClickSecondaryButton}
          disablePrimaryButton={disablePrimaryButton}
          disableSecondaryButton={disableSecondaryButton}
          buttonGroupHint={buttonGroupHint}
        />
      </StContentContainer>
    </RadixDialog.Portal>
  )
}

const StClose = styled.button`
  cursor: pointer;

  color: ${({ theme }) => theme.theme.colors.black};
  margin-right: -${({ theme }) => theme.UI.SpacingPx.Space5};
  margin-top: -${({ theme }) => theme.UI.SpacingPx.Space1};
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space5};

  border-radius: 100px;

  font-size: ${({ theme }) => theme.UI.SpacingPx.Space5};
  height: ${({ theme }) => theme.UI.SpacingPx.Space10};
  width: ${({ theme }) => theme.UI.SpacingPx.Space10};

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.theme.colors.white};
  border: none;
  z-index: 100;

  &:hover {
    background-color: #0000001c;
  }

  @media ${breakpoints.mobile} {
    margin-right: -${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

export const StOverlay = styled(RadixDialog.Overlay)`
  background-color: ${({ theme }) => theme.theme.colors['secondary-1']};
  opacity: 0.8;
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  z-index: 505;
`

const StContentContainer = styled(RadixDialog.Content)<{
  $width?: string
  $height?: string
}>`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.theme.colors.white};
  max-width: 100dvw;
  position: fixed;

  overflow: auto;

  left: 0;
  right: 0;
  bottom: 0;

  z-index: 505;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  width: ${({ $width }) => $width || 'auto'};
  height: ${({ $height }) => $height || 'auto'};

  @media ${breakpoints.mobile} {
    max-height: 90dvh;

    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;

    transform: translate(-50%, -50%);

    padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space8};
  }
`

const StTitleRow = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: nowrap;
  align-items: start;
`

const StTitle = styled.div`
  flex-grow: 1;
`

const StDescription = styled(BodyMediumRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StH4 = styled(H4)`
  line-height: 100%;
`
