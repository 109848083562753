import { Geolocation } from '@capacitor/geolocation'
import { useEffect, useState, useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

import { AtomKeys } from '../../core/recoil/atomsKeys'
import { useMember } from '../../member/hooks/useMember'
import { useUser } from '../../user/hooks/useUser'

type Location = {
  latitude: number
  longitude: number
}

const locationAtom = atom<Location>({
  key: AtomKeys.Location,
  default: undefined,
})

type UseGeoLocationParameters = {
  onPermissionChange?: (permissionGranted: boolean) => void
}

export const useGeoLocation = ({
  onPermissionChange,
}: UseGeoLocationParameters = {}) => {
  // -- State --
  const [location, setLocation] = useRecoilState(locationAtom)
  const [locationPermissionGranted, setLocationPermissionGranted] =
    useState<boolean>(false)

  // -- Hooks --
  const { user } = useUser()
  const { currentMember } = useMember()

  // -- Functions --
  const getInitialLocation = async () => {
    const permission = await Geolocation.checkPermissions()

    if (permission.location !== 'granted') {
      return setLocation({
        longitude: user.addresses[0]?.longitude || 3.71667,
        latitude: user.addresses[0]?.latitude || 51.05,
      })
    }

    try {
      const coordinates = await Geolocation.getCurrentPosition({
        timeout: 5000,
        maximumAge: 0,
        enableHighAccuracy: true,
      })

      return setLocation({
        longitude: coordinates.coords.longitude,
        latitude: coordinates.coords.latitude,
      })
    } catch {
      // Only set the location if it is undefined

      if (location === undefined && user.addresses.length > 0) {
        setLocation({
          longitude: user.addresses[0]?.longitude || 3.71667,
          latitude: user.addresses[0]?.latitude || 51.05,
        })
      }
    }
  }

  const watchLocation = async () => {
    try {
      const watchId = await Geolocation.watchPosition(
        { enableHighAccuracy: true, maximumAge: 5000 },
        (position) => {
          position &&
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
        }
      )

      return () => Geolocation.clearWatch({ id: watchId })
    } catch {
      // Only set the location if it is undefined
      if (location === undefined) {
        setLocation({
          longitude: user.addresses[0].longitude,
          latitude: user.addresses[0].latitude,
        })
      }
    }
  }

  const getLocationPermission = useCallback(async () => {
    const permission = await Geolocation.checkPermissions()

    const newLocationPermissionGranted = permission.location === 'granted'
    setLocationPermissionGranted(newLocationPermissionGranted)

    if (locationPermissionGranted !== newLocationPermissionGranted) {
      onPermissionChange?.(newLocationPermissionGranted)
    }
  }, [locationPermissionGranted, onPermissionChange])

  // -- Effects --
  useEffect(() => {
    if (currentMember.roles.includes('Employee') && !location) {
      getInitialLocation()
    }
  }, [currentMember.roles])

  useEffect(() => {
    if (!locationPermissionGranted) {
      const intervalId = setInterval(() => {
        return getLocationPermission()
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [getLocationPermission])

  return { watchLocation, location, locationPermissionGranted }
}
