import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { ChargerIcon } from '../../assets/ChargerIcon'
import { PoleIcon } from '../../assets/PoleIcon'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { SelectBoxes } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

import type { SelectBoxesItems } from '../../components/SelectBoxes'

export enum InstallationMethodKeys {
  Pole = 'Pole',
  Wall = 'Wall',
}

export const InstallationMethodStep = () => {
  const { handleNext, setLoading, values } = useOnboarding()

  // -- State --
  const [selectedItem, setSelectedItem] = useState(values.installationMethodKey)

  // -- Hooks --
  const { t } = useTranslation()

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Vars --
  const items: SelectBoxesItems<InstallationMethodKeys>[] = [
    {
      name: t('onboarding.installation-method.wall'),
      icon: <ChargerIcon />,
      key: InstallationMethodKeys.Wall,
    },
    {
      name: t('onboarding.installation-method.pole'),
      icon: <PoleIcon />,
      key: InstallationMethodKeys.Pole,
    },
  ]

  // -- Handlers --
  const handleSubmit = async (key: InstallationMethodKeys) => {
    try {
      setSelectedItem(key)
      setLoading(true)

      if (key !== values.installationMethodKey) {
        await execute({
          data: {
            installationMethodKey: key,
          },
        })
      }

      handleNext({
        ...values,
        installationMethodKey: key,
      })
    } catch {
      setLoading(false)
      return console.error('Failed to save installation method')
    }
  }

  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.installation-method.title')}
      </OnboardingStepTitle>

      <SelectBoxes
        items={items}
        onSelect={handleSubmit}
        selectedItem={selectedItem}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  height: 100%;
`
