import * as RadixDialog from '@radix-ui/react-dialog'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { BottomSheet } from '../bottom-sheet/BottomSheet'
import { BodyMediumRegular } from '../typography'

import { ButtonGroup } from './ButtonGroup'
import { DialogPortal } from './DialogPortal'

import type { PropsWithChildren, ReactNode } from 'react'

type DialogProps = {
  title?: string
  description?: string
  primaryButtonText?: string
  secondaryButtonText?: string
  onClickPrimaryButton?: () => void
  onClickSecondaryButton?: () => void
  onClickCloseButton?: () => void
  disablePrimaryButton?: boolean
  disableSecondaryButton?: boolean
  trigger?: ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
  // Used to fix weird scrolling with the ios keyboard
  disableScrollLocking?: boolean
  showHeader?: boolean
  width?: string
  height?: string
} & PropsWithChildren

export const Dialog = ({
  title,
  description,
  trigger,
  primaryButtonText,
  secondaryButtonText,
  onClickPrimaryButton,
  onClickSecondaryButton,
  onClickCloseButton,
  disablePrimaryButton,
  disableSecondaryButton,
  children,
  open,
  onOpenChange,
  disableScrollLocking = false,
  showHeader = true,
  width,
  height,
}: DialogProps) => {
  // -- Hooks --
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Render --
  if (isDesktop) {
    return (
      <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
        {trigger ? (
          <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>
        ) : null}
        <DialogPortal
          title={title}
          description={description}
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          onClickPrimaryButton={onClickPrimaryButton}
          onClickSecondaryButton={onClickSecondaryButton}
          onClickCloseButton={onClickCloseButton}
          disablePrimaryButton={disablePrimaryButton}
          disableSecondaryButton={disableSecondaryButton}
          showHeader={showHeader}
          width={width}
          height={height}
        >
          {children}
        </DialogPortal>
      </RadixDialog.Root>
    )
  }

  return (
    <BottomSheet
      onClose={() => onClickCloseButton?.()}
      isOpen={!!open}
      hasBackdrop
      detent="content-height"
      disableScrollLocking={disableScrollLocking}
      title={title}
    >
      <StContent>
        {description && <StDescription>{description}</StDescription>}
        {children}
        <ButtonGroup
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          onClickPrimaryButton={onClickPrimaryButton}
          onClickSecondaryButton={onClickSecondaryButton}
          disablePrimaryButton={disablePrimaryButton}
          disableSecondaryButton={disableSecondaryButton}
        />
      </StContent>
    </BottomSheet>
  )
}

const StContent = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StDescription = styled(BodyMediumRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};
`
