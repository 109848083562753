import { styled } from 'styled-components'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { H4Css, H5Css } from '../typography'

import type { PropsWithChildren } from 'react'

export const PageHeader = ({ children }: PropsWithChildren) => {
  return (
    <StPageHeader>
      <StPageHeaderContent>{children}</StPageHeaderContent>
    </StPageHeader>
  )
}

const StPageHeader = styled.div`
  background-color: ${({ theme }) => theme.theme.colors.white};
  width: 100%;

  position: sticky;
  top: 0px;

  margin-top: calc((var(--inset-top, 0px) * -1));
  padding-top: var(--inset-top);

  z-index: 200;
  justify-content: space-between;
  align-items: center;
`

const StPageHeaderContent = styled.div`
  ${H5Css}

  min-height: calc(var(--mobile-page-header-height));

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  flex-grow: 1;

  @media ${breakpoints.desktop} {
    min-height: var(--page-header-height);
    ${H4Css}

    position: initial;
  }
`
