import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type ChargingSession } from 'database'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../components/typography'
import { ChargingSessionType } from '../types/types'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

type ChargingSessionTypeProps = {
  session: ChargingSession
}

export const ChargingSessionTypeLabel = ({
  session,
}: ChargingSessionTypeProps) => {
  const { t } = useTranslation()

  const icon = useMemo(() => {
    let resultingIcon: IconProp = ['fass', 'road']

    if (session.type === ChargingSessionType.HOME) {
      resultingIcon = ['fass', 'home']
    }

    if (session.type === ChargingSessionType.WORK) {
      resultingIcon = ['fass', 'car-building']
    }

    return resultingIcon
  }, [session.type])

  const iconTooltip = () => {
    let result = t('employer.chargingSessions.type.Public')

    if (session.type === ChargingSessionType.HOME) {
      result = t('employer.chargingSessions.type.Home')
    }

    if (session.type === ChargingSessionType.WORK) {
      result = t('employer.chargingSessions.type.Work')
    }

    return result
  }

  const label = useMemo(() => {
    let result = `${session.city}, ${session.country}`

    if (session.type === ChargingSessionType.HOME) {
      result = t('employer.chargingSessions.home')
    }

    return result
  }, [session, t])

  return (
    <StSessionTypeLabel>
      <StIcon title={iconTooltip()} icon={icon} />
      <BodySmallRegular>{label}</BodySmallRegular>
    </StSessionTypeLabel>
  )
}

const StSessionTypeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
