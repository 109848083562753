import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { StIconContainer } from '../../employee/onboarding/components/SelectBoxes'
import { BodyLargeSemiBold } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

type SmallInfoCardProps = {
  icon: IconProp
  title: string
}

export const SmallInfoCard = ({ icon, title }: SmallInfoCardProps) => {
  return (
    <StSmallCard>
      <StIconContainer>
        <FontAwesomeIcon icon={icon} />
      </StIconContainer>
      <BodyLargeSemiBold>{title}</BodyLargeSemiBold>
    </StSmallCard>
  )
}

const StSmallCard = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  border: 1px solid
    ${({ theme }) => theme.components['card-select']['default-border']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`
