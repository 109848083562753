export const ChargerIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 52 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.61694"
        y="1.63647"
        width="48.5519"
        height="38.726"
        rx="2.89"
        stroke="#2D2D2D"
        strokeWidth="2.312"
      />
      <rect
        x="17.5135"
        y="12.3299"
        width="16.762"
        height="17.34"
        rx="0.866999"
        fill="#2D2D2D"
        stroke="#2D2D2D"
        strokeWidth="1.734"
      />
      <path
        d="M21.0527 21.5848L27.9164 15.0823L26.5617 19.5527L30.4451 20.1398L23.5814 26.6423L24.9135 22.1944L21.0527 21.5848Z"
        fill="#FFF3CF"
      />
    </svg>
  )
}
