import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BottomSheet } from '../../../components/bottom-sheet/BottomSheet'
import {
  BodyExtraSmallRegular,
  BodyExtraSmallSemiBold,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { formatDecimals } from '../../../core/utils/number'
import { DataList } from '../../../dataTable/components/DataList'
import { ColumnType } from '../../../dataTable/components/DataTable'
import { useMember } from '../../../member/hooks/useMember'
import { valueToDate } from '../../../translations/utils/date'

import { ChargingSessionInfoHcp } from './ChargingSessionInfoHcp'
import { ReimbursementStatusChip } from './ReimbursementStatusChip'

import type { Column } from '../../../dataTable/components/DataTable'
import type { ChargingSessionWithRelations } from 'types'

export const UsageDataListHcp = () => {
  const { t } = useTranslation()
  const dateFnsLocale = useGetDateFnsLocale()
  const [isSheetOpen, setIsSheetOpen] = useState(false)
  const [selectedChargingSession, setSelectedChargingSession] = useState<
    ChargingSessionWithRelations | undefined
  >(undefined)

  const { currentMember } = useMember()

  const columns = useMemo<Column<ChargingSessionWithRelations>[]>(
    () => [
      {
        key: 'name',
        component: BodyExtraSmallSemiBold,
        type: ColumnType.STRING,
        transformData: (session) => {
          return session.mspCard?.memberId === currentMember.id
            ? `${currentMember.user.firstName} ${currentMember.user.lastName}`
            : t('employee.chargingSessions.guest')
        },
      },
      {
        key: 'hcpPrice',
        prepend: '€ ',
        component: BodyExtraSmallSemiBold,
        type: ColumnType.STRING,
        transformData: (session) => {
          return formatDecimals(session.hcpPrice)
        },
      },
      {
        key: 'kwh',
        type: ColumnType.STRING,
        component: StLightText,
        transformData: (session) => {
          return (
            <>
              <StIcon icon={['fass', 'bolt']} />
              {session.kwh.toFixed(2)}
              {' kWh'}
            </>
          )
        },
      },
      {
        key: 'status',
        label: t('employee.chargingSessions.table.status'),
        type: ColumnType.STRING,
        component: Fragment,
        transformData: (session) => {
          return (
            <ReimbursementStatusChip
              chargingSession={session}
              member={currentMember}
            />
          )
        },
      },
    ],
    [t, currentMember]
  )

  const groupOn = useMemo<Column<ChargingSessionWithRelations>>(() => {
    return {
      key: 'start',
      type: ColumnType.STRING,
      transformData: (data: ChargingSessionWithRelations) => {
        return format(valueToDate(data.start), 'd MMMM', dateFnsLocale)
      },
    }
  }, [dateFnsLocale])

  const selectedChargingSessionTitle = useMemo(() => {
    if (!selectedChargingSession) {
      return ''
    }

    const startDate = valueToDate(selectedChargingSession.start)
    return format(startDate, 'dd MMMM yyyy', dateFnsLocale)
  }, [selectedChargingSession, dateFnsLocale])

  const handleRowClick = useCallback(
    (dataRow: ChargingSessionWithRelations) => {
      setSelectedChargingSession(dataRow)
      setIsSheetOpen(true)
    },
    []
  )

  const handleSheetClose = useCallback(() => {
    setIsSheetOpen(false)
  }, [])

  return (
    <StContainer>
      <DataList<ChargingSessionWithRelations>
        columns={columns}
        groupOn={groupOn}
        onRowClick={handleRowClick}
        emptyTitle={t('employee.chargingSessions.list.empty-title')}
        emptyFiltersTitle=""
      />
      <BottomSheet
        detent="content-height"
        isOpen={isSheetOpen}
        onClose={handleSheetClose}
        hasBackdrop
        title={selectedChargingSessionTitle}
      >
        {selectedChargingSession && (
          <StSessionInfoContainer>
            <ChargingSessionInfoHcp chargingSession={selectedChargingSession} />
          </StSessionInfoContainer>
        )}
      </BottomSheet>
    </StContainer>
  )
}

const StContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StSessionInfoContainer = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StIcon = styled(FontAwesomeIcon)`
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StLightText = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
