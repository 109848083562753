import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { Input } from '../../../../components/form/Input'
import { InfoPopup } from '../../../../components/info-popup/InfoPopup'
import {
  BodyExtraExtraSmallMedium,
  BodyMediumRegular,
  BodyMediumSemiBold,
  H5,
} from '../../../../components/typography'
import { useHemsConfiguration } from '../hooks/useHemsConfiguration'

export const HemsEnergyImportStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const {
    hemsConfigurationData,
    setHemsConfigurationData,
    flowNavigation,
    configureHems,
  } = useHemsConfiguration()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- State --
  const [loading, setLoading] = useState(false)

  // -- Handlers --
  const handleSubmit = async (values: {
    elek_import_price: number
    elek_export_price: number
  }) => {
    setHemsConfigurationData((previous) => ({ ...previous, ...values }))

    try {
      setLoading(true)
      await configureHems({ ...hemsConfigurationData, ...values })
      flowNavigation.next()
    } catch {
      toast.error(t('employee.hems.onboarding.configuration.error'))
    }
  }

  // -- Render --
  return (
    <Formik
      initialValues={{
        elek_import_price: hemsConfigurationData.elek_import_price,
        elek_export_price: hemsConfigurationData.elek_export_price,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleChange, values }) => (
        <StContainer>
          <StTitle>
            {t(
              hemsConfigurationData.pv_installed
                ? 'employee.hems.onboarding.tarrif-step.title'
                : 'employee.hems.onboarding.tarrif-step.title-only-import'
            )}
          </StTitle>
          <StFlexContainer>
            <Input
              name="elek_import_price"
              label={t('employee.hems.onboarding.tarrif-step.import-label')}
              valueLabel={t(
                'employee.hems.onboarding.tarrif-step.import-value'
              )}
              onChange={handleChange}
              value={values.elek_import_price}
              hint={
                <StInfoContainer>
                  <InfoPopup
                    content={
                      <StInfoText>
                        {t('employee.hems.onboarding.tarrif-step.import-info')}
                      </StInfoText>
                    }
                  />
                </StInfoContainer>
              }
            />
            {hemsConfigurationData.pv_installed && (
              <Input
                name="elek_export_price"
                label={t('employee.hems.onboarding.tarrif-step.export-label')}
                valueLabel={t(
                  'employee.hems.onboarding.tarrif-step.export-value'
                )}
                onChange={handleChange}
                value={values.elek_export_price}
                hint={
                  <StInfoContainer>
                    <InfoPopup
                      content={
                        <StInfoText>
                          {t(
                            'employee.hems.onboarding.tarrif-step.export-info'
                          )}
                        </StInfoText>
                      }
                    />
                  </StInfoContainer>
                }
              />
            )}
            <StInfoCard>
              <FontAwesomeIcon icon={['fasr', 'info-circle']} fontSize={18} />
              <BodyMediumSemiBold>
                {t('employee.hems.onboarding.tarrif-step.info.title')}
              </BodyMediumSemiBold>
              <BodyMediumRegular>
                {t('employee.hems.onboarding.tarrif-step.info.description')}
              </BodyMediumRegular>
            </StInfoCard>
          </StFlexContainer>

          {isDesktop ? (
            <StFlexRowContainer>
              <ButtonTertiary
                onClick={() => flowNavigation.close()}
                compact
                loading={loading}
              >
                {t('employee.hems.onboarding.close')}
              </ButtonTertiary>
              <ButtonPrimary type="submit" compact loading={loading}>
                {t('employee.hems.onboarding.next')}
              </ButtonPrimary>
            </StFlexRowContainer>
          ) : (
            <FloatingButtonContainer>
              <ButtonPrimary type="submit" loading={loading}>
                {t('employee.hems.onboarding.next')}
              </ButtonPrimary>
            </FloatingButtonContainer>
          )}
        </StContainer>
      )}
    </Formik>
  )
}

const StContainer = styled(Form)`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  /* Compensate for floating button */
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space31};

  border-top: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};

  @media ${breakpoints.desktop} {
    border: none;
    padding: 0;
  }
`

const StFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StTitle = styled(H5)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StInfoContainer = styled.div`
  margin-left: auto;

  @media ${breakpoints.desktop} {
    margin-left: 0;
  }
`

const StInfoText = styled(BodyExtraExtraSmallMedium)`
  color: ${({ theme }) => theme.theme.text.body.white};
  max-width: 250px;
`

const StFlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  position: absolute;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  right: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  align-items: start;

  border-radius: 10px;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
`
