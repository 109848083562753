import { useTranslation } from 'react-i18next'

import { EmployerRoutes } from '../../routing/routes'
import { Navigation } from '../components/navigation/Navigation'
import { PageLayout } from '../components/page-layout'
import { EmployerProfileMenu } from '../employer/profile/components/EmployerProfileMenu'

import type { NavigationOptions } from '../components/navigation/Navigation'
import type { PropsWithChildren } from 'react'

export const AuthenticatedNavigation = ({ children }: PropsWithChildren) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Vars --
  const navigationOptions: NavigationOptions = {
    logo: true,
    navigationItems: [
      { name: t('admin.navigation.home'), url: EmployerRoutes.Root },
      { name: t('admin.navigation.employees'), url: EmployerRoutes.Employees },
      { name: t('admin.navigation.usage'), url: EmployerRoutes.Usage },
      // { name: t('admin.navigation.invoices'), url: EmployerRoutes.Invoices },
      { name: t('admin.navigation.contracts'), url: EmployerRoutes.Contracts },
    ],
    rightItem: <EmployerProfileMenu />,
  }

  return (
    <PageLayout navigation={<Navigation options={navigationOptions} />}>
      {/* Render children via react navigation */}
      {children}
    </PageLayout>
  )
}
