import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../button/ButtonPrimary'
import { ButtonTertiary } from '../button/ButtonTertiary'
import { BodySmallRegular } from '../typography'

import type { ReactNode } from 'react'

type ButtonGroupProps = {
  primaryButtonText?: string
  secondaryButtonText?: string
  onClickPrimaryButton?: () => void
  onClickSecondaryButton?: () => void
  disablePrimaryButton?: boolean
  disableSecondaryButton?: boolean
  buttonGroupHint?: string | ReactNode
}

export const ButtonGroup = ({
  primaryButtonText,
  secondaryButtonText,
  onClickPrimaryButton,
  onClickSecondaryButton,
  disablePrimaryButton = false,
  disableSecondaryButton = false,
  buttonGroupHint,
}: ButtonGroupProps) => {
  const isDesktop = useMediaQuery(breakpoints.desktop)

  if (!primaryButtonText && !secondaryButtonText) {
    // Return empty container if no buttons are provided
    return (
      <StContainer>
        <StButtonGroupContainer $empty />
      </StContainer>
    )
  }

  return (
    <StContainer>
      <StButtonGroupContainer>
        {secondaryButtonText ? (
          <StConfirmContainer onClick={onClickSecondaryButton}>
            <ButtonTertiary
              compact={isDesktop}
              disabled={disableSecondaryButton}
            >
              {secondaryButtonText}
            </ButtonTertiary>
          </StConfirmContainer>
        ) : null}
        {primaryButtonText ? (
          <StConfirmContainer onClick={onClickPrimaryButton}>
            <ButtonPrimary
              type="submit"
              compact={isDesktop}
              disabled={disablePrimaryButton}
            >
              {primaryButtonText}
            </ButtonPrimary>
          </StConfirmContainer>
        ) : null}
      </StButtonGroupContainer>
      {buttonGroupHint && (
        <StHintContainer>
          {typeof buttonGroupHint === 'string' ? (
            <BodySmallRegular>{buttonGroupHint}</BodySmallRegular>
          ) : (
            buttonGroupHint
          )}
        </StHintContainer>
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  justify-content: flex-end;
`

const StButtonGroupContainer = styled.div<{ $empty?: boolean }>`
  margin-top: ${({ theme, $empty }) =>
    $empty ? 0 : theme.UI.SpacingPx.Space8};

  display: flex;
  flex-direction: column-reverse;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  padding-bottom: calc(var(--content-bottom-inset, 0));

  @media ${breakpoints.mobile} {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0;
    padding: 0;
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StConfirmContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StHintContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
