export enum AtomKeys {
  InviteId = 'INVITE_ID',
  OnboardingRedirectedFromDesktop = 'ONBOARDING_ORIGIN',
  ZohoImage = 'ZOHO_IMAGE',
  Navigation = 'NAVIGATION',
  PushToken = 'PUSH_TOKEN',
  Location = 'LOCATION',
  AppSettings = 'APP_SETTINGS',
  AppSettingsLoading = 'APP_SETTINGS_LOADING',
  DisableScroll = 'DISABLE_SCROLL',
}
