import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { BottomSheet } from '../../../components/bottom-sheet/BottomSheet'
import { IconButtonTertiary } from '../../../components/button/IconButton'
import { Drawer, DrawerContent } from '../../../components/general/Drawer'

import { MapFiltersForm } from './MapFiltersForm'

export type MapFiltersType = {
  // maxPrice: number
  speed: 'all' | 'standard' | 'fast'
  onlyAvailable: 'false' | 'true'
}

type MapFiltersProps = {
  onSubmit: (values: MapFiltersType) => void
  values: MapFiltersType
}

export const MapFilters = ({ values, onSubmit }: MapFiltersProps) => {
  // -- State --
  const [open, setOpen] = useState(false)
  const { trackEvent } = useTracking()
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Handlers --
  const handleOpen = () => {
    setOpen(true)
    trackEvent(EventType.Click, 'open_map_filters')
  }

  const handleClose = () => {
    setOpen(false)
    trackEvent(EventType.Close, 'close_map_filters')
  }

  const handleSubmit = (values: MapFiltersType) => {
    onSubmit(values)
    trackEvent(EventType.Submit, 'submit_map_filters')
    setOpen(false)
  }

  // -- Render --
  return (
    <>
      <StIconButton icon={['fasr', 'sliders']} onClick={handleOpen} />
      {isDesktop ? (
        <Drawer open={open}>
          <DrawerContent onClose={handleClose} title={t('map.filters.title')}>
            <MapFiltersForm onSubmit={handleSubmit} values={values} />
          </DrawerContent>
        </Drawer>
      ) : (
        <BottomSheet
          isOpen={open}
          onClose={handleClose}
          title={t('map.filters.title')}
          detent="content-height"
        >
          <StContainer>
            <MapFiltersForm onSubmit={handleSubmit} values={values} />
          </StContainer>
        </BottomSheet>
      )}
    </>
  )
}

const StIconButton = styled(IconButtonTertiary)`
  height: 52px;
  width: 52px;
  min-width: 52px;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  color: ${({ theme }) => theme.theme.colors['nonary-2']};
`

const StContainer = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  display: flex;
  flex-direction: column;
  margin-bottom: var(--content-bottom-inset);
`
