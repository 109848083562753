import { Capacitor } from '@capacitor/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { QRCodeSVG } from 'qrcode.react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { BottomSheet } from '../../../../components/bottom-sheet/BottomSheet'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { Dialog } from '../../../../components/general/Dialog'
import {
  BodyMediumRegular,
  BodyMediumRegularCss,
  BodySmallRegular,
  H6,
  H6Css,
} from '../../../../components/typography'
import dongleImage from '../assets/dongle-flow.jpeg'

import { HemsLostConnectionFlow } from './HemsLostConnectionFlow'

export const HemsLostConnectionCard = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const isNative = Capacitor.isNativePlatform()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- State --
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  // -- Handlers --
  const handleClose = () => setIsSheetOpen(false)
  const handleOpen = () => setIsSheetOpen(true)

  // -- Render --
  return (
    <>
      <StContainer>
        <H6>{t('employee.hems.overview.no-connection.title')}</H6>
        <StTextContainer>
          <BodySmallRegular>
            {t('employee.hems.overview.no-connection.description')}
          </BodySmallRegular>
          <StIconContainer>
            <FontAwesomeIcon icon={['fass', 'wifi-slash']} />
          </StIconContainer>
        </StTextContainer>
        <ButtonPrimary onClick={handleOpen}>
          {t('employee.hems.overview.no-connection.button')}
        </ButtonPrimary>
      </StContainer>

      {isNative ? (
        <BottomSheet
          isOpen={isSheetOpen}
          onClose={handleClose}
          hasBackdrop
          hideHeader
        >
          <HemsLostConnectionFlow onClose={handleClose} />
        </BottomSheet>
      ) : (
        <Dialog
          open={isSheetOpen}
          onOpenChange={setIsSheetOpen}
          title={t('employee.hems.intro-card.web-dialog.title')}
          onClickPrimaryButton={handleClose}
          onClickCloseButton={handleClose}
          primaryButtonText={t('employee.hems.intro-card.web-dialog.button')}
        >
          <StWebDialogContainer>
            <img src={dongleImage} alt="" />
            <StWebDialogTextContainer>
              <StWebDialogSubtitle>
                {t('employee.hems.intro-card.web-dialog.reconnect.subtitle')}
              </StWebDialogSubtitle>
              {isDesktop && (
                <>
                  <BodyMediumRegular>
                    {t(
                      'employee.hems.intro-card.web-dialog.reconnect.description'
                    )}
                  </BodyMediumRegular>
                  <QRCodeSVG value={t('qr.download-app')} size={110} />
                </>
              )}
            </StWebDialogTextContainer>
          </StWebDialogContainer>
        </Dialog>
      )}
    </>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
  background-color: ${({ theme }) => theme.theme.colors['nonary-10']};

  h6 {
    margin: 0;
  }
`

const StTextContainer = styled.div`
  display: grid;
  grid-template-columns: auto ${({ theme }) => theme.UI.SpacingPx.Space13};
  align-items: center;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  p {
    min-width: 0;
  }
`

const StIconContainer = styled.div`
  background-color: ${({ theme }) => theme.theme.colors.white};
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ theme }) => theme.UI.SpacingPx.Space13};
  height: ${({ theme }) => theme.UI.SpacingPx.Space13};
`

const StWebDialogContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  img {
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }

  @media ${breakpoints.desktop} {
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
  }
`

const StWebDialogSubtitle = styled.p`
  margin: 0;
  ${BodyMediumRegularCss}

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5} 0;

  @media ${breakpoints.desktop} {
    padding: 0;
    ${H6Css}
  }
`

const StWebDialogTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`
