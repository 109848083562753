import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { FloatingButtonBlur } from '../../../components/button/FloatingButtonBlur'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

type FloatingContainerButtonProps = {
  onClick?: () => void
  title: string
  icon?: IconProp
  disabled?: boolean
}

export const FloatingContainerButton = ({
  title,
  disabled,
  onClick,
  icon,
}: FloatingContainerButtonProps) => {
  // -- Hooks --
  const isNotMobile = useMediaQuery(breakpoints.mobile)

  // -- Render --
  return (
    <StButtonContainer>
      <ButtonPrimary
        type="submit"
        compact={isNotMobile}
        disabled={disabled}
        onClick={onClick}
        icon={icon}
      >
        {title}
      </ButtonPrimary>
      <FloatingButtonBlur />
    </StButtonContainer>
  )
}

const StButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-bottom: var(--content-bottom-inset);

  z-index: 450;

  button {
    z-index: 10;
  }

  @media ${breakpoints.mobile} {
    background-color: ${({ theme }) => theme.theme.colors.white};
    height: var(--sticky-button-container-height);

    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;

    box-shadow: 0px -4px 24px 0px rgba(76, 79, 86, 0.06);
  }
`
