import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { FloatingButtonContainer } from '../../../components/button/FloatingButtonContainer'
import { BodyMediumRegular, H4 } from '../../../components/typography'
import { UploadSvg } from '../assets/UploadSvg'

type ContinueInTheAppProps = {
  handleConfirm: () => void
}

export const ContinueInTheApp = ({ handleConfirm }: ContinueInTheAppProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Render --
  return (
    <StContainer>
      <StContent>
        <UploadSvg />

        <H4>{t('onboarding.continue-desktop.title')}</H4>

        <BodyMediumRegular>
          {t('onboarding.continue-desktop.body1')}
        </BodyMediumRegular>
        <BodyMediumRegular>
          {t('onboarding.continue-desktop.body2')}
        </BodyMediumRegular>
      </StContent>

      <FloatingButtonContainer>
        <ButtonPrimary onClick={handleConfirm}>
          {t('onboarding.continue-desktop.confirm')}
        </ButtonPrimary>
      </FloatingButtonContainer>
    </StContainer>
  )
}

const StContainer = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
`

const StContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  svg {
    margin: 0 auto;
  }
`
