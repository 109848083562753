import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import {
  ContentContainer,
  StWidthContainer,
} from '../../../components/page-layout'
import { UsageDetails } from '../components/UsageDetails'

export const UsageDetailsScreen = () => {
  // -- Hooks --
  const navigate = useNavigate()
  const { t } = useTranslation()

  // -- Render--
  return (
    <StScrollContainer bareBones>
      <StPageHeader>
        <DetailPageHeader
          title={t('employee.hems.usage-details.title')}
          onBack={() => navigate(EmployeeRoutes.HEMS)}
        />
      </StPageHeader>

      <UsageDetails />
    </StScrollContainer>
  )
}

const StScrollContainer = styled(ContentContainer)`
  flex: auto;
  height: 100dvh;
`

const StPageHeader = styled(StWidthContainer)`
  position: fixed;
  top: var(--inset-top, 0px);
  left: 50%;
  transform: translateX(-50%);

  @media (${breakpoints.desktop}) {
    background-color: ${({ theme }) => theme.theme.colors.white};
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
    top: calc(var(--inset-top, 0px) + var(--navigation-height));
  }
`
