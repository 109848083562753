import * as RadixToggleGroup from '@radix-ui/react-toggle-group'
import { styled } from 'styled-components'

import { BodyMediumRegular, BodySmallSemiBoldCss } from '../typography'

type ToggleGroupProps = {
  label?: string
  options: {
    value: string
    label: string
  }[]
  value: string
  onValueChange: (value: string) => void
}

export const ToggleGroup = ({
  label,
  options,
  onValueChange,
  value,
}: ToggleGroupProps) => {
  return (
    <div>
      {label && <StLabel htmlFor={label}>{label}</StLabel>}
      <StToggleGroup
        id={label}
        className="ToggleGroup"
        type="single"
        value={value}
        onValueChange={onValueChange}
      >
        {options.map((option) => (
          <StToggleGroupItem key={option.value} value={option.value}>
            <BodyMediumRegular>{option.label}</BodyMediumRegular>
          </StToggleGroupItem>
        ))}
      </StToggleGroup>
    </div>
  )
}

const StToggleGroup = styled(RadixToggleGroup.Root)`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StToggleGroupItem = styled(RadixToggleGroup.Item)`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  background-color: ${({ theme }) => theme.theme.colors.white};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space3} ${theme.UI.SpacingPx.Space4}`};

  &[data-state='on'] {
    border: 1px solid
      ${({ theme }) => theme.components['card-select']['hover-border']};
    background-color: ${({ theme }) =>
      theme.components['card-select']['active-bg']};
  }
`

const StLabel = styled.label`
  ${BodySmallSemiBoldCss}

  display: inline-block;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
`
