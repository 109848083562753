import * as Tabs from '@radix-ui/react-tabs'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { Empty } from '../../../components/general/Empty'
import { ContentContainer } from '../../../components/page-layout'
import {
  BodyLargeRegularCss,
  BodyLargeSemiBoldCss,
  H3,
} from '../../../components/typography'
import { useMember } from '../../../member/hooks/useMember'
import { ContractLoader } from '../assets/ContractLoader'
import { HcpContractOverviewItem } from '../components/HcpContractOverviewItem'
import { MspContractOverviewItem } from '../components/MspContractOverviewItem'

import type { HcpContract, MspContract } from '../types/Contract'

enum ContractTabs {
  HCP = 'hcp',
  MSP = 'msp',
}

export const ContractsScreen = () => {
  const { currentMember } = useMember()
  const { t } = useTranslation()

  const [{ data: contracts, loading: loadingContracts, error }] =
    useAuthMemberAxios<{
      hcpContracts: HcpContract[]
      mspContracts: MspContract[]
    }>({
      url: Urls.contractData,
      params: {
        companyId: currentMember.companyId,
      },
    })

  if (loadingContracts) {
    return <ContractLoader />
  }

  if (error) {
    throw new Error('Failed to load HCP contracts')
  }

  return (
    <ContentContainer>
      <H3>{t('employer.contracts.title')}</H3>
      <StTabsContainer defaultValue={ContractTabs.HCP}>
        <StTabsList>
          <StTrigger value={ContractTabs.HCP}>
            {t('employer.contracts.hcp')}
          </StTrigger>
          <StTrigger value={ContractTabs.MSP}>
            {t('employer.contracts.msp')}
          </StTrigger>
        </StTabsList>
        <StContent value={ContractTabs.HCP}>
          {contracts?.hcpContracts?.length ? (
            contracts?.hcpContracts.map((contract) => (
              <HcpContractOverviewItem contract={contract} key={contract.id} />
            ))
          ) : (
            <Empty
              title={t('empty.hcp-contracts.title')}
              description={t('empty.hcp-contracts.description')}
            />
          )}
        </StContent>
        <StContent value={ContractTabs.MSP}>
          {contracts?.mspContracts?.length ? (
            contracts?.mspContracts.map((contract) => (
              <MspContractOverviewItem contract={contract} key={contract.id} />
            ))
          ) : (
            <Empty
              title={t('empty.msp-contracts.title')}
              description={t('empty.msp-contracts.description')}
            />
          )}
        </StContent>
      </StTabsContainer>
    </ContentContainer>
  )
}

const StTabsContainer = styled(Tabs.Root)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space9};
`

const StTabsList = styled(Tabs.List)`
  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space1};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  align-self: flex-start;
`

const StTrigger = styled(Tabs.Trigger)`
  ${BodyLargeRegularCss}
  line-height: 140%;

  color: ${({ theme }) => theme.theme.text.body['gray-dark']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space2} 14px;
  border: none;
  cursor: pointer;
  background-color: transparent;

  &[data-state='active'] {
    ${BodyLargeSemiBoldCss}
    line-height: 140%;

    color: ${({ theme }) => theme.theme.text.body.black};
    background-color: white;
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  }
`

const StContent = styled(Tabs.Content)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
