import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { EmployeeRoutes } from '../../../../../routing/routes'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { HcpStatusType } from '../../../../employer/employees/components/EmployeesDataTable'
import { useUpdateStatus } from '../../../../member/hooks/useUpdateStatus'
import { useUser } from '../../../../user/hooks/useUser'
import analogMeter from '../../assets/analogMeter.png'
import digitalMeter from '../../assets/digitalMeter.png'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { SelectBoxes } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

import type { SelectBoxesItems } from '../../components/SelectBoxes'

export enum DigitalMeterKeys {
  Yes = 'Yes',
  No = 'No',
}

export const DigitalMeterStep = () => {
  const { handleNext, setLoading, values } = useOnboarding()
  const { updateStatus } = useUpdateStatus()
  const { user } = useUser()
  const navigate = useNavigate()

  // -- State --
  const [selectedItem, setSelectedItem] = useState(values.digitalMeterKey)

  // -- Hooks --
  const { t } = useTranslation()

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Vars --
  const items: SelectBoxesItems<DigitalMeterKeys>[] = [
    {
      name: t('onboarding.digital-meter.yes.title'),
      subTitle: t('onboarding.digital-meter.yes.subtitle'),
      image: digitalMeter,
      key: DigitalMeterKeys.Yes,
    },
    {
      name: t('onboarding.digital-meter.no.title'),
      subTitle: t('onboarding.digital-meter.no.subtitle'),
      image: analogMeter,
      key: DigitalMeterKeys.No,
    },
  ]

  // -- Handlers --
  const handleSubmit = async (key: DigitalMeterKeys) => {
    try {
      setSelectedItem(key)
      setLoading(true)

      if (key !== values.digitalMeterKey) {
        await execute({
          data: {
            digitalMeterKey: key,
          },
        })
      }

      const isDigitalMeter = key === DigitalMeterKeys.Yes

      if (user.hcpStatus.hasExistingDevice && !isDigitalMeter) {
        await updateStatus(HcpStatusType.OnboardingCompleted)

        navigate(EmployeeRoutes.Root, { replace: true })
      } else {
        handleNext({ ...values, digitalMeterKey: key }, isDigitalMeter)
      }
    } catch {
      setLoading(false)
      return console.error('Failed to save electricity meter data')
    }
  }

  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.digital-meter.title')}
      </OnboardingStepTitle>

      <SelectBoxes
        items={items}
        onSelect={handleSubmit}
        selectedItem={selectedItem}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  height: 100%;
`
