import { Capacitor } from '@capacitor/core'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { FileOpener } from '@capacitor-community/file-opener'

export const downloadBlob = async (
  data: Blob,
  fileName: string,
  extension: string = '.pdf',
  download: boolean = true,
  mimeType: string = 'application/pdf'
) => {
  if (Capacitor.isNativePlatform()) {
    const dataContent = await blobToBase64(data)

    const file = await Filesystem.writeFile({
      path: fileName + extension,
      directory: Directory.Cache,
      data: dataContent as string,
    })

    FileOpener.open({
      filePath: file.uri,
      contentType: 'application/pdf',
    })
  }

  // Add correct mime type to the blob
  const blob = new Blob([data], { type: mimeType })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url

  if (download) {
    link.setAttribute('download', fileName + extension)
  } else {
    link.target = '_blank'
  }

  document.body.append(link)
  link.click()
}

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(blob)
  })
}
