import { Capacitor } from '@capacitor/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EspProvisioning } from '@general-galactic/capacitor-esp-idf-provisioning'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import {
  BodyMediumSemiBold,
  BodySmallRegular,
  H4,
} from '../../../../components/typography'
import { useIntercom } from '../../../../intercom/hooks/useIntercom'
import dongle from '../assets/connecting-dongle.png'
import { useHemsFlow } from '../hooks/useHemsFlow'
import { connectToDevice, searchDevices } from '../lib/esp-provisioning'
import { isValidMacAddress, uuidToMacAddress } from '../lib/mac-address-helpers'

enum ConnectionState {
  SEARCHING = 'SEARCHING',
  CONNECTING = 'CONNECTING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const DongleConnectionStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { show } = useIntercom()
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.SEARCHING
  )
  const {
    flowNavigation,
    setDeviceName,
    deviceName,
    setMacAddress,
    macAddress,
  } = useHemsFlow()

  // -- Handlers --
  const handleConnect = async () => {
    // Ask for BLE permissions
    if (Capacitor.getPlatform() === 'android') {
      const permissions = await EspProvisioning.requestPermissions()

      if (permissions.ble === 'denied') {
        // TODO what to do here?
        console.error('BLE permission denied')
        return
      }
    }

    // Search for ESP devices
    const devices = await searchDevices()

    if (devices.length === 0) {
      return setConnectionState(ConnectionState.ERROR)
    }

    const firstDeviceName = devices[0].name
    setDeviceName(firstDeviceName)
    const idWithMac: string =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      devices[0].advertisementData?.kCBAdvDataServiceUUIDs[0]
    const mac = idWithMac && uuidToMacAddress(idWithMac)

    // Store the found mac address if it is valid
    if (mac && isValidMacAddress(mac)) {
      setMacAddress(mac)
    }

    // Start connecting
    setConnectionState(ConnectionState.CONNECTING)
    const connected = await connectToDevice(firstDeviceName)

    if (!connected) {
      setConnectionState(ConnectionState.ERROR)
    }

    setConnectionState(ConnectionState.SUCCESS)
  }

  const handleNext = () => {
    flowNavigation.next(!!macAddress)
  }

  // -- Effects --
  useEffect(() => {
    handleConnect()
  }, [])

  // -- Render --
  return (
    <>
      <StTextContainer>
        <H4>
          {t(
            `employee.hems.dongle-onboarding.connecting.${connectionState}.title`
          )}
        </H4>
        <BodySmallRegular>
          {t(
            `employee.hems.dongle-onboarding.connecting.${connectionState}.subtitle`,
            {
              deviceName,
            }
          )}
        </BodySmallRegular>
      </StTextContainer>

      <StImage src={dongle} alt="dongle" />

      {connectionState === ConnectionState.ERROR && (
        <StIconContainer $error>
          <FontAwesomeIcon icon={['fasr', 'xmark']} />
        </StIconContainer>
      )}

      {connectionState === ConnectionState.SUCCESS && (
        <StIconContainer>
          <FontAwesomeIcon icon={['fasr', 'check']} />
        </StIconContainer>
      )}

      <StFloatingContainer>
        {connectionState === ConnectionState.ERROR ? (
          <>
            <ButtonTertiary onClick={show}>
              {t('employee.hems.dongle-onboarding.connecting.ERROR.help')}
            </ButtonTertiary>
            <ButtonPrimary onClick={flowNavigation.previous}>
              {t('employee.hems.dongle-onboarding.connecting.ERROR.try-again')}
            </ButtonPrimary>
          </>
        ) : connectionState === ConnectionState.SUCCESS ? (
          <ButtonPrimary onClick={handleNext}>
            {t('employee.hems.dongle-onboarding.connecting.SUCCESS.button')}
          </ButtonPrimary>
        ) : (
          <>
            <StSpinner />
            <BodyMediumSemiBold>
              {t('employee.hems.dongle-onboarding.connecting.loading')}
            </BodyMediumSemiBold>
          </>
        )}
      </StFloatingContainer>
    </>
  )
}

const StTextContainer = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  flex: 1;

  h4 {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StImage = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: -1;

  width: 100%;
  object-fit: cover;
`

const StFloatingContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  margin-bottom: var(--content-bottom-inset);
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};

  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StSpinner = styled.span`
  width: 18px;
  height: 18px;
  border: 2.5px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const StIconContainer = styled.div<{ $error?: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: ${({ theme, $error }) =>
    $error
      ? theme.components.input.error
      : theme.components.navcircle['completed-bg']};
  color: ${({ theme }) => theme.theme.colors.white};
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space8};

  height: 70px;
  width: 70px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
`
