import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import {
  BodyMediumRegularCss,
  BodySmallRegular,
  H3Css,
  H4,
} from '../../../components/typography'

import type { ReactNode } from 'react'

type InviteExplanationCardProps = {
  title: string
  content: string
  buttonText?: string
  onClick?: () => void
  image: ReactNode
}

export const InviteExplanationCard = ({
  buttonText,
  content,
  title,
  onClick,
  image,
}: InviteExplanationCardProps) => {
  // -- Hooks --
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Render --
  return (
    <StContainer>
      <StCard>{image}</StCard>
      <StCardContent>
        <StTitle>{title}</StTitle>
        <StText>{content}</StText>

        {onClick && isDesktop && (
          <ButtonPrimary
            compact={isDesktop}
            icon={['fass', 'arrow-right']}
            onClick={onClick}
          >
            {buttonText}
          </ButtonPrimary>
        )}
      </StCardContent>
    </StContainer>
  )
}

const StContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.theme.colors.white};

  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

  flex-direction: column;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space17};
  }
`

const StCard = styled.div`
  background-color: ${({ theme }) => theme.theme.colors['secondary-1']};

  height: 320px;
  max-height: 33dvh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.tablet} {
    border-radius: 10px;

    height: 320px;
    width: 435px;
  }
`

const StCardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: 0;
  padding-bottom: var(--sticky-button-container-height);

  @media ${breakpoints.tablet} {
    padding: 0;
  }
`

const StTitle = styled(H4)`
  @media ${breakpoints.desktop} {
    ${H3Css}
  }
`

const StText = styled(BodySmallRegular)`
  @media ${breakpoints.desktop} {
    ${BodyMediumRegularCss}
  }
`
