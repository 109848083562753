import { useTranslation } from 'react-i18next'
import { css, styled } from 'styled-components'

import { onHover } from '../../core/lib/styleHelpers'
import { useIntercom } from '../../intercom/hooks/useIntercom'
import { BodySmallRegular, BodySmallSemiBold } from '../typography'

import { IntercomLogoDark } from './IntercomLogoDark'

type IntercomCardProps = {
  title?: string
  subtitle?: string
}

export const IntercomCard = ({ subtitle, title }: IntercomCardProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const { show } = useIntercom()

  // -- Render --
  return (
    <StCard onClick={show}>
      <StIconContainer>
        <IntercomLogoDark />
      </StIconContainer>

      <div>
        <BodySmallSemiBold>
          {subtitle || t('intercom.card.title')}
        </BodySmallSemiBold>
        <BodySmallRegular>
          {title || t('intercom.card.subtitle')}
        </BodySmallRegular>
      </div>
    </StCard>
  )
}

const StCard = styled.button`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
  flex-direction: row;
  align-items: center;

  height: min-content;

  background-color: ${({ theme }) =>
    theme.components['card-select']['default-bg']};
  border: 1px solid
    ${({ theme }) => theme.components['card-select']['default-border']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};

  text-align: left;
  cursor: pointer;

  p:first-child {
    margin-bottom: 2px;
  }

  ${onHover(
    css`
      border-color: ${({ theme }) =>
        theme.components['card-select']['hover-border']};
    `,
    false
  )}
`

const StIconContainer = styled.div`
  height: ${({ theme }) => theme.UI.SpacingPx.Space14};
  width: ${({ theme }) => theme.UI.SpacingPx.Space14};

  flex-shrink: 0;

  background-color: ${({ theme }) => theme.theme.colors['primary-1']};
  border-radius: 9999px;

  display: flex;
  justify-content: center;
  align-items: center;
`
