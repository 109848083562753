import { useEffect, useMemo } from 'react'
import ContentLoader from 'react-content-loader'
import { useRecoilState } from 'recoil'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../api/urls'
import { zohoImageAtom } from '../../core/recoil/atoms'

export enum ZohoModule {
  File = 'File',
  Products = 'Products',
  Contacts = 'Contacts',
}

type ZohoImageProps = {
  module: ZohoModule
  id: string
  altText?: string
  objectFit?: 'cover' | 'contain' | 'fill'
  forUserId?: string
}

export const ZohoImage = ({
  module,
  id,
  altText,
  objectFit,
  forUserId,
}: ZohoImageProps) => {
  const [zohoImage, setZohoImage] = useRecoilState(zohoImageAtom)

  const [, executeLoadImage] = useAuthMemberAxios(
    {
      url: Urls.ZohoPhoto + `/${module}/${id}`,
      method: 'GET',
      responseType: 'blob',
      params: { forUserId },
    },
    {
      manual: true,
      cache: true,
    }
  )

  const loadImage = async () => {
    const image = await executeLoadImage()

    setZohoImage((previous) => ({
      ...previous,
      [module]: {
        ...previous[module],
        [id]: image.data,
      },
    }))
  }

  useEffect(() => {
    loadImage()
  }, [])

  const image = useMemo(() => {
    const imageStream = zohoImage[module]?.[id]

    if (!imageStream) {
      return null
    }

    return URL.createObjectURL(imageStream)
  }, [id, module, JSON.stringify(zohoImage[module]?.[id])])

  return image ? (
    <StImage src={image} alt={altText} $objectFit={objectFit} />
  ) : (
    <StLoader speed={2} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
    </StLoader>
  )
}

const StImage = styled.img<{ $objectFit?: 'cover' | 'contain' | 'fill' }>`
  width: 100%;
  height: 100%;
  object-fit: ${({ $objectFit }) => $objectFit || 'cover'};
`

const StLoader = styled(ContentLoader)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
