import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Map as MapBox } from 'react-map-gl'
import { css, styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'
import { minutesToHoursAndMinutes } from 'utils/src'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import {
  BodyExtraSmallRegular,
  BodyMediumSemiBoldCss,
  BodySmallRegular,
  BodySmallRegularCss,
  BodySmallSemiBold,
  H5,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { applyVat, formatDecimals } from '../../../core/utils/number'
import { valueToDate } from '../../../translations/utils/date'

import type { ChargingSessionWithPriceLines } from 'types'

type ChargingSessionInfoMspProps = {
  chargingSession: ChargingSessionWithPriceLines
}

export const ChargingSessionInfoMsp = ({
  chargingSession,
}: ChargingSessionInfoMspProps) => {
  const dateFnsLocale = useGetDateFnsLocale()
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  const fullDate = useMemo(() => {
    const startDate = valueToDate(chargingSession.start)
    return format(startDate, 'dd MMMM yyyy', dateFnsLocale)
  }, [chargingSession.start, dateFnsLocale])

  const startHour = useMemo(() => {
    const startDate = valueToDate(chargingSession.start)
    return format(startDate, 'HH:mm', dateFnsLocale)
  }, [chargingSession.start, dateFnsLocale])

  const endHour = useMemo(() => {
    const endDate = valueToDate(chargingSession.end)
    return format(endDate, 'HH:mm', dateFnsLocale)
  }, [chargingSession.end, dateFnsLocale])

  const totalDuration = useMemo(() => {
    return minutesToHoursAndMinutes(chargingSession.duration)
  }, [chargingSession.duration])

  const totalCost = useMemo(() => {
    return formatDecimals(
      applyVat(chargingSession.mspPrice, chargingSession.vat)
    )
  }, [chargingSession.mspPrice, chargingSession.vat])

  // Price breakdown
  const vatPercentage = chargingSession.vat
  const startingPriceLine = chargingSession.chargingSessionLines
    .filter((line) => line.sessionOrigin === 'MSP')
    .find((line) => line.type === 'Start')
  const startingPrice = formatDecimals(
    applyVat(startingPriceLine?.price ?? 0, vatPercentage)
  )
  const hasStartingPrice = startingPrice !== '0'

  const energyPrices = chargingSession.chargingSessionLines.filter(
    (line) => line.sessionOrigin === 'MSP' && line.type === 'Energy'
  )
  const totalEnergyPrice = applyVat(
    energyPrices.reduce((accumulator, price) => accumulator + price.price, 0),
    chargingSession.vat
  )
  const energyPriceText = (() => {
    const initialText = t('map.detail.group.tarrif-kwh', {
      currency: '€ ',
      price: formatDecimals(
        applyVat(energyPrices[0].tariff ?? 0, chargingSession.vat),
        2
      ),
    })

    if (energyPrices[0].price === 0) {
      return (
        <StPriceWrapper>
          <BodySmallSemiBold>
            {t('map.detail.group.charging-price')}
          </BodySmallSemiBold>
          <BodySmallSemiBold>€ 0</BodySmallSemiBold>
        </StPriceWrapper>
      )
    }

    // If no limit return initial price
    if (!energyPrices[0].limit) {
      return (
        <StPriceBlock>
          <StPriceWrapper>
            <BodySmallSemiBold>
              {t('map.detail.group.charging-price')}
            </BodySmallSemiBold>
            <BodySmallSemiBold>
              €&nbsp;{formatDecimals(totalEnergyPrice, 2)}
            </BodySmallSemiBold>
          </StPriceWrapper>
          <StSubPriceWrapper>
            <BodyExtraSmallRegular>
              <StIcon icon={['fass', 'bolt']} />
              {formatDecimals(chargingSession.kwh, 2)} kWh
            </BodyExtraSmallRegular>
            <BodyExtraSmallRegular>{initialText}</BodyExtraSmallRegular>
          </StSubPriceWrapper>
        </StPriceBlock>
      )
    }

    // Otherwise calculate additional prices
    return energyPrices.map((price, index) => {
      const start = index === 0 ? 0 : energyPrices[index - 1].limit
      const text = t('map.detail.group.tarrif-kwh', {
        currency: '€ ',
        price: formatDecimals(applyVat(price.tariff ?? 0, vatPercentage), 2),
      })

      return (
        <StPriceBlock key={index}>
          <StPriceWrapper>
            <BodySmallSemiBold>
              {price.limit
                ? `${t('map.detail.group.charging-price')} (${start}-${
                    price.limit
                  } kWh)`
                : `${t('map.detail.group.charging-price')} (${start}+ kWh)`}
            </BodySmallSemiBold>
            <BodySmallSemiBold>
              €&nbsp;
              {formatDecimals(applyVat(price.price, chargingSession.vat), 2)}
            </BodySmallSemiBold>
          </StPriceWrapper>
          <StSubPriceWrapper>
            <BodyExtraSmallRegular>
              <StIcon icon={['fass', 'bolt']} />
              {price.usage} kWh
            </BodyExtraSmallRegular>
            <BodyExtraSmallRegular>{text}</BodyExtraSmallRegular>
          </StSubPriceWrapper>
        </StPriceBlock>
      )
    })
  })()

  const hourPrices = chargingSession.chargingSessionLines.filter(
    (line) => line.sessionOrigin === 'MSP' && line.type === 'Time'
  )
  const hourPriceText = (() => {
    if (hourPrices[0].price === 0) return ''

    const initialPrice = formatDecimals(
      applyVat(hourPrices[0].price, vatPercentage),
      2
    )

    // If no limit return initial price
    if (!hourPrices[0].limit) {
      return (
        <StPriceWrapper>
          <BodySmallSemiBold>
            {t('map.detail.group.hourly-price')}
          </BodySmallSemiBold>
          <BodySmallSemiBold>{`€ ${initialPrice}`}</BodySmallSemiBold>
        </StPriceWrapper>
      )
    }

    return hourPrices.map((price, index) => {
      const start = (index === 0 ? 0 : hourPrices[index - 1].limit ?? 0) / 60
      const pricePerHour = formatDecimals(
        applyVat(price.tariff ?? 0, vatPercentage),
        2
      )
      const text = t('map.detail.group.tarrif-hour', {
        currency: '€',
        price: pricePerHour,
      })

      const duration = minutesToHoursAndMinutes((price.usage ?? 0) / 60)

      return (
        <StPriceBlock key={index}>
          <StPriceWrapper>
            <BodySmallSemiBold>
              {price.limit
                ? `${t('map.detail.group.hourly-price')} (${start}-${
                    (price.limit ?? 0) / 60
                  } min)`
                : `${t('map.detail.group.hourly-price')} (${start}+ min)`}
            </BodySmallSemiBold>
            <BodySmallSemiBold>
              €&nbsp;
              {formatDecimals(applyVat(price.price, chargingSession.vat), 2)}
            </BodySmallSemiBold>
          </StPriceWrapper>
          <StSubPriceWrapper>
            <BodyExtraSmallRegular>
              <StIcon icon={['fasr', 'clock']} />
              {duration.hours > 0 &&
                `${t('employee.chargingSessions.detail.duration-hours', {
                  hours: duration.hours,
                })} `}
              {t('employee.chargingSessions.detail.duration-minutes', {
                minutes: formatDecimals(duration.minutes, 0),
              })}
            </BodyExtraSmallRegular>
            <BodyExtraSmallRegular>{text}</BodyExtraSmallRegular>
          </StSubPriceWrapper>
        </StPriceBlock>
      )
    })
  })()

  return (
    <>
      {isDesktop ? (
        <StChargingSessionTitle>{fullDate}</StChargingSessionTitle>
      ) : null}
      <StChargingSessionInfo>
        <StChargingSessionLocation>
          <StLocationName>{chargingSession.locationName}</StLocationName>
          <p>{`${chargingSession.address}, ${chargingSession.postcode} ${chargingSession.city}`}</p>
          <StChargingSessionTime>
            <BodySmallRegular>
              {startHour} - {endHour}
            </BodySmallRegular>
            <BodyExtraSmallRegular>
              <StIcon icon={['fasr', 'clock']} />
              {totalDuration.hours > 0 &&
                `${t('employee.chargingSessions.detail.duration-hours', {
                  hours: totalDuration.hours,
                })} `}
              {t('employee.chargingSessions.detail.duration-minutes', {
                minutes: formatDecimals(totalDuration.minutes, 0),
              })}
            </BodyExtraSmallRegular>
          </StChargingSessionTime>
        </StChargingSessionLocation>

        <StPricesWrapper>
          {hasStartingPrice && (
            <StPriceWrapper>
              <BodySmallSemiBold>
                {t('map.detail.group.start-price')}
              </BodySmallSemiBold>
              <BodySmallSemiBold>€&nbsp;{startingPrice}</BodySmallSemiBold>
            </StPriceWrapper>
          )}
          {energyPriceText}
          {hourPriceText}
          <StPriceTotal>
            <StPriceWrapper>
              <BodySmallSemiBold>
                {t('employee.chargingSessions.detail.total')}
              </BodySmallSemiBold>
              <BodySmallSemiBold>€&nbsp;{totalCost}</BodySmallSemiBold>
            </StPriceWrapper>
          </StPriceTotal>
        </StPricesWrapper>
      </StChargingSessionInfo>

      {chargingSession.longitude && chargingSession.latitude && (
        <StMap>
          <MapBox
            longitude={chargingSession.longitude}
            latitude={chargingSession.latitude}
            zoom={15}
            reuseMaps
            dragRotate={false}
            touchPitch={false}
            pitchWithRotate={false}
            mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
            style={{
              width: '100%',
              height: '200px',
            }}
            mapStyle="mapbox://styles/bothrsdev/cloy3aalb013d01qo1geifax9"
            onRender={(event) => {
              event.target.resize()
            }}
            scrollZoom={{ around: 'center' }}
          />

          <StMarkerWrapper>
            <StMarker $selected={true} $clusterSize={4}>
              <FontAwesomeIcon icon={['fass', 'bolt']} fontSize={18} />
            </StMarker>
          </StMarkerWrapper>
        </StMap>
      )}
    </>
  )
}

const StChargingSessionTitle = styled(H5)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};
`

const StChargingSessionInfo = styled.div`
  ${BodySmallRegularCss}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media ${breakpoints.desktop} {
    padding-top: 0;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
  }
`

const StChargingSessionLocation = styled.div`
  ${BodySmallRegularCss}
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StChargingSessionTime = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StLocationName = styled(BodySmallSemiBold)`
  color: ${({ theme }) => theme.theme.text.body.black};
`

const StPriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StSubPriceWrapper = styled(StPriceWrapper)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space3};
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StPricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StPriceTotal = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StMap = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  overflow: hidden;
`

const StMarkerWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;

  // Make sure the pointer is in the middle of the map so zooming doesn't feel off
  transform: translateX(-50%) translateY(-2%);

  pointer-events: none;
`

const StMarker = styled.div<{
  $selected?: boolean
  $clusterSize?: number
}>`
  height: calc(40px + ${({ $clusterSize = 0 }) => $clusterSize}px);
  aspect-ratio: 1 / 1;

  display: flex;
  justify-content: center;
  align-items: center;

  ${BodyMediumSemiBoldCss}

  background-color: ${({ theme, $selected }) =>
    $selected
      ? theme.theme.colors['secondary-1']
      : theme.theme.colors['primary-1']};
  color: ${({ theme, $selected }) =>
    $selected ? theme.theme.colors['primary-1'] : theme.theme.colors.black};

  border-radius: 999px;

  cursor: pointer;

  margin-bottom: 7px;

  ${({ $clusterSize }) =>
    $clusterSize
      ? ''
      : css`
          &::before {
            content: '';

            aspect-ratio: 1 /1;

            position: absolute;
            top: -2px;
            right: -2px;

            width: 12px;
            border-radius: 999px;
            background-color: ${({ theme }) => theme.theme.colors.error};

            box-shadow: 0px 0px 0px 3px #bdc7cc;
          }
        `}

  &::after {
    content: '';

    aspect-ratio: 1 /1;

    position: absolute;
    bottom: 0px;
    z-index: -1;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid
      ${({ theme, $selected }) =>
        $selected
          ? theme.theme.colors['secondary-1']
          : theme.theme.colors['primary-1']};
  }
`
