import { useTranslation } from 'react-i18next'

import example1 from '../../assets/examples/fusebox1.png'
import example2 from '../../assets/examples/fusebox2.png'
import { MobileUploadContent } from '../../components/upload/MobileUploadContent'
import { MobileUploadLoader } from '../../components/upload/MobileUploadLoader'
import { MobileUploadSuccess } from '../../components/upload/MobileUploadSuccess'
import { useImagePolling } from '../../hooks/useImagePolling'
import { useMultiImageUpload } from '../../hooks/useMultiImageUpload'
import { useOnboarding } from '../../hooks/useOnboarding'

import { TOTAL_STEPS } from './ExtraImagesStep'

const CURRENT_STEP = 2
const examples = [example1, example2]

export const FuseBoxImageStep = () => {
  // -- Hooks --
  const { values, handleNext } = useOnboarding()
  const { t } = useTranslation()
  useImagePolling()

  const { loading, handleAddImage, handleRemoveImage } = useMultiImageUpload(
    'fuseBox',
    'fuseBoxImageIds'
  )

  // -- Handlers --
  const handleSubmit = () => {
    handleNext(values)
  }

  // -- Render --
  if (loading) {
    return (
      <MobileUploadLoader currentStep={CURRENT_STEP} totalSteps={TOTAL_STEPS} />
    )
  }

  if (values.fuseBoxImageIds?.length) {
    return (
      <MobileUploadSuccess
        imageIds={values.fuseBoxImageIds}
        currentStep={CURRENT_STEP}
        totalSteps={TOTAL_STEPS}
        title={t('onboarding.fusebox-image.title')}
        onSubmit={handleSubmit}
        onDelete={handleRemoveImage}
        onAddImage={
          values.fuseBoxImageIds.length >= 5 ? undefined : handleAddImage
        }
      />
    )
  }

  return (
    <MobileUploadContent
      uploadPicture={handleAddImage}
      title={t('onboarding.fusebox-image.title')}
      description={t('onboarding.fusebox-image.description')}
      currentStep={CURRENT_STEP}
      totalSteps={TOTAL_STEPS}
      examples={examples}
    />
  )
}
