export const getFileErrorMessage = (code: string) => {
  switch (code) {
    case 'too-many-files': {
      return 'input.upload.error-max-files'
    }

    case 'file-too-large': {
      return 'input.upload.error-max-size'
    }

    case 'file-invalid-type': {
      return 'input.upload.error-file-invalid'
    }

    default: {
      return 'input.upload.error'
    }
  }
}
