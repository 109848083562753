import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { match } from 'ts-pattern'

import AppDutchScreenshot from '../../../components/assets/app_dutch_screenshot.png'
import AppEnglishScreenshot from '../../../components/assets/app_english_screenshot.png'
import AppFrenchScreenshot from '../../../components/assets/app_french_screenshot.png'
import {
  BodySmallRegular,
  BodySmallSemiBold,
} from '../../../components/typography'

export const DownloadAppCta = () => {
  const { t, i18n } = useTranslation()

  const screenshotSrc = match(i18n.language)
    .with('nl', () => AppDutchScreenshot)
    .with('fr', () => AppFrenchScreenshot)
    .otherwise(() => AppEnglishScreenshot)

  // -- Render component --
  return (
    <StDownloadAppCta>
      <StScreenshot alt="Blossom app screenshot" src={screenshotSrc} />
      <StQRCodeContainer>
        <QRCodeSVG
          value={t('qr.download-app')}
          height={'52px'}
          bgColor="#ffffff00"
        />
        <div>
          <BodySmallRegular>
            {t('employee.home.download_the_app.title')}
          </BodySmallRegular>
          <BodySmallSemiBold>
            {t('employee.home.download_the_app.description')}
          </BodySmallSemiBold>
        </div>
      </StQRCodeContainer>
    </StDownloadAppCta>
  )
}

const StDownloadAppCta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  height: 150px;
  width: 100%;

  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StScreenshot = styled.img`
  align-self: flex-end;
  height: 95%;
`

const StQRCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
