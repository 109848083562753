import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { ContentContainer } from '../../../components/page-layout'
import { BodyMediumRegular } from '../../../components/typography'

export const EmployerPrivacyScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Render --
  return (
    <ContentContainer>
      <DetailPageHeader
        title={t('employer.profile.privacy.title')}
        topTitle={t('employer.profile.title')}
      />

      <StLink href={t('profile.privacy.item1.link')} target="_blank">
        <BodyMediumRegular>
          {t('profile.privacy.item1.title')}
        </BodyMediumRegular>
        <FontAwesomeIcon icon={['fasr', 'arrow-up-right-from-square']} />
      </StLink>

      <StLink href={t('profile.privacy.item2.link')} target="_blank">
        <BodyMediumRegular>
          {t('profile.privacy.item2.title')}
        </BodyMediumRegular>
        <FontAwesomeIcon icon={['fasr', 'arrow-up-right-from-square']} />
      </StLink>
    </ContentContainer>
  )
}

const StLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5} 0;

  text-decoration: none;
  color: ${({ theme }) => theme.theme.text.body.black};

  @media ${breakpoints.desktop} {
    width: 50%;
  }
`
