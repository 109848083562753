import ContentLoader from 'react-content-loader'

export const QrCodeSkeletonLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="120px"
      height="120px"
      viewBox="0 0 120 120"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="4" ry="4" width="120" height="120" />
    </ContentLoader>
  )
}
