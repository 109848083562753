import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { ToggleGroup } from '../../../components/form/ToggleGroup'

import type { MapFiltersType } from './MapFilters'

type MapFiltersFormProps = {
  values: MapFiltersType
  onSubmit: (values: MapFiltersType) => void
}

export const MapFiltersForm = ({ onSubmit, values }: MapFiltersFormProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Render --
  return (
    <Formik<MapFiltersType> initialValues={values} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => (
        <StForm>
          <ToggleGroup
            label={t('map.filters.speed.label')}
            onValueChange={(value) => setFieldValue('speed', value)}
            value={values.speed}
            options={[
              {
                label: t('map.filters.speed.all'),
                value: 'all',
              },
              {
                label: t('map.filters.speed.standard'),
                value: 'standard',
              },
              {
                label: t('map.filters.speed.fast'),
                value: 'fast',
              },
            ]}
          />

          <ToggleGroup
            label={t('map.filters.availability.label')}
            onValueChange={(value) => setFieldValue('onlyAvailable', value)}
            value={values.onlyAvailable}
            options={[
              {
                label: t('map.filters.availability.all'),
                value: 'false',
              },
              {
                label: t('map.filters.availability.only-available'),
                value: 'true',
              },
            ]}
          />
          {/* <Slider
                  label={t('map.filters.price.label')}
                  valueLabel={t('map.filters.price.value-label', {
                    price: formatDecimals(values.maxPrice / 100),
                  })}
                  min={0}
                  max={5000}
                  step={5}
                  value={values.maxPrice}
                  onValueChange={(value) => setFieldValue('maxPrice', value)}
                /> */}

          <StButton type="submit">{t('map.filters.submit')}</StButton>
        </StForm>
      )}
    </Formik>
  )
}

const StForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StButton = styled(ButtonPrimary)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
