import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { Navigation } from '../navigation/Navigation'

import { Loader } from './Loader'

import type { NavigationOptions } from '../navigation/Navigation'

type FullPageLoaderProps = {
  withNavigation?: boolean
}

export const FullPageLoader = ({
  withNavigation = true,
}: FullPageLoaderProps) => {
  const isDesktop = useMediaQuery(breakpoints.desktop)

  return (
    <StContainer key="loader" $withNavigation={withNavigation}>
      {isDesktop && withNavigation ? (
        <Navigation options={[] as NavigationOptions} />
      ) : null}
      <StPlayerContainer>
        <Loader />
      </StPlayerContainer>
    </StContainer>
  )
}

const StContainer = styled.div<{ $withNavigation: boolean }>`
  flex: 1;
  height: ${({ $withNavigation }) =>
    $withNavigation ? '100vh' : 'calc(100vh - var(--navigation-height))'};
  display: flex;
  flex-direction: column;
`

const StPlayerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
