import type { ChargingSessionStatistics } from '../usage/types/types'

export const statisticsChargingTypeData = (
  statistics?: ChargingSessionStatistics | null
) => {
  if (!statistics)
    return {
      homeUsagePercentage: 0,
      publicUsagePercentage: 0,
      workUsagePercentage: 0,
    }

  if (
    !statistics.totalUsagePerType?.Home &&
    !statistics.totalUsagePerType?.Public &&
    !statistics.totalUsagePerType?.Work
  )
    return {
      homeUsagePercentage: 100 / 3,
      publicUsagePercentage: 100 / 3,
      workUsagePercentage: 100 / 3,
    }

  const homeUsage = statistics.totalUsagePerType?.Home
    ? (statistics.totalUsagePerType.Home / statistics.totalUsage) * 100
    : 0
  const publicUsage = statistics.totalUsagePerType?.Public
    ? (statistics.totalUsagePerType.Public / statistics.totalUsage) * 100
    : 0
  const workUsage = statistics.totalUsagePerType?.Work
    ? (statistics.totalUsagePerType.Work / statistics.totalUsage) * 100
    : 0

  return {
    homeUsagePercentage: homeUsage,
    publicUsagePercentage: publicUsage,
    workUsagePercentage: workUsage,
  }
}

// Thanks to copilot
export const formatPowerConsumption = (powerConsumption: number) => {
  const units = ['kWh', 'MWh', 'GWh', 'TWh']
  let unitIndex = 0

  while (powerConsumption >= 1000 && unitIndex < units.length - 1) {
    powerConsumption /= 1000
    unitIndex++
  }

  return `${powerConsumption.toFixed(3)} ${units[unitIndex]}`
}
