import { detectDevice } from './detectDevice'

export const goToStore = () => {
  const device = detectDevice()

  if (device === 'ios') {
    window.open(import.meta.env.VITE_IOS_STORE_URL)
  } else if (device === 'android') {
    window.open(import.meta.env.VITE_ANDROID_STORE_URL)
  }
}
