import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'

import { Dialog } from './Dialog'
import { InfoButton } from './InfoButton'

import type { PropsWithChildren } from 'react'

type InfoDialogProps = {
  title: string
} & PropsWithChildren

export function InfoDialog({ title, children }: InfoDialogProps) {
  // -- State --
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  return (
    <StContainer>
      <InfoButton onClick={() => setOpen(true)} />
      <Dialog
        title={title}
        open={open}
        onOpenChange={setOpen}
        onClickCloseButton={() => setOpen(false)}
        onClickPrimaryButton={() => setOpen(false)}
        disableSecondaryButton
        primaryButtonText={isDesktop ? t('common.close') : undefined}
      >
        {children}
      </Dialog>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: grid;
  place-items: center;
`
