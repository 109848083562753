import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'
import { minutesToHoursAndMinutes } from 'utils/src'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import {
  BodyExtraSmallRegular,
  BodySmallRegular,
  BodySmallRegularCss,
  BodySmallSemiBold,
  H5,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { formatDecimals } from '../../../core/utils/number'
import { useMember } from '../../../member/hooks/useMember'
import { valueToDate } from '../../../translations/utils/date'
import { useUser } from '../../../user/hooks/useUser'

import { ReimbursementStatusChip } from './ReimbursementStatusChip'

import type { ChargingSessionWithRelations } from 'types'

type ChargingSessionInfoHcpProps = {
  chargingSession: ChargingSessionWithRelations
}

export const ChargingSessionInfoHcp = ({
  chargingSession,
}: ChargingSessionInfoHcpProps) => {
  const dateFnsLocale = useGetDateFnsLocale()
  const { t } = useTranslation()
  const { currentMember } = useMember()
  const { user } = useUser()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  const startDate = valueToDate(chargingSession.start)
  const startHour = format(startDate, 'HH:mm', dateFnsLocale)
  const endDate = valueToDate(chargingSession.end)
  const endHour = format(endDate, 'HH:mm', dateFnsLocale)

  const duration = minutesToHoursAndMinutes(chargingSession.duration)

  const fullStartDate = format(startDate, 'dd MMMM yyyy', dateFnsLocale)

  const userName =
    chargingSession.mspCard?.memberId === currentMember.id
      ? `${user.firstName} ${user.lastName}`
      : t('employee.chargingSessions.guest')

  const handleRefundsClick = useCallback(() => {
    navigate('/refunds', {
      state: {
        backTo: location.pathname,
      },
    })
  }, [navigate])

  const energyPrices = chargingSession.chargingSessionLines.filter(
    (line) => line.sessionOrigin === 'HCP' && line.type === 'Energy'
  )
  const totalEnergyPrice = energyPrices.reduce(
    (accumulator, price) => accumulator + price.price,
    0
  )
  const energyPriceText = (() => {
    const initialText = t('map.detail.group.tarrif-kwh', {
      currency: '€ ',
      price: formatDecimals(
        energyPrices[0].tariff ??
          chargingSession.hcpPrice /
            (chargingSession.kwh === 0 ? 1 : chargingSession.kwh),
        2
      ),
    })

    if (energyPrices[0].price === 0) {
      return (
        <StPriceWrapper>
          <BodySmallSemiBold>
            {t('map.detail.group.charging-price')}
          </BodySmallSemiBold>
          <BodySmallSemiBold>{initialText}</BodySmallSemiBold>
        </StPriceWrapper>
      )
    }

    // We can expect that there is no energy limit for hcp sessions
    return (
      <StPriceBlock>
        <StPriceWrapper>
          <BodySmallSemiBold>
            {t('map.detail.group.charging-price')}
          </BodySmallSemiBold>
          <BodySmallSemiBold>
            €&nbsp;{formatDecimals(totalEnergyPrice, 2)}
          </BodySmallSemiBold>
        </StPriceWrapper>
        <StSubPriceWrapper>
          <BodyExtraSmallRegular>
            <StIcon icon={['fass', 'bolt']} />
            {formatDecimals(chargingSession.kwh, 2)} kWh
          </BodyExtraSmallRegular>
          <BodyExtraSmallRegular>{initialText}</BodyExtraSmallRegular>
        </StSubPriceWrapper>
      </StPriceBlock>
    )
  })()

  return (
    <>
      {isDesktop ? (
        <StChargingSessionTitle>{fullStartDate}</StChargingSessionTitle>
      ) : null}
      <StChargingSessionInfo>
        <StChargingSessionRow>
          <BodySmallSemiBold>{userName}</BodySmallSemiBold>
          <ReimbursementStatusChip
            chargingSession={chargingSession}
            member={currentMember}
          />
        </StChargingSessionRow>
        <StChargingSessionRow>
          <StData>
            {startHour} - {endHour}
          </StData>
          <StDuration>
            <StIcon icon={['fasr', 'clock']} />
            {duration.hours > 0 &&
              `${t('employee.chargingSessions.detail.duration-hours', {
                hours: duration.hours,
              })} `}
            {t('employee.chargingSessions.detail.duration-minutes', {
              minutes: formatDecimals(duration.minutes, 0),
            })}
          </StDuration>
        </StChargingSessionRow>

        <StPriceBreakdown>{energyPriceText}</StPriceBreakdown>
      </StChargingSessionInfo>
      <StChargingSessionAction>
        <StToRefundsButton
          icon={['fasr', 'arrow-right']}
          onClick={handleRefundsClick}
        >
          {t('employee.chargingSessions.detail.refunds-overview')}
        </StToRefundsButton>
      </StChargingSessionAction>
    </>
  )
}

const StChargingSessionTitle = styled(H5)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};
`

const StChargingSessionInfo = styled.div`
  ${BodySmallRegularCss}
  display: flex;
  flex-direction: column;
  gap: 6px;

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space4};

  media ${breakpoints.desktop} {
    padding-top: 0;
  }
`

const StChargingSessionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StChargingSessionAction = styled.div`
  ${BodySmallRegularCss}
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StToRefundsButton = styled(ButtonTertiary)`
  justify-content: space-between;
`

const StData = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StDuration = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space3};
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StPriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StSubPriceWrapper = styled(StPriceWrapper)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StPriceBreakdown = styled.div`
  // Total margin of 24px
  margin-top: 18px;
`
