import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { styled } from 'styled-components'
import { type UserWithMembers } from 'types'

import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { EmployerRoutes } from '../../../../routing/routes'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { FilterTypes } from '../../../components/filters/Filters'
import { Banner, BannerType } from '../../../components/general/Banner'
import { ContentContainer } from '../../../components/page-layout'
import { StatusChip } from '../../../components/status-chip/StatusChip'
import { BodyMediumSemiBoldCss, H4 } from '../../../components/typography'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'
import { isUrgent } from '../../../quotes/lib/quote'

import type { Filter } from '../../../components/filters/Filters'
import type { Column } from '../../../dataTable/components/DataTable'

export enum HcpStatusType {
  Registered = 'Registered',
  Invited = 'Invited',
  Onboarding = 'Onboarding',
  OnboardingCompleted = 'OnboardingCompleted',
  OnboardingRejected = 'OnboardingRejected',
  PreSiteVisitPlanned = 'PreSiteVisitPlanned',
  ExportedForMigration = 'ExportedForMigration',
  QuotationPendingApproval = 'QuotationPendingApproval',
  QuotationApproved = 'QuotationApproved',
  QuotationApprovedByEmployer = 'QuotationApprovedByEmployer',
  QuotationApprovedByEmployee = 'QuotationApprovedByEmployee',
  InstallationPlanned = 'InstallationPlanned',
  Configured = 'Configured',
  CertificationPlanned = 'CertificationPlanned',
  Operational = 'Operational',
  Inapplicable = 'Inapplicable',
}

export enum MspStatusType {
  Registered = 'Registered',
  Invited = 'Invited',
  Requested = 'Requested',
  Exported = 'Exported',
  Sent = 'Sent',
  Active = 'Active',
  Inapplicable = 'Inapplicable',
}

export const EmployeesDataTable = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { state } = useLocation()
  const { dataCount } = useDataTable()

  // Data
  const hcpStatusOptions = useMemo(() => {
    const values: { label: string; values: string[] }[] = []

    Object.values(HcpStatusType).forEach((status) => {
      const translation = t(`hcp.status.${status}`)

      const index = values.findIndex((value) => value.label === translation)

      if (index < 0) {
        values.push({ label: translation, values: [status] })
      } else {
        values[index].values.push(status)
      }
    })

    return values.map((value) => ({
      key: JSON.stringify(value.values),
      label: value.label,
    }))
  }, [])

  const mspStatusOptions = useMemo(() => {
    const values: { label: string; values: string[] }[] = []

    Object.values(MspStatusType).forEach((status) => {
      const translation = t(`msp.status.${status}`)

      const index = values.findIndex((value) => value.label === translation)

      if (index < 0) {
        values.push({ label: translation, values: [status] })
      } else {
        values[index].values.push(status)
      }
    })

    return values.map((value) => ({
      key: JSON.stringify(value.values),
      label: value.label,
    }))
  }, [])

  const columns = useMemo<Column<UserWithMembers>[]>(
    () => [
      {
        key: 'fullName',
        label: t('admin.employees.table.name'),
        type: ColumnType.STRING,
        sortable: true,
      },
      {
        key: 'workEmail',
        label: t('admin.employees.table.email'),
        type: ColumnType.STRING,
        sortable: true,
      },
      {
        key: 'hcpStatus.status',
        label: t('admin.employees.table.hcp-status'),
        type: ColumnType.CHIP,
        sortable: true,
        transformData: (data) => {
          if (!data.hcpStatus) {
            return
          }

          return (
            <StatusChip
              urgent={isUrgent(data.hcpStatus)}
              label={t(`hcp.status.${data.hcpStatus.status}`)}
            />
          )
        },
      },
      {
        key: 'mspStatus.status',
        label: t('admin.employees.table.msp-status'),
        type: ColumnType.CHIP,
        // We cannot sort on a 1 to many relationship
        sortable: false,
        transformData: (data) => {
          if (!data.members?.[0].mspStatus) {
            return
          }

          return (
            <StatusChip
              label={t(`msp.status.${data.members[0].mspStatus}`)}
              urgent={false}
            />
          )
        },
      },
    ],
    [t]
  )

  const filters = useMemo<Filter[]>(
    () => [
      {
        key: 'hcpStatus',
        placeholder: t('admin.employees.table.hcp-status'),
        type: FilterTypes.Multi,
        options: hcpStatusOptions,
      },
      {
        key: 'mspStatus',
        placeholder: t('admin.employees.table.msp-status'),
        type: FilterTypes.Multi,
        options: mspStatusOptions,
      },
      {
        key: 'warning',
        type: FilterTypes.Toggle,
      },
    ],
    [hcpStatusOptions, mspStatusOptions, t]
  )

  // -- Render --
  return (
    <ContentContainer>
      <StContainer>
        <StHeader>
          <StTitle>
            {t('admin.employees.title')}
            <StTitleBadge>
              {dataCount.itemCount &&
              dataCount.itemCount !== dataCount.filteredItemCount
                ? `${dataCount.filteredItemCount ?? 0}/${dataCount.itemCount}`
                : dataCount.itemCount ?? ''}
            </StTitleBadge>
          </StTitle>
          <NavLink to={EmployerRoutes.InviteEmployees}>
            <ButtonPrimary
              icon={['fass', 'plus']}
              iconAlignment="left"
              size="sm"
            >
              {t('admin.employees.invite-employees')}
            </ButtonPrimary>
          </NavLink>
        </StHeader>
        {state?.alerts?.inviteEmployees && (
          <Banner type={BannerType.INFORMATION} icon={['fass', 'info-square']}>
            {t('admin.invite-employees.information.success')}
          </Banner>
        )}
        <DataTable<UserWithMembers>
          columns={columns}
          filters={filters}
          hrefGenerator={(dataRow) =>
            replaceParametersInPath(EmployerRoutes.EmployeeDetail, {
              blossomId: dataRow.blossomId!,
            })
          }
          emptyFiltersTitle={t('admin.employees.table.empty-filters-title')}
          emptyTitle={t('admin.employees.table.empty-title')}
          emptyFiltersDescription={t(
            'admin.employees.table.empty-filters-description'
          )}
          emptyDescription={t('admin.employees.table.empty-description')}
        />
      </StContainer>
    </ContentContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: 70px;
`

const StTitleBadge = styled.span`
  ${BodyMediumSemiBoldCss}
  background-color: ${({ theme }) => theme.theme.colors['primary-1']};
  border-radius: 999px;
  padding: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space3}`};

  font-size: 24px;

  height: ${({ theme }) => theme.UI.SpacingPx.Space10};
  min-width: ${({ theme }) => theme.UI.SpacingPx.Space10};

  display: grid;
  place-items: center;
`

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StTitle = styled(H4)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
