import { Capacitor } from '@capacitor/core'
import { styled } from 'styled-components'

import { useAppInfo } from '../core/hooks/useAppInfo'
import { capitalizeFirstLetter } from '../core/lib/capitalizeFirstLetter'

export const EnvironmentBanner = () => {
  const appInfo = useAppInfo()

  const env = import.meta.env.VITE_ENV

  if (!Capacitor.isNativePlatform()) return null
  if (env !== 'development' && env !== 'staging') return null

  return (
    <StBanner $env={env}>
      {appInfo && (
        <>
          <p>{`${capitalizeFirstLetter(env)}`}</p>
          <p>{`v ${appInfo.version} - ${appInfo.build}`}</p>
        </>
      )}
    </StBanner>
  )
}

const StBanner = styled.div<{ $env: string }>`
  width: 100%;
  height: 15px;
  z-index: 9999999;
  background-color: ${({ $env }) =>
    $env === 'staging' ? '#DCF730' : '#12292F'};
  opacity: 0.7;

  position: fixed;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 45px;

  p {
    font-family: 'F37 Jagger';
    font-size: 11px;
    margin-top: -2px;

    color: ${({ $env }) => ($env === 'staging' ? 'black' : 'white')};
  }
`
