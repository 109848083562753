import { addMonths, endOfMonth, format } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { Chip } from '../../../components/general/Chip'
import { Drawer, DrawerContent } from '../../../components/general/Drawer'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { formatDecimals } from '../../../core/utils/number'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { formatPowerConsumption } from '../../../employer/utils/statistics'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'

import { ChargingSessionInfoHcp } from './ChargingSessionInfoHcp'
import handHoldingEuro from './handHoldingEuro.svg'

import type { Column } from '../../../dataTable/components/DataTable'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ChargingSessionWithRelations } from 'types'

export const UsageDataTableHcp = () => {
  const { t } = useTranslation()
  const { currentMember } = useMember()
  const { user } = useUser()
  const theme = useTheme()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const dateFnsLocale = useGetDateFnsLocale()
  const [selectedChargingSession, setSelectedChargingSession] = useState<
    ChargingSessionWithRelations | undefined
  >(undefined)

  const columns = useMemo<Column<ChargingSessionWithRelations>[]>(
    () => [
      {
        key: 'start',
        label: t('employee.chargingSessions.table.date'),
        type: ColumnType.DATE,
        sortable: true,
      },
      {
        key: 'memberId',
        label: t('employee.chargingSessions.table.name'),
        type: ColumnType.STRING,
        transformData: (data: ChargingSessionWithRelations) => {
          return data.mspCard?.memberId === currentMember.id
            ? `${user.firstName} ${user.lastName}`
            : t('employee.chargingSessions.guest')
        },
      },
      {
        key: 'hcpPrice',
        label: t('employee.chargingSessions.table.cost'),
        prepend: '€',
        type: ColumnType.NUMBER,
        decimal: 2,
        sortable: true,
        transformData: (data: ChargingSessionWithRelations) => {
          return formatDecimals(data.hcpPrice)
        },
      },
      {
        key: 'kwh',
        label: t('employee.chargingSessions.table.charged'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (data: ChargingSessionWithRelations) => {
          return formatPowerConsumption(data.kwh ?? 0)
        },
      },
      {
        key: 'status',
        label: t('employee.chargingSessions.table.status'),
        type: ColumnType.STRING,
        transformData: (session) => {
          const isOwnSession = session.mspCard?.memberId === currentMember.id
          const payedBack = isOwnSession
            ? ['paid', 'paid_in_advance'].includes(
                session.debitNote?.paymentStatus ?? ''
              )
            : session.hostInvoice?.isPaid
          const processed = isOwnSession
            ? session.debitNote?.isProcessed
            : session.hostInvoice?.isProcessed

          const chipBackgroundColor = payedBack
            ? theme.theme.colors['tertiary-6']
            : processed
            ? theme.theme.colors['septenary-4']
            : theme.theme.colors['nonary-9']

          const chipColor = payedBack
            ? theme.theme.colors['tertiary-3']
            : processed
            ? theme.theme.colors['septenary-1']
            : theme.theme.colors['nonary-3']

          const chipFaIcon: IconProp | undefined = payedBack
            ? ['fasr', 'check']
            : processed
            ? ['fasr', 'clock']
            : undefined

          const chipSvgIcon: string | undefined =
            !payedBack && !processed ? handHoldingEuro : undefined

          const paybackDate = format(
            endOfMonth(addMonths(new Date(session.end), 1)),
            'd LLLL',
            { locale: dateFnsLocale.locale }
          )

          const chipText = payedBack
            ? t('employee.chargingSessions.paid')
            : processed
            ? t('employee.chargingSessions.processed')
            : t('employee.chargingSessions.not-processed', {
                date: paybackDate,
              })

          return (
            <Chip
              backgroudColor={chipBackgroundColor}
              color={chipColor}
              icon={chipFaIcon}
              iconSrc={chipSvgIcon}
              borderRadius="square"
            >
              {chipText}
            </Chip>
          )
        },
      },
    ],
    [
      t,
      currentMember.id,
      user.firstName,
      user.lastName,
      theme.theme.colors,
      dateFnsLocale.locale,
    ]
  )

  const handleRowClick = useCallback(
    (dataRow: ChargingSessionWithRelations) => {
      setIsDrawerOpen(true)
      setSelectedChargingSession(dataRow)
    },
    []
  )

  const handleClose = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  return (
    <>
      <DataTable<ChargingSessionWithRelations>
        columns={columns}
        onRowClick={handleRowClick}
        emptyTitle={t('employee.chargingSessions.table.empty-title')}
        emptyDescription={t(
          'employee.chargingSessions.table.empty-description'
        )}
        emptyFiltersTitle=""
        emptyFiltersDescription=""
      />
      <Drawer open={isDrawerOpen}>
        <DrawerContent onClose={handleClose}>
          {selectedChargingSession && (
            <ChargingSessionInfoHcp chargingSession={selectedChargingSession} />
          )}
        </DrawerContent>
      </Drawer>
    </>
  )
}
