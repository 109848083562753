import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { styled } from 'styled-components'

import { replaceParametersInPath } from '../../../routing/lib/replaceIdInPath'
import { UnauthenticatedRoutes } from '../../../routing/routes'
import logo from '../assets/vectors/logo.svg'
import { ButtonPrimary } from '../button/ButtonPrimary'
import { BodyMediumRegular, H4 } from '../typography'

export const LoginErrorScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParameters] = useSearchParams()

  const inviteId = searchParameters.get('inviteId')

  // -- Vars --
  const triedToRegister = !!inviteId

  // -- Handlers --
  const handleClick = () => {
    if (triedToRegister) {
      navigate(
        replaceParametersInPath(UnauthenticatedRoutes.Invite, { inviteId })
      )
      return
    }

    navigate(UnauthenticatedRoutes.Root)
  }

  // -- Render --
  return (
    <StContainer>
      <StContent>
        <img src={logo} alt="Blossom Logo" />
        <div>
          <H4>
            {triedToRegister
              ? t('register-error-screen.title')
              : t('login-error-screen.title')}
          </H4>
          <BodyMediumRegular>
            {triedToRegister
              ? t('register-error-screen.subtitle')
              : t('login-error-screen.subtitle')}
          </BodyMediumRegular>
        </div>
        <ButtonPrimary onClick={handleClick}>
          {triedToRegister
            ? t('register-error-screen.button')
            : t('login-error-screen.button')}
        </ButtonPrimary>
      </StContent>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  height: 100dvh;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  max-width: 400px;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

  h4 {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }

  img {
    height: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`
