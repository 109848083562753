import { useAuth0 } from '@auth0/auth0-react'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { usePostHog } from 'posthog-js/react'
import { useTranslation } from 'react-i18next'

import { LocalStorageKeys } from '../../../config/constants/local-storage-keys'
import { useIntercom } from '../../intercom/hooks/useIntercom'

import type {
  Auth0ContextInterface,
  LogoutOptions,
  RedirectLoginOptions,
  User,
} from '@auth0/auth0-react'

/** Same as the useAuth0 hook but with altered login and logout logic */
export const useAuthentication = (): Auth0ContextInterface<User> & {
  refreshUser: () => Promise<void>
} => {
  const auth0 = useAuth0()
  const intercom = useIntercom()
  const posthog = usePostHog()
  const { i18n } = useTranslation()
  const loginWithRedirect = async (
    options: RedirectLoginOptions | undefined
  ) => {
    // Custom browser behavior on native platforms
    const extraOptions: RedirectLoginOptions | undefined =
      Capacitor.isNativePlatform()
        ? {
            authorizationParams: {
              ...options?.authorizationParams,
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
              ui_locales: i18n.language,
            },
            async openUrl(url: string) {
              // Redirect using Capacitor's Browser plugin
              await Browser.open({
                url,
              })
            },
          }
        : {
            authorizationParams: {
              ...options?.authorizationParams,
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
              ui_locales: i18n.language,
            },
          }
    await auth0.loginWithRedirect({ ...options, ...extraOptions })
  }

  const logout = async (options: LogoutOptions | undefined) => {
    // Custom browser behavior on native platforms
    const extraOptions: LogoutOptions = Capacitor.isNativePlatform()
      ? {
          logoutParams: {
            returnTo: `${import.meta.env.VITE_APP_ID}://${
              import.meta.env.VITE_AUTH0_DOMAIN
            }/capacitor/${import.meta.env.VITE_APP_ID}`,
            ...options?.logoutParams,
          },
          async openUrl(url) {
            // Redirect using Capacitor's Browser plugin
            await Browser.open({
              url,
            })
          },
        }
      : {
          logoutParams: {
            returnTo: window.location.origin,
            ...options?.logoutParams,
          },
        }

    // Unidentify tracking requests after logout
    posthog.reset()
    intercom.shutdown()

    // Clear localstorage
    const language = localStorage.getItem(LocalStorageKeys.SELECTED_LANGUAGE)
    localStorage.clear()

    if (language) {
      localStorage.setItem(LocalStorageKeys.SELECTED_LANGUAGE, language)
    }

    if (Capacitor.isNativePlatform()) {
      PushNotifications.unregister()
    }

    if (Capacitor.getPlatform() === 'ios') {
      try {
        // Close the browser in case there still is a browser running in the background
        // This is to fix a bug on IOS when the user tries to login with apple or google while not having registered yet
        await Browser.close()
      } catch {
        console.error('No active browser window open')
      }
    }

    await auth0.logout({ ...options, ...extraOptions })
  }

  const refreshUser = async () => {
    // We need to force the update to be in the next render cycle.
    // We have no explanation why it doesn't work without the setTimeout :)
    setTimeout(() => {
      auth0.getAccessTokenSilently({
        detailedResponse: true,
        cacheMode: 'off',
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        },
      })
    }, 0)
  }

  return { ...auth0, loginWithRedirect, logout, refreshUser }
}
