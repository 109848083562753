import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import {
  BodyExtraExtraSmallRegular,
  BodyExtraSmallSemiBoldCss,
} from '../../../components/typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

type UsageDetailsInfoCardProps = {
  value: string | number
  suffix: string
  color?: string
  label: string
  selected?: boolean | null
  disabled?: boolean | null
  empty?: boolean
  icon?: IconProp
}

export const UsageDetailsInfoCard = ({
  value,
  suffix,
  color,
  label,
  selected,
  disabled,
  empty = false,
  icon,
}: UsageDetailsInfoCardProps) => {
  // -- Render --
  return (
    <StChartInfoCard $selected={selected} $disabled={disabled}>
      {empty ? (
        <div>
          <FontAwesomeIcon icon={['fass', 'bolt-slash']} />
        </div>
      ) : (
        <BodyExtraExtraSmallRegular>
          <StBold>{value}</StBold>
          &nbsp;{suffix}
        </BodyExtraExtraSmallRegular>
      )}
      <StLabel>
        {color && (
          <StDotContainer>
            {icon ? (
              <FontAwesomeIcon icon={icon} color={color} fontSize={6} />
            ) : (
              <StDot $color={color} />
            )}
          </StDotContainer>
        )}
        <BodyExtraExtraSmallRegular>{label}</BodyExtraExtraSmallRegular>
      </StLabel>
    </StChartInfoCard>
  )
}

const StChartInfoCard = styled.div<{
  $selected?: boolean | null
  $disabled?: boolean | null
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-grow: 1;
  flex-basis: 0;

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space5}`};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  background-color: ${({ theme, $selected }) =>
    $selected
      ? theme.theme.colors['primary-3']
      : theme.theme.colors['nonary-10']};

  opacity: ${({ $disabled, $selected }) =>
    !$selected && $disabled ? 0.25 : 1};
`

const StLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`

const StDotContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  height: 100%;

  align-items: center;
`

const StDot = styled.span<{ $color: string }>`
  display: inline-block;

  width: 5px;
  height: 5px;

  background-color: ${({ $color }) => $color};
  border-radius: 50%;
`

const StBold = styled.span`
  ${BodyExtraSmallSemiBoldCss}
`
