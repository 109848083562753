import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'

export const takePicture = async (title: string) => {
  const image = await Camera.getPhoto({
    quality: 80,
    allowEditing: false,
    resultType: CameraResultType.DataUrl,
    promptLabelHeader: title,
    webUseInput: true,
    source: CameraSource.Prompt,
  })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const response = await fetch(image.dataUrl!)
  const ImageBlob = await response.blob()

  // Names need to be chronological so we can filter on name because Zoho returns the images in a random order
  const randomName = Date.now().toString()

  return new File([ImageBlob], `${randomName}.${image.format}`, {
    type: ImageBlob.type,
  })
}
