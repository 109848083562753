import ContentLoader from 'react-content-loader'
import { styled } from 'styled-components'

import { breakpoints } from '../../../../theme/layout/breakpoints'

export function HemsLoader() {
  return (
    <StGrid>
      <StColumn>
        <ContentLoader
          speed={2}
          width="100%"
          height={129}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="2" rx="8" ry="8" width="67.22%" height="24" />
          <rect x="0" y="37" rx="8" ry="8" width="52.0%" height="17" />
          <rect x="0" y="62" rx="8" ry="8" width="64.24%" height="17" />
          <rect x="0" y="87" rx="8" ry="8" width="48.68%" height="17" />
          <rect x="0" y="112" rx="8" ry="8" width="45.70%" height="17" />
          <rect x="82.78%" y="77" rx="26" ry="26" width="52" height="52" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width="100%"
          height={186}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="8" ry="8" width="45%" height="24" />
          <rect x="6%" y="54" rx="8" ry="8" width="94%" height="74" />
          <rect x="6%" y="144" rx="8" ry="8" width="90%" height="17" />
          <rect x="6%" y="169" rx="8" ry="8" width="75%" height="17" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width="100%"
          height={186}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="8" ry="8" width="45%" height="24" />
          <rect x="6%" y="54" rx="8" ry="8" width="94%" height="74" />
          <rect x="6%" y="144" rx="8" ry="8" width="90%" height="17" />
          <rect x="6%" y="169" rx="8" ry="8" width="75%" height="17" />
        </ContentLoader>
      </StColumn>
      <StColumn>
        <ContentLoader
          speed={2}
          width="100%"
          height={213}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="8" ry="8" width="45%" height="24" />
          <rect x="6.21%" y="48" rx="8" ry="8" width="45.03%" height="101" />
          <rect x="6.21%" y="48" rx="8" ry="8" width="45.03%" height="101" />
          <rect x="54.97%" y="48" rx="8" ry="8" width="45.03%" height="101" />
          <rect x="54.97%" y="48" rx="8" ry="8" width="45.03%" height="101" />
          <rect x="6.21%" y="161" rx="4" ry="4" width="93.79%" height="52" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width="100%"
          height={186}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="8" ry="8" width="45%" height="24" />
          <rect x="6%" y="54" rx="8" ry="8" width="94%" height="74" />
          <rect x="6%" y="144" rx="8" ry="8" width="90%" height="17" />
          <rect x="6%" y="169" rx="8" ry="8" width="75%" height="17" />
        </ContentLoader>
      </StColumn>
    </StGrid>
  )
}

const StGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  max-height: calc(100dvh - 204px);
  overflow-y: hidden;

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    column-gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
    grid-template-columns: repeat(2, 1fr);

    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
    padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`
