import { CompositeLayer } from '@deck.gl/core'
import { IconLayer } from '@deck.gl/layers'
import Supercluster from 'supercluster'

import { chargingPointIsOpen } from '../lib/chargingPointIsOpen'

import type { ChargerGeoDataFeatures } from '../types/mapTypes'
import type { ChangeFlags, PickingInfo } from '@deck.gl/core'
import type { Feature, Properties } from '@turf/turf'
import type { ClusterProperties } from 'supercluster'

type Props = {
  data: ChargerGeoDataFeatures[]
  pickable: boolean
  sizeScale: number
  iconAtlas: string
  iconMapping: string
  selectedId: number
}

export function getIconName(
  size: number,
  available?: boolean,
  selected?: boolean
) {
  if (size === 0) return ''

  if (size === 1) {
    return available
      ? selected
        ? 'marker-1-active-selected'
        : 'marker-1-active'
      : selected
      ? 'marker-1-closed-selected'
      : 'marker-1-closed'
  }
  if (size <= 10) {
    return `marker-${size}`
  }
  if (size < 50) {
    return `marker-more-10`
  }
  if (size < 100) {
    return `marker-more-50`
  }
  if (size < 200) {
    return `marker-more-100`
  }
  if (size < 500) {
    return `marker-more-200`
  }
  if (size < 1000) {
    return `marker-more-500`
  }
  return 'marker-1000'
}

function getIconSize(size: number) {
  return Math.min(100, size) / 100 + 1
}

export class IconClusterLayer extends CompositeLayer<Props> {
  shouldUpdateState({ changeFlags }: { changeFlags: ChangeFlags }) {
    return changeFlags.somethingChanged
  }

  // When props changes, what needs to happen?
  updateState({
    props,
    oldProps,
    changeFlags,
  }: {
    props: Props
    oldProps: Props
    changeFlags: ChangeFlags
  }) {
    const rebuildIndex =
      changeFlags.dataChanged ||
      props.sizeScale !== oldProps.sizeScale ||
      props.selectedId !== oldProps.selectedId

    // If data or bbox changed, recalculate clusters
    if (rebuildIndex) {
      const index = new Supercluster({
        maxZoom: 16,
        radius: props.sizeScale * Math.sqrt(2),
      })
      index.load(props.data)
      this.setState({ index })
    }

    const z = Math.floor(this.context.viewport.zoom)
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  getPickingInfo({
    info,
  }: {
    info: PickingInfo<
      ChargerGeoDataFeatures | ClusterProperties,
      { expansionZoom: number; objects: Properties[] }
    >
  }) {
    const pickedObject =
      info.object && (info.object as ChargerGeoDataFeatures).properties
    if (pickedObject) {
      if (pickedObject.cluster) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((feature: Feature) => feature.properties)
      }
      info.object = pickedObject

      if ((info.object as ClusterProperties)?.cluster) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        info.expansionZoom = this.state.index.getClusterExpansionZoom(
          (info.object as ClusterProperties).cluster_id
        )
      }
    }
    return info
  }

  renderLayers() {
    const { data } = this.state
    const { iconAtlas, iconMapping, sizeScale, selectedId } = this.props

    return new IconLayer(
      this.getSubLayerProps({
        id: 'icon',
        data,
        iconAtlas,
        iconMapping,
        sizeScale,
        getPosition: (object: ChargerGeoDataFeatures) =>
          object.geometry.coordinates,
        getIcon: (object: ChargerGeoDataFeatures) =>
          getIconName(
            object.properties.cluster ? object.properties.point_count : 1,
            object.properties.status === 'available' &&
              chargingPointIsOpen(object.properties.opening_times),
            object.properties.id === selectedId
          ),
        getSize: (object: ChargerGeoDataFeatures) =>
          getIconSize(
            object.properties.cluster ? object.properties.point_count : 1
          ),
      })
    )
  }
}
