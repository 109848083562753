import { Capacitor } from '@capacitor/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { styled, useTheme } from 'styled-components'

import { minutesToHoursAndMinutes } from '../../../../../../../packages/utils/src/time/minutesToHoursAndMinutes'
import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import { Chip } from '../../../components/general/Chip'
import {
  BodyExtraSmallRegular,
  BodyExtraSmallSemiBold,
  BodySmallRegularCss,
  H6,
} from '../../../components/typography'
import { useDownloadZohoFile } from '../../../core/hooks/useDownloadZohoFile'
import { formatDecimals } from '../../../core/utils/number'
import { DataList } from '../../../dataTable/components/DataList'
import { ColumnType } from '../../../dataTable/components/DataTable'
import {
  DataTableProvider,
  PaginationType,
} from '../../../dataTable/providers/DataTableProvider'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'

import type { Column } from '../../../dataTable/components/DataTable'
import type { Reimbursement } from 'database'
import type { ChargingSessionWithMsp } from 'types'

type ReimbursementInfoProps = {
  reimbursement: Reimbursement
}

export const ReimbursementInfo = ({
  reimbursement,
}: ReimbursementInfoProps) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { trackEvent } = useTracking()
  const theme = useTheme()
  const { currentMember } = useMember()

  // Input a space every 4 characters
  const formattedIban = user.iban ? user.iban.match(/.{1,4}/g)?.join(' ') : ''

  // State
  const [downloadingReimbursement, setDownloadReimbursement] = useState(false)

  // Actions
  const [, fetchReimbursementFileId] = useAuthMemberAxios(
    {
      url: Urls.fetchReimbursementFileId,
      method: 'GET',
    },
    { manual: true }
  )

  const { download, loading } = useDownloadZohoFile()

  // -- Vars --
  const hcpColumns = useMemo<Column<ChargingSessionWithMsp>[]>(
    () => [
      {
        key: 'name',
        component: BodyExtraSmallSemiBold,
        type: ColumnType.STRING,
        transformData: (session) => {
          return session.mspCard?.memberId === currentMember.id
            ? `${user.firstName} ${user.lastName}`
            : t('employee.chargingSessions.guest')
        },
      },
      {
        key: 'hcpPrice',
        prepend: '€ ',
        component: BodyExtraSmallSemiBold,
        type: ColumnType.STRING,
      },
      {
        key: 'kwh',
        type: ColumnType.STRING,
        component: StLightText,
        transformData: (session) => {
          return (
            <>
              <StIcon icon={['fass', 'bolt']} />
              {session.kwh.toFixed(2)}
              {' kWh'}
            </>
          )
        },
      },
      {
        key: 'duration',
        type: ColumnType.STRING,
        component: StLightText,
        transformData: (session) => {
          const duration = minutesToHoursAndMinutes(session.duration)

          return (
            <>
              <StIcon icon={['fasr', 'clock']} />
              {duration.hours > 0 &&
                `${t('employee.chargingSessions.detail.duration-hours', {
                  hours: duration.hours,
                })} `}
              {t('employee.chargingSessions.detail.duration-minutes', {
                minutes: duration.minutes,
              })}
            </>
          )
        },
      },
    ],
    [t]
  )

  // -- Handlers --
  const handleDownloadReimbursement = useCallback(
    async (reimbursement: Reimbursement) => {
      try {
        trackEvent(EventType.Click, 'download_reimbursement', {
          id: reimbursement.id,
        })
        setDownloadReimbursement(true)
        const response = await fetchReimbursementFileId({
          params: {
            reimbursementId: reimbursement.id,
          },
          method: 'GET',
        })

        // Save file
        download(
          `${t('employee.reimbursement.file')}_${format(
            new Date(reimbursement.date),
            'yyyy-MM-dd_HH'
          )}.pdf`,
          response.data,
          !Capacitor.isNativePlatform()
        )
      } catch {
        toast.error(t('employee.reimbursement.download.error'))
      }

      setDownloadReimbursement(false)
    },
    [fetchReimbursementFileId, t, trackEvent]
  )

  return (
    <StReimbursementInfoContainer>
      <StHeader>
        <StReimbursementInfo>
          <StReimbursementRow>
            <p>€{formatDecimals(reimbursement.price)}</p>
            <Chip
              size="small"
              icon={
                reimbursement.isPaid ? ['fasr', 'check'] : ['fasr', 'clock']
              }
              backgroudColor={
                reimbursement.isPaid
                  ? theme.theme.colors['tertiary-6']
                  : theme.theme.colors['septenary-4']
              }
            >
              {reimbursement.isPaid
                ? t('employee.reimbursement.status-paid-out')
                : t('employee.reimbursement.status-processing')}
            </Chip>
          </StReimbursementRow>
          <StReimbursementRow>
            <p>{t(`employee.reimbursement.${reimbursement.type}`)}</p>
            <p>{formattedIban}</p>
          </StReimbursementRow>
        </StReimbursementInfo>

        <StDownloadButton
          icon={['fasr', 'file']}
          iconAlignment="left"
          size="md"
          onClick={() => handleDownloadReimbursement(reimbursement)}
          loading={downloadingReimbursement || loading}
        >
          {t('employee.reimbursement.download-details')}
        </StDownloadButton>
      </StHeader>

      <StSessions>
        <H6>{t('employee.chargingSessions.title')}</H6>
        <DataTableProvider
          paginationType={PaginationType.None}
          url={replaceParametersInPath(Urls.reimbursementLines, {
            id: reimbursement.id,
          })}
        >
          <DataList<ChargingSessionWithMsp>
            columns={hcpColumns}
            emptyTitle={t('employee.reimbursement.detail.error')}
            emptyFiltersTitle={''}
          />
        </DataTableProvider>
      </StSessions>
    </StReimbursementInfoContainer>
  )
}

const StReimbursementInfoContainer = styled.div`
  media ${breakpoints.desktop} {
    padding-top: 0;
  }
`

const StReimbursementInfo = styled.div`
  ${BodySmallRegularCss}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StReimbursementRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StDownloadButton = styled(ButtonTertiary)`
  background-color: ${({ theme }) => theme.theme.colors['nonary-10']};
`

const StHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space8};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space7};

  @media ${breakpoints.desktop} {
    background-color: ${({ theme }) => theme.theme.colors['nonary-10']};
  }
`

const StSessions = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  background-color: ${({ theme }) => theme.theme.colors.white};

  h6 {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  }
`

const StIcon = styled(FontAwesomeIcon)`
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StLightText = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
