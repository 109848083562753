import { styled } from 'styled-components'

import { BodyExtraSmallRegular, BodySmallRegular } from '../../typography'

import { createMapboxAddressString } from './createAddressString'

import type { MapboxSuggestion } from './types'

type AddressSelectProps = {
  address: MapboxSuggestion
  onClick: () => void
}

export const AddressSelect = ({ address, onClick }: AddressSelectProps) => {
  const name = address.name
  const country = address.context.country?.name || ''

  const addressString = createMapboxAddressString(address.context)
  const simplifiedAddressString = address.name
  const hasAddress = addressString.trim().length > 0

  return (
    <StButton onClick={onClick}>
      <StSimplifiedAddress>{simplifiedAddressString}</StSimplifiedAddress>
      <StFullAddress>
        {hasAddress
          ? address.feature_type === 'country'
            ? addressString
            : addressString + ', ' + country
          : name}
      </StFullAddress>
    </StButton>
  )
}

const StButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space5}`};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  }
`

const StSimplifiedAddress = styled(BodySmallRegular)`
  text-transform: capitalize;
`

const StFullAddress = styled(BodyExtraSmallRegular)`
  display: flex;
  flex-direction: row;
  text-transform: capitalize;

  color: ${({ theme }) => theme.theme.colors['nonary-3']};
`
