import { useContext } from 'react'

import { dataTableContext } from '../providers/DataTableProvider'

import type { DataTableContext } from '../providers/DataTableProvider'
import type { Context } from 'react'

export const useDataTable = <Statistics extends Record<string, unknown>>() => {
  const context = useContext(
    dataTableContext as Context<DataTableContext<Statistics>>
  )

  if (!context) {
    throw new Error('useDataTable needs to be used inside a DataTableProvider')
  }

  return context
}
