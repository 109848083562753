export const detectDevice = () => {
  const userAgent = navigator.userAgent

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios'
  }
  // Android detection
  else if (/android/i.test(userAgent)) {
    return 'android'
  }
  // Other device
  else {
    return 'other'
  }
}
