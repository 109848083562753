import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css, styled } from 'styled-components'

import {
  BodyExtraExtraSmallRegular,
  BodyExtraExtraSmallSemiBold,
} from '../../../../components/typography'

import type { ChargeMode } from './ChargeModeSwitcher'

export function ChargeModeSwitcherMobile({
  value,
  options,
  disabled = false,
  onChange,
}: ChargeModeSwitcherMobileProps) {
  return (
    <StHorizontalModeSelector>
      {options.map((chargeMode) => (
        <StSelectOption
          key={chargeMode.value}
          onClick={() => {
            if (disabled) {
              return
            }

            onChange?.(chargeMode)
          }}
          $disabled={disabled}
          $selected={value.value === chargeMode.value}
        >
          <StChargeModeSymbol
            $color={
              value.value === chargeMode.value ? chargeMode.color : undefined
            }
            $large
          >
            <FontAwesomeIcon icon={chargeMode.icon} fontSize={18} />
          </StChargeModeSymbol>
          <StChargeModeText>
            {value.value === chargeMode.value ? (
              <BodyExtraExtraSmallSemiBold>
                {chargeMode.label}
              </BodyExtraExtraSmallSemiBold>
            ) : (
              <BodyExtraExtraSmallRegular>
                {chargeMode.label}
              </BodyExtraExtraSmallRegular>
            )}
          </StChargeModeText>
        </StSelectOption>
      ))}
    </StHorizontalModeSelector>
  )
}
type ChargeModeSwitcherMobileProps = {
  options: ChargeMode[]
  value: ChargeMode
  disabled?: boolean
  onChange?: (value: ChargeMode) => void
}

const StHorizontalModeSelector = styled.div`
  display: flex;
  justify-content: space-between;
`

const StSelectOption = styled.div<{
  $disabled: boolean
  $selected: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  text-align: center;

  ${({ $disabled, $selected }) =>
    $disabled &&
    css`
      opacity: ${$selected ? 0.5 : 1};
    `}
`

const StChargeModeSymbol = styled.div<{ $color?: string; $large?: boolean }>`
  width: ${({ theme, $large }) =>
    $large ? theme.UI.SpacingPx.Space13 : theme.UI.SpacingPx.Space9};
  height: ${({ theme, $large }) =>
    $large ? theme.UI.SpacingPx.Space13 : theme.UI.SpacingPx.Space9};
  border-radius: 50%;

  background-color: ${({ $color }) => $color ?? 'transparent'};
  border: ${({ theme, $color }) =>
    $color ? 'none' : `1px solid ${theme.theme.colors['nonary-7']}`};

  display: grid;
  place-content: center;
`

const StChargeModeText = styled.div`
  display: flex;
  flex-direction: column;
`
