import { Capacitor } from '@capacitor/core'
import { Intercom } from '@capacitor-community/intercom'
import { useEffect, type PropsWithChildren } from 'react'
import { IntercomProvider as WebIntercomProvider } from 'react-use-intercom'

export const IntercomProvider = ({ children }: PropsWithChildren) => {
  // -- Effects --
  useEffect(() => {
    const asyncFunction = async () => {
      if (Capacitor.isNativePlatform()) {
        await Intercom.loadWithKeys({
          apiKeyAndroid: import.meta.env.VITE_INTERCOM_ANDROID_API_KEY,
          apiKeyIOS: import.meta.env.VITE_INTERCOM_IOS_API_KEY,
          appId: import.meta.env.VITE_INTERCOM_APP_ID,
        })
      }
    }

    asyncFunction()
  }, [])

  // -- Render --
  return (
    <WebIntercomProvider
      appId={import.meta.env.VITE_INTERCOM_APP_ID}
      apiBase="https://api-iam.eu.intercom.io"
      shouldInitialize={!Capacitor.isNativePlatform()}
    >
      {children}
    </WebIntercomProvider>
  )
}
