import { createContext } from 'react'

import type { HemsConfigutrationData } from '../hooks/useHemsOnboardingState'
import type { Dispatch, SetStateAction } from 'react'

export type HemsConfigurationContext = {
  hemsConfigurationData: HemsConfigutrationData
  setHemsConfigurationData: Dispatch<SetStateAction<HemsConfigutrationData>>
  configureHems: (data: HemsConfigutrationData) => Promise<void>
}

export const hemsConfigurationContext = createContext<
  HemsConfigurationContext | undefined
>(undefined)
