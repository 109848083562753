import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'

import {
  BodyLargeSemiBold,
  BodyMediumRegular,
} from '../../../components/typography'
import { formatDecimals } from '../../../core/utils/number'
import { useHems } from '../hooks/useHems'

import { EnergyConsumptionCard } from './EnergyConsumptionStatisticCard'

export function EnergyTotalConsumptionCard() {
  const { energyConsumption, energyConsumptionLoading } = useHems()

  return (
    <EnergyConsumptionCard
      icon={<FontAwesomeIcon icon={['fass', 'bolt']} fontSize={16} />}
      description={t('employee.hems.usage.electricity.total-consumption')}
      isLoading={energyConsumptionLoading}
    >
      {energyConsumption ? (
        <BodyMediumRegular>
          <BodyLargeSemiBold as="strong">
            {formatDecimals(energyConsumption.importedEnergyWh / 1000)}
          </BodyLargeSemiBold>{' '}
          {t('employee.hems.usage.electricity.kWh')}
        </BodyMediumRegular>
      ) : (
        '-'
      )}
    </EnergyConsumptionCard>
  )
}
