import { Form, Formik, type FormikProps } from 'formik'
import { useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'
import * as Yup from 'yup'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { Checkbox } from '../../../components/checkbox/Checkbox'
import { Input } from '../../../components/form/Input'
import { Select } from '../../../components/form/Select'
import { FormCardHeader } from '../../../components/form-card/FormCardHeader'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { FullPageLoader } from '../../../components/general/FullPageLoader'
import { InfoPopup } from '../../../components/info-popup/InfoPopup'
import {
  BodyExtraExtraSmallMedium,
  BodyMediumRegularCss,
} from '../../../components/typography'
import { shallowEqual } from '../../../core/lib/shallowEqual'
import { useHems } from '../../hems/hooks/useHems'
import { ElectricityContract } from '../../hems/types/enums'

export const EmployeeSmartChargingSettings = () => {
  // -- State --
  const [editable, setEditable] = useState<boolean>(false)

  // -- Hooks --
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const {
    installation,
    installationLoading,
    handleUpdateInstallation,
    updateInstallationLoading,
  } = useHems()
  const { trackEvent } = useTracking()

  const formRef = useRef<
    FormikProps<{
      electricity_contract: ElectricityContract
      elek_import_price: number
      elek_export_price: number
      pv_installed: boolean
      bat_installed: boolean
      hp_installed: boolean
      hpb_installed: boolean
      ac_installed: boolean
      peak_solar_capacity: number
    }>
  >(null)

  const initialValues = {
    electricity_contract:
      installation?.electricity_contract || ElectricityContract.variable,
    elek_import_price: installation?.elek_import_price || 0,
    elek_export_price: installation?.elek_export_price || 0,
    pv_installed: installation?.pv_installed || false,
    bat_installed: installation?.bat_installed || false,
    hp_installed: installation?.hp_installed || false,
    hpb_installed: installation?.hpb_installed || false,
    ac_installed: installation?.ac_installed || false,
    peak_solar_capacity: installation?.peak_solar_capacity || 0,
  }

  const validationSchema = Yup.object().shape({
    elek_import_price: Yup.number()
      .required(
        t('employee.settings.smart-charging.elek-import-price-required')
      )
      .min(0, t('employee.settings.smart-charging.elek-import-price-min')),
    elek_export_price: Yup.number()
      .required(
        t('employee.settings.smart-charging.elek-export-price-required')
      )
      .min(0, t('employee.settings.smart-charging.elek-export-price-min')),
    peak_solar_capacity: Yup.number()
      .required(
        t('employee.settings.smart-charging.peak-solar-capacity-required')
      )
      .min(0, t('employee.settings.smart-charging.peak-solar-capacity-min'))
      .max(50000),
  })

  // -- Handlers --
  const handleOpen = () => {
    setEditable(true)
    trackEvent(EventType.Click, `edit_smart_charging_settings`)
  }

  const handleClose = () => {
    setEditable(false)
    formRef.current?.resetForm()
    trackEvent(EventType.Click, `cancel_edit_smart_charging_settings`)
  }

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await handleUpdateInstallation(values)

      setEditable(false)
      trackEvent(EventType.Submit, `update_smart_charging_settings`)

      formRef.current?.resetForm({
        values: values,
      })
    } catch {
      toast.error(t('employee.settings.smart-charging.error'))
    }
  }

  // -- Render --

  if (installationLoading) {
    return <FullPageLoader />
  }

  return (
    <StContainer>
      {!isDesktop && (
        <DetailPageHeader
          title={t('employee.settings.smart-charging.title')}
          onBack={
            isDesktop
              ? undefined
              : state?.backTo
              ? () => navigate(state.backTo)
              : () => navigate(EmployeeRoutes.Settings)
          }
          actions={
            editable
              ? [
                  {
                    name: 'edit',
                    icon: ['fasr', 'xmark-large'],
                    onClick: () => {
                      handleClose()
                    },
                  },
                ]
              : [
                  {
                    name: 'edit',
                    icon: ['fasr', 'pen'],
                    onClick: () => {
                      handleOpen()
                    },
                  },
                ]
          }
        />
      )}

      {/* Settings Form */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={formRef}
        enableReinitialize
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          handleBlur,
          setFieldValue,
          resetForm,
        }) => (
          <>
            <StForm>
              {isDesktop && (
                <FormCardHeader
                  disableSubmit={
                    shallowEqual(initialValues, values) || installationLoading
                  }
                  disableCancel={installationLoading}
                  handleSetEditable={handleOpen}
                  editable={editable}
                  handleClose={() => {
                    resetForm()
                    handleClose()
                  }}
                  title={t('employee.settings.smart-charging.form-title')}
                />
              )}

              <Select
                label={t('employee.settings.smart-charging.contract-type')}
                value={values.electricity_contract}
                onChange={(value) =>
                  setFieldValue('electricity_contract', value)
                }
                options={[
                  {
                    key: ElectricityContract.variable,
                    label: t('employee.hems.onboarding.contract-step.variable'),
                  },
                  {
                    key: ElectricityContract.dynamic,
                    label: t('employee.hems.onboarding.contract-step.dynamic'),
                  },
                  {
                    key: ElectricityContract.fixed,
                    label: t('employee.hems.onboarding.contract-step.fixed'),
                  },
                ]}
                readOnly={!editable}
                disabled={updateInstallationLoading}
              />

              {values.electricity_contract !== ElectricityContract.dynamic && (
                <Input
                  name="elek_import_price"
                  label={t('employee.hems.onboarding.tarrif-step.import-label')}
                  valueLabel={t(
                    'employee.hems.onboarding.tarrif-step.import-value'
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.elek_import_price}
                  readOnly={!editable}
                  disabled={updateInstallationLoading}
                  error={errors.elek_import_price}
                  touched={touched.elek_import_price}
                  hint={
                    <StInfoContainer>
                      <InfoPopup
                        content={
                          <StInfoText>
                            {t(
                              'employee.hems.onboarding.tarrif-step.import-info'
                            )}
                          </StInfoText>
                        }
                      />
                    </StInfoContainer>
                  }
                />
              )}

              {values?.pv_installed &&
                values.electricity_contract !== ElectricityContract.dynamic && (
                  <Input
                    name="elek_export_price"
                    label={t(
                      'employee.hems.onboarding.tarrif-step.export-label'
                    )}
                    valueLabel={t(
                      'employee.hems.onboarding.tarrif-step.export-value'
                    )}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.elek_export_price}
                    readOnly={!editable}
                    disabled={updateInstallationLoading}
                    error={errors.elek_export_price}
                    touched={touched.elek_export_price}
                    hint={
                      <StInfoContainer>
                        <InfoPopup
                          content={
                            <StInfoText>
                              {t(
                                'employee.hems.onboarding.tarrif-step.export-info'
                              )}
                            </StInfoText>
                          }
                        />
                      </StInfoContainer>
                    }
                  />
                )}

              {values.pv_installed && (
                <Input
                  name="peak_solar_capacity"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.peak_solar_capacity}
                  label={t('employee.settings.smart-charging.solar-peak')}
                  valueLabel={t(
                    'employee.hems.onboarding.installations-step.va'
                  )}
                  readOnly={!editable}
                  disabled={updateInstallationLoading}
                  error={errors.peak_solar_capacity}
                  touched={touched.peak_solar_capacity}
                  hint={
                    <StInfoContainer>
                      <InfoPopup
                        content={
                          <StInfoText>
                            {t(
                              'employee.hems.onboarding.installations-step.solar-peak-explanation'
                            )}
                          </StInfoText>
                        }
                      />
                    </StInfoContainer>
                  }
                />
              )}

              <StInstallations>
                <StInstallationsLabel>
                  {t('employee.settings.smart-charging.installations')}
                </StInstallationsLabel>
                <Checkbox
                  name="solar"
                  checked={values.pv_installed}
                  onChange={(checked) => setFieldValue('pv_installed', checked)}
                  label={t('employee.hems.onboarding.installations-step.solar')}
                  readOnly={!editable}
                  disabled={updateInstallationLoading}
                />
                <Checkbox
                  name="batery"
                  checked={values.bat_installed}
                  onChange={(checked) =>
                    setFieldValue('bat_installed', checked)
                  }
                  label={t(
                    'employee.hems.onboarding.installations-step.battery'
                  )}
                  readOnly={!editable}
                  disabled={updateInstallationLoading}
                />
                <Checkbox
                  name="heatpump"
                  checked={values.hp_installed}
                  onChange={(checked) => setFieldValue('hp_installed', checked)}
                  label={t(
                    'employee.hems.onboarding.installations-step.heat-pump'
                  )}
                  readOnly={!editable}
                  disabled={updateInstallationLoading}
                />
                <Checkbox
                  name="heatpumpboiler"
                  checked={values.hpb_installed}
                  onChange={(checked) =>
                    setFieldValue('hpb_installed', checked)
                  }
                  label={t(
                    'employee.hems.onboarding.installations-step.heat-pump-boiler'
                  )}
                  readOnly={!editable}
                  disabled={updateInstallationLoading}
                />
                <Checkbox
                  name="airco"
                  checked={values.ac_installed}
                  onChange={(checked) => setFieldValue('ac_installed', checked)}
                  label={t('employee.hems.onboarding.installations-step.airco')}
                  readOnly={!editable}
                  disabled={updateInstallationLoading}
                />
              </StInstallations>

              {editable && !isDesktop && (
                <StMobileSubmitButton
                  compact
                  type="submit"
                  disabled={
                    shallowEqual(initialValues, values) ||
                    updateInstallationLoading
                  }
                >
                  {t('employee.settings.smart-charging.save')}
                </StMobileSubmitButton>
              )}
            </StForm>
          </>
        )}
      </Formik>
    </StContainer>
  )
}

const StContainer = styled.div`
  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space6} 0;
  }
`

const StForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  position: relative;

  /* Extra padding for button height + gap height */
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space24};

  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
    grid-template-columns: 1fr 1fr;

    border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StMobileSubmitButton = styled(ButtonPrimary)`
  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
  position: fixed;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StInfoContainer = styled.div`
  margin-left: auto;

  @media ${breakpoints.desktop} {
    margin-left: 0;
  }
`

const StInfoText = styled(BodyExtraExtraSmallMedium)`
  color: ${({ theme }) => theme.theme.text.body.white};
  max-width: 250px;
`

const StInstallations = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  @media ${breakpoints.desktop} {
    grid-column: span 2;

    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const StInstallationsLabel = styled.label`
  ${BodyMediumRegularCss}

  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  display: block;

  @media ${breakpoints.desktop} {
    grid-column: span 2;
  }
`
