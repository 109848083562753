import { useTranslation } from 'react-i18next'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

import { UnauthenticatedRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { useAuthentication } from '../../../authentication/hooks/useAuthentication'
import { FullPageLoader } from '../../../components/general/FullPageLoader'
import { Wizard, type CurrentStep } from '../../../components/navigation/Wizard'
import {
  hcpOnboardingWizardConfig,
  simplifiedOnboardingWizardConfig,
} from '../../onboarding/config/wizardConfig'
import { InviteScreenSteps } from '../components/InviteScreenSteps'
import { useGetInvite } from '../hooks/useGetInvite'

export enum OnboardingInviteSteps {
  Welcome = 'welcome',
  Intercom = 'intercom',
  CloseExplanation = 'close',
}

type InviteStepsConfigOption = {
  next: (alternative?: boolean) => OnboardingInviteSteps
  previous: () => OnboardingInviteSteps
}

const inviteStepsConfig: Record<
  OnboardingInviteSteps,
  InviteStepsConfigOption
> = {
  [OnboardingInviteSteps.Welcome]: {
    next: () => OnboardingInviteSteps.Intercom,
    previous: () => OnboardingInviteSteps.Welcome,
  },
  [OnboardingInviteSteps.Intercom]: {
    next: () => OnboardingInviteSteps.CloseExplanation,
    previous: () => OnboardingInviteSteps.Welcome,
  },
  [OnboardingInviteSteps.CloseExplanation]: {
    next: () => OnboardingInviteSteps.CloseExplanation,
    previous: () => OnboardingInviteSteps.Intercom,
  },
}

export const UnauthenticatedInviteScreen = () => {
  // -- State --
  const [searchParameters, setSearchParameters] = useSearchParams()

  // -- Hooks --
  const { loginWithRedirect } = useAuthentication()
  const { inviteId } = useParams()
  const [{ loading, error, data }] = useGetInvite(inviteId || '')
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { i18n } = useTranslation()

  const currentStep =
    (searchParameters.get('step') as OnboardingInviteSteps) || 'welcome'

  // -- Handlers --
  const handleRegister = () => {
    loginWithRedirect({
      appState: { inviteId },
      authorizationParams: {
        prompt: 'login',
        screen_hint: 'signup',
        ui_locales: i18n.language,
      },
    })
  }

  const handleNext = () => {
    if (
      currentStep === OnboardingInviteSteps.CloseExplanation ||
      (currentStep === OnboardingInviteSteps.Intercom &&
        (isDesktop || simplifiedFlow))
    ) {
      handleRegister()
    } else {
      setSearchParameters({ step: inviteStepsConfig[currentStep].next() })
    }
  }

  const handlePrevious = () => {
    setSearchParameters({ step: inviteStepsConfig[currentStep].previous() })
  }

  // -- Vars --
  const simplifiedFlow =
    data?.user.hcpStatus.status === 'Inapplicable' ||
    data?.user.hcpStatus.hasExistingDevice ||
    false
  const wizardConfig = simplifiedFlow
    ? simplifiedOnboardingWizardConfig
    : hcpOnboardingWizardConfig

  const totalSteps = isDesktop ? 2 : 3

  const headerStates: Record<
    OnboardingInviteSteps,
    { state: CurrentStep; cantGoBack?: boolean }
  > = {
    [OnboardingInviteSteps.Welcome]: {
      state: {
        ...wizardConfig[0],
        subStep: {
          step: 1,
          of: totalSteps,
        },
      },
      cantGoBack: true,
    },
    [OnboardingInviteSteps.Intercom]: {
      state: {
        ...wizardConfig[0],
        subStep: {
          step: 2,
          of: totalSteps,
        },
      },
    },
    [OnboardingInviteSteps.CloseExplanation]: {
      state: {
        ...wizardConfig[0],
        subStep: {
          step: 3,
          of: totalSteps,
        },
      },
    },
  }

  // -- Render --
  if (loading) {
    return <FullPageLoader />
  }

  // Redirect the user to login if the invite doesn't exist or is already used
  if (error || data?.used) {
    return <Navigate to={UnauthenticatedRoutes.Root} replace />
  }

  return (
    <>
      <Wizard
        steps={wizardConfig}
        currentStep={headerStates[currentStep].state}
        canCancel={false}
        onGoBack={
          headerStates[currentStep].cantGoBack ? undefined : handlePrevious
        }
        showIntercom={false}
        showLanguage
      />

      <InviteScreenSteps
        simplifiedFlow={simplifiedFlow}
        currentStep={headerStates[currentStep].state}
        handleNext={handleNext}
      />
    </>
  )
}
