import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { css, styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { StError } from '../../../components/form/Input'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { ContentContainer } from '../../../components/page-layout'
import {
  BodyLargeSemiBoldCss,
  BodyMediumRegular,
  BodyMediumRegularCss,
  H5,
} from '../../../components/typography'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'
import ChargeCardIllustration from '../assets/charge-card-illustration.svg'
import { ChargeCardDetail } from '../components/ChargeCardDetail'

import type { AxiosError } from 'axios'
import type { MspCard } from 'database'
import type { KeyboardEvent } from 'react'

export const ChargeCardScreen = () => {
  // -- Hooks --
  const { currentChargeCard, currentMember } = useMember()
  const { refetch } = useUser()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { trackEvent } = useTracking()

  // -- State --
  const [activationCode, setActivationCode] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  // -- Data --
  const [, execute] = useAuthMemberAxios<MspCard>(
    {
      url: Urls.activateChargeCard,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // Input refs
  const inputReferences = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ]

  // -- Effects --
  useEffect(() => {
    if (state?.activationCode) {
      let code = state.activationCode

      code = code.replaceAll('BE-BLO-C', '').replace('-', '')

      setActivationCode([...code])
    }
  }, [])

  // -- Handlers --
  const handleActivationCodeChange = (
    inputValue: string,
    index: number,
    backspace: boolean = false
  ) => {
    let value = inputValue.trim().toUpperCase()

    // If value is not a letter or number, skip it
    if (!/[\dA-Za-z]/.test(value) && value !== '') {
      return
    }

    // If we are backspacing and the value is empty, focus the previous input
    if (value === '' && backspace && index > 0) {
      setTimeout(() => {
        inputReferences[index - 1].current?.focus()
        inputReferences[index - 1].current?.select()
      }, 0)

      return
    }

    // If the value was not empty, apply the backspace to the cell
    if (backspace) {
      value = ''
    }

    const newActivationCode = [...activationCode]

    // Append the input at the provided index if more than 1 value is provided
    if (value.length > 1) {
      const inputCharacters = [...value]

      inputCharacters.forEach((character, index_) => {
        if (index + index_ < inputReferences.length) {
          newActivationCode[index + index_] = character
        }
      })

      setActivationCode(newActivationCode)
    } else {
      newActivationCode[index] = value
      setActivationCode(newActivationCode)
    }

    // Focus the next input if the value is not empty
    if (!backspace && index < inputReferences.length - 1) {
      const indexesToSkip = value.length ?? 1

      setTimeout(() => {
        inputReferences[index + indexesToSkip].current?.focus()
        inputReferences[index + indexesToSkip].current?.select()
      }, 0)
    } else if (!backspace && index === inputReferences.length - 1) {
      inputReferences[index].current?.blur()
    }
  }

  const handleActivationCodeKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    // Handle backspace
    if (event.key === 'Backspace' && index > 0) {
      const target = event.target as HTMLInputElement

      handleActivationCodeChange(target.value, index, true)
    }

    // Handle arrow left
    if (event.key === 'ArrowLeft' && index > 0) {
      setTimeout(() => {
        inputReferences[index - 1].current?.select()
        inputReferences[index - 1].current?.focus()
      }, 0)
    }

    // Handle arrow right
    if (event.key === 'ArrowRight' && index < inputReferences.length - 1) {
      setTimeout(() => {
        inputReferences[index + 1].current?.select()
        inputReferences[index + 1].current?.focus()
      }, 0)
    }
  }

  const handleSubmit = async () => {
    if (!activationCode.join('') || activationCode.join('').length < 9) {
      setError(t('charge-card.detail.activation.form.error.required'))
      return
    }

    try {
      setLoading(true)

      await execute({
        data: {
          visualNumber:
            'BE-BLO-C' +
            activationCode.slice(0, 8).join('') +
            '-' +
            activationCode[8],
        },
      })

      toast.success(t('charge-card.detail.activation.form.success'))
      trackEvent(EventType.Submit, 'activate_msp_card')
      refetch()
      setLoading(false)
    } catch (error) {
      const axiosError = error as AxiosError<{
        message: string
        translationKey: string
      }>

      setLoading(false)
      setError(
        axiosError.response?.data?.translationKey
          ? t(
              'charge-card.detail.activation.form.error.' +
                axiosError.response?.data?.translationKey
            )
          : axiosError.response?.data?.message
      )

      return console.error('Failed to activate msp card')
    }
  }

  // -- Render --
  if (!currentChargeCard && currentMember.mspStatus !== 'Sent') {
    navigate(EmployeeRoutes.Profile)
  }

  return (
    <ContentContainer>
      <DetailPageHeader
        onBack={isDesktop ? undefined : () => navigate(EmployeeRoutes.Profile)}
        title={t('charge-card.detail.title')}
        topTitle={t('employee.profile.title')}
      />
      {currentMember?.mspStatus === 'Sent' && (
        <StActivationContainer>
          <BodyMediumRegular>
            {currentChargeCard && currentMember.manualMspHandover
              ? t('charge-card.block.sent.manualHandover')
              : currentChargeCard
              ? t('charge-card.block.sent')
              : t('charge-card.detail.activation.title')}
          </BodyMediumRegular>
          <StActivationForm>
            {isDesktop && <StIllustration src={ChargeCardIllustration} />}

            <StActivationContent>
              <StActivationTitle>
                {currentChargeCard
                  ? t('charge-card.block.activation')
                  : t('charge-card.detail.activation.form.title')}
              </StActivationTitle>
              <StInputs>
                <StPlaceholderInput>BE-BLO-C</StPlaceholderInput>
                <StInput
                  name="activationCode_1"
                  type="text"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement

                    handleActivationCodeChange(target.value, 0)
                  }}
                  onKeyDown={(event) => {
                    handleActivationCodeKeyDown(event, 0)
                  }}
                  onFocus={(event) => {
                    const target = event.target as HTMLInputElement

                    target.select()
                  }}
                  value={activationCode[0] ?? ''}
                  ref={inputReferences[0]}
                />
                <StInput
                  name="activationCode_2"
                  type="text"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement

                    handleActivationCodeChange(target.value, 1)
                  }}
                  onKeyDown={(event) => {
                    handleActivationCodeKeyDown(event, 1)
                  }}
                  onFocus={(event) => {
                    const target = event.target as HTMLInputElement

                    target.select()
                  }}
                  value={activationCode[1] ?? ''}
                  ref={inputReferences[1]}
                />
                <StInput
                  name="activationCode_3"
                  type="text"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement

                    handleActivationCodeChange(target.value, 2)
                  }}
                  onKeyDown={(event) => {
                    handleActivationCodeKeyDown(event, 2)
                  }}
                  onFocus={(event) => {
                    const target = event.target as HTMLInputElement

                    target.select()
                  }}
                  value={activationCode[2] ?? ''}
                  ref={inputReferences[2]}
                />
                <StInput
                  name="activationCode_4"
                  type="text"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement

                    handleActivationCodeChange(target.value, 3)
                  }}
                  onKeyDown={(event) => {
                    handleActivationCodeKeyDown(event, 3)
                  }}
                  onFocus={(event) => {
                    const target = event.target as HTMLInputElement

                    target.select()
                  }}
                  value={activationCode[3] ?? ''}
                  ref={inputReferences[3]}
                />
                <StInput
                  name="activationCode_5"
                  type="text"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement

                    handleActivationCodeChange(target.value, 4)
                  }}
                  onKeyDown={(event) => {
                    handleActivationCodeKeyDown(event, 4)
                  }}
                  onFocus={(event) => {
                    const target = event.target as HTMLInputElement

                    target.select()
                  }}
                  value={activationCode[4] ?? ''}
                  ref={inputReferences[4]}
                />
                <StInput
                  name="activationCode_6"
                  type="text"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement

                    handleActivationCodeChange(target.value, 5)
                  }}
                  onKeyDown={(event) => {
                    handleActivationCodeKeyDown(event, 5)
                  }}
                  onFocus={(event) => {
                    const target = event.target as HTMLInputElement

                    target.select()
                  }}
                  value={activationCode[5] ?? ''}
                  ref={inputReferences[5]}
                />
                <StInput
                  name="activationCode_7"
                  type="text"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement

                    handleActivationCodeChange(target.value, 6)
                  }}
                  onKeyDown={(event) => {
                    handleActivationCodeKeyDown(event, 6)
                  }}
                  onFocus={(event) => {
                    const target = event.target as HTMLInputElement

                    target.select()
                  }}
                  value={activationCode[6] ?? ''}
                  ref={inputReferences[6]}
                />
                <StInput
                  name="activationCode_8"
                  type="text"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement

                    handleActivationCodeChange(target.value, 7)
                  }}
                  onKeyDown={(event) => {
                    handleActivationCodeKeyDown(event, 7)
                  }}
                  onFocus={(event) => {
                    const target = event.target as HTMLInputElement

                    target.select()
                  }}
                  value={activationCode[7] ?? ''}
                  ref={inputReferences[7]}
                />
                <StPlaceholderInput>-</StPlaceholderInput>
                <StInput
                  name="activationCode_9"
                  type="text"
                  onChange={(event) => {
                    const target = event.target as HTMLInputElement

                    handleActivationCodeChange(target.value, 8)
                  }}
                  onKeyDown={(event) => {
                    handleActivationCodeKeyDown(event, 8)
                  }}
                  onFocus={(event) => {
                    const target = event.target as HTMLInputElement

                    target.select()
                  }}
                  value={activationCode[8] ?? ''}
                  ref={inputReferences[8]}
                />
                {error && (
                  <StInputError>
                    <StError>{error}</StError>
                  </StInputError>
                )}
              </StInputs>
              <StSubmitButton
                onClick={handleSubmit}
                disabled={loading}
                type="submit"
              >
                {currentChargeCard
                  ? t('charge-card.block.activate')
                  : t('charge-card.detail.activation.form.submit')}
              </StSubmitButton>
            </StActivationContent>
          </StActivationForm>
        </StActivationContainer>
      )}

      {currentChargeCard && (
        <StDetailsWrapper>
          {currentChargeCard.mspCardStatus !== 'Active' &&
            currentMember.mspStatus === 'Requested' && (
              <BodyMediumRegular>
                {t('charge-card.block.requested')}
              </BodyMediumRegular>
            )}
          <StDetailsContainer>
            <ChargeCardDetail chargeCard={currentChargeCard} />

            {currentChargeCard.mspCardStatus === 'Active' && (
              <StActionsContainer>
                <StActionsTitle>
                  {t('charge-card.detail.actions')}
                </StActionsTitle>
                <StAction
                  onClick={() => navigate(EmployeeRoutes.BlockChargeCard)}
                >
                  <BodyMediumRegular>
                    {t('charge-card.detail.actions.block')}
                  </BodyMediumRegular>
                  <FontAwesomeIcon icon={['fasr', 'chevron-right']} />
                </StAction>
              </StActionsContainer>
            )}
          </StDetailsContainer>
        </StDetailsWrapper>
      )}
    </ContentContainer>
  )
}

// Detail
const StDetailsWrapper = styled.div`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StDetailsContainer = styled.div`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space15};
  }
`

const StActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StActionsTitle = styled(H5)`
  margin: 0;
`

const StAction = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.theme.colors.white};

  cursor: pointer;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space6} 0;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
    border-bottom: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  }

  &:hover {
    color: ${({ theme }) => theme.theme.colors['quinary-1']};
  }
`

// Activation
const StActivationContainer = styled.div`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StActivationForm = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  @media ${breakpoints.tablet} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
    padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  }
`

const StIllustration = styled.img``

const StActivationContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  column-gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

  @media ${breakpoints.tablet} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
    grid-template-columns: repeat(14, 40px);
  }
`

const StInputError = styled.div`
  grid-column: span 8;

  @media ${breakpoints.tablet} {
    grid-column: span 14;
  }
`

const StSharedInputStyles = css`
  height: ${({ theme }) => theme.UI.SpacingPx.Space13};

  text-align: center;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StInput = styled.input<{ $readOnly?: boolean }>`
  ${BodyMediumRegularCss}
  ${StSharedInputStyles}
  
  border: 1px solid;
  border-color: ${({ theme }) => theme.components.input.border};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  background-color: ${({ theme }) => theme.components.input.bg};
  width: 100%;

  &:focus {
    outline: none;

    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }
`

const StPlaceholderInput = styled.div`
  ${BodyLargeSemiBoldCss}
  ${StSharedInputStyles}


  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};

  &:nth-of-type(1) {
    grid-column: span 8;
  }

  &:nth-of-type(2) {
    grid-column: span 7;
  }

  @media ${breakpoints.tablet} {
    &:nth-of-type(1) {
      grid-column: span 4;
    }

    &:nth-of-type(2) {
      grid-column: span 1;
    }
  }
`

const StActivationTitle = styled(H5)`
  margin: 0;
`

const StSubmitButton = styled(ButtonPrimary)`
  align-self: stretch;
  width: 100%;

  @media ${breakpoints.tablet} {
    align-self: flex-start;
    width: auto;
  }
`
