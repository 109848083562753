import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { styled, css } from 'styled-components'

import { BodySmallRegular, H5 } from '../../../components/typography'
import { onHover } from '../../../core/lib/styleHelpers'

import type { HcpContract, MspContract } from '../../contracts/types/Contract'
import type { EmployeeInformation } from '../screens/InviteEmployeesScreen'

type InviteEmployeeCardProps = {
  employee: EmployeeInformation
  selectedHcpContract?: HcpContract
  selectedMspContract?: MspContract
  error?: boolean
  onClick: (employee: EmployeeInformation) => void
}

export const InviteEmployeeCard = ({
  employee,
  selectedHcpContract,
  selectedMspContract,
  error,
  onClick,
}: InviteEmployeeCardProps) => {
  const { t } = useTranslation()

  return (
    <StContainer
      $error={error}
      onClick={() => {
        onClick(employee)
      }}
    >
      <StTitleWrapper>
        <StTitle title={employee.email}>{employee.email}</StTitle>
        <BodySmallRegular>
          {employee.internalId && employee.internalId !== ''
            ? employee.internalId
            : ''}
        </BodySmallRegular>
      </StTitleWrapper>
      <StInformationRow>
        <StInformation>
          <StInformationLabel>
            {t('admin.invite-employees.employee.activation-date')}
          </StInformationLabel>
          <StInformationValue>
            {employee.activationDate
              ? format(employee.activationDate, 'dd/MM/yyyy')
              : '-'}
          </StInformationValue>
        </StInformation>
        <StInformation>
          <StInformationLabel>
            {t('admin.invite-employees.employee.hcp')}
          </StInformationLabel>
          <StInformationValue
            title={
              employee.hcpContractId === 'NULL'
                ? ''
                : selectedHcpContract?.displayName ?? selectedHcpContract?.name
            }
          >
            {employee.hcpContractId === 'NULL'
              ? t('admin.invite-employees.employee.none')
              : selectedHcpContract?.displayName ?? selectedHcpContract?.name}
          </StInformationValue>
        </StInformation>
      </StInformationRow>
      <StCompactInformation>
        <StCompactInformationRow>
          <StIconContainer>
            <FontAwesomeIcon icon={['fass', 'charging-station']} />
          </StIconContainer>
          <BodySmallRegular>
            {employee.hcpContractId === 'NULL'
              ? '-'
              : employee.hasExistingDevice === 'true'
              ? t('admin.invite-employees.employee.existing')
              : t('admin.invite-employees.employee.new')}
          </BodySmallRegular>
        </StCompactInformationRow>
        <StCompactInformationRow>
          <StIconContainer>
            <FontAwesomeIcon icon={['fass', 'credit-card-front']} />
          </StIconContainer>
          <BodySmallRegular>
            {employee.mspContractId === 'NULL'
              ? t('admin.invite-employees.employee.none')
              : selectedMspContract?.displayName ?? selectedMspContract?.name}
          </BodySmallRegular>
        </StCompactInformationRow>
        <StCompactInformationRow>
          <StIconContainer>
            <FontAwesomeIcon icon={['fass', 'truck-fast']} />
          </StIconContainer>
          <BodySmallRegular>
            {!employee.mspContractId || employee.mspContractId === 'NULL'
              ? '-'
              : employee.manualMspHandover === 'true'
              ? t('admin.invite-employees.employee.msp-manual')
              : t('admin.invite-employees.employee.msp-send')}
          </BodySmallRegular>
        </StCompactInformationRow>
      </StCompactInformation>
    </StContainer>
  )
}

const StContainer = styled.div<{ $error?: boolean }>`
  overflow: hidden;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  border-color: ${({ theme, $error }) =>
    $error ? theme.components.input.error : theme.theme.colors['nonary-7']};

  ${onHover(css`
    border-color: ${({ theme }) => theme.theme.colors.black};
  `)}
`

const StTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  flex-grow: 1;
`

const StTitle = styled(H5)`
  margin: 0;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const StInformationRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
  justify-content: space-between;
`

const StInformation = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
`

const StInformationLabel = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StInformationValue = styled(BodySmallRegular)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const StCompactInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StCompactInformationRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  align-items: center;
`

const StIconContainer = styled.div`
  width: 20px;

  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
