import { styled } from 'styled-components'

import type { PropsWithChildren, ReactNode } from 'react'

type PageLayoutProps = PropsWithChildren & {
  navigation?: ReactNode
}

export const PageLayout = ({ children, navigation }: PageLayoutProps) => {
  return (
    <StContainer>
      {navigation ?? null}
      <StContentContainer>{children}</StContentContainer>
    </StContainer>
  )
}

const StContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  min-height: 100vh;
`

const StContentContainer = styled.div`
  flex: 1;
`
