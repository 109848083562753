import { Outlet, Navigate } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../theme/layout/breakpoints'
import { UnauthenticatedRoutes } from '../routes'

export const MobileOnlyGuard = () => {
  // -- Hooks --
  const isTablet = useMediaQuery(breakpoints.tablet)

  // -- Render --

  if (isTablet) {
    return <Navigate to={UnauthenticatedRoutes.Root} replace />
  }

  return <Outlet />
}
