import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useCountdown } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../../routing/routes'
import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { useAuthentication } from '../../../../authentication/hooks/useAuthentication'
import mailImage from '../../../../components/assets/mail.svg'
import { ButtonQuaternary } from '../../../../components/button/ButtonQuaternary'
import {
  BodyMediumRegular,
  BodyMediumSemiBoldCss,
} from '../../../../components/typography'
import { useUser } from '../../../../user/hooks/useUser'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { OnboardingSteps } from '../../hooks/useOnboardingState'

export const VerifyEmailStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { user } = useUser()
  const { user: auth0User } = useAuthentication()

  // -- State --
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 60,
  })
  const [countdownBuffer, setCountdownBuffer] = useState(false)

  // -- Data --
  const [{ loading }, execute] = useAuthMemberAxios(
    {
      url: Urls.SendVerificationEmail,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  const sendVerificationEmailDisabled =
    (count > 0 && count < 60) || countdownBuffer

  // -- Functions --
  const handleSendVerificationEmail = async () => {
    try {
      setCountdownBuffer(true)
      resetCountdown()
      startCountdown()

      await execute({
        data: {
          userId: user.id,
        },
      })

      // Disable buffer state after 1 second
      // This way the countdown get's shown immediately but we don't show the initial state
      setTimeout(() => {
        setCountdownBuffer(false)
      }, 1000)

      toast.success(t('onboarding.verify-email.resend-success'))
    } catch {
      console.error('Failed to send verification email')
      toast.error(t('onboarding.verify-email.resend-error'))
    }
  }

  if (auth0User && auth0User.email_verified) {
    // Go to personal data step
    return (
      <Navigate
        to={EmployeeRoutes.Onboarding + '?step=' + OnboardingSteps.PersonalData}
        replace
      />
    )
  }

  // -- Render --
  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.verify-email.title')}
      </OnboardingStepTitle>
      <StContentWrapper>
        <StImageContainer>
          <img src={mailImage} alt="mail" />
        </StImageContainer>
        <StContentContainer>
          <BodyMediumRegular>
            <Trans
              t={t}
              i18nKey={'onboarding.verify-email.content'}
              components={{ bold: <StBold /> }}
              values={{
                email: user.email,
              }}
            />
          </BodyMediumRegular>
          <StSubscript>
            <BodyMediumRegular>
              {t('onboarding.verify-email.not-received') + ' '}
            </BodyMediumRegular>
            {!sendVerificationEmailDisabled && (
              <ButtonQuaternary
                disabled={sendVerificationEmailDisabled || loading}
                onClick={handleSendVerificationEmail}
              >
                {t('onboarding.verify-email.resend')}
              </ButtonQuaternary>
            )}
            {sendVerificationEmailDisabled && (
              <BodyMediumRegular>
                {t('onboarding.verify-email.resend-countdown', {
                  count,
                })}
              </BodyMediumRegular>
            )}
          </StSubscript>
        </StContentContainer>
      </StContentWrapper>
    </StContainer>
  )
}

const StContainer = styled.div`
  padding-bottom: ${({ theme }) =>
    `calc(var(--inset-bottom, ${theme.UI.SpacingPx.Space6}) + var(--sticky-button-container-height))`};
`

const StContentWrapper = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  border: 1px solid #d0d0d0;

  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`

const StContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  align-items: flex-start;

  flex-grow: 1;
`

const StImageContainer = styled.div`
  width: 150px;
  flex-shrink: 0;

  img {
    width: 100%;
  }

  @media ${breakpoints.tablet} {
    width: 100px;
  }
`

const StBold = styled.span`
  ${BodyMediumSemiBoldCss}
  display: inline;
`

const StSubscript = styled.div`
  color: ${({ theme }) => theme.components.buttons.quaternary['default-txt']};

  > * {
    display: inline;
  }
`
