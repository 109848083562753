import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { match } from 'ts-pattern'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import AppDutchScreenshot from '../../../../components/assets/app_dutch_screenshot.png'
import AppEnglishScreenshot from '../../../../components/assets/app_english_screenshot.png'
import AppFrenchScreenshot from '../../../../components/assets/app_french_screenshot.png'
import {
  BodyMediumRegular,
  BodyMediumSemiBold,
  H4,
} from '../../../../components/typography'
import { HcpIntroductionDutchSvg } from '../../assets/HcpIntroductionDutchSvg'
import { HcpIntroductionEnglishSvg } from '../../assets/HcpIntroductionEnglishSvg'
import { HcpIntroductionFrenchSvg } from '../../assets/HcpIntroductionFrenchSvg'
import { MspIntroductionDutchSvg } from '../../assets/MspIntroductionDutchSvg'
import { MspIntroductionEnglishSvg } from '../../assets/MspIntroductionEnglishSvg'
import { MspIntroductionFrenchSvg } from '../../assets/MspIntroductionFrenchSvg'
import { InviteExplanationCard } from '../InviteExplanationCard'

type InviteWelcomeContentProps = {
  handleNext: () => void
  simplifiedFlow: boolean
}

export const InviteWelcomeContent = ({
  handleNext,
  simplifiedFlow,
}: InviteWelcomeContentProps) => {
  // -- Hooks --
  const { t, i18n } = useTranslation()

  const screenshotSrc = match(i18n.language)
    .with('nl', () => AppDutchScreenshot)
    .with('fr', () => AppFrenchScreenshot)
    .otherwise(() => AppEnglishScreenshot)

  // -- Render --
  return (
    <div>
      <InviteExplanationCard
        title={
          simplifiedFlow
            ? t('msp-introduction.welcome-card.title')
            : t('hcp-introduction.welcome-card.title')
        }
        content={
          simplifiedFlow
            ? t('msp-introduction.welcome-card.content')
            : t('hcp-introduction.welcome-card.content')
        }
        buttonText={t('introduction.welcome-card.button')}
        onClick={handleNext}
        image={
          simplifiedFlow ? (
            <MspIntroductionSvg language={i18n.language} />
          ) : (
            <HcpIntroductionSvg language={i18n.language} />
          )
        }
      />

      <StDownloadAppCta>
        <StScreenshot alt="Blossom app screenshot" src={screenshotSrc} />
        <StQRCodeContainer>
          <H4>{t('introduction.app-card.title')}</H4>
          <BodyMediumRegular>
            {t('introduction.app-card.description')}
          </BodyMediumRegular>
          <StQrCode>
            <QRCodeSVG
              value={t('qr.download-app')}
              height={'52px'}
              bgColor="#ffffff00"
            />
            <BodyMediumSemiBold>
              {t('introduction.app-card.cta')}
            </BodyMediumSemiBold>
          </StQrCode>
        </StQRCodeContainer>
      </StDownloadAppCta>
    </div>
  )
}

const StDownloadAppCta = styled.div`
  display: none;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space20};

  height: 300px;
  width: 100%;

  background-color: ${({ theme }) => theme.theme.colors.white};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space25};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space8};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space20};

  @media ${breakpoints.desktop} {
    display: flex;
  }
`

const StScreenshot = styled.img`
  height: 100%;
  align-self: flex-end;
`

const StQrCode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StQRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

function MspIntroductionSvg({ language }: { language: string }) {
  return match(language)
    .with('nl', () => <MspIntroductionDutchSvg />)
    .with('fr', () => <MspIntroductionFrenchSvg />)
    .otherwise(() => <MspIntroductionEnglishSvg />)
}

function HcpIntroductionSvg({ language }: { language: string }) {
  return match(language)
    .with('nl', () => <HcpIntroductionDutchSvg />)
    .with('fr', () => <HcpIntroductionFrenchSvg />)
    .otherwise(() => <HcpIntroductionEnglishSvg />)
}
