import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { H5 } from '../typography'

import { SmallInfoCard } from './SmallInfoCard'

export const QuestionsBlock = () => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Render --
  return (
    <div>
      <StQuestionTitle>{t('questions-block.title')}</StQuestionTitle>
      <StCardContainer>
        <SmallInfoCard
          icon={['fass', 'message']}
          title={t('questions-block.card-1')}
        />
        <SmallInfoCard
          icon={['fasr', 'envelope']}
          title={t('questions-block.card-2')}
        />
      </StCardContainer>
    </div>
  )
}

const StCardContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
  flex-wrap: wrap;
`

const StQuestionTitle = styled(H5)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space15};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
`
