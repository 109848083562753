import { createContext } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { useAuthAxios } from '../../../api/hooks/useAuthAxios'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'

import type { RefetchFunction } from '../../../api/hooks/types'
import type { UserSettingsMode } from '../types/enums'
import type { PropsWithChildren } from 'react'
import type {
  EnergyConsumption,
  EnergySavings,
  ScoptChargerSetpoint,
  ScoptInstallation,
} from 'types'

type HemsProviderProps = {
  fetchSetpoints?: boolean
} & PropsWithChildren

type SetChargerPointOptions =
  | {
      mode: UserSettingsMode.solar | UserSettingsMode.standard
      settings?: undefined
    }
  | {
      mode: UserSettingsMode.cap
      settings: {
        cap: number
      }
    }
  | {
      mode: UserSettingsMode.autopilot
      settings: {
        chargeKm: number
        endDate: string
      }
    }

type HemsContext = {
  // -- Data --
  installation?: ScoptInstallation
  setPoints?: ScoptChargerSetpoint
  energyConsumption?: EnergyConsumption
  energySavings: EnergySavings
  dongleStatus: 'offline' | 'online'

  // -- Handlers --
  handleSetChargerSetPoint: (options: SetChargerPointOptions) => Promise<void>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSetPoints: RefetchFunction<any, ScoptChargerSetpoint>
  handleUpdateInstallation: (data: Partial<ScoptInstallation>) => Promise<void>
  handleCompleteIntroduction: () => Promise<void>
  refetchInstallation: () => void

  // -- Loading --
  installationLoading?: boolean
  setPointsLoading?: boolean
  energyConsumptionLoading?: boolean
  energySavingsLoading: boolean
  updateInstallationLoading: boolean
  setChargerSetpointsLoading: boolean
}

export const hemsContext = createContext<HemsContext | undefined>(undefined)

export const HemsProvider = ({
  children,
  fetchSetpoints = true,
}: HemsProviderProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Data --
  const [
    { data: installationData, loading: installationLoading },
    refetchInstallation,
  ] = useAuthAxios<ScoptInstallation>(Urls.fetchInstallation)
  const [{ data: setPoints, loading: setPointsLoading }, refetchSetpoints] =
    useAuthAxios<ScoptChargerSetpoint>(Urls.fetchChargerSetPoints, {
      manual: !fetchSetpoints,
    })

  // -- Update Calls --
  const [{ loading: updateInstallationLoading }, updateInstallation] =
    useAuthAxios<ScoptInstallation>(
      { url: Urls.updateInstallation, method: 'PATCH' },
      {
        manual: true,
      }
    )

  const [, completeIntroduction] = useAuthMemberAxios(
    { url: Urls.completeIntroduction, method: 'POST' },
    {
      manual: true,
    }
  )

  const [{ loading: setChargerSetpointsLoading }, setChargerSetpoints] =
    useAuthAxios(
      { url: Urls.SetChargerSetPoints, method: 'POST' },
      { manual: true }
    )

  const [{ data: energyConsumption, loading: energyConsumptionLoading }] =
    useAuthAxios(Urls.fetchEnergyConsumption)
  const [{ data: energySavings, loading: energySavingsLoading }] = useAuthAxios(
    Urls.fetchEnergySavings
  )

  // -- Handlers --
  const handleSetChargerSetPoint = async (options: SetChargerPointOptions) => {
    await setChargerSetpoints({
      data: { mode: options.mode, ...options.settings },
    })

    await refetchSetpoints()
  }

  const handleUpdateInstallation = async (data: Partial<ScoptInstallation>) => {
    try {
      const { status } = await updateInstallation({
        data,
      })

      if (status !== 200 && status !== 201) {
        throw new Error('Failed to update scopt installation')
      }

      refetchInstallation()
    } catch {
      toast.error(t('employee.hems.onboarding.error'))
      throw new Error('Failed to update scopt installation')
    }
  }

  const handleCompleteIntroduction = async () => {
    try {
      const { status } = await completeIntroduction()

      if (status !== 200 && status !== 201) {
        throw new Error('Failed to complete scopt introduction')
      }
    } catch {
      toast.error(t('employee.hems.introduction.error'))
      throw new Error('Failed to complete scopt installation')
    }
  }

  // -- Render --
  return (
    <hemsContext.Provider
      value={{
        // -- Data --
        installation: installationData,
        setPoints,
        energyConsumption,
        energySavings,
        dongleStatus: energyConsumption?.dongleStatus ?? 'offline',

        // -- Handlers --
        getSetPoints: refetchSetpoints,
        handleSetChargerSetPoint,
        handleUpdateInstallation,
        handleCompleteIntroduction,
        refetchInstallation,

        // -- Loading --
        installationLoading,
        setPointsLoading,
        energyConsumptionLoading,
        energySavingsLoading,
        updateInstallationLoading,
        setChargerSetpointsLoading,
      }}
    >
      {children}
    </hemsContext.Provider>
  )
}
