import { styled } from 'styled-components'

import { BodyMediumRegular, BodySmallRegular, H4 } from '../typography'

import type { PropsWithChildren } from 'react'

type StatisticCardProps = PropsWithChildren & {
  title?: string
  subTitle?: string
  value?: string | number
  appendValue?: string
  prependValue?: string
}

export const StatisticCard = ({
  title,
  subTitle,
  prependValue,
  value,
  appendValue,
  children,
}: StatisticCardProps) => {
  // Only take 2 decimals if number
  const formattedValue =
    typeof value === 'number'
      ? Number.parseFloat(value as unknown as string).toFixed(2)
      : value

  return (
    <StStatisticCardContainer>
      <div>
        <StTitle>{title}</StTitle>
        {formattedValue && (
          <StValue>
            {prependValue}
            {formattedValue}
            {appendValue}
          </StValue>
        )}
        <StSubTitle>{subTitle}</StSubTitle>
      </div>
      {children}
    </StStatisticCardContainer>
  )
}

const StStatisticCardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: 10px;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StTitle = styled(BodyMediumRegular)``

const StSubTitle = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StValue = styled(H4)`
  margin-top: 10px;
`
