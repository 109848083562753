import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../../../theme/layout/breakpoints'
import {
  BodyMediumRegular,
  BodyMediumSemiBold,
  H6Css,
  H7,
} from '../../../../../components/typography'
import { ActiveSessionDutchSvg } from '../../assets/activeSession/ActiveSessionDutchSvg'
import { ActiveSessionDutchSvgDesktop } from '../../assets/activeSession/ActiveSessionDutchSvgDesktop'
import { ActiveSessionEnglishSvg } from '../../assets/activeSession/ActiveSessionEnglishSvg'
import { ActiveSessionEnglishSvgDesktop } from '../../assets/activeSession/ActiveSessionEnglishSvgDesktop'
import { ActiveSessionFrenchSvg } from '../../assets/activeSession/ActiveSessionFrenchSvg'
import { ActiveSessionFrenchSvgDesktop } from '../../assets/activeSession/ActiveSessionFrenchSvgDesktop'
import { SetStatesDutchSvg } from '../../assets/setStates/SetStatesDutchSvg'
import { SetStatesDutchSvgDesktop } from '../../assets/setStates/SetStatesDutchSvgDesktop'
import { SetStatesEnglishSvg } from '../../assets/setStates/SetStatesEnglishSvg'
import { SetStatesEnglishSvgDesktop } from '../../assets/setStates/SetStatesEnglishSvgDesktop'
import { SetStatesFrenchSvg } from '../../assets/setStates/SetStatesFrenchSvg'
import { SetStatesFrenchSvgDesktop } from '../../assets/setStates/SetStatesFrenchSvgDesktop'
import { UsageGraphDutchSvg } from '../../assets/usageGraph/UsageGraphDutchSvg'
import { UsageGraphDutchSvgDesktop } from '../../assets/usageGraph/UsageGraphDutchSvgDesktop'
import { UsageGraphEnglishSvg } from '../../assets/usageGraph/UsageGraphEnglishSvg'
import { UsageGraphEnglishSvgDesktop } from '../../assets/usageGraph/UsageGraphEnglishSvgDesktop'
import { UsageGraphFrenchSvg } from '../../assets/usageGraph/UsageGraphFrenchSvg'
import { UsageGraphFrenchSvgDesktop } from '../../assets/usageGraph/UsageGraphFrenchSvgDesktop'
import {
  HemsIntroductionSteps,
  useHemsIntroductionState,
} from '../../hooks/useHemsIntroductionState'

import { HemsInfoStep } from './HemsInfoStep'

import type { ScoptInstallation } from 'types'

type HemsIntroductionProps = {
  onClose: () => void
}

type HemsIntroductionContext = {
  values?: ScoptInstallation
  handleNext: (
    data?: Partial<ScoptInstallation>
  ) => Promise<boolean | undefined>
  handleClose: (data?: Partial<ScoptInstallation>) => void
}

const getActiveSessionSvg = (isDesktop: boolean, language: string) => {
  if (isDesktop) {
    return language === 'nl' ? (
      <ActiveSessionDutchSvgDesktop />
    ) : language === 'fr' ? (
      <ActiveSessionFrenchSvgDesktop />
    ) : (
      <ActiveSessionEnglishSvgDesktop />
    )
  } else {
    return language === 'nl' ? (
      <ActiveSessionDutchSvg />
    ) : language === 'fr' ? (
      <ActiveSessionFrenchSvg />
    ) : (
      <ActiveSessionEnglishSvg />
    )
  }
}

const getUsageGraphSvg = (isDesktop: boolean, language: string) => {
  if (isDesktop) {
    return language === 'nl' ? (
      <UsageGraphDutchSvgDesktop />
    ) : language === 'fr' ? (
      <UsageGraphFrenchSvgDesktop />
    ) : (
      <UsageGraphEnglishSvgDesktop />
    )
  } else {
    return language === 'nl' ? (
      <UsageGraphDutchSvg />
    ) : language === 'fr' ? (
      <UsageGraphFrenchSvg />
    ) : (
      <UsageGraphEnglishSvg />
    )
  }
}

const getSetStatesSvg = (isDesktop: boolean, language: string) => {
  if (isDesktop) {
    return language === 'nl' ? (
      <SetStatesDutchSvgDesktop />
    ) : language === 'fr' ? (
      <SetStatesFrenchSvgDesktop />
    ) : (
      <SetStatesEnglishSvgDesktop />
    )
  } else {
    return language === 'nl' ? (
      <SetStatesDutchSvg />
    ) : language === 'fr' ? (
      <SetStatesFrenchSvg />
    ) : (
      <SetStatesEnglishSvg />
    )
  }
}

export const hemsIntroductionContext =
  createContext<HemsIntroductionContext | null>(null)

export const HemsIntroduction = ({ onClose }: HemsIntroductionProps) => {
  // -- Hooks --
  const { t, i18n } = useTranslation()
  const { currentStep, handleNext, handlePrevious, values, currentStepConfig } =
    useHemsIntroductionState()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Vars --
  const steps = {
    [HemsIntroductionSteps.ActiveSessionInfo]: (
      <HemsInfoStep
        title={t('employee.hems.introduction.step-1.title')}
        description={t('employee.hems.introduction.step-1.description')}
        visual={getActiveSessionSvg(isDesktop, i18n.language)}
      />
    ),
    [HemsIntroductionSteps.UsageGraphInfo]: (
      <HemsInfoStep
        title={t('employee.hems.introduction.step-2.title')}
        description={t('employee.hems.introduction.step-2.description')}
        visual={getUsageGraphSvg(isDesktop, i18n.language)}
      />
    ),
    [HemsIntroductionSteps.SetStateInfo]: (
      <HemsInfoStep
        title={t('employee.hems.introduction.step-3.title')}
        description={t('employee.hems.introduction.step-3.description')}
        visual={getSetStatesSvg(isDesktop, i18n.language)}
        nextString={t('employee.hems.introduction.confirm')}
        showCloseButton={false}
      />
    ),
  }

  const stepCount = Object.keys(steps).length
  const currentStepIndex = Object.keys(steps).indexOf(currentStep) + 1

  // -- Handlers --
  const handlePreviousStep = () => {
    if (currentStepIndex === 1) {
      onClose()
    } else {
      handlePrevious()
    }
  }

  // -- Render --
  return (
    <hemsIntroductionContext.Provider
      value={{ handleNext, handleClose: onClose, values }}
    >
      {(currentStepConfig.showHeader ?? true) && (
        <StHeader>
          {currentStepIndex === 1 ? (
            isDesktop ? (
              <div />
            ) : (
              <StIconButton onClick={onClose}>
                <FontAwesomeIcon icon={['fasr', 'xmark-large']} fontSize={22} />
              </StIconButton>
            )
          ) : (
            <StIconButton onClick={handlePreviousStep}>
              {isDesktop ? (
                <StBack>
                  <FontAwesomeIcon
                    icon={['fasr', 'arrow-left']}
                    fontSize={20}
                  />
                  <BodyMediumRegular>
                    {t('employee.hems.onboarding.back')}
                  </BodyMediumRegular>
                </StBack>
              ) : (
                <FontAwesomeIcon
                  icon={['fasr', 'chevron-left']}
                  fontSize={22}
                />
              )}
            </StIconButton>
          )}
          <StTitle>{t('employee.hems.introduction.title')}</StTitle>
          <StStepsContainer>
            <StSteps>
              {currentStepIndex}/{stepCount}
            </StSteps>
            {isDesktop && (
              <StIconButton onClick={onClose}>
                <FontAwesomeIcon icon={['fasl', 'xmark']} fontSize={24} />
              </StIconButton>
            )}
          </StStepsContainer>
        </StHeader>
      )}

      {steps[currentStep]}
    </hemsIntroductionContext.Provider>
  )
}

const StIconButton = styled.button`
  background-color: transparent;
  /* Increase hitslop */
  padding: ${({ theme }) => theme.UI.SpacingPx.Space1};
  border: none;
  width: min-content;

  cursor: pointer;

  @media ${breakpoints.desktop} {
    color: ${({ theme }) => theme.components.menu.wizard['item-icon']};
  }
`

const StHeader = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
  /* Compensate for hitslop */
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space4};

  h6 {
    margin: 0;
    text-align: center;
  }

  svg {
    margin-bottom: -4px;
  }

  @media ${breakpoints.desktop} {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space7};
  }
`

const StBack = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  svg {
    margin-top: -4px;
  }
`

const StStepsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StSteps = styled(BodyMediumSemiBold)`
  background-color: ${({ theme }) => theme.components.navcircle['mobile-bg']};
  padding: 2px ${({ theme }) => theme.UI.SpacingPx.Space2};

  border-radius: 999px;

  width: fit-content;
  margin-left: auto;
`

const StTitle = styled(H7)`
  @media ${breakpoints.desktop} {
    ${H6Css}
  }
`
