import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { MobileUploadContent } from '../../components/upload/MobileUploadContent'
import { MobileUploadLoader } from '../../components/upload/MobileUploadLoader'
import { MobileUploadSuccess } from '../../components/upload/MobileUploadSuccess'
import { useImagePolling } from '../../hooks/useImagePolling'
import { useMultiImageUpload } from '../../hooks/useMultiImageUpload'
import { useOnboarding } from '../../hooks/useOnboarding'

const CURRENT_STEP = 5
export const TOTAL_STEPS = 5

export const ExtraImagesStep = () => {
  // -- Hooks --
  const { values, handleNext, setLoading } = useOnboarding()
  const { t } = useTranslation()
  useImagePolling()

  const { loading, handleAddImage, handleRemoveImage } = useMultiImageUpload(
    'extraImages',
    'extraImageIds'
  )

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Handlers --
  const handleSubmit = async (skipped: boolean) => {
    try {
      setLoading(true)

      await execute({
        data: {
          skippedExtraImages: skipped,
        },
      })

      handleNext({ ...values, skippedExtraImages: skipped })
    } catch {
      setLoading(false)
      return console.error('Failed')
    }
  }

  // -- Render --
  if (loading) {
    return (
      <MobileUploadLoader currentStep={CURRENT_STEP} totalSteps={TOTAL_STEPS} />
    )
  }

  if (values.extraImageIds?.length) {
    return (
      <MobileUploadSuccess
        imageIds={values.extraImageIds}
        currentStep={CURRENT_STEP}
        totalSteps={TOTAL_STEPS}
        title={t('onboarding.extra-image.title')}
        onSubmit={() => handleSubmit(false)}
        onDelete={handleRemoveImage}
        onAddImage={
          values.extraImageIds.length >= 5 ? undefined : handleAddImage
        }
      />
    )
  }

  return (
    <MobileUploadContent
      uploadPicture={handleAddImage}
      title={t('onboarding.extra-image.title')}
      description={t('onboarding.extra-image.description')}
      currentStep={CURRENT_STEP}
      totalSteps={TOTAL_STEPS}
      extraButton={
        <StButton onClick={() => handleSubmit(true)}>
          {t('onboarding.mobile-documents.submit')}
        </StButton>
      }
    />
  )
}

const StButton = styled(ButtonPrimary)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
