import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme, styled } from 'styled-components'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { Chart } from '../../../components/chart/Chart'
import {
  BodyExtraSmallRegular,
  BodyExtraSmallSemiBold,
} from '../../../components/typography'
import { formatData, formatTooltip } from '../../../core/lib/chart'
import { formatDecimals } from '../../../core/utils/number'

import { StChartContainer, StChartInfoContainer } from './UsageDetails'
import { UsageDetailsInfoCard } from './UsageDetailsInfoCard'

import type { DateRangeMode } from './UsageDetails'
import type { ActiveElement } from 'chart.js'
import type { UsageDetails as UsageDetailsResponse } from 'types'

type UsageDetailsChargerProps = {
  usageDetails?: UsageDetailsResponse
  dateRange: { start: Date; end: Date }
  dateRangeMode: DateRangeMode
}

export function UsageDetailsCharger({
  usageDetails,
  dateRange,
  dateRangeMode,
}: UsageDetailsChargerProps) {
  // -- Hooks --
  const theme = useTheme()
  const { t } = useTranslation()

  // -- State --
  const [selectedNetCarConsumption, setSelectedNetCarConsumption] =
    useState<ActiveElement | null>(null)
  const [selectedSolarCarConsumption, setSelectedSolarCarConsumption] =
    useState<ActiveElement | null>(null)

  // -- Graph data --
  const actualConsumption = useMemo(
    () =>
      formatData(
        dateRange,
        dateRangeMode,

        (usageDetails?.actualConsumption ?? []).map((data) => ({
          label: data[0],
          value: data[1],
        })),
        t
      ),
    [dateRange, dateRangeMode, t, usageDetails?.actualConsumption]
  )

  const actualCarConsumption = useMemo(
    () =>
      formatData(
        dateRange,
        dateRangeMode,
        (usageDetails?.actualCarConsumption ?? []).map((data) => ({
          label: data[0],
          value: data[1],
        })),
        t
      ),
    [dateRange, dateRangeMode, t, usageDetails?.actualCarConsumption]
  )

  const netCarConsumption = useMemo(
    () =>
      formatData(
        dateRange,
        dateRangeMode,
        (usageDetails?.netCarConsumption ?? []).map((data) => ({
          label: data[0],
          value: data[1] / 1000,
        })),
        t
      ),
    [dateRange, dateRangeMode, t, usageDetails?.netCarConsumption]
  )

  const solarCarConsumption = useMemo(
    () =>
      formatData(
        dateRange,
        dateRangeMode,
        (usageDetails?.solarCarConsumption ?? []).map((data) => ({
          label: data[0],
          value: data[1],
        })),
        t
      ),
    [dateRange, dateRangeMode, t, usageDetails?.solarCarConsumption]
  )

  const carConsumptionData = useMemo(() => {
    const carSolarConsumptionData = actualCarConsumption.map(
      (valueObject, index) => {
        if (valueObject.value === undefined) {
          return undefined
        }

        const resultingValue =
          valueObject.value - Math.max(actualConsumption[index].value ?? 0, 0)

        if (resultingValue < 0) {
          return 0
        }

        if (
          actualCarConsumption[index] &&
          resultingValue > (actualCarConsumption[index].value ?? 0)
        ) {
          return actualCarConsumption[index].value
        }

        return resultingValue
      }
    )

    const carNetConsumptionData = actualCarConsumption.map(
      (valueObject, index) =>
        valueObject.value === undefined
          ? undefined
          : valueObject.value - (carSolarConsumptionData[index] ?? 0)
    )

    return {
      carSolarConsumptionData,
      carNetConsumptionData,
    }
  }, [actualCarConsumption, actualConsumption])

  const totalCharged = formatDecimals(
    (usageDetails?.totals.totalCharged ?? 0) / 1000,
    dateRangeMode === 'day' ? 1 : 0
  )
  const totalChargedSolar = formatDecimals(
    usageDetails?.totals.totalChargedSolar ?? 0,
    dateRangeMode === 'day' ? 1 : 0
  )

  const totalChargedNet = formatDecimals(
    Number.parseFloat(totalCharged) - Number.parseFloat(totalChargedSolar),
    dateRangeMode === 'day' ? 1 : 0
  )

  const emptyData = useMemo(() => {
    if (dateRangeMode === 'day') {
      return false
    }

    return (
      netCarConsumption.every((data) => !data.value) &&
      solarCarConsumption.every((data) => !data.value)
    )
  }, [dateRangeMode, netCarConsumption, solarCarConsumption])

  // -- Selected values --
  const selectedNetCarConsumptionValue = useMemo(
    () =>
      selectedNetCarConsumption
        ? formatDecimals(
            dateRangeMode === 'day'
              ? carConsumptionData.carNetConsumptionData[
                  selectedNetCarConsumption.index
                ] ?? 0
              : netCarConsumption[selectedNetCarConsumption.index].value ?? 0,
            dateRangeMode === 'day' ? 1 : 0
          )
        : totalChargedNet,
    [
      carConsumptionData.carNetConsumptionData,
      dateRangeMode,
      netCarConsumption,
      selectedNetCarConsumption,
      totalChargedNet,
    ]
  )

  const selectedSolarCarConsumptionValue = useMemo(
    () =>
      selectedSolarCarConsumption
        ? formatDecimals(
            dateRangeMode === 'day'
              ? carConsumptionData.carSolarConsumptionData[
                  selectedSolarCarConsumption.index
                ] ?? 0
              : solarCarConsumption[selectedSolarCarConsumption.index].value ??
                  0,
            dateRangeMode === 'day' ? 1 : 0
          )
        : totalChargedSolar,
    [
      carConsumptionData.carSolarConsumptionData,
      dateRangeMode,
      selectedSolarCarConsumption,
      solarCarConsumption,
      totalChargedSolar,
    ]
  )

  // -- Line chart --
  const lineChart = useMemo(
    () => (
      <Chart<'line'>
        type="line"
        stacked
        data={{
          labels: actualCarConsumption.map((d) => d.label),
          datasets: [
            {
              data: carConsumptionData.carSolarConsumptionData,
              backgroundColor: '#FFF5DA80',
              borderColor: '#FFDC7A',
              borderWidth: 1,
              fill: true,
              hoverBackgroundColor: theme.theme.colors['nonary-7'],
              hoverBorderColor: theme.theme.colors['nonary-7'],
              tension: 0.4,
              animation: {
                duration: 0,
              },
            },
            {
              data: carConsumptionData.carNetConsumptionData,
              backgroundColor: theme.theme.colors['secondary-5'] + '80',
              borderColor: theme.theme.colors['nonary-5'],
              borderWidth: 1,
              fill: 0,
              hoverBackgroundColor: theme.theme.colors['nonary-7'],
              hoverBorderColor: theme.theme.colors['nonary-7'],
              tension: 0.4,
              animation: {
                duration: 0,
              },
            },
          ],
        }}
        onSelectedDataPointsChange={(elements) => {
          setSelectedSolarCarConsumption(elements?.[0] ?? null)
          setSelectedNetCarConsumption(elements?.[1] ?? null)
        }}
        processTooltipData={(data: ActiveElement[]) =>
          formatTooltip(
            dateRangeMode,
            t,
            actualCarConsumption[data[0].index].timestamp
          )
        }
      />
    ),
    [
      actualCarConsumption,
      carConsumptionData.carNetConsumptionData,
      carConsumptionData.carSolarConsumptionData,
      dateRangeMode,
      t,
      theme.theme.colors,
    ]
  )

  // -- Bar chart --
  const barChart = useMemo(
    () => (
      <Chart<'bar'>
        type="bar"
        stacked
        options={{
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 15,
              },
            },
          },
        }}
        data={(selectedDataPoints: ActiveElement[] | null) => ({
          labels: actualCarConsumption.map((d) => d.label),
          datasets: [
            {
              data: solarCarConsumption.map((d) => d.value),
              backgroundColor: solarCarConsumption.map(
                (d, index) =>
                  `#FFDC7A${
                    selectedDataPoints === null ||
                    selectedDataPoints[0].index === index
                      ? 'FF'
                      : '40'
                  }`
              ),
              animation: {
                duration: 0,
              },
              hoverBackgroundColor: '#FFDC7A',
              borderRadius: 2,
            },
            {
              data: netCarConsumption.map((d) => d.value),
              backgroundColor: netCarConsumption.map(
                (d, index) =>
                  `${theme.theme.colors['nonary-7']}${
                    selectedDataPoints === null ||
                    selectedDataPoints[0].index === index
                      ? 'FF'
                      : '40'
                  }`
              ),
              animation: {
                duration: 0,
              },
              hoverBackgroundColor: theme.theme.colors['nonary-7'],
              borderRadius: 2,
            },
          ],
        })}
        onSelectedDataPointsChange={(elements) => {
          setSelectedSolarCarConsumption(elements?.[0] ?? null)
          setSelectedNetCarConsumption(elements?.[1] ?? null)
        }}
        processTooltipData={(data: ActiveElement[]) => {
          return formatTooltip(
            dateRangeMode,
            t,
            actualCarConsumption[data[0].index].timestamp
          )
        }}
      />
    ),
    [
      actualCarConsumption,
      dateRangeMode,
      netCarConsumption,
      solarCarConsumption,
      t,
      theme.theme.colors,
    ]
  )

  return (
    <StChartContainer>
      <StChartInfoContainer>
        <UsageDetailsInfoCard
          selected={!!selectedNetCarConsumption}
          value={selectedNetCarConsumptionValue}
          suffix={
            selectedNetCarConsumption && dateRangeMode === 'day' ? 'W' : 'kWh'
          }
          color={theme.theme.colors['nonary-5']}
          label={t('employee.hems.usage-details.charger-usage.net')}
          empty={emptyData}
        />
        <UsageDetailsInfoCard
          selected={!!selectedSolarCarConsumption}
          value={selectedSolarCarConsumptionValue}
          suffix={
            selectedNetCarConsumption && dateRangeMode === 'day' ? 'W' : 'kWh'
          }
          color={'#FFDC7A'}
          label={t('employee.hems.usage-details.charger-usage.solar')}
          empty={emptyData}
        />
        <UsageDetailsInfoCard
          selected={!!selectedNetCarConsumption}
          value={
            selectedNetCarConsumption && selectedSolarCarConsumption
              ? formatDecimals(
                  Number.parseFloat(selectedNetCarConsumptionValue) +
                    Number.parseFloat(selectedSolarCarConsumptionValue),
                  dateRangeMode === 'day' ? 1 : 0
                )
              : totalCharged
          }
          suffix={
            selectedNetCarConsumption && dateRangeMode === 'day' ? 'W' : 'kWh'
          }
          label={t('employee.hems.usage-details.charger-usage.net-and-solar')}
          empty={emptyData}
        />
      </StChartInfoContainer>
      {emptyData ? (
        <StEmptyContainer>
          <BodyExtraSmallSemiBold>
            {t(`employee.hems.usage-details.${dateRangeMode}.not-charged`)}
          </BodyExtraSmallSemiBold>
          <BodyExtraSmallRegular>
            {t(`employee.hems.usage-details.${dateRangeMode}.no-data`)}
          </BodyExtraSmallRegular>
        </StEmptyContainer>
      ) : dateRangeMode === 'day' ? (
        actualCarConsumption && lineChart
      ) : (
        barChart
      )}
    </StChartContainer>
  )
}

const StEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10} 0;

  @media ${breakpoints.desktop} {
    justify-content: center;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

    flex-grow: 1;

    padding: ${({ theme }) => theme.UI.SpacingPx.Space10};

    border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }
`
