import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { minutesToHoursAndMinutes } from '../../../../../../../packages/utils/src'
import { Drawer, DrawerContent } from '../../../components/general/Drawer'
import { applyVat, formatDecimals } from '../../../core/utils/number'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'

import { ChargingSessionInfoMsp } from './ChargingSessionInfoMsp'

import type { Column } from '../../../dataTable/components/DataTable'
import type { ChargingSessionWithRelations } from 'types'

export const UsageDataTableMsp = () => {
  const { t } = useTranslation()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedChargingSession, setSelectedChargingSession] = useState<
    ChargingSessionWithRelations | undefined
  >(undefined)

  const columns = useMemo<Column<ChargingSessionWithRelations>[]>(
    () => [
      {
        key: 'start',
        label: t('employee.chargingSessions.table.date'),
        type: ColumnType.DATE,
        sortable: true,
      },
      {
        key: 'city',
        label: t('employee.chargingSessions.table.location'),
        type: ColumnType.STRING,
      },
      {
        key: 'mspPrice',
        label: t('employee.chargingSessions.table.price'),
        type: ColumnType.NUMBER,
        prepend: '€',
        decimal: 2,
        transformData: (data: ChargingSessionWithRelations) => {
          return formatDecimals(applyVat(data.mspPrice, data.vat))
        },
      },
      {
        key: 'kwh',
        label: t('employee.chargingSessions.table.amount'),
        type: ColumnType.NUMBER,
        append: ' kwh',
        decimal: 2,
      },
      {
        key: 'duration',
        label: t('employee.chargingSessions.table.time'),
        type: ColumnType.STRING,
        transformData: (session) => {
          const duration = minutesToHoursAndMinutes(session.duration)

          return (
            <>
              {duration.hours > 0 &&
                `${t('employee.chargingSessions.detail.duration-hours', {
                  hours: duration.hours,
                })} `}
              {t('employee.chargingSessions.detail.duration-minutes', {
                minutes: duration.minutes,
              })}
            </>
          )
        },
      },
    ],
    [t]
  )

  const handleRowClick = useCallback(
    (dataRow: ChargingSessionWithRelations) => {
      setIsDrawerOpen(true)
      setSelectedChargingSession(dataRow)
    },
    []
  )

  const handleClose = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  return (
    <>
      <DataTable<ChargingSessionWithRelations>
        columns={columns}
        onRowClick={handleRowClick}
        emptyTitle={t('employee.chargingSessions.table.empty-title')}
        emptyDescription={t(
          'employee.chargingSessions.table.empty-description'
        )}
        emptyFiltersTitle=""
        emptyFiltersDescription=""
      />
      <Drawer open={isDrawerOpen}>
        <DrawerContent onClose={handleClose}>
          {selectedChargingSession && (
            <ChargingSessionInfoMsp chargingSession={selectedChargingSession} />
          )}
        </DrawerContent>
      </Drawer>
    </>
  )
}
