import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useEmblaCarousel from 'embla-carousel-react'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { styled } from 'styled-components'

import { ZohoImage, ZohoModule } from '../../../../components/image/ZohoImage'

import type { UseEmblaCarouselType } from 'embla-carousel-react'
import type { Gallery } from 'types'

type ImageSliderProps = {
  images: Gallery[]
}

export const ImageSlider = forwardRef(function ImageSlider(
  { images }: ImageSliderProps,
  ref
) {
  const [emblaRef, emblaApi] = useEmblaCarousel()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(true)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)

  // -- Handlers --
  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  )

  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  )

  const scrollPrevious = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  )

  const onInit = useCallback((emblaApi: UseEmblaCarouselType[1]) => {
    if (!emblaApi) {
      return
    }

    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = useCallback((emblaApi: UseEmblaCarouselType[1]) => {
    if (!emblaApi) {
      return
    }
    setSelectedIndex(emblaApi.selectedScrollSnap())
    setPreviousButtonDisabled(!emblaApi.canScrollPrev())
    setNextButtonDisabled(!emblaApi.canScrollNext())
  }, [])

  // -- Effects --
  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)

    emblaApi.on('reInit', onInit)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  // -- ForwardRef --
  useImperativeHandle(
    ref,
    () => {
      return {
        scrollTo(index: number) {
          scrollTo(index)
        },
      }
    },
    [scrollTo]
  )

  return (
    <StSliderContainer>
      <StEmbla>
        <div ref={emblaRef}>
          <StEmblaContainer>
            {images.map((image: Gallery, index: number) => (
              <StEmblaSlide key={image.id}>
                <ZohoImage
                  module={ZohoModule.File}
                  id={image.fileId}
                  altText={`image ${index} for product`}
                />
              </StEmblaSlide>
            ))}
          </StEmblaContainer>
        </div>
      </StEmbla>

      <StNavigationButton
        $direction="previous"
        onClick={scrollPrevious}
        disabled={previousButtonDisabled}
      >
        <StNavigationIcon icon={['fasr', 'chevron-left']} />
      </StNavigationButton>

      <StNavigationButton
        $direction="next"
        onClick={scrollNext}
        disabled={nextButtonDisabled}
      >
        <StNavigationIcon icon={['fasr', 'chevron-right']} />
      </StNavigationButton>

      <StEmblaDots>
        {scrollSnaps.map((_, index) => (
          <StThumbnail
            key={'thumbnail' + index}
            $selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
          >
            <ZohoImage
              module={ZohoModule.File}
              id={images[index].fileId}
              altText={`image thumbnail ${index} for product`}
            />
          </StThumbnail>
        ))}
      </StEmblaDots>
    </StSliderContainer>
  )
})

const StNavigationButton = styled.button<{ $direction: 'previous' | 'next' }>`
  background-color: white;
  width: ${({ theme }) => theme.UI.SpacingPx.Space8};
  height: ${({ theme }) => theme.UI.SpacingPx.Space8};

  position: absolute;
  top: calc(50% - ${({ theme }) => theme.UI.SpacingPx.Space13});
  left: ${({ $direction, theme }) =>
    $direction === 'previous' ? '-' + theme.UI.SpacingPx.Space4 : 'auto'};

  right: ${({ $direction, theme }) =>
    $direction === 'next' ? '-' + theme.UI.SpacingPx.Space4 : 'auto'};

  transform: translateY(
    calc(-50% + ${({ theme }) => theme.UI.SpacingPx.Space13} / 2)
  );

  display: none;
  border: none;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
`

const StSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &:hover {
    button {
      display: block;
    }
  }
`

const StEmbla = styled.div`
  display: flex;
  overflow: hidden;
`

const StEmblaContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  aspect-ratio: 1/1;
  height: 100%;
`

const StEmblaSlide = styled.div`
  flex: 0 0 100%;
  min-width: 0;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  overflow: hidden;
`

const StNavigationIcon = styled(FontAwesomeIcon)`
  width: ${({ theme }) => theme.UI.SpacingPx.Space4};
  overflow: hidden;
`

const StEmblaDots = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  overflow: auto;

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StThumbnail = styled.div<{ $selected: boolean }>`
  width: ${({ theme }) => theme.UI.SpacingPx.Space10};
  height: ${({ theme }) => theme.UI.SpacingPx.Space10};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  border: 2px solid
    ${({ theme, $selected }) =>
      $selected ? theme.theme.colors['primary-0'] : 'transparent'};

  padding: 0;
  overflow: hidden;
  cursor: pointer;

  flex: 0 0 auto;

  &:hover {
    border-color: ${({ theme }) =>
      theme.components['shop-detail']['thumb-hover']};
  }
`
