import { format, formatDistanceToNowStrict } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { css, styled, useTheme } from 'styled-components'

import {
  BodyExtraSmallRegular,
  BodySmallRegularCss,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { UserSettingsMode } from '../../hems/types/enums'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type {
  FormatDistanceFn,
  FormatDistanceToNowStrictOptions,
  FormatDistanceToken,
} from 'date-fns'

export const StCard = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  color: ${({ theme }) => theme.theme.colors.black};
`

export const StGradientCard = styled(StCard)`
  overflow: hidden;
  position: relative;

  ${({ theme }) => css`
    // Background will fill border
    border: 1px solid transparent;

    background: linear-gradient(white, white) padding-box,
      linear-gradient(
          var(--angle),
          ${theme.theme.colors['primary-0']},
          ${theme.theme.colors['nonary-7']} 55%
        )
        border-box;
  `}

  animation: 5s rotate linear infinite;

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }
`

export const StChip = styled.div<{ $color?: 'primary' | 'nonary' }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space1} ${theme.UI.SpacingPx.Space2}`};
  background-color: ${({ theme }) => theme.theme.colors['nonary-10']};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  p {
    ${BodySmallRegularCss}
    color: ${({ theme }) => theme.theme.text.body};
  }

  ${({ $color = 'nonary' }) =>
    $color === 'primary' &&
    css`
      background-color: ${({ theme }) =>
        theme.components.chip.primary['active-bg']};

      p {
        color: ${({ theme }) => theme.theme.colors.black};
      }
    `}
`

export const StBodyExtraSmallRegular = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.colors['nonary-3']};
`

export const StChargModeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  align-items: center;
`

export const StChargeModeSymbol = styled.div<{ $color?: string }>`
  width: 30px;
  height: 30px;

  border-radius: 50%;

  background-color: ${({ $color }) => $color ?? 'transparent'};
  border: ${({ theme, $color }) =>
    $color ? 'none' : `1px solid ${theme.theme.colors['nonary-7']}`};

  display: grid;
  place-content: center;
`

export const StTime = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

export const StDuration = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

type ChargeMode = {
  icon: IconProp
  color: string
  label: string
  value: UserSettingsMode
}

export function useChargeModes() {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const chargeModes: ChargeMode[] = [
    {
      icon: ['fass', 'fast-forward'],
      color: theme.smartcharging['quick-charging'],
      label: t('employee.hems.charge-mode-fast-charge.title'),
      value: UserSettingsMode.standard,
    },
    {
      icon: ['fass', 'bolt'],
      color: theme.smartcharging.standard,
      label: t('employee.hems.charge-mode-standard.title'),
      value: UserSettingsMode.cap,
    },
    {
      icon: ['fass', 'brightness'],
      color: theme.smartcharging.sun,
      label: t('employee.hems.charge-mode-sun.title'),
      value: UserSettingsMode.solar,
    },
    {
      value: UserSettingsMode.autopilot,
      icon: ['fass', 'clock'],
      color: theme.smartcharging['departure-time'],
      label: t('employee.hems.charge-mode.autopilot.title'),
    },
  ]

  return chargeModes
}

export function formatTime(date: Date) {
  return format(date, 'HH:mm')
}

export function useDistanceToNowFormatter() {
  const { locale } = useGetDateFnsLocale()

  return function formatDistanceToNow(
    date: Date,
    options?: FormatDistanceToNowStrictOptions
  ) {
    const formatDistanceLocale: Record<FormatDistanceToken, string> = {
      lessThanXSeconds: '{{count}}s',
      xSeconds: '{{count}}s',
      halfAMinute: '30s',
      lessThanXMinutes: '{{count}}m',
      xMinutes: '{{count}}m',
      aboutXHours: '{{count}}h',
      xHours: '{{count}}h',
      xDays: '{{count}}d',
      aboutXWeeks: '{{count}}w',
      xWeeks: '{{count}}w',
      aboutXMonths: '{{count}}m',
      xMonths: '{{count}}m',
      aboutXYears: '{{count}}y',
      xYears: '{{count}}y',
      overXYears: '{{count}}y',
      almostXYears: '{{count}}y',
    }

    const formatDistance: FormatDistanceFn = (token, count) => {
      return formatDistanceLocale[token].replace('{{count}}', count.toString())
    }

    return formatDistanceToNowStrict(date, {
      locale: {
        ...locale,
        formatDistance,
      },
      ...options,
    })
  }
}
