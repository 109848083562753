import ContentLoader from 'react-content-loader'
import { styled } from 'styled-components'

import { breakpoints } from '../../../../../theme/layout/breakpoints'

export const ProductLoader = () => {
  return (
    <StContainer>
      {/* Header */}
      <StHeader
        speed={2}
        height={41}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="473" height="40" />
      </StHeader>

      {/* Filters */}
      <StFilters>
        {Array.from({ length: 4 }).map((x, index) => (
          <StFilter
            key={index}
            speed={2}
            height={53}
            viewBox="0 0 188 53"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="4" ry="4" width="188" height="53" />
          </StFilter>
        ))}
      </StFilters>

      {/* Content */}
      <StContent>
        {Array.from({ length: 3 }).map((x, index) => (
          <ContentLoader
            key={index}
            speed={2}
            width="100%"
            height={401}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="35%" y="0" rx="10" ry="10" width="30%" height="132" />
            <rect x="0" y="167" rx="10" ry="10" width="40%" height="26" />
            <rect x="0" y="209" rx="9" ry="9" width="25%" height="18" />
            <rect x="27%" y="209" rx="9" ry="9" width="29%" height="18" />
            <rect x="60%" y="209" rx="9" ry="9" width="25%" height="18" />
            <rect x="0" y="235" rx="9" ry="9" width="35%" height="18" />
            <rect x="38%" y="235" rx="9" ry="9" width="24%" height="18" />
            <rect x="0" y="289" rx="10" ry="10" width="24%" height="27" />
            <rect x="26%" y="289" rx="10" ry="10" width="20%" height="27" />
            <rect x="0" y="353" rx="6" ry="6" width="100%" height="48" />
          </ContentLoader>
        ))}
      </StContent>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};

  @media ${breakpoints.mobile} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
  }
`
const StHeader = styled(ContentLoader)`
  width: 100%;

  @media ${breakpoints.mobile} {
    width: 474px;
  }
`

const StFilters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 14px;
  }
`

const StFilter = styled(ContentLoader)`
  width: 100%;

  @media ${breakpoints.mobile} {
    width: 188px;
  }
`

const StContent = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  column-gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space14};

  @media ${breakpoints.mobile} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: ${({ theme }) => theme.UI.SpacingPx.Space18};
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }

  @media ${breakpoints.desktop} {
    grid-template-columns: repeat(3, 1fr);
    row-gap: ${({ theme }) => theme.UI.SpacingPx.Space20};
  }
`
