import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { useAxios } from '../../api/hooks/useAxios'
import { Urls } from '../../api/urls'
import { isOutdated } from '../lib/isOutdated'
import { appSettingsAtom, appSettingsLoadingAtom } from '../recoil/atoms'

export type AppSettings = {
  android: string
  ios: string
  mspOffset: number
  hcpOffset: number
  maxPeak: number
}

export const useAppSettings = () => {
  // -- State --
  const [settings, setSettings] = useRecoilState<AppSettings>(appSettingsAtom)
  const [loading, setLoading] = useRecoilState(appSettingsLoadingAtom)
  const [outdated, setOutdated] = useState(false)

  // -- Data --
  const [, getAppSettings] = useAxios<AppSettings>(Urls.AppSettings, {
    manual: true,
  })

  // -- Vars --
  const platform = Capacitor.getPlatform()

  // -- Effects --
  useEffect(() => {
    const asyncFunction = async () => {
      try {
        const { data } = await getAppSettings()

        setSettings(data)

        if (platform == 'android' || platform == 'ios') {
          const info = await App.getInfo()

          setOutdated(isOutdated(info.version, data[platform]))
          setLoading(false)
          return
        }

        setLoading(false)
      } catch {
        // This means we are not running on a native platform
        setOutdated(false)
        setLoading(false)
      }
    }

    if (Object.values(settings).length === 0) {
      asyncFunction()
    }
  }, [])

  return {
    loading,
    outdated,
    mspOffset: settings?.mspOffset,
    hcpOffset: settings?.hcpOffset,
    maxPeak: settings?.maxPeak,
  }
}
