import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'
import * as Yup from 'yup'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { Checkbox } from '../../../../components/checkbox/Checkbox'
import { Input } from '../../../../components/form/Input'
import { InfoPopup } from '../../../../components/info-popup/InfoPopup'
import {
  BodyExtraExtraSmallMedium,
  H5,
} from '../../../../components/typography'
import { useHemsConfiguration } from '../hooks/useHemsConfiguration'

export const HemsInstallationStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { flowNavigation, hemsConfigurationData, setHemsConfigurationData } =
    useHemsConfiguration()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Handlers --
  const handleSubmit = (values: {
    pv_installed: boolean
    bat_installed: boolean
    hp_installed: boolean
    hpb_installed: boolean
    ac_installed: boolean
    peak_solar_capacity: number
  }) => {
    setHemsConfigurationData((previous) => ({ ...previous, ...values }))
    flowNavigation.next()
  }

  // -- Validation --
  const validationSchema = Yup.object().shape({
    peak_solar_capacity: Yup.number()
      .min(0, t('employee.settings.smart-charging.peak-solar-capacity-min'))
      .max(50000),
  })

  // -- Render --
  return (
    <Formik
      initialValues={{
        pv_installed: hemsConfigurationData.pv_installed,
        bat_installed: hemsConfigurationData.bat_installed,
        hp_installed: hemsConfigurationData.hp_installed,
        hpb_installed: hemsConfigurationData.hpb_installed,
        ac_installed: hemsConfigurationData.ac_installed,
        peak_solar_capacity: hemsConfigurationData.peak_solar_capacity,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ setFieldValue, values, handleChange, errors, touched }) => (
        <StForm>
          <StTitle>
            {t('employee.hems.onboarding.installations-step.title')}
          </StTitle>

          <Checkbox
            name="solar"
            checked={values.pv_installed}
            onChange={(checked) => setFieldValue('pv_installed', checked)}
            label={t('employee.hems.onboarding.installations-step.solar')}
          />
          {values.pv_installed && (
            <Input
              name="peak_solar_capacity"
              type="number"
              onChange={handleChange}
              value={values.peak_solar_capacity}
              label={t(
                'employee.hems.onboarding.installations-step.solar-peak'
              )}
              valueLabel={t('employee.hems.onboarding.installations-step.va')}
              touched={touched.peak_solar_capacity}
              error={errors.peak_solar_capacity}
              hint={
                <StInfoContainer>
                  <InfoPopup
                    content={
                      <StInfoText>
                        {t(
                          'employee.hems.onboarding.installations-step.solar-peak-explanation'
                        )}
                      </StInfoText>
                    }
                  />
                </StInfoContainer>
              }
            />
          )}
          <Checkbox
            name="batery"
            checked={values.bat_installed}
            onChange={(checked) => setFieldValue('bat_installed', checked)}
            label={t('employee.hems.onboarding.installations-step.battery')}
          />
          <Checkbox
            name="heatpump"
            checked={values.hp_installed}
            onChange={(checked) => setFieldValue('hp_installed', checked)}
            label={t('employee.hems.onboarding.installations-step.heat-pump')}
          />
          <Checkbox
            name="heatpumpboiler"
            checked={values.hpb_installed}
            onChange={(checked) => setFieldValue('hpb_installed', checked)}
            label={t(
              'employee.hems.onboarding.installations-step.heat-pump-boiler'
            )}
          />
          <Checkbox
            name="airco"
            checked={values.ac_installed}
            onChange={(checked) => setFieldValue('ac_installed', checked)}
            label={t('employee.hems.onboarding.installations-step.airco')}
          />

          {isDesktop ? (
            <StFlexContainer>
              <ButtonTertiary onClick={() => flowNavigation.close()} compact>
                {t('employee.hems.onboarding.close')}
              </ButtonTertiary>
              <ButtonPrimary type="submit" compact>
                {t('employee.hems.onboarding.next')}
              </ButtonPrimary>
            </StFlexContainer>
          ) : (
            <FloatingButtonContainer>
              <ButtonPrimary type="submit">
                {t('employee.hems.onboarding.next')}
              </ButtonPrimary>
            </FloatingButtonContainer>
          )}
        </StForm>
      )}
    </Formik>
  )
}

const StForm = styled(Form)`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  /* Compensate for floating button */
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space31};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  @media ${breakpoints.desktop} {
    border: none;
    padding: 0;

    position: relative;
    height: 100%;
  }
`

const StTitle = styled(H5)`
  margin: 0;
`

const StInfoContainer = styled.div`
  margin-left: auto;

  @media ${breakpoints.desktop} {
    margin-left: 0;
  }
`

const StInfoText = styled(BodyExtraExtraSmallMedium)`
  color: ${({ theme }) => theme.theme.text.body.white};
  max-width: 250px;
`

const StFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  position: absolute;
  bottom: 0;
  right: 0;
`
