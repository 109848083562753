import { css, styled } from 'styled-components'

import { onHover } from '../../core/lib/styleHelpers'
import { BodyMediumRegularCss, BodySmallRegularCss } from '../typography'

import { Button } from './Button'

export const ButtonTertiary = styled(Button)<{
  $size?: 'lg' | 'md' | 'sm'
  spread?: boolean
}>`
  ${({ $size }) =>
    !$size || $size === 'lg' ? BodyMediumRegularCss : BodySmallRegularCss}
  justify-content: ${({ spread }) => (spread ? 'space-between' : 'center')};

  background-color: ${({ theme }) => theme.theme.colors.white};
  color: ${({ theme }) => theme.components.buttons.tertiary['default-txt']};
  border: 1px solid
    ${({ theme }) => theme.components.buttons.tertiary['default-border']};

  ${onHover(css`
    background-color: ${({ theme }) => theme.theme.colors.white};
    color: ${({ theme }) => theme.components.buttons.tertiary['hover-txt']};
    border: 1px solid
      ${({ theme }) => theme.components.buttons.tertiary['hover-border']};
  `)}

  &:disabled {
    background-color: ${({ theme }) => theme.theme.colors.white};
    color: ${({ theme }) => theme.components.buttons.tertiary['disabled-txt']};
    border: 1px solid
      ${({ theme }) => theme.components.buttons.tertiary['disabled-stroke']};
  }
`
