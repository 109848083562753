import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BigInfoCard } from '../../../../components/general/BigInfoCard'
import { QuestionsBlock } from '../../../../components/general/QuestionsBlock'
import { BodyMediumRegular } from '../../../../components/typography'
import { useUser } from '../../../../user/hooks/useUser'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'

export const RejectedStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.reject.title', { userName: user.firstName })}
      </OnboardingStepTitle>
      <StSubtitle>{t('onboarding.reject.description')}</StSubtitle>
      <StCardContainer>
        <BigInfoCard
          icon={['fasr', 'ban']}
          title={t('onboarding.reject.card-1.title')}
          bulletPoints={[
            t('onboarding.reject.card-1.item-1'),
            t('onboarding.reject.card-1.item-2'),
            t('onboarding.reject.card-1.item-3'),
          ]}
        />
        <BigInfoCard
          icon={['fasr', 'circle-user']}
          title={t('onboarding.reject.card-2.title')}
          bulletPoints={[
            t('onboarding.reject.card-2.item-1'),
            t('onboarding.reject.card-2.item-2'),
          ]}
        />
      </StCardContainer>

      <QuestionsBlock />
    </StContainer>
  )
}

const StContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StCardContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
  flex-wrap: wrap;
`

const StSubtitle = styled(BodyMediumRegular)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
`
