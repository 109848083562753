import { usePostHog } from 'posthog-js/react'

export enum EventType {
  Click = 'click',
  Submit = 'submit',
  Open = 'open',
  Close = 'close',
}

type Properties = { [data: string]: unknown }

export const useTracking = () => {
  const posthog = usePostHog()

  const trackEvent = (
    eventType: EventType,
    name: string,
    properties?: Properties
  ) => {
    posthog.capture(name, {
      type: eventType,
      ...properties,
    })
  }

  return { trackEvent }
}
