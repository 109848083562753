import { useContext } from 'react'

import { userContext } from '../providers/UserProvider'

export const useUser = () => {
  const context = useContext(userContext)

  if (!context) {
    throw new Error('useUser needs to be used inside a userProvider')
  }

  return context
}
