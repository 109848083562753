import { autoUpdate, useFloating } from '@floating-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  addDays,
  addHours,
  eachDayOfInterval,
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isToday,
  isTomorrow,
  parse,
  parseISO,
  set,
  startOfDay,
} from 'date-fns'
import { dequal } from 'dequal'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { css, styled, useTheme } from 'styled-components'
import { match } from 'ts-pattern'
import { useMediaQuery, useOnClickOutside } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../../routing/routes'
import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../../analytics/hooks/useTracking'
import { BottomSheet } from '../../../../components/bottom-sheet/BottomSheet'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { Input } from '../../../../components/form/Input'
import { Select } from '../../../../components/form/Select'
import {
  BodyExtraSmallMedium,
  BodyExtraSmallRegular,
  BodyMediumRegular,
  BodyMediumSemiBold,
  BodySmallMedium,
  BodySmallRegular,
} from '../../../../components/typography'
import { useAppSettings } from '../../../../core/hooks/useAppSettings'
import { useGetDateFnsLocale } from '../../../../core/hooks/useGetDateFnsLocale'
import { onHover } from '../../../../core/lib/styleHelpers'
import { useHems } from '../../hooks/useHems'
import { UserSettingsMode } from '../../types/enums'

import { AutopilotDateTimePicker } from './AutopilotDateTimePicker'
import { AutopilotDistancePicker } from './AutopilotDistancePicker'
import { ChargeModeSwitcherMobile } from './ChargeModeSwitcherMobile'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ScoptChargerSetpoint } from 'types'

function getAutopilotSettings(setPoints?: ScoptChargerSetpoint) {
  return {
    endDate:
      setPoints?.charge_end_date && setPoints?.charge_end_date !== '0'
        ? parseISO(setPoints.charge_end_date)
        : addHours(new Date(), 8),
    chargeKm: setPoints?.charge_need_km || 200,
  }
}

export type ChargeMode = {
  icon: IconProp
  color: string
  label: string
  value: UserSettingsMode
  descriptionParagraphs?: string[]
  caution?: string
}

export const ChargeModeSwitcher = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const {
    handleSetChargerSetPoint,
    setChargerSetpointsLoading,
    installation,
    setPoints,
  } = useHems()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const theme = useTheme()
  const navigate = useNavigate()
  const { maxPeak } = useAppSettings()
  const { locale } = useGetDateFnsLocale()
  const { trackEvent } = useTracking()

  // -- State --
  const [showChargeModeSwitcher, setShowChargeModeSwitcher] = useState(false)
  const [disableChargeModeSwitcher, setDisableChargeModeSwitcher] =
    useState(false)

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [isDistancePickerOpen, setIsDistancePickerOpen] = useState(false)

  const autopilotSettings = getAutopilotSettings(setPoints)

  const [newAutopilotSettings, setNewAutoPilotSettings] =
    useState(autopilotSettings)

  useEffect(() => {
    if (setPoints) {
      setNewAutoPilotSettings(autopilotSettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPoints])

  // -- Variables --
  const inputRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef(null)

  const chargeModes: ChargeMode[] = useMemo(
    () => [
      {
        icon: ['fass', 'charging-station'],
        color: theme.theme.smartcharging.standard,
        label: t('employee.hems.charge-mode-standard.title'),
        value: UserSettingsMode.cap,
        descriptionParagraphs: [
          t('employee.hems.charge-mode-standard.description.1'),
          t('employee.hems.charge-mode-standard.description.2'),
        ],
      },
      {
        icon: ['fass', 'fast-forward'],
        color: theme.theme.smartcharging['quick-charging'],
        label: t('employee.hems.charge-mode-fast-charge.title'),
        value: UserSettingsMode.standard,
        descriptionParagraphs: [
          t('employee.hems.charge-mode-fast-charge.description.1'),
        ],
        caution: t('employee.hems.charge-mode-fast-charge.caution'),
      },
      {
        icon: ['fass', 'brightness'],
        color: theme.theme.smartcharging.sun,
        label: t('employee.hems.charge-mode-sun.title'),
        value: UserSettingsMode.solar,
        descriptionParagraphs: [
          t('employee.hems.charge-mode-sun.description.1'),
        ],
        caution: t('employee.hems.charge-mode-sun.caution'),
      },
      {
        value: UserSettingsMode.autopilot,
        icon: ['fass', 'sparkles'],
        color: theme.theme.smartcharging['departure-time'],
        label: t('employee.hems.charge-mode.autopilot.title'),
        descriptionParagraphs: [
          t('employee.hems.charge-mode.autopilot.description.1'),
        ],
        caution: t('employee.hems.charge-mode.autopilot.caution'),
      },
    ],
    [t, theme.theme.smartcharging]
  )

  const [selectedChargeMode, setSelectedChargeMode] = useState<ChargeMode>(
    chargeModes.find((chargeMode) => chargeMode.value === UserSettingsMode.cap)!
  )

  // -- Memos --
  const activeChargingMode = useMemo<ChargeMode>(() => {
    const setPointMode = setPoints?.user_setting_mode || UserSettingsMode.cap

    return chargeModes.find((chargeMode) => chargeMode.value === setPointMode)!
  }, [chargeModes, setPoints?.user_setting_mode])

  const availableChargeModes = useMemo<ChargeMode[]>(() => {
    return chargeModes.filter(
      (chargeMode) =>
        !(
          !installation?.pv_installed &&
          chargeMode.value === UserSettingsMode.solar
        )
    )
  }, [chargeModes, installation?.pv_installed])

  const isDirty =
    selectedChargeMode.value !== activeChargingMode.value ||
    !dequal(newAutopilotSettings, autopilotSettings)

  useEffect(() => {
    setSelectedChargeMode(activeChargingMode)
  }, [activeChargingMode])

  // -- Floating --
  useOnClickOutside(
    containerRef,
    () => isDesktop && setShowChargeModeSwitcher(false)
  )

  const { refs, floatingStyles } = useFloating({
    open: showChargeModeSwitcher,
    onOpenChange: setShowChargeModeSwitcher,
    placement: 'bottom-start',
    strategy: 'absolute',
    whileElementsMounted(referenceElement, floatingElement, update) {
      return autoUpdate(referenceElement, floatingElement, update, {
        animationFrame: true,
      })
    },
  })

  // -- Handlers --
  async function handleConfirmChargeMode() {
    setDisableChargeModeSwitcher(true)

    const options = match(selectedChargeMode.value)
      .with(UserSettingsMode.autopilot, (value) => ({
        mode: value,
        settings: {
          endDate: newAutopilotSettings.endDate.toISOString(),
          chargeKm: newAutopilotSettings.chargeKm,
        },
      }))
      .with(UserSettingsMode.cap, (value) => ({
        mode: value,
        settings: {
          cap: setPoints?.user_setting_cap_value || maxPeak,
        },
      }))
      .otherwise((value) => ({ mode: value, settings: undefined }))

    trackEvent(EventType.Submit, `change_charge_mode`, {
      mode: selectedChargeMode.value,
    })

    await handleSetChargerSetPoint(options)

    setShowChargeModeSwitcher(false)

    setDisableChargeModeSwitcher(false)
  }

  function formatDateLabel(date: Date) {
    const time = format(date, 'HH:mm')

    if (isToday(date)) {
      return `${t('employee.hems.charge-mode.autopilot.today')} ${time}`
    }

    if (isTomorrow(date)) {
      return `${t('employee.hems.charge-mode.autopilot.tomorrow')} ${format(
        date,
        'HH:mm'
      )}`
    }

    return format(date, 'd MMM HH:mm', { locale })
  }

  const departureOptions = useMemo(() => {
    const now = new Date()

    return eachDayOfInterval({
      start: now,
      end: addDays(now, 8),
    }).map((date) => {
      const label = isToday(date)
        ? t('employee.hems.charge-mode.autopilot.today')
        : isTomorrow(date)
        ? t('employee.hems.charge-mode.autopilot.tomorrow')
        : format(date, 'eeee d MMMM yyyy', { locale })

      return { key: startOfDay(date).toISOString(), label }
    })
  }, [t, locale])

  // Show the selected charge mode on desktop for confirmation.
  // Always show the active charge mode on mobile, as the confirmation
  // action occurs on the bottom sheet.
  // (otherwise the charge mode visually changes in the background while selecting one from the bottom sheet)
  const responseChargeMode = isDesktop ? selectedChargeMode : activeChargingMode

  // -- Render --
  return (
    <>
      <StContainer>
        <StSwitcherContainer
          ref={containerRef}
          $open={isDesktop && showChargeModeSwitcher}
        >
          <div ref={refs.setReference}>
            <StSwitcher
              ref={inputRef}
              $open={isDesktop && showChargeModeSwitcher}
              $disabled={disableChargeModeSwitcher}
              onClick={() =>
                !disableChargeModeSwitcher &&
                setShowChargeModeSwitcher(!showChargeModeSwitcher)
              }
            >
              {/* Selected Charge Mode */}
              <StChargeModeSymbol $color={responseChargeMode.color}>
                <FontAwesomeIcon icon={responseChargeMode.icon} />
              </StChargeModeSymbol>
              <StChargeModeText>
                <BodyExtraSmallRegular>
                  {t('employee.hems.charge-mode.mode')}
                </BodyExtraSmallRegular>
                <BodyMediumSemiBold>
                  {responseChargeMode.label}
                </BodyMediumSemiBold>
              </StChargeModeText>
              <StChevronContainer>
                <FontAwesomeIcon icon={['fass', 'chevron-down']} />
              </StChevronContainer>
            </StSwitcher>
          </div>

          {/* Charge mode options on desktop */}
          {isDesktop && showChargeModeSwitcher && (
            <StSwitcherOptions
              $width={inputRef.current?.offsetWidth}
              ref={refs.setFloating}
              style={floatingStyles}
            >
              {availableChargeModes
                .filter(
                  (chargeMode) => chargeMode.value !== selectedChargeMode.value
                )
                .map((chargeMode) => (
                  <StSwitcherOption
                    key={'charge_mode_option_' + chargeMode.value}
                    onClick={() => {
                      setSelectedChargeMode(chargeMode)
                      setShowChargeModeSwitcher(false)
                    }}
                  >
                    <StChargeModeSymbol $color={chargeMode.color}>
                      <FontAwesomeIcon icon={chargeMode.icon} />
                    </StChargeModeSymbol>
                    <StChargeModeText>
                      <BodyMediumRegular>{chargeMode.label}</BodyMediumRegular>
                    </StChargeModeText>
                  </StSwitcherOption>
                ))}
            </StSwitcherOptions>
          )}
        </StSwitcherContainer>

        {/* Active Charge Mode information */}
        {responseChargeMode.descriptionParagraphs?.map((paragraph, index) => (
          <BodyMediumRegular key={'charge_mode_description_' + index}>
            {paragraph}
          </BodyMediumRegular>
        ))}

        {responseChargeMode.value === UserSettingsMode.autopilot ? (
          isDesktop ? (
            <StAutopilotDesktopInputsContainer>
              <StAutopilotDesktopLabelInputContainer>
                <BodySmallRegular>
                  {t('employee.hems.charge-mode.autopilot.departure')}
                </BodySmallRegular>
                <Select
                  size="md"
                  value={
                    departureOptions.find(
                      (option) =>
                        option.key ===
                        startOfDay(newAutopilotSettings.endDate).toISOString()
                    )?.key ?? startOfDay(new Date()).toISOString()
                  }
                  options={departureOptions}
                  onChange={async (value) => {
                    if (value) {
                      const parseDate = parseISO(value)

                      const newEndDate = set(newAutopilotSettings.endDate, {
                        year: getYear(parseDate),
                        month: getMonth(parseDate),
                        date: getDate(parseDate),
                      })

                      const newSettings = {
                        ...newAutopilotSettings,
                        endDate: newEndDate,
                      }

                      setNewAutoPilotSettings(newSettings)
                    }
                  }}
                />
                <Input
                  name="time"
                  value={`${format(newAutopilotSettings.endDate, 'HH:mm')}`}
                  type="time"
                  size="sm"
                  onChange={async (event) => {
                    if (event.target.value) {
                      const parsedDate = parse(
                        event.target.value,
                        'HH:mm',
                        new Date()
                      )

                      const newEndDate = set(newAutopilotSettings.endDate, {
                        hours: getHours(parsedDate),
                        minutes: getMinutes(parsedDate),
                      })

                      const newSettings = {
                        ...newAutopilotSettings,
                        endDate: newEndDate,
                      }
                      setNewAutoPilotSettings(newSettings)
                    }
                  }}
                />
              </StAutopilotDesktopLabelInputContainer>
              <StAutopilotDesktopLabelInputContainer>
                <StBodySmallRegularNoWrap>
                  {t('employee.hems.charge-mode.autopilot.km-added')}
                </StBodySmallRegularNoWrap>
                <StDistanceInput
                  name="distance"
                  size="sm"
                  type="number"
                  value={newAutopilotSettings.chargeKm}
                  valueLabel={t('employee.hems.charge-mode.autopilot.km')}
                  min={1}
                  onChange={(event) => {
                    const newDistance = event.target.valueAsNumber

                    if (!Number.isNaN(newDistance)) {
                      const newSettings = {
                        ...newAutopilotSettings,
                        chargeKm: newDistance,
                      }

                      setNewAutoPilotSettings(newSettings)
                    }
                  }}
                />
              </StAutopilotDesktopLabelInputContainer>
            </StAutopilotDesktopInputsContainer>
          ) : (
            <StAutopilotMobileInputsContainer>
              <StAutopilotMobileLabelInputContainer>
                <BodySmallRegular>
                  {t('employee.hems.charge-mode.autopilot.departure')}
                </BodySmallRegular>
                <StAutopilotInputTrigger $isSelected={false}>
                  <StBodySmallMedium>
                    {formatDateLabel(
                      newAutopilotSettings.endDate
                    ).toLowerCase()}
                  </StBodySmallMedium>
                </StAutopilotInputTrigger>
              </StAutopilotMobileLabelInputContainer>
              <StAutopilotMobileLabelInputContainer>
                <BodySmallRegular>
                  {t('employee.hems.charge-mode.autopilot.km-added')}
                </BodySmallRegular>
                <StAutopilotInputTrigger $isSelected={false}>
                  <StBodySmallMedium>
                    {newAutopilotSettings.chargeKm}{' '}
                    {t('employee.hems.charge-mode.autopilot.km')}
                  </StBodySmallMedium>
                </StAutopilotInputTrigger>
              </StAutopilotMobileLabelInputContainer>
            </StAutopilotMobileInputsContainer>
          )
        ) : null}

        {responseChargeMode.caution && (
          <StCaution>
            <BodyMediumSemiBold>
              {t('employee.hems.charge-mode.caution') + ' '}
            </BodyMediumSemiBold>
            <BodyMediumRegular>{responseChargeMode.caution}</BodyMediumRegular>
          </StCaution>
        )}

        {responseChargeMode.value === UserSettingsMode.cap && (
          <StPeakContainer>
            <StPeakLabel>
              <Trans
                t={t}
                i18nKey="employee.hems.charge-mode.peak-label"
                values={{
                  peak: (setPoints?.user_setting_cap_value ?? 0) / 1000,
                }}
                components={{
                  strong: <strong />,
                }}
              />
            </StPeakLabel>
            <ButtonTertiary
              spread
              size="md"
              icon={['fass', 'chevron-right']}
              onClick={() => navigate(EmployeeRoutes.Peak)}
            >
              {t('employee.hems.charge-mode.peak-cta')}
            </ButtonTertiary>
          </StPeakContainer>
        )}

        {isDesktop ? (
          <ButtonPrimary
            disabled={!isDirty || setChargerSetpointsLoading}
            onClick={async () => {
              await handleConfirmChargeMode()
            }}
          >
            {t('employee.hems.charge-mode.action.save-changes')}
          </ButtonPrimary>
        ) : null}
      </StContainer>

      {/* BottomSheet Charge Mode selector on mobile */}
      {!isDesktop && (
        <BottomSheet
          isOpen={showChargeModeSwitcher}
          onClose={() => {
            setShowChargeModeSwitcher(false)
            // Reset the selected charge mode
            setSelectedChargeMode(activeChargingMode)
          }}
          hasBackdrop={true}
          detent="content-height"
          title="Modus"
          disableDrag={isDistancePickerOpen || isDatePickerOpen}
        >
          <StBottomSheetContainer>
            <>
              <StBottomSheetContent>
                <ChargeModeSwitcherMobile
                  value={selectedChargeMode}
                  options={availableChargeModes}
                  disabled={disableChargeModeSwitcher}
                  onChange={(chargeMode) => {
                    setSelectedChargeMode(chargeMode)
                  }}
                />
                {selectedChargeMode.value === UserSettingsMode.autopilot ? (
                  <>
                    <StAutopilotInputContainer>
                      <BodyExtraSmallRegular>
                        {t('employee.hems.charge-mode.autopilot.departure')}
                      </BodyExtraSmallRegular>
                      <StAutopilotInputTrigger
                        $isSelected={isDatePickerOpen}
                        onClick={() => {
                          setIsDistancePickerOpen(false)
                          setIsDatePickerOpen(true)
                        }}
                      >
                        <StBodyExtraSmallMedium>
                          {formatDateLabel(newAutopilotSettings.endDate)}
                        </StBodyExtraSmallMedium>
                      </StAutopilotInputTrigger>
                      <StAutopilotInputTrigger
                        $isSelected={isDistancePickerOpen}
                        onClick={() => {
                          setIsDatePickerOpen(false)
                          setIsDistancePickerOpen(true)
                        }}
                      >
                        <StBodyExtraSmallMedium>
                          {newAutopilotSettings.chargeKm}{' '}
                          {t('employee.hems.charge-mode.autopilot.km')}
                        </StBodyExtraSmallMedium>
                      </StAutopilotInputTrigger>
                      <BodyExtraSmallRegular>
                        {t('employee.hems.charge-mode.autopilot.recharge')}
                      </BodyExtraSmallRegular>
                    </StAutopilotInputContainer>

                    {isDatePickerOpen ? (
                      <StAutopilotContainer>
                        <AutopilotDateTimePicker
                          value={newAutopilotSettings.endDate}
                          onChange={(newEndDate) =>
                            setNewAutoPilotSettings((settings) => ({
                              ...settings,
                              endDate: newEndDate,
                            }))
                          }
                        />
                      </StAutopilotContainer>
                    ) : null}
                    {isDistancePickerOpen ? (
                      <StAutopilotContainer>
                        <AutopilotDistancePicker
                          value={newAutopilotSettings.chargeKm}
                          onChange={(chargeKm) =>
                            setNewAutoPilotSettings((settings) => ({
                              ...settings,
                              chargeKm,
                            }))
                          }
                        />
                      </StAutopilotContainer>
                    ) : null}
                  </>
                ) : null}
              </StBottomSheetContent>

              <ButtonPrimary
                loading={setChargerSetpointsLoading}
                disabled={!isDirty || setChargerSetpointsLoading}
                onClick={async () => {
                  await handleConfirmChargeMode()
                }}
              >
                {t('employee.hems.charge-mode.action.confirm')}
              </ButtonPrimary>
            </>
          </StBottomSheetContainer>
        </BottomSheet>
      )}
    </>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
  max-width: 600px;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0);
`

const StBottomSheetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

// -- Switcher --
const StSwitcherContainer = styled.div<{ $open: boolean }>`
  overflow: hidden;

  border-top-left-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  border-top-right-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  ${({ $open }) =>
    $open
      ? css`
          box-shadow: 64px 60px 25px 0px rgba(0, 0, 0, 0),
            41px 39px 23px 0px rgba(0, 0, 0, 0.01),
            23px 22px 19px 0px rgba(0, 0, 0, 0.05),
            10px 10px 14px 0px rgba(0, 0, 0, 0.09),
            3px 2px 8px 0px rgba(0, 0, 0, 0.1);
        `
      : undefined}
`

const StChevronContainer = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.UI.SpacingPx.Space5};
  transform: translateY(-50%);

  color: ${({ theme }) => theme.theme.colors['nonary-5']};
`

const StSwitcher = styled.div<{ $open: boolean; $disabled: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  align-items: center;

  position: relative;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};

  cursor: pointer;

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  ${({ $open }) =>
    $open
      ? css`
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: none;

          ${StChevronContainer} {
            color: ${({ theme }) => theme.theme.colors['secondary-0']};
          }
        `
      : undefined}

  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: 0.5;
          cursor: default;
        `
      : undefined}

  ${({ $disabled }) =>
    onHover(css`
      ${StChevronContainer} {
        color: ${({ theme }) =>
          $disabled
            ? theme.theme.colors['nonary-5']
            : theme.theme.colors['secondary-0']};
      }
    `)}
`

const StSwitcherOptions = styled.div<{ $width?: number }>`
  display: flex;
  flex-direction: column;

  // Make sure the options are on top of the switcher
  z-index: 1;

  width: ${({ $width }) => $width}px;

  background-color: ${({ theme }) => theme.theme.colors.white};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-bottom-left-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  border-bottom-right-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  box-shadow: 64px 60px 25px 0px rgba(0, 0, 0, 0),
    41px 39px 23px 0px rgba(0, 0, 0, 0.01),
    23px 22px 19px 0px rgba(0, 0, 0, 0.05),
    10px 10px 14px 0px rgba(0, 0, 0, 0.09), 3px 2px 8px 0px rgba(0, 0, 0, 0.1);
`

const StSwitcherOption = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  align-items: center;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  cursor: pointer;

  ${onHover(css`
    background-color: ${({ theme }) => theme.theme.colors['nonary-10']};
    & p {
      font-weight: 600;
    }
  `)}
`

const StChargeModeSymbol = styled.div<{ $color?: string; $large?: boolean }>`
  width: ${({ theme, $large }) =>
    $large ? theme.UI.SpacingPx.Space13 : theme.UI.SpacingPx.Space9};
  height: ${({ theme, $large }) =>
    $large ? theme.UI.SpacingPx.Space13 : theme.UI.SpacingPx.Space9};
  border-radius: 50%;

  background-color: ${({ $color }) => $color ?? 'transparent'};
  border: ${({ theme, $color }) =>
    $color ? 'none' : `1px solid ${theme.theme.colors['nonary-7']}`};

  display: grid;
  place-content: center;
`

const StChargeModeText = styled.div`
  display: flex;
  flex-direction: column;
`

const StBottomSheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StCaution = styled.div`
  > p {
    display: inline;
  }
`

const StPeakContainer = styled.div`
  background-color: ${({ theme }) => theme.theme.colors['nonary-10']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StPeakLabel = styled(BodySmallRegular)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StAutopilotContainer = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  background-color: ${({ theme }) => theme.theme.colors['nonary-10']};
`

const StAutopilotInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StAutopilotDesktopInputsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  flex-wrap: wrap;
`

const StAutopilotDesktopLabelInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StBodySmallRegularNoWrap = styled(BodySmallRegular)`
  white-space: nowrap;
`

const StDistanceInput = styled(Input)`
  width: 85px;
`

const StAutopilotMobileInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StAutopilotMobileLabelInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StAutopilotInputTrigger = styled.div<{ $isSelected: boolean }>`
  display: flex;
  padding: 8px 14px;

  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;

  ${({ theme, $isSelected }) =>
    $isSelected
      ? css`
          p {
            color: ${theme.theme.colors['secondary-0']};
          }

          border: 1px solid ${theme.theme.colors['secondary-0']};
        `
      : css`
          border: 1px solid ${theme.theme.colors['nonary-7']};
        `}
`

const StBodyExtraSmallMedium = styled(BodyExtraSmallMedium)`
  color: ${({ theme }) => theme.theme.colors['nonary-2']};
`

const StBodySmallMedium = styled(BodySmallMedium)`
  color: ${({ theme }) => theme.theme.colors['nonary-2']};
`
