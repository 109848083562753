import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import * as Yup from 'yup'

import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { Input } from '../../../../components/form/Input'
import { BodySmallRegular, H4 } from '../../../../components/typography'
import { useHemsFlow } from '../hooks/useHemsFlow'
import { isValidMacAddress } from '../lib/mac-address-helpers'

export const MacAddressStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { macAddress, setMacAddress, flowNavigation } = useHemsFlow()

  // -- Validation schema --
  const validationSchema = Yup.object().shape({
    mac: Yup.string()
      .min(17, t('employee.hems.dongle-onboarding.mac-address.format-error'))
      .max(17, t('employee.hems.dongle-onboarding.mac-address.format-error'))
      .required(t('employee.hems.dongle-onboarding.mac-address.required-error'))
      .test({
        test: isValidMacAddress,
        message: t('employee.hems.dongle-onboarding.mac-address.format-error'),
      }),
  })

  // -- Handlers --
  const handleSubmit = (mac: string) => {
    setMacAddress(mac)
    flowNavigation.next()
  }

  // -- Render --
  return (
    <StContainer>
      <StTextContainer>
        <H4>{t('employee.hems.dongle-onboarding.mac-address.title')}</H4>
        <BodySmallRegular>
          {t('employee.hems.dongle-onboarding.mac-address.description')}
        </BodySmallRegular>
      </StTextContainer>
      <Formik
        initialValues={{ mac: macAddress || '' }}
        onSubmit={(values) => handleSubmit(values.mac)}
        validationSchema={validationSchema}
        validateOnBlur
      >
        {({ errors, values, handleBlur, touched, handleChange }) => (
          <Form>
            <Input
              name="mac"
              onChange={handleChange}
              value={values.mac}
              error={errors.mac}
              touched={touched.mac}
              onBlur={handleBlur}
              placeholder={t(
                'employee.hems.dongle-onboarding.mac-address.placeholder'
              )}
            />
            <FloatingButtonContainer>
              <ButtonPrimary type="submit">
                {t('employee.hems.dongle-onboarding.mac-address.next')}
              </ButtonPrimary>
            </FloatingButtonContainer>
          </Form>
        )}
      </Formik>
    </StContainer>
  )
}

const StContainer = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StTextContainer = styled.div`
  h4 {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`
