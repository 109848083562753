import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { SelectBoxes } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

export enum ChargerLocationKeys {
  Garage = 'Garage',
  Outdoor = 'Outdoor',
  Carport = 'Carport',
  Underground = 'Underground',
  Other = 'Other',
}

export const ChargerLocationStep = () => {
  // -- Data --
  const {
    setLoading,
    handleNext,
    values,
    items: { chargerLocationItems },
  } = useOnboarding()
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- State --
  const [selectedItem, setSelectedItem] = useState(values.chargerLocationKey)

  // -- Hooks --
  const { t } = useTranslation()

  // -- Vars --

  // -- Handlers --
  const handleSelect = async (key: ChargerLocationKeys) => {
    try {
      setSelectedItem(key)
      setLoading(true)

      if (key !== values.chargerLocationKey) {
        await execute({
          data: {
            chargerLocationKey: key,
          },
        })
      }

      if (key === ChargerLocationKeys.Other) {
        handleNext({ ...values, chargerLocationKey: key }, true)
      } else {
        // Also reset the changer location in case it was previously set
        handleNext({ ...values, chargerLocationKey: key, chargerLocation: '' })
      }
    } catch {
      setLoading(false)
      return console.error('Failed to save charger location')
    }
  }

  // -- Render --
  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.location.title')}
      </OnboardingStepTitle>

      <SelectBoxes
        items={chargerLocationItems}
        onSelect={handleSelect}
        selectedItem={selectedItem}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  height: 100%;
`
