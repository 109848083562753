import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import hourglass from '../../../components/assets/hourglass.svg'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { BodyMediumRegular, H5 } from '../../../components/typography'

type NotOperationalCardProps = {
  onContactClick: () => void
}

export const NotOperationalCard = ({
  onContactClick,
}: NotOperationalCardProps) => {
  const { t } = useTranslation()

  return (
    <StNotOperationalCard>
      <img src={hourglass} alt="Charger not operational" />
      <StNotOperationalMessage>
        <StNotOperationalTitle>
          {t('employee.profile.charger.not-operational.title')}
        </StNotOperationalTitle>
        <BodyMediumRegular>
          {t('employee.profile.charger.not-operational.description')}
        </BodyMediumRegular>
      </StNotOperationalMessage>
      <StNotOperationalButton onClick={onContactClick}>
        {t('employee.profile.charger.not-operational.contact')}
      </StNotOperationalButton>
    </StNotOperationalCard>
  )
}

const StNotOperationalCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space10} ${theme.UI.SpacingPx.Space7}`};
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  text-align: center;
`

const StNotOperationalMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StNotOperationalTitle = styled(H5)`
  margin: 0;
`

const StNotOperationalButton = styled(ButtonPrimary)`
  @media ${breakpoints.desktop} {
    width: initial;
  }
`
