import { useNavigate, useSearchParams } from 'react-router-dom'

import { EmployeeRoutes } from '../../../../../routing/routes'
import { EventType, useTracking } from '../../../../analytics/hooks/useTracking'
import { useUser } from '../../../../user/hooks/useUser'
import { useHems } from '../../hooks/useHems'

import type { ScoptInstallation } from 'types'

export enum HemsIntroductionSteps {
  // Info steps
  ActiveSessionInfo = 'ActiveSessionInfo',
  UsageGraphInfo = 'UsageGraphInfo',
  SetStateInfo = 'SetStateInfo',
}

type HemsIntroductionConfigOption = {
  next: (alternative?: boolean) => HemsIntroductionSteps
  previous: (alternative?: boolean) => HemsIntroductionSteps
  showHeader?: boolean
  nextString?: string
}

export const useHemsIntroductionState = () => {
  // -- Hooks --
  const navigate = useNavigate()
  const { refetch } = useUser()
  const { trackEvent } = useTracking()

  // -- Data --
  const { installation, handleUpdateInstallation, handleCompleteIntroduction } =
    useHems()

  // -- State --
  const [formState, setFormState] = useSearchParams()

  const currentStep =
    (formState.get('step') as HemsIntroductionSteps) ||
    HemsIntroductionSteps.ActiveSessionInfo

  const stateMachine: Record<
    HemsIntroductionSteps,
    HemsIntroductionConfigOption
  > = {
    [HemsIntroductionSteps.ActiveSessionInfo]: {
      next: () => HemsIntroductionSteps.UsageGraphInfo,
      previous: () => HemsIntroductionSteps.ActiveSessionInfo,
    },
    [HemsIntroductionSteps.UsageGraphInfo]: {
      next: () => HemsIntroductionSteps.SetStateInfo,
      previous: () => HemsIntroductionSteps.ActiveSessionInfo,
    },
    [HemsIntroductionSteps.SetStateInfo]: {
      next: () => HemsIntroductionSteps.SetStateInfo,
      previous: () => HemsIntroductionSteps.UsageGraphInfo,
    },
  }

  const currentStepConfig = stateMachine[currentStep]

  // -- Functions --
  const handleNext = async (data?: Partial<ScoptInstallation>) => {
    const nextStep = stateMachine[currentStep].next()

    if (currentStep === HemsIntroductionSteps.SetStateInfo) {
      trackEvent(EventType.Submit, 'complete_hems_introduction')
      await handleCompleteIntroduction()
      await refetch()

      navigate(EmployeeRoutes.HEMS)

      // We'll close the modal on the final step
      return true
    } else {
      if (data) {
        await handleUpdateInstallation(data)
      }
      setFormState({ step: nextStep })
    }
  }

  const handlePrevious = () => {
    const previousStep = stateMachine[currentStep].previous()

    setFormState({ step: previousStep })
  }

  return {
    values: installation,
    currentStep: currentStep,
    currentStepConfig,
    handleNext,
    handlePrevious,
  }
}
