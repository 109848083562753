import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { BodySmallRegular, H4 } from '../../../../components/typography'
import dongle from '../assets/dongle-front.png'
import { useHemsFlow } from '../hooks/useHemsFlow'

export const ConnectionSelectionStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { flowNavigation } = useHemsFlow()

  // -- Handlers --
  const handleNext = (method: 'wifi' | 'utp') => {
    flowNavigation.next(method === 'utp')
  }

  // -- Render --
  return (
    <>
      <StTextContainer>
        <H4>
          {t('employee.hems.dongle-onboarding.connection-selection.title')}
        </H4>
        <BodySmallRegular>
          {t(
            'employee.hems.dongle-onboarding.connection-selection.description'
          )}
        </BodySmallRegular>
      </StTextContainer>
      <StImage src={dongle} alt="dongle" />
      <FloatingButtonContainer>
        <ButtonTertiary
          style={{ marginBottom: '12px' }}
          onClick={() => handleNext('wifi')}
        >
          {t(
            'employee.hems.dongle-onboarding.connection-selection.wifi-button'
          )}
        </ButtonTertiary>
        <ButtonPrimary onClick={() => handleNext('utp')}>
          {t('employee.hems.dongle-onboarding.connection-selection.utp-button')}
        </ButtonPrimary>
      </FloatingButtonContainer>
    </>
  )
}

const StTextContainer = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  h4 {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 50%;

  object-fit: contain;
`
