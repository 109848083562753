import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { replaceParametersInPath } from '../../../routing/lib/replaceIdInPath'
import { useAuthMemberAxios } from '../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../api/urls'

import type { ZohoHcpQuote } from 'types'

export const useQuote = (memberId: string) => {
  // -- State --
  const [quote, setQuote] = useState<ZohoHcpQuote>()
  const [loading, setLoading] = useState(true)

  // -- Hooks --
  const { t } = useTranslation()

  // -- Data --
  const [{ error, data }] = useAuthMemberAxios<ZohoHcpQuote>({
    url: replaceParametersInPath(Urls.getQuoteByMemberId, {
      memberId,
    }),
  })

  const [{ loading: approveLoading }, executeApprove] =
    useAuthMemberAxios<ZohoHcpQuote>(
      {
        method: 'POST',
      },
      { manual: true }
    )

  // -- Effects --
  useEffect(() => {
    if (data) {
      setQuote(data)
      setLoading(false)
    }
  }, [data])

  // -- Functions --
  const approveQuote = async () => {
    try {
      if (quote) {
        const { data } = await executeApprove({
          url: replaceParametersInPath(Urls.approveQuote, { memberId }),
        })

        setQuote(data)
      }
    } catch {
      toast.error(t('admin.employee-detail.quote.approve-error'))
      return console.error('Failed to approve quote')
    }
  }

  return { quote, loading, error, approveQuote, approveLoading }
}
