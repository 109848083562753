import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { H5 } from '../../../../components/typography'
import { SelectBoxes } from '../../../onboarding/components/SelectBoxes'
import { ElectricityContract } from '../../types/enums'
import { useHemsConfiguration } from '../hooks/useHemsConfiguration'

import type { SelectBoxesItems } from '../../../onboarding/components/SelectBoxes'

export const HemsContractStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const {
    hemsConfigurationData,
    setHemsConfigurationData,
    flowNavigation,
    configureHems,
  } = useHemsConfiguration()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- State --
  const [selected, setSelected] = useState<ElectricityContract | undefined>(
    hemsConfigurationData.electricity_contract
  )
  const [loading, setLoading] = useState(false)

  // -- Handlers --
  const handleSubmit = async () => {
    if (selected) {
      setHemsConfigurationData((previous) => ({
        ...previous,
        electricity_contract: selected,
      }))

      if (selected === ElectricityContract.dynamic) {
        try {
          setLoading(true)
          await configureHems({
            ...hemsConfigurationData,
            electricity_contract: selected,
          })

          flowNavigation.next(true)
        } catch {
          toast.error(t('employee.hems.onboarding.configuration.error'))
        }
      } else {
        flowNavigation.next()
      }
    }
  }

  // -- Vars --
  const options: SelectBoxesItems<ElectricityContract>[] = [
    {
      key: ElectricityContract.variable,
      name: t('employee.hems.onboarding.contract-step.variable'),
    },
    {
      key: ElectricityContract.dynamic,
      name: t('employee.hems.onboarding.contract-step.dynamic'),
    },
    {
      key: ElectricityContract.fixed,
      name: t('employee.hems.onboarding.contract-step.fixed'),
    },
  ]

  // -- Render --
  return (
    <StContainer>
      <StTitle>{t('employee.hems.onboarding.contract-step.title')}</StTitle>

      <SelectBoxes
        small
        vertical
        items={options}
        onSelect={setSelected}
        selectedItem={selected}
      />

      {isDesktop ? (
        <StFlexRowContainer>
          <ButtonTertiary onClick={() => flowNavigation.close()} compact>
            {t('employee.hems.onboarding.close')}
          </ButtonTertiary>
          <ButtonPrimary
            type="submit"
            onClick={handleSubmit}
            compact
            disabled={!selected}
          >
            {t('employee.hems.onboarding.next')}
          </ButtonPrimary>
        </StFlexRowContainer>
      ) : (
        <FloatingButtonContainer>
          <ButtonPrimary
            type="submit"
            disabled={!selected}
            onClick={handleSubmit}
            loading={loading}
          >
            {t('employee.hems.onboarding.next')}
          </ButtonPrimary>
        </FloatingButtonContainer>
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  /* Compensate for floating button */
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space31};

  border-top: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};

  @media ${breakpoints.desktop} {
    border: none;
    padding: 0;
  }
`

const StFlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  position: absolute;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  right: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StTitle = styled(H5)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
