import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { H4 } from '../../../../components/typography'
import example1 from '../../assets/examples/example1.png'
import example2 from '../../assets/examples/example2.png'
import example3 from '../../assets/examples/example3.png'
import { MobileUploadContent } from '../../components/upload/MobileUploadContent'
import { MobileUploadLoader } from '../../components/upload/MobileUploadLoader'
import { MobileUploadSuccess } from '../../components/upload/MobileUploadSuccess'
import { useTokenImagePolling } from '../../hooks/useTokenImagePolling'
import { useTokenMultiImageUpload } from '../../hooks/useTokenMultiImageUpload'
import { useTokenOnboarding } from '../../hooks/useTokenOnboarding'
import { TOTAL_STEPS } from '../upload/ExtraImagesStep'

const CURRENT_STEP = 1
const examples = [example1, example2, example3]

export const TokenElectricityBoxImageStep = () => {
  // -- Hooks --
  const { values, handleNext, token } = useTokenOnboarding()
  const { t } = useTranslation()
  useTokenImagePolling()

  const { loading, handleAddImage, handleRemoveImage } =
    useTokenMultiImageUpload('electricalBox', 'electricalBoxImageIds', token)

  // -- Handlers --
  const handleSubmit = () => {
    handleNext(values)
  }

  // -- Render --
  if (loading) {
    return (
      <MobileUploadLoader currentStep={CURRENT_STEP} totalSteps={TOTAL_STEPS} />
    )
  }

  if (values.electricalBoxImageIds?.length) {
    return (
      <MobileUploadSuccess
        imageIds={values.electricalBoxImageIds}
        currentStep={CURRENT_STEP}
        totalSteps={TOTAL_STEPS}
        title={t('onboarding.electricitybox-image.title')}
        onSubmit={handleSubmit}
        onDelete={handleRemoveImage}
        onAddImage={
          values.electricalBoxImageIds.length >= 5 ? undefined : handleAddImage
        }
        token={token}
      />
    )
  }

  return (
    <>
      <StH4>{t('onboarding.mobile-images.title')}</StH4>
      <MobileUploadContent
        uploadPicture={handleAddImage}
        title={t('onboarding.electricitybox-image.title')}
        description={t('onboarding.electricitybox-image.description')}
        currentStep={CURRENT_STEP}
        totalSteps={TOTAL_STEPS}
        examples={examples}
      />
    </>
  )
}

const StH4 = styled(H4)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
`
