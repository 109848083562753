import { Outlet, Navigate } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

import { MobileBlocked } from '../../modules/components/error/MobileBlocked'
import { useMember } from '../../modules/member/hooks/useMember'
import { breakpoints } from '../../theme/layout/breakpoints'
import { UnauthenticatedRoutes } from '../routes'

export const EmployerGuard = () => {
  // -- Hooks --
  const { currentMember } = useMember()
  const isTablet = useMediaQuery(breakpoints.tablet)

  // -- Render --

  if (currentMember.roles.includes('Employee')) {
    return <Navigate to={UnauthenticatedRoutes.Root} replace />
  }

  if (!isTablet) {
    return <MobileBlocked />
  }

  return <Outlet />
}
