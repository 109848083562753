import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../components/typography'
import { applyVat, formatDecimals } from '../../../core/utils/number'

import type { OptimileEvsesChargingPoint } from 'types'

type ChargerGroupCardProps = {
  group: OptimileEvsesChargingPoint[]
}

export const ChargerGroupCard = ({ group }: ChargerGroupCardProps) => {
  //-- Hooks --
  const { t } = useTranslation()

  // -- Vars --
  const available = group.filter(
    (charger) => charger.status === 'available'
  ).length

  const vatPercentage = group[0].connector.tariff.vat
  const startingPrice = formatDecimals(
    applyVat(group[0].connector.tariff.starting_price, vatPercentage)
  )
  const hasStartingPrice = startingPrice !== '0'

  const energyPrices = group[0].connector.tariff.energy_components
  const energyPriceText = (() => {
    const initialPrice = formatDecimals(
      applyVat(energyPrices[0].price, vatPercentage),
      3
    )

    const initialText = t('map.detail.group.tarrif-kwh', {
      currency: '€',
      price: initialPrice,
    })

    // If price is 0 return an empty string
    if (energyPrices[0].price === 0) {
      return (
        <StPriceWrapper>
          <BodySmallRegular>
            <StIcon icon={['fass', 'bolt']} />
            {t('map.detail.group.charging-price')}
          </BodySmallRegular>
          <BodySmallRegular>{initialText}</BodySmallRegular>
        </StPriceWrapper>
      )
    }

    // If no limit return initial price
    if (!energyPrices[0].limit) {
      return (
        <StPriceWrapper>
          <BodySmallRegular>
            <StIcon icon={['fass', 'bolt']} />
            {t('map.detail.group.charging-price')}
          </BodySmallRegular>
          <BodySmallRegular>{initialText}</BodySmallRegular>
        </StPriceWrapper>
      )
    }

    // Otherwise calculate additional prices
    return energyPrices.map((price, index) => {
      const start = index === 0 ? 0 : energyPrices[index - 1].limit
      const text = t('map.detail.group.tarrif-kwh', {
        currency: '€',
        price: formatDecimals(applyVat(price.price, vatPercentage), 3),
      })

      return (
        <StPriceWrapper key={index}>
          <BodySmallRegular>
            <StIcon icon={['fass', 'bolt']} />
            {price.limit
              ? `${t('map.detail.group.charging-price')} (${start}-${
                  price.limit
                } kWh)`
              : `${t('map.detail.group.charging-price')} (${start}+ kWh)`}
          </BodySmallRegular>
          <BodySmallRegular>{text}</BodySmallRegular>
        </StPriceWrapper>
      )
    })
  })()

  const hourPrices = group[0].connector.tariff.time_components
  const hourPriceText = (() => {
    // If price is 0 return an empty string
    if (hourPrices[0].price === 0) return ''

    const initialPrice = formatDecimals(
      applyVat(hourPrices[0].price, vatPercentage),
      3
    )
    const initialText = t('map.detail.group.tarrif-hour', {
      currency: '€',
      price: initialPrice,
    })

    // If no limit return initial price
    if (!hourPrices[0].limit) {
      return (
        <StPriceWrapper>
          <BodySmallRegular>
            <StIcon icon={['fasr', 'clock']} />
            {t('map.detail.group.hourly-price')}
          </BodySmallRegular>
          <BodySmallRegular>{initialText}</BodySmallRegular>
        </StPriceWrapper>
      )
    }

    return hourPrices.map((price, index) => {
      const start = index === 0 ? 0 : hourPrices[index - 1].limit
      const pricePerHour = formatDecimals(
        applyVat(price.price, vatPercentage),
        3
      )
      const text = t('map.detail.group.tarrif-hour', {
        currency: '€',
        price: pricePerHour,
      })

      return (
        <StPriceWrapper key={index}>
          <BodySmallRegular>
            <StIcon icon={['fasr', 'clock']} />
            {price.limit
              ? `${t('map.detail.group.hourly-price')} (${start / 60}-${
                  price.limit / 60
                } min)`
              : `${t('map.detail.group.hourly-price')} (${start / 60}+ min)`}
          </BodySmallRegular>
          <BodySmallRegular>{text}</BodySmallRegular>
        </StPriceWrapper>
      )
    })
  })()

  const isFree = !hasStartingPrice && !energyPriceText && !hourPriceText

  // -- Render --
  return (
    <StCard>
      <StTopText>
        <Trans
          t={t}
          i18nKey={'map.detail.availability'}
          values={{
            available: available,
            total: group.length,
            kw: Math.round(group[0].connector.power || 0),
          }}
          components={{
            b: available > 0 ? <Green /> : <Red />,
          }}
        />
      </StTopText>
      {isFree ? (
        <BodySmallRegular>{t('map.detail.group.free')}</BodySmallRegular>
      ) : (
        <StPricesWrapper>
          {hasStartingPrice && (
            <StPriceWrapper>
              <BodySmallRegular>
                {t('map.detail.group.start-price')}
              </BodySmallRegular>
              <BodySmallRegular>€{startingPrice}</BodySmallRegular>
            </StPriceWrapper>
          )}
          {energyPriceText}
          {hourPriceText}
        </StPricesWrapper>
      )}
    </StCard>
  )
}

const StCard = styled.div`
  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const Red = styled.strong`
  color: ${({ theme }) => theme.theme.colors.error};
`

const Green = styled.strong`
  color: ${({ theme }) => theme.theme.colors['quaternary-1']};
`

const StTopText = styled(BodySmallRegular)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space3};
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StPricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`
