export const isOutdated = (current: string, latest: string) => {
  const [cMajor, cMinor, cPatch] = current
    .split('.')
    .map((n) => Number.parseInt(n))
  const [lMajor, lMinor, lPatch] = latest
    .split('.')
    .map((n) => Number.parseInt(n))
  if (cMajor < lMajor) return true
  if (cMajor > lMajor) return false
  if (cMinor < lMinor) return true
  if (cMinor > lMinor) return false
  return cPatch < lPatch
}
