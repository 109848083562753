import { DotLottiePlayer } from '@dotlottie/react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Sheet from 'react-modal-sheet'
import { styled } from 'styled-components'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { H5 } from '../typography'

import type { SheetProps } from 'react-modal-sheet/dist/types'

type BottomSheetProps = {
  title?: string
  hasBackdrop?: boolean
  loading?: boolean
  hideHeader?: boolean
  backgroundColor?: string
} & SheetProps

export const BottomSheet = ({
  children,
  title,
  hasBackdrop = false,
  onClose,
  loading,
  hideHeader,
  backgroundColor,
  ...sheetProps
}: BottomSheetProps) => {
  // -- State --
  const [event, setEvent] = useState<string>('')
  const [showLoader, setShowLoader] = useState(false)

  // -- Effects --
  useEffect(() => {
    if (loading === true) {
      setShowLoader(true)
    } else if (event === '') {
      setShowLoader(false)
    }
  }, [loading])

  useEffect(() => {
    if (event === 'loopComplete' && !loading) {
      setShowLoader(false)
      setEvent('')
    }
  }, [event])

  // -- Handlers --
  const handleEvent = (event: string) => {
    setEvent(event)
  }

  // -- Render --
  return (
    <StSheet onClose={onClose} {...sheetProps} $hasBackdrop={hasBackdrop}>
      {hasBackdrop ? <Sheet.Backdrop onTap={onClose} /> : <></>}

      <Sheet.Container style={{ backgroundColor: backgroundColor || 'white' }}>
        {showLoader ? (
          <StLoadingContainer>
            <StLoader
              src="/assets/map-loader-mobile.lottie"
              autoplay
              loop
              onEvent={handleEvent}
            />
          </StLoadingContainer>
        ) : (
          <Sheet.Header />
        )}
        {!hideHeader && (
          <Sheet.Header>
            <StTitleRow>
              <StTitle>{title && <H5>{title}</H5>}</StTitle>

              <StClose onClick={onClose}>
                <FontAwesomeIcon icon={['fasr', 'xmark-large']} />
              </StClose>
            </StTitleRow>
          </Sheet.Header>
        )}

        <Sheet.Content>
          <Sheet.Scroller>{children}</Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </StSheet>
  )
}

const StSheet = styled(Sheet)<{ $hasBackdrop: boolean }>`
  /* Overwrite package styles */
  .react-modal-sheet-backdrop {
    /* Add 99 to the hex code for 60% opacity */
    /* If we set the opacity directly the animation of the package breaks */
    background-color: ${({ theme, $hasBackdrop }) =>
      $hasBackdrop
        ? theme.theme.colors['secondary-1'] + '99'
        : 'transparent'} !important;

    position: fixed;
    inset: 0;
  }

  .react-modal-sheet-container {
    box-shadow: none !important;
    border-top-right-radius: 14px !important;
    border-top-left-radius: 14px !important;
  }
`

const StTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};

  margin-top: -8px;
`

const StTitle = styled.div`
  flex-grow: 1;

  h5 {
    margin: 0;
  }
`

const StClose = styled.button`
  cursor: pointer;

  color: ${({ theme }) => theme.theme.colors.black};
  margin-right: -${({ theme }) => theme.UI.SpacingPx.Space3};

  border-radius: 100px;
  width: ${({ theme }) => theme.UI.SpacingPx.Space8};
  height: ${({ theme }) => theme.UI.SpacingPx.Space8};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;
  z-index: 100;

  &:hover {
    background-color: #0000001c;
  }

  @media ${breakpoints.mobile} {
    margin-right: -${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StLoadingContainer = styled(Sheet.Header)`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StLoader = styled(DotLottiePlayer)`
  padding-bottom: 4px;
  width: ${({ theme }) => theme.UI.SpacingPx.Space10};
`
