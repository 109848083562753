import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import { styled } from 'styled-components'

import {
  BodyLargeSemiBold,
  BodyMediumRegular,
} from '../../../components/typography'
import { formatDecimals } from '../../../core/utils/number'
import { CarBrightnessIcon } from '../assets/CarBrightnessIcon'
import { useHems } from '../hooks/useHems'

import { EnergyConsumptionCard } from './EnergyConsumptionStatisticCard'

export function EnergyConsumptionCarCard({
  className,
}: EnergyConsumptionCarCardProps) {
  const { installation } = useHems()

  if (!installation) {
    return null
  }

  const hasSolarPanels = installation.pv_installed === true

  if (!hasSolarPanels) {
    return <EnergyConsumptionFromCarCard className={className} />
  }

  return <EnergyConsumptionForCarFromSolarCard className={className} />
}

type EnergyConsumptionCarCardProps = {
  className?: string
}

function EnergyConsumptionFromCarCard({
  className,
}: EnergyConsumptionCarCardProps) {
  const { energyConsumption, energyConsumptionLoading } = useHems()

  if (!energyConsumption) {
    return null
  }

  const { importedEnergyWh, carConsumptionWh } = energyConsumption
  const percentage = carConsumptionWh / importedEnergyWh

  return (
    <EnergyConsumptionCard
      icon={<FontAwesomeIcon icon={['fass', 'car']} fontSize={16} />}
      description={t('employee.hems.usage.electricity.car-consumption', {
        percentage: formatDecimals(percentage * 100, 0),
      })}
      color="nonary"
      className={className}
      isLoading={energyConsumptionLoading}
    >
      <BodyMediumRegular>
        <BodyLargeSemiBold as="strong">
          {formatDecimals(carConsumptionWh / 1000)}
        </BodyLargeSemiBold>{' '}
        {t('employee.hems.usage.electricity.kWh')}
      </BodyMediumRegular>
    </EnergyConsumptionCard>
  )
}

function EnergyConsumptionForCarFromSolarCard({
  className,
}: EnergyConsumptionCarCardProps) {
  const { energyConsumption, energyConsumptionLoading } = useHems()

  if (!energyConsumption) {
    return null
  }

  const { solarChargePowerkWh, carConsumptionWh } = energyConsumption

  function calculatePercentageOfCarConsumptionFromSolar() {
    // Avoid dividing by zero
    if (carConsumptionWh > 0) {
      const carConsumptionKWh = carConsumptionWh / 1000

      return solarChargePowerkWh / carConsumptionKWh
    }

    return 0
  }

  const percentageOfCarConsumptionFromSolar =
    calculatePercentageOfCarConsumptionFromSolar()

  return (
    <EnergyConsumptionCard
      icon={<StCarBrightnessIcon />}
      description={t('employee.hems.usage.electricity.solar-car-consumption', {
        percentage: formatDecimals(
          percentageOfCarConsumptionFromSolar * 100,
          0
        ),
      })}
      color="nonary"
      className={className}
      isLoading={energyConsumptionLoading}
    >
      <BodyMediumRegular>
        <BodyLargeSemiBold as="strong">
          {formatDecimals(carConsumptionWh / 1000)}
        </BodyLargeSemiBold>{' '}
        {t('employee.hems.usage.electricity.kWh')}
      </BodyMediumRegular>
    </EnergyConsumptionCard>
  )
}

const StCarBrightnessIcon = styled(CarBrightnessIcon)`
  width: 22px;
`
