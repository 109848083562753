import { Capacitor } from '@capacitor/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { QRCodeSVG } from 'qrcode.react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { useAuthAxios } from '../../../../api/hooks/useAuthAxios'
import { Urls } from '../../../../api/urls'
import { BottomSheet } from '../../../../components/bottom-sheet/BottomSheet'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { Dialog } from '../../../../components/general/Dialog'
import {
  BodyMediumRegular,
  BodyMediumRegularCss,
  BodySmallRegular,
  BodySmallSemiBold,
  BodySmallSemiBoldCss,
  H4,
  H6Css,
} from '../../../../components/typography'
import { useMember } from '../../../../member/hooks/useMember'
import dongleImage from '../assets/dongle-flow.jpeg'
import { HemsBackgroundPattern } from '../assets/HemsBackgroundPattern'
import { OnboardingCardSkeleton } from '../assets/OnboardingCard.skeleton'

import { HemsOnboarding } from './HemsOnboarding'

import type { OnboardingValues } from '../../../onboarding/hooks/useOnboardingState'

export const HemsOnboardingCard = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { currentMember } = useMember()
  const isNative = Capacitor.isNativePlatform()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- State --
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  // -- Data --
  // Get onboardingData for digitalMeter step
  const [{ data, loading }] = useAuthAxios<Partial<OnboardingValues>>(
    Urls.Onboarding
  )

  // -- Vars --
  const progressIndex =
    currentMember.hemsOnboardingStatus === 'Preparation'
      ? 1
      : currentMember.hemsOnboardingStatus === 'Connection'
      ? 2
      : 3

  const inConfigurationStep =
    currentMember.hemsOnboardingStatus === 'Configuration'

  // -- Handlers --
  const handleClose = () => setIsSheetOpen(false)
  const handleOpen = () => setIsSheetOpen(true)

  // -- Render--
  if (!data || loading) {
    return <OnboardingCardSkeleton />
  }

  // Don't show card is user has no digital meter
  if (data.digitalMeterKey === 'No') {
    return null
  }

  return (
    <>
      <StCard>
        <StSvg />
        <StHeader>
          <StIconContainer>
            <FontAwesomeIcon icon={['fass', 'plug']} fontSize={32} />
          </StIconContainer>
          <StTitle>{t('employee.hems.intro-card.title')}</StTitle>
          <StSubtitle>{t('employee.hems.intro-card.subtitle')}</StSubtitle>
        </StHeader>
        <StContent>
          <StProgressContainer>
            <StProgressCircle $active $done={progressIndex > 1}>
              {progressIndex === 1 ? (
                1
              ) : (
                <FontAwesomeIcon icon={['fass', 'check']} />
              )}
            </StProgressCircle>
            <StDivider />
            <StProgressCircle
              $active={progressIndex === 2}
              $done={progressIndex > 2}
            >
              {progressIndex <= 2 ? (
                2
              ) : (
                <FontAwesomeIcon icon={['fass', 'check']} />
              )}
            </StProgressCircle>
            <StDivider />
            <StProgressCircle $active={progressIndex === 3} $done={false}>
              {progressIndex <= 3 ? (
                3
              ) : (
                <FontAwesomeIcon icon={['fass', 'check']} />
              )}
            </StProgressCircle>
          </StProgressContainer>
          <StTitleSection>
            <BodySmallSemiBold>
              {t(
                `employee.hems.intro-card.category-title.${currentMember.hemsOnboardingStatus}`
              )}
            </BodySmallSemiBold>
            <BodySmallRegular>
              {t(
                `employee.hems.intro-card.category-subtitle.${currentMember.hemsOnboardingStatus}`
              )}
            </BodySmallRegular>
          </StTitleSection>
          <ButtonPrimary onClick={handleOpen}>
            {t(
              `employee.hems.intro-card.button.${currentMember.hemsOnboardingStatus}`
            )}
          </ButtonPrimary>
        </StContent>
      </StCard>
      <>
        {isNative || inConfigurationStep ? (
          <BottomSheet
            isOpen={isSheetOpen}
            onClose={handleClose}
            hasBackdrop
            hideHeader
          >
            <HemsOnboarding onClose={handleClose} />
          </BottomSheet>
        ) : (
          <Dialog
            open={isSheetOpen}
            onOpenChange={setIsSheetOpen}
            title={t('employee.hems.intro-card.web-dialog.title')}
            onClickPrimaryButton={handleClose}
            onClickCloseButton={handleClose}
            primaryButtonText={t('employee.hems.intro-card.web-dialog.button')}
          >
            <StWebDialogContainer>
              <img src={dongleImage} alt="" />
              <StWebDialogTextContainer>
                <StWebDialogSubtitle>
                  {t('employee.hems.intro-card.web-dialog.subtitle')}
                </StWebDialogSubtitle>
                {isDesktop && (
                  <>
                    <BodyMediumRegular>
                      {t('employee.hems.intro-card.web-dialog.description')}
                    </BodyMediumRegular>
                    <QRCodeSVG value={t('qr.download-app')} size={110} />
                  </>
                )}
              </StWebDialogTextContainer>
            </StWebDialogContainer>
          </Dialog>
        )}
      </>
    </>
  )
}

const StCard = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.theme.colors.white};

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  overflow: hidden;
`

const StSvg = styled(HemsBackgroundPattern)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`

const StTitle = styled(H4)`
  text-align: center;
  z-index: 2;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StHeader = styled.div`
  z-index: 2;
  position: relative;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StSubtitle = styled(BodySmallRegular)`
  text-align: center;
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 60px;
  width: 60px;

  background: linear-gradient(180deg, #fff 0%, #d0d5dd 100%), #fff;

  border-radius: 15px;

  margin: auto;

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StContent = styled.div`
  position: relative;
  z-index: 2;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: 0;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StProgressCircle = styled.div<{ $active: boolean; $done: boolean }>`
  ${BodySmallSemiBoldCss}
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme, $active, $done }) =>
    $done
      ? theme.components.navcircle['completed-bg']
      : $active
      ? theme.theme.colors['primary-1']
      : 'transparent'};
  height: 40px;
  width: 40px;

  border-radius: 100%;

  border: ${({ $done, $active, theme }) =>
    $active || $done
      ? 'none'
      : `2px solid ${theme.theme.colors['secondary-5']}`};
  color: ${({ theme, $done, $active }) =>
    $done
      ? theme.theme.colors.white
      : $active
      ? theme.theme.colors.black
      : theme.theme.colors['secondary-3']};
`

const StDivider = styled.div`
  height: 2px;
  width: 24px;
  background-color: ${({ theme }) => theme.theme.colors['secondary-5']};
  border-radius: 100px;
`

const StTitleSection = styled.div`
  text-align: center;

  p:first-child {
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }
`

const StWebDialogSubtitle = styled.p`
  margin: 0;
  ${BodyMediumRegularCss}

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5} 0;

  @media ${breakpoints.desktop} {
    padding: 0;
    ${H6Css}
  }
`

const StWebDialogContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  img {
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }

  @media ${breakpoints.desktop} {
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
  }
`

const StWebDialogTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`
