import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../components/typography'

import type { OptimileEvsesChargingPoint } from 'types'

type ChargerAvailabilityProps = {
  groups: OptimileEvsesChargingPoint[][]
}

export const ChargerAvailability = ({ groups }: ChargerAvailabilityProps) => {
  //-- Hooks --
  const { t } = useTranslation()

  // -- Render --
  return (
    <StFlexContainer>
      {groups.map((group) => {
        const available = group.filter(
          (charger) => charger.status === 'available'
        ).length

        return (
          <BodySmallRegular key={group[0].id}>
            <Trans
              t={t}
              i18nKey={'map.detail.group-availability'}
              values={{
                available: available,
                total: group.length,
                kw: Math.round(group[0].connector.power || 0),
              }}
              components={{
                b: available > 0 ? <Green /> : <Red />,
              }}
            />
          </BodySmallRegular>
        )
      })}
    </StFlexContainer>
  )
}

const StFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const Red = styled.strong`
  color: ${({ theme }) => theme.theme.colors.error};
`

const Green = styled.strong`
  color: ${({ theme }) => theme.theme.colors['quaternary-1']};
`
