import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { styled } from 'styled-components'

type FilePreviewProps = {
  file: File
}

export const FilePreview = ({ file }: FilePreviewProps) => {
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null)

  useEffect(() => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (reader?.result) {
        setPreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }, [file])

  return (
    <StContainer>
      {preview ? (
        <StPreviewImage src={preview as string} alt="Preview" />
      ) : (
        <StLoader speed={2} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
          <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
        </StLoader>
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  height: 100%;
`

const StPreviewImage = styled.img`
  height: 100%;

  aspect-ratio: 1/1;

  object-fit: cover;
  border-radius: 2px;
`

const StLoader = styled(ContentLoader)`
  width: 100%;
  height: 100%;

  aspect-ratio: 1/1;
  object-fit: cover;
`
