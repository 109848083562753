import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { BodySmallRegular, BodySmallSemiBold } from '../typography'

type StatusChipProps = {
  urgent: boolean
  bold?: boolean
  label: string
}

export const StatusChip = ({
  urgent,
  bold = false,
  label,
}: StatusChipProps) => {
  // -- Render --
  if (urgent) {
    return (
      <StChip>
        <FontAwesomeIcon icon={['fasr', 'circle-exclamation']} />
        <BodySmallRegular>{label}</BodySmallRegular>
      </StChip>
    )
  }

  return bold ? (
    <BodySmallSemiBold>{label}</BodySmallSemiBold>
  ) : (
    <BodySmallRegular>{label}</BodySmallRegular>
  )
}

const StChip = styled.div`
  background-color: ${({ theme }) => theme.components.status.red};
  display: flex;
  align-items: center;
  gap: 6px;

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space1} ${theme.UI.SpacingPx.Space2}`};

  width: fit-content;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
`
