import type { BaseRow } from '../components/DataTable'

export const applyDotNotation = <T extends BaseRow>(
  dataRow: T,
  key: string
): string => {
  const keys = key.split('.')
  const currentKey = keys.shift()

  if (currentKey) {
    let value = dataRow[currentKey]

    // We don't support array data in the data table but we do want to take the first value for now
    // Another way we can handle array data is to apply a transformData callback on the column
    if (Array.isArray(value)) {
      value = value[0]
    }

    if (keys.length > 0 && typeof value === 'object' && value !== null) {
      return applyDotNotation(value as T, keys.join('.'))
    }

    return typeof value === 'object' ? '' : String(value)
  }

  return ''
}
