import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { ButtonTertiary } from '../button/ButtonTertiary'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

type ToggleProps = {
  onToggle: (active: boolean) => void
  active: boolean
  icon: IconProp
}

export const Toggle = ({ onToggle, active, icon }: ToggleProps) => {
  // -- Handlers --
  const handleClick = () => {
    onToggle(!active)
  }
  // -- Render --
  return (
    <StButton onClick={handleClick} $active={active}>
      <FontAwesomeIcon icon={icon} />
    </StButton>
  )
}

const StButton = styled(ButtonTertiary)<{ $active: boolean }>`
  width: auto;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  border-color: ${({ theme, $active }) =>
    $active ? theme.theme.colors.black : theme.theme.colors['nonary-7']};

  &:hover {
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }
`
