import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../../routing/routes'
import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { BodySmallRegular, H4 } from '../../../../components/typography'
import { useUser } from '../../../../user/hooks/useUser'
import { ConfirmationDutchSvg } from '../assets/confirmation/ConfirmationDutchSvg'
import { ConfirmationDutchSvgDesktop } from '../assets/confirmation/ConfirmationDutchSvgDesktop'
import { ConfirmationEnglishSvg } from '../assets/confirmation/ConfirmationEnglishSvg'
import { ConfirmationEnglishSvgDesktop } from '../assets/confirmation/ConfirmationEnglishSvgDesktop'
import { ConfirmationFrenchSvg } from '../assets/confirmation/ConfirmationFrenchSvg'
import { ConfirmationFrenchSvgDesktop } from '../assets/confirmation/ConfirmationFrenchSvgDesktop'
import { useHemsFlow } from '../hooks/useHemsFlow'

const getConfirmationSvg = (isDesktop: boolean, language: string) => {
  if (isDesktop) {
    return language === 'nl' ? (
      <ConfirmationDutchSvgDesktop />
    ) : language === 'fr' ? (
      <ConfirmationFrenchSvgDesktop />
    ) : (
      <ConfirmationEnglishSvgDesktop />
    )
  } else {
    return language === 'nl' ? (
      <ConfirmationDutchSvg />
    ) : language === 'fr' ? (
      <ConfirmationFrenchSvg />
    ) : (
      <ConfirmationEnglishSvg />
    )
  }
}

export const HemsConfirmationStep = () => {
  // -- Hooks --
  const { t, i18n } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { flowNavigation } = useHemsFlow()
  const navigate = useNavigate()
  const { refetch: refetchUser } = useUser()

  // -- Handlers --
  const handleSubmit = async () => {
    await refetchUser()
    navigate(EmployeeRoutes.HEMS)
  }

  // -- Render --
  return (
    <StContainer>
      <StVisual>{getConfirmationSvg(isDesktop, i18n.language)}</StVisual>

      <StTextContainer>
        {isDesktop && (
          <StTitle>
            {t('employee.hems.onboarding.confirmation-step.title')}
          </StTitle>
        )}
        {isDesktop && (
          <BodySmallRegular>
            {t('employee.hems.onboarding.confirmation-step.description')}
          </BodySmallRegular>
        )}
      </StTextContainer>

      {isDesktop ? (
        <StFlexContainer>
          <ButtonTertiary onClick={() => flowNavigation.close()} compact>
            {t('employee.hems.onboarding.close')}
          </ButtonTertiary>
          <ButtonPrimary onClick={handleSubmit} compact>
            {t('employee.hems.onboarding.submit')}
          </ButtonPrimary>
        </StFlexContainer>
      ) : (
        <FloatingButtonContainer>
          <ButtonPrimary onClick={handleSubmit}>
            {t('employee.hems.onboarding.submit')}
          </ButtonPrimary>
        </FloatingButtonContainer>
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  position: relative;
  height: ${({ theme }) => `calc(100% - ${theme.UI.SpacingPx.Space12})`};
  overflow: hidden;

  @media ${breakpoints.desktop} {
    height: 100%;
  }
`

const StVisual = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 100dvw;
    height: auto;
  }

  @media ${breakpoints.desktop} {
    position: relative;
    transform: none;
    top: 0;
    left: 0;
    width: 100%;

    svg {
      width: 100%;
    }
  }
`

const StTextContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space7} 0;
  }
`

const StTitle = styled(H4)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  position: absolute;
  bottom: 0;
  right: 0;
`
