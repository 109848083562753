export const isValidMacAddress = (value: string) => {
  const MAC_TEST_REGEX = new RegExp(/([\dA-Fa-f]{2}:){5}[\dA-Fa-f]{2}/)

  return MAC_TEST_REGEX.test(value)
}

export const uuidToMacAddress = (uuid: string) => {
  // Check if the first 13 characters of the UUID are zeros
  // if they aren't zeros it means this is an old dongle and the user should enter the MAc address manually
  if (uuid.slice(0, 13) !== '00000000-0000') {
    return
  }

  // Extract the last 12 characters from the UUID
  const macAddressPart = uuid.slice(-12)

  // Format the MAC address part with colons every two characters
  return macAddressPart
    .match(/.{1,2}/g)
    ?.join(':')
    .toUpperCase()
}
