import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { BodyMediumRegularCss } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { PropsWithChildren } from 'react'

export enum BannerType {
  INFORMATION = 'information',
  ERROR = 'error',
}

type BannerProps = PropsWithChildren & {
  icon?: IconProp
  type: BannerType
}

export const Banner = ({ icon, type, children }: BannerProps) => {
  return (
    <StBanner $type={type}>
      {icon ? <StFontAwesomeIcon icon={icon} /> : null}
      <span>{children}</span>
    </StBanner>
  )
}

const StBanner = styled.div<{ $type: BannerType }>`
  ${BodyMediumRegularCss}

  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  background-color: ${({ theme, $type }) =>
    $type === BannerType.ERROR
      ? theme.theme.colors['senary-4']
      : theme.theme.colors['nonary-9']};

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space5} ${theme.UI.SpacingPx.Space6}`};
  border-radius: 6px;

  color: ${({ theme, $type }) =>
    $type === BannerType.ERROR
      ? theme.components.input.error
      : theme.theme.colors.black};
`

const StFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: ${({ theme }) => theme.UI.SpacingPx.Space6};
  height: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
