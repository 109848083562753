import { Capacitor } from '@capacitor/core'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { css, styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../analytics/hooks/useTracking'
import { useDownloadZohoFile } from '../../core/hooks/useDownloadZohoFile'
import { useGetDateFnsLocale } from '../../core/hooks/useGetDateFnsLocale'
import { ButtonTertiary } from '../button/ButtonTertiary'
import { PdfPreview } from '../general/PdfPreview'
import { BodyLargeSemiBold, BodySmallRegular } from '../typography'

import type { ZohoHcpQuote } from 'types'

type QuoteCardProps = {
  fullName: string
  date: Date
  quote: ZohoHcpQuote
  withBorder?: boolean
  forUserId?: string
}

export const QuoteCard = ({
  fullName,
  date,
  withBorder = true,
  quote,
  forUserId,
}: QuoteCardProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { trackEvent } = useTracking()
  const dateFnsLocale = useGetDateFnsLocale()

  // -- Data --
  const { download, loading, file } = useDownloadZohoFile(
    quote.Quote[0].File_Id__s,
    forUserId
  )

  // -- Vars --
  const formattedDate = format(date, 'dd MMMM yyyy', dateFnsLocale)

  // -- Handlers --
  const handleDownload = (openInWindow: boolean) => {
    const fileName = t('admin.employee-detail.quote.title', {
      fullName,
    }).replaceAll(/\s/g, '_')

    trackEvent(EventType.Click, 'download_quote')
    download(fileName, undefined, !openInWindow)
  }

  // -- Render --
  return (
    <StCard $withBorder={withBorder}>
      <PdfPreview file={file} small={!withBorder} />

      <StDownloadContentContainer>
        <div>
          <BodyLargeSemiBold>
            {t('admin.employee-detail.quote.title', {
              fullName,
            })}
          </BodyLargeSemiBold>
          <BodySmallRegular>{formattedDate}</BodySmallRegular>
        </div>

        <StActions>
          <ButtonTertiary
            icon={['fasr', 'eye']}
            iconAlignment="left"
            compact={isDesktop}
            onClick={() => handleDownload(true)}
            loading={loading}
            size="md"
          >
            {t('admin.employee-detail.quote.view')}
          </ButtonTertiary>

          {!Capacitor.isNativePlatform() && (
            <ButtonTertiary
              icon={['fasr', 'arrow-down-to-line']}
              iconAlignment="left"
              compact={isDesktop}
              onClick={() => handleDownload(false)}
              loading={loading}
              size="md"
            >
              {t('admin.employee-detail.quote.download')}
            </ButtonTertiary>
          )}
        </StActions>
      </StDownloadContentContainer>
    </StCard>
  )
}

const StCard = styled.div<{ $withBorder: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  flex-direction: column;

  ${({ $withBorder }) =>
    $withBorder
      ? css`
          border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
          border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
          padding: ${({ theme }) => theme.UI.SpacingPx.Space7};
        `
      : undefined}

  @media ${breakpoints.desktop} {
    flex-direction: row;
    padding: ${({ theme, $withBorder }) =>
      $withBorder ? theme.UI.SpacingPx.Space7 : 0};
    gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
  }
`

const StDownloadContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StActions = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  @media ${breakpoints.desktop} {
    flex-direction: row;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }
`
