import { createContext } from 'react'

import { FullPageLoader } from '../../../../components/general/FullPageLoader'
import { Wizard } from '../../../../components/navigation/Wizard'
import { hcpOnboardingWizardConfig } from '../../config/wizardConfig'
import { useTokenFlowState } from '../../hooks/useTokenFlowState'
import { StOnboardingContentContainer } from '../../steps/RenderOnboardingStep'
import { RenderTokenOnboardingStep } from '../../steps/RenderTokenOnboardingStep'

import type { TokenFlowValues } from '../../hooks/useTokenFlowState'
import type { Dispatch, SetStateAction } from 'react'

type TokenFlowContext = {
  handleNext: (data: TokenFlowValues) => void
  setLoading: (loading: boolean) => void
  values: TokenFlowValues
  setValues: Dispatch<SetStateAction<TokenFlowValues>>
  token: string
}

export const tokenflowContext = createContext<TokenFlowContext | null>(null)

export const TokenFlow = () => {
  // -- Hooks --
  const {
    loading,
    showSaved,
    headerState,
    handlePrevious,
    currentStep,
    setValues,
    handleNext,
    setLoading,
    values,
    token,
    onboardingIsLoading,
    error,
  } = useTokenFlowState()

  // -- Render --
  if (error) {
    throw new Error('Failed to load onboarding data')
  }

  if (onboardingIsLoading) {
    return <FullPageLoader />
  }

  return (
    <tokenflowContext.Provider
      value={{
        setValues,
        handleNext,
        setLoading,
        values,
        token,
      }}
    >
      <Wizard
        steps={hcpOnboardingWizardConfig}
        currentStep={headerState.state}
        onGoBack={headerState.cantGoBack ? undefined : handlePrevious}
        loading={loading}
        showSaved={showSaved}
        canCancel={headerState.canCancel}
      />
      <StOnboardingContentContainer>
        <RenderTokenOnboardingStep currentStep={currentStep} />
      </StOnboardingContentContainer>
    </tokenflowContext.Provider>
  )
}
