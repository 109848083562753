import { SafeArea } from 'capacitor-plugin-safe-area'
import { useEffect, useState } from 'react'

export const useSafeAreaInsets = () => {
  const [insets, setInsets] = useState({ top: 0, bottom: 0, left: 0, right: 0 })
  useEffect(() => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      setInsets(insets)
    })

    SafeArea.addListener('safeAreaChanged', ({ insets }) => {
      setInsets(insets)
    })

    return () => {
      SafeArea.removeAllListeners()
    }
  }, [])

  return insets
}
