import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { BodyMediumRegular } from '../../../components/typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

type ProfileMenuItemProps = {
  title: string
  icon: IconProp
  onClick: () => void
}

export const ProfileMenuItem = ({
  icon,
  title,
  onClick,
}: ProfileMenuItemProps) => {
  // -- Hooks --
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Render --
  return (
    <StButton onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
      <BodyMediumRegular>{title}</BodyMediumRegular>
      {isDesktop && <FontAwesomeIcon icon={['fasr', 'chevron-right']} />}
    </StButton>
  )
}

const StButton = styled.button`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
  justify-content: left;
  align-items: center;

  background-color: transparent;

  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space6} 0;

  cursor: pointer;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  svg {
    width: 16px;
  }

  p {
    width: 100%;
    text-align: left;
  }
`
