export const applyVat = (price: number, vat?: number): number => {
  return price + (price * (vat ?? 21)) / 100
}

export function subtractVat(price: number, vat: number): number {
  return price / (1 + vat / 100)
}

// Format a number to a string with a maximum number of decimals
// Removing trailing zeros BUT Showing at least 2 decimals except if the number is an integer
export const formatDecimals = (value: number, maxDecimals = 2): string => {
  const endDecimalsMax = maxDecimals - 2
  const maxZeroDecimalsCheck = '0'.repeat(maxDecimals)

  const regex =
    endDecimalsMax > 0
      ? new RegExp(`0{1,${maxDecimals - 2}}$|.${maxZeroDecimalsCheck}`)
      : new RegExp(`\\.${maxZeroDecimalsCheck}`)

  return (value ?? 0).toFixed(maxDecimals).replace(regex, '')
}

export const calculatePeakUsagePrice = (
  peakKw: number,
  period: 'monthly' | 'yearly' = 'monthly'
) => {
  const yearlyPrice = peakKw * 50

  return period === 'monthly' ? yearlyPrice / 12 : yearlyPrice
}
