import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { ToolTipWrapper } from '../general/ToolTipWrapper'

import type { ReactNode } from 'react'

type InfoPopupProps = {
  content: ReactNode
}

export const InfoPopup = ({ content }: InfoPopupProps) => {
  return (
    <ToolTipWrapper toolTip={content} placement="bottom-end" theme="dark">
      <StIcon>
        <FontAwesomeIcon icon={['fass', 'info-circle']} />
      </StIcon>
    </ToolTipWrapper>
  )
}

const StIcon = styled.div`
  color: ${({ theme }) => theme.theme.colors['nonary-4']};
  background-color: transparent;
  border: none;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space1};
  cursor: pointer;
`
