import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as RadixAccordion from '@radix-ui/react-accordion'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import {
  BodyExtraSmallRegular,
  BodyMediumRegular,
  BodyMediumSemiBold,
} from '../../../components/typography'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'

export const MemberSwitcher = () => {
  const { user } = useUser()
  const { currentMember, setCurrentMember } = useMember()
  const { t } = useTranslation()

  const loadMember = (memberId: string) => {
    setCurrentMember(memberId)
  }

  return (
    <StContainer>
      <BodyMediumSemiBold>{user?.fullName}</BodyMediumSemiBold>
      <RadixAccordion.Root type="single" disabled={user.members?.length === 1}>
        <RadixAccordion.Item value="members">
          <StCurrentMember>
            <BodyMediumRegular>{currentMember.company.name}</BodyMediumRegular>
            <StSwitcherIndication>
              <FontAwesomeIcon icon={['fass', 'arrows-repeat']} size="sm" />
              <BodyExtraSmallRegular>
                {t('employer.profile.switch_company')}
              </BodyExtraSmallRegular>
            </StSwitcherIndication>
          </StCurrentMember>
          <StAccordionContent className="AccordionContent">
            {user.members
              .filter((member) => member.id !== currentMember.id)
              .map((member) => (
                <StMember key={member.id} onClick={() => loadMember(member.id)}>
                  <BodyMediumRegular title={member.company.name}>
                    {member.company.name}
                  </BodyMediumRegular>
                  <StSwitcherIndication>
                    <BodyExtraSmallRegular>
                      {t('employer.profile.switch_to_company')}
                    </BodyExtraSmallRegular>
                  </StSwitcherIndication>
                </StMember>
              ))}
          </StAccordionContent>
        </RadixAccordion.Item>
      </RadixAccordion.Root>
    </StContainer>
  )
}

const StContainer = styled.div`
  width: 400px;

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space5} ${theme.UI.SpacingPx.Space7}`};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  border-bottom: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
`

const StSwitcherIndication = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;

  color: ${({ theme }) => theme.theme.colors['secondary-1']};

  height: ${({ theme }) => theme.UI.SpacingPx.Space7};
  border-radius: 9999px;

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space3};

  background-color: ${({ theme }) => theme.theme.colors['secondary-5']};
`

const StCurrentMember = styled(RadixAccordion.Trigger)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  width: 100%;
  padding: 0;
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space1};

  background-color: transparent;
  color: ${({ theme }) => theme.theme.text.body.black};
  border: none;

  cursor: pointer;

  > p {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }

  &[data-state='open'],
  &[data-disabled] {
    cursor: default;

    ${StSwitcherIndication} {
      display: none;
    }
  }

  &:hover ${StSwitcherIndication} {
    background-color: ${({ theme }) => theme.theme.colors['secondary-4']};
  }
`

const StAccordionContent = styled(RadixAccordion.Content)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

  &[data-state='open'] {
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
  }
`

const StMember = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  height: ${({ theme }) => theme.UI.SpacingPx.Space7};

  cursor: pointer;
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};

  > p {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${StSwitcherIndication} {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.theme.text.body.black};

    ${StSwitcherIndication} {
      display: flex;
    }
  }
`
