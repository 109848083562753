import { createContext } from 'react'

import type { ESPNetwork } from '@general-galactic/capacitor-esp-idf-provisioning'

export type HemsFlowContext = {
  flowNavigation: {
    next: (alternative?: boolean) => void
    previous: () => void
    close: () => void
    setStep: (step: string) => void
  }
  deviceName: string
  setDeviceName: (deviceName: string) => void
  macAddress: string | null
  setMacAddress: (macAddress: string) => void
  selectedNetwork: ESPNetwork | null
  setSelectedNetwork: (network: ESPNetwork) => void
  networkPassword: string | null
  setNetworkPassword: (networkPassword: string) => void
}

export const hemsFlowContext = createContext<HemsFlowContext | undefined>(
  undefined
)
