import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { ZohoImage, ZohoModule } from '../../image/ZohoImage'

import { FilePreview } from './FilePreview'

import type { MultiFileValues } from './MultipleFileInput'
import type { MouseEventHandler } from 'react'

type ImagePreviewProps = {
  value: MultiFileValues
  onClick: (id: string) => void
}

export const ImagePreview = ({ value, onClick }: ImagePreviewProps) => {
  // -- Vars --
  const file = value.file

  // -- Handlers --
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    onClick(value.zohoId)
  }

  // -- Render --
  if (file) {
    return (
      <StFilePreview onClick={handleClick}>
        <FilePreview file={file} />
        <FontAwesomeIcon icon={['fasr', 'xmark']} />
      </StFilePreview>
    )
  }

  return (
    <StFilePreview onClick={handleClick}>
      <ZohoImage id={value.zohoId} module={ZohoModule.File} />
      <FontAwesomeIcon icon={['fasr', 'xmark']} />
    </StFilePreview>
  )
}

const StFilePreview = styled.button`
  height: 100%;
  aspect-ratio: 1/1;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  padding: 0;

  svg {
    display: none;
  }

  &:hover {
    svg {
      display: block;
      z-index: 1;
      color: white;
      position: absolute;
      inset: 0;
      margin: auto;
    }

    &::before {
      content: '';
      background-color: ${({ theme }) => theme.theme.colors.error};
      position: absolute;
      inset: 0;
    }
  }
`
