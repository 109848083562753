import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { useAxios } from '../../../api/hooks/useAxios'
import { Urls } from '../../../api/urls'

import { useMultiUploadFunctions } from './useMultiUploadFunctions'
import { useTokenOnboarding } from './useTokenOnboarding'

import type { OnboardingValues } from './useOnboardingState'
import type { MultiFileValues } from '../../../components/form/file-input/MultipleFileInput'

export const useTokenMultiImageUpload = (
  key: string,
  valueKey: keyof Pick<
    OnboardingValues,
    | 'extraImageIds'
    | 'fuseBoxImageIds'
    | 'electricalBoxImageIds'
    | 'chargerLocationImageIds'
    | 'electricityMeterImageIds'
  >,
  token: string
) => {
  // -- Hooks --
  const { values, setValues } = useTokenOnboarding()
  const { addImage, deleteImage } = useMultiUploadFunctions()

  // -- Data --
  const [{ loading }, uploadExtraImage] = useAxios<
    {
      imageId: string
      name: string
    }[]
  >(
    {
      url: replaceParametersInPath(Urls.TokenOnboardingImages, { key }),
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        ['x-onboarding-token']: token,
      },
    },
    {
      manual: true,
    }
  )

  // eslint-disable-next-line no-unused-vars
  const [_, executeDelete] = useAxios(
    {
      method: 'DELETE',
      headers: {
        ['x-onboarding-token']: token,
      },
    },
    {
      manual: true,
    }
  )

  // -- Handlers --
  const handleAddImage = addImage({
    setValues,
    uploadExtraImage,
    valueKey,
  })

  const handleRemoveImage = deleteImage({
    executeDelete,
    key,
    setValues,
    valueKey,
    url: Urls.TokenDeleteOnboardingImage,
  })

  // -- Vars --
  const zohoImages: MultiFileValues[] =
    values[valueKey]?.map((image) => ({
      zohoId: image,
    })) || []

  return {
    images: zohoImages,
    handleAddImage,
    handleRemoveImage,
    loading,
  }
}
