import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import logo from '../../../components/assets/vectors/logo.svg'
import { ButtonQuaternary } from '../../../components/button/ButtonQuaternary'
import { IntercomLogo } from '../../../components/intercom/IntercomLogo'
import { useIntercom } from '../../../intercom/hooks/useIntercom'

export const EmployeeHomeHeader = () => {
  // -- Hooks --
  const { show } = useIntercom()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  if (isDesktop) {
    return
  }

  // -- Render component --
  return (
    <StContainer>
      <StMobileHeader>
        <StHomeLogo>
          <img src={logo} alt="Logo" />
        </StHomeLogo>
        <StIntercomButton border={false} onClick={show}>
          <IntercomLogo />
        </StIntercomButton>
      </StMobileHeader>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  background-color: ${({ theme }) => theme.theme.colors['primary-3']};
  padding-top: var(--inset-top, 0px);

  position: sticky;
  top: 0;
  z-index: 10;

  @media ${breakpoints.desktop} {
    height: 120px;
  }
`

const StMobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: calc(var(--mobile-page-header-height));
  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  margin: 0 auto;
`

const StHomeLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  height: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    display: none;
  }
`

// Make sure the button wraps nicely around the icon
const StIntercomButton = styled(ButtonQuaternary)`
  display: flex;
  padding: 0;

  span {
    line-height: 100%;
    padding: 0;
  }
`
