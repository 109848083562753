import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ContentContainer } from '../../../components/page-layout'
import { EmployeeGeneralSettings } from '../components/EmployeeGeneralSettings'

export const EmployeeGeneralSettingsScreen = () => {
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const navigate = useNavigate()

  if (isDesktop) {
    navigate(EmployeeRoutes.Settings, {
      state: { settingScreen: 'general' },
    })
  }

  return (
    <ContentContainer>
      <EmployeeGeneralSettings />
    </ContentContainer>
  )
}
