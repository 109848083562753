import { css } from 'styled-components'

import type { RuleSet } from 'styled-components'

/**
 * Helper function to apply hover styles to a component
 *
 * @param hoverCss - The styles to apply on hover
 * @param active - Whether the hover styles should be applied on active state
 *
 * @returns The hover styles
 *
 * @example
 * const Button = styled.button`
 *  ${onHover(css`
 *   background-color: red;
 * `)}
 * `
 *
 * @example
 * const Button = styled.button`
 * ${onHover(css`
 *  background-color: red;
 * `, false)}
 * `
 */
export const onHover = (hoverCss: RuleSet<object>, active: boolean = true) => {
  if (active) {
    return css`
      /* Checks if hover is supported on device */
      @media (hover: hover) {
        &:hover {
          ${hoverCss}
        }
      }

      @media not all and (hover: hover) {
        &:active {
          ${hoverCss}
        }
      }
    `
  }

  return css`
    @media (hover: hover) {
      &:hover {
        ${hoverCss}
      }
    }
  `
}
