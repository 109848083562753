import { useEffect } from 'react'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'

export const usePingDongleSatus = () => {
  // -- Data --
  const [{ data, loading }, refetch] = useAuthMemberAxios<{ online: boolean }>({
    url: Urls.getDongleStatus,
  })

  useEffect(() => {
    // retry untill we get online status
    if (!data?.online && !loading) {
      const timeout = setTimeout(() => {
        refetch()
      }, 10000)

      return () => clearTimeout(timeout)
    }
  }, [data])

  return {
    loading,
    online: data?.online,
  }
}
