import type { WizardStep } from '../../../components/navigation/Wizard'

export const hcpOnboardingWizardConfig: WizardStep[] = [
  {
    key: 'INTRODUCTION',
    order: 1,
  },
  {
    key: 'ACCOUNT',
    order: 2,
  },
  {
    key: 'PREFERENCES',
    order: 3,
  },
  {
    key: 'QUOTATION',
    order: 4,
  },
  {
    key: 'INSTALLATION',
    order: 5,
  },
]

export const simplifiedOnboardingWizardConfig: WizardStep[] = [
  {
    key: 'INTRODUCTION',
    order: 1,
  },
  {
    key: 'ACCOUNT',
    order: 2,
  },
  {
    key: 'PREFERENCES',
    order: 3,
  },
]
