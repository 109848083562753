import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { Chart } from '../../../components/chart/Chart'
import { getGradient, formatData, formatTooltip } from '../../../core/lib/chart'
import { formatDecimals } from '../../../core/utils/number'

import { StChartContainer, StChartInfoContainer } from './UsageDetails'
import { UsageDetailsInfoCard } from './UsageDetailsInfoCard'

import type { DateRangeMode } from './UsageDetails'
import type { ActiveElement, Chart as ChartType } from 'chart.js'
import type { UsageDetails as UsageDetailsResponse } from 'types'

type UsageDetailsHomeProps = {
  usageDetails?: UsageDetailsResponse
  dateRange: { start: Date; end: Date }
  dateRangeMode: DateRangeMode
}

export function UsageDetailsHome({
  usageDetails,
  dateRange,
  dateRangeMode,
}: UsageDetailsHomeProps) {
  // -- Hooks --
  const theme = useTheme()
  const { t } = useTranslation()

  // -- State --
  const [selectedNetInjection, setSelectedNetInjection] =
    useState<ActiveElement | null>(null)
  const [selectedNetConsumption, setSelectedNetConsumption] =
    useState<ActiveElement | null>(null)
  const [selectedActualConsumption, setSelectedActualConsumption] =
    useState<ActiveElement | null>(null)

  // -- Graph data --
  const actualConsumption = useMemo(
    () =>
      formatData(
        dateRange,
        dateRangeMode,
        (usageDetails?.actualConsumption ?? []).map((data) => ({
          label: data[0],
          value: data[1],
        })),
        t
      ),
    [dateRange, dateRangeMode, t, usageDetails?.actualConsumption]
  )

  const netConsumption = useMemo(
    () =>
      formatData(
        dateRange,
        dateRangeMode,
        (usageDetails?.netConsumption ?? []).map((data) => ({
          label: data[0],
          value: data[1] / 1000,
        })),
        t
      ),
    [dateRange, dateRangeMode, t, usageDetails?.netConsumption]
  )

  const netInjection = useMemo(
    () =>
      formatData(
        dateRange,
        dateRangeMode,
        (usageDetails?.netInjection ?? []).map((data) => ({
          label: data[0],
          value: (data[1] / 1000) * -1,
        })),
        t
      ),
    [dateRange, dateRangeMode, t, usageDetails?.netInjection]
  )

  // -- Line chart --
  const lineChart = useMemo(
    () => (
      <Chart<'line'>
        type="line"
        data={{
          labels: actualConsumption.map((d) => d.label),
          datasets: [
            {
              data: actualConsumption.map((d) => d.value),
              animation: {
                duration: 0,
              },
              tension: 0.4,
              fill: {
                target: 'origin',
                above: theme.theme.colors['secondary-5'] + '80',
                below: theme.theme.colors['quaternary-5'] + '80',
              },
              borderWidth: 1,
              segment: {
                borderColor: (context) => {
                  const chart = (context as unknown as { chart: ChartType })
                    .chart

                  const { ctx, chartArea } = chart

                  if (!chartArea) {
                    return 'black'
                  }

                  return getGradient(
                    context,
                    ctx,
                    chart,
                    theme.theme.colors['nonary-6'],
                    theme.theme.colors['quaternary-2']
                  )
                },
              },
            },
          ],
        }}
        onSelectedDataPointsChange={(elements) => {
          setSelectedActualConsumption(elements?.[0] ?? null)
        }}
        processTooltipData={(data: ActiveElement[]) => {
          return formatTooltip(
            dateRangeMode,
            t,
            actualConsumption[data[0].index]?.timestamp
          )
        }}
      />
    ),
    [actualConsumption, dateRangeMode, t, theme.theme.colors]
  )

  // -- Bar chart --
  const barChart = useMemo(
    () => (
      <Chart<'bar'>
        type="bar"
        stacked
        options={{
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 15,
              },
            },
          },
        }}
        data={(selectedDataPoints: ActiveElement[] | null) => ({
          labels: netConsumption.map((d) => d.label),
          datasets: [
            {
              data: netConsumption.map((d) => d.value),
              backgroundColor: netConsumption.map(
                (d, index) =>
                  `${theme.theme.colors['nonary-7']}${
                    selectedDataPoints === null ||
                    selectedDataPoints[0].index === index
                      ? 'FF'
                      : '40'
                  }`
              ),
              animation: {
                duration: 0,
              },
              hoverBackgroundColor: theme.theme.colors['nonary-7'],
              borderRadius: 2,
            },
            {
              data: netInjection.map((d) => d.value),
              backgroundColor: netInjection.map(
                (d, index) =>
                  `${theme.theme.colors['quaternary-3']}${
                    selectedDataPoints === null ||
                    selectedDataPoints[0].index === index
                      ? 'FF'
                      : '40'
                  }`
              ),
              animation: {
                duration: 0,
              },
              hoverBackgroundColor: theme.theme.colors['quaternary-3'],
              borderRadius: 2,
            },
          ],
        })}
        onSelectedDataPointsChange={(elements) => {
          setSelectedNetConsumption(elements?.[0] ?? null)
          setSelectedNetInjection(elements?.[1] ?? null)
        }}
        processTooltipData={(data: ActiveElement[]) => {
          return formatTooltip(
            dateRangeMode,
            t,
            netConsumption[data[0].index]?.timestamp
          )
        }}
      />
    ),
    [dateRangeMode, netConsumption, netInjection, t, theme.theme.colors]
  )

  return (
    <StChartContainer>
      <StChartInfoContainer>
        <UsageDetailsInfoCard
          selected={
            !!selectedNetConsumption ||
            (selectedActualConsumption &&
              (actualConsumption[selectedActualConsumption.index].value ?? 0) >=
                0)
          }
          value={
            selectedNetConsumption
              ? formatDecimals(
                  netConsumption[selectedNetConsumption.index].value ?? 0,
                  dateRangeMode === 'day' ? 1 : 0
                )
              : selectedActualConsumption
              ? (actualConsumption[selectedActualConsumption.index].value ??
                  0) >= 0
                ? actualConsumption[selectedActualConsumption.index].value ?? 0
                : '-'
              : formatDecimals(
                  (usageDetails?.totals.totalConsumption ?? 0) / 1000,
                  dateRangeMode === 'day' ? 1 : 0
                )
          }
          suffix={selectedActualConsumption ? 'W' : 'kWh'}
          color={theme.theme.colors['nonary-5']}
          label={t('employee.hems.usage-details.home-usage.net')}
        />
        <UsageDetailsInfoCard
          selected={
            !!selectedNetInjection ||
            (selectedActualConsumption &&
              (actualConsumption[selectedActualConsumption.index].value ?? 0) <=
                0)
          }
          value={
            selectedNetInjection
              ? formatDecimals(
                  netInjection[selectedNetInjection.index].value ?? 0,
                  dateRangeMode === 'day' ? 1 : 0
                )
              : selectedActualConsumption
              ? (actualConsumption[selectedActualConsumption.index].value ??
                  0) <= 0
                ? Math.abs(
                    actualConsumption[selectedActualConsumption.index].value ??
                      0
                  )
                : '-'
              : formatDecimals(
                  (usageDetails?.totals.totalProduction ?? 0) / 1000,
                  dateRangeMode === 'day' ? 1 : 0
                )
          }
          suffix={selectedActualConsumption ? 'W' : 'kWh'}
          color={theme.theme.colors['quaternary-2']}
          label={t('employee.hems.usage-details.home-usage.injection')}
        />
        {dateRangeMode === 'month' && (
          <UsageDetailsInfoCard
            value={
              formatDecimals((usageDetails?.totals.totalPeak ?? 0) / 1000, 0) ??
              0
            }
            suffix={'kW'}
            color={theme.theme.colors.black}
            label={t('employee.hems.usage-details.home-usage.peak')}
            icon={['fas', 'triangle']}
          />
        )}
      </StChartInfoContainer>
      {dateRangeMode === 'day' ? lineChart : barChart}
    </StChartContainer>
  )
}
