import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { styled, useTheme } from 'styled-components'

import {
  BodyExtraSmallRegular,
  BodySmallRegular,
  BodySmallSemiBold,
} from '../../../components/typography'
import { formatDecimals } from '../../../core/utils/number'

import {
  StCardRow,
  StChargModeContainer,
  StChargeModeSymbol,
  StChip,
  StDuration,
  StGradientCard,
  StTime,
  formatTime,
  useChargeModes,
  useDistanceToNowFormatter,
} from './ChargingSessionCard'

import type { UserSettingsMode } from '../../hems/types/enums'

export function PausedByCarChargingSessionCard({
  customerName,
  mode,
  kWh,
  startTime,
}: PausedByCarChargingSessionCardProps) {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const chargeModes = useChargeModes()
  const chargeMode = chargeModes.find(
    (chargeMode) => chargeMode.value === mode
  )!

  const formatDistanceToNow = useDistanceToNowFormatter()

  return (
    <StGradientCard>
      <StCardRow>
        <BodySmallSemiBold>{customerName}</BodySmallSemiBold>
      </StCardRow>
      <StCardRow>
        <StTime>
          <BodySmallRegular>
            {t('employee.home.charging-session.card.time', {
              time: formatTime(startTime),
            })}
          </BodySmallRegular>
          <StDuration>
            <FontAwesomeIcon icon={['fasr', 'clock']} fontSize={12} />
            <BodySmallRegular>
              {formatDistanceToNow(startTime)}
            </BodySmallRegular>
          </StDuration>
        </StTime>
        <BodySmallRegular>
          {t('employee.home.charging-session.card.kWh', {
            kWh: formatDecimals(kWh),
          })}
        </BodySmallRegular>
      </StCardRow>
      <StCardRow>
        <StChip>
          <FontAwesomeIcon
            color={theme.text.body['gray-mid']}
            icon={['fass', 'bolt-slash']}
            fontSize={12}
          />{' '}
          <StGreyBodySmallRegular>
            {t('employee.home.charging-session.card.paused.chip')}
          </StGreyBodySmallRegular>
        </StChip>
        <StChargModeContainer>
          <StChargeModeSymbol $color={chargeMode.color}>
            <FontAwesomeIcon fontSize={14} icon={chargeMode.icon} />
          </StChargeModeSymbol>
          <BodySmallRegular>{chargeMode.label}</BodySmallRegular>
        </StChargModeContainer>
      </StCardRow>
      <StCardRow>
        <StBodyExtraSmallRegular>
          {t(
            'employee.home.charging-session.card.paused-by-car.chip.description'
          )}
        </StBodyExtraSmallRegular>
      </StCardRow>
    </StGradientCard>
  )
}
type PausedByCarChargingSessionCardProps = {
  customerName: string
  kWh: number
  startTime: Date
  mode: UserSettingsMode
}

const StGreyBodySmallRegular = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-dark']};
`

const StBodyExtraSmallRegular = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
