import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { EmployeeRoutes } from '../../../../routing/routes'

export const ChargeCardLandingPageScreen = () => {
  // -- Hooks --
  const navigate = useNavigate()
  const { activationCode } = useParams()

  useEffect(() => {
    // For now, always redirect to charge detail/activation page
    // We use this screen so we can adapt the flow when scanning the QR code later on
    navigate(EmployeeRoutes.ChargeCard, {
      state: { activationCode },
    })
  }, [])

  return null
}
