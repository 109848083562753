import { type ReactNode } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import {
  BodyLargeSemiBold,
  BodyMediumRegular,
  H4,
} from '../../../../components/typography'
import { takePicture } from '../../lib/takePicture'

import { MobileChip } from './MobileChip'

type MobileUploadContentProps = {
  examples?: string[]
  uploadPicture: (file: File[]) => Promise<void>
  title: string
  description: string
  currentStep: number
  totalSteps: number
  extraButton?: ReactNode
}

export const MobileUploadContent = ({
  title,
  description,
  uploadPicture,
  examples,
  currentStep,
  totalSteps,
  extraButton,
}: MobileUploadContentProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Handlers --
  const handleTakePicture = async () => {
    const file = await takePicture(title)

    // If file is larger than 5MB, throw an error
    if (file.size > 5000000) {
      return toast.error(t('onboarding.mobile-images.size-error'))
    }

    await uploadPicture([file])
  }

  // -- Render --
  return (
    <StContainer>
      <MobileChip currentStep={currentStep} totalSteps={totalSteps} />

      <StH4>{title}</StH4>
      <StBody>{description}</StBody>

      {examples ? (
        <>
          <BodyLargeSemiBold>
            {t('onboarding.mobile-documents.examples')}
          </BodyLargeSemiBold>
          <StExampleContainer>
            {examples.map((image) => (
              <StImage key={image} src={image} alt="" />
            ))}
          </StExampleContainer>
        </>
      ) : null}

      <FloatingButtonContainer>
        {extraButton ? (
          <ButtonTertiary
            onClick={handleTakePicture}
            icon={['fass', 'plus']}
            iconAlignment="left"
          >
            {t('onboarding.mobile-documents.add-picture')}
          </ButtonTertiary>
        ) : (
          <ButtonPrimary
            onClick={handleTakePicture}
            icon={['fass', 'plus']}
            iconAlignment="left"
          >
            {t('onboarding.mobile-documents.add-picture')}
          </ButtonPrimary>
        )}
        {extraButton}
      </FloatingButtonContainer>
    </StContainer>
  )
}

const StContainer = styled.div`
  padding-bottom: ${({ theme }) =>
    `calc(var(--inset-bottom, ${theme.UI.SpacingPx.Space6}) + var(--sticky-button-container-height))`};
`

const StH4 = styled(H4)`
  margin: ${({ theme }) => theme.UI.SpacingPx.Space5} 0;
`

const StExampleContainer = styled.div`
  display: flex;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  overflow: scroll;
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
  margin-right: -${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-left: -${({ theme }) => theme.UI.SpacingPx.Space6};

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`

const StImage = styled.img`
  width: 190px;
  height: 190px;
  aspect-ratio: 1/1;
  object-fit: cover;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StBody = styled(BodyMediumRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
