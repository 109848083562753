import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { H4 } from '../../../../components/typography'
import example1 from '../../assets/examples/example1.png'
import example2 from '../../assets/examples/example2.png'
import example3 from '../../assets/examples/example3.png'
import { MobileUploadContent } from '../../components/upload/MobileUploadContent'
import { MobileUploadLoader } from '../../components/upload/MobileUploadLoader'
import { MobileUploadSuccess } from '../../components/upload/MobileUploadSuccess'
import { useImagePolling } from '../../hooks/useImagePolling'
import { useMultiImageUpload } from '../../hooks/useMultiImageUpload'
import { useOnboarding } from '../../hooks/useOnboarding'

import { TOTAL_STEPS } from './ExtraImagesStep'

const CURRENT_STEP = 1
const examples = [example1, example2, example3]

export const ElectricityBoxImageStep = () => {
  // -- Hooks --
  const { values, handleNext } = useOnboarding()
  const { t } = useTranslation()
  useImagePolling()

  const { loading, handleAddImage, handleRemoveImage } = useMultiImageUpload(
    'electricalBox',
    'electricalBoxImageIds'
  )

  // -- Handlers --
  const handleSubmit = () => {
    handleNext(values)
  }

  // -- Render --
  if (loading) {
    return (
      <MobileUploadLoader currentStep={CURRENT_STEP} totalSteps={TOTAL_STEPS} />
    )
  }

  if (values.electricalBoxImageIds?.length) {
    return (
      <MobileUploadSuccess
        imageIds={values.electricalBoxImageIds}
        currentStep={CURRENT_STEP}
        totalSteps={TOTAL_STEPS}
        title={t('onboarding.electricitybox-image.title')}
        onSubmit={handleSubmit}
        onDelete={handleRemoveImage}
        onAddImage={
          values.electricalBoxImageIds.length >= 5 ? undefined : handleAddImage
        }
      />
    )
  }

  return (
    <>
      <StH4>{t('onboarding.mobile-images.title')}</StH4>
      <MobileUploadContent
        uploadPicture={handleAddImage}
        title={t('onboarding.electricitybox-image.title')}
        description={t('onboarding.electricitybox-image.description')}
        currentStep={CURRENT_STEP}
        totalSteps={TOTAL_STEPS}
        examples={examples}
      />
    </>
  )
}

const StH4 = styled(H4)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
`
