import ContentLoader from 'react-content-loader'

import type { IContentLoaderProps } from 'react-content-loader'

export const StatusCardSkeleton = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={160}
      viewBox="0 0 600 160"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="1" y="1" rx="8" ry="8" width="599" height="159" />
      <rect x="24" y="92" rx="4" ry="4" width="148" height="44" />
    </ContentLoader>
  )
}
