import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { AnalyticsProvider } from '../../modules/analytics/providers/AnalyticsProvider'
import { useAuthentication } from '../../modules/authentication/hooks/useAuthentication'
import { UserProvider } from '../../modules/user/providers/UserProvider'
import { UnauthenticatedRoutes } from '../routes'

export const AuthenticatedGuard = () => {
  const { isAuthenticated, isLoading, refreshUser } = useAuthentication()
  // It's important that we do not use the authentication hook for this yet
  const { user: auth0User } = useAuth0()

  // -- Effects --
  // Update the user object until the email is verified
  useEffect(() => {
    if (
      auth0User &&
      !auth0User?.email_verified &&
      import.meta.env.VITE_ENV !== 'local'
    ) {
      refreshUser()
    }
  }, [auth0User?.email])

  if (isLoading) {
    return null
  }

  return isAuthenticated ? (
    <AnalyticsProvider>
      <UserProvider>
        <Outlet />
      </UserProvider>
    </AnalyticsProvider>
  ) : (
    <Navigate to={UnauthenticatedRoutes.Root} replace />
  )
}
