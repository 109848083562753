import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  isSameDay,
  startOfDay,
  subDays,
  subMonths,
  subYears,
} from 'date-fns'
import { useRef, type FocusEventHandler, useState } from 'react'
import DatePicker from 'react-datepicker'
import { styled } from 'styled-components'

import { useDatepicker } from '../../../core/hooks/useDatePicker'
import { registerDatePickerLocales } from '../../../core/hooks/useGetDateFnsLocale'
import { useUser } from '../../../user/hooks/useUser'
import { BodyMediumRegularCss, BodyMediumSemiBoldCss } from '../../typography'

import * as Styled from './DateInput.styled'
import { DateInputComponent } from './DateInputComponent'

import type { Shortcut } from '../../../core/hooks/useDatePicker'
import type { Placement } from '@floating-ui/react'

export type DateInputProps = {
  value?: Date | DateRange | null
  range?: boolean
  onChange: (value: DateRange | Date) => void
  onBlur?: FocusEventHandler<HTMLInputElement>
  label?: string
  placeholder?: string
  error?: string
  touched?: boolean
  disabled?: boolean
  readOnly?: boolean
  minDate?: Date
  maxDate?: Date
  size?: 'sm' | 'md' | 'lg'
  shortCutType?: 'month' | 'short-term'
  mode?: 'day' | 'month' | 'year'
  withNavigation?: boolean
  formatString?: string
  placement?: Placement
  className?: string
}

export type DateRange = {
  start: Date | null
  end: Date | null
}

export const DateInput = ({
  value,
  range = false,
  onChange,
  shortCutType,
  error,
  touched,
  minDate,
  maxDate,
  size = 'lg',
  label,
  readOnly,
  disabled,
  mode = 'day',
  withNavigation = false,
  formatString,
  placement,
  className,
}: DateInputProps) => {
  const { monthBasedShortcuts, shortTermShortcuts } = useDatepicker()
  const reactDatePicker = useRef<DatePicker>(null)
  const { user } = useUser()

  registerDatePickerLocales()

  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)
  const [shortcutsContainerWidth, setShortcutsContainerWidth] =
    useState<number>()

  const shortcuts =
    shortCutType === 'month' ? monthBasedShortcuts : shortTermShortcuts

  const onDateChange = (value: Date | Array<Date | null>) => {
    setOpenDatePicker(false)

    // We only support start of day values for now, later we could implement a time selection too
    if (!Array.isArray(value)) {
      return onChange(startOfDay(value))
    }

    const [start, end] = value

    onChange({
      start: start ? startOfDay(start) : null,
      end: end ? endOfDay(end) : null,
    })
  }

  const activeShortcut = (shortcut: Shortcut) => {
    if (shortCutType && range) {
      const dateRange = value as DateRange

      const shortcutValue = shortcut.onClick()

      if (!shortcutValue || !shortcutValue.start || !shortcutValue.end) {
        return false
      }

      if (!dateRange?.start || !dateRange?.end) {
        return false
      }

      return (
        isSameDay(dateRange.start, shortcutValue.start) &&
        isSameDay(dateRange.end, shortcutValue.end)
      )
    }

    if (shortCutType && !range) {
      const date = value as Date
      const shortcutValue = shortcut.onClick()

      if (!shortcutValue || !shortcutValue.start) {
        return false
      }

      return isSameDay(date, shortcutValue.start)
    }

    return false
  }

  const shortcutsContainerRef = (node: HTMLDivElement) => {
    setShortcutsContainerWidth(node?.offsetWidth)
  }

  const handlePrevious = () => {
    if (mode === 'year') {
      onChange(subYears(value as Date, 1))
    } else if (mode === 'month') {
      onChange(subMonths(value as Date, 1))
    } else {
      onChange(subDays(value as Date, 1))
    }
  }

  const handleNext = () => {
    if (mode === 'year') {
      onChange(addYears(value as Date, 1))
    } else if (mode === 'month') {
      onChange(addMonths(value as Date, 1))
    } else {
      onChange(addDays(value as Date, 1))
    }
  }

  return (
    <Styled.DatePickerContainer
      $shortcutsContainerWidth={shortcutsContainerWidth}
      className={className}
    >
      {label && <StLabel $readOnly={readOnly}>{label}</StLabel>}
      <DatePicker
        disabled={!readOnly && disabled}
        ref={reactDatePicker}
        dateFormat={formatString ?? (range ? 'MMM d' : 'MMM dd yyyy')}
        popperPlacement={placement ?? 'top-end'}
        popperProps={{
          strategy: 'fixed',
        }}
        selectsRange={range}
        selected={range ? null : (value as Date)}
        startDate={range ? (value as DateRange)?.start : null}
        endDate={range ? (value as DateRange)?.end : null}
        onChange={(date) => {
          if (!date) {
            return
          }

          onDateChange(date)
        }}
        icon="fasr fa-calendar"
        open={openDatePicker}
        readOnly={readOnly}
        customInput={
          <DateInputComponent
            toggleDatePicker={() => setOpenDatePicker(!openDatePicker)}
            rawValue={value}
            error={error}
            touched={touched}
            size={size}
            readOnly={readOnly}
            disabled={disabled}
            withNavigation={withNavigation}
            onPrevious={handlePrevious}
            onNext={handleNext}
            formatString={formatString}
            minDate={minDate}
            maxDate={maxDate}
            mode={mode}
          />
        }
        onCalendarOpen={() => setOpenDatePicker(true)}
        onCalendarClose={() => setOpenDatePicker(false)}
        onClickOutside={() => setOpenDatePicker(false)}
        minDate={minDate}
        maxDate={maxDate}
        showYearPicker={mode === 'year'}
        showMonthYearPicker={mode === 'month'}
        locale={user.language}
      >
        {shortCutType && (
          <StShortcutContainer ref={shortcutsContainerRef}>
            {shortcuts.map((shortcut) => (
              <StShortcut
                onClick={() => {
                  const dates = shortcut.onClick()

                  onDateChange(
                    dates.end
                      ? [dates.start, dates.end]
                      : dates.start || new Date()
                  )
                  reactDatePicker.current?.setOpen(false)
                }}
                key={shortcut.key}
                $active={activeShortcut(shortcut)}
              >
                {shortcut.label}
              </StShortcut>
            ))}
          </StShortcutContainer>
        )}
      </DatePicker>
    </Styled.DatePickerContainer>
  )
}

const StShortcutContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space3} ${theme.UI.SpacingPx.Space4}`};

  border-right: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
`

const StShortcut = styled.div<{ $active?: boolean }>`
  ${({ $active }) => ($active ? BodyMediumSemiBoldCss : BodyMediumRegularCss)}

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space4}`};

  cursor: pointer;
  border-radius: 6px;

  &::first-letter {
    text-transform: uppercase;
  }

  &:hover {
    background-color: #f9fafb;
  }
`

export const StLabel = styled.label<{ $readOnly?: boolean }>`
  ${({ $readOnly }) =>
    $readOnly ? BodyMediumRegularCss : BodyMediumSemiBoldCss}
  color: ${({ theme, $readOnly }) =>
    $readOnly
      ? theme.theme.text.body['gray-mid']
      : theme.theme.text.body.black};
  display: block;
  margin-bottom: ${({ theme, $readOnly }) =>
    $readOnly ? 0 : theme.UI.SpacingPx.Space2};
`
