import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { StartNavigation } from '@proteansoftware/capacitor-start-navigation'
import { styled } from 'styled-components'

import { IconButtonPrimary } from '../../../components/button/IconButton'
import { BodyExtraSmallRegular } from '../../../components/typography'

import type { ChargingLocation } from 'types'

type AddressNavigationCardProps = {
  detail: ChargingLocation
}

export const AddressNavigationCard = ({
  detail,
}: AddressNavigationCardProps) => {
  // -- Vars --
  const address =
    detail.address && detail.town && detail.postcode
      ? `${detail.address}, ${detail.postcode} ${detail.town}`
      : null

  // -- Handlers --
  const openMaps = async () => {
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${address}`

    try {
      if (Capacitor.isNativePlatform()) {
        StartNavigation.launchMapsApp({
          // When address is not defined, use latitude and longitude
          latitude: address ? null : detail.latitude,
          longitude: address ? null : detail.longitude,
          // When address is defined, use address
          address: address
            ? {
                city: detail.town,
                postalCode: detail.postcode,
                country: detail.country,
                street: detail.address,
                state: null,
              }
            : null,
          name: detail.name,
          travelMode: 'driving',
        })
      } else {
        Browser.open({ url: googleMapsUrl })
      }
    } catch {
      Browser.open({ url: googleMapsUrl })
    }
  }

  // -- Render --
  return (
    <StCard>
      <BodyExtraSmallRegular>{address || detail.name}</BodyExtraSmallRegular>
      <IconButtonPrimary
        icon={['fass', 'diamond-turn-right']}
        onClick={openMaps}
      />
    </StCard>
  )
}

const StCard = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  display: flex;
  justify-content: space-between;
  align-items: center;
`
