import { useEffect, useState } from 'react'

import { FullPageLoader } from '../../../components/general/FullPageLoader'
import { useGeoLocation } from '../../../location/hooks/useGeoLocation'
import { Map } from '../components/Map'

export const MapScreen = () => {
  // -- State --
  const [firstLocation, setFirstLocation] = useState<{
    latitude: number
    longitude: number
  } | null>(null)

  // -- Hooks --
  const { location } = useGeoLocation()

  // -- Effects --
  useEffect(() => {
    if (location && !firstLocation) {
      setFirstLocation({
        latitude: location.latitude,
        longitude: location.longitude,
      })
    }
  }, [location])

  // -- Render --
  if (!firstLocation) {
    return <FullPageLoader withNavigation={false} />
  }

  return <Map startLocation={firstLocation} />
}
