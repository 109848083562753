import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { match } from 'ts-pattern'

import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { EmployerRoutes } from '../../../../routing/routes'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { Banner, BannerType } from '../../../components/general/Banner'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { Menubar } from '../../../components/general/Menubar'
import { ContentContainer } from '../../../components/page-layout'
import { PersonalDataEdit } from '../../../components/profile/PersonalDataEdit'
import { StatusChip } from '../../../components/status-chip/StatusChip'
import {
  BodyMediumRegular,
  BodyMediumSemiBold,
} from '../../../components/typography'
import {
  INVITE_REMINDER_MAX_DAYS,
  ONBOARDING_REMINDER_MAX_DAYS,
} from '../../../core/utils/status'
import { useMember } from '../../../member/hooks/useMember'
import { isUrgent } from '../../../quotes/lib/quote'

import { DeleteEmployeeDialog } from './DeleteEmployeeDialog'
import { DetailQuoteApproval } from './DetailQuoteApproval'
import { EmployeeContractCard } from './EmployeeContractCard'
import { PairedDevicesCard } from './PairedDevicesCard'
import { PracticalInfoCard } from './PracticalInfoCard'
import { QuoteCard } from './QuoteCard'

import type { MenubarItems } from '../../../components/general/Menubar'
import type { PersonalDataEditValues } from '../../../components/profile/PersonalDataEdit'
import type { AxiosPromise } from 'axios'
import type { User } from 'database'
import type { UserWithContractAndCards, UserWithMembers } from 'types'

type EmployeeDetailProps = {
  employee: UserWithContractAndCards
  updateEmployee: (data: Partial<User>) => AxiosPromise<UserWithMembers>
  refetchEmployee: () => void
}

export const EmployeeDetail = ({
  employee,
  updateEmployee,
  refetchEmployee,
}: EmployeeDetailProps) => {
  // -- State --
  const [dialogOpen, setDialogOpen] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [editable, setEditable] = useState<boolean>(false)

  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const navigate = useNavigate()
  const { currentMember } = useMember()

  // -- Data --
  const [, deleteEmployee] = useAuthMemberAxios(
    {
      url: replaceParametersInPath(Urls.employeeDelete, { id: employee.id }),
      method: 'DELETE',
    },
    { manual: true }
  )

  const [, markEmployeeForManualHandover] = useAuthMemberAxios(
    {
      url: replaceParametersInPath(Urls.employeeManualMspHandover, {
        memberId: employee.members[0].id,
      }),
      method: 'POST',
    },
    { manual: true }
  )

  const [, resendEmployeeInvite] = useAuthMemberAxios(
    {
      url: Urls.employeeResendInvite,
      method: 'POST',
      data: { userId: employee.id },
    },
    { manual: true }
  )

  // -- Effects --
  // If the currentMember changes, we navigate back to the employees overview
  // This employee will not exist on the new member so it makes no sense to stay on the detail page
  useEffect(() => {
    if (employee.members[0]?.companyId !== currentMember.companyId) {
      navigate(EmployerRoutes.Employees)
    }
  }, [currentMember.companyId, currentMember.id, employee.members, navigate])

  // -- Vars --
  const hasQuoteFlow = [
    'QuotationPendingApproval',
    'QuotationApprovedByEmployer',
    'QuotationApprovedByEmployee',
    'QuotationApproved',
  ].includes(employee.hcpStatus.status)

  const menuItem: MenubarItems = [
    {
      name: t('admin.employee-detail.actions.delete'),
      onClick: () => setDialogOpen(true),
    },
    employee.members[0].mspStatus === 'Requested' ||
    employee.members[0].mspStatus === 'Invited' ||
    employee.members[0].mspStatus === 'Registered'
      ? {
          name: t('admin.employee-detail.actions.manual-handover'),
          onClick: () => handleManualHandover(),
        }
      : undefined,
    employee.hcpStatus.status === 'Invited' ||
    employee.members[0].mspStatus === 'Invited'
      ? {
          name: t('admin.employee-detail.actions.resend-invite'),
          onClick: () => handleResendEmployeeInvite(),
        }
      : undefined,
  ].filter(Boolean) as MenubarItems

  const bannerText = match(employee.hcpStatus.status)
    .with(
      'InstallationPlanned',
      'Configured',
      'CertificationPlanned',
      'OnboardingCompleted',
      'OnboardingRejected',
      'PreSiteVisitPlanned',
      (status) => t(`admin.employee-detail.callout.${status}`)
    )
    .with('SharedWithInstallationPartner', () =>
      t(`admin.employee-detail.callout.OnboardingCompleted`)
    )
    .with('Invited', () =>
      employee.hcpStatus.overdue
        ? t(`admin.employee-detail.callout.Invited.overdue`, {
            days: INVITE_REMINDER_MAX_DAYS,
          })
        : t(`admin.employee-detail.callout.Invited`)
    )
    .with('Onboarding', () =>
      employee.hcpStatus.overdue
        ? t(`admin.employee-detail.callout.Onboarding.overdue`, {
            days: ONBOARDING_REMINDER_MAX_DAYS,
          })
        : t(`admin.employee-detail.callout.Onboarding`)
    )
    .with('Registered', () =>
      t(`admin.employee-detail.callout.Registered`, {
        date: format(
          new Date(employee.members[0].activationDate!),
          'dd/MM/yyyy'
        ),
      })
    )
    .with('ExportedForMigration', () =>
      t(`admin.employee-detail.callout.ExportedForMigration`)
    )
    // Don't use exhaustiveness check here, we want to allow new hcp status types without changing the code here
    .otherwise(() => undefined)

  // -- Handlers --
  const handleDelete = async () => {
    trackEvent(EventType.Submit, 'confirm_delete_member', {
      userId: employee.id,
    })

    try {
      await deleteEmployee()

      navigate(EmployerRoutes.Employees)
      toast.success(t('admin.employee-detail.actions.delete.success'))
    } catch {
      toast.error(t('admin.employee-detail.actions.delete.error'))
    }
  }

  const handleManualHandover = async () => {
    trackEvent(EventType.Submit, 'manual_handover', {
      userId: employee.id,
    })

    try {
      await markEmployeeForManualHandover()

      refetchEmployee()
      toast.success(t('admin.employee-detail.actions.manual-handover.success'))
    } catch {
      toast.error(t('admin.employee-detail.actions.manual-handover.error'))
    }
  }

  const handleResendEmployeeInvite = async () => {
    trackEvent(EventType.Submit, 'resend_invite', {
      userId: employee.id,
    })

    try {
      await resendEmployeeInvite()

      refetchEmployee()
      toast.success(t('admin.employee-detail.actions.resend-invite.success'))
    } catch {
      toast.error(t('admin.employee-detail.actions.resend-invite.error'))
    }
  }

  const handleUpdate = async (data: PersonalDataEditValues) => {
    setUpdateLoading(true)
    const response = await updateEmployee({ ...data, id: employee.id })
    setUpdateLoading(false)

    return response
  }

  // -- Render --
  return (
    <ContentContainer>
      <StContainer>
        <StHeader>
          <StTitleContainer>
            <DetailPageHeader
              title={employee.fullName || employee.workEmail || ''}
              topTitle={t('admin.employees.title')}
            />
            <div>
              <div>
                <BodyMediumRegular>
                  {t('admin.employee-detail.pole-status')}
                </BodyMediumRegular>
                <StatusChip
                  label={t(`hcp.status.${employee.hcpStatus.status}`)}
                  urgent={isUrgent(employee.hcpStatus)}
                  bold
                />
              </div>
              <div>
                <BodyMediumRegular>
                  {t('admin.employee-detail.card-status')}
                </BodyMediumRegular>
                <BodyMediumSemiBold>
                  {employee.members[0].manualMspHandover &&
                  employee.members[0].mspStatus === 'Sent'
                    ? t(`msp.status.Sent.manual`)
                    : t(`msp.status.${employee.members[0].mspStatus}`)}
                </BodyMediumSemiBold>
              </div>
            </div>
          </StTitleContainer>
          <StMenubarWrapper>
            <Menubar
              size="sm"
              title={t('admin.employee-detail.actions')}
              options={menuItem}
            />
          </StMenubarWrapper>
        </StHeader>

        {bannerText && (
          <Banner type={BannerType.INFORMATION} icon={['fass', 'info-square']}>
            {bannerText}
          </Banner>
        )}

        {hasQuoteFlow ? (
          <DetailQuoteApproval
            employee={employee}
            refetchEmployee={refetchEmployee}
          />
        ) : null}

        <PersonalDataEdit
          user={employee}
          disabled={updateLoading}
          updateUser={handleUpdate}
          disableEdit={!employee.auth0Id}
          title={t('profile.personal-data.title')}
          editable={editable}
          setEditable={setEditable}
        />

        <PracticalInfoCard
          employee={employee}
          refetchEmployee={refetchEmployee}
        />

        {(employee.members[0].hcpContract ||
          employee.members[0].mspContractId) && (
          <EmployeeContractCard
            refetchEmployee={refetchEmployee}
            employeeMemberId={employee.members[0].id}
            hcpContract={employee.members[0].hcpContract}
            mspContract={employee.members[0].mspContract}
            companyId={employee.members[0].companyId}
          />
        )}

        {match(employee.hcpStatus.status)
          .with(
            'SharedWithInstallationPartner',
            'InstallationPlanned',
            'Configured',
            'CertificationPlanned',
            'Operational',
            'Inapplicable',
            () => <QuoteCard employee={employee} />
          )
          .otherwise(() => null)}

        <PairedDevicesCard
          dongleMacAddress={
            employee.members.at(0)?.scoptDongleMacAddress ?? null
          }
          ocppId={employee.addresses.at(0)?.devices.at(0)?.ocppId ?? null}
        />
      </StContainer>
      <DeleteEmployeeDialog
        employee={employee}
        open={dialogOpen}
        setOpen={setDialogOpen}
        handleDelete={handleDelete}
      />
    </ContentContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const StTitleContainer = styled.div`
  & > div {
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space2};

    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

    & > div {
      display: flex;
      align-items: center;
      gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
    }
  }
`

const StMenubarWrapper = styled.div`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
