import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useChargingPoint } from '../../../chargingPoint/hooks/useChargingPoint'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { FullPageLoader } from '../../../components/general/FullPageLoader'
import { ContentContainer } from '../../../components/page-layout'
import {
  BodyMediumMedium,
  BodySmallRegular,
  H5,
} from '../../../components/typography'
import { useIntercom } from '../../../intercom/hooks/useIntercom'
import { ChargerInformationCard } from '../components/ChargerInformationCard'
import { NotOperationalCard } from '../components/NotOperationalCard'

export const EmployeeChargerScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const { show: showIntercom } = useIntercom()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { loading, charger, error, charging, operational, address } =
    useChargingPoint()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Callbacks --
  const handleNotOperationalContactClick = useCallback(() => {
    trackEvent(EventType.Click, 'charger_not_operational')
    showIntercom()
  }, [trackEvent, showIntercom])

  if (loading && !charger) {
    return <FullPageLoader withNavigation />
  }

  if (error || !charger) {
    throw new Error('Failed to load charger data')
  }

  // -- Render --
  return (
    <ContentContainer>
      <DetailPageHeader
        title={t('employee.profile.charger.title')}
        topTitle={t('employee.profile.title')}
        onBack={
          isDesktop
            ? undefined
            : // If we can, go back to the previous page
            state?.backTo
            ? () => navigate(state.backTo)
            : () => navigate(EmployeeRoutes.Profile)
        }
      />
      <StContainer>
        <StContainerColumn>
          <ChargerInformationCard
            style="gray"
            charging={charging}
            operational={operational}
          />

          {!operational && (
            <NotOperationalCard
              onContactClick={handleNotOperationalContactClick}
            />
          )}
        </StContainerColumn>

        <StContainerColumn>
          <StDetailsContainer>
            <H5>{t('employee.profile.charger.details')}</H5>

            <StDetailCard>
              <div>
                <StBodySmallRegular>
                  {t('employee.profile.charger.brand')}
                </StBodySmallRegular>
                <StBodyMediumMedium>{charger.device.vendor}</StBodyMediumMedium>
              </div>

              <div>
                <StBodySmallRegular>
                  {t('employee.profile.charger.model')}
                </StBodySmallRegular>
                <StBodyMediumMedium>
                  {charger.device.model_number}
                </StBodyMediumMedium>
              </div>

              <div>
                <StBodySmallRegular>
                  {t('employee.profile.charger.address')}
                </StBodySmallRegular>
                <StBodyMediumMedium>{address}</StBodyMediumMedium>
              </div>
            </StDetailCard>
          </StDetailsContainer>
        </StContainerColumn>
      </StContainer>
    </ContentContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space15};

    padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  h5 {
    margin: 0;
  }
`

const StBodySmallRegular = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
const StBodyMediumMedium = styled(BodyMediumMedium)`
  margin: ${({ theme }) => theme.UI.SpacingPx.Space3} 0;
`
const StDetailCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  @media ${breakpoints.desktop} {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
    padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
  }
`
