import { styled } from 'styled-components'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { H5 } from '../typography'

import {
  InformationOverviewItem,
  type InformationItem,
} from './InformationOverviewItem'

export enum InformationOverviewType {
  KeyValue = 'key-value',
  ZohoImages = 'zoho-images',
}

type InformationOverviewProps = {
  type?: InformationOverviewType
  title: string
  values: InformationItem[]
}

export const InformationOverview = ({
  type = InformationOverviewType.KeyValue,
  title,
  values,
}: InformationOverviewProps) => {
  return (
    <StOverviewContainer
      $columns={type === InformationOverviewType.ZohoImages ? 1 : 2}
    >
      <StHeaderContainer
        $columns={type === InformationOverviewType.ZohoImages ? 1 : 2}
      >
        <H5>{title}</H5>
      </StHeaderContainer>

      {values.map((item, index) => (
        <InformationOverviewItem type={type} key={index} item={item} />
      ))}
    </StOverviewContainer>
  )
}

const StOverviewContainer = styled.div<{ $columns: number }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: ${({ theme, $columns }) =>
    $columns > 1 ? theme.UI.SpacingPx.Space8 : theme.UI.SpacingPx.Space6};

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
    grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
  }
`

const StHeaderContainer = styled.div<{ $columns: number }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  h5 {
    margin: 0;
  }

  @media ${breakpoints.desktop} {
    grid-column: span ${({ $columns }) => $columns};
  }
`
