import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { ButtonTertiary } from '../button/ButtonTertiary'
import { BodyMediumRegular, H4Css, H6 } from '../typography'

import { PageHeader } from './PageHeader'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export type DetailPageHeaderAction = {
  name: string
  icon: IconProp
  onClick: () => void
}

type DetailPageHeaderProps = {
  title?: string
  onBack?: () => void
  topTitle?: string
  actions?: DetailPageHeaderAction[]
  desktopButton?: DetailPageHeaderAction & { loading?: boolean }
}

export const DetailPageHeader = ({
  title,
  onBack,
  topTitle,
  actions,
  desktopButton,
}: DetailPageHeaderProps) => {
  // -- Hooks --
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Render --
  return (
    <PageHeader>
      {!isDesktop && onBack && (
        <StButton onClick={onBack}>
          <FontAwesomeIcon fontSize="20px" icon={['fasr', 'chevron-left']} />
        </StButton>
      )}
      <StTitleContainer>
        {isDesktop && (topTitle || onBack) && (
          <StDesktopTopTitle $clickable={!!onBack} onClick={onBack}>
            {onBack && <FontAwesomeIcon icon={['fasr', 'arrow-left']} />}
            {topTitle}
          </StDesktopTopTitle>
        )}
        {title && (
          <StFlexTitle>
            <StTitle>{title}</StTitle>
            {desktopButton && (
              <ButtonTertiary
                size="sm"
                compact
                loading={desktopButton.loading}
                icon={desktopButton.icon}
                onClick={desktopButton.onClick}
              >
                {desktopButton.name}
              </ButtonTertiary>
            )}
          </StFlexTitle>
        )}
      </StTitleContainer>

      {/* This is here for alignment */}
      {!isDesktop && (
        <StSpacer>
          {actions?.map((action) => (
            <StButton key={action.name} onClick={() => action.onClick()}>
              <FontAwesomeIcon fontSize="20px" icon={action.icon} />
            </StButton>
          ))}
        </StSpacer>
      )}
    </PageHeader>
  )
}

const StTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media ${breakpoints.desktop} {
    align-items: flex-start;
  }
`

const StFlexTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const StTitle = styled(H6)`
  margin: 0;

  @media ${breakpoints.desktop} {
    ${H4Css}
  }
`

const StButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 20px;

  cursor: pointer;
`

const StDesktopTopTitle = styled(BodyMediumRegular)<{ $clickable: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};

  margin-bottom: ${({ $clickable, theme }) =>
    $clickable ? theme.UI.SpacingPx.Space2 : 0};

  &:hover {
    color: ${({ theme }) => theme.theme.colors['quinary-1']};
  }
`

const StSpacer = styled.div`
  min-width: 20px;
`
