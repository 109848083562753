/** Accepts a string in the format "HH:MM" */
export function convertStringToDateTime(timeString: string): Date {
  // Assuming the time is in the format "HH:MM"
  const [hours, minutes] = timeString.split(':')

  // Create a new Date object for today
  const date = new Date()

  // Set hours and minutes from the time string
  date.setHours(Number.parseInt(hours, 10), Number.parseInt(minutes, 10), 0, 0)

  return date
}
