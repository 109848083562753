import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { breakpoints } from '../../../../theme/layout/breakpoints'

export const StContainer = styled.div<{
  $size: 'sm' | 'md' | 'lg'
}>`
  display: flex;
  flex-direction: column;

  --react-international-phone-height: ${({ $size }) =>
    $size === 'sm' ? '44px' : $size === 'lg' ? '60px' : '52px'};

  .react-international-phone-input-container {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

    .react-international-phone-country-selector-button {
      border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
      padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space3};
    }

    & * {
      font-family: 'Inter';
    }
  }
`

export const StPhoneInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  & > div:last-child {
    flex-grow: 1;
  }
`

export const StSelectionContainer = styled.div`
  display: flex;
`

export const StCountrySelectorButton = styled.button<{
  $disabled?: boolean
  $active?: boolean
}>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  align-items: center;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};

  background-color: ${({ theme, $disabled }) =>
    $disabled
      ? theme.components.input['disabled-bg']
      : theme.theme.colors.white};

  outline: none;
  box-shadow: none;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.components.input.border};
  border-color: ${({ theme, $active }) =>
    $active
      ? theme.components.input['focus-border']
      : theme.components.input.border};
  border-width: ${({ $disabled }) => ($disabled ? '0px' : '1px')};

  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};

  & > * {
    color: ${({ theme, $disabled }) =>
      $disabled
        ? theme.components.input['disabled-text']
        : theme.components.checkbox['icon-dark']};
  }
`

export const StIcon = styled(FontAwesomeIcon)`
  transform: rotate(180deg);
`

export const StCountriesDropdown = styled.div<{ $show?: boolean }>`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space5};
  background-color: ${({ theme }) => theme.theme.colors.white};
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  z-index: 100;

  .react-international-phone-country-selector-dropdown {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    max-height: none;
    z-index: 100;
    box-shadow: none;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

    margin: ${({ theme }) => theme.UI.SpacingPx.Space5} 0;

    &:focus {
      outline: none;
    }

    .react-international-phone-country-selector-dropdown__list-item {
      display: flex;
      flex-direction: row;
      gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
      align-items: center;

      padding: ${({ theme }) =>
        `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space3}`};
      border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

      margin: 2px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .react-international-phone-country-selector-dropdown__list-item-flag-emoji {
        margin: 0;
      }

      .react-international-phone-country-selector-dropdown__list-item-country-name {
        flex-grow: 1;
      }

      .react-international-phone-country-selector-dropdown__list-item-dial-code {
        color: ${({ theme }) => theme.theme.text.body['gray-mid']};
      }

      & * {
        font-family: 'F37 Jagger';
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 140%;
        font-weight: 500;
        color: black;
      }
    }

    .react-international-phone-country-selector-dropdown__preferred-list-divider {
      margin: ${({ theme }) => `${theme.UI.SpacingPx.Space3} 0`};
      background-color: ${({ theme }) => theme.theme.colors['nonary-8']};
    }
  }

  @media ${breakpoints.desktop} {
    border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

    box-shadow: 12px 12px 32px 0px rgba(26, 26, 26, 0.06);

    .react-international-phone-country-selector-dropdown {
      max-height: 300px;
    }
  }
`
