import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { BodySmallSemiBold } from '../../../../components/typography'

type MobileChipProps = {
  currentStep: number
  totalSteps: number
  success?: boolean
}

export const MobileChip = ({
  currentStep,
  totalSteps,
  success,
}: MobileChipProps) => {
  return (
    <StContainer $success={success}>
      <FontAwesomeIcon
        icon={success ? ['fass', 'check'] : ['fass', 'camera']}
      />
      <BodySmallSemiBold>
        {currentStep}/{totalSteps}
      </BodySmallSemiBold>
    </StContainer>
  )
}

const StContainer = styled.div<{ $success?: boolean }>`
  width: fit-content;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space3}`};

  color: ${({ theme, $success }) =>
    $success ? theme.theme.colors.white : theme.theme.colors.black};

  background-color: ${({ theme, $success }) =>
    $success
      ? theme.components.chip.primary['success-bg']
      : theme.components.chip.primary['default-bg']};
`
