import { useContext } from 'react'

import { hemsFlowContext } from '../context/hemsFlowContext'

export const useHemsFlow = () => {
  const hemsFlow = useContext(hemsFlowContext)

  if (!hemsFlow) {
    throw new Error('useHemsFlow must be used within a HemsFlowProvider')
  }

  return hemsFlow
}
