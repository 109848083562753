import { useState } from 'react'
import { useInterval } from 'usehooks-ts'

import { useAuthAxios } from '../../../api/hooks/useAuthAxios'
import { Urls } from '../../../api/urls'

import type { HomeChargingSession } from 'types'

const POLLING_INTERVAL = 5000

export const useHomeChargingSession = () => {
  const [retriesOnError, setRetriesOnError] = useState(0)

  const shouldRetry = retriesOnError < 2

  const [bag, refetch] = useAuthAxios<HomeChargingSession>({
    url: Urls.fetchHomeChargingSession,
  })

  useInterval(() => {
    if (shouldRetry) {
      refetch()

      if (bag.error) {
        setRetriesOnError((retries) => retries + 1)
      }
    }
  }, POLLING_INTERVAL)

  return bag
}
