import * as turf from '@turf/turf'

import type {
  BBox,
  Feature,
  Polygon,
  MultiPolygon,
  Properties,
} from '@turf/turf'

export const boundingBoxesContainBoundingBox = (
  boundingBoxes: BBox[],
  checkBoundingBox: BBox
) => {
  // Convert the list of bounding boxes into GeoJSON Polygons
  const polygons = boundingBoxes.map((bbox) => turf.bboxPolygon(bbox))

  // Accumulate the area by unioning the polygons. Start with the first polygon.
  let accumulatedArea: Feature<Polygon | MultiPolygon, Properties> = polygons[0]
  for (let index = 1; index < polygons.length; index++) {
    accumulatedArea =
      turf.union(accumulatedArea, polygons[index]) || accumulatedArea
  }

  // Convert the checkBoundingBox into a GeoJSON Polygon
  const checkPolygon = turf.bboxPolygon(checkBoundingBox)

  if (!accumulatedArea) return false

  // Check if the accumulated area is a MultiPolygon
  if (accumulatedArea.geometry.type === 'MultiPolygon') {
    // Check each polygon within the MultiPolygon
    for (const polygon of accumulatedArea.geometry.coordinates) {
      // Create a Polygon from the current set of coordinates in the MultiPolygon
      const singlePolygon = turf.polygon(polygon)
      // Check if this polygon contains the checkPolygon
      if (turf.booleanContains(singlePolygon, checkPolygon)) {
        return true // The checkPolygon is contained within this part of the MultiPolygon
      }
    }
    return false // The checkPolygon is not contained within any part of the MultiPolygon
  } else {
    // If the accumulated area is a single Polygon, check containment directly
    return turf.booleanContains(accumulatedArea, checkPolygon)
  }
}
