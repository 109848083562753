import { css, styled } from 'styled-components'

import { onHover } from '../../core/lib/styleHelpers'

import { Button } from './Button'

export const ButtonSecondary = styled(Button)`
  background-color: ${({ theme }) =>
    theme.components.buttons.secondary['default-bg']};
  color: ${({ theme }) => theme.components.buttons.secondary['default-txt']};

  ${onHover(css`
    background-color: ${({ theme }) =>
      theme.components.buttons.secondary['hover-bg']};
    color: ${({ theme }) => theme.components.buttons.secondary['hover-text']};
  `)}

  &:disabled {
    background-color: ${({ theme }) =>
      theme.components.buttons.secondary['disabled-bg']};
    color: ${({ theme }) => theme.components.buttons.secondary['disabled-txt']};
  }
`
