import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodyMediumSemiBoldCss, H4 } from '../../../../components/typography'
import { useIntercom } from '../../../../intercom/hooks/useIntercom'
import { useMember } from '../../../../member/hooks/useMember'
import { useHemsFlow } from '../hooks/useHemsFlow'

export const TroubleShootQuestionStep = () => {
  // -- Hooks --
  const { currentMember } = useMember()
  const { t } = useTranslation()
  const { flowNavigation } = useHemsFlow()
  const { show } = useIntercom()

  const usesWifi = currentMember.scoptDongleConnectionMethod === 'Wifi'

  // -- Handlers --
  const handleHelp = () => {
    flowNavigation.close()
    show()
  }

  // -- Render --
  return (
    <StContainer>
      <StTitle>
        {usesWifi
          ? t('employee.hems.dongle-onboarding.troubleshoot.wifi.title')
          : t('employee.hems.dongle-onboarding.troubleshoot.utp.title')}
      </StTitle>
      <StOptionsContainer>
        <StOption onClick={() => flowNavigation.next()}>
          {usesWifi
            ? t('employee.hems.dongle-onboarding.troubleshoot.wifi.provision')
            : t('employee.hems.dongle-onboarding.troubleshoot.utp.provision')}
        </StOption>
        <StOption onClick={handleHelp}>
          {usesWifi
            ? t('employee.hems.dongle-onboarding.troubleshoot.wifi.get-help')
            : t('employee.hems.dongle-onboarding.troubleshoot.utp.get-help')}
        </StOption>
      </StOptionsContainer>
    </StContainer>
  )
}

const StContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StTitle = styled(H4)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space7};
`

const StOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StOption = styled.button`
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space8} ${theme.UI.SpacingPx.Space4}`};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  border: 1px solid
    ${({ theme }) => theme.components['card-select']['default-border']};
  background-color: ${({ theme }) =>
    theme.components['card-select']['default-bg']};

  ${BodyMediumSemiBoldCss}
`
