import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../button/ButtonPrimary'
import { ButtonSecondary } from '../button/ButtonSecondary'
import { H5 } from '../typography'

type FormCardHeaderProps = {
  editable: boolean
  title?: string
  disableEdit?: boolean
  disableSubmit?: boolean
  disableCancel?: boolean
  handleSetEditable: () => void
  handleClose: () => void
}

export const FormCardHeader = ({
  title,
  editable,
  disableEdit,
  disableSubmit,
  disableCancel,
  handleSetEditable,
  handleClose,
}: FormCardHeaderProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Render --
  return (
    <StHeaderContainer>
      <H5>{title}</H5>
      {!disableEdit &&
        (editable ? (
          <StButtonContainer>
            <ButtonPrimary
              compact
              type="submit"
              icon={['fasr', 'floppy-disk']}
              iconAlignment="left"
              disabled={disableSubmit}
              size="sm"
            >
              {t('profile.personal-data.save')}
            </ButtonPrimary>
            <ButtonPrimary
              compact
              type="button"
              onClick={handleClose}
              icon={['fasr', 'xmark-large']}
              iconAlignment="left"
              disabled={disableCancel}
              size="sm"
            >
              {t('profile.personal-data.cancel')}
            </ButtonPrimary>
          </StButtonContainer>
        ) : (
          <ButtonSecondary
            type="button"
            compact
            onClick={handleSetEditable}
            icon={['fasr', 'pen']}
            iconAlignment="left"
            size="sm"
          >
            {t('profile.personal-data.edit')}
          </ButtonSecondary>
        ))}
    </StHeaderContainer>
  )
}

const StButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  h5 {
    margin: 0;
  }

  @media ${breakpoints.desktop} {
    grid-column: span 2;
  }
`
