import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as RadixCheckbox from '@radix-ui/react-checkbox'
import { styled } from 'styled-components'

import { BodyMediumRegularCss } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ReactNode } from 'react'

type CheckboxProps = {
  name: string
  icon?: IconProp
  label?: ReactNode
  checked: boolean
  onChange: (checked: boolean) => void
  readOnly?: boolean
  disabled?: boolean
}

export const Checkbox = ({
  name,
  label,
  icon = ['fasr', 'check'],
  checked,
  onChange,
  readOnly,
  disabled,
}: CheckboxProps) => {
  return (
    <StContainer>
      <StCheckBox
        checked={checked}
        onCheckedChange={onChange}
        id={name}
        name={name}
        disabled={disabled || readOnly}
      >
        <RadixCheckbox.Indicator>
          <FontAwesomeIcon icon={icon} />
        </RadixCheckbox.Indicator>
      </StCheckBox>
      <StLabel htmlFor={name}>{label}</StLabel>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space3} 0;
`

const StCheckBox = styled(RadixCheckbox.Root)<{ $readOnly?: boolean }>`
  background-color: ${({ theme }) => theme.theme.colors.white};
  border: 2px solid ${({ theme }) => theme.components.checkbox.default};
  width: 24px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 0;

  flex-shrink: 0;
  flex-grow: 0;

  &[data-state='checked'] {
    border: 2px solid ${({ theme }) => theme.components.checkbox.active};
    background-color: ${({ theme }) => theme.components.checkbox.active};
  }

  &[data-disabled] {
    border: 2px solid
      ${({ theme }) => theme.components.buttons.primary['disabled-text']};

    &[data-state='checked'] {
      border: none;
      background-color: ${({ theme }) => theme.components.checkbox.default};
    }
  }
`

const StLabel = styled.label`
  ${BodyMediumRegularCss}
  cursor: pointer;
`
