import type { CountryData } from 'react-international-phone'

// Country model:
// [
//    Country name,
//    iso2 code,
//    International dial code,
//    Format (if available),
//    Order priority (if >1 country with same dial code),
//    Area codes (if >1 country with same dial code)
// ]

export const dutchCountries: CountryData[] = [
  ['Afghanistan', 'af', '93'],
  ['Albanië', 'al', '355'],
  ['Algerije', 'dz', '213'],
  ['Andorra', 'ad', '376'],
  ['Angola', 'ao', '244'],
  ['Antigua en Barbuda', 'ag', '1268'],
  ['Argentinië', 'ar', '54', '(..) ........', 0],
  ['Armenië', 'am', '374', '.. ......'],
  ['Aruba', 'aw', '297'],
  [
    'Australië',
    'au',
    '61',
    {
      default: '. .... ....',
      '/^4/': '... ... ...',
      '/^5(?!50)/': '... ... ...',
      '/^1(3|8)00/': '.... ... ...',
      '/^13/': '.. .. ..',
      '/^180/': '... ....',
    },
    0,
    [],
  ],
  ['Oostenrijk', 'at', '43'],
  ['Azerbeidzjan', 'az', '994', '(..) ... .. ..'],
  ["Bahama's", 'bs', '1242'],
  ['Bahrein', 'bh', '973'],
  ['Bangladesh', 'bd', '880'],
  ['Barbados', 'bb', '1246'],
  ['Wit-Rusland', 'by', '375', '(..) ... .. ..'],
  ['België', 'be', '32', '... .. .. ..'],
  ['Belize', 'bz', '501'],
  ['Benin', 'bj', '229'],
  ['Bhutan', 'bt', '975'],
  ['Bolivië', 'bo', '591'],
  ['Bosnië en Herzegovina', 'ba', '387'],
  ['Botswana', 'bw', '267'],
  ['Brazilië', 'br', '55', '(..) .........'],
  ['Brits Indische Oceaanterritorium', 'io', '246'],
  ['Brunei', 'bn', '673'],
  ['Bulgarije', 'bg', '359'],
  ['Burkina Faso', 'bf', '226'],
  ['Burundi', 'bi', '257'],
  ['Cambodja', 'kh', '855'],
  ['Kameroen', 'cm', '237'],
  [
    'Canada',
    'ca',
    '1',
    '(...) ...-....',
    1,
    [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  ],
  ['Kaapverdië', 'cv', '238'],
  ['Caribisch Nederland', 'bq', '599', '', 1],
  ['Kaaimaneilanden', 'ky', '1', '... ... ....', 4, ['345']],
  ['Centraal-Afrikaanse Republiek', 'cf', '236'],
  ['Tsjaad', 'td', '235'],
  ['Chili', 'cl', '56'],
  ['China', 'cn', '86', '... .... ....'],
  ['Colombia', 'co', '57', '... ... ....'],
  ['Comoren', 'km', '269'],
  ['Congo (DRC)', 'cd', '243'],
  ['Congo (Brazaville)', 'cg', '242'],
  ['Costa Rica', 'cr', '506', '....-....'],
  ['Ivoorkust', 'ci', '225', '.. .. .. .. ..'],
  ['Kroatië', 'hr', '385'],
  ['Cuba', 'cu', '53'],
  ['Curaçao', 'cw', '599', '', 0],
  ['Cyprus', 'cy', '357', '.. ......'],
  ['Tsjechië', 'cz', '420', '... ... ...'],
  ['Denemarken', 'dk', '45', '.. .. .. ..'],
  ['Djibouti', 'dj', '253'],
  ['Dominica', 'dm', '1767'],
  [
    'Dominicaanse Republiek',
    'do',
    '1',
    '(...) ...-....',
    2,
    ['809', '829', '849'],
  ],
  ['Ecuador', 'ec', '593'],
  ['Egypte', 'eg', '20'],
  ['El Salvador', 'sv', '503', '....-....'],
  ['Equatoriaal-Guinea', 'gq', '240'],
  ['Eritrea', 'er', '291'],
  ['Estland', 'ee', '372', '.... ......'],
  ['Ethiopië', 'et', '251'],
  ['Fiji', 'fj', '679'],
  ['Finland', 'fi', '358', '.. ... .. ..'],
  ['Frankrijk', 'fr', '33', '. .. .. .. ..'],
  ['Frans-Guyana', 'gf', '594'],
  ['Frans-Polynesië', 'pf', '689'],
  ['Gabon', 'ga', '241'],
  ['Gambia', 'gm', '220'],
  ['Georgië', 'ge', '995'],
  ['Duitsland', 'de', '49', '... .........'],
  ['Ghana', 'gh', '233'],
  ['Griekenland', 'gr', '30'],
  ['Groenland', 'gl', '299', '.. .. ..'],
  ['Grenada', 'gd', '1473'],
  ['Guadeloupe', 'gp', '590', '', 0],
  ['Guam', 'gu', '1671'],
  ['Guatemala', 'gt', '502', '....-....'],
  ['Guinee', 'gn', '224'],
  ['Guinee-Bissau', 'gw', '245'],
  ['Guyana', 'gy', '592'],
  ['Haïti', 'ht', '509', '....-....'],
  ['Honduras', 'hn', '504'],
  ['Hongkong', 'hk', '852', '.... ....'],
  ['Hongarije', 'hu', '36'],
  ['IJsland', 'is', '354', '... ....'],
  ['India', 'in', '91', '.....-.....'],
  ['Indonesië', 'id', '62'],
  ['Iran', 'ir', '98', '... ... ....'],
  ['Irak', 'iq', '964'],
  ['Ierland', 'ie', '353', '.. .......'],
  ['Israël', 'il', '972', '... ... ....'],
  ['Italië', 'it', '39', '... .......', 0],
  ['Jamaica', 'jm', '1876'],
  ['Japan', 'jp', '81', '.. .... ....'],
  ['Jordanië', 'jo', '962'],
  ['Kazachstan', 'kz', '7', '... ...-..-..', 0],
  ['Kenia', 'ke', '254'],
  ['Kiribati', 'ki', '686'],
  ['Kosovo', 'xk', '383'],
  ['Koeweit', 'kw', '965'],
  ['Kirgizië', 'kg', '996', '... ... ...'],
  ['Laos', 'la', '856'],
  ['Letland', 'lv', '371', '.. ... ...'],
  ['Libanon', 'lb', '961'],
  ['Lesotho', 'ls', '266'],
  ['Liberia', 'lr', '231'],
  ['Libië', 'ly', '218'],
  ['Liechtenstein', 'li', '423'],
  ['Litouwen', 'lt', '370'],
  ['Luxemburg', 'lu', '352'],
  ['Macau', 'mo', '853'],
  ['Noord-Macedonië', 'mk', '389'],
  ['Madagaskar', 'mg', '261'],
  ['Malawi', 'mw', '265'],
  ['Maleisië', 'my', '60', '..-....-....'],
  ['Maldiven', 'mv', '960'],
  ['Mali', 'ml', '223'],
  ['Malta', 'mt', '356'],
  ['Marshalleilanden', 'mh', '692'],
  ['Martinique', 'mq', '596'],
  ['Mauritanië', 'mr', '222'],
  ['Mauritius', 'mu', '230'],
  ['Mexico', 'mx', '52', '... ... ....', 0],
  ['Micronesië', 'fm', '691'],
  ['Moldavië', 'md', '373', '(..) ..-..-..'],
  ['Monaco', 'mc', '377'],
  ['Mongolië', 'mn', '976'],
  ['Montenegro', 'me', '382'],
  ['Marokko', 'ma', '212'],
  ['Mozambique', 'mz', '258'],
  ['Myanmar', 'mm', '95'],
  ['Namibië', 'na', '264'],
  ['Nauru', 'nr', '674'],
  ['Nepal', 'np', '977'],
  [
    'Nederland',
    'nl',
    '31',
    {
      '/^06/': '(.). .........',
      '/^6/': '. .........',
      '/^0(10|13|14|15|20|23|24|26|30|33|35|36|38|40|43|44|45|46|50|53|55|58|70|71|72|73|74|75|76|77|78|79|82|84|85|87|88|91)/':
        '(.).. ........',
      '/^(10|13|14|15|20|23|24|26|30|33|35|36|38|40|43|44|45|46|50|53|55|58|70|71|72|73|74|75|76|77|78|79|82|84|85|87|88|91)/':
        '.. ........',
      '/^0/': '(.)... .......',
      default: '... .......',
    },
  ],
  ['Nieuw-Caledonië', 'nc', '687'],
  ['Nieuw-Zeeland', 'nz', '64', '...-...-....'],
  ['Nicaragua', 'ni', '505'],
  ['Niger', 'ne', '227'],
  ['Nigeria', 'ng', '234'],
  ['Noord-Korea', 'kp', '850'],
  ['Noorwegen', 'no', '47', '... .. ...'],
  ['Oman', 'om', '968'],
  ['Pakistan', 'pk', '92', '...-.......'],
  ['Palau', 'pw', '680'],
  ['Palestina', 'ps', '970'],
  ['Panama', 'pa', '507'],
  ['Papoea-Nieuw-Guinea', 'pg', '675'],
  ['Paraguay', 'py', '595'],
  ['Peru', 'pe', '51'],
  ['Filipijnen', 'ph', '63', '.... .......'],
  ['Polen', 'pl', '48', '...-...-...'],
  ['Portugal', 'pt', '351'],
  ['Puerto Rico', 'pr', '1', '(...) ...-....', 3, ['787', '939']],
  ['Qatar', 'qa', '974'],
  ['Réunion', 're', '262'],
  ['Roemenië', 'ro', '40'],
  ['Rusland', 'ru', '7', '(...) ...-..-..', 1],
  ['Rwanda', 'rw', '250'],
  ['Saint Kitts en Nevis', 'kn', '1869'],
  ['Saint Lucia', 'lc', '1758'],
  ['Saint Vincent en de Grenadines', 'vc', '1784'],
  ['Samoa', 'ws', '685'],
  ['San Marino', 'sm', '378'],
  ['Sao Tomé en Principe', 'st', '239'],
  ['Saoedi-Arabië', 'sa', '966'],
  ['Senegal', 'sn', '221'],
  ['Servië', 'rs', '381'],
  ['Seychellen', 'sc', '248'],
  ['Sierra Leone', 'sl', '232'],
  ['Singapore', 'sg', '65', '....-....'],
  ['Slowakije', 'sk', '421'],
  ['Slovenië', 'si', '386'],
  ['Salomonseilanden', 'sb', '677'],
  ['Somalië', 'so', '252'],
  ['Zuid-Afrika', 'za', '27'],
  ['Zuid-Korea', 'kr', '82', '... .... ....'],
  ['Zuid-Soedan', 'ss', '211'],
  ['Spanje', 'es', '34', '... ... ...'],
  ['Sri Lanka', 'lk', '94'],
  ['Soedan', 'sd', '249'],
  ['Suriname', 'sr', '597'],
  ['Swaziland', 'sz', '268'],
  ['Zweden', 'se', '46', '... ... ...'],
  ['Zwitserland', 'ch', '41', '.. ... .. ..'],
  ['Syrië', 'sy', '963'],
  ['Taiwan', 'tw', '886'],
  ['Tadzjikistan', 'tj', '992'],
  ['Tanzania', 'tz', '255'],
  ['Thailand', 'th', '66'],
  ['Oost-Timor', 'tl', '670'],
  ['Togo', 'tg', '228'],
  ['Tonga', 'to', '676'],
  ['Trinidad en Tobago', 'tt', '1868'],
  ['Tunesië', 'tn', '216'],
  ['Turkije', 'tr', '90', '... ... .. ..'],
  ['Turkmenistan', 'tm', '993'],
  ['Tuvalu', 'tv', '688'],
  ['Oeganda', 'ug', '256'],
  ['Oekraïne', 'ua', '380', '(..) ... .. ..'],
  ['Verenigde Arabische Emiraten', 'ae', '971'],
  ['Verenigd Koninkrijk', 'gb', '44', '.... ......'],
  ['Verenigde Staten', 'us', '1', '(...) ...-....', 0],
  ['Uruguay', 'uy', '598'],
  ['Oezbekistan', 'uz', '998', '.. ... .. ..'],
  ['Vanuatu', 'vu', '678'],
  ['Vaticaanstad', 'va', '39', '.. .... ....', 1],
  ['Venezuela', 've', '58'],
  ['Vietnam', 'vn', '84'],
  ['Jemen', 'ye', '967'],
  ['Zambia', 'zm', '260'],
  ['Zimbabwe', 'zw', '263'],
]
