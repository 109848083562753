export const theme = {
  theme: {
    border: {
      black: '#000000',
      green: '#007354',
      red: '#b50c00',
    },
    colors: {
      black: '#000000',
      error: '#b14037',
      'nonary-0': '#292c31',
      'nonary-1': '#545960',
      'nonary-10': '#f4f6f9',
      'nonary-2': '#696d75',
      'nonary-3': '#80848c',
      'nonary-4': '#9b9fa5',
      'nonary-5': '#b0b4ba',
      'nonary-6': '#c2c5ca',
      'nonary-7': '#d3d6db',
      'nonary-8': '#e5e8ed',
      'nonary-9': '#eef0f4',
      octonary: '#262626',
      'primary-0': '#c6e500',
      'primary-1': '#dcf730',
      'primary-2': '#ebf3b5',
      'primary-3': '#f8fdd5',
      'quaternary-1': '#29724a',
      'quaternary-2': '#3ea46c',
      'quaternary-3': '#83bf9e',
      'quaternary-4': '#beddcc',
      'quaternary-5': '#dbede3',
      'quaternary-6': '#eff9f4',
      'quinary-1': '#253954',
      'quinary-2': '#68778a',
      'quinary-3': '#c5cbd3',
      'quinary-4': '#ebeef2',
      'secondary-0': '#0c1b1e',
      'secondary-1': '#12292f',
      'secondary-2': '#3e5358',
      'secondary-3': '#748488',
      'secondary-4': '#ccd3d4',
      'secondary-5': '#e8eae9',
      'secondary-6': '#f5f7f6',
      'senary-1': '#ca6b57',
      'senary-2': '#eab9aa',
      'senary-3': '#fee9e6',
      'senary-4': '#fff3f1',
      'septenary-1': '#584d3d',
      'septenary-2': '#9d9282',
      'septenary-3': '#c3b8a9',
      'septenary-4': '#e9e3db',
      'tertiary-1': '#003d31',
      'tertiary-2': '#316057',
      'tertiary-3': '#5a7c75',
      'tertiary-4': '#92a6a2',
      'tertiary-5': '#cbd5d3',
      'tertiary-6': '#e9eeed',
      white: '#ffffff',
    },
    skeleton: {
      default: '#eef0f4',
    },
    smartcharging: {
      'departure-time': '#cbd6f3',
      'quick-charging': '#cce3dd',
      standard: '#dcf730',
      sun: '#ffecb6',
    },
    text: {
      body: {
        black: '#000000',
        'gray-dark': '#292c31',
        'gray-light': '#c2c5ca',
        'gray-lighter': '#e5e8ed',
        'gray-mid': '#545960',
        white: '#ffffff',
      },
      heading: {
        H1: '#000000',
        H2: '#000000',
        H3: '#000000',
        H4: '#000000',
        H5: '#000000',
        H6: '#000000',
      },
    },
  },
  typography: {
    'body-large': {
      Medium: {
        'font-family': 'Inter',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '150%',
        'font-weight': '500',
      },
      Regular: {
        'font-family': 'Inter',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '160%',
        'font-weight': '400',
      },
      SemiBold: {
        'font-family': 'Inter',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '140%',
        'font-weight': '600',
      },
    },
    'body-medium': {
      Medium: {
        'font-family': 'Inter',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '140%',
        'font-weight': '500',
      },
      Regular: {
        'font-family': 'Inter',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '150%',
        'font-weight': '400',
      },
      SemiBold: {
        'font-family': 'Inter',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '150%',
        'font-weight': '600',
      },
    },
    'body-small': {
      Medium: {
        'font-family': 'Inter',
        'font-size': '15px',
        'letter-spacing': '0px',
        'line-height': '150%',
        'font-weight': '500',
      },
      Regular: {
        'font-family': 'Inter',
        'font-size': '15px',
        'letter-spacing': '0px',
        'line-height': '150%',
        'font-weight': '400',
      },
      SemiBold: {
        'font-family': 'Inter',
        'font-size': '15px',
        'letter-spacing': '0px',
        'line-height': '150%',
        'font-weight': '600',
      },
    },
    'body-xsmall': {
      Medium: {
        'font-family': 'Inter',
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '140%',
        'font-weight': '500',
      },
      Regular: {
        'font-family': 'Inter',
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '140%',
        'font-weight': '400',
      },
      SemiBold: {
        'font-family': 'Inter',
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '150%',
        'font-weight': '600',
      },
    },
    'body-xxsmall': {
      Bold: {
        'font-family': 'Inter',
        'font-size': '12px',
        'letter-spacing': '0px',
        'line-height': '140%',
        'font-weight': '700',
      },
      Medium: {
        'font-family': 'Inter',
        'font-size': '12px',
        'letter-spacing': '0px',
        'line-height': '140%',
        'font-weight': '500',
      },
      Regular: {
        'font-family': 'Inter',
        'font-size': '12px',
        'letter-spacing': '0px',
        'line-height': '140%',
        'font-weight': '400',
      },
      SemiBold: {
        'font-family': 'Inter',
        'font-size': '12px',
        'letter-spacing': '0px',
        'line-height': '140%',
        'font-weight': '600',
      },
    },
    email: {
      body: {
        'font-family': 'Inter',
        'font-size': '15px',
        'letter-spacing': '0px',
        'line-height': '22px',
        'font-weight': '400',
      },
      H1: {
        'font-family': 'F37 Jagger',
        'font-size': '38px',
        'letter-spacing': '0px',
        'line-height': '120%',
        'font-weight': '700',
      },
      H2: {
        'font-family': 'F37 Jagger',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '125%',
        'font-weight': '700',
      },
      H3: {
        'font-family': 'F37 Jagger',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '28px',
        'font-weight': '700',
      },
    },
    heading: {
      H2: {
        'font-family': 'F37 Jagger',
        'font-size': '50px',
        'letter-spacing': '0px',
        'line-height': '120%',
        'font-weight': '700',
      },
      H3: {
        'font-family': 'F37 Jagger',
        'font-size': '34px',
        'letter-spacing': '0px',
        'line-height': '120%',
        'font-weight': '700',
      },
      H4: {
        'font-family': 'F37 Jagger',
        'font-size': '26px',
        'letter-spacing': '0px',
        'line-height': '120%',
        'font-weight': '700',
      },
      H5: {
        'font-family': 'F37 Jagger',
        'font-size': '22px',
        'letter-spacing': '0px',
        'line-height': '140%',
        'font-weight': '700',
      },
      H6: {
        'font-family': 'F37 Jagger',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '120%',
        'font-weight': '700',
      },
      H7: {
        'font-family': 'F37 Jagger',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '150%',
        'font-weight': '700',
      },
    },
    icon: {
      large: {
        'font-family': 'Font Awesome 6 Sharp',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '26.4px',
        'font-weight': '400',
      },
      medium: {
        'font-family': 'Font Awesome 6 Sharp',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '26.4px',
        'font-weight': '400',
      },
      small: {
        'font-family': 'Font Awesome 6 Sharp',
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '26.4px',
        'font-weight': '400',
      },
      'small-fill': {
        'font-family': 'Font Awesome 6 Sharp',
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '26.4px',
        'font-weight': '400',
      },
      xlarge: {
        'font-family': 'Font Awesome 6 Sharp',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '38.6px',
        'font-weight': '400',
      },
      xxlarge: {
        'font-family': 'Font Awesome 6 Sharp',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '100%',
        'font-weight': '300',
      },
    },
  },
  shadows: {
    website: {
      image1:
        '13.302204132080078px 13.302204132080078px 26.604408264160156px 0px rgba(150, 150, 150, 0.1)',
    },
    'bottom-nav-desktop': '0.0px -4.0px 24.0px 0px rgba(76, 79, 86, 0.06)',
    'bottom-nav-mobile': '0.0px -4.0px 6.0px 0px rgba(0, 0, 0, 0.03)',
    'bottom-sheet': '0.0px 2.0px 20.0px 0px rgba(5, 28, 56, 0.08)',
    'card-1': '1.0px 2.0px 8.0px 0px rgba(0, 0, 0, 0.06)',
    'card-2-off': '0.0px 1.0px 4.0px 0px rgba(16, 24, 40, 0)',
    'dashboard-card':
      '0.0px 1.0px 5.0px 0px rgba(0, 0, 0, 0.1),0.0px 1.0px 2.0px -1.0px rgba(0, 0, 0, 0.03)',
    dropdown: '10.0px 10.0px 20.0px 0px rgba(102, 102, 102, 0.19)',
    'dropdown-desktop':
      '3.0px 2.0px 8.0px 0px rgba(0, 0, 0, 0.1),10.0px 10.0px 14.0px 0px rgba(0, 0, 0, 0.09),23.0px 22.0px 19.0px 0px rgba(0, 0, 0, 0.05),41.0px 39.0px 23.0px 0px rgba(0, 0, 0, 0.01),64.0px 60.0px 25.0px 0px rgba(0, 0, 0, 0)',
    'intercom-outer': '5.0px 14.0px 40.0px 0px rgba(26, 26, 26, 0.18)',
    menu: '0.0px 10.0px 40.0px 0px rgba(0, 0, 0, 0.08)',
    'shadow-menu': '0.0px 0.0px 70.0px 0px rgba(0, 0, 0, 0.05)',
    'shadow-widget':
      '5.0px 14.0px 80.0px 0px rgba(26, 26, 26, 0.12),10.0px 10.0px 40.0px 0px rgba(0, 0, 0, 0.08)',
  },
  components: {
    buttons: {
      neon: {
        'default-bg': '#dcf730',
        'default-text': '#12292f',
        'disabled-bg': '#f2f8ce',
        'disabled-text': '#b2b8ba',
        'hover-bg': '#c2dd13',
        'hover-text': '#12292f',
      },
      primary: {
        'default-bg': '#12292f',
        'default-text': '#dcf730',
        'disabled-bg': '#9ea6ae',
        'disabled-text': '#d1d9e0',
        'hover-bg': '#293f45',
      },
      quaternary: {
        'default-stroke': '#6a6a6a',
        'default-txt': '#6a6a6a',
        'hover-stroke': '#000000',
      },
      secondary: {
        'default-bg': '#3e5357',
        'default-txt': '#ffffff',
        'disabled-bg': '#9ea6ae',
        'disabled-txt': '#d1d9e0',
        'hover-bg': '#464e56',
        'hover-text': '#ffffff',
      },
      tertiary: {
        'default-border': '#d3d6db',
        'default-txt': '#000000',
        'disabled-stroke': '#e1e1e1',
        'disabled-txt': '#bebebe',
        'hover-border': '#424242',
        'hover-txt': '#000000',
      },
    },
    'card-select': {
      'active-bg': '#f8fdd5',
      'default-bg': '#ffffff',
      'default-border': '#d8d8d8',
      'hover-border': '#c6e500',
      icon: '#151515',
      'icon-bg':
        'linear-gradient(150.28deg, rgba(255, 248, 226, 1.0) 0%, rgba(255, 238, 191, 1.0) 100%)',
      text: '#000000',
    },
    checkbox: {
      active: '#c6e500',
      default: '#cfd3da',
      icon: '#ffffff',
      'icon-dark': '#000000',
    },
    chip: {
      primary: {
        'active-bg': '#dcf730',
        'active-text': '#000000',
        'default-bg': '#eef0f4',
        'default-text': '#000000',
        'success-bg': '#29724a',
        'success-text': '#ffffff',
      },
      secondary: {
        'active-bg': '#e9e9e9',
        'active-border': '#c2c2c2',
        'active-text': '#616161',
        'default-border': '#c2c2c2',
        'default-text': '#616161',
      },
    },
    input: {
      bg: '#ffffff',
      border: '#d3d6db',
      'disabled-bg': '#eef0f4',
      'disabled-text': '#858a95',
      error: '#b50c00',
      'focus-border': '#c6e500',
      'info-text': '#868686',
      'input-text': '#000000',
      'label-text': '#000000',
      'placeholder-text': '#9c9fa5',
      success: '#477b71',
    },
    intercom: {
      'company-bg': '#f8f8f8',
      'company-text': '#000000',
      'header-bg': '#000000',
      'reply-icons': '#8f9195',
      'reply-text': '#8f9195',
      'user-bg': '#e5e5e5',
      'user-text': '#000000',
    },
    menu: {
      default: {
        bg: '#ffffff',
        border: '#ebeef3',
        'border-select': '#dcf730',
        'gray-txt': '#6a6a6a',
        'item-icon': '#000000',
        'item-text': '#000000',
      },
      wizard: {
        bg: '#ffffff',
        border: '#ebeef3',
        'item-icon': '#6a6a6a',
        'item-text': '#6a6a6a',
      },
    },
    'modal-centered': {
      'body-text': '#000000',
      'title-text': '#000000',
    },
    navcircle: {
      'active-bg': '#dcf730',
      'active-text': '#000000',
      'check-text': '#ffffff',
      'completed-bg': '#477b71',
      'mobile-bg': '#e7eaef',
      'notactive-bg': '#ffffff',
    },
    navwizard: {
      'active-text': '#000000',
      bg: '#f1f3f6',
      chevron: '#6a6a6a',
      'default-text': '#6a6a6a',
    },
    'shop-detail': {
      'thumb-active': '#ffc421',
      'thumb-hover': '#000000',
    },
    'shop-item': {
      'features-border': '#d0d0d0',
      'price-text': '#000000',
      'review-text': '#000000',
      stars: '#c6e500',
      title: '#000000',
    },
    situatie: {
      background: '#effaa6',
      'default-icon': '#000000',
      'default-text': '#000000',
      'disabled-icon': '#bfc885',
      'disabled-text': '#bfc885',
    },
    status: {
      blue: '#cbd6f3',
      brown: '#ead7c9',
      'gray-dark': '#e3e2e0',
      'gray-light': '#f2f2f2',
      green: '#cce3dd',
      orange: '#ffdebf',
      pink: '#f3cbf3',
      red: '#ffd6cf',
      text: '#000000',
      yellow: '#ffecb6',
    },
    toaster: {
      'action-bg': '#ffffff',
      'body-text': '#ffffff',
      icon: '#ffffff',
    },
    toggle: {
      handle: '#ffffff',
      'off-bg': '#cfd3da',
      'on-bg': '#c6e500',
    },
  },
}

export type GeneratedTheme = typeof theme
