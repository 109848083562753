// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/named
import { isAxiosError } from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { merge } from 'ts-deepmerge'

import { useAuthentication } from '../../authentication/hooks/useAuthentication'
import { axios } from '../lib/axios'
import { configToObject } from '../lib/axiosHelpers'

import type { Options, RefetchFunction, UseAxiosResult } from './types'
import type { AxiosError, AxiosResponse } from 'axios'
import type { ConfigWithCache } from 'axios-cache-interceptor'

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

const abortControllers = new Map<string, AbortController>()

export const useAuthAxios = <TResponse = any, TBody = any, TError = any>(
  config: Optional<ConfigWithCache<TBody>, 'cache'> | string,
  options?: Options
): UseAxiosResult<TResponse, TBody, TError> => {
  const configMemo = useMemo(() => config, [JSON.stringify(config)])

  const [loading, setLoading] = useState(!options?.manual)
  const [error, setError] = useState<AxiosError<TError, TBody>>()
  const [data, setData] = useState<TResponse>()
  const [response, setResponse] = useState<AxiosResponse>()

  const { i18n } = useTranslation()

  const { getAccessTokenSilently, logout } = useAuthentication()

  const request: RefetchFunction<any, any> = async (refetchConfig) => {
    setLoading(true)
    setError(undefined)

    try {
      let accessToken: string
      try {
        accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          },
        })
      } catch (error) {
        // If the user is not authenticated, log them out
        logout()
        throw error
      }

      const configWithCache = configToObject(config)

      if (abortControllers.has(configWithCache.url)) {
        abortControllers.get(configWithCache.url)?.abort()
      }

      const newAbortController = new AbortController()
      const abortControllerConfig = {
        signal: newAbortController.signal,
      }

      abortControllers.set(configWithCache.url, newAbortController)

      // Make caching opt-in
      if (!options?.cache) {
        configWithCache.cache = false
      }

      const combinedConfig = merge(
        configWithCache,
        configToObject(refetchConfig),
        abortControllerConfig
      )

      const axiosResponse = await axios({
        ...combinedConfig,
        headers: {
          ...combinedConfig.headers,
          Authorization: `Bearer ${accessToken}`,
          'x-user-lang': i18n.language,
          // Header to bypass localTunnel security
          'Bypass-Tunnel-Reminder': 'Emiel is cool',
        },
      })

      setData(axiosResponse.data)
      setResponse(axiosResponse)
      setLoading(false)

      return axiosResponse
    } catch (error) {
      // Ignore aborted requests
      if ((error as AxiosError).code === 'ERR_CANCELED') {
        throw error
      }

      if (isAxiosError(error)) {
        setError(error)
      }
      setLoading(false)

      throw error
    }
  }

  useEffect(() => {
    if (!options?.manual) {
      request()
    }
  }, [configMemo])

  return [{ data, error: error, loading, response }, request]
}
