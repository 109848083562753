// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import enLocale from 'date-fns/locale/en-US'

import { formatDecimals } from '../../core/utils/number'
import { ColumnType } from '../components/DataTable'

import { applyDotNotation } from './applyDotNotation'

import type { BaseRow, Column } from '../components/DataTable'
import type { Locale } from 'date-fns'
import type { ReactNode } from 'react'

export const columnValue = <T extends BaseRow>(
  column: Column<T>,
  dataRow: T,
  dateFnsLocale: { locale: Locale }
): string | ReactNode => {
  const value = column.transformData
    ? column.transformData(dataRow)
    : applyDotNotation(dataRow, column.key)

  if (column.type === ColumnType.DATE) {
    return format(
      new Date(value as string),
      'dd MMMM yyyy',
      dateFnsLocale ?? { locale: enLocale }
    )
  }

  if (column.type === ColumnType.NUMBER && column.decimal) {
    return formatDecimals(Number.parseFloat(value as string), column.decimal)
  }

  return value !== undefined && value !== null && value !== '' ? value : '–'
}
