import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useDebounce, useOnClickOutside } from 'usehooks-ts'
// eslint-disable-next-line import/named
import { v4 as uuidv4 } from 'uuid'

import { useAxios } from '../../../api/hooks/useAxios'
import { euCountryCodes } from '../../../core/consts/countries'
import { BodyLargeSemiBoldCss, BodyMediumRegularCss } from '../../typography'
import { StError } from '../Input'

import { AddressSelect } from './AddressSelect'

import type {
  MapboxAddress,
  MapboxSuggestion,
  SuggestionResponse,
} from './types'
import type { ChangeEvent } from 'react'

// -- Vars --
const SESSION_TOKEN = uuidv4()

// -- Type --
type AddressSearchProps = {
  displayValue?: string
  onSelect: (address: MapboxAddress | null) => void
  onChange?: (event: ChangeEvent) => void
  onBlur?: () => void
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  sharp?: boolean
  showIcon?: boolean
  clearable?: boolean
  types?: (
    | 'country'
    | 'region'
    | 'postcode'
    | 'district'
    | 'place'
    | 'city'
    | 'locality'
    | 'neighborhood'
    | 'street'
    | 'address'
  )[]
  location?: { longitude: number; latitude: number }
  error?: string
  touched?: boolean
}

export const AddressSearch = ({
  displayValue = '',
  onSelect,
  onChange,
  onBlur,
  placeholder,
  types = [],
  location,
  disabled,
  sharp = true,
  label,
  name,
  showIcon,
  clearable,
  touched,
  error,
}: AddressSearchProps) => {
  // -- State --
  const [searchValue, setSearchValue] = useState(displayValue)
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const [open, setOpen] = useState(false)
  const [suggestions, setSuggestions] = useState<MapboxSuggestion[]>([])
  const ref = useRef(null)
  const inputRef = useRef<HTMLInputElement>(null)

  // -- Hooks --
  const { i18n } = useTranslation()
  useOnClickOutside(ref, () => setOpen(false))

  // -- Search --
  const [{ data }, execute] = useAxios<SuggestionResponse>(
    {
      url: 'https://api.mapbox.com/search/searchbox/v1/suggest',
      params: {
        access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
        session_token: SESSION_TOKEN,
        q: searchValue,
        language: i18n.language,
        types: types.length > 0 ? types.join(', ') : undefined,
        country: euCountryCodes.join(','),
        proximity: location
          ? `${location.longitude},${location.latitude}`
          : undefined,
      },
    },
    { manual: true }
  )

  const [, getAddressDetail] = useAxios<MapboxAddress>(
    {
      params: {
        access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
        session_token: SESSION_TOKEN,
        language: i18n.language,
      },
    },
    { manual: true }
  )

  // -- Effects --
  useEffect(() => {
    if (searchValue.length > 2) {
      execute()
    }
  }, [debouncedSearchValue])

  useEffect(() => {
    if (data?.suggestions) {
      setSuggestions(data.suggestions)
    }
  }, [data])

  useEffect(() => {
    setSearchValue(displayValue)
  }, [displayValue])

  // -- Handlers --
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange ? onChange(event) : null
    setSearchValue(event.target.value)

    if (displayValue) {
      onBlur?.()
      onSelect(null)
    }
  }

  const handleClear = () => {
    setSearchValue('')
    setSuggestions([])
    setOpen(false)
    onBlur?.()
  }

  const handleSelectAddress = async (address: MapboxSuggestion) => {
    setOpen(false)

    const AddressDetailResponse = await getAddressDetail(
      `https://api.mapbox.com/search/searchbox/v1/retrieve/${address.mapbox_id}`
    )

    onBlur?.()
    onSelect(AddressDetailResponse.data)
  }

  const handleClickInput = () => {
    setOpen(true)
  }

  return (
    <StContainer ref={ref}>
      {label ? <StLabel htmlFor={name}>{label}</StLabel> : null}
      <StInputFieldContainer
        $disabled={disabled}
        $sharp={sharp}
        $error={touched && !!error}
        $touched={touched}
      >
        {showIcon ? <StSearchIcon icon={['fasr', 'search']} /> : null}
        <StInput
          ref={inputRef}
          id={name}
          value={searchValue}
          onChange={(event) => handleChange(event)}
          placeholder={placeholder}
          type="text"
          onFocus={handleClickInput}
          disabled={disabled}
        />
        {clearable && searchValue ? (
          <StClearButton onClick={handleClear}>
            <FontAwesomeIcon icon={['fasr', 'xmark-large']} />
          </StClearButton>
        ) : null}
        {touched && !disabled && (
          <StStatusIcon
            icon={['fasr', error ? 'triangle-exclamation' : 'check']}
            $error={!!error}
          />
        )}
      </StInputFieldContainer>

      {touched && error ? <StError>{error}</StError> : null}

      {open && suggestions.length > 0 ? (
        <StSuggestionsContainer $hasLabel={!!label}>
          {suggestions.map((address) => (
            <AddressSelect
              key={address.mapbox_id}
              address={address}
              onClick={() => {
                handleSelectAddress(address)
              }}
            />
          ))}
        </StSuggestionsContainer>
      ) : null}
    </StContainer>
  )
}

const StContainer = styled.div`
  position: relative;
  height: min-content;
  width: 100%;
`

const StLabel = styled.label`
  ${BodyLargeSemiBoldCss}
  display: block;

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StInputFieldContainer = styled.div<{
  $disabled?: boolean
  $sharp?: boolean
  $touched?: boolean
  $error?: boolean
}>`
  height: ${({ theme }) => theme.UI.SpacingPx.Space13};
  display: flex;
  justify-content: center;
  align-items: center;

  border: ${({ $disabled }) => ($disabled ? 'none' : '1px solid')};
  border-color: ${({ theme, $error, $touched }) =>
    $error
      ? theme.components.input.error
      : $touched
      ? theme.components.input.success
      : theme.components.input.border};

  border-radius: ${({ theme, $sharp }) =>
    $sharp ? theme.UI.SpacingPx.Space1 : theme.UI.SpacingPx.Space2};
  background-color: ${({ theme, $disabled }) =>
    $disabled
      ? theme.components.input['disabled-bg']
      : theme.components.input.bg};
  overflow: hidden;

  &:focus-within {
    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }
`

const StSearchIcon = styled(FontAwesomeIcon)`
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space5};
  color: ${({ theme }) => theme.theme.colors['nonary-2']};
`

const StInput = styled.input`
  ${BodyMediumRegularCss}

  height: 100%;
  width: 100%;

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  border: none;

  &:disabled {
    color: ${({ theme }) => theme.components.input['disabled-text']};
  }

  &::placeholder {
    color: ${({ theme }) => theme.components.input['placeholder-text']};
  }

  &:focus {
    outline: none;
  }
`

const StClearButton = styled.button`
  padding: 0;
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space5};
  background-color: transparent;
  border: none;

  cursor: pointer;

  color: ${({ theme }) => theme.theme.colors['nonary-2']};
`

const StSuggestionsContainer = styled.div<{ $hasLabel?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: ${({ $hasLabel }) => ($hasLabel ? 96 : 60)}px;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5} 0;

  background-color: ${({ theme }) => theme.theme.colors.white};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  box-shadow: ${({ theme }) => theme.shadows.menu};

  overflow-y: scroll;
  overflow-x: hidden;
`

const StStatusIcon = styled(FontAwesomeIcon)<{
  $error?: boolean
}>`
  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
  margin: auto 0;

  color: ${({ $error, theme }) =>
    $error ? theme.components.input.error : theme.components.input.success};
`
