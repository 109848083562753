import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../../components/button/FloatingButtonContainer'
import { BodySmallRegular, H4 } from '../../../../../components/typography'
import { useHemsIntroduction } from '../../hooks/useHemsIntroduction'

import type { ReactNode } from 'react'

type HemsInfoStepProps = {
  title?: string
  description?: string
  visual: ReactNode
  nextString?: string
  showCloseButton?: boolean
  finalStep?: boolean
}

export const HemsInfoStep = ({
  title,
  description,
  visual,
  nextString,
  showCloseButton = true,
}: HemsInfoStepProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { handleClose, handleNext } = useHemsIntroduction()

  // -- Render --
  return (
    <div>
      {visual}
      {(title || description) && (
        <StTextContainer>
          {title && <StTitle>{title}</StTitle>}
          {description && <BodySmallRegular>{description}</BodySmallRegular>}
        </StTextContainer>
      )}
      {isDesktop ? (
        <StFlexContainer>
          {showCloseButton && (
            <ButtonTertiary onClick={() => handleClose()} compact>
              {t('employee.hems.onboarding.close')}
            </ButtonTertiary>
          )}
          <ButtonPrimary
            onClick={async () => {
              const shouldClose = await handleNext()

              if (shouldClose) {
                handleClose()
              }
            }}
            compact
          >
            {nextString ?? t('employee.hems.onboarding.next')}
          </ButtonPrimary>
        </StFlexContainer>
      ) : (
        <FloatingButtonContainer>
          <ButtonPrimary
            onClick={async () => {
              const shouldClose = await handleNext()

              if (shouldClose) {
                handleClose()
              }
            }}
          >
            {nextString ?? t('employee.hems.onboarding.next')}
          </ButtonPrimary>
        </FloatingButtonContainer>
      )}
    </div>
  )
}

const StTextContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  /* Compensate for floating button */
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space31};

  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space7} 0;
  }
`

const StTitle = styled(H4)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  position: absolute;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  right: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
