// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const configToObject = (config: any) => {
  if (typeof config === 'string') {
    return {
      url: config,
    }
  }

  return Object.assign({}, config)
}
