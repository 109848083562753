import { Outlet } from 'react-router-dom'

import { HemsProvider } from '../../modules/employee/hems/providers/HemsProvider'

export const EmployeeSettingsWrapper = () => {
  return (
    <HemsProvider>
      <Outlet />
    </HemsProvider>
  )
}
