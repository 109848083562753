// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-duplicates */
import { format, startOfWeek } from 'date-fns'
import { enUS } from 'date-fns/locale'

import type { Locale } from 'date-fns'

export const getDayName = (
  dayNumber: number,
  config?: { locale?: Locale; format?: string }
): string => {
  // ISO Week days: 1 (Monday) to 7 (Sunday)
  if (dayNumber < 1 || dayNumber > 7) {
    throw new Error('Day number must be between 1 and 7.')
  }

  // Create a reference monday
  const referenceDate = startOfWeek(new Date('1997-07-23'), { weekStartsOn: 1 })

  // Calculate the date to get the desired day of the week
  const targetDate = new Date(referenceDate)
  targetDate.setDate(referenceDate.getDate() + dayNumber - 1)

  // Format the date to get the weekday name
  return format(targetDate, config?.format || 'eeeeee', {
    locale: config?.locale || enUS,
  })
}
