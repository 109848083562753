import { OnboardingSteps } from '../hooks/useOnboardingState'

import { TokenChargerLocationImageStep } from './token-flow/TokenChargerLocationImageStep'
import { TokenContinueInTheAppStep } from './token-flow/TokenContinueInTheAppStep'
import { TokenElectricityBoxImageStep } from './token-flow/TokenElectricityBoxImageStep'
import { TokenElectricityMeterImageStep } from './token-flow/TokenElectricityMeterImageStep'
import { TokenExtraImagesStep } from './token-flow/TokenExtraImagesStep'
import { TokenFuseBoxImageStep } from './token-flow/TokenFuseBoxImageStep'

import type { TokenFlowSteps } from '../hooks/useTokenFlowState'
import type { ReactNode } from 'react'

const tokenOnboardingStepComponents: Record<TokenFlowSteps, ReactNode> = {
  [OnboardingSteps.ElectricityBoxImage]: <TokenElectricityBoxImageStep />,
  [OnboardingSteps.FuseBoxImage]: <TokenFuseBoxImageStep />,
  [OnboardingSteps.ElectricityMeterImage]: <TokenElectricityMeterImageStep />,
  [OnboardingSteps.ChargerLocationImage]: <TokenChargerLocationImageStep />,
  [OnboardingSteps.ExtraImages]: <TokenExtraImagesStep />,
  [OnboardingSteps.ContinueInTheApp]: <TokenContinueInTheAppStep />,
}

type RenderTokenOnboardingStepProps = {
  currentStep: TokenFlowSteps
}

export const RenderTokenOnboardingStep = ({
  currentStep,
}: RenderTokenOnboardingStepProps) => {
  return tokenOnboardingStepComponents[currentStep]
}
