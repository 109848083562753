import { useContext } from 'react'

import { hemsContext } from '../providers/HemsProvider'

export const useHems = () => {
  const hems = useContext(hemsContext)

  if (!hems) {
    throw new Error('useHems must be used within a HemsProvider')
  }

  return hems
}
