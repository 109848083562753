import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import * as Yup from 'yup'

import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { Input } from '../../../../components/form/Input'
import { BodySmallRegular, H4 } from '../../../../components/typography'
import { useHemsFlow } from '../hooks/useHemsFlow'

export const WifiPasswordStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const {
    flowNavigation,
    selectedNetwork,
    setNetworkPassword,
    networkPassword,
  } = useHemsFlow()

  // -- Validation schema --
  const validationSchema = Yup.object().shape({
    password: Yup.string().required(
      t('employee.hems.dongle-onboarding.wifi-password.required')
    ),
  })

  // -- Handlers --
  const handleSubmit = (password: string) => {
    setNetworkPassword(password)
    flowNavigation.next()
  }

  // -- Render --
  return (
    <StContainer>
      <StTextContainer>
        <H4>{t('employee.hems.dongle-onboarding.wifi-password.title')}</H4>
        <BodySmallRegular>
          {t('employee.hems.dongle-onboarding.wifi-password.description', {
            networkName: selectedNetwork?.ssid,
          })}
        </BodySmallRegular>
      </StTextContainer>
      <Formik
        initialValues={{ password: networkPassword || '' }}
        onSubmit={(values) => handleSubmit(values.password)}
        validationSchema={validationSchema}
        validateOnBlur
      >
        {({ errors, values, handleBlur, touched, handleChange }) => (
          <Form>
            <Input
              name="password"
              type="password"
              autocomplete="no"
              onChange={handleChange}
              value={values.password}
              error={errors.password}
              touched={touched.password}
              onBlur={handleBlur}
              placeholder={t(
                'employee.hems.dongle-onboarding.wifi-password.placeholder'
              )}
            />
            <FloatingButtonContainer>
              <ButtonPrimary type="submit">
                {t('employee.hems.dongle-onboarding.wifi-password.submit')}
              </ButtonPrimary>
            </FloatingButtonContainer>
          </Form>
        )}
      </Formik>
    </StContainer>
  )
}

const StContainer = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StTextContainer = styled.div`
  h4 {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`
