import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import * as Yup from 'yup'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { Select } from '../../../../components/form/Select'
import { TextArea } from '../../../../components/form/TextArea'
import { ZohoImage, ZohoModule } from '../../../../components/image/ZohoImage'
import {
  BodyLargeSemiBold,
  BodyLargeSemiBoldCss,
  BodyMediumRegular,
  BodyMediumSemiBold,
  H5,
} from '../../../../components/typography'
import { FloatingContainerButton } from '../../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { StIconContainer } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

import { cableDistanceOptions } from './CableDistanceStep'
import { StBulletPoint, StExamples } from './ChargerLocationInputStep'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export const CableRouteStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const {
    handleNext,
    setLoading,
    values: onboardingValues,
    items: { chargerLocationItems },
  } = useOnboarding()

  // -- Data --
  // eslint-disable-next-line no-unused-vars
  const [_, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Vars --
  const validationSchema = Yup.object().shape({
    cableRoute: Yup.string()
      .max(750, t('onboarding.cable-route.error'))
      .optional(),
    cableLengthKey: Yup.string().required(
      t('onboarding.cable-length.input.error')
    ),
  })

  // -- Vars --
  const selectedChargerLocation = chargerLocationItems.find(
    (item) => item.key === onboardingValues.chargerLocationKey
  )

  const selectOptions = cableDistanceOptions.map((item) => ({
    key: item.key,
    label: t(item.translationKey),
  }))

  // -- Handlers --
  const handleSubmit = async ({
    cableRoute,
    cableLengthKey,
  }: {
    cableRoute: string
    cableLengthKey: string
  }) => {
    try {
      setLoading(true)

      await execute({
        data: {
          cableRoute,
          cableLengthKey,
        },
      })

      handleNext({ ...onboardingValues, cableRoute, cableLengthKey })
    } catch {
      setLoading(false)
      return console.error('Failed to save extra comments')
    }
  }

  // -- Render --
  return (
    <Stcontainer>
      <OnboardingStepTitle>
        {t('onboarding.cable-route.title')}
      </OnboardingStepTitle>

      <Formik
        initialValues={{
          cableRoute: onboardingValues.cableRoute ?? '',
          cableLengthKey: onboardingValues.cableLengthKey,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <StFormContainer>
              <TextArea
                name="cableRoute"
                label={t('onboarding.cable-route.label')}
                placeholder={t('onboarding.cable-route.placeholder')}
                value={values.cableRoute}
                error={errors.cableRoute}
                touched={touched.cableRoute}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={4}
              />
              <div>
                <StExamples>{t('onboarding.cable-route.example')}</StExamples>
                <ul>
                  <StBulletPoint>
                    {t('onboarding.cable-route.example-1')}
                  </StBulletPoint>
                  <StBulletPoint>
                    {t('onboarding.cable-route.example-2')}
                  </StBulletPoint>
                </ul>
              </div>
            </StFormContainer>

            <FloatingContainerButton
              title={t('onboarding.cable-route.submit')}
              disabled={!!errors.cableRoute || !values.cableRoute}
            />

            <StOverviewContainer>
              <StH5>{t('onboarding.cable-route.overview.title')}</StH5>
              <BodyMediumRegular>
                {t('onboarding.cable-route.overview.description')}
              </BodyMediumRegular>

              <StOverViewContent>
                <Select
                  label={t('onboarding.cable-route.overview.cable-length')}
                  onChange={(value) => setFieldValue('cableLengthKey', value)}
                  options={selectOptions}
                  value={values.cableLengthKey}
                />
                <div>
                  <BodyLargeSemiBold>
                    {t('onboarding.cable-route.overview.charger-location')}
                  </BodyLargeSemiBold>
                  <StFlexContainer>
                    {selectedChargerLocation ? (
                      <StImage>
                        <StIconContainer>
                          {selectedChargerLocation.icon && (
                            <FontAwesomeIcon
                              icon={selectedChargerLocation.icon as IconProp}
                            />
                          )}
                        </StIconContainer>
                        <StTitle>{selectedChargerLocation.name}</StTitle>
                      </StImage>
                    ) : null}
                    {onboardingValues.chargerLocationImageIds ? (
                      <StImage>
                        <ZohoImage
                          id={onboardingValues.chargerLocationImageIds[0]}
                          module={ZohoModule.File}
                        />
                      </StImage>
                    ) : null}
                  </StFlexContainer>
                </div>

                <div>
                  <BodyLargeSemiBold>
                    {t('onboarding.cable-route.overview.charger')}
                  </BodyLargeSemiBold>
                  {onboardingValues.product ? (
                    <StImage>
                      <ZohoImage
                        id={onboardingValues.product}
                        module={ZohoModule.Products}
                        objectFit="contain"
                      />
                    </StImage>
                  ) : null}
                </div>

                <div>
                  <BodyLargeSemiBold>
                    {t('onboarding.cable-route.overview.breaker-location')}
                  </BodyLargeSemiBold>
                  {onboardingValues.electricalBoxImageIds ? (
                    <StImage>
                      <ZohoImage
                        id={onboardingValues.electricalBoxImageIds[0]}
                        module={ZohoModule.File}
                      />
                    </StImage>
                  ) : null}
                </div>
              </StOverViewContent>
            </StOverviewContainer>
          </Form>
        )}
      </Formik>
    </Stcontainer>
  )
}

const Stcontainer = styled.div`
  padding-bottom: ${({ theme }) =>
    `calc(var(--sticky-button-container-height) + ${theme.UI.SpacingPx.Space6})`};
`

const StFormContainer = styled.div`
  @media ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: ${({ theme }) => theme.UI.SpacingPx.Space12};
  }
`

const StOverviewContainer = styled.div`
  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space8};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StH5 = styled(H5)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StOverViewContent = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space8};
  display: flex;
  flex-wrap: wrap;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space15};
`

const StImage = styled.div`
  max-height: 147px;
  flex: 1;
  min-width: 0;
  aspect-ratio: 1/1;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  background-color: ${({ theme }) => theme.theme.colors.white};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  @media ${breakpoints.desktop} {
    height: 147px;
  }
`

const StFlexContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StTitle = styled(BodyMediumSemiBold)`
  color: ${({ theme }) => theme.components['card-select'].text};
  text-align: center;

  @media ${breakpoints.desktop} {
    ${BodyLargeSemiBoldCss}
  }
`
