import { styled } from 'styled-components'

import { Urls } from '../../../api/urls'
import { ContentContainer } from '../../../components/page-layout'
import { SortKeys } from '../../../dataTable/components/DataTableHeader'
import {
  DataTableProvider,
  PaginationType,
} from '../../../dataTable/providers/DataTableProvider'
import { ActionRequiredDataTable } from '../components/ActionRequiredDataTable'
import { EmployerHomeStatistics } from '../components/EmployerHomeStatistics'
import { RecentUsageDataTable } from '../components/RecentUsageDataTable'

export const EmployerHomeScreen = () => {
  return (
    <ContentContainer>
      <StPageContainerContainer>
        <EmployerHomeStatistics />

        <DataTableProvider
          paginationType={PaginationType.None}
          url={Urls.fetchEmployees}
          defaultSort="hcpStatus.status"
          defaultSortDirection={SortKeys.ASCENDING}
          initialFilterValues={{
            warning: true,
          }}
          amount={5}
        >
          <ActionRequiredDataTable />
        </DataTableProvider>

        <DataTableProvider
          paginationType={PaginationType.None}
          url={Urls.employerChargingSessions}
          defaultSort="start"
          defaultSortDirection={SortKeys.DESCENDING}
          amount={5}
        >
          <RecentUsageDataTable />
        </DataTableProvider>
      </StPageContainerContainer>
    </ContentContainer>
  )
}

const StPageContainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

  padding-bottom: 70px;
`
