import { createGlobalStyle } from 'styled-components'

import { breakpoints, widths } from '../layout/breakpoints'

export const GlobalStyles = createGlobalStyle<{
  $topInset: number
  $bottomInset: number
  $native: boolean
}>`

/* Variables */
:root {
  --page-padding-top: ${({ theme, $topInset }) =>
    $topInset >= theme.UI.Spacing.Space6
      ? $topInset
      : theme.UI.Spacing.Space6}px;
  --navigation-height: 76px;
  --bottom-navigation-height: 66px;
  --page-header-height: 52px;
  --sticky-button-container-height: 120px;
  --mobile-page-header-height: 68px;
  --inset-top: ${({ $topInset }) => ($topInset ? `${$topInset}px` : undefined)};
  --content-bottom-inset: ${({ $bottomInset, theme }) =>
    ($bottomInset || theme.UI.Spacing.Space8) + theme.UI.Spacing.Space4}px ;
  --inset-bottom: ${({ $bottomInset }) =>
    $bottomInset ? `${$bottomInset}px` : undefined};

    @media ${breakpoints.desktop} {
      --sticky-button-container-height: 100px;
    }
}


/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  user-select: ${({ $native }) => ($native ? 'none' : 'auto')};
  -webkit-user-select: ${({ $native }) => ($native ? 'none' : 'auto')};
  -webkit-user-drag: ${({ $native }) => ($native ? 'none' : 'auto')};
}



#__next {
  min-height: 100%;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

* {
  /* Remove scrollbars on mobile */
  @media only screen and (max-width: ${widths.desktop}px) {
    scrollbar-width: none; /* Hide scrollbar for Firefox */
      -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
      &::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari, and Opera */
        display: none;
        --webkit-appearance: none;
      }
  }
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;

  overscroll-behavior-y: none;
}

body.overlay-open {
  overflow: hidden;
  max-height: 100vh;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* A elements shouldn't be underlined */
a {
  text-decoration: none;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

input-security,
button,
textarea,
select,
a {
  outline-color: ${({ theme }) => theme.theme.colors['primary-0']};
}

button {
  color: ${({ theme }) => theme.theme.text.body.black};
}

/* Site wide styles */
html {
  .splash-leave {
    transition: opacity 250ms ease-out;
  }
  .splash-leave-from {
    opacity: 1;
  }
  .splash-leave-to {
    opacity: 0;
  }
  .splash-enter {
    transition: none;
  }
  .splash-enter-from {
    opacity: 1;
  }
  .splash-enter-to {
    opacity: 1;
  }
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

// z-index fix for radix dropdown menu
div[data-radix-popper-content-wrapper]{
  z-index: 9999 !important;
}

/* Accordion animations */
.AccordionContent {
  overflow: hidden;
}
.AccordionContent[data-state='open'] {
  animation: slideDown 200ms ease-out;
}
.AccordionContent[data-state='closed'] {
  animation: slideUp 200ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

.toasterClass {
  & > div {
    justify-content: flex-start
  }
}
`
