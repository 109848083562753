import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Dialog from '@radix-ui/react-dialog'
import { useTranslation } from 'react-i18next'
import { styled, keyframes } from 'styled-components'

import { BodyLargeRegularCss } from '../../../components/typography'

import type { ReactNode } from 'react'

type SimpleLightboxProps = {
  open: boolean
  onClose: () => void
  children?: ReactNode
}

export const SimpleLightbox = ({
  open,
  onClose,
  children,
}: SimpleLightboxProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Render component --
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <StDialogOverlay onClick={onClose} />
        <StDialogContent onEscapeKeyDown={onClose}>
          <StDialogClose onClick={onClose} asChild>
            <StDialogCloseLabel>
              <p>{t('onboarding.connection-type.close')}</p>
              <FontAwesomeIcon icon={['fasr', 'xmark-large']} />
            </StDialogCloseLabel>
          </StDialogClose>
          <StDialogContainer>{children}</StDialogContainer>
        </StDialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const appearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StDialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  z-index: 600;
  background-color: ${({ theme }) => theme.theme.colors.black};
  animation: ${appearAnimation} 0.1s linear;
`

const StDialogClose = styled(Dialog.Close)`
  position: absolute;
  right: 0;
  transform: translateY(-100%);
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
  cursor: pointer;
`

const StDialogCloseLabel = styled.div`
  ${BodyLargeRegularCss}
  display: flex;
  align-items: center;
  gap: 8px;
`

const StDialogContent = styled(Dialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.theme.colors.white};
  z-index: 601;

  &:focus-visible {
    outline: none;
  }
`

const StDialogContainer = styled.div`
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  overflow: hidden;
`
