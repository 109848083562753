import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import * as Yup from 'yup'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { Select } from '../../../../components/form/Select'
import { Dialog } from '../../../../components/general/Dialog'
import {
  BodyMediumRegular,
  BodyMediumRegularCss,
} from '../../../../components/typography'
import { FloatingContainerButton } from '../../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { useOnboarding } from '../../hooks/useOnboarding'

enum CableDistance {
  '0 - 5 Meter' = '0 - 5 Meter',
  '5 - 10 Meter' = '5 - 10 Meter',
  '10 - 15 Meter' = '10 - 15 Meter',
  '15 - 30 Meter' = '15 - 30 Meter',
  '30+ Meter' = '30+ Meter',
}

export const cableDistanceOptions = [
  {
    key: CableDistance['0 - 5 Meter'],
    translationKey: 'onboarding.cable-length.key-1',
  },
  {
    key: CableDistance['5 - 10 Meter'],
    translationKey: 'onboarding.cable-length.key-2',
  },
  {
    key: CableDistance['10 - 15 Meter'],
    translationKey: 'onboarding.cable-length.key-3',
  },
  {
    key: CableDistance['15 - 30 Meter'],
    translationKey: 'onboarding.cable-length.key-4',
  },
  {
    key: CableDistance['30+ Meter'],
    translationKey: 'onboarding.cable-length.key-5',
  },
]

export const CableDistanceStep = () => {
  // -- State --
  const [open, setOpen] = useState(false)

  // -- Hooks --
  const { t } = useTranslation()
  const { handleNext, setLoading, values: onboardingValues } = useOnboarding()
  const { trackEvent } = useTracking()

  // -- Data --
  // eslint-disable-next-line no-unused-vars
  const [{ loading }, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Vars --
  const validationSchema = Yup.object().shape({
    cableLength: Yup.string().required(
      t('onboarding.cable-length.input.error')
    ),
  })

  const selectOptions = cableDistanceOptions.map((item) => ({
    key: item.key,
    label: t(item.translationKey),
  }))

  // -- Handlers --
  const handleSelect = (values: { cableLength: string }) => {
    // Open the dialog if "30+ Meter" is selected
    if (values.cableLength === CableDistance['30+ Meter']) {
      setOpen(true)
      trackEvent(EventType.Open, 'open_presite_visit_modal')
    } else {
      handleSubmit(values.cableLength)
    }
  }

  const handleSubmit = async (key: string) => {
    try {
      setLoading(true)

      if (key !== onboardingValues.cableLengthKey) {
        await execute({
          data: {
            cableLengthKey: key,
          },
        })
      }

      handleNext({ ...onboardingValues, cableLengthKey: key })
    } catch {
      setLoading(false)
      return console.error('Failed to save cable length')
    }
  }

  return (
    <>
      <StContainer>
        <OnboardingStepTitle>
          {t('onboarding.cable-length.title')}
        </OnboardingStepTitle>

        <Formik
          initialValues={{ cableLength: onboardingValues.cableLengthKey }}
          onSubmit={handleSelect}
          validationSchema={validationSchema}
          validateOnBlur
        >
          {({ errors, values, setFieldValue, setFieldTouched }) => (
            <Form>
              <StFormContainer>
                <Select
                  options={selectOptions}
                  label={t('onboarding.cable-length.input.label')}
                  placeholder={t('onboarding.cable-length.input.placeholder')}
                  value={values.cableLength}
                  onChange={(value) => setFieldValue('cableLength', value)}
                  onBlur={() => setFieldTouched('cableLength')}
                  disabled={loading}
                />
                <div>
                  <StExamples>
                    {t('onboarding.cable-length.example')}
                  </StExamples>
                  <ul>
                    <StBulletPoint>
                      {t('onboarding.cable-length.example-1')}
                    </StBulletPoint>
                    <StBulletPoint>
                      {t('onboarding.cable-length.example-2')}
                    </StBulletPoint>
                  </ul>
                </div>
              </StFormContainer>
              <FloatingContainerButton
                title={t('onboarding.cable-length.submit')}
                disabled={!!errors.cableLength || !values.cableLength}
              />
            </Form>
          )}
        </Formik>
      </StContainer>
      <Dialog
        title={t('onboarding.installation-material.dialog.title')}
        description={t('onboarding.installation-material.dialog.description')}
        primaryButtonText={t('onboarding.installation-material.dialog.confirm')}
        secondaryButtonText={t(
          'onboarding.installation-material.dialog.cancel'
        )}
        open={open}
        onClickPrimaryButton={() => {
          trackEvent(EventType.Click, 'confirm_site_visit')
          handleSubmit(CableDistance['30+ Meter'])
          setOpen(false)
        }}
        onClickSecondaryButton={() => setOpen(false)}
        onClickCloseButton={() => {
          setOpen(false)
          trackEvent(EventType.Close, 'close_presite_visit_modal')
        }}
      />
    </>
  )
}

const StContainer = styled.div`
  padding-bottom: ${({ theme }) =>
    `calc(var(--inset-bottom, ${theme.UI.SpacingPx.Space6}) + var(--sticky-button-container-height))`};
`

const StFormContainer = styled.div`
  @media ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: ${({ theme }) => theme.UI.SpacingPx.Space12};
  }
`

const StExamples = styled(BodyMediumRegular)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};

  @media ${breakpoints.desktop} {
    margin-top: 0;
  }
`

const StBulletPoint = styled.li`
  ${BodyMediumRegularCss}

  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
