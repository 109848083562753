import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import * as Yup from 'yup'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { Input } from '../../../../components/form/Input'
import {
  BodyMediumRegular,
  BodyMediumRegularCss,
} from '../../../../components/typography'
import { FloatingContainerButton } from '../../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { useOnboarding } from '../../hooks/useOnboarding'

export const ChargerLocationInputStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { handleNext, setLoading, values: onboardingValues } = useOnboarding()

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Vars --
  const validationSchema = Yup.object().shape({
    location: Yup.string().required(t('onboarding.location-else.error')),
  })

  // -- Handlers --
  const handleSubmit = async (values: { location: string }) => {
    try {
      setLoading(true)

      await execute({
        data: {
          chargerLocation: values.location,
        },
      })

      handleNext({ ...onboardingValues, chargerLocation: values.location })
    } catch {
      setLoading(false)
      return console.error('Failed to save charger location')
    }
  }

  // -- Render --
  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.location-else.title')}
      </OnboardingStepTitle>

      <Formik
        initialValues={{ location: onboardingValues.chargerLocation ?? '' }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur
      >
        {({ errors, touched, values, handleChange, handleBlur }) => (
          <Form>
            <StFormContainer>
              <Input
                name="location"
                label={t('onboarding.location-else.label')}
                value={values.location}
                error={errors.location}
                touched={touched.location}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div>
                <StExamples>{t('onboarding.location-else.example')}</StExamples>
                <ul>
                  <StBulletPoint>
                    {t('onboarding.location-else.example-1')}
                  </StBulletPoint>
                  <StBulletPoint>
                    {t('onboarding.location-else.example-2')}
                  </StBulletPoint>
                </ul>
              </div>
            </StFormContainer>
            <FloatingContainerButton
              title={t('onboarding.account.submit')}
              disabled={!!errors.location || !values.location}
            />
          </Form>
        )}
      </Formik>
    </StContainer>
  )
}

const StContainer = styled.div`
  margin-bottom: ${({ theme }) =>
    `calc(var(--inset-bottom, ${theme.UI.SpacingPx.Space6}) + var(--sticky-button-container-height))`};
`

const StFormContainer = styled.div`
  @media ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: ${({ theme }) => theme.UI.SpacingPx.Space12};
  }
`

export const StExamples = styled(BodyMediumRegular)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};

  @media ${breakpoints.desktop} {
    margin-top: 0;
  }
`

export const StBulletPoint = styled.li`
  ${BodyMediumRegularCss}

  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
