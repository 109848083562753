import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { forwardRef } from 'react'
import { styled } from 'styled-components'

type InfoButtonProps = {
  onClick?: () => void
}

export const InfoButton = forwardRef<HTMLButtonElement, InfoButtonProps>(
  function InfoButton({ onClick }, ref) {
    return (
      <StButton onClick={onClick} ref={ref}>
        <FontAwesomeIcon icon={['fass', 'circle-info']} />
      </StButton>
    )
  }
)

const StButton = styled.button`
  margin: 0;
  padding: 0;

  background-color: transparent;
  border: none;

  cursor: pointer;

  display: grid;
  place-items: center;

  color: ${({ theme }) => theme.theme.text.body['gray-light']};
`
