import { isValid } from 'date-fns'
import { Field, Formik } from 'formik'
import { parse } from 'papaparse'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import * as Yup from 'yup'

import { FileInput } from '../../../components/form/file-input/FileInput'
import { Select } from '../../../components/form/Select'
import { Dialog } from '../../../components/general/Dialog'
import {
  BodyMediumRegular,
  BodyMediumRegularCss,
} from '../../../components/typography'

import type { SelectOption } from '../../../components/form/Select'
import type { EmployeeInformation } from '../screens/InviteEmployeesScreen'
import type { FieldProps } from 'formik'

type EmployeeImportInformationRaw = {
  email: string
  'internal ID': string
  'activation date': string
}

type EmployeeImportInformation = {
  email: string
  'internal ID': string
  'activation date': Date
}

type BulkUploadEmployeesDialogProps = {
  open: boolean
  handleBulkUploadEmployees: (employees: EmployeeInformation[]) => void
  handleClose: () => void
  hcpContractOptions: SelectOption[]
  mspContractOptions: SelectOption[]
  mspDeliveryOptions: SelectOption[]
  disableMspContract: boolean
}

type BulkUploadFormValues = {
  mspContractId: string | undefined
  manualMspHandover: boolean | string
  hcpContractId: string | undefined
  hasExistingDevice: 'true' | 'false'
  bulkUploadFile: File | undefined
}

export const BulkUploadEmployeesDialog = ({
  open,
  handleBulkUploadEmployees,
  handleClose,
  hcpContractOptions,
  mspContractOptions,
  mspDeliveryOptions,
  disableMspContract,
}: BulkUploadEmployeesDialogProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- State --
  const [employees, setEmployees] = useState<EmployeeImportInformation[]>([])
  const [processingEmployees, setProcessingEmployees] = useState<boolean>(false)
  const [fileInputError, setFileInputError] = useState<string | undefined>(
    undefined
  )

  // -- Data --
  const initialFormValues: BulkUploadFormValues = {
    mspContractId: undefined,
    manualMspHandover: 'false',
    hcpContractId: undefined,
    hasExistingDevice: 'false',
    bulkUploadFile: undefined,
  }

  const hasExistingDeviceOptions: SelectOption[] = [
    {
      key: 'false',
      label: t('admin.invite-employees.information.new'),
    },
    {
      key: 'true',
      label: t('admin.invite-employees.information.existing'),
    },
  ]

  // -- Validation --
  const bulkUploadValidation = Yup.object().shape({
    mspContractId: Yup.string(),
    manualMspHandover: Yup.boolean(),
    hcpContractId: Yup.string(),
    hasExistingDevice: Yup.string().oneOf(['true', 'false']).required(),
    bulkUploadFile: Yup.mixed().required(
      t('admin.invite-employees.bulk.file.required')
    ),
  })

  // -- Handlers --
  const handleProcessEmployees = (csvFile: File) => {
    setProcessingEmployees(true)
    parse<EmployeeImportInformationRaw>(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        if (results.data.length === 0) {
          setFileInputError(
            t('admin.invite-employees.bulk.file.error.no-employees')
          )

          setProcessingEmployees(false)
          return
        }

        if (
          !(
            results.meta.fields?.includes('activation date') &&
            results.meta.fields?.includes('internal ID') &&
            results.meta.fields?.includes('email')
          )
        ) {
          setFileInputError(
            t('admin.invite-employees.bulk.file.error.invalid-format')
          )

          setProcessingEmployees(false)
          return
        }

        if (results.errors.length > 0) {
          setFileInputError(
            results.errors.length > 5
              ? t('admin.invite-employees.bulk.file.error.parse-rows-large', {
                  count: results.errors.length,
                })
              : t('admin.invite-employees.bulk.file.error.parse-rows', {
                  rows: results.errors.map((error) => error.row).join(', '),
                  count: results.errors.length,
                })
          )

          setProcessingEmployees(false)
          return
        }

        const resultingEmployees = results.data.map((employee) => {
          // check if the employee has a valid date if provided
          const dateArray = employee['activation date'].split('/')

          let activationDate

          if (dateArray[0].length === 4) {
            activationDate = new Date(employee['activation date'])
          } else {
            const [day, month, year] = dateArray
            activationDate = new Date(`${year}/${month}/${day}`)
          }

          return {
            ...employee,
            'activation date': isValid(activationDate)
              ? activationDate
              : new Date(),
          }
        })

        setProcessingEmployees(false)
        setEmployees(resultingEmployees)
      },
    })
  }

  const handleSubmit = async (
    values: BulkUploadFormValues,
    resetCallback: () => void
  ) => {
    if (employees.length === 0) {
      return
    }

    handleBulkUploadEmployees(
      employees.map((employee) => ({
        email: employee.email,
        internalId: employee['internal ID'],
        activationDate: new Date(employee['activation date']),
        hcpContractId: values.hcpContractId ?? 'NULL',
        mspContractId: values.mspContractId ?? 'NULL',
        hasExistingDevice: values.hasExistingDevice,
        manualMspHandover: values.mspContractId
          ? values.manualMspHandover
            ? 'true'
            : 'false'
          : 'false',
      }))
    )

    setEmployees([])
    resetCallback()
  }

  // -- Render --
  return (
    <Formik
      onSubmit={() => {
        return
      }}
      initialValues={initialFormValues}
      validationSchema={bulkUploadValidation}
    >
      {({ values, touched, setFieldValue, resetForm }) => (
        <Dialog
          open={open}
          title={t('admin.invite-employees.bulk.title')}
          primaryButtonText={t('admin.invite-employees.bulk.submit', {
            count: employees.length,
          })}
          onClickPrimaryButton={() => {
            handleSubmit(values, resetForm)
          }}
          secondaryButtonText={t('admin.invite-employees.bulk.cancel')}
          onClickSecondaryButton={() => {
            handleClose()
            resetForm()
            setFileInputError(undefined)
          }}
          onOpenChange={(value) => {
            if (value) {
              return
            }

            handleClose()
            resetForm()
            setFileInputError(undefined)
          }}
          disablePrimaryButton={employees.length === 0 || processingEmployees}
        >
          <StBulkUploadContainer>
            <StHeader>
              <BodyMediumRegular>
                <Trans
                  t={t}
                  i18nKey={'admin.invite-employees.bulk.description1'}
                >
                  Gelieve een bestand op te laden in de juiste formatting. Om
                  dit gemakkelijk te maken kan je hier een
                  <a href="/assets/templates/import-employees.csv">
                    CSV Template
                  </a>
                  downloaden.
                </Trans>
              </BodyMediumRegular>
              <BodyMediumRegular>
                {t('admin.invite-employees.bulk.description2')}
              </BodyMediumRegular>
            </StHeader>

            <StForm>
              <StFormRow>
                <Field name="hcpContractId">
                  {({ field, form, meta }: FieldProps) => (
                    <Select
                      options={hcpContractOptions}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value)
                      }}
                      hasError={!!meta.error}
                      touched={meta.touched}
                      value={field.value}
                      label={t('admin.invite-employees.employee-form.hcp')}
                      icon={['fass', 'user']}
                    />
                  )}
                </Field>
                <Field name="hasExistingDevice">
                  {({ field, form, meta }: FieldProps) => (
                    <Select
                      disabled={!values.hcpContractId || !hcpContractOptions}
                      options={hasExistingDeviceOptions}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value)
                      }}
                      hasError={!!meta.error}
                      touched={meta.touched}
                      value={field.value}
                      label={t(
                        'admin.invite-employees.employee-form.existing-hcp'
                      )}
                      icon={['fass', 'charging-station']}
                    />
                  )}
                </Field>
              </StFormRow>
              <StFormRow>
                <Field name="mspContractId">
                  {({ field, form, meta }: FieldProps) => (
                    <Select
                      disabled={disableMspContract}
                      options={mspContractOptions}
                      value={field.value}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value)
                      }}
                      hasError={!!meta.error}
                      touched={meta.touched}
                      label={t(
                        'admin.invite-employees.information.charge-card'
                      )}
                      icon={['fass', 'credit-card-front']}
                    />
                  )}
                </Field>
                <Field name="manualMspHandover">
                  {({ field, form, meta }: FieldProps) => (
                    <Select
                      label={t(
                        'admin.invite-employees.employee-form.msp-postage'
                      )}
                      disabled={disableMspContract || !values.mspContractId}
                      options={mspDeliveryOptions}
                      value={field.value}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value)
                      }}
                      hasError={!!meta.error}
                      touched={meta.touched}
                      icon={['fass', 'truck-fast']}
                    />
                  )}
                </Field>
              </StFormRow>
              <StFileInputContainer>
                <FileInput
                  fileType="csv"
                  fileLabel={t('admin.invite-employees.bulk.file.label', {
                    count: employees.length,
                  })}
                  onDelete={() => {
                    setFieldValue('bulkUploadFile', undefined)
                    setFileInputError(undefined)
                    setEmployees([])
                  }}
                  onFileChange={(file) => {
                    setFieldValue('bulkUploadFile', file)
                    setFileInputError(undefined)
                    handleProcessEmployees(file)
                  }}
                  label={t('admin.invite-employees.bulk.file.label')}
                  file={values.bulkUploadFile}
                  touched={touched.bulkUploadFile}
                  isLoading={processingEmployees}
                  loadingText={t('admin.invite-employees.bulk.file.loading')}
                  error={fileInputError}
                />
              </StFileInputContainer>
            </StForm>
          </StBulkUploadContainer>
        </Dialog>
      )}
    </Formik>
  )
}

const StBulkUploadContainer = styled.div`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  min-width: 60dvw;
`

const StHeader = styled.div`
  ${BodyMediumRegularCss}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};

  a {
    color: ${({ theme }) => theme.theme.text.body.black};
    text-decoration: underline;
  }
`

const StForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: 8px;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StFormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StFileInputContainer = styled.div`
  grid-column: span 2;
`
