import { styled } from 'styled-components'

import { breakpoints } from '../../../theme/layout/breakpoints'

import { FloatingButtonBlur } from './FloatingButtonBlur'

import type { PropsWithChildren } from 'react'

export const FloatingButtonContainer = ({ children }: PropsWithChildren) => {
  return (
    <StContainer>
      <StChildren>{children}</StChildren>
      <FloatingButtonBlur />
    </StContainer>
  )
}

const StContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;

  margin-bottom: var(--content-bottom-inset);
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktop} {
    position: relative;
  }
`

const StChildren = styled.div`
  position: relative;
  z-index: 10;
`
