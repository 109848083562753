import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../../components/typography'
import { calculateColorContrast } from '../../../../core/utils/colorContrast'
import { useUser } from '../../../../user/hooks/useUser'
import { getColorName } from '../../util/product'

import type { Color } from 'types'

type ProductColorButtonProps = {
  color: Color
  selected?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const ProductColorButton = ({
  color,
  onClick,
  selected = false,
  disabled = false,
}: ProductColorButtonProps) => {
  const { user } = useUser()

  const hasValidContrast = calculateColorContrast(color.hexValue, '#FFFFFF')

  return (
    <StColorButton onClick={onClick} $selected={selected} $disabled={disabled}>
      <StColorDot $color={color.hexValue} $showBorder={!hasValidContrast} />
      <BodySmallRegular>{getColorName(color, user.language)}</BodySmallRegular>
    </StColorButton>
  )
}

const StColorButton = styled.button<{ $selected: boolean; $disabled: boolean }>`
  display: flex;
  gap: 6px;
  align-items: center;

  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.theme.colors['nonary-9'] : 'white'};
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.theme.text.body['gray-mid'] : 'black'};

  padding: 14px;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  border: 1px solid
    ${({ theme, $selected, $disabled }) =>
      $disabled
        ? theme.theme.colors['nonary-9']
        : $selected
        ? theme.components['card-select'].text
        : theme.components['card-select']['default-border']};

  &:hover {
    border-color: ${({ theme, $disabled }) =>
      $disabled
        ? theme.theme.colors['nonary-9']
        : theme.theme.colors['primary-0']};
  }
`

const StColorDot = styled.div<{ $color: string; $showBorder: boolean }>`
  flex: 0 0 auto;

  width: ${({ theme }) => theme.UI.SpacingPx.Space4};
  height: ${({ theme }) => theme.UI.SpacingPx.Space4};
  border-radius: 50%;

  background-color: ${({ $color }) => $color};
  border: 1px solid
    ${({ theme, $showBorder }) =>
      $showBorder ? theme.theme.colors['nonary-2'] : 'transparent'};
`
