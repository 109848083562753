import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { PageHeader } from '../../../components/general/PageHeader'
import { ContentContainer } from '../../../components/page-layout'
import { Tabs } from '../../../components/Tabs/Tabs'
import { useMember } from '../../../member/hooks/useMember'
import { UsageContent } from '../components/UsageContent'

export const EmployeeUsageScreen = () => {
  // Hooks
  const { t } = useTranslation()
  const { currentMember } = useMember()

  const { state } = useLocation()

  // -- Vars --
  const pageItems = [
    {
      content: <UsageContent type="hcp" />,
      value: 'hcp',
      title: t('employee.chargingSessions.at-home'),
    },
    {
      content: <UsageContent type="msp" />,
      value: 'msp',
      title: t('employee.chargingSessions.on-the-road'),
    },
  ]

  // Render empty state
  if (!currentMember.mspContractId && !currentMember.hcpContractId) {
    return (
      <ContentContainer>
        <PageHeader>{t('employee.chargingSessions.title')}</PageHeader>
        <UsageContent />
      </ContentContainer>
    )
  }

  // User only has MSP contract, don't show HCP
  if (currentMember.mspContractId && !currentMember.hcpContractId) {
    return (
      <ContentContainer>
        <PageHeader>{t('employee.chargingSessions.title')}</PageHeader>
        <UsageContent type="msp" />
      </ContentContainer>
    )
  }

  // User only has HCP, don't show MSP
  if (!currentMember.mspContractId && !!currentMember.hcpContractId) {
    return (
      <ContentContainer>
        <PageHeader>{t('employee.chargingSessions.title')}</PageHeader>
        <UsageContent type="hcp" />
      </ContentContainer>
    )
  }

  // Show both options with tabs
  return (
    <ContentContainer>
      <PageHeader>{t('employee.chargingSessions.title')}</PageHeader>

      <Tabs defaultValue={state?.usageType ?? 'hcp'} items={pageItems} />
    </ContentContainer>
  )
}
