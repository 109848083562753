import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import { styled } from 'styled-components'

import { BodyMediumMediumCss } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

type NavigationItemProps = {
  name?: string
  to: string
  icon?: IconProp
}

export const NavigationItem = ({ name, to, icon }: NavigationItemProps) => {
  return (
    <StStButton to={to} end>
      {icon ? <StFontAwesomeIcon icon={icon} /> : null}
      {name}
    </StStButton>
  )
}

const StStButton = styled(NavLink)`
  ${BodyMediumMediumCss}
  font-family: 'F37 Jagger';
  background-color: transparent;

  border: none;
  border-bottom: 6px solid transparent;
  padding-top: 6px;

  text-decoration: none;
  color: ${({ theme }) => theme.components.menu.default['item-text']};

  display: flex;
  align-items: center;

  &.active {
    border-bottom: 6px solid
      ${({ theme }) => theme.components.menu.default['border-select']};
    font-weight: 700;

    &:focus-visible {
      box-shadow: 0px 0px 0px 2px
        ${({ theme }) => theme.theme.colors['primary-0']};
    }
  }
`

const StFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space1};
`
