import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../../components/typography'
import { SelectedWheelSlide, Wheel } from '../../../../components/Wheel'

export function AutopilotDistancePicker({
  value,
  onChange,
}: AutopilotDistancePickerProps) {
  const { t } = useTranslation()

  const minDistance = 10
  const distanceStep = 10

  return (
    <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
      <StSelectedWheelSlide>
        <StBodySmallRegular>
          {t('employee.hems.charge-mode.autopilot.km')}
        </StBodySmallRegular>
      </StSelectedWheelSlide>
      <StWheel
        length={60}
        width={140}
        perspective="center"
        formatValue={(relative) => `${minDistance + distanceStep * relative}`}
        initialIndex={(value - minDistance) / distanceStep}
        onChange={(wheelIndex) => {
          onChange(wheelIndex * distanceStep + minDistance)
        }}
      />
    </div>
  )
}

type AutopilotDistancePickerProps = {
  onChange: (newDistance: number) => void
  value: number
}

const StSelectedWheelSlide = styled(SelectedWheelSlide)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StWheel = styled(Wheel)`
  flex: 1;
  height: 200px;
`

const StBodySmallRegular = styled(BodySmallRegular)`
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
