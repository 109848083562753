import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import { styled } from 'styled-components'

import {
  BodyLargeSemiBold,
  BodyMediumRegular,
} from '../../../components/typography'
import { formatDecimals } from '../../../core/utils/number'
import { useHems } from '../hooks/useHems'

import { EnergyConsumptionCard } from './EnergyConsumptionStatisticCard'

export function EnergyConsumptionPeakCard() {
  const { energyConsumption, energyConsumptionLoading } = useHems()

  return (
    <EnergyConsumptionCard
      icon={<StTriangleIcon icon={['fass', 'triangle']} fontSize={12} />}
      description={t('employee.hems.usage.electricity.peak')}
      isLoading={energyConsumptionLoading}
    >
      {energyConsumption ? (
        <BodyMediumRegular>
          <BodyLargeSemiBold as="strong">
            {formatDecimals(energyConsumption.peakW / 1000, 2)}
          </BodyLargeSemiBold>{' '}
          {t('employee.hems.usage.electricity.kW')}
        </BodyMediumRegular>
      ) : (
        '-'
      )}
    </EnergyConsumptionCard>
  )
}

const StTriangleIcon = styled(FontAwesomeIcon)`
  margin: 2px 0;
`
