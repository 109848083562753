import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { Banner, BannerType } from '../../../components/general/Banner'
import {
  BodyLargeMedium,
  BodyMediumRegular,
  H5,
} from '../../../components/typography'

import type { HcpContract } from '../types/Contract'

type HcpContractOverviewItemProps = {
  contract: HcpContract
}

export const HcpContractOverviewItem = ({
  contract,
}: HcpContractOverviewItemProps) => {
  const { t } = useTranslation()

  return (
    <StContract>
      <StContractHeader>
        <StContractTitle>{contract.name}</StContractTitle>
        <BodyLargeMedium>
          {contract.members.length ?? 0} {t('employer.contracts.hcp.employees')}
        </BodyLargeMedium>
      </StContractHeader>
      {contract.context && (
        <StContextBanner>
          <Banner type={BannerType.INFORMATION} icon={['fass', 'info-square']}>
            {contract.context}
          </Banner>
        </StContextBanner>
      )}
      <StContractValue>
        <BodyMediumRegular>
          {t('employer.contracts.hcp.installation-contribution')}
        </BodyMediumRegular>
        <BodyLargeMedium>€{contract.installationContribution}</BodyLargeMedium>
      </StContractValue>
      <StContractValue>
        <BodyMediumRegular>
          {t('employer.contracts.hcp.device-contribution')}
        </BodyMediumRegular>
        <BodyLargeMedium>€{contract.deviceContribution}</BodyLargeMedium>
      </StContractValue>
      <StContractValue>
        <BodyMediumRegular>
          {t('employer.contracts.hcp.service-contribution')}
        </BodyMediumRegular>
        <BodyLargeMedium>€{contract.serviceContribution}%</BodyLargeMedium>
      </StContractValue>
    </StContract>
  )
}

const StContract = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
`

const StContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  grid-column: span 2;
`

const StContractTitle = styled(H5)`
  margin: 0;
`

const StContractValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const StContextBanner = styled.div`
  grid-column: span 2;
`
