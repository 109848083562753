import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'
import * as Yup from 'yup'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { Input } from '../../../components/form/Input'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { FullPageLoader } from '../../../components/general/FullPageLoader'
import { ContentContainer } from '../../../components/page-layout'
import { BodySmallRegular, H6 } from '../../../components/typography'
import {
  calculatePeakUsagePrice,
  formatDecimals,
} from '../../../core/utils/number'
import { useHems } from '../hooks/useHems'
import { UserSettingsMode } from '../types/enums'

export const ChargingPeakScreen = () => {
  // -- State --
  const [editable, setEditable] = useState(false)

  // -- Data --
  // const [{ data }] = useAuthMemberAxios<ScoptTips>({
  //   url: Urls.fetchEnergyTips,
  // })

  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { setPoints, handleSetChargerSetPoint } = useHems()

  // -- Handlers --
  const handleSetEditable = () => {
    setEditable((previous) => !previous)

    trackEvent(
      EventType.Click,
      editable ? 'disable_edit_charging_cap' : 'edit_charging_cap'
    )
  }

  // -- Vars --

  // const suggestedPeak = useMemo(() => {
  //   if (data && setPoints) {
  //     const suggestedPeak = data.current_month_peak[0].value
  //     const currentPeak = setPoints.user_setting_cap_value
  //     const peakDifference = currentPeak - suggestedPeak

  //     // Don't show the suggestion if the suggestion is lower than the minimum value
  //     if (peakDifference > 0 && suggestedPeak > 5000) {
  //       return {
  //         suggestedKw: formatDecimals(suggestedPeak / 1000, 1),
  //         savings: Math.ceil(calculatePeakUsagePrice(peakDifference / 1000)),
  //       }
  //     }
  //   }

  //   return null
  // }, [data, setPoints])

  const validationSchema = Yup.object({
    maxCap: Yup.number()
      .required(t('employee.charging-peak.setting.required-error'))
      .min(2.5, t('employee.charging-peak.setting.min-error'))
      .max(49, t('employee.charging-peak.setting.max-error')),
  })

  const handleSubmit = (values: { maxCap: number }) => {
    handleSetChargerSetPoint({
      mode: UserSettingsMode.cap,
      settings: { cap: values.maxCap * 1000 },
    })
  }

  if (!setPoints) {
    return <FullPageLoader />
  }

  // -- Render--
  return (
    <ContentContainer>
      <DetailPageHeader
        title={t('employee.charging-peak.title')}
        topTitle={t('employee.profile.title')}
        onBack={isDesktop ? undefined : () => navigate(-1)}
      />

      <BodySmallRegular>
        {t('employee.charging-peak.description')}
      </BodySmallRegular>

      <StColumns>
        <div>
          <StTitle>{t('employee.charging-peak.setting.title')}</StTitle>
          {/* {suggestedPeak && (
            <StInfoCard>
              <BodySmallRegular>
                <Trans
                  t={t}
                  i18nKey="employee.charging-peak.setting.lower-peak-info"
                  values={{
                    amount: suggestedPeak.suggestedKw,
                    savings: suggestedPeak.savings,
                  }}
                  components={{
                    string: <strong />,
                  }}
                />
              </BodySmallRegular>
            </StInfoCard>
          )} */}

          <Formik
            initialValues={{
              maxCap: setPoints.user_setting_cap_value / 1000,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values, handleChange, errors, touched }) => (
              <StSettingCard>
                <Input
                  type="number"
                  name="maxCap"
                  onChange={handleChange}
                  value={values.maxCap}
                  valueLabel="kW"
                  label={t('employee.charging-peak.setting.label')}
                  size="sm"
                  error={errors.maxCap}
                  touched={touched.maxCap}
                  readOnly={!editable}
                  warning={
                    values.maxCap > 19
                      ? t('employee.charging-peak.setting.warning', {
                          price: formatDecimals(
                            calculatePeakUsagePrice(values.maxCap),
                            0
                          ),
                        })
                      : undefined
                  }
                />
                <StButton
                  onClick={handleSetEditable}
                  disabled={Object.values(errors).length > 0}
                >
                  {editable
                    ? t('employee.charging-peak.setting.save')
                    : t('employee.charging-peak.setting.edit')}
                </StButton>
              </StSettingCard>
            )}
          </Formik>
        </div>
      </StColumns>
    </ContentContainer>
  )
}

const StTitle = styled(H6)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StSettingCard = styled(Form)`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
`

const StButton = styled(ButtonPrimary)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

// const StInfoCard = styled.div`
//   padding: ${({ theme }) => theme.UI.SpacingPx.Space5};
//   background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
//   border-radius: 8px;
//   margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
// `

const StColumns = styled.div`
  @media ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space15};
  }
`
