import { Browser } from '@capacitor/browser'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { BodySmallRegular, H4 } from '../../../../components/typography'
import { useMember } from '../../../../member/hooks/useMember'
import { useUser } from '../../../../user/hooks/useUser'
import p1port from '../assets/dongle-ports.png'
import { useHemsFlow } from '../hooks/useHemsFlow'

export const P1Step = () => {
  // -- Hooks --
  const { t, i18n } = useTranslation()
  const { flowNavigation } = useHemsFlow()
  const { currentMember } = useMember()
  const { refetch } = useUser()

  // -- State --
  const [isLoading, setIsLoading] = useState(false)

  // -- Data --
  const [, startOnboarding] = useAuthMemberAxios(
    {
      url: Urls.startHemsOnboarding,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Handlers --
  const handleNext = async () => {
    try {
      if (currentMember.hemsOnboardingStatus === 'Preparation') {
        setIsLoading(true)
        await startOnboarding()
        await refetch()
        setIsLoading(false)
      }
      flowNavigation.next()
    } catch {
      setIsLoading(false)

      toast.error(t('employee.hems.dongle-onboarding.p1.error'), {
        position: 'bottom-center',
        style: {
          // Put the toast on right above the button
          marginBottom: '100px',
        },
      })
    }
  }

  const handleOpenFAQ = () => {
    Browser.open({
      url: t('employee.hems.dongle-onboarding.p1.faq-url', {
        language: i18n.language,
      }),
    })
  }

  // -- Render --
  return (
    <div>
      <StImage src={p1port} alt="p1 port" />

      <StTextContainer>
        <StTitle>{t('employee.hems.dongle-onboarding.p1.title')}</StTitle>
        <BodySmallRegular>
          {t('employee.hems.dongle-onboarding.p1.description')}
        </BodySmallRegular>
      </StTextContainer>

      <FloatingButtonContainer>
        <ButtonTertiary
          style={{ marginBottom: '12px' }}
          onClick={handleOpenFAQ}
        >
          {t('employee.hems.dongle-onboarding.p1.help')}
        </ButtonTertiary>
        <ButtonPrimary onClick={handleNext} loading={isLoading}>
          {t('employee.hems.dongle-onboarding.p1.next')}
        </ButtonPrimary>
      </FloatingButtonContainer>
    </div>
  )
}

const StTextContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  /* Compensate for floating button */
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space47};

  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space7} 0;
  }
`

const StTitle = styled(H4)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StImage = styled.img`
  width: 100%;
  height: 300px;

  object-fit: cover;
`
