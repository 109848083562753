import { styled } from 'styled-components'

import { BodyMediumRegularCss } from '../typography'

import { StError, StLabel, StLabelContainer } from './Input'

import type { ChangeEvent, FocusEventHandler } from 'react'

type TextAreaProps = {
  value: string
  onChange: (event: ChangeEvent) => void
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  name: string
  label?: string
  placeholder?: string
  error?: string
  touched?: boolean
  disabled?: boolean
  rows?: number
}

export const TextArea = ({
  value,
  onChange,
  onBlur,
  name,
  label,
  placeholder,
  error,
  touched,
  disabled,
  rows = 5,
}: TextAreaProps) => {
  return (
    <StContainer>
      {label ? (
        <StLabelContainer>
          <StLabel htmlFor={name} $size="md">
            {label}
          </StLabel>
        </StLabelContainer>
      ) : null}
      <StTextArea
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        $error={touched && !!error}
        rows={rows}
      />
      {touched && error ? <StError>{error}</StError> : null}
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StTextArea = styled.textarea<{
  $error?: boolean
}>`
  display: flex;

  border: 1px solid;
  border-color: ${({ theme, $error }) =>
    $error ? theme.components.input.error : theme.components.input.border};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  background-color: ${({ theme }) => theme.components.input.bg};
  overflow: hidden;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};

  ${BodyMediumRegularCss}

  &:focus-within {
    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
  }

  &:hover {
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.components.input['disabled-bg']};
    border: none;
  }
`
