import { addMonths, endOfMonth, format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { Chip } from '../../../components/general/Chip'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'

import handHoldingEuro from './handHoldingEuro.svg'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ChargingSessionWithMspAndReimbursements, FullMember } from 'types'

export const ReimbursementStatusChip = ({
  chargingSession,
  member,
}: {
  chargingSession: ChargingSessionWithMspAndReimbursements
  member: FullMember
}) => {
  // -- Hooks --
  const dateFnsLocale = useGetDateFnsLocale()
  const { t } = useTranslation()
  const theme = useTheme()

  // -- Logic
  const isOwnSession = chargingSession.mspCard?.memberId === member.id
  const payedBack = isOwnSession
    ? ['paid', 'paid_in_advance'].includes(
        chargingSession.debitNote?.paymentStatus ?? ''
      )
    : chargingSession.hostInvoice?.isPaid
  const processed = isOwnSession
    ? chargingSession.debitNote?.isProcessed
    : chargingSession.hostInvoice?.isProcessed

  const chipBackgroundColor = payedBack
    ? theme.theme.colors['tertiary-6']
    : processed
    ? theme.theme.colors['septenary-4']
    : theme.theme.colors['nonary-9']

  const chipColor = payedBack
    ? theme.theme.colors['tertiary-3']
    : processed
    ? theme.theme.colors['septenary-1']
    : theme.theme.colors['nonary-3']

  const chipFaIcon: IconProp | undefined = payedBack
    ? ['fasr', 'check']
    : processed
    ? ['fasr', 'clock']
    : undefined

  const chipSvgIcon: string | undefined =
    !payedBack && !processed ? handHoldingEuro : undefined

  const paybackDate = format(
    endOfMonth(addMonths(new Date(chargingSession.end), 1)),
    'd LLLL',
    {
      locale: dateFnsLocale.locale,
    }
  )

  const chipText = payedBack
    ? t('employee.chargingSessions.paid')
    : processed
    ? t('employee.chargingSessions.processed')
    : t('employee.chargingSessions.not-processed', {
        date: paybackDate,
      })

  return (
    <Chip
      backgroudColor={chipBackgroundColor}
      color={chipColor}
      icon={chipFaIcon}
      iconSrc={chipSvgIcon}
      size="small"
      borderRadius="square"
    >
      {chipText}
    </Chip>
  )
}
