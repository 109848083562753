import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import Logo from '../../../components/assets/vectors/logo_bright.svg'
import { Chip } from '../../../components/general/Chip'
import {
  BodyExtraExtraSmallRegular,
  BodySmallSemiBold,
} from '../../../components/typography'
import CardBackground from '../assets/card-background.svg'

import type { MspCard } from 'database'
import type { FullMember } from 'types'

type ChargeCardDetailProps = {
  chargeCard: MspCard & {
    member: FullMember
  }
}

export const ChargeCardDetail = ({ chargeCard }: ChargeCardDetailProps) => {
  const { t } = useTranslation()

  // -- Render --
  return (
    <StContainer>
      <StBackground src={CardBackground} />

      <StChargeCardTopContainer>
        <StChipContainer>
          {chargeCard.mspCardStatus === 'Damaged' && (
            <Chip icon={['fass', 'repeat']}>
              {t('charge-card.detail.status.damaged')}
            </Chip>
          )}

          {chargeCard.mspCardStatus !== 'Active' &&
            chargeCard.mspCardStatus !== 'Damaged' && (
              <Chip icon={['fasr', 'ban']}>
                {t('charge-card.detail.status.blocked')}
              </Chip>
            )}
        </StChipContainer>

        <StLogo src={Logo} />
      </StChargeCardTopContainer>

      <StInformation>
        <BodySmallSemiBold>{chargeCard.member.user.fullName}</BodySmallSemiBold>
        <BodyExtraExtraSmallRegular>
          {chargeCard.member.mspCards[0].visualNumber}
        </BodyExtraExtraSmallRegular>
      </StInformation>
    </StContainer>
  )
}

const StContainer = styled.div`
  max-width: 400px;
  aspect-ratio: 8 / 5;

  position: relative;

  user-select: none;
`

const StBackground = styled.img`
  max-width: 100%;
  width: 100%;
`

const StChargeCardTopContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  left: ${({ theme }) => theme.UI.SpacingPx.Space7};
  right: ${({ theme }) => theme.UI.SpacingPx.Space7};

  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StInformation = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.UI.SpacingPx.Space7};
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  text-align: end;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

  color: ${({ theme }) => theme.theme.colors['primary-1']};
`

const StLogo = styled.img`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 45%;
  height: ${({ theme }) => theme.UI.SpacingPx.Space9};
`

const StChipContainer = styled.div``
