import { DotLottiePlayer } from '@dotlottie/react-player'
import { styled } from 'styled-components'

type LoaderProps = {
  size?: number
}

export const Loader = ({ size = 130 }: LoaderProps) => {
  return (
    <LoaderContainer>
      <DotLottiePlayer
        autoplay
        loop
        src="/assets/loader.lottie"
        style={{ height: `${size}px`, width: `${size}px` }}
        speed={1.4}
      />
    </LoaderContainer>
  )
}

const LoaderContainer = styled.div`
  display: grid;
  place-items: center;
`
