import { SpacingNumeric, SpacingString } from './design-tokens/spacing'
import { theme as generatedTheme } from './design-tokens/theme-generated'

const theme = {
  UI: {
    MaxWidth: {
      Article: 740,
      Page: 1500,
      HighlightBanner: 2000,
      navigation: 1260,
    },
    MaxWidthPx: {
      Article: '740px',
      Page: '1500px',
      HighlightBanner: '2000px',
      navigation: '1260px',
    },
    SpacingPx: SpacingString,
    Spacing: SpacingNumeric,
  },

  ...generatedTheme,
}

type Theme = typeof theme

export type { Theme }
export { theme }
