import { shallowEqual } from 'fast-equals'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { Select } from '../../../components/form/Select'
import { FormCardHeader } from '../../../components/form-card/FormCardHeader'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'

import type { FormikProps } from 'formik'

enum Vat {
  Included = 'included',
  Excluded = 'excluded',
}

export const FinancialSettings = () => {
  // -- State --
  const [editable, setEditable] = useState<boolean>(false)

  const formRef = useRef<
    FormikProps<{
      applyVat: Vat
    }>
  >(null)

  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const { currentMember } = useMember()
  const { refetch } = useUser()

  const initialValues = {
    applyVat: currentMember.company.applyVat ? Vat.Included : Vat.Excluded,
  }

  const [{ loading }, updateCompanyFinancialSettings] = useAuthMemberAxios(
    {
      url: Urls.updateFinancialSettings,
      method: 'Patch',
    },
    {
      manual: true,
    }
  )

  // -- Handlers --
  const handleSetEditable = () => {
    setEditable(true)
    trackEvent(EventType.Click, `edit_financial_settings`)
  }

  const handleClose = () => {
    setEditable(false)
    trackEvent(EventType.Click, `cancel_edit_financial_settings`)
  }

  const handleSubmit = async (values: typeof initialValues) => {
    await updateCompanyFinancialSettings({
      data: {
        applyVat: values.applyVat === Vat.Included,
      },
    })
    trackEvent(EventType.Submit, `update_financial_settings`)

    // Refetch user data to update the interface state
    await refetch()

    setEditable(false)

    formRef.current?.resetForm({
      values: values,
    })
  }

  // -- Render --
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      innerRef={formRef}
      enableReinitialize
    >
      {({ values, setFieldValue, resetForm }) => (
        <StForm>
          <FormCardHeader
            title={t('employer.financial.title')}
            handleSetEditable={handleSetEditable}
            editable={editable}
            handleClose={() => {
              resetForm()
              handleClose()
            }}
            disableSubmit={loading || shallowEqual(initialValues, values)}
            disableCancel={loading}
          />

          <StFormContent>
            <Select
              value={values.applyVat}
              label={t('employer.financial.vat.title')}
              onChange={(value) => setFieldValue('applyVat', value)}
              options={[
                {
                  key: Vat.Included,
                  label: t('employer.financial.vat.included'),
                },
                {
                  key: Vat.Excluded,
                  label: t('employer.financial.vat.excluded'),
                },
              ]}
              readOnly={!editable}
            />
          </StFormContent>
        </StForm>
      )}
    </Formik>
  )
}

const StForm = styled(Form)`
  margin: ${({ theme }) => theme.UI.SpacingPx.Space6} 0;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StFormContent = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space7};
`
