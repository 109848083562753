import { intervalToDuration, parseISO } from 'date-fns'

import type { TFunction } from 'i18next'

export const formatIntervalInDuration = (
  start: Date,
  end: Date,
  translate: TFunction
) => {
  let result = ''

  const duration = intervalToDuration({
    start,
    end,
  })

  if (duration.days) {
    result += duration.days + translate('duration.day')
  }

  if (duration.hours) {
    result += (result ? ' ' : '') + duration.hours + translate('duration.hour')
  }

  if (duration.minutes) {
    result +=
      (result ? ' ' : '') + duration.minutes + translate('duration.minute')
  }

  if (duration.seconds) {
    result +=
      (result ? ' ' : '') + duration.seconds + translate('duration.second')
  }

  return result === '' ? '0' + translate('duration.second') : result
}

export const valueToDate = (value: string | number | Date): Date => {
  return parseISO(value as string)
}
