import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { Empty } from '../../../../components/general/Empty'

import { ProductListItem } from './ProductListItem'

import type { Product } from 'types'

type ProductListProps = {
  products?: Product[]
  onSelect: (
    productId: string,
    colorId: string,
    connectionMethodId: string,
    mountingOptionId: string,
    mountingOption: string
  ) => void
}

export const ProductList = ({ products, onSelect }: ProductListProps) => {
  const { t } = useTranslation()

  return (
    <StContainer>
      {products && products?.length > 0 ? (
        products.map((product: Product) => (
          <ProductListItem
            key={product.id}
            product={product}
            onSelect={onSelect}
          />
        ))
      ) : (
        <StEmptyContainer>
          <Empty
            title={t('onboarding.product-selection-overview.empty.title')}
            description={t(
              'onboarding.product-selection-overview.empty.description'
            )}
          />
        </StEmptyContainer>
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  column-gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space14};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space12};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.mobile} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: ${({ theme }) => theme.UI.SpacingPx.Space18};
  }

  @media ${breakpoints.desktop} {
    grid-template-columns: repeat(3, 1fr);
    row-gap: ${({ theme }) => theme.UI.SpacingPx.Space20};
  }
`

const StEmptyContainer = styled.div`
  @media ${breakpoints.mobile} {
    grid-column: span 2;
  }

  @media ${breakpoints.desktop} {
    grid-column: span 3;
  }
`
