import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { StIconContainer } from '../../employee/onboarding/components/SelectBoxes'
import { BodyMediumRegularCss, H5 } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

type BigInfoCardProps = {
  icon: IconProp
  title: string
  bulletPoints: string[]
}

export const BigInfoCard = ({
  icon,
  title,
  bulletPoints,
}: BigInfoCardProps) => {
  return (
    <StBigCard>
      <StIconContainer>
        <FontAwesomeIcon icon={icon} />
      </StIconContainer>
      <H5>{title}</H5>
      <ul>
        {bulletPoints.map((bulletpoint) => (
          <StBulletPoint key={bulletpoint}>{bulletpoint}</StBulletPoint>
        ))}
      </ul>
    </StBigCard>
  )
}

const StBigCard = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  border: 1px solid
    ${({ theme }) => theme.components['card-select']['default-border']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StBulletPoint = styled.li`
  ${BodyMediumRegularCss}

  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
