import { useTranslation } from 'react-i18next'

import dongleButton from '../assets/dongle-button.png'
import dongleFlow from '../assets/dongle-flow.jpeg'
import donglePorts from '../assets/dongle-ports.png'
import fluvius from '../assets/fluvius-website.png'
import { ConnectionSelectionStep } from '../steps/ConnectionSelectionStep'
import { DongleConnectionStep } from '../steps/DongleConnectionStep'
import { HemsConfirmationStep } from '../steps/HemsConfirmationStep'
import { HemsContractStep } from '../steps/HemsContractStep'
import { HemsEnergyImportStep } from '../steps/HemsEnergyImportStep'
import { HemsInfoStep } from '../steps/HemsInfoStep'
import { HemsInstallationStep } from '../steps/HemsInstallationsStep'
import { MacAddressStep } from '../steps/MacAddressStep'
import { P1Step } from '../steps/P1Step'
import { ProvisioningSuccessStep } from '../steps/ProvisioningSuccessStep'
import { UtpProvisioningStep } from '../steps/UtpProvisioningStep'
import { WifiNetworkSelectionStep } from '../steps/WifiNetworkSelectionStep'
import { WifiPasswordStep } from '../steps/WifiPasswordStep'
import { WifiProvisioningStep } from '../steps/WifiProvisioningStep'

import { HemsOnboardingSteps } from './useHemsOnboardingState'

import type { ReactNode } from 'react'

export const useHemsOnboardingSteps = (): Record<
  HemsOnboardingSteps,
  ReactNode
> => {
  // -- Hooks --
  const { t } = useTranslation()

  return {
    [HemsOnboardingSteps.Fluvius]: (
      <HemsInfoStep
        visual={fluvius}
        title={t('employee.hems.dongle-onboarding.fluvius.title')}
        description={t('employee.hems.dongle-onboarding.fluvius.description')}
        nextString={t('employee.hems.dongle-onboarding.fluvius.next')}
      />
    ),
    [HemsOnboardingSteps.P1Port]: <P1Step />,
    [HemsOnboardingSteps.Bluetooth]: (
      <HemsInfoStep
        visual={dongleFlow}
        title={t('employee.hems.dongle-onboarding.bluetooth.title')}
        description={t('employee.hems.dongle-onboarding.bluetooth.description')}
      />
    ),
    [HemsOnboardingSteps.DongleButton]: (
      <HemsInfoStep
        visual={dongleButton}
        title={t('employee.hems.dongle-onboarding.dongle-button.title')}
        description={t(
          'employee.hems.dongle-onboarding.dongle-button.description'
        )}
        nextString={t('employee.hems.dongle-onboarding.dongle-button.button')}
      />
    ),
    [HemsOnboardingSteps.DongleConnection]: <DongleConnectionStep />,
    [HemsOnboardingSteps.MacAddress]: <MacAddressStep />,
    [HemsOnboardingSteps.ConnectionSelection]: <ConnectionSelectionStep />,
    [HemsOnboardingSteps.WifiNetworkSelection]: <WifiNetworkSelectionStep />,
    [HemsOnboardingSteps.WifiPassword]: <WifiPasswordStep />,
    [HemsOnboardingSteps.WifiProvisioning]: <WifiProvisioningStep />,
    [HemsOnboardingSteps.UtpConnection]: (
      <HemsInfoStep
        visual={donglePorts}
        title={t('employee.hems.dongle-onboarding.utp-connection.title')}
        description={t(
          'employee.hems.dongle-onboarding.utp-connection.description'
        )}
        nextString={t('employee.hems.dongle-onboarding.utp-connection.next')}
      />
    ),
    [HemsOnboardingSteps.UtpProvisioning]: <UtpProvisioningStep />,
    [HemsOnboardingSteps.ProvisioningSuccess]: <ProvisioningSuccessStep />,
    [HemsOnboardingSteps.Installations]: <HemsInstallationStep />,
    [HemsOnboardingSteps.Contract]: <HemsContractStep />,
    [HemsOnboardingSteps.EnergyImport]: <HemsEnergyImportStep />,
    [HemsOnboardingSteps.Confirmation]: <HemsConfirmationStep />,
  }
}
