import { format } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { minutesToHoursAndMinutes } from '../../../../../../../packages/utils/src/time/minutesToHoursAndMinutes'
import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonQuinary } from '../../../components/button/ButtonQuinary'
import { StIcon } from '../../../components/form/phone-input/PhoneInput.styled'
import {
  BodyExtraSmallRegular,
  BodyExtraSmallSemiBold,
  BodyLargeSemiBold,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { applyVat, formatDecimals } from '../../../core/utils/number'
import { DataList } from '../../../dataTable/components/DataList'
import { ColumnType } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'
import { valueToDate } from '../../../translations/utils/date'

import type { Column } from '../../../dataTable/components/DataTable'
import type { ChargingSessionWithRelations } from 'types'

export const RecentUsageDataList = () => {
  const { t } = useTranslation()
  const dateFnsLocale = useGetDateFnsLocale()
  const { amount, loading, dataCount } = useDataTable()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  const columns = useMemo<Column<ChargingSessionWithRelations>[]>(
    () => [
      {
        key: 'city',
        component: BodyExtraSmallSemiBold,
        type: ColumnType.STRING,
        transformData: (session) => {
          if (session.type === 'Home') {
            return t('employee.chargingSessions.at-home')
          }

          // Capitalize first letter of city
          return session.city.charAt(0).toUpperCase() + session.city.slice(1)
        },
      },
      {
        key: 'mspPrice',
        prepend: '€ ',
        component: BodyExtraSmallSemiBold,
        type: ColumnType.STRING,
        transformData: (session) => {
          if (session.type === 'Home') {
            return formatDecimals(session.hcpPrice)
          }

          return formatDecimals(applyVat(session.mspPrice, session.vat))
        },
      },
      {
        key: 'kwh',
        type: ColumnType.STRING,
        component: StLightText,
        transformData: (session) => {
          return (
            <StInfoRow>
              <StIcon icon={['fass', 'bolt']} />
              {session.kwh.toFixed(2)}
              {' kWh'}
            </StInfoRow>
          )
        },
      },
      {
        key: 'duration',
        type: ColumnType.STRING,
        component: StLightText,
        transformData: (session) => {
          const duration = minutesToHoursAndMinutes(session.duration)

          return (
            <StInfoRow>
              <StIcon icon={['fasr', 'clock']} />
              {duration.hours > 0 &&
                `${t('employee.chargingSessions.detail.duration-hours', {
                  hours: duration.hours,
                })} `}
              {t('employee.chargingSessions.detail.duration-minutes', {
                minutes: duration.minutes,
              })}
            </StInfoRow>
          )
        },
      },
    ],
    [t]
  )

  const groupOn = useMemo<Column<ChargingSessionWithRelations>>(() => {
    return {
      key: 'start',
      type: ColumnType.STRING,
      transformData: (data: ChargingSessionWithRelations) => {
        return format(valueToDate(data.start), 'd MMMM', dateFnsLocale)
      },
    }
  }, [dateFnsLocale])

  const handleRowClick = useCallback(
    (dataRow: ChargingSessionWithRelations) => {
      navigate(EmployeeRoutes.Usage, {
        state: {
          usageType: dataRow.chargingPointId ? 'hcp' : 'msp',
        },
      })
    },
    [navigate]
  )

  return (
    <>
      <StTitleRow>
        <BodyLargeSemiBold>
          {t('employee.home.recent_charging_sessions', { count: amount })}
        </BodyLargeSemiBold>
        {!loading && dataCount.itemCount > 0 && (
          <ButtonQuinary
            onClick={() => {
              navigate(EmployeeRoutes.Usage)
            }}
          >
            {t('employee.home.show_all')}
          </ButtonQuinary>
        )}
      </StTitleRow>
      <DataList<ChargingSessionWithRelations>
        columns={columns}
        groupOn={isDesktop ? groupOn : undefined}
        onRowClick={handleRowClick}
        emptyTitle={t('employee.chargingSessions.list.empty-title')}
        emptyFiltersTitle=""
      />
    </>
  )
}

const StTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StLightText = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StInfoRow = styled(BodyExtraSmallRegular)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
