import { useTranslation } from 'react-i18next'

import { LocalStorageKeys } from '../../../config/constants/local-storage-keys'
import { EventType, useTracking } from '../../analytics/hooks/useTracking'

import type { SupportedLanguage } from '../types/Languages'

export const useChangeLanguage = () => {
  const { i18n } = useTranslation()
  const { trackEvent } = useTracking()

  const changeLanguage = (language: SupportedLanguage | string) => {
    if (language !== i18n.language) {
      localStorage.setItem(LocalStorageKeys.SELECTED_LANGUAGE, language)
      i18n.changeLanguage(language)
      trackEvent(EventType.Submit, 'change_language')
    }
  }

  return { changeLanguage }
}
