import { styled } from 'styled-components'

import { Loader } from '../../../components/general/Loader'
import { BodyExtraExtraSmallRegularCss } from '../../../components/typography'

import type React from 'react'

type ElectricityStatisticCardProps = {
  children: React.ReactNode
  description: React.ReactNode
  color?: 'primary' | 'nonary'
  className?: string
  icon?: React.ReactNode
  isLoading?: boolean
}

export function EnergyConsumptionCard({
  children,
  description,
  color = 'primary',
  className,
  icon = null,
  isLoading,
}: ElectricityStatisticCardProps) {
  return (
    <StStatisticCard $color={color} className={className}>
      <>
        {icon}
        <StContent>
          {isLoading ? (
            <StatisticLoaderContainer>
              <Loader size={26} />
            </StatisticLoaderContainer>
          ) : (
            children
          )}
          <StDescription>{description}</StDescription>
        </StContent>
      </>
    </StStatisticCard>
  )
}

const StStatisticCard = styled.div<{
  $color?: ElectricityStatisticCardProps['color']
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};

  background-color: ${({ theme, $color: $variant }) =>
    $variant === 'primary'
      ? theme.theme.colors['primary-3']
      : theme.theme.colors['nonary-9']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StDescription = styled.span`
  ${BodyExtraExtraSmallRegularCss}
`

const StContent = styled.div`
  display: flex;
  flex-direction: column;
`

const StatisticLoaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`
