import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { Loader } from '../../../../components/general/Loader'
import { H4 } from '../../../../components/typography'

import { MobileChip } from './MobileChip'

type MobileUploadLoaderProps = {
  currentStep: number
  totalSteps: number
}

export const MobileUploadLoader = ({
  currentStep,
  totalSteps,
}: MobileUploadLoaderProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Render --
  return (
    <>
      <MobileChip currentStep={currentStep} totalSteps={totalSteps} />
      <StH4>{t('onboarding.mobile-documents.loading')}</StH4>
      <StContainer>
        <Loader />
      </StContainer>
    </>
  )
}

const StContainer = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StH4 = styled(H4)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
