import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { Fractions } from '../../../components/statistics/Fractions'
import {
  formatPowerConsumption,
  statisticsChargingTypeData,
} from '../../utils/statistics'

import type { ChargingSessionStatistics } from '../types/types'

type UsageFractionsProps = {
  statistics?: ChargingSessionStatistics | null
}

export const UsageFractions = ({ statistics }: UsageFractionsProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { homeUsagePercentage, publicUsagePercentage, workUsagePercentage } =
    statisticsChargingTypeData(statistics)

  return (
    <Fractions
      data={[
        {
          title: t('employer.chargingSessions.type.Home'),
          formattedAmount: formatPowerConsumption(
            statistics?.totalUsagePerType?.Home ?? 0
          ),
          width: homeUsagePercentage,
          total: statistics?.totalUsage ?? 0,
          backgroundColor: theme.theme.colors['quaternary-4'],
          icon: ['fass', 'home'],
        },
        {
          title: t('employer.chargingSessions.type.Work'),
          formattedAmount: formatPowerConsumption(
            statistics?.totalUsagePerType?.Work ?? 0
          ),
          width: workUsagePercentage,
          total: statistics?.totalUsage ?? 0,
          backgroundColor: '#FFF8E2',
          icon: ['fass', 'car-building'],
        },
        {
          title: t('employer.chargingSessions.type.Public'),
          formattedAmount: formatPowerConsumption(
            statistics?.totalUsagePerType?.Public ?? 0
          ),
          width: publicUsagePercentage,
          total: statistics?.totalUsage ?? 0,
          icon: ['fass', 'road'],
        },
      ]}
      disabled={!statistics?.totalUsage}
      disabledMessage={t('employer.chargingSessions.stats.no-data')}
    />
  )
}
