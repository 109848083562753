import { useEffect, useState } from 'react'

import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { boundingBoxesContainBoundingBox } from '../lib/boundingBoxesContainBoundingBox'
import { createBBoxAroundPoint } from '../lib/createBBoxAroundPoint'

import type { MapFiltersType } from '../components/MapFilters'
import type { ChargerGeoData, ChargerGeoDataFeatures } from '../types/mapTypes'
import type { BBox } from '@turf/turf'
import type { OptimileChargingPointCluster } from 'types'

type ChargerLocationsResponse = {
  locations: ChargerGeoDataFeatures[]
  clusters: OptimileChargingPointCluster[]
  loading: boolean
}

export const useChargerLocations = (
  location: {
    longitude: number
    latitude: number
  },
  center: {
    longitude: number
    latitude: number
  },
  zoom: number,
  bounds: string,
  filters?: Partial<MapFiltersType>
): ChargerLocationsResponse => {
  // -- State --
  const [locations, setLocations] = useState<ChargerGeoDataFeatures[]>([])
  const [fetchedBoundingBoxes, setFetchedBoundingBoxes] = useState<BBox[]>([])
  const [clusters, setClusters] = useState<
    Record<number, OptimileChargingPointCluster[]>
  >({
    11: [],
    10: [],
    9: [],
    8: [],
    7: [],
    6: [],
    5: [],
    4: [],
    3: [],
    2: [],
    1: [],
    0: [],
  })

  // -- Data --
  const [{ data: initialRequest, loading: loadingPoints }, fetchSmallData] =
    useAuthMemberAxios<ChargerGeoData>(
      {
        url: Urls.chargingLocations,
      },
      {
        manual: true,
      }
    )

  const [{ data: bigRequest, loading: loadingBigData }, fetchBigData] =
    useAuthMemberAxios<ChargerGeoData>(
      {
        url: Urls.chargingLocations,
      },
      {
        manual: true,
      }
    )

  const [{ loading: loadingOptimileMarkers }, getMarkers] = useAuthMemberAxios<
    OptimileChargingPointCluster[]
  >(
    {
      url: Urls.chargingMarkers,
    },
    {
      manual: true,
    }
  )

  // -- Functions --
  const mergeChargerData = (data: ChargerGeoDataFeatures[]) => {
    // merge two arryas of chargers and remove duplicates
    const merged = [...locations, ...data]
    const unique = merged.filter(
      (item, index) =>
        merged.findIndex(
          (index_) =>
            index_.geometry.coordinates[0] === item.geometry.coordinates[0] &&
            index_.geometry.coordinates[1] === item.geometry.coordinates[1]
        ) === index
    )
    setLocations(unique)
  }

  const alreadyFetched = (bbox: BBox) => {
    return boundingBoxesContainBoundingBox(fetchedBoundingBoxes, bbox)
  }

  const getClustersForZoom = async (zoom: number, bounds: string) => {
    const { data } = await getMarkers({
      params: {
        zoom,
        bounds,
        // maxPrice: filters?.maxPrice,
        speed: filters?.speed,
        onlyAvailable: filters?.onlyAvailable,
      },
    })

    setClusters((previous) => ({
      ...previous,
      [zoom]: data,
    }))
  }

  const showClusters = (zoom: number) => {
    if (zoom === 5) {
      return clusters[6]
    }

    if (zoom === 4) {
      return clusters[2]
    }

    if (zoom === 3) {
      return clusters[2]
    }

    if (zoom === 0) {
      return clusters[1]
    }

    return clusters[zoom] || []
  }

  const intialFetch = async () => {
    const smallBBox = createBBoxAroundPoint(location, 10)
    const bigBBox = createBBoxAroundPoint(location, 25)
    const giant = createBBoxAroundPoint(location, 50)

    await fetchSmallData({
      params: {
        bounds: [smallBBox[1], smallBBox[0], smallBBox[3], smallBBox[2]].join(
          ','
        ),
        // maxPrice: filters?.maxPrice,
        speed: filters?.speed,
        onlyAvailable: filters?.onlyAvailable,
      },
    })

    fetchBigData({
      params: {
        bounds: [bigBBox[1], bigBBox[0], bigBBox[3], bigBBox[2]].join(','),
        // maxPrice: filters?.maxPrice,
        speed: filters?.speed,
        onlyAvailable: filters?.onlyAvailable,
      },
    })

    setFetchedBoundingBoxes([smallBBox, bigBBox])
    getClustersForZoom(
      11,
      [bigBBox[1], bigBBox[0], bigBBox[3], bigBBox[2]].join(',')
    )
    getClustersForZoom(10, [giant[1], giant[0], giant[3], giant[2]].join(','))
    getClustersForZoom(9, [giant[1], giant[0], giant[3], giant[2]].join(','))
  }

  // -- Effects --
  useEffect(() => {
    intialFetch()
  }, [])

  useEffect(() => {
    // Only listen to filter changes if the inital data is loaded
    if (initialRequest) {
      setLocations([])
      setClusters({
        11: [],
        10: [],
        9: [],
        8: [],
        7: [],
        6: [],
        5: [],
        4: [],
        3: [],
        2: [],
        1: [],
        0: [],
      })
      setFetchedBoundingBoxes([])
      intialFetch()
    }
  }, [JSON.stringify(filters)])

  useEffect(() => {
    if (initialRequest) {
      mergeChargerData(initialRequest.features)
    }
  }, [initialRequest])

  useEffect(() => {
    if (bigRequest) {
      mergeChargerData(bigRequest.features)
    }
  }, [bigRequest])

  useEffect(() => {
    if (zoom > 11 && locations.length > 0) {
      const newBox = createBBoxAroundPoint(center, 10)

      // Only fetch the data if it's not already fetched
      if (!alreadyFetched(newBox)) {
        setFetchedBoundingBoxes((previous) => [...previous, newBox])
        fetchSmallData({
          params: {
            bounds: [newBox[1], newBox[0], newBox[3], newBox[2]].join(','),
          },
        })
      }
    }
  }, [center.latitude, center.longitude, zoom])

  useEffect(() => {
    if (bounds.length === 0) return

    switch (zoom) {
      case 0: {
        clusters[1].length === 0 && getClustersForZoom(1, bounds)

        break
      }
      case 5: {
        getClustersForZoom(6, bounds)

        break
      }
      case 4:
      case 3: {
        clusters[2].length === 0 && getClustersForZoom(2, bounds)

        break
      }
      default: {
        if (zoom < 11) {
          getClustersForZoom(zoom, bounds)

          // Only prefetch the next zoom level if there are no clusters for that level yet
          if (zoom <= 10) {
            clusters[zoom + 1].length === 0 &&
              getClustersForZoom(zoom + 1, bounds)
          }
        }
      }
    }
  }, [zoom, bounds])

  return {
    locations: locations || [],
    clusters: showClusters(zoom),
    loading: loadingPoints || loadingBigData || loadingOptimileMarkers,
  }
}
