import { Auth0Provider } from '@auth0/auth0-react'
import { Capacitor } from '@capacitor/core'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { EmployeeRoutes } from '../../../routing/routes'
import { inviteIdAtom } from '../../core/recoil/atoms'

import type { AppState } from '@auth0/auth0-react'
import type { PropsWithChildren } from 'react'

type AuthenticationProviderProps = PropsWithChildren

/** Same as the <Auth0Provider /> but already configured to work with capacitor */
export const AuthenticationProvider = ({
  children,
}: AuthenticationProviderProps) => {
  const setInviteId = useSetRecoilState(inviteIdAtom)

  const navigate = useNavigate()

  const onRedirectCallback = (appState?: AppState) => {
    if (appState?.inviteId) {
      setInviteId(appState.inviteId)
      return navigate(EmployeeRoutes.Onboarding)
    }

    if (appState?.returnTo) {
      return navigate(appState.returnTo)
    }

    navigate(EmployeeRoutes.Root)
  }

  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      useRefreshTokensFallback={false}
      authorizationParams={{
        redirect_uri: Capacitor.isNativePlatform()
          ? `${import.meta.env.VITE_APP_ID}://${
              import.meta.env.VITE_AUTH0_DOMAIN
            }/capacitor/${import.meta.env.VITE_APP_ID}`
          : window.location.origin,
      }}
      cacheLocation="localstorage"
      onRedirectCallback={
        Capacitor.isNativePlatform() ? undefined : onRedirectCallback
      }
    >
      {children}
    </Auth0Provider>
  )
}
