import { Trans, useTranslation } from 'react-i18next'

import { useAuthentication } from '../../authentication/hooks/useAuthentication'

import { MobileBlockedSvg } from './assets/MobileBlockedSvg'
import { FullScreenError } from './FullScreenError'

export const MobileBlocked = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { logout } = useAuthentication()

  // -- Render --
  return (
    <FullScreenError
      onClick={() => logout()}
      title={t('admin.mobile-blocked.title')}
      subTitle={
        <Trans
          t={t}
          i18nKey="admin.mobile-blocked.description"
          components={{
            br: (
              <>
                <br />
                <br />
              </>
            ),
          }}
        />
      }
      buttonText={t('admin.mobile-blocked.logout')}
      svg={<MobileBlockedSvg />}
    />
  )
}
