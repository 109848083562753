import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { BodyMediumRegular, H4 } from '../../../../components/typography'
import { UploadSvg } from '../../assets/UploadSvg'
import { useOnboarding } from '../../hooks/useOnboarding'

export const ContinueInTheAppStep = () => {
  // -- Hooks --
  const { handleNext, values } = useOnboarding()
  const { t } = useTranslation()

  // -- Handlers --
  const handleConfirm = () => {
    handleNext(values)
  }

  // -- Render --
  return (
    <StSvgContainer>
      <UploadSvg />

      <H4>{t('onboarding.continue-desktop.title')}</H4>

      <BodyMediumRegular>
        {t('onboarding.continue-desktop.body1')}
      </BodyMediumRegular>
      <BodyMediumRegular>
        {t('onboarding.continue-desktop.body2')}
      </BodyMediumRegular>

      <FloatingButtonContainer>
        <ButtonPrimary onClick={handleConfirm}>
          {t('onboarding.continue-desktop.confirm')}
        </ButtonPrimary>
      </FloatingButtonContainer>
    </StSvgContainer>
  )
}

const StSvgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`
