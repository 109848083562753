import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

import { UnauthenticatedRoutes } from '../../../routing/routes'
import logo from '../assets/vectors/logo.svg'

type NavigationLogoProps = {
  isLink?: boolean
}

export const NavigationLogo = ({ isLink = true }: NavigationLogoProps) => {
  if (isLink) {
    return (
      <StLogoLink to={UnauthenticatedRoutes.Root}>
        <img src={logo} alt="Blossom Logo" />
      </StLogoLink>
    )
  }

  return (
    <StLogoWrapper>
      <img src={logo} alt="Blossom Logo" />
    </StLogoWrapper>
  )
}

const StLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StLogoLink = styled(Link)`
  display: flex;
  justify-content: center;
`
