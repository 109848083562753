import { useContext } from 'react'

import { onboardingContext } from '../screens/OnboardingScreen'

export const useOnboarding = () => {
  const onboarding = useContext(onboardingContext)

  if (!onboarding) {
    throw new Error(
      'useOnboarding needs to be used inside the onboarding provider!'
    )
  }

  return onboarding
}
