import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useMember } from '../../../../member/hooks/useMember'
import { useHems } from '../../hooks/useHems'
import { ElectricityContract } from '../../types/enums'

import type { CurrentStep } from '../../../../components/navigation/Wizard'

export type HemsConfigutrationData = {
  pv_installed: boolean
  bat_installed: boolean
  hp_installed: boolean
  hpb_installed: boolean
  ac_installed: boolean
  peak_solar_capacity: number
  electricity_contract: ElectricityContract
  elek_import_price: number
  elek_export_price: number
}

export enum HemsOnboardingSteps {
  // Preparation
  Fluvius = 'fluvius',
  P1Port = 'p1-port',

  // Connection
  Bluetooth = 'bluetooth',
  DongleButton = 'dongle-button',
  DongleConnection = 'dongle-connection',
  MacAddress = 'mac-address',
  ConnectionSelection = 'connection-selection',

  // Wifi-flow
  WifiNetworkSelection = 'network-selection',
  WifiPassword = 'wifi-password',
  WifiProvisioning = 'wifi-provisioning',

  // UTP-flow
  UtpConnection = 'utp-connection',
  UtpProvisioning = 'utp-provisioning',

  ProvisioningSuccess = 'provisioning-success',

  // Configuration
  Installations = 'Installations',
  Contract = 'Contract',
  EnergyImport = 'EnergyImport',

  Confirmation = 'Confirmation',
}

type HemsOnboardingConfigOption = {
  next: (alernativeRoute?: boolean) => HemsOnboardingSteps
  previous: () => HemsOnboardingSteps
}

const HemsOnboardingConfig: Record<
  HemsOnboardingSteps,
  HemsOnboardingConfigOption
> = {
  [HemsOnboardingSteps.Fluvius]: {
    next: () => HemsOnboardingSteps.P1Port,
    previous: () => HemsOnboardingSteps.Fluvius,
  },
  [HemsOnboardingSteps.P1Port]: {
    next: () => HemsOnboardingSteps.Bluetooth,
    previous: () => HemsOnboardingSteps.Fluvius,
  },
  [HemsOnboardingSteps.Bluetooth]: {
    next: () => HemsOnboardingSteps.DongleButton,
    previous: () => HemsOnboardingSteps.P1Port,
  },
  [HemsOnboardingSteps.DongleButton]: {
    next: () => HemsOnboardingSteps.DongleConnection,
    previous: () => HemsOnboardingSteps.Bluetooth,
  },
  [HemsOnboardingSteps.DongleConnection]: {
    next: (alternative) =>
      alternative
        ? HemsOnboardingSteps.ConnectionSelection
        : HemsOnboardingSteps.MacAddress,
    previous: () => HemsOnboardingSteps.DongleButton,
  },
  [HemsOnboardingSteps.MacAddress]: {
    next: () => HemsOnboardingSteps.ConnectionSelection,
    previous: () => HemsOnboardingSteps.DongleConnection,
  },
  [HemsOnboardingSteps.ConnectionSelection]: {
    next: (alternative) =>
      alternative
        ? HemsOnboardingSteps.UtpConnection
        : HemsOnboardingSteps.WifiNetworkSelection,
    previous: () => HemsOnboardingSteps.MacAddress,
  },
  [HemsOnboardingSteps.WifiNetworkSelection]: {
    next: (unsecureNetWork) =>
      unsecureNetWork
        ? HemsOnboardingSteps.WifiProvisioning
        : HemsOnboardingSteps.WifiPassword,
    previous: () => HemsOnboardingSteps.ConnectionSelection,
  },
  [HemsOnboardingSteps.WifiPassword]: {
    next: () => HemsOnboardingSteps.WifiProvisioning,
    previous: () => HemsOnboardingSteps.WifiNetworkSelection,
  },
  [HemsOnboardingSteps.WifiProvisioning]: {
    next: () => HemsOnboardingSteps.ProvisioningSuccess,
    previous: () => HemsOnboardingSteps.WifiPassword,
  },
  [HemsOnboardingSteps.UtpConnection]: {
    next: () => HemsOnboardingSteps.UtpProvisioning,
    previous: () => HemsOnboardingSteps.ConnectionSelection,
  },
  [HemsOnboardingSteps.UtpProvisioning]: {
    next: () => HemsOnboardingSteps.ProvisioningSuccess,
    previous: () => HemsOnboardingSteps.UtpConnection,
  },
  [HemsOnboardingSteps.ProvisioningSuccess]: {
    next: () => HemsOnboardingSteps.Installations,
    previous: () => HemsOnboardingSteps.ProvisioningSuccess,
  },
  [HemsOnboardingSteps.Installations]: {
    next: () => HemsOnboardingSteps.Contract,
    previous: () => HemsOnboardingSteps.Installations,
  },
  [HemsOnboardingSteps.Contract]: {
    next: (alternative?: boolean) =>
      alternative
        ? HemsOnboardingSteps.Confirmation
        : HemsOnboardingSteps.EnergyImport,
    previous: () => HemsOnboardingSteps.Installations,
  },
  [HemsOnboardingSteps.EnergyImport]: {
    next: () => HemsOnboardingSteps.Confirmation,
    previous: () => HemsOnboardingSteps.Contract,
  },
  [HemsOnboardingSteps.Confirmation]: {
    next: () => HemsOnboardingSteps.Confirmation,
    previous: () => HemsOnboardingSteps.Confirmation,
  },
}

const baseHeaderStates = {
  prepare: {
    key: 'hems-prepare',
    order: 1,
  },
  connect: {
    key: 'hems-connect',
    order: 2,
  },
  configure: {
    key: 'hems-configure',
    order: 3,
  },
}

const headerStates: Record<
  HemsOnboardingSteps,
  {
    state: CurrentStep
    cantGoBack?: boolean
    cantCancel?: boolean
  }
> = {
  [HemsOnboardingSteps.Fluvius]: {
    state: {
      ...baseHeaderStates.prepare,
      subStep: {
        step: 1,
        of: 2,
      },
    },
    cantGoBack: true,
  },
  [HemsOnboardingSteps.P1Port]: {
    state: {
      ...baseHeaderStates.prepare,
      subStep: {
        step: 2,
        of: 2,
      },
    },
  },
  [HemsOnboardingSteps.Bluetooth]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 1,
        of: 5,
      },
    },
  },
  [HemsOnboardingSteps.DongleButton]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 2,
        of: 5,
      },
    },
  },
  [HemsOnboardingSteps.DongleConnection]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 3,
        of: 5,
      },
    },
  },
  [HemsOnboardingSteps.MacAddress]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 4,
        of: 5,
      },
    },
  },
  [HemsOnboardingSteps.ConnectionSelection]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 5,
        of: 5,
      },
    },
  },
  [HemsOnboardingSteps.WifiNetworkSelection]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 5,
        of: 5,
      },
    },
  },
  [HemsOnboardingSteps.WifiPassword]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 5,
        of: 5,
      },
    },
  },
  [HemsOnboardingSteps.WifiProvisioning]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 5,
        of: 5,
      },
    },
  },
  [HemsOnboardingSteps.UtpConnection]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 5,
        of: 5,
      },
    },
  },
  [HemsOnboardingSteps.UtpProvisioning]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 5,
        of: 5,
      },
    },
  },
  [HemsOnboardingSteps.ProvisioningSuccess]: {
    state: {
      ...baseHeaderStates.connect,
      subStep: {
        step: 5,
        of: 5,
      },
    },
    cantGoBack: true,
  },
  [HemsOnboardingSteps.Installations]: {
    state: {
      ...baseHeaderStates.configure,
      subStep: {
        step: 1,
        of: 4,
      },
    },
    cantGoBack: true,
  },
  [HemsOnboardingSteps.Contract]: {
    state: {
      ...baseHeaderStates.configure,
      subStep: {
        step: 2,
        of: 4,
      },
    },
  },
  [HemsOnboardingSteps.EnergyImport]: {
    state: {
      ...baseHeaderStates.configure,
      subStep: {
        step: 3,
        of: 4,
      },
    },
  },
  [HemsOnboardingSteps.Confirmation]: {
    state: {
      ...baseHeaderStates.configure,
      subStep: {
        step: 4,
        of: 4,
      },
    },
    cantGoBack: true,
  },
}

export const useHemsOnboardingState = () => {
  // -- Hooks --
  const { currentMember } = useMember()
  const { installation } = useHems()

  // -- State --
  const [formState, setFormState] = useSearchParams()
  const [hemsConfigurationData, setHemsConfigurationData] =
    useState<HemsConfigutrationData>({
      ac_installed: installation?.ac_installed || false,
      bat_installed: installation?.bat_installed || false,
      hp_installed: installation?.hp_installed || false,
      pv_installed: installation?.pv_installed || false,
      hpb_installed: installation?.hpb_installed || false,
      peak_solar_capacity: installation?.peak_solar_capacity || 0,
      electricity_contract:
        installation?.electricity_contract || ElectricityContract.variable,
      elek_export_price: installation?.elek_export_price || 0,
      elek_import_price: installation?.elek_import_price || 0,
    })

  // -- Vars --
  const currentStep =
    (formState.get('step') as HemsOnboardingSteps) ||
    HemsOnboardingSteps.Fluvius

  // -- Effects --
  useEffect(() => {
    if (currentMember.hemsOnboardingStatus === 'Preparation') {
      return setFormState({ step: HemsOnboardingSteps.Fluvius })
    }

    if (currentMember.hemsOnboardingStatus === 'Connection') {
      return setFormState({ step: HemsOnboardingSteps.Bluetooth })
    }

    if (currentMember.hemsOnboardingStatus === 'Configuration') {
      return setFormState({ step: HemsOnboardingSteps.Installations })
    }
  }, [])

  // -- Handlers --
  const handleNext = (alernativeRoute?: boolean) => {
    const nextStep = HemsOnboardingConfig[currentStep].next(alernativeRoute)

    setFormState({ step: nextStep })
  }

  const handlePrevious = () => {
    const previousStep = HemsOnboardingConfig[currentStep].previous()

    setFormState({ step: previousStep })
  }

  const setStep = (step: string) => {
    setFormState({ step })
  }

  return {
    currentStep,
    handleNext,
    handlePrevious,
    headerState: headerStates[currentStep],
    setStep,
    hemsConfigurationData,
    setHemsConfigurationData,
  }
}
