import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { EmployeeRoutes } from '../../../../routing/routes'
import { useAuthentication } from '../../../authentication/hooks/useAuthentication'
import { useUser } from '../../../user/hooks/useUser'

import { OnboardingSteps } from './useOnboardingState'

export const useStatusCheck = (currentStep: string) => {
  // -- State --
  const [, setFormState] = useSearchParams()

  // -- Hooks --
  const { user } = useUser()
  const navigate = useNavigate()
  const { user: auth0User } = useAuthentication()

  // -- Effects --
  useEffect(() => {
    switch (user.hcpStatus.status) {
      case 'OnboardingRejected': {
        if (currentStep !== OnboardingSteps.Rejected) {
          setFormState({ step: OnboardingSteps.Rejected })
        }
        break
      }

      case 'OnboardingCompleted': {
        if (auth0User?.email_verified) {
          return navigate(EmployeeRoutes.Root, { replace: true })
        }

        break
      }

      // If the user is not applicable, check the msp onboarding status
      case 'Inapplicable': {
        const mspOnboardingSteps = [
          OnboardingSteps.Welcome,
          OnboardingSteps.Intercom,
          OnboardingSteps.VerifyEmail,
          OnboardingSteps.PersonalData,
        ]

        if (
          user.members[0].mspStatus === 'Invited' &&
          !mspOnboardingSteps.includes(currentStep as OnboardingSteps)
        ) {
          setFormState({ step: OnboardingSteps.PersonalData })
          // If the onboaring has already been completed, redirect to the home screen
        } else if (user.members[0].mspStatus === 'Requested') {
          navigate(EmployeeRoutes.Root, { replace: true })
        }

        break
      }

      default: {
        break
      }
    }
  }, [currentStep])
}
