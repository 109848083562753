import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifiFair as fasdWifiFair } from '@fortawesome/sharp-duotone-solid-svg-icons/faWifiFair'
import { faWifiStrong as fasdWifiStrong } from '@fortawesome/sharp-duotone-solid-svg-icons/faWifiStrong'
import { faWifiWeak as fasdWifiWeak } from '@fortawesome/sharp-duotone-solid-svg-icons/faWifiWeak'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import {
  BodyExtraSmallRegular,
  BodyMediumSemiBold,
  BodySmallRegular,
  H4,
} from '../../../../components/typography'
import { useHemsFlow } from '../hooks/useHemsFlow'
import { HemsOnboardingSteps } from '../hooks/useHemsOnboardingState'
import { findWifiNetworks } from '../lib/esp-provisioning'

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import type { ESPNetwork } from '@general-galactic/capacitor-esp-idf-provisioning'

export const WifiNetworkSelectionStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { deviceName, setSelectedNetwork, flowNavigation } = useHemsFlow()

  // -- State --
  const [networks, setNetworks] = useState<ESPNetwork[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [tries, setTries] = useState<number>(0)

  // -- Handlers --
  const handleSelectNetwork = (network: ESPNetwork) => {
    setSelectedNetwork(network)
    // If the network is open, we can skip the password step
    flowNavigation.next(network.auth === 'open')
  }

  const handleFetchNetworks = async () => {
    setTries((previous) => previous + 1)

    setLoading(true)
    const fetchedNetworks = await findWifiNetworks(deviceName)
    setLoading(false)

    setNetworks(fetchedNetworks)
  }

  const handleUseUtp = () => {
    flowNavigation.setStep(HemsOnboardingSteps.UtpConnection)
  }

  // -- Effects --
  useEffect(() => {
    handleFetchNetworks()
  }, [])

  // -- Render --
  return (
    <StContainer>
      <H4>{t('employee.hems.dongle-onboarding.network-selection.title')}</H4>
      <StNetWorkHeader>
        {t('employee.hems.dongle-onboarding.network-selection.networks')}
        {loading && <StSpinner />}
      </StNetWorkHeader>

      {networks?.map((network) => (
        <StNetwork
          key={network.ssid}
          onClick={() => handleSelectNetwork(network)}
        >
          <BodySmallRegular>{network.ssid}</BodySmallRegular>
          <IconContainer>
            {network.auth !== 'open' && network.auth !== 'unknown' && (
              <FontAwesomeIcon icon={['fass', 'lock']} />
            )}

            {network.rssi >= -60 ? (
              <FontAwesomeIcon icon={fasdWifiStrong as IconDefinition} />
            ) : network.rssi >= -80 ? (
              <FontAwesomeIcon icon={fasdWifiFair as IconDefinition} />
            ) : (
              <FontAwesomeIcon icon={fasdWifiWeak as IconDefinition} />
            )}
          </IconContainer>
        </StNetwork>
      ))}

      {loading && (
        <StNetworkText>
          {t('employee.hems.dongle-onboarding.network-selection.loading')}
        </StNetworkText>
      )}

      {!loading &&
        networks?.length === 0 &&
        (tries >= 5 ? (
          <StNetworkText>
            {t(
              'employee.hems.dongle-onboarding.network-selection.no-networks-error'
            )}
          </StNetworkText>
        ) : (
          <StNetworkText>
            {t(
              'employee.hems.dongle-onboarding.network-selection.no-networks-warning'
            )}
          </StNetworkText>
        ))}

      <StInfo>
        {t('employee.hems.dongle-onboarding.network-selection.info')}
      </StInfo>

      <FloatingButtonContainer>
        {tries >= 5 ? (
          <>
            <ButtonTertiary onClick={flowNavigation.close}>
              {t('employee.hems.dongle-onboarding.network-selection.close')}
            </ButtonTertiary>
            <ButtonPrimary onClick={handleUseUtp}>
              {t('employee.hems.dongle-onboarding.network-selection.try-utp')}
            </ButtonPrimary>
          </>
        ) : tries > 1 ? (
          <ButtonTertiary onClick={handleFetchNetworks} loading={loading}>
            {t('employee.hems.dongle-onboarding.network-selection.scan-again')}
          </ButtonTertiary>
        ) : null}
      </FloatingButtonContainer>
    </StContainer>
  )
}

const StContainer = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  h4 {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StNetWorkHeader = styled(BodyMediumSemiBold)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  border-bottom: 1px solid ${({ theme }) => theme.theme.colors['nonary-8']};
`

const StSpinner = styled.span`
  width: 16px;
  height: 16px;
  border: 2px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space2};

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const StNetwork = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.theme.colors['nonary-8']};

  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space5}`};
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  font-size: 14px;
`

const StNetworkText = styled(BodySmallRegular)`
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space5}`};
`

const StInfo = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.colors['nonary-2']};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
