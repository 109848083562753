import { Capacitor } from '@capacitor/core'
import { DotLottiePlayer, PlayerEvents } from '@dotlottie/react-player'
import { Transition } from '@headlessui/react'
import { useEffect, useRef } from 'react'
import { styled } from 'styled-components'

import { useSplashScreen } from '../../authentication/hooks/useSplashScreen'

export const SplashScreen = () => {
  // -- Hooks --
  const lottiePlayer = useRef(null)
  const { shouldHideSplashScreen, appIsReady, setShow, setAnimationFinished } =
    useSplashScreen()

  // -- Effects --
  // This effect is used here so it is triggered after all other splash screen logic is triggered in the provider
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      setShow(true)

      // If the splash screen had already been closed, hide the splash screen again
      // this will allow it to play the animation
      if (appIsReady) {
        setShow(false)
      }
    }
  }, [])

  return (
    <Transition
      show={!shouldHideSplashScreen}
      leave="splash-leave"
      leaveFrom="splash-leave-from"
      leaveTo="splash-leave-to"
      enter="splash-enter"
      enterFrom="splash-enter-from"
      enterTo="splash-enter-to"
    >
      <SplashScreenContainer>
        <PlayerWrapper>
          <DotLottiePlayer
            ref={lottiePlayer}
            autoplay
            src="/assets/logo-animation.lottie"
            speed={1.5}
            loop={false}
            onEvent={(event: PlayerEvents) => {
              if (event === PlayerEvents.Complete) {
                setAnimationFinished(true)
              }
            }}
          />
        </PlayerWrapper>
      </SplashScreenContainer>
    </Transition>
  )
}

const SplashScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: grid;
  place-items: center;

  background-color: ${({ theme }) => theme.theme.colors['primary-1']};
  z-index: 1000000000;
`

const PlayerWrapper = styled.div`
  max-width: 250px;
`
