import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { Dialog } from '../../../components/general/Dialog'
import { FullPageLoader } from '../../../components/general/FullPageLoader'
import { IntercomCard } from '../../../components/intercom/IntercomCard'
import { ContentContainer } from '../../../components/page-layout'
import { QuoteCard } from '../../../components/quote/QuoteCard'
import {
  BodyLargeSemiBold,
  BodySmallRegular,
} from '../../../components/typography'
import { useMember } from '../../../member/hooks/useMember'
import { useQuote } from '../../../quotes/hooks/useQuote'
import { useUser } from '../../../user/hooks/useUser'

export const EmployeeQuoteScreen = () => {
  // -- State --
  const [dialogOpen, setDialogOpen] = useState(false)
  const [approved, setApproved] = useState(false)

  // -- Hooks --
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentMember } = useMember()
  const { refetch } = useUser()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { trackEvent } = useTracking()

  // -- Data --
  const { quote, loading, error, approveLoading, approveQuote } = useQuote(
    currentMember.id
  )

  // -- Handlers --
  const handleOpenApprovalDialog = () => {
    trackEvent(EventType.Click, 'approve_quote')
    setDialogOpen(true)
  }

  const handleApproveQuote = async () => {
    trackEvent(EventType.Click, 'approve_quote_confirm')

    try {
      setDialogOpen(false)
      setApproved(true)
      await approveQuote()
      await refetch()
    } catch (error) {
      toast.error(t('employee.quote.approve.error'))
      console.error(error)

      setApproved(false)
    }

    // This fixes some weird state issues on the home screen
    setTimeout(() => {
      toast.success(t('employee.quote.approve.success'))
      navigate(EmployeeRoutes.Root, { replace: true })
    }, 200)
  }

  if (loading) return <FullPageLoader withNavigation={false} />

  if (!quote || error) {
    toast.error(t('employee.quote.error'))
    return <Navigate to={EmployeeRoutes.Root} replace />
  }

  if (quote.Approved_By_Employee) {
    return <Navigate to={EmployeeRoutes.Root} replace />
  }

  // -- Render --
  return (
    <>
      <ContentContainer>
        <DetailPageHeader
          title={t('employee.quote.title')}
          onBack={isDesktop ? undefined : () => navigate(EmployeeRoutes.Root)}
        />

        <StFlexContainer>
          <QuoteCard
            fullName={currentMember.user.fullName || ''}
            date={new Date(quote.Created_Time)}
            quote={quote}
          />

          <StCard>
            <StCtaContainer>
              <BodyLargeSemiBold>
                {t('employee.quote.approve.title')}
              </BodyLargeSemiBold>
              <StDescription>
                {t('employee.quote.approve.description')}
              </StDescription>
              <ButtonPrimary
                compact={isDesktop}
                loading={approveLoading}
                disabled={approved}
                onClick={handleOpenApprovalDialog}
                size="md"
              >
                {t('employee.quote.approve.button')}
              </ButtonPrimary>
            </StCtaContainer>

            <IntercomCard />
          </StCard>
        </StFlexContainer>
      </ContentContainer>
      <Dialog
        title={t('admin.employee-detail.quote.approve-dialog.title')}
        description={t(
          'admin.employee-detail.quote.approve-dialog.description'
        )}
        onClickCloseButton={() => setDialogOpen(false)}
        open={dialogOpen}
        onClickPrimaryButton={handleApproveQuote}
        primaryButtonText={t('admin.employee-detail.quote.approve.button')}
      />
    </>
  )
}

const StFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  @media ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
    padding: ${({ theme }) => theme.UI.SpacingPx.Space7};
  }
`

const StDescription = styled(BodySmallRegular)`
  margin-top: 10px;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StCtaContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};

  @media ${breakpoints.desktop} {
    border: none;
    padding: 0;
  }
`
