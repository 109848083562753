import { library } from '@fortawesome/fontawesome-svg-core'
import { faTriangle as fasTriangle } from '@fortawesome/pro-solid-svg-icons/faTriangle'
import { faHome as faslHome } from '@fortawesome/sharp-light-svg-icons'
import { faBolt as faslBolt } from '@fortawesome/sharp-light-svg-icons/faBolt'
import { faCar as faslCar } from '@fortawesome/sharp-light-svg-icons/faCar'
import { faCarBolt as faslCarBolt } from '@fortawesome/sharp-light-svg-icons/faCarBolt'
import { faCircleUser as faslCircleUser } from '@fortawesome/sharp-light-svg-icons/faCircleUser'
import { faHouse as faslHouse } from '@fortawesome/sharp-light-svg-icons/faHouse'
import { faLocationDot as faslLocationDot } from '@fortawesome/sharp-light-svg-icons/faLocationDot'
import { faPlug as faslPlug } from '@fortawesome/sharp-light-svg-icons/faPlug'
import { faXmark as faslXmark } from '@fortawesome/sharp-light-svg-icons/faXmark'
import { faArrowDownLong as fasrArrowDownLong } from '@fortawesome/sharp-regular-svg-icons/faArrowDownLong'
import { faArrowDownToLine as fasrArrowDownToLine } from '@fortawesome/sharp-regular-svg-icons/faArrowDownToLine'
import { faArrowLeft as fasrArrowLeft } from '@fortawesome/sharp-regular-svg-icons/faArrowLeft'
import { faArrowRight as fasrArrowRight } from '@fortawesome/sharp-regular-svg-icons/faArrowRight'
import { faArrowRightFromBracket as fasrArrowRightFromBracket } from '@fortawesome/sharp-regular-svg-icons/faArrowRightFromBracket'
import { faArrowUpLong as fasrArrowUpLong } from '@fortawesome/sharp-regular-svg-icons/faArrowUpLong'
import { faArrowUpRightFromSquare as fasrArrowUpRightFromSquare } from '@fortawesome/sharp-regular-svg-icons/faArrowUpRightFromSquare'
import { faBan as fasrBan } from '@fortawesome/sharp-regular-svg-icons/faBan'
import { faBlock as fasrBlock } from '@fortawesome/sharp-regular-svg-icons/faBlock'
import { faBlockBrick as fasrBlockBrick } from '@fortawesome/sharp-regular-svg-icons/faBlockBrick'
import { faBolt as fasrBolt } from '@fortawesome/sharp-regular-svg-icons/faBolt'
import { faBoltSlash as fasrBoltSlash } from '@fortawesome/sharp-regular-svg-icons/faBoltSlash'
import { faBrightness as fasrBrightness } from '@fortawesome/sharp-regular-svg-icons/faBrightness'
import { faBugs as fasrBugs } from '@fortawesome/sharp-regular-svg-icons/faBugs'
import { faCalendar as fasrCalendar } from '@fortawesome/sharp-regular-svg-icons/faCalendar'
import { faCarGarage as fasrCarGarage } from '@fortawesome/sharp-regular-svg-icons/faCarGarage'
import { faChargingStation as fasrChargingStation } from '@fortawesome/sharp-regular-svg-icons/faChargingStation'
import { faCheck as fasrCheck } from '@fortawesome/sharp-regular-svg-icons/faCheck'
import { faChevronDown as fasrChevronDown } from '@fortawesome/sharp-regular-svg-icons/faChevronDown'
import { faChevronLeft as fasrChevronLeft } from '@fortawesome/sharp-regular-svg-icons/faChevronLeft'
import { faChevronRight as fasrChevronRight } from '@fortawesome/sharp-regular-svg-icons/faChevronRight'
import { faChevronUp as fasrChevronUp } from '@fortawesome/sharp-regular-svg-icons/faChevronUp'
import { faCircleExclamation as fasrCircleExclamation } from '@fortawesome/sharp-regular-svg-icons/faCircleExclamation'
import { faCircleInfo as fasrCircleInfo } from '@fortawesome/sharp-regular-svg-icons/faCircleInfo'
import { faCircleUser as fasrCircleUser } from '@fortawesome/sharp-regular-svg-icons/faCircleUser'
import { faClock as fasrClock } from '@fortawesome/sharp-regular-svg-icons/faClock'
import { faCog as fasrCog } from '@fortawesome/sharp-regular-svg-icons/faCog'
import { faCreditCardBlank as fasrCreditCardBlank } from '@fortawesome/sharp-regular-svg-icons/faCreditCardBlank'
import { faDash as fasrDash } from '@fortawesome/sharp-regular-svg-icons/faDash'
import { faEllipsis as fasrEllipsis } from '@fortawesome/sharp-regular-svg-icons/faEllipsis'
import { faEnvelope as fasrEnvelope } from '@fortawesome/sharp-regular-svg-icons/faEnvelope'
import { faEuroSign as fasrEuroSign } from '@fortawesome/sharp-regular-svg-icons/faEuroSign'
import { faEye as fasrEye } from '@fortawesome/sharp-regular-svg-icons/faEye'
import { faEyeSlash as fasrEyeSlash } from '@fortawesome/sharp-regular-svg-icons/faEyeSlash'
import { faFile as fasrFile } from '@fortawesome/sharp-regular-svg-icons/faFile'
import { faFileArrowDown as fasrFileArrowDown } from '@fortawesome/sharp-regular-svg-icons/faFileArrowDown'
import { faFileCheck as fasrFileCheck } from '@fortawesome/sharp-regular-svg-icons/faFileCheck'
import { faFileUpload as fasrFileUpload } from '@fortawesome/sharp-regular-svg-icons/faFileUpload'
import { faFloppyDisk as fasrFloppyDisk } from '@fortawesome/sharp-regular-svg-icons/faFloppyDisk'
import { faGarage as fasrGarage } from '@fortawesome/sharp-regular-svg-icons/faGarage'
import { faGlobe as fasrGlobe } from '@fortawesome/sharp-regular-svg-icons/faGlobe'
import { faHourglass as fasrHourglass } from '@fortawesome/sharp-regular-svg-icons/faHourglass'
import { faHouseBlank as fasrHouseBlank } from '@fortawesome/sharp-regular-svg-icons/faHouseBlank'
import { faHouseTree as fasrHouseTree } from '@fortawesome/sharp-regular-svg-icons/faHouseTree'
import { faImageLandscape as fasrImageLandscape } from '@fortawesome/sharp-regular-svg-icons/faImageLandscape'
import { faLock as fasrLock } from '@fortawesome/sharp-regular-svg-icons/faLock'
import { faMagnifyingGlass as fasrMagnifyingGlass } from '@fortawesome/sharp-regular-svg-icons/faMagnifyingGlass'
import { faPen as fasrPen } from '@fortawesome/sharp-regular-svg-icons/faPen'
import { faPlug as fasrPlug } from '@fortawesome/sharp-regular-svg-icons/faPlug'
import { faSend as fasrSend } from '@fortawesome/sharp-regular-svg-icons/faSend'
import { faSliders as fasrSliders } from '@fortawesome/sharp-regular-svg-icons/faSliders'
import { faSlidersSimple as fasrSlidersSimple } from '@fortawesome/sharp-regular-svg-icons/faSlidersSimple'
import { faTrash as fasrTrash } from '@fortawesome/sharp-regular-svg-icons/faTrash'
import { faTriangle as fasrTriangle } from '@fortawesome/sharp-regular-svg-icons/faTriangle'
import { faTriangleExclamation as fasrTriangleExclamation } from '@fortawesome/sharp-regular-svg-icons/faTriangleExclamation'
import { faUser as fasrUser } from '@fortawesome/sharp-regular-svg-icons/faUser'
import { faVideo as fasrVideo } from '@fortawesome/sharp-regular-svg-icons/faVideo'
import { faXmark as fasrXmark } from '@fortawesome/sharp-regular-svg-icons/faXmark'
import { faXmarkLarge as fasrXmarkLarge } from '@fortawesome/sharp-regular-svg-icons/faXmarkLarge'
import { faArrowDownToLine as fassArrowDownToLine } from '@fortawesome/sharp-solid-svg-icons/faArrowDownToLine'
import { faArrowLeft as fassArrowLeft } from '@fortawesome/sharp-solid-svg-icons/faArrowLeft'
import { faArrowRight as fassArrowRight } from '@fortawesome/sharp-solid-svg-icons/faArrowRight'
import { faArrowsRepeat as fassArrowsRepeat } from '@fortawesome/sharp-solid-svg-icons/faArrowsRepeat'
import { faBolt as fassBolt } from '@fortawesome/sharp-solid-svg-icons/faBolt'
import { faBoltSlash as fassBoltSlash } from '@fortawesome/sharp-solid-svg-icons/faBoltSlash'
import { faBrightness as fassBrightness } from '@fortawesome/sharp-solid-svg-icons/faBrightness'
import { faCamera as fassCamera } from '@fortawesome/sharp-solid-svg-icons/faCamera'
import { faCar as fassCar } from '@fortawesome/sharp-solid-svg-icons/faCar'
import { faCarBuilding as fassCarBuilding } from '@fortawesome/sharp-solid-svg-icons/faCarBuilding'
import { faChargingStation as fassChargingStation } from '@fortawesome/sharp-solid-svg-icons/faChargingStation'
import { faCheck as fassCheck } from '@fortawesome/sharp-solid-svg-icons/faCheck'
import { faChevronDown as fassChevronDown } from '@fortawesome/sharp-solid-svg-icons/faChevronDown'
import { faChevronRight as fassChevronRight } from '@fortawesome/sharp-solid-svg-icons/faChevronRight'
import { faCircle as fassCircle } from '@fortawesome/sharp-solid-svg-icons/faCircle'
import { faCircleCheck as fassCircleCheck } from '@fortawesome/sharp-solid-svg-icons/faCircleCheck'
import { faCircleExclamation as fassCircleExclamation } from '@fortawesome/sharp-solid-svg-icons/faCircleExclamation'
import { faCircleInfo as fassCircleInfo } from '@fortawesome/sharp-solid-svg-icons/faCircleInfo'
import { faCircleNotch as fassCircleNotch } from '@fortawesome/sharp-solid-svg-icons/faCircleNotch'
import { faCircleUser as fassCircleUser } from '@fortawesome/sharp-solid-svg-icons/faCircleUser'
import { faClock as fassClock } from '@fortawesome/sharp-solid-svg-icons/faClock'
import { faCog as fassCog } from '@fortawesome/sharp-solid-svg-icons/faCog'
import { faCoins as fassCoins } from '@fortawesome/sharp-solid-svg-icons/faCoins'
import { faCreditCardFront as fassCreditCardFront } from '@fortawesome/sharp-solid-svg-icons/faCreditCardFront'
import { faDiamondTurnRight as fassDiamondTurnRight } from '@fortawesome/sharp-solid-svg-icons/faDiamondTurnRight'
import { faFastForward as fassFastForward } from '@fortawesome/sharp-solid-svg-icons/faFastForward'
import { faFilePdf as fassFilePdf } from '@fortawesome/sharp-solid-svg-icons/faFilePdf'
import { faHourglass as fassHourglass } from '@fortawesome/sharp-solid-svg-icons/faHourglass'
import { faHouse as fassHouse } from '@fortawesome/sharp-solid-svg-icons/faHouse'
import { faLocationCrosshairs as fassLocationCrosshairs } from '@fortawesome/sharp-solid-svg-icons/faLocationCrosshairs'
import { faLocationDot as fassLocationDot } from '@fortawesome/sharp-solid-svg-icons/faLocationDot'
import { faLock as fassLock } from '@fortawesome/sharp-solid-svg-icons/faLock'
import { faMessage as fassMessage } from '@fortawesome/sharp-solid-svg-icons/faMessage'
import { faMinus as fassMinus } from '@fortawesome/sharp-solid-svg-icons/faMinus'
import { faPaperPlaneTop as fassPaperPlaneTop } from '@fortawesome/sharp-solid-svg-icons/faPaperPlaneTop'
import { faPlug as fassPlug } from '@fortawesome/sharp-solid-svg-icons/faPlug'
import { faPlus as fassPlus } from '@fortawesome/sharp-solid-svg-icons/faPlus'
import { faRoad as fassRoad } from '@fortawesome/sharp-solid-svg-icons/faRoad'
import { faSparkles as fassSparkles } from '@fortawesome/sharp-solid-svg-icons/faSparkles'
import { faSquareExclamation as fassSquareExclamation } from '@fortawesome/sharp-solid-svg-icons/faSquareExclamation'
import { faSquareInfo as fassSquareInfo } from '@fortawesome/sharp-solid-svg-icons/faSquareInfo'
import { faTrash as fassTrash } from '@fortawesome/sharp-solid-svg-icons/faTrash'
import { faTriangle as fassTriangle } from '@fortawesome/sharp-solid-svg-icons/faTriangle'
import { faTruckFast as fassTruckFast } from '@fortawesome/sharp-solid-svg-icons/faTruckFast'
import { faUser as fassUser } from '@fortawesome/sharp-solid-svg-icons/faUser'
import { faWifiSlash as fassWifiSlash } from '@fortawesome/sharp-solid-svg-icons/faWifiSlash'

export const initFontAwesomeLibrary = () => {
  // Add all the used icons in the project to the library
  library.add(
    // Solid Icons
    fasTriangle,

    // Sharp Solid Icons
    fassPaperPlaneTop,
    fassArrowRight,
    fassArrowLeft,
    fassCircleUser,
    fassChevronRight,
    fassChevronDown,
    fassCheck,
    fassCircle,
    fassCircleNotch,
    fassMessage,
    fassCircleInfo,
    fassCamera,
    fassCog,
    fassPlus,
    fassMinus,
    fassSquareInfo,
    fassHourglass,
    fassRoad,
    fassHouse,
    fassArrowDownToLine,
    fassTriangle,
    fassBolt,
    fassCoins,
    fassLocationDot,
    fassLocationCrosshairs,
    fassFilePdf,
    fassCarBuilding,
    fassArrowsRepeat,
    fassCircleExclamation,
    fassSquareExclamation,
    fassDiamondTurnRight,
    fassTrash,
    fassCircleCheck,
    fassPlug,
    fassFastForward,
    fassBrightness,
    fassClock,
    fassCar,
    fassLock,
    fassBoltSlash,
    fassChargingStation,
    fassSparkles,
    fassUser,
    fassCreditCardFront,
    fassTruckFast,
    fassWifiSlash,

    // Sharp Regular Icons
    fasrCheck,
    fasrTriangleExclamation,
    fasrXmarkLarge,
    fasrXmark,
    fasrArrowLeft,
    fasrChevronDown,
    fasrChevronRight,
    fasrChevronLeft,
    fasrChevronUp,
    fasrGarage,
    fasrHouseTree,
    fasrCarGarage,
    fasrHouseBlank,
    fasrEllipsis,
    fasrFileCheck,
    fasrBlockBrick,
    fasrBlock,
    fasrHourglass,
    fasrCircleUser,
    fasrEnvelope,
    fasrImageLandscape,
    fasrPen,
    fasrFloppyDisk,
    fasrArrowUpRightFromSquare,
    fasrCog,
    fasrMagnifyingGlass,
    fasrArrowUpLong,
    fasrArrowDownLong,
    fasrBan,
    fasrVideo,
    fasrCalendar,
    fasrArrowRight,
    fasrUser,
    fasrChargingStation,
    fasrLock,
    fasrArrowRightFromBracket,
    fasrBolt,
    fasrBoltSlash,
    fasrFileArrowDown,
    fasrCircleInfo,
    fasrClock,
    fasrArrowDownToLine,
    fasrCreditCardBlank,
    fasrTrash,
    fasrBugs,
    fasrEye,
    fasrEyeSlash,
    fasrFileUpload,
    fasrSend,
    fasrGlobe,
    fasrCircleExclamation,
    fasrDash,
    fasrFile,
    fasrSlidersSimple,
    fasrPlug,
    fasrEuroSign,
    fasrBrightness,
    fasrTriangle,
    fasrSliders,

    // Sharp Light Icons
    faslHome,
    faslBolt,
    faslLocationDot,
    faslCircleUser,
    faslXmark,
    faslCarBolt,
    faslCar,
    faslHouse,
    faslPlug
  )
}
