import { useContext } from 'react'

import { splashScreenContext } from '../providers/SplashScreenProvider'

export const useSplashScreen = () => {
  const context = useContext(splashScreenContext)

  if (!context) {
    throw new Error(
      'useSplashScreen needs to be used inside a SplashScreenProvider'
    )
  }

  return context
}
