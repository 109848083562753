import { Outlet, Navigate, useLocation } from 'react-router-dom'

import { useAuthentication } from '../../modules/authentication/hooks/useAuthentication'
import { useGeoLocation } from '../../modules/location/hooks/useGeoLocation'
import { useMember } from '../../modules/member/hooks/useMember'
import { useUser } from '../../modules/user/hooks/useUser'
import { EmployeeRoutes, EmployerRoutes } from '../routes'

export const EmployeeGuard = () => {
  // -- Hooks --
  const { currentMember } = useMember()
  const { user } = useUser()
  const { pathname } = useLocation()
  const { user: auth0User } = useAuthentication()
  // Get and set the inital location if the user has already given permission
  useGeoLocation()

  // -- Render --

  if (!currentMember.roles.includes('Employee')) {
    return <Navigate to={EmployerRoutes.Root} replace />
  }

  // If the user has no personal data or it's email is not verified, redirect to the onboarding screen
  if (
    (!user.firstName ||
      (!auth0User?.email_verified && import.meta.env.VITE_ENV !== 'local')) &&
    !pathname.includes('onboarding')
  ) {
    return <Navigate to={EmployeeRoutes.Onboarding} replace />
  }

  return <Outlet />
}
