import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { BodyMediumSemiBold, BodyMediumSemiBoldCss } from '../typography'

import type { CurrentStep, WizardStep } from './Wizard'

type WizardItemProps = {
  step: WizardStep
  currentStep: CurrentStep
}

export const WizardItem = ({ step, currentStep }: WizardItemProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Vars --
  const isActive = step.key === currentStep.key
  const isDone =
    step.order < currentStep.order ||
    (isActive && currentStep.completed === true)

  // -- Render --
  return (
    <StContainer>
      {step.order > 1 && isDesktop ? (
        <StChevron icon={['fasr', 'chevron-right']} />
      ) : null}
      <StStep $isDone={isDone} $isActive={isActive}>
        {isDone ? <StCheck icon={['fass', 'check']} /> : step.order}
      </StStep>
      {currentStep.subStep && isActive ? (
        <StSubStep>{`${currentStep.subStep.step} / ${currentStep.subStep.of}`}</StSubStep>
      ) : null}
      {currentStep.loading && isActive ? (
        <StSubStep>
          <FontAwesomeIcon icon={['fass', 'hourglass']} />
        </StSubStep>
      ) : null}
      <StText $isActive={isActive}>{t(`navigation.${step.key}`)}</StText>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  align-items: center;
`

const StText = styled(BodyMediumSemiBold)<{
  $isActive: boolean
}>`
  color: ${({ theme, $isActive }) =>
    $isActive
      ? theme.components.navwizard['active-text']
      : theme.components.navwizard['default-text']};
`

const StStep = styled.div<{ $isDone: boolean; $isActive: boolean }>`
  background-color: ${({ theme, $isDone, $isActive }) =>
    $isDone
      ? theme.components.navcircle['completed-bg']
      : $isActive
      ? theme.components.navcircle['active-bg']
      : theme.components.navcircle['notactive-bg']};

  ${BodyMediumSemiBoldCss}

  color: ${({ theme, $isActive }) =>
    $isActive
      ? theme.components.navwizard['active-text']
      : theme.components.navwizard['default-text']};

  border-radius: 100px;
  width: 26px;
  height: 26px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StSubStep = styled.p`
  background-color: ${({ theme }) => theme.components.navwizard.bg};

  ${BodyMediumSemiBoldCss}
  white-space: nowrap;

  color: ${({ theme }) => theme.components.navwizard['active-text']};

  border: 2px solid ${({ theme }) => theme.theme.colors.white};
  border-radius: 100px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space2};
  margin-left: -${({ theme }) => theme.UI.SpacingPx.Space3};
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};

  @media ${breakpoints.desktop} {
    border: 2px solid ${({ theme }) => theme.components.navwizard.bg};
    background-color: ${({ theme }) => theme.theme.colors.white};
  }
`

const StCheck = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.components.navcircle['check-text']};
`

const StChevron = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.components.navwizard.chevron};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
