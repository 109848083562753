import { styled, css } from 'styled-components'

/* Heading 2 */
export const H2Css = css`
  ${({ theme }) => theme.typography.heading.H2}
`

export const H2 = styled.h2`
  ${H2Css}
`

/* Heading 3 */
export const H3Css = css`
  ${({ theme }) => theme.typography.heading.H3}
`

export const H3 = styled.h3`
  ${H3Css}
`

/* Heading 4 */
export const H4Css = css`
  ${({ theme }) => theme.typography.heading.H4}
`

export const H4 = styled.h4`
  ${H4Css}
`

/* Heading 5 */
export const H5Css = css`
  ${({ theme }) => theme.typography.heading.H5}
`

export const H5 = styled.h5`
  ${H5Css}
`

/* Heading 6 */
export const H6Css = css`
  ${({ theme }) => theme.typography.heading.H6}
`

export const H6 = styled.h6`
  ${H6Css}
`

/* Heading 7 */
export const H7Css = css`
  ${({ theme }) => theme.typography.heading.H7}
`

export const H7 = styled.h6`
  ${H7Css}
`

/* BodyLargeMedium */
export const BodyLargeMediumCss = css`
  ${({ theme }) => theme.typography['body-large'].Medium}
`

export const BodyLargeMedium = styled.p`
  ${BodyLargeMediumCss}
`

/* BodyLargeRegular */
export const BodyLargeRegularCss = css`
  ${({ theme }) => theme.typography['body-large'].Regular}
`

export const BodyLargeRegular = styled.p`
  ${BodyLargeRegularCss}
`

/* BodyLargeSemiBold*/
export const BodyLargeSemiBoldCss = css`
  ${({ theme }) => theme.typography['body-large'].SemiBold}
`

export const BodyLargeSemiBold = styled.p`
  ${BodyLargeSemiBoldCss}
`

/* BodyMediumMedium */
export const BodyMediumMediumCss = css`
  ${({ theme }) => theme.typography['body-medium'].Medium}
`

export const BodyMediumMedium = styled.p`
  ${BodyMediumMediumCss}
`

/* BodyMediumRegular */
export const BodyMediumRegularCss = css`
  ${({ theme }) => theme.typography['body-medium'].Regular}
`

export const BodyMediumRegular = styled.p`
  ${BodyMediumRegularCss}
`

/* BodyMediumSemiBold */
export const BodyMediumSemiBoldCss = css`
  ${({ theme }) => theme.typography['body-medium'].SemiBold}
`

export const BodyMediumSemiBold = styled.p`
  ${BodyMediumSemiBoldCss}
`

/* BodySmallRegular */
export const BodySmallRegularCss = css`
  ${({ theme }) => theme.typography['body-small'].Regular}
`

export const BodySmallRegular = styled.p`
  ${BodySmallRegularCss}
`

/* BodySmallMedium */
export const BodySmallMediumCss = css`
  ${({ theme }) => theme.typography['body-small'].Medium}
`

export const BodySmallMedium = styled.p`
  ${BodySmallMediumCss}
`

/* BodySmallSemiBold */
export const BodySmallSemiBoldCss = css`
  ${({ theme }) => theme.typography['body-small'].SemiBold}
`

export const BodySmallSemiBold = styled.p`
  ${BodySmallSemiBoldCss}
`

/* BodyExtraSmallRegular */
export const BodyExtraSmallRegularCss = css`
  ${({ theme }) => theme.typography['body-xsmall'].Regular}
`

export const BodyExtraSmallRegular = styled.p`
  ${BodyExtraSmallRegularCss}
`

/* BodyExtraSmallMedium */
export const BodyExtraSmallMediumCss = css`
  ${({ theme }) => theme.typography['body-xsmall'].Medium}
`

export const BodyExtraSmallMedium = styled.p`
  ${BodyExtraSmallMediumCss}
`

/* BodyExtraSmallSemiBold */
export const BodyExtraSmallSemiBoldCss = css`
  ${({ theme }) => theme.typography['body-xsmall'].SemiBold}
`

export const BodyExtraSmallSemiBold = styled.p`
  ${BodyExtraSmallSemiBoldCss}
`

/* BodyExtraExtraSmallRegular */
export const BodyExtraExtraSmallRegularCss = css`
  ${({ theme }) => theme.typography['body-xxsmall'].Regular}
`

export const BodyExtraExtraSmallRegular = styled.p`
  ${BodyExtraExtraSmallRegularCss}
`

/* BodyExtraExtraSmallMedium */
export const BodyExtraExtraSmallMediumCss = css`
  ${({ theme }) => theme.typography['body-xxsmall'].Medium}
`

export const BodyExtraExtraSmallMedium = styled.p`
  ${BodyExtraExtraSmallMediumCss}
`

/* BodyExtraExtraSmallSemiBold */
export const BodyExtraExtraSmallSemiBoldCss = css`
  ${({ theme }) => theme.typography['body-xxsmall'].SemiBold}
`

export const BodyExtraExtraSmallSemiBold = styled.p`
  ${BodyExtraExtraSmallSemiBoldCss}
`
