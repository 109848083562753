import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import trans_en from '../../../../../../packages/translations/en/export.json'
import trans_fr from '../../../../../../packages/translations/fr/export.json'
import trans_nl from '../../../../../../packages/translations/nl/export.json'
import { LocalStorageKeys } from '../../../config/constants/local-storage-keys'

export const initTranslations = () => {
  i18next.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    lng:
      localStorage.getItem(LocalStorageKeys.SELECTED_LANGUAGE) ||
      navigator.language.slice(0, 2),
    fallbackLng: 'en',
    resources: {
      en: { translation: trans_en },
      fr: { translation: trans_fr },
      nl: { translation: trans_nl },
    },
  })
}
