import ContentLoader from 'react-content-loader'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
export const OnboardingCardSkeleton = () => {
  const isDesktop = useMediaQuery(breakpoints.desktop)

  return (
    <ContentLoader
      speed={2}
      width="100%"
      viewBox={`0 0 350 ${isDesktop ? '220' : '350'}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect
        x="0"
        y="0"
        rx="8"
        ry="8"
        width="100%"
        height={isDesktop ? '220' : '350'}
      />
    </ContentLoader>
  )
}
