import { useEffect } from 'react'
import { useInterval } from 'usehooks-ts'

import { useAxios } from '../../../api/hooks/useAxios'
import { Urls } from '../../../api/urls'

import { useTokenOnboarding } from './useTokenOnboarding'

const POLLING_INTERVAL = 5000

export const useTokenImagePolling = () => {
  // -- Hooks --
  const { setValues, values, token } = useTokenOnboarding()

  // -- Data --
  const [{ data }, refetch] = useAxios({
    url: Urls.TokenOnboardingImagesPolling,
    headers: {
      ['x-onboarding-token']: token,
    },
  })

  // -- Interval --
  useInterval(refetch, POLLING_INTERVAL)

  // -- Effect --
  useEffect(() => {
    if (data && Object.values(data).length > 0) {
      setValues({
        ...values,
        ...data,
      })
    }
  }, [data])
}
