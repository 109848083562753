import { useEffect, useState } from 'react'

import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'

import type { User } from 'database'
import type { UserWithContractAndCards } from 'types'

export const useEmployeeDetail = (blossomId: string) => {
  const [employee, setEmployee] = useState<UserWithContractAndCards>()
  const [loading, setLoading] = useState<boolean>(true)

  const [{ data, error }, refetch] =
    useAuthMemberAxios<UserWithContractAndCards>({
      url: replaceParametersInPath(Urls.employeeDetail, {
        blossomId: blossomId,
      }),
    })

  const [{ data: employeeUpdate }, update] =
    useAuthMemberAxios<UserWithContractAndCards>(
      { method: 'PATCH' },
      { manual: true }
    )

  const updateEmployee = (data: Partial<User>) => {
    return update({
      data,
      url: replaceParametersInPath(Urls.employeeUpdate, {
        // Id will always be available
        id: data.id || '',
      }),
    })
  }

  useEffect(() => {
    if (data) {
      setEmployee(data)
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (employeeUpdate) {
      setEmployee(employeeUpdate)
      setLoading(false)
    }
  }, [employeeUpdate])

  return { employee, loading, error, updateEmployee, refetch }
}
