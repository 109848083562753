import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import { Dialog } from '../../../components/general/Dialog'
import { Loader } from '../../../components/general/Loader'
import { PdfPreview } from '../../../components/general/PdfPreview'
import { BodyMediumRegular, H4 } from '../../../components/typography'
import { useDownloadZohoFile } from '../../../core/hooks/useDownloadZohoFile'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { useQuote } from '../../../quotes/hooks/useQuote'

import type { UserWithContractAndCards } from 'types'

type DetailQuoteApprovalProps = {
  employee: UserWithContractAndCards
  refetchEmployee: () => void
}

export const DetailQuoteApproval = ({
  employee,
  refetchEmployee,
}: DetailQuoteApprovalProps) => {
  // -- State --
  const [dialogOpen, setDialogOpen] = useState(false)

  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const dateFnsLocale = useGetDateFnsLocale()

  // -- Data --
  const { quote, loading, error, approveQuote, approveLoading } = useQuote(
    employee.members[0].id
  )

  // -- Data --
  const {
    download: quoteDownload,
    loading: quoteLoading,
    file,
  } = useDownloadZohoFile(quote?.Quote[0].File_Id__s, employee.id)

  // -- Handlers --
  const handleApproveQuote = async () => {
    setDialogOpen(false)
    await approveQuote()
    refetchEmployee()
  }

  const approvedByEmployer = useMemo(
    () => employee.hcpStatus.status === 'QuotationApprovedByEmployer',
    [quote, employee.hcpStatus]
  )

  const approvedByBothParties = useMemo(
    () => employee.hcpStatus.status === 'QuotationApproved',
    [quote, employee.hcpStatus]
  )

  // -- Handlers --
  const handleDownload = (openInWindow: boolean) => {
    const fileName = t('admin.employee-detail.quote.title', {
      fullName: employee.fullName,
    }).replaceAll(/\s/g, '_')

    trackEvent(EventType.Click, 'download_quote')
    quoteDownload(fileName, undefined, !openInWindow)
  }

  if (loading) {
    return <Loader />
  }

  if (error || !quote) {
    toast.error(t('admin.employee-detail.quote.error'))
    return null
  }

  // -- Render --
  return (
    <>
      <StContainer>
        <StFlexContainer>
          <H4>{t('admin.employee-detail.quote.title-small')}</H4>
          <StActions>
            <ButtonTertiary
              icon={['fasr', 'eye']}
              iconAlignment="left"
              compact
              onClick={() => handleDownload(true)}
              loading={quoteLoading}
              size="md"
            >
              {t('admin.employee-detail.quote.view')}
            </ButtonTertiary>

            <ButtonTertiary
              icon={['fasr', 'arrow-down-to-line']}
              iconAlignment="left"
              compact
              onClick={() => handleDownload(false)}
              loading={quoteLoading}
              size="md"
            >
              {t('admin.employee-detail.quote.download')}
            </ButtonTertiary>
          </StActions>
        </StFlexContainer>
        <StDetailWrapper>
          <PdfPreview file={file} small={false} />
          <div>
            <StStatusTextTitle>
              {t('admin.employee-detail.quote.status')}
            </StStatusTextTitle>
            <StStatusText>
              <Trans
                t={t}
                i18nKey={
                  approvedByEmployer
                    ? 'admin.employee-detail.quote.employer-approved.title'
                    : approvedByBothParties
                    ? 'admin.employee-detail.quote.approved.title'
                    : 'admin.employee-detail.quote.creation-date'
                }
                values={{
                  name: employee.firstName,
                  date: format(
                    new Date(quote.Created_Time),
                    'dd MMMM yyyy',
                    dateFnsLocale
                  ),
                }}
                components={{
                  br: <br />,
                }}
              />
            </StStatusText>
            {!(approvedByEmployer || approvedByBothParties) && (
              <ButtonPrimary
                compact
                onClick={() => setDialogOpen(true)}
                loading={approveLoading}
                size="md"
              >
                {t('admin.employee-detail.quote.approve.button')}
              </ButtonPrimary>
            )}
          </div>
        </StDetailWrapper>
      </StContainer>
      <Dialog
        title={t('admin.employee-detail.quote.approve-dialog.title')}
        description={t(
          'admin.employee-detail.quote.approve-dialog.description'
        )}
        onClickCloseButton={() => setDialogOpen(false)}
        open={dialogOpen}
        onClickPrimaryButton={handleApproveQuote}
        primaryButtonText={t('admin.employee-detail.quote.approve.button')}
      />
    </>
  )
}

const StContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space7};
`

const StActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StDetailWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space7};
`

const StFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StStatusTextTitle = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  margin-bottom: 10px;
`
const StStatusText = styled(BodyMediumRegular)`
  margin-bottom: 30px;
`
