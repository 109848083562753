import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { css, styled } from 'styled-components'

import {
  BodySmallRegular,
  BodySmallSemiBold,
} from '../../../components/typography'
import { onHover } from '../../../core/lib/styleHelpers'

type ChargerInformationCardProps = {
  charging: boolean
  operational: boolean
  style: 'white' | 'gray'
}

export const ChargerInformationCard = ({
  charging,
  operational,
  style,
}: ChargerInformationCardProps) => {
  const { t } = useTranslation()

  return (
    <StCard $style={style}>
      <StIconContainer>
        <FontAwesomeIcon icon={['fasr', 'charging-station']} fontSize={19} />
      </StIconContainer>
      <StCardContent>
        <StStatusText $operational={operational}>
          {operational
            ? t('employee.profile.charger.operational')
            : t('employee.profile.charger.not-operational')}
        </StStatusText>
        <StChargingText>
          <FontAwesomeIcon icon={['fasr', charging ? 'bolt' : 'bolt-slash']} />
          <BodySmallRegular>
            {charging
              ? t('employee.profile.charger.charging')
              : t('employee.profile.charger.not-charging')}
          </BodySmallRegular>
        </StChargingText>
      </StCardContent>
    </StCard>
  )
}

const StCard = styled.div<{ $style: 'white' | 'gray' }>`
  background-color: ${({ theme, $style }) =>
    $style === 'gray'
      ? theme.theme.colors['nonary-9']
      : theme.theme.colors.white};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};

  color: ${({ theme }) => theme.theme.colors.black};

  height: fit-content;

  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  ${({ $style }) =>
    $style === 'white' &&
    css`
      border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};

      ${onHover(
        css`
          border: 1px solid ${({ theme }) => theme.theme.colors.black};
        `,
        false
      )}
    `}
`

const StIconContainer = styled.div`
  background-color: ${({ theme }) => theme.theme.colors.white};

  height: 50px;
  width: 50px;

  border-radius: 999px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const StCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StChargingText = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StStatusText = styled(BodySmallSemiBold)<{ $operational?: boolean }>`
  position: relative;
  width: fit-content;

  &::after {
    content: '';
    height: ${({ theme }) => theme.UI.SpacingPx.Space2};
    width: ${({ theme }) => theme.UI.SpacingPx.Space2};

    background-color: ${({ theme, $operational }) =>
      $operational
        ? theme.theme.colors['quaternary-1']
        : theme.theme.colors.error};

    position: absolute;
    top: 50%;
    right: -${({ theme }) => theme.UI.SpacingPx.Space4};

    transform: translateY(-50%);

    border-radius: 999px;
  }
`
