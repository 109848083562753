import { Outlet } from 'react-router-dom'

import { AuthenticatedNavigation } from '../../modules/navigation/AuthenticatedNavigation'

export const NavigationEmployerGuard = () => {
  return (
    <AuthenticatedNavigation>
      <Outlet />
    </AuthenticatedNavigation>
  )
}
