import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../components/typography'
import { InstallationMethodKeys } from '../steps/preferences/InstallationMethodStep'

import type { Product } from 'types'

export const useProductAttributes = () => {
  const { t } = useTranslation()

  const formatChargingSpeed = useCallback(
    (product: Product): string => {
      return `${t('onboarding.product-selection-overview.up_to')} ${
        product.chargingSpeedKw
      }kW`
    },
    [t]
  )

  // Keeping this commented out for now, as this may be used in the future
  // const formatConnectionMethods = useCallback(
  //   (product: Product): string => {
  //     let result: string = ''

  //     if (
  //       product.connectionMethods.some(
  //         (connectionMethod: ConnectionMethod) =>
  //           connectionMethod.cableLength === 0 || !connectionMethod.cableLength
  //       )
  //     ) {
  //       result += t('onboarding.product-selection-overview.plug')
  //     }

  //     if (
  //       product.connectionMethods.some(
  //         (connectionMethod: ConnectionMethod) =>
  //           connectionMethod.cableLength > 1
  //       )
  //     ) {
  //       if (result.length > 0) {
  //         result += ` ${t('onboarding.product-selection-overview.or')} `
  //       }

  //       result += t('onboarding.product-selection-overview.cable')
  //     }

  //     // Replace first letter with uppercase
  //     return result === ''
  //       ? result
  //       : result.replace(/^./, result[0].toUpperCase())
  //   },
  //   [t]
  // )

  const formatColorAmount = useCallback(
    (product: Product): string => {
      if (product.colors?.length === 0) {
        return ''
      }

      return t('onboarding.product-selection-overview.color', {
        count: product.colors?.length,
      })
    },
    [t]
  )

  const formatMountingOption = useCallback(
    (product: Product): string => {
      if (
        product.mountingOptions.some(
          (mountingOption) =>
            mountingOption.type === InstallationMethodKeys.Pole
        ) &&
        product.mountingOptions.some(
          (mountingOption) =>
            mountingOption.type === InstallationMethodKeys.Wall
        )
      ) {
        return t('onboarding.product-selection-overview.pole_or_wall')
      }

      if (
        product.mountingOptions.some(
          (mountingOption) =>
            mountingOption.type === InstallationMethodKeys.Pole
        )
      ) {
        return t('onboarding.product-selection-overview.pole')
      }

      if (
        product.mountingOptions.some(
          (mountingOption) =>
            mountingOption.type === InstallationMethodKeys.Wall
        )
      ) {
        return t('onboarding.product-selection-overview.wall')
      }

      return ''
    },
    [t]
  )

  const productAttributesList = useCallback(
    (product: Product) =>
      [
        formatMountingOption(product),
        // formatConnectionMethods(product),
        formatColorAmount(product),
        formatChargingSpeed(product),
      ].filter(Boolean),
    [
      formatChargingSpeed,
      formatColorAmount,
      // formatConnectionMethods
    ]
  )

  const ProductAttributes = ({ product }: { product: Product }) => {
    return (
      <StProductAttributes>
        {productAttributesList(product).map(
          (attribute: string, index: number) => (
            <span key={index}>
              {attribute}
              {index + 1 < productAttributesList(product).length ? (
                <StDelimiter>|</StDelimiter>
              ) : (
                ''
              )}
            </span>
          )
        )}
      </StProductAttributes>
    )
  }

  return {
    ProductAttributes,
  }
}

const StProductAttributes = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.colors['nonary-2']};
`

const StDelimiter = styled.span`
  margin: 0 ${({ theme }) => theme.UI.SpacingPx.Space3};
  color: ${({ theme }) => theme.theme.colors['nonary-7']};
`
