import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, useTheme } from 'styled-components'

import { Drawer, DrawerContent } from '../../../components/general/Drawer'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { valueToDate } from '../../../translations/utils/date'

import { ReimbursementInfo } from './ReimbursementInfo'

import type { Column } from '../../../dataTable/components/DataTable'
import type { Reimbursement } from 'database'

export const ReimbursementsDataTable = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const dateFnsLocale = useGetDateFnsLocale()
  const theme = useTheme()

  // -- State --
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedReimbursement, setSelectedReimbursement] = useState<
    Reimbursement | undefined
  >(undefined)

  // -- Vars --
  const columns = useMemo<Column<Reimbursement>[]>(
    () => [
      {
        key: 'date',
        label: t('employee.reimbursement.date'),
        type: ColumnType.DATE,
        sortable: true,
      },
      {
        key: 'type',
        label: t('employee.reimbursement.type'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (reimbursement) => {
          return t(`employee.reimbursement.${reimbursement.type}`)
        },
      },
      {
        key: 'price',
        label: t('employee.reimbursement.cost'),
        type: ColumnType.NUMBER,
        prepend: '€ ',
        sortable: true,
      },
      {
        key: 'isPaid',
        label: t('employee.reimbursement.status'),
        type: ColumnType.STRING,
        transformData: (reimbursement) => {
          return reimbursement.isPaid
            ? t('employee.reimbursement.status-paid-out')
            : t('employee.reimbursement.status-processing')
        },
        sortable: true,
      },
    ],
    [t]
  )

  // -- Render --
  return (
    <>
      <StContainer>
        <DataTable<Reimbursement>
          columns={columns}
          emptyFiltersTitle={t(
            'employee.reimbursement.table.empty-filters-title'
          )}
          emptyTitle={t('employee.reimbursement.table.empty-title')}
          emptyFiltersDescription={t(
            'employee.reimbursement.table.empty-filters-description'
          )}
          emptyDescription={t('employee.reimbursement.table.empty-description')}
          onRowClick={(dataRow) => {
            setIsDrawerOpen(true)
            setSelectedReimbursement(dataRow)
          }}
        />
      </StContainer>
      <Drawer open={isDrawerOpen}>
        <DrawerContent
          onClose={() => setIsDrawerOpen(false)}
          title={
            selectedReimbursement &&
            format(
              valueToDate(selectedReimbursement.date),
              'dd MMMM yyyy',
              dateFnsLocale
            )
          }
          contentPadding={false}
          headerBackground={theme.theme.colors['nonary-10']}
        >
          {selectedReimbursement && (
            <ReimbursementInfo reimbursement={selectedReimbursement} />
          )}
        </DrawerContent>
      </Drawer>
    </>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: 70px;
`
