export function replaceParametersInPath(
  path: string,
  parameters: Record<string, string | number>
): string {
  let url = path

  Object.keys(parameters).forEach((parameter) => {
    url = url.replace(`:${parameter}`, parameters[parameter].toString())
  })

  return url
}
