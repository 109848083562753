import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { EmployerRoutes } from '../../../../routing/routes'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import { StatusChip } from '../../../components/status-chip/StatusChip'
import { H4 } from '../../../components/typography'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { isUrgent } from '../../../quotes/lib/quote'
import zenImage from '../assets/zen.svg'

import type { Column } from '../../../dataTable/components/DataTable'
import type { UserWithMembers } from 'types'

export const ActionRequiredDataTable = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { trackEvent } = useTracking()

  const columns = useMemo<Column<UserWithMembers>[]>(
    () => [
      {
        key: 'fullName',
        label: t('admin.employees.table.name'),
        type: ColumnType.STRING,
      },
      {
        key: 'workEmail',
        label: t('admin.employees.table.email'),
        type: ColumnType.STRING,
      },
      {
        key: 'members.hcpContract.name',
        label: t('admin.employees.table.hcp-contract'),
        type: ColumnType.STRING,
      },
      {
        key: 'hcpStatus.status',
        label: t('admin.employees.table.hcp-status'),
        type: ColumnType.CHIP,
        transformData: (data) => (
          <StatusChip
            label={t(`hcp.status.${data.hcpStatus.status}`)}
            urgent={isUrgent(data.hcpStatus)}
          />
        ),
      },
      {
        key: 'members.mspStatus',
        label: 'Status kaart',
        type: ColumnType.CHIP,
        transformData(data) {
          return t(`msp.status.${data.members[0].mspStatus}`)
        },
      },
    ],
    [t]
  )

  // -- Handlers --
  const handleRowClick = (dataRow: UserWithMembers) => {
    navigate(
      replaceParametersInPath(EmployerRoutes.EmployeeDetail, {
        blossomId: dataRow.blossomId!,
      })
    )
    trackEvent(EventType.Click, 'open_employee_detail')
  }

  // -- Render --
  return (
    <div>
      <StHeader>
        <H4>{t('employer.home.action-required')}</H4>
        <ButtonTertiary
          onClick={() => {
            navigate(EmployerRoutes.Employees)
          }}
          compact
          icon={['fasr', 'arrow-right']}
          size="sm"
        >
          {t('employer.home.all-employees')}
        </ButtonTertiary>
      </StHeader>
      <DataTable<UserWithMembers>
        columns={columns}
        emptyFiltersTitle={t('employer.home.employees.empty.title')}
        emptyTitle={t('employer.home.employees.empty.title')}
        emptyFiltersDescription={t('employer.home.employees.empty.description')}
        emptyDescription={t('employer.home.employees.empty.description')}
        emptyImage={zenImage}
        onRowClick={handleRowClick}
      />
    </div>
  )
}

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
