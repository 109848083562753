import { Formik, Form } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { Select } from '../../../../components/form/Select'
import { IntercomCard } from '../../../../components/intercom/IntercomCard'
import {
  BodyMediumRegular,
  BodyMediumSemiBold,
} from '../../../../components/typography'
import amperage1 from '../../assets/amperage/Amp1.jpg'
import amperage2 from '../../assets/amperage/Amp2.jpg'
import amperage3 from '../../assets/amperage/Amp3.jpg'
import analogMeter1 from '../../assets/analog/A1.jpg'
import analogMeter2 from '../../assets/analog/A2.jpg'
import analogMeter3 from '../../assets/analog/A3.jpg'
import digitalMeter1 from '../../assets/digital/D1.jpg'
import digitalMeter2 from '../../assets/digital/D2.jpg'
import digitalMeter3 from '../../assets/digital/D3.jpg'
import { FloatingContainerButton } from '../../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { SimpleLightbox } from '../../components/SimpleLightbox'
import { useOnboarding } from '../../hooks/useOnboarding'

export enum GridConnection {
  'Single Phase 230V' = 'Single Phase 230V',
  'Triple Phase 230V' = 'Triple Phase 230V',
  'Triple Phase 400V with Neutral' = 'Triple Phase 400V with Neutral',
  'Triple Phase 230V with Neutral' = 'Triple Phase 230V with Neutral',
}

export enum Amperage {
  '16 A' = '16 A',
  '25 A' = '25 A',
  '32 A' = '32 A',
  '40 A' = '40 A',
  '50 A' = '50 A',
  '64 A' = '64 A',
}

export const ConnectionTypeStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const [selectedImageSet, setSelectedImageSet] = useState<
    string[] | undefined
  >(undefined)
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const {
    handleNext,
    setLoading,
    values: onboardingValues,
    items: { gridConnectionOptions, amperageOptions },
  } = useOnboarding()
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Data --
  const digitalMeterImageSet = [digitalMeter1, digitalMeter2, digitalMeter3]
  const analogMeterImageSet = [analogMeter1, analogMeter2, analogMeter3]
  const amperageImageSet = [amperage1, amperage2, amperage3]

  // -- Memo's --
  const hasDigitalMeter = useMemo(() => {
    return onboardingValues.digitalMeterKey === 'Yes'
  }, [onboardingValues.digitalMeterKey])

  // -- Callbacks --
  const handleSubmit = useCallback(
    async (data: { gridConnection: string; amperage: string }) => {
      setLoading(true)
      const { status } = await execute({
        data: {
          gridConnection: data.gridConnection,
          amperage: data.amperage,
        },
      })
      setLoading(false)
      if (status === 200 || status === 201) {
        handleNext({
          ...onboardingValues,
          connectionType: {
            gridConnection: data.gridConnection,
            amperage: data.amperage,
          },
        })
      } else {
        console.error('Failed to save connection type')
      }
    },
    [setLoading, handleNext, onboardingValues, execute]
  )

  const handleImageSetClick = useCallback(
    (imageSet: string[] | undefined) => {
      if (isDesktop) {
        return
      }

      setSelectedImageSet(imageSet)
    },
    [isDesktop]
  )

  // -- Render component --
  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.connection-type.title')}
      </OnboardingStepTitle>
      <StContentContainer>
        <div>
          <Formik
            initialValues={onboardingValues.connectionType}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <StQuestionContainer>
                  <Select
                    key={'connectionType'}
                    options={gridConnectionOptions.filter(
                      (option) =>
                        !option.type ||
                        option.type === (hasDigitalMeter ? 'digital' : 'analog')
                    )}
                    label={t(
                      'onboarding.connection-type.grid-connection.title'
                    )}
                    placeholder={t('onboarding.connection-type.placeholder')}
                    value={values.gridConnection}
                    onChange={(data) => {
                      setFieldValue('gridConnection', data)
                    }}
                    disabled={false}
                  />
                  <Select
                    key={'amperage'}
                    options={amperageOptions}
                    label={t('onboarding.connection-type.ampere')}
                    placeholder={t('onboarding.connection-type.placeholder')}
                    value={values.amperage}
                    onChange={(data) => {
                      setFieldValue('amperage', data)
                    }}
                    disabled={false}
                  />
                  <FloatingContainerButton
                    disabled={!values.gridConnection || !values.amperage}
                    title={t('onboarding.account.submit')}
                  />
                </StQuestionContainer>
              </Form>
            )}
          </Formik>
        </div>
        <StInfoContainer>
          <div>
            <StInfoTitle>
              {t('onboarding.connection-type.info.grid-connection.title')}
            </StInfoTitle>
            <BodyMediumRegular>
              {hasDigitalMeter
                ? t(
                    'onboarding.connection-type.info.grid-connection-digital.description'
                  )
                : t(
                    'onboarding.connection-type.info.grid-connection.description'
                  )}
            </BodyMediumRegular>
          </div>
          {hasDigitalMeter ? (
            <StImageRow
              onClick={() => handleImageSetClick(digitalMeterImageSet)}
            >
              {digitalMeterImageSet.map((image, index) => (
                <StImage
                  key={'digital_meter_image_' + index}
                  src={image}
                  alt={'Digital meter ' + index}
                />
              ))}
            </StImageRow>
          ) : (
            <StImageRow
              onClick={() => handleImageSetClick(analogMeterImageSet)}
            >
              {analogMeterImageSet.map((image, index) => (
                <StImage
                  key={'analog_meter_image_' + index}
                  src={image}
                  alt={'Analog meter ' + index}
                />
              ))}
            </StImageRow>
          )}
          <div>
            <StInfoTitle>
              {t('onboarding.connection-type.info.amperage.title')}
            </StInfoTitle>
            <BodyMediumRegular>
              {t('onboarding.connection-type.info.amperage.description')}
            </BodyMediumRegular>
          </div>
          <StImageRow onClick={() => handleImageSetClick(amperageImageSet)}>
            {amperageImageSet.map((image, index) => (
              <StImage
                key={'amperage_image_' + index}
                src={image}
                alt={'Amperage ' + index}
              />
            ))}
          </StImageRow>
          <IntercomCard />
        </StInfoContainer>
      </StContentContainer>
      {!isDesktop && (
        <SimpleLightbox
          open={selectedImageSet !== undefined}
          onClose={() => handleImageSetClick(undefined)}
        >
          {
            <StImageColumn>
              {selectedImageSet?.map((image, index) => (
                <StImage
                  key={'highlighted_image_' + index}
                  src={image}
                  alt={'Highlighted image ' + index}
                />
              ))}
            </StImageColumn>
          }
        </SimpleLightbox>
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  height: 100%;
  padding-bottom: ${({ theme }) =>
    `calc(var(--sticky-button-container-height) + ${theme.UI.SpacingPx.Space6})`};

  @media ${breakpoints.desktop} {
    margin-top: 0;
    padding-bottom: ${({ theme }) =>
      `calc(var(--sticky-button-container-height) + ${theme.UI.SpacingPx.Space6})`};
  }
`

const StContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  @media ${breakpoints.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`

const StQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  background-color: ${({ theme }) => theme.components.menu.default.border};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space7};
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StInfoTitle = styled(BodyMediumSemiBold)`
  font-weight: 700;
`

const StImageRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StImage = styled.img`
  max-width: 550px;
  width: 100%;
  align-self: center;
  justify-self: center;
`
