import { usePostHog } from 'posthog-js/react'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAxios } from '../../../api/hooks/useAxios'
import { Urls } from '../../../api/urls'
import { useIntercom } from '../../../intercom/hooks/useIntercom'
import { hcpOnboardingWizardConfig } from '../config/wizardConfig'

import type { OnboardingValues } from './useOnboardingState'
import type { CurrentStep } from '../../../components/navigation/Wizard'

export type TokenFlowValues = Pick<
  OnboardingValues,
  | 'electricalBoxImageIds'
  | 'extraImageIds'
  | 'fuseBoxImageIds'
  | 'chargerLocationImageIds'
  | 'electricityMeterImageIds'
  | 'skippedExtraImages'
>

export enum TokenFlowSteps {
  ElectricityBoxImage = 'electricityBoxImage',
  FuseBoxImage = 'fuseBoxImage',
  ElectricityMeterImage = 'electricityMeterImage',
  ChargerLocationImage = 'chargerLocationImage',
  ExtraImages = 'extraImages',
  ContinueInTheApp = 'continueInTheApp',
}

const getCurrentStep = (data: Partial<TokenFlowValues>): TokenFlowSteps => {
  // Mobile upload flow check
  if (!data.electricalBoxImageIds) {
    return TokenFlowSteps.ElectricityBoxImage
  }

  if (!data.fuseBoxImageIds) {
    return TokenFlowSteps.FuseBoxImage
  }

  if (!data.electricityMeterImageIds) {
    return TokenFlowSteps.ElectricityMeterImage
  }

  if (!data.chargerLocationImageIds) {
    return TokenFlowSteps.ChargerLocationImage
  }

  if (!data.skippedExtraImages && !data.extraImageIds?.length) {
    return TokenFlowSteps.ExtraImages
  }

  return TokenFlowSteps.ContinueInTheApp
}

type TokenFlowConfigOption = {
  next: (alternative?: boolean) => TokenFlowSteps
  previous: () => TokenFlowSteps
}

export const useTokenFlowState = () => {
  // -- State --
  const [formState, setFormState] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [onboardingIsLoading, setOnboardingIsLoading] = useState(true)
  const [showSaved, setShowSaved] = useState(false)
  const { token } = useParams()
  const [values, setValues] = useState<TokenFlowValues>({
    skippedExtraImages: false,
  })
  const intercom = useIntercom()
  const posthog = usePostHog()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Hooks --
  const { trackEvent } = useTracking()

  // -- Data --
  const [{ data, error }] = useAxios<{
    userId: string
    data: Partial<TokenFlowValues>
  }>({
    url: Urls.TokenFlow,
    headers: {
      ['x-onboarding-token']: token,
    },
  })

  const [, fetchIntercomHashes] = useAxios<{
    web: string
    android: string
    ios: string
  }>(
    {
      headers: {
        ['x-onboarding-token']: token,
      },
    },
    {
      manual: true,
    }
  )

  const currentStep = formState.get('step') as TokenFlowSteps

  const headerStates: Record<
    TokenFlowSteps,
    { state: CurrentStep; cantGoBack?: boolean; canCancel?: boolean }
  > = {
    [TokenFlowSteps.ElectricityBoxImage]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 11,
          of: 18,
        },
      },
      cantGoBack: true,
      canCancel: false,
    },
    [TokenFlowSteps.FuseBoxImage]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 12,
          of: 18,
        },
      },
      canCancel: false,
    },
    [TokenFlowSteps.ElectricityMeterImage]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 13,
          of: 18,
        },
      },
      canCancel: false,
    },
    [TokenFlowSteps.ChargerLocationImage]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 14,
          of: 18,
        },
      },
      canCancel: false,
    },
    [TokenFlowSteps.ExtraImages]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 15,
          of: 18,
        },
      },
      canCancel: false,
    },
    [TokenFlowSteps.ContinueInTheApp]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 15,
          of: 18,
        },
      },
      canCancel: false,
    },
  }

  const OnboardingConfig: Record<TokenFlowSteps, TokenFlowConfigOption> = {
    [TokenFlowSteps.ElectricityBoxImage]: {
      next: () => TokenFlowSteps.FuseBoxImage,
      previous: () => TokenFlowSteps.ElectricityBoxImage,
    },
    [TokenFlowSteps.FuseBoxImage]: {
      next: () => TokenFlowSteps.ElectricityMeterImage,
      previous: () => TokenFlowSteps.ElectricityBoxImage,
    },
    [TokenFlowSteps.ElectricityMeterImage]: {
      next: () => TokenFlowSteps.ChargerLocationImage,
      previous: () => TokenFlowSteps.FuseBoxImage,
    },
    [TokenFlowSteps.ChargerLocationImage]: {
      next: () => TokenFlowSteps.ExtraImages,
      previous: () => TokenFlowSteps.ElectricityMeterImage,
    },
    [TokenFlowSteps.ExtraImages]: {
      next: () => TokenFlowSteps.ContinueInTheApp,
      previous: () => TokenFlowSteps.ChargerLocationImage,
    },
    [TokenFlowSteps.ContinueInTheApp]: {
      next: () => TokenFlowSteps.ContinueInTheApp,
      previous: () => TokenFlowSteps.ExtraImages,
    },
  }

  // -- Effects --
  useEffect(() => {
    if (
      (!currentStep || !Object.values(TokenFlowSteps).includes(currentStep)) &&
      !loading
    ) {
      setFormState(
        { step: TokenFlowSteps.ElectricityBoxImage },
        { replace: true }
      )
    }
  }, [currentStep, setFormState])

  useEffect(() => {
    const asyncFunction = async () => {
      if (data) {
        const currentstep = getCurrentStep(data.data)

        setValues({
          skippedExtraImages: false,
          ...data.data,
        })
        setFormState({ step: currentstep }, { replace: true })
        setOnboardingIsLoading(false)

        let hashes = {}
        try {
          ;({ data: hashes } = await fetchIntercomHashes(
            `${Urls.IntercomHashes}/${data.userId}`
          ))
        } catch {
          // Do nothing.
        }

        intercom.boot(
          {
            userId: data.userId,
            hideDefaultLauncher: !isDesktop,
          },
          hashes
        )

        posthog.identify(data.userId)
      }
    }

    asyncFunction()
  }, [data])

  // -- Functions --
  const handleNext = (data: TokenFlowValues) => {
    const nextStep = OnboardingConfig[currentStep].next()

    setValues(data)

    trackEvent(EventType.Submit, `submit_${currentStep}`)

    setShowSaved(true)
    setLoading(false)

    setFormState({ step: nextStep })

    setTimeout(() => {
      setShowSaved(false)
    }, 1500)
  }

  const handlePrevious = () => {
    const previousStep = OnboardingConfig[currentStep].previous()

    trackEvent(EventType.Click, 'go_back_onboarding', {
      from: currentStep,
      to: previousStep,
    })

    setFormState({ step: previousStep })
  }

  // -- Return --
  return {
    currentStep: currentStep,
    headerState: headerStates[currentStep] || headerStates.electricityBoxImage,
    handleNext,
    handlePrevious,
    loading,
    setLoading,
    showSaved,
    values,
    setValues,
    onboardingIsLoading: onboardingIsLoading || !data,
    error,
    // Token will allways be set
    token: token as string,
  }
}
