import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as RadixDialog from '@radix-ui/react-dialog'
// eslint-disable-next-line import/named
import { isAxiosError } from 'axios'
import { useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { css, styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { router } from '../../../../routing/router'
import { EmployerRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { ButtonSecondary } from '../../../components/button/ButtonSecondary'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import { Banner, BannerType } from '../../../components/general/Banner'
import { DialogPortal } from '../../../components/general/DialogPortal'
import { Navigation } from '../../../components/navigation/Navigation'
import { ContentContainer } from '../../../components/page-layout'
import {
  BodyMediumRegular,
  BodyMediumSemiBoldCss,
  H4,
} from '../../../components/typography'
import { onHover } from '../../../core/lib/styleHelpers'
import { FloatingContainerButton } from '../../../employee/onboarding/components/FloatingContainerButton'
import { useMember } from '../../../member/hooks/useMember'
import { BulkUploadEmployeesDialog } from '../components/BulkUploadEmployeesDialog'
import { CreateOrEditEmployee } from '../components/CreateOrEditEmployee'
import { InviteEmployeeCard } from '../components/InviteEmployeeCard'

import type { SelectOption } from '../../../components/form/Select'
import type { HcpContract, MspContract } from '../../contracts/types/Contract'

export type EmployeeInformation = {
  email: string
  hcpContractId: string | 'NULL'
  mspContractId: string | 'NULL'
  activationDate: Date | null
  internalId: string
  hasExistingDevice: 'true' | 'false'
  manualMspHandover: 'true' | 'false'
}

export const InviteEmployeesScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const navigate = useNavigate()
  const { currentMember } = useMember()
  const isDesktopXl = useMediaQuery(breakpoints.desktopXl)

  // Refs
  const headerContainer = useRef<HTMLDivElement>(null)

  // State
  const [employees, setEmployees] = useState<EmployeeInformation[]>([])
  const [openBulkUpload, setOpenBulkUpload] = useState(false)
  const [openCreateOrEditEmployee, setOpenCreateOrEditEmployee] =
    useState<boolean>(false)
  const [formError, setFormError] = useState<string | null>(null)
  const [employeeErrors, setEmployeeErrors] = useState<number[]>([])
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeInformation>()

  // Data
  const navigationContainerBottom =
    document.querySelector('#navigation_container')?.getBoundingClientRect()
      .bottom ?? 0
  const fullRowOfCards = isDesktopXl
    ? employees.length % 4 === 0
    : employees.length % 3 === 0

  const mspDeliveryOptions: SelectOption[] = [
    {
      key: 'false',
      label: t('admin.invite-employees.msp-send'),
    },
    {
      key: 'true',
      label: t('admin.invite-employees.msp-manual'),
    },
  ]

  // API
  const [{ loading }, inviteEmployees] = useAuthMemberAxios(
    {
      url: Urls.inviteEmployees,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  const [{ data: contracts, error }] = useAuthMemberAxios<{
    hcpContracts: HcpContract[]
    mspContracts: MspContract[]
  }>({
    url: Urls.contractData,
    params: {
      companyId: currentMember.companyId,
    },
  })

  const hcpContractOptions = useMemo(() => {
    const contractOptions =
      contracts?.hcpContracts?.map((contract) => ({
        key: contract.id,
        label: contract.displayName || contract.name,
      })) ?? []

    return [
      {
        key: 'NULL',
        label: t('admin.invite-employees.information.no-hcp'),
      },
      ...contractOptions,
    ]
  }, [contracts?.hcpContracts, t])

  const mspContractOptions = useMemo(() => {
    const contractOptions =
      currentMember.company.mspContracts?.map((contract) => ({
        key: contract.id,
        label: contract.displayName || contract.name,
      })) ?? []

    return [
      {
        key: 'NULL',
        label: t('admin.invite-employees.information.no-charge-card'),
      },
      ...contractOptions,
    ]
  }, [currentMember.company.mspContracts, t])

  // -- Handlers --
  const clearEmptyEmployees = (employees: EmployeeInformation[]) => {
    return employees.filter(
      (employee) =>
        employee.email !== '' ||
        employee.hcpContractId !== 'NULL' ||
        employee.mspContractId !== 'NULL' ||
        employee.internalId !== ''
    )
  }

  const handleSubmit = async () => {
    trackEvent(EventType.Submit, 'submit_employee_invites')
    setFormError(null)
    setEmployeeErrors([])

    const setFormErrors = (
      invalidEmails: string[],
      extendedTranslationKey: string
    ) => {
      setFormError(
        invalidEmails.length === 1
          ? t(extendedTranslationKey, {
              count: invalidEmails.length,
              email: invalidEmails[0],
            })
          : t(extendedTranslationKey, {
              count: invalidEmails.length,
            })
      )

      const employeeIndexes = employees.reduce(
        (accumulator, employee, index) =>
          invalidEmails.includes(employee.email)
            ? [...accumulator, index]
            : accumulator,
        [] as number[]
      )

      setEmployeeErrors(employeeIndexes)
    }

    try {
      await inviteEmployees({
        data: {
          employees: employees.map((employee) => ({
            ...employee,
            hcpContractId:
              employee.hcpContractId === 'NULL' ? null : employee.hcpContractId,
            mspContractId:
              employee.mspContractId === 'NULL' ? null : employee.mspContractId,
          })),
          companyId: currentMember.companyId,
        },
      })
    } catch (error) {
      if (isAxiosError(error)) {
        switch (error.response?.data.key) {
          case 'formEmailNotUnique': {
            const invalidEmails = error.response?.data.invalidEmails

            setFormErrors(
              invalidEmails,
              'admin.invite-employees.error.email-not-unique.extended'
            )

            break
          }
          case 'databaseEmailNotUnique': {
            const invalidEmails = error.response?.data.invalidEmails
            setFormErrors(
              invalidEmails,
              'admin.invite-employees.error.email-already-exists.extended'
            )
            break
          }
          default: {
            toast.error(t('admin.invite-employees.error.generic'))
            break
          }
        }
        return
      }
    }

    // navigate to employees overview with success param
    router.navigate(EmployerRoutes.Employees, {
      state: { alerts: { inviteEmployees: true } },
    })
  }

  const handleBulkUploadEmployees = (employees: EmployeeInformation[]) => {
    trackEvent(EventType.Submit, 'bulk_upload_employees')
    setOpenBulkUpload(false)

    setEmployees((currentEmployees) =>
      [...clearEmptyEmployees(currentEmployees), ...employees].filter(
        (employee, index, self) =>
          index === self.findIndex((t) => t.email === employee.email)
      )
    )
  }

  const handleUpsertEmployee = (
    employee: EmployeeInformation,
    shouldClose: boolean
  ) => {
    setEmployeeErrors([])
    setSelectedEmployee(undefined)

    setEmployees((currentEmployees) => {
      const newEmployees = [...currentEmployees]

      if (selectedEmployee) {
        const index = newEmployees.findIndex(
          (employee) => employee.email === selectedEmployee.email
        )

        newEmployees[index] = employee
      } else {
        newEmployees.push(employee)
      }

      return newEmployees
    })

    toast.success(
      selectedEmployee
        ? t('admin.invite-employees.employee-saved')
        : t('admin.invite-employees.employee-added')
    )

    if (shouldClose) {
      setOpenCreateOrEditEmployee(false)
    }
  }

  const handleRemoveEmployee = (employee: EmployeeInformation) => {
    setEmployeeErrors([])
    setSelectedEmployee(undefined)

    setEmployees((currentEmployees) =>
      currentEmployees.filter(
        (currentEmployee) => currentEmployee.email !== employee.email
      )
    )

    setOpenCreateOrEditEmployee(false)
  }

  // -- Render --
  if (error) {
    throw new Error('Failed to load HCP contracts')
  }

  return (
    <>
      <Navigation options={{ onGoBack: () => navigate(-1) }} />

      <StEmployeesWrapper>
        <ContentContainer topPadding={false}>
          <StHeader $top={navigationContainerBottom} ref={headerContainer}>
            <StTitle>
              {t('admin.invite-employees.information.title')}
              <StHeaderCount>
                {clearEmptyEmployees(employees).length}
              </StHeaderCount>
            </StTitle>
            <StActions>
              <ButtonSecondary
                onClick={() => setOpenBulkUpload(true)}
                icon={['fasr', 'file-upload']}
                iconAlignment="left"
                size="sm"
              >
                {t('admin.invite-employees.upload-document')}
              </ButtonSecondary>
              <ButtonPrimary
                onClick={() => setOpenCreateOrEditEmployee(true)}
                icon={['fass', 'plus']}
                iconAlignment="left"
                size="sm"
              >
                {t('admin.invite-employees.add-employee')}
              </ButtonPrimary>
            </StActions>
          </StHeader>

          <StBannerContainer>
            <Banner
              type={BannerType.INFORMATION}
              icon={['fass', 'info-square']}
            >
              {t('admin.invite-employees.information.banner.1')}
              <FontAwesomeIcon icon={['fass', 'plus']} />
              {t('admin.invite-employees.information.banner.2')}
              <FontAwesomeIcon icon={['fasr', 'file-upload']} />
              {t('admin.invite-employees.information.banner.3')}
              <FontAwesomeIcon icon={['fasr', 'send']} />
            </Banner>
          </StBannerContainer>

          {formError && (
            <StBannerContainer>
              <Banner
                type={BannerType.ERROR}
                icon={['fasr', 'circle-exclamation']}
              >
                {formError}
              </Banner>
            </StBannerContainer>
          )}

          <StEmployeesGrid>
            {clearEmptyEmployees(employees).map((employee, index) => (
              <InviteEmployeeCard
                key={index}
                employee={employee}
                selectedHcpContract={contracts?.hcpContracts?.find(
                  (contract) => contract.id === employee.hcpContractId
                )}
                selectedMspContract={contracts?.mspContracts?.find(
                  (contract) => contract.id === employee.mspContractId
                )}
                error={employeeErrors.includes(index)}
                onClick={(employee) => {
                  setOpenCreateOrEditEmployee(true)
                  setSelectedEmployee(employee)
                }}
              />
            ))}
            {!fullRowOfCards && (
              <StAddEmployeeCard
                onClick={() => setOpenCreateOrEditEmployee(true)}
              >
                <StContent>
                  <FontAwesomeIcon icon={['fass', 'plus']} />
                  <BodyMediumRegular>
                    {t('admin.invite-employees.add-employee')}
                  </BodyMediumRegular>
                </StContent>
              </StAddEmployeeCard>
            )}
          </StEmployeesGrid>

          {fullRowOfCards && (
            <AddEmployeeRowButton>
              <ButtonTertiary
                onClick={() => setOpenCreateOrEditEmployee(true)}
                icon={['fass', 'plus']}
                iconAlignment="left"
                type="button"
              >
                {t('admin.invite-employees.add-row')}
              </ButtonTertiary>
            </AddEmployeeRowButton>
          )}

          <FloatingContainerButton
            title={t('admin.invite-employees.information.invite')}
            icon={['fass', 'paper-plane-top']}
            disabled={loading}
            onClick={() => {
              handleSubmit()
            }}
          />
        </ContentContainer>
      </StEmployeesWrapper>

      <BulkUploadEmployeesDialog
        open={openBulkUpload}
        handleBulkUploadEmployees={(employees: EmployeeInformation[]) => {
          handleBulkUploadEmployees(employees)
        }}
        handleClose={() => setOpenBulkUpload(false)}
        hcpContractOptions={hcpContractOptions}
        mspContractOptions={mspContractOptions}
        mspDeliveryOptions={mspDeliveryOptions}
        disableMspContract={
          !currentMember.company.mspContracts ||
          currentMember.company.mspContracts.length === 0
        }
      />

      <RadixDialog.Root
        open={openCreateOrEditEmployee}
        onOpenChange={(value) => {
          setOpenCreateOrEditEmployee(value)
          setSelectedEmployee(undefined)
        }}
      >
        <DialogPortal
          title={t('admin.invite-employees.add-employee')}
          width="800px"
          showHeader={true}
        >
          <CreateOrEditEmployee
            key={selectedEmployee?.email}
            hcpContractOptions={hcpContractOptions}
            mspContractOptions={mspContractOptions}
            mspDeliveryOptions={mspDeliveryOptions}
            onUpsertEmployee={handleUpsertEmployee}
            onRemoveEmployee={handleRemoveEmployee}
            onClose={() => {
              setOpenCreateOrEditEmployee(false)
              setSelectedEmployee(undefined)
            }}
            existingEmailAddresses={employees
              .filter((employee) => employee.email !== selectedEmployee?.email)
              .map((employee) => employee.email)}
            initialValues={selectedEmployee}
          />
        </DialogPortal>
      </RadixDialog.Root>
    </>
  )
}

const StEmployeesWrapper = styled.div`
  margin-bottom: ${({ theme }) =>
    `calc(${theme.UI.SpacingPx.Space6} + var(--sticky-button-container-height))`};
`

const StTitle = styled(H4)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StBannerContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  svg {
    margin: 0 4px;
    display: inline;
  }
`

const StHeader = styled.div<{
  $top: number
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  background-color: ${({ theme }) => theme.theme.colors.white};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};

  position: sticky;
  top: ${({ $top }) => $top + 'px'};
  z-index: 100;
`

const StHeaderCount = styled.span`
  ${BodyMediumSemiBoldCss}
  background-color: ${({ theme }) => theme.theme.colors['secondary-1']};
  color: ${({ theme }) => theme.theme.colors.white};
  border-radius: 999px;
  padding: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space3}`};

  height: ${({ theme }) => theme.UI.SpacingPx.Space9};
  min-width: ${({ theme }) => theme.UI.SpacingPx.Space9};

  display: grid;
  place-items: center;
`

const StActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  button {
    white-space: nowrap;
  }
`

const StEmployeesGrid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  grid-template-columns: repeat(3, 1fr);

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media ${breakpoints.desktopXl} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const AddEmployeeRowButton = styled.div`
  display: grid;
  place-items: center;
  grid-column: span 6;
`

const StAddEmployeeCard = styled.div`
  display: grid;
  place-items: center;
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  cursor: pointer;

  align-self: stretch;

  svg {
    color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  }

  ${onHover(css`
    border-color: ${({ theme }) => theme.theme.colors.black};
  `)}
`

const StContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
