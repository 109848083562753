import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { styled } from 'styled-components'

import { ContentContainer } from '../../../components/page-layout'
import { Tabs } from '../../../components/Tabs/Tabs'
import { H4 } from '../../../components/typography'
import { EmployerPersonalDataEdit } from '../content/EmployerPersonalDataEdit'
import { FinancialSettings } from '../content/FinancialSettings'

export const EmployerSettingsScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const [queryParameters, setQueryParameters] = useSearchParams()

  // -- Vars --
  const currentTab = queryParameters.get('tab')

  const pageItems = [
    {
      content: <EmployerPersonalDataEdit />,
      value: 'profile',
      title: t('employer.profile.title'),
    },
    {
      content: <FinancialSettings />,
      value: 'financials',
      title: t('employer.financial.title'),
    },
  ]

  // -- Render --
  return (
    <ContentContainer>
      <StHeaderContainer>
        <H4>{t('employer.settings.title')}</H4>
      </StHeaderContainer>

      <Tabs
        defaultValue="profile"
        items={pageItems}
        value={currentTab || 'profile'}
        setValue={(value) =>
          // We don't want to add a new entry to the history stack when changing tabs
          setQueryParameters({ tab: value }, { replace: true })
        }
      />
    </ContentContainer>
  )
}

const StHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
