import json2mq from 'json2mq'

export const widths = {
  mobile: 576,
  tablet: 800,
  desktop: 1000,
  desktopXl: 1260,
}

const breakpoints = {
  mobile: json2mq({ minWidth: widths.mobile }),
  tablet: json2mq({ minWidth: widths.tablet }),
  desktop: json2mq({ minWidth: widths.desktop }),
  desktopXl: json2mq({ minWidth: widths.desktopXl }),
}

function toRem(pixelValue: number): string {
  return `${pixelValue / 16}rem`
}

export { breakpoints, toRem }
