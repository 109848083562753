import type { Color, ConnectionMethod, Product } from 'types'

export const getConnectionMethodName = (
  connectionMethod: ConnectionMethod,
  language: 'nl' | 'en' | 'fr'
) => {
  switch (language) {
    case 'en': {
      return connectionMethod.nameEn
    }

    case 'fr': {
      return connectionMethod.nameFr
    }

    default: {
      return connectionMethod.nameNl
    }
  }
}

export const getColorName = (color: Color, language: 'nl' | 'en' | 'fr') => {
  switch (language) {
    case 'en': {
      return color.nameEn
    }

    case 'fr': {
      return color.nameFr
    }

    default: {
      return color.nameNl
    }
  }
}

export const getProductDescription = (
  product: Product,
  language: 'nl' | 'en' | 'fr'
) => {
  switch (language) {
    case 'en': {
      return product.descriptionEn
    }

    case 'fr': {
      return product.descriptionFr
    }

    default: {
      return product.descriptionNl
    }
  }
}

export const getProductSpecifications = (
  product: Product,
  language: 'nl' | 'en' | 'fr'
) => {
  switch (language) {
    case 'en': {
      return product.specificationsEn
    }

    case 'fr': {
      return product.specificationsFr
    }

    default: {
      return product.specificationsNl
    }
  }
}

export const getProductName = (
  product: Product,
  language: 'nl' | 'en' | 'fr'
) => {
  switch (language) {
    case 'en': {
      return product.nameEn
    }

    case 'fr': {
      return product.nameFr
    }

    default: {
      return product.nameNl
    }
  }
}
