import { useTranslation } from 'react-i18next'

import example1 from '../../assets/examples/chargerLocation1.png'
import example2 from '../../assets/examples/chargerLocation2.png'
import { MobileUploadContent } from '../../components/upload/MobileUploadContent'
import { MobileUploadLoader } from '../../components/upload/MobileUploadLoader'
import { MobileUploadSuccess } from '../../components/upload/MobileUploadSuccess'
import { useTokenImagePolling } from '../../hooks/useTokenImagePolling'
import { useTokenMultiImageUpload } from '../../hooks/useTokenMultiImageUpload'
import { useTokenOnboarding } from '../../hooks/useTokenOnboarding'
import { TOTAL_STEPS } from '../upload/ExtraImagesStep'

const CURRENT_STEP = 4
const examples = [example1, example2]

export const TokenChargerLocationImageStep = () => {
  // -- Hooks --
  const { values, handleNext, token } = useTokenOnboarding()
  const { t } = useTranslation()
  useTokenImagePolling()

  const { loading, handleAddImage, handleRemoveImage } =
    useTokenMultiImageUpload(
      'chargerLocationImage',
      'chargerLocationImageIds',
      token
    )

  // -- Handlers --
  const handleSubmit = () => {
    handleNext(values)
  }

  // -- Render --
  if (loading) {
    return (
      <MobileUploadLoader currentStep={CURRENT_STEP} totalSteps={TOTAL_STEPS} />
    )
  }

  if (values.chargerLocationImageIds?.length) {
    return (
      <MobileUploadSuccess
        imageIds={values.chargerLocationImageIds}
        currentStep={CURRENT_STEP}
        totalSteps={TOTAL_STEPS}
        title={t('onboarding.chargerLocation-image.title')}
        onSubmit={handleSubmit}
        onDelete={handleRemoveImage}
        onAddImage={
          values.chargerLocationImageIds.length >= 5
            ? undefined
            : handleAddImage
        }
        token={token}
      />
    )
  }

  return (
    <MobileUploadContent
      uploadPicture={handleAddImage}
      title={t('onboarding.chargerLocation-image.title')}
      description={t('onboarding.chargerLocation-image.description')}
      currentStep={CURRENT_STEP}
      totalSteps={TOTAL_STEPS}
      examples={examples}
    />
  )
}
