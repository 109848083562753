// This creates a deck.gl overlay for the mapbox map

import { MapboxOverlay } from '@deck.gl/mapbox'
import { useControl } from 'react-map-gl'

import type { MapboxOverlayProps } from '@deck.gl/mapbox'

// We use this approach over the deckgl React wrapper because we need access to the mapbox ref
export function DeckGLOverlay(
  props: MapboxOverlayProps & {
    interleaved?: boolean
  }
) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props))
  overlay.setProps(props)

  return null
}
