import { useContext } from 'react'

import { tokenflowContext } from '../components/token-flow/TokenFlow'

export const useTokenOnboarding = () => {
  const onboarding = useContext(tokenflowContext)

  if (!onboarding) {
    throw new Error(
      'useOnboarding needs to be used inside the onboarding provider!'
    )
  }

  return onboarding
}
