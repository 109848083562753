import { useTranslation } from 'react-i18next'

import example1 from '../../assets/examples/electricalMeter1.png'
import example2 from '../../assets/examples/electricalMeter2.png'
import { MobileUploadContent } from '../../components/upload/MobileUploadContent'
import { MobileUploadLoader } from '../../components/upload/MobileUploadLoader'
import { MobileUploadSuccess } from '../../components/upload/MobileUploadSuccess'
import { useTokenImagePolling } from '../../hooks/useTokenImagePolling'
import { useTokenMultiImageUpload } from '../../hooks/useTokenMultiImageUpload'
import { useTokenOnboarding } from '../../hooks/useTokenOnboarding'
import { TOTAL_STEPS } from '../upload/ExtraImagesStep'

const CURRENT_STEP = 3
const examples = [example1, example2]

export const TokenElectricityMeterImageStep = () => {
  // -- Hooks --
  const { values, handleNext, token } = useTokenOnboarding()
  const { t } = useTranslation()
  useTokenImagePolling()

  const { loading, handleAddImage, handleRemoveImage } =
    useTokenMultiImageUpload(
      'electricityMeter',
      'electricityMeterImageIds',
      token
    )

  // -- Handlers --
  const handleSubmit = () => {
    handleNext(values)
  }

  // -- Render --
  if (loading) {
    return (
      <MobileUploadLoader currentStep={CURRENT_STEP} totalSteps={TOTAL_STEPS} />
    )
  }

  if (values.electricityMeterImageIds?.length) {
    return (
      <MobileUploadSuccess
        imageIds={values.electricityMeterImageIds}
        currentStep={CURRENT_STEP}
        totalSteps={TOTAL_STEPS}
        title={t('onboarding.electricityMeter-image.title')}
        onSubmit={handleSubmit}
        onDelete={handleRemoveImage}
        onAddImage={
          values.electricityMeterImageIds.length >= 5
            ? undefined
            : handleAddImage
        }
        token={token}
      />
    )
  }

  return (
    <MobileUploadContent
      uploadPicture={handleAddImage}
      title={t('onboarding.electricityMeter-image.title')}
      description={t('onboarding.electricityMeter-image.description')}
      currentStep={CURRENT_STEP}
      totalSteps={TOTAL_STEPS}
      examples={examples}
    />
  )
}
