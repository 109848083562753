// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'

import type { OnboardingValues } from './useOnboardingState'
import type { TokenFlowValues } from './useTokenFlowState'
import type { RefetchFunction } from '../../../api/hooks/types'
import type { AxiosPromise, AxiosRequestConfig } from 'axios'
import type { Dispatch, SetStateAction } from 'react'

type SetValues =
  | Dispatch<SetStateAction<OnboardingValues>>
  | Dispatch<SetStateAction<TokenFlowValues>>

type DeleteImageType = {
  setValues: SetValues
  executeDelete: (
    config?: string | AxiosRequestConfig<any> | undefined
  ) => AxiosPromise<any>
  key: string
  valueKey: keyof Pick<
    OnboardingValues,
    | 'extraImageIds'
    | 'fuseBoxImageIds'
    | 'electricalBoxImageIds'
    | 'chargerLocationImageIds'
    | 'electricityMeterImageIds'
  >
  url: string
}

type AddImageType = {
  setValues: SetValues
  uploadExtraImage: RefetchFunction<
    any,
    {
      imageId: string
      name: string
    }[]
  >
  valueKey: keyof Pick<
    OnboardingValues,
    | 'extraImageIds'
    | 'fuseBoxImageIds'
    | 'electricalBoxImageIds'
    | 'chargerLocationImageIds'
    | 'electricityMeterImageIds'
  >
}

export const useMultiUploadFunctions = () => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Functions
  const deleteImage = ({
    setValues,
    executeDelete,
    key,
    valueKey,
    url,
  }: DeleteImageType) => {
    return async (zohoId: string) => {
      setValues((previous: any) => {
        const images = previous[valueKey]?.filter(
          (imageId: string) => imageId !== zohoId
        )

        return { ...previous, [valueKey]: images }
      })

      const { status } = await executeDelete({
        url: replaceParametersInPath(url, {
          key,
          imageId: zohoId,
        }),
      })

      if (status !== 200 && status !== 204) {
        toast.error(t('onboarding.mobile-images.error'))
      }
    }
  }

  const addImage = ({
    uploadExtraImage,
    setValues,
    valueKey,
  }: AddImageType) => {
    return async (files: File[]) => {
      const formData = new FormData()
      files.forEach((file) => formData.append('files', file))

      const upload = await uploadExtraImage({
        data: formData,
      })

      // Update state if upload succeeded
      if (upload.status === 200 || upload.status === 201) {
        const imageIds = upload.data.map((image) => image.imageId)

        setValues((previous: any) => {
          const valueIds = [
            ...new Set([...(previous[valueKey] || []), ...imageIds]),
          ]

          return {
            ...previous,
            [valueKey]: valueIds,
          }
        })
      } else {
        toast.error(t('onboarding.mobile-images.error'))
      }
    }
  }

  return {
    deleteImage,
    addImage,
  }
}
