import { useContext } from 'react'

import { hemsConfigurationContext } from '../context/hemsConfigurationContext'

import { useHemsFlow } from './useHemsFlow'

export const useHemsConfiguration = () => {
  const hemsConfiguration = useContext(hemsConfigurationContext)
  const hemsFlow = useHemsFlow()

  if (!hemsConfiguration) {
    throw new Error(
      'useHemsFlow must be used within a HemsConfigurationProvider'
    )
  }

  return { ...hemsConfiguration, ...hemsFlow }
}
