import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import {
  BodyMediumSemiBold,
  BodySmallRegular,
  H4,
} from '../../../../components/typography'
import dongle from '../assets/dongle-front.png'
import { useHemsFlow } from '../hooks/useHemsFlow'
import { usePingDongleSatus } from '../hooks/usePingDongleSatus'

export const UtpReconnectionStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { flowNavigation } = useHemsFlow()

  // -- Data --
  const { online } = usePingDongleSatus()

  // -- Effects --
  useEffect(() => {
    if (online) {
      flowNavigation.next()
    }
  }, [online])

  // -- Render --
  return (
    <>
      <StTextContainer>
        <H4>
          {t(
            `employee.hems.dongle-onboarding.utp-provisioning.CONNECTING.title`
          )}
        </H4>
        <BodySmallRegular>
          {t(
            `employee.hems.dongle-onboarding.utp-provisioning.CONNECTING.description`
          )}
        </BodySmallRegular>
      </StTextContainer>

      <StImage src={dongle} alt="dongle" />

      <FloatingButtonContainer>
        <StSpinnerContainer>
          <StSpinner />
          <BodyMediumSemiBold>
            {t('employee.hems.dongle-onboarding.connecting.loading')}
          </BodyMediumSemiBold>
        </StSpinnerContainer>
      </FloatingButtonContainer>
    </>
  )
}

const StTextContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  h4 {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StImage = styled.img`
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: -1;

  width: 100%;
  max-height: 50%;

  object-fit: contain;
`

const StSpinner = styled.span`
  width: 18px;
  height: 18px;
  border: 2.5px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const StSpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
