import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import {
  BodySmallRegular,
  BodySmallSemiBold,
} from '../../../components/typography'
import { formatDecimals } from '../../../core/utils/number'

import {
  StCardRow,
  StChargModeContainer,
  StChargeModeSymbol,
  StChip,
  StDuration,
  StGradientCard,
  StTime,
  formatTime,
  useChargeModes,
  useDistanceToNowFormatter,
} from './ChargingSessionCard'

import type { UserSettingsMode } from '../../hems/types/enums'

export function ActiveChargingSessionCard({
  customerName,
  mode,
  kWh,
  startTime,
}: ActiveChargingSessionCardProps) {
  const { theme } = useTheme()
  const { t } = useTranslation()

  const chargeModes = useChargeModes()
  const chargeMode = chargeModes.find(
    (chargeMode) => chargeMode.value === mode
  )!

  const formatDistanceToNow = useDistanceToNowFormatter()

  return (
    <StGradientCard>
      <StCardRow>
        <BodySmallSemiBold>{customerName}</BodySmallSemiBold>
      </StCardRow>
      <StCardRow>
        <StTime>
          <BodySmallRegular>
            {t('employee.home.charging-session.card.time', {
              time: formatTime(startTime),
            })}
          </BodySmallRegular>
          <StDuration>
            <FontAwesomeIcon icon={['fasr', 'clock']} fontSize={12} />
            <BodySmallRegular>
              {formatDistanceToNow(startTime)}
            </BodySmallRegular>
          </StDuration>
        </StTime>
        <BodySmallRegular>+ {formatDecimals(kWh)} kWh</BodySmallRegular>
      </StCardRow>
      <StCardRow>
        <StChip $color="primary">
          <FontAwesomeIcon
            icon={['fass', 'bolt']}
            color={theme.colors['nonary-1']}
            fontSize={12}
          />{' '}
          <BodySmallRegular>
            {t('employee.home.charging-session.card.charging.chip')}
          </BodySmallRegular>
        </StChip>
        <StChargModeContainer>
          <StChargeModeSymbol $color={chargeMode.color}>
            <FontAwesomeIcon fontSize={14} icon={chargeMode.icon} />
          </StChargeModeSymbol>
          <BodySmallRegular>{chargeMode.label}</BodySmallRegular>
        </StChargModeContainer>
      </StCardRow>
    </StGradientCard>
  )
}

type ActiveChargingSessionCardProps = {
  customerName: string
  kWh: number
  startTime: Date
  mode: UserSettingsMode
}
