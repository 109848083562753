import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EspProvisioning } from '@general-galactic/capacitor-esp-idf-provisioning'
import { useState } from 'react'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import intercomLogo from '../../../../components/assets/intercom_full.svg'
import { ButtonQuaternary } from '../../../../components/button/ButtonQuaternary'
import { WizardItem } from '../../../../components/navigation/WizardItem'
import { useIntercom } from '../../../../intercom/hooks/useIntercom'
import { useUser } from '../../../../user/hooks/useUser'
import { hemsConfigurationContext } from '../context/hemsConfigurationContext'
import { hemsFlowContext } from '../context/hemsFlowContext'
import {
  HemsOnboardingSteps,
  useHemsOnboardingState,
} from '../hooks/useHemsOnboardingState'
import { useHemsOnboardingSteps } from '../hooks/useHemsOnboardingSteps'

import type { HemsConfigutrationData } from '../hooks/useHemsOnboardingState'
import type { ESPNetwork } from '@general-galactic/capacitor-esp-idf-provisioning'

type HemsOnboardingProps = {
  onClose: () => void
}

export const HemsOnboarding = ({ onClose }: HemsOnboardingProps) => {
  // -- State --
  const [deviceName, setDeviceName] = useState<string>('')
  const [macAddress, setMacAddress] = useState<string | null>(null)
  const [selectedNetwork, setSelectedNetwork] = useState<ESPNetwork | null>(
    null
  )
  const [networkPassword, setNetworkPassword] = useState<string | null>(null)

  // -- Hooks --
  const { show } = useIntercom()
  const {
    headerState,
    currentStep,
    handleNext,
    handlePrevious,
    setStep,
    hemsConfigurationData,
    setHemsConfigurationData,
  } = useHemsOnboardingState()
  const hemspOnboardingSteps = useHemsOnboardingSteps()
  const { refetch: refetchUser } = useUser()

  // -- Data --
  const [, completeOnboarding] = useAuthMemberAxios(
    {
      url: Urls.completeOnboarding,
      method: 'POST',
    },
    { manual: true }
  )

  // -- Handlers --
  const handleClose = async () => {
    if (deviceName.length > 0) {
      EspProvisioning.disconnect({ deviceName })
      setDeviceName('')
    }

    // These steps have impact on the user, so we revalidate the user
    if (
      currentStep === HemsOnboardingSteps.Contract ||
      currentStep === HemsOnboardingSteps.EnergyImport ||
      currentStep === HemsOnboardingSteps.Confirmation
    ) {
      await refetchUser()
    }

    onClose()
  }

  const handleConfigureHems = async (data: HemsConfigutrationData) => {
    const { status } = await completeOnboarding({ data })

    if (status !== 201) {
      throw new Error('Failed to configure HEMS')
    }
  }

  // -- Render --
  return (
    <>
      <StHeader>
        <StFlex>
          {!headerState.cantGoBack && (
            <StIconButton onClick={handlePrevious}>
              <FontAwesomeIcon icon={['fasr', 'chevron-left']} fontSize={22} />
            </StIconButton>
          )}

          <WizardItem
            currentStep={headerState.state}
            step={{
              key: headerState.state.key,
              order: headerState.state.order,
            }}
          />
        </StFlex>
        <StFlex>
          <StIntercomTrigger border={false} onClick={show}>
            <img src={intercomLogo} alt="Intercom" />
          </StIntercomTrigger>

          <StCloseButton border={false} onClick={handleClose}>
            <FontAwesomeIcon icon={['fasr', 'xmark-large']} />
          </StCloseButton>
        </StFlex>
      </StHeader>

      <hemsFlowContext.Provider
        value={{
          flowNavigation: {
            next: handleNext,
            previous: handlePrevious,
            close: handleClose,
            setStep: setStep,
          },
          deviceName,
          setDeviceName,
          macAddress,
          setMacAddress,
          selectedNetwork,
          setSelectedNetwork,
          networkPassword,
          setNetworkPassword,
        }}
      >
        <hemsConfigurationContext.Provider
          value={{
            hemsConfigurationData,
            setHemsConfigurationData,
            configureHems: handleConfigureHems,
          }}
        >
          {hemspOnboardingSteps[currentStep]}
        </hemsConfigurationContext.Provider>
      </hemsFlowContext.Provider>
    </>
  )
}

const StIconButton = styled.button`
  background-color: transparent;
  /* Increase hitslop */
  padding: ${({ theme }) => theme.UI.SpacingPx.Space1};
  border: none;
  width: min-content;

  cursor: pointer;

  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StHeader = styled.div`
  position: sticky;
  top: 0;

  display: flex;

  align-items: center;
  justify-content: space-between;

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
  /* Compensate for hitslop */
  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space4};

  background-color: ${({ theme }) => theme.theme.colors.white};

  h6 {
    margin: 0;
    text-align: center;
  }

  svg {
    margin-bottom: -4px;
  }
`

const StFlex = styled.div`
  display: flex;
  align-items: center;
`

const StIntercomTrigger = styled(ButtonQuaternary)`
  display: grid;
  place-items: center;

  border: none;
  background-color: ${({ theme }) => theme.theme.colors['primary-1']};
  color: ${({ theme }) => theme.theme.colors.black};
  border-radius: 50%;

  width: ${({ theme }) => theme.UI.SpacingPx.Space10};
  height: ${({ theme }) => theme.UI.SpacingPx.Space10};
  aspect-ratio: 1 / 1;
  padding: 0;
`

const StCloseButton = styled(ButtonQuaternary)`
  display: grid;
  place-items: center;

  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.components.menu.wizard['item-icon']};
  border-radius: 50%;

  width: ${({ theme }) => theme.UI.SpacingPx.Space10};
  height: ${({ theme }) => theme.UI.SpacingPx.Space10};

  aspect-ratio: 1 / 1;
  padding: 0;
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space1};

  span {
    margin-top: -5px;
  }
`
