import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import 'chart.js/auto'
import { merge } from 'chart.js/helpers'
import { useEffect, useRef, useState } from 'react'
import { Chart as ReactChart } from 'react-chartjs-2'
import { useSetRecoilState } from 'recoil'
import { styled, useTheme } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { drawBarChartTooltip, drawLineChartTooltip } from '../../core/lib/chart'
import { disableScrollAtom } from '../../core/recoil/atoms'

import type {
  ActiveElement,
  ChartData,
  ChartOptions,
  ChartTypeRegistry,
} from 'chart.js/auto'
import type { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

type ChartProps<T extends keyof ChartTypeRegistry> = {
  type: T
  data:
    | ((
        selectedDatapoints: ActiveElement[] | null
      ) => ChartData<T, Array<number | undefined>>)
    | ChartData<T, Array<number | undefined>>
  options?: ChartOptions<T>
  stacked?: boolean
  processTooltipData: (data: ActiveElement[]) => string
  onSelectedDataPointsChange?: (data: ActiveElement[] | null) => void
}

export function Chart<T extends keyof ChartTypeRegistry>({
  type,
  data,
  options,
  stacked = false,
  processTooltipData,
  onSelectedDataPointsChange,
}: ChartProps<T>) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const setDisableScroll = useSetRecoilState(disableScrollAtom)

  const [selectedDataPoints, setSelectedDataPoints] = useState<
    ActiveElement[] | null
  >(null)
  const [allowHoverEvents, setAllowHoverEvents] = useState(false)

  const chartRef = useRef<ChartJSOrUndefined<T, number[], number>>()

  // -- Data --
  const actualData =
    typeof data === 'function' ? data(selectedDataPoints) : data

  const defaultOptions: ChartOptions<T> = {
    maintainAspectRatio: isDesktop ? false : true,
    responsive: true,
    layout: {
      padding: {
        top: 40,
        left: -15,
      },
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
        hoverRadius: 0,
      },
    },
    scales: {
      x: {
        stacked,
        ticks: {
          maxTicksLimit: isDesktop ? 15 : 6,
          padding: 0,
          color: '#A5ABB6',
        },
        grid: {
          tickLength: 16,
          tickBorderDash: [isDesktop ? 2 : 1.85],
        },
        border: {
          dash: [isDesktop ? 2 : 1.85],
          display: false,
        },
      },
      y: {
        stacked,
        ticks: {
          maxTicksLimit: 6,
          includeBounds: false,
          padding: 15,
          color: '#A5ABB6',
        },
        grid: {
          tickLength: 0,
          tickBorderDash: [isDesktop ? 2 : 1.85],
        },
        border: {
          dash: [isDesktop ? 2 : 1.85],
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      blossomTooltip: {},
    },
    onHover: (event, item, chart) => {
      const isLastItem =
        item?.length > 0 &&
        item[0]?.index === chart.data.datasets[0].data.length - 1

      if (
        event.x &&
        isLastItem &&
        //@ts-expect-error - element type is wrong :/
        event.x > item[0].element.x + (item[0].element.width ?? 0)
      ) {
        setSelectedDataPoints(null)
        return
      }

      if (
        allowHoverEvents &&
        item?.length > 0 &&
        (item[0]?.index || item[0].index === 0) &&
        selectedDataPoints?.[0]?.index !== item[0]?.index
      ) {
        const values = chart.data.datasets
          .map((dataset) => {
            return dataset.data[item[0].index]
          })
          .filter((value) => value !== undefined)

        values.length === 0
          ? setSelectedDataPoints(null)
          : setSelectedDataPoints(item)
      }
    },
  } as ChartOptions<T>

  // -- Handlers --
  const handleTouchStart = (disableTouch = true) => {
    // Disable scroll on body
    setDisableScroll(disableTouch)

    setAllowHoverEvents(true)
  }

  const handleTouchEnd = () => {
    setSelectedDataPoints(null)
    setAllowHoverEvents(false)
    setDisableScroll(false)
  }

  // -- Effects --
  useEffect(() => {
    if (chartRef.current) {
      // @ts-expect-error - blossomTooltip is a custom plugin
      chartRef.current.selectedDataPoints = selectedDataPoints
      chartRef.current.update()
    }

    onSelectedDataPointsChange?.(selectedDataPoints)

    if (Capacitor.isNativePlatform() && selectedDataPoints) {
      Haptics.impact({ style: ImpactStyle.Light })
    }
  }, [onSelectedDataPointsChange, selectedDataPoints])

  return (
    <StChart>
      <ReactChart
        // Make sure chart is re-rendered when desktop/mobile changes
        key={isDesktop ? 'desktop' : 'mobile'}
        type={type}
        onTouchStart={() => {
          handleTouchStart()
        }}
        onTouchEnd={() => {
          handleTouchEnd()
        }}
        onMouseEnter={() => {
          handleTouchStart(false)
        }}
        onMouseLeave={() => {
          handleTouchEnd()
        }}
        ref={chartRef}
        data={actualData}
        options={merge(defaultOptions, options)}
        plugins={[
          {
            id: 'blossomTooltip',
            afterDatasetsDraw(chart) {
              // @ts-expect-error - blossomTooltip is a custom plugin
              let selectedDataPoints = chart.selectedDataPoints

              // Sort datapoints by y value ascending (top to bottom)
              selectedDataPoints = selectedDataPoints?.sort(
                (a: ActiveElement, b: ActiveElement) =>
                  a.element.y - b.element.y
              )

              const context = chart.ctx
              const chartArea = chart.chartArea

              if (!selectedDataPoints || selectedDataPoints.length === 0) return

              const firstElement = selectedDataPoints[0]
              const lastElement = selectedDataPoints.at(-1)

              const hasNegativeValues = actualData.datasets.some((dataset) =>
                dataset.data.some((value) => (value ?? 0) < 0)
              )

              const x = firstElement.element.x
              const topY = firstElement.element.y
              const bottomY =
                selectedDataPoints.length > 1
                  ? hasNegativeValues
                    ? lastElement.element.y
                    : 0
                  : firstElement.element.y + firstElement.element.height

              // Draw tooltip
              switch (type) {
                case 'bar': {
                  drawBarChartTooltip(
                    context,
                    chartArea,
                    x,
                    topY,
                    bottomY,
                    theme,
                    processTooltipData(selectedDataPoints)
                  )
                  break
                }

                case 'line': {
                  drawLineChartTooltip(
                    context,
                    chartArea,
                    x,
                    theme,
                    selectedDataPoints.map((dataPoint: ActiveElement) => {
                      return { x: dataPoint.element.x, y: dataPoint.element.y }
                    }),
                    processTooltipData(selectedDataPoints)
                  )
                  break
                }

                default: {
                  break
                }
              }
            },
          },
        ]}
      />
    </StChart>
  )
}

const StChart = styled.div`
  flex-grow: 1;

  @media (${breakpoints.desktop}) {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space1};

    border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }
`
