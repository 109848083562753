import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import type { SizeProp } from '@fortawesome/fontawesome-svg-core'

type SpinnerProps = { size?: SizeProp; secondary?: boolean }

export const Spinner = ({ size, secondary = false }: SpinnerProps) => {
  return (
    <StSpinner
      icon={['fass', 'circle-notch']}
      size={size}
      $secondary={secondary}
    />
  )
}

const StSpinner = styled(FontAwesomeIcon)<{ $secondary?: boolean }>`
  color: ${({ theme, $secondary }) =>
    $secondary
      ? theme.theme.colors['nonary-7']
      : theme.theme.colors['primary-0']};
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  height: ${({ theme }) => theme.UI.SpacingPx.Space4};
  width: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
