import { useTranslation } from 'react-i18next'

import dongleButton from '../assets/dongle-button.png'
import dongleFlow from '../assets/dongle-flow.jpeg'
import donglePorts from '../assets/dongle-ports.png'
import dongleTop from '../assets/dongle-top.png'
import { ConnectionSelectionStep } from '../steps/ConnectionSelectionStep'
import { DongleConnectionStep } from '../steps/DongleConnectionStep'
import { HemsInfoStep } from '../steps/HemsInfoStep'
import { ReconnectionSuccessStep } from '../steps/ReconnectionSuccessStep'
import { TroubleShootQuestionStep } from '../steps/TroubleShootQuestionStep'
import { UtpReconnectionStep } from '../steps/UtpReconnectionStep'
import { WifiNetworkSelectionStep } from '../steps/WifiNetworkSelectionStep'
import { WifiPasswordStep } from '../steps/WifiPasswordStep'
import { WifiReconnectionStep } from '../steps/WifiReconnectionStep'

import { HemsConnectionLostSteps } from './useHemsConnectionLostState'

import type { ReactNode } from 'react'

export const useHemsConnectionLostSteps = (): Record<
  HemsConnectionLostSteps,
  ReactNode
> => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Render --
  return {
    [HemsConnectionLostSteps.TroubleshootQuestion]: (
      <TroubleShootQuestionStep />
    ),
    [HemsConnectionLostSteps.ConnectionSelection]: <ConnectionSelectionStep />,
    [HemsConnectionLostSteps.Reset]: (
      <HemsInfoStep
        visual={dongleTop}
        title={t('employee.hems.dongle-onboarding.reset.title')}
        description={t('employee.hems.dongle-onboarding.reset.description')}
        nextString={t('employee.hems.dongle-onboarding.reset.button')}
      />
    ),
    [HemsConnectionLostSteps.Bluetooth]: (
      <HemsInfoStep
        visual={dongleFlow}
        title={t('employee.hems.dongle-onboarding.bluetooth.title')}
        description={t('employee.hems.dongle-onboarding.bluetooth.description')}
      />
    ),
    [HemsConnectionLostSteps.DongleButton]: (
      <HemsInfoStep
        visual={dongleButton}
        title={t('employee.hems.dongle-onboarding.dongle-button.title')}
        description={t(
          'employee.hems.dongle-onboarding.dongle-button.description'
        )}
        nextString={t('employee.hems.dongle-onboarding.dongle-button.button')}
      />
    ),
    [HemsConnectionLostSteps.DongleConnection]: <DongleConnectionStep />,
    [HemsConnectionLostSteps.WifiNetworkSelection]: (
      <WifiNetworkSelectionStep />
    ),
    [HemsConnectionLostSteps.WifiPassword]: <WifiPasswordStep />,
    [HemsConnectionLostSteps.WifiProvisioning]: <WifiReconnectionStep />,
    [HemsConnectionLostSteps.UtpConnection]: (
      <HemsInfoStep
        visual={donglePorts}
        title={t('employee.hems.dongle-onboarding.utp-connection.title')}
        description={t(
          'employee.hems.dongle-onboarding.utp-connection.description'
        )}
        nextString={t('employee.hems.dongle-onboarding.utp-connection.next')}
      />
    ),
    [HemsConnectionLostSteps.UtpProvisioning]: <UtpReconnectionStep />,
    [HemsConnectionLostSteps.ProvisioningSuccess]: <ReconnectionSuccessStep />,
  }
}
