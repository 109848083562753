import { styled } from 'styled-components'

import { breakpoints } from '../../../theme/layout/breakpoints'

import type { PropsWithChildren } from 'react'

type ContentContainerProps = PropsWithChildren & {
  topPadding?: boolean
  bareBones?: boolean
  className?: string
}

export const ContentContainer = ({
  children,
  topPadding = true,
  bareBones = false,
  className,
}: ContentContainerProps) => {
  return (
    <StContentContainer
      className={className}
      $topPadding={topPadding}
      $bareBones={bareBones}
    >
      {children}
    </StContentContainer>
  )
}

const StContentContainer = styled.div<{
  $topPadding: boolean
  $bareBones: boolean
}>`
  flex: 1;

  width: ${({ theme, $bareBones }) =>
    $bareBones ? '100%' : `calc(100% - ${theme.UI.SpacingPx.Space12})`};
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  padding-top: ${({ $topPadding }) =>
    $topPadding ? 'var(--inset-top, 0px)' : 0};
  margin: 0 auto;

  @media ${breakpoints.tablet} {
    padding-top: ${({ theme, $topPadding }) =>
      $topPadding ? theme.UI.SpacingPx.Space11 : 0};
  }
`

export const StWidthContainer = styled.div`
  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  margin: 0 auto;
`
