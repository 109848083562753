import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { useAxios } from '../../../api/hooks/useAxios'
import { Urls } from '../../../api/urls'

import { ChargeLocationDetail } from './ChargeLocationDetail'

import type {
  ChargingLocation,
  OptimileEvsesChargingPointResponse,
} from 'types'

type ChargeLocationDetailProps = {
  detailInfo: ChargingLocation
  onClose: () => void
}

export const UnAuthenticatedChargeLocationDetail = ({
  detailInfo,
  onClose,
}: ChargeLocationDetailProps) => {
  // -- Data --
  const [{ data, loading }] = useAxios<OptimileEvsesChargingPointResponse>({
    url: replaceParametersInPath(Urls.publicChargingLocationDetail, {
      id: detailInfo.id,
    }),
  })

  return (
    <ChargeLocationDetail
      detailInfo={detailInfo}
      data={data}
      loading={loading}
      onClose={onClose}
    />
  )
}
