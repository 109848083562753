import type { SVGProps } from 'react'

export const IntercomExplanationSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="78"
      height="79"
      viewBox="0 0 78 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.15625" width="78" height="78" rx="39" fill="#DCF730" />
      <path
        d="M54.8514 57.1291C54.8514 57.1291 49.3702 55.0464 44.9356 53.3779H26.2863C24.0782 53.3779 22.2891 51.5446 22.2891 49.2844V25.2896C22.2891 23.0305 24.0782 21.1973 26.2863 21.1973H50.8519C53.0599 21.1973 54.8502 23.0305 54.8502 25.2896V45.9555L54.8514 45.9543V57.1291ZM50.0385 43.6744C49.6556 43.2172 48.9815 43.165 48.5359 43.556C48.5011 43.5862 45.0295 46.5681 38.5679 46.5681C32.1886 46.5681 28.6637 43.6071 28.5999 43.5537C28.1532 43.1638 27.4825 43.2184 27.0996 43.6744C26.7156 44.1327 26.7689 44.8207 27.2156 45.2141C27.3816 45.3579 31.33 48.7506 38.5679 48.7506C45.8093 48.7506 49.7589 45.3579 49.9225 45.2141C50.3692 44.8207 50.4226 44.1327 50.0385 43.6744Z"
        fill="black"
      />
    </svg>
  )
}
