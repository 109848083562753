import ContentLoader from 'react-content-loader'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DetailLoader = (props: any) => (
  <ContentLoader
    speed={2}
    height={112}
    viewBox="0 0 351 112"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="1" y="0" rx="4" ry="4" width="124" height="22" />
    <rect x="1" y="26" rx="4" ry="4" width="230" height="22" />
    <rect x="1" y="60" rx="4" ry="4" width="177" height="16" />
    <rect x="1" y="88" rx="4" ry="4" width="118" height="24" />
    <rect x="135" y="88" rx="4" ry="4" width="118" height="24" />
  </ContentLoader>
)
