import type { MapboxContext } from './types'
import type { Address } from 'database'

export const createMapboxAddressString = (context: MapboxContext) => {
  const streetname = context.street?.name || ''
  const number = context.address?.address_number || ''
  const postcode = context.postcode?.name || ''
  const place = context.place?.name || ''

  const streetAddress = streetname
    ? `${streetname}${number ? ' ' + number : ''}, `
    : ''

  return `${streetAddress}${postcode} ${place}`
}

export const createAddressString = (address: Address) => {
  const fullAddress = `${address.street} ${address.number}, ${address.postcode} ${address.city}`

  return fullAddress.charAt(0).toUpperCase() + fullAddress.slice(1)
}
