import { Capacitor } from '@capacitor/core'
import { useNavigate } from 'react-router-dom'

import { UnauthenticatedRoutes } from '../../../../../routing/routes'
import { goToStore } from '../../../../core/lib/goToStore'
import { ContinueInTheApp } from '../../components/ContinueInTheApp'

export const TokenContinueInTheAppStep = () => {
  // -- Hooks --
  const navigate = useNavigate()

  // -- Handlers --
  const handleConfirm = () => {
    if (Capacitor.isNativePlatform()) {
      navigate(UnauthenticatedRoutes.Root)
    } else {
      goToStore()
    }
  }

  // -- Render --
  return <ContinueInTheApp handleConfirm={handleConfirm} />
}
