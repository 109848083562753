import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  isBefore,
  isValid,
} from 'date-fns'
import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { css, styled } from 'styled-components'
import { match } from 'ts-pattern'

import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { onHover } from '../../../core/lib/styleHelpers'
import { BodyMediumRegularCss, BodySmallRegularCss } from '../../typography'
import { StError } from '../Input'

import type { DateRange } from './DateInput'
import type { MouseEvent } from 'react'

type DateInputComponentProps = {
  rawValue?: Date | DateRange | null
  onClick?: () => void
  placeholder?: string
  toggleDatePicker: () => void
  error?: string
  touched?: boolean
  size?: 'sm' | 'md' | 'lg'
  readOnly?: boolean
  disabled?: boolean
  withNavigation?: boolean
  onPrevious?: () => void
  onNext?: () => void
  formatString?: string
  minDate?: Date
  maxDate?: Date
  mode: 'day' | 'month' | 'year'
}

export const DateInputComponent = forwardRef<
  HTMLButtonElement,
  DateInputComponentProps
>(function DateInputComponent(
  {
    rawValue,
    placeholder,
    toggleDatePicker,
    error,
    touched,
    size = 'lg',
    readOnly,
    disabled,
    withNavigation = false,
    onPrevious,
    onNext,
    formatString,
    minDate,
    maxDate,
    mode,
  },
  ref
) {
  const { t } = useTranslation()
  const dateFnsLocale = useGetDateFnsLocale()

  const formattedValue = () => {
    if (!rawValue) {
      return placeholder ?? t('components.date-input.placeholder')
    }

    const dateRange = rawValue as DateRange
    if (typeof dateRange === 'object' && dateRange?.start && dateRange?.end) {
      return `${format(
        dateRange.start,
        formatString ?? 'MMM dd',
        dateFnsLocale
      )} - ${format(dateRange.end, formatString ?? 'MMM dd', dateFnsLocale)}`
    }

    if (isValid(rawValue as Date)) {
      return format(
        rawValue as Date,
        formatString ?? 'dd/MM/yyyy',
        dateFnsLocale
      )
    }

    return placeholder ?? t('components.date-input.placeholder')
  }

  const handlePrevious = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    onPrevious?.()
  }

  const showPreviousButton = useMemo(() => {
    if (!readOnly && !withNavigation) {
      return false
    }

    const previousAllowed = match(mode)
      .with('day', () => {
        return (
          !minDate || isBefore(endOfDay(minDate), endOfDay(rawValue as Date))
        )
      })
      .with('month', () => {
        return (
          !minDate ||
          isBefore(endOfMonth(minDate), endOfMonth(rawValue as Date))
        )
      })
      .with('year', () => {
        return (
          !minDate || isBefore(endOfYear(minDate), endOfYear(rawValue as Date))
        )
      })
      .exhaustive()

    return withNavigation && previousAllowed
  }, [minDate, rawValue, readOnly, withNavigation, mode])

  const handleNext = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    onNext?.()
  }

  const showNextButton = useMemo(() => {
    if (!readOnly && !withNavigation) {
      return false
    }

    const nextAllowed = match(mode)
      .with('day', () => {
        return (
          !maxDate || isBefore(endOfDay(rawValue as Date), endOfDay(maxDate))
        )
      })
      .with('month', () => {
        return (
          !maxDate ||
          isBefore(endOfMonth(rawValue as Date), endOfMonth(maxDate))
        )
      })
      .with('year', () => {
        return (
          !maxDate || isBefore(endOfYear(rawValue as Date), endOfYear(maxDate))
        )
      })
      .exhaustive()

    return withNavigation && nextAllowed
  }, [maxDate, rawValue, readOnly, withNavigation, mode])

  return (
    <>
      <StContainer
        onClick={toggleDatePicker}
        ref={ref}
        type="button"
        $error={!!error}
        $size={size}
        $readOnly={readOnly}
        $disabled={disabled}
        $withNavigation={withNavigation}
        className="react-datepicker-ignore-onclickoutside"
        disabled={disabled || readOnly}
      >
        {!readOnly && !withNavigation && (
          <StCalendarIcon icon={['fasr', 'calendar']} />
        )}
        {withNavigation && (
          <StNavigationIcon
            $visible={showPreviousButton}
            $size={size}
            onClick={handlePrevious}
          >
            <FontAwesomeIcon icon={['fasr', 'chevron-left']} />
          </StNavigationIcon>
        )}
        <StValueLabel $size={size}>{formattedValue()}</StValueLabel>
        {withNavigation && (
          <StNavigationIcon
            $visible={showNextButton}
            $size={size}
            onClick={handleNext}
          >
            <FontAwesomeIcon icon={['fasr', 'chevron-right']} />
          </StNavigationIcon>
        )}
      </StContainer>
      {touched && error ? <StError>{error}</StError> : null}
    </>
  )
})

const StContainer = styled.button<{
  $hasValue?: boolean
  $disabled?: boolean
  $error?: boolean
  $size: 'sm' | 'md' | 'lg'
  $readOnly?: boolean
  $withNavigation?: boolean
}>`
  width: 100%;

  height: ${({ theme, $size }) =>
    $size === 'lg'
      ? theme.UI.SpacingPx.Space13
      : $size === 'md'
      ? theme.UI.SpacingPx.Space11
      : theme.UI.SpacingPx.Space10};

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  justify-content: ${({ $withNavigation }) =>
    $withNavigation ? 'space-between' : 'flex-start'};

  cursor: pointer;

  border: ${({ $hasValue, $error, theme, $readOnly }) =>
    $error
      ? theme.components.input.error
      : $readOnly
      ? 'none'
      : $hasValue
      ? '1px solid black'
      : `1px solid ${theme.components.input.border}`};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  background-color: white;

  padding: ${({ theme, $size, $readOnly, $withNavigation }) =>
    $readOnly || $withNavigation
      ? 0
      : $size === 'lg'
      ? `0 ${theme.UI.SpacingPx.Space5}`
      : $size === 'md'
      ? `0 ${theme.UI.SpacingPx.Space4}`
      : `0 14px`};

  &:focus-visible {
    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }

  ${onHover(css`
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  `)}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
      color: ${({ theme }) => theme.theme.text.body['gray-mid']};
      border-color: ${({ theme }) => theme.theme.colors['nonary-9']};
      cursor: not-allowed;

      &:hover {
        border-color: ${({ theme }) => theme.theme.colors['nonary-9']};
      }
    `}

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      cursor: default;
    `}
`

const StCalendarIcon = styled(FontAwesomeIcon)`
  width: ${({ theme }) => theme.UI.SpacingPx.Space4};
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StValueLabel = styled.div<{ $size: 'sm' | 'md' | 'lg' }>`
  ${({ $size }) =>
    $size === 'lg' ? BodyMediumRegularCss : BodySmallRegularCss}
  white-space: nowrap;
`

const StNavigationIcon = styled.div<{
  $size: 'sm' | 'md' | 'lg'
  $visible?: boolean
}>`
  align-self: stretch;
  padding: ${({ theme, $size }) =>
    $size === 'lg'
      ? `0 ${theme.UI.SpacingPx.Space5}`
      : $size === 'md'
      ? `0 ${theme.UI.SpacingPx.Space4}`
      : `0 14px`};

  display: grid;
  place-items: center;

  cursor: pointer;

  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  pointer-events: ${({ $visible }) => ($visible ? 'auto' : 'none')};

  &:hover {
    opacity: 0.5;
  }
`
