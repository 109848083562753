import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import { styled } from 'styled-components'

import type { NavigationItemType } from './Navigation'

type BottomNavigationProps = {
  navigationItems: NavigationItemType[]
}

export const BottomNavigation = ({
  navigationItems,
}: BottomNavigationProps) => {
  return (
    <StContainer>
      {navigationItems.map((item) => (
        <StItem key={item.name} to={item.url}>
          {({ isActive }) =>
            isActive ? (
              <FontAwesomeIcon icon={['fass', item.icon || 'home']} />
            ) : (
              <FontAwesomeIcon icon={['fasl', item.icon || 'home']} />
            )
          }
        </StItem>
      ))}
    </StContainer>
  )
}

const StContainer = styled.div`
  z-index: 999;

  position: sticky;
  bottom: 0;

  display: flex;

  width: 100%;
  max-width: 100%;
  overflow: hidden;

  background-color: ${({ theme }) => theme.theme.colors.white};

  box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.03);

  height: calc(
    var(--bottom-navigation-height) + var(--inset-bottom, 20px) - 20px
  );
`

const StItem = styled(NavLink)`
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: center;

  height: var(--bottom-navigation-height);
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 22px;
  line-height: 135%;
  color: ${({ theme }) => theme.theme.colors.black};
`
