import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { Urls } from '../../../api/urls'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { ContentContainer } from '../../../components/page-layout'
import { SortKeys } from '../../../dataTable/components/DataTableHeader'
import {
  DataTableProvider,
  PaginationType,
} from '../../../dataTable/providers/DataTableProvider'
import { ReimbursementsDataList } from '../components/ReimbursementsDataList'
import { ReimbursementsDataTable } from '../components/ReimbursementsDataTable'

export const EmployeeReimbursementsScreen = () => {
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  // -- Render --
  return (
    <DataTableProvider
      paginationType={PaginationType.Infinite}
      url={Urls.reimbursements}
      defaultSort="date"
      defaultSortDirection={SortKeys.DESCENDING}
    >
      <ContentContainer>
        <DetailPageHeader
          title={t('employee.reimbursements.title')}
          onBack={
            isDesktop
              ? undefined
              : () => navigate(state?.backTo || EmployeeRoutes.Profile)
          }
        />
        {isDesktop ? <ReimbursementsDataTable /> : <ReimbursementsDataList />}
      </ContentContainer>
    </DataTableProvider>
  )
}
