import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonQuaternary } from '../../../../components/button/ButtonQuaternary'
import { BodyMediumSemiBold } from '../../../../components/typography'
import { hemsFlowContext } from '../context/hemsFlowContext'
import { useHemsConnectionLostState } from '../hooks/useHemsConnectionLostState'
import { useHemsConnectionLostSteps } from '../hooks/useHemsConnectionLostSteps'

import type { ESPNetwork } from '@general-galactic/capacitor-esp-idf-provisioning'

type HemsLostConnectionFlowProps = {
  onClose: () => void
}

export const HemsLostConnectionFlow = ({
  onClose,
}: HemsLostConnectionFlowProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const steps = useHemsConnectionLostSteps()
  const { currentStep, handleNext, setStep, handlePrevious, headerState } =
    useHemsConnectionLostState()

  // -- State --
  const [deviceName, setDeviceName] = useState<string>('')
  const [macAddress, setMacAddress] = useState<string | null>(null)
  const [selectedNetwork, setSelectedNetwork] = useState<ESPNetwork | null>(
    null
  )
  const [networkPassword, setNetworkPassword] = useState<string | null>(null)

  // -- Handlers --

  // -- Render --
  return (
    <>
      <StHeader>
        {headerState.cantGoBack ? (
          <div></div>
        ) : (
          <StIconButton onClick={handlePrevious}>
            <FontAwesomeIcon icon={['fasr', 'chevron-left']} fontSize={20} />
          </StIconButton>
        )}

        <BodyMediumSemiBold>
          {t('employee.hems.overview.no-connection.wizard.title')}
        </BodyMediumSemiBold>

        <StCloseButton border={false} onClick={onClose}>
          <FontAwesomeIcon icon={['fasr', 'xmark-large']} fontSize={18} />
        </StCloseButton>
      </StHeader>
      <hemsFlowContext.Provider
        value={{
          flowNavigation: {
            next: handleNext,
            previous: handlePrevious,
            close: onClose,
            setStep: setStep,
          },
          deviceName,
          setDeviceName,
          macAddress,
          setMacAddress,
          selectedNetwork,
          setSelectedNetwork,
          networkPassword,
          setNetworkPassword,
        }}
      >
        {steps[currentStep]}
      </hemsFlowContext.Provider>
    </>
  )
}

const StHeader = styled.div`
  position: sticky;
  top: 0;

  display: grid;
  grid-template-columns: 40px auto 40px;

  align-items: center;
  justify-content: space-between;

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
  /* Compensate for hitslop */
  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space4};

  background-color: ${({ theme }) => theme.theme.colors.white};

  h6 {
    margin: 0;
    text-align: center;
  }

  svg {
    margin-bottom: -4px;
  }
`

const StIconButton = styled.button`
  background-color: transparent;
  /* Increase hitslop */
  padding: ${({ theme }) => theme.UI.SpacingPx.Space1};
  border: none;
  width: min-content;

  cursor: pointer;

  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
  color: ${({ theme }) => theme.components.menu.wizard['item-icon']};
`

const StCloseButton = styled(ButtonQuaternary)`
  display: grid;
  place-items: center;

  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.components.menu.wizard['item-icon']};
  border-radius: 50%;

  width: ${({ theme }) => theme.UI.SpacingPx.Space10};
  height: ${({ theme }) => theme.UI.SpacingPx.Space10};

  aspect-ratio: 1 / 1;
  padding: 0;
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space1};

  span {
    margin-top: -5px;
  }
`
