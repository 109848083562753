import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ContentContainer } from '../../../components/page-layout'
import { useUser } from '../../../user/hooks/useUser'
import { EmployeeSmartChargingSettings } from '../components/EmployeeSmartChargingSettings'

export const EmployeeSmartChargingSettingsScreen = () => {
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const navigate = useNavigate()
  const { user } = useUser()

  if (!user.addresses[0].scoptOnboarded) {
    navigate(EmployeeRoutes.Onboarding)
  }

  if (isDesktop) {
    navigate(EmployeeRoutes.Settings, {
      state: { settingScreen: 'smart-charging' },
    })
  }

  return (
    <ContentContainer>
      <EmployeeSmartChargingSettings />
    </ContentContainer>
  )
}
