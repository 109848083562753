import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { SelectBoxes } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

import type { SelectBoxesItems } from '../../components/SelectBoxes'

export enum ChargerDisassembleKeys {
  Yes = 'Yes',
  No = 'No',
}
export const ChargerDisassembleStep = () => {
  const { handleNext, setLoading, values } = useOnboarding()

  // -- State --
  const [selectedItem, setSelectedItem] = useState(values.chargerDisassembleKey)

  // -- Hooks --
  const { t } = useTranslation()

  // -- Data --
  // eslint-disable-next-line no-unused-vars
  const [_, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Vars --
  const items: SelectBoxesItems<ChargerDisassembleKeys>[] = [
    {
      name: t('onboarding.charger-disassemble.no.title'),
      subTitle: t('onboarding.charger-disassemble.no.subtitle'),
      icon: ['fasr', 'xmark'],
      key: ChargerDisassembleKeys.No,
    },
    {
      name: t('onboarding.charger-disassemble.yes.title'),
      subTitle: t('onboarding.charger-disassemble.yes.subtitle'),
      icon: ['fasr', 'check'],
      key: ChargerDisassembleKeys.Yes,
    },
  ]

  // -- Handlers --
  const handleSubmit = async (key: ChargerDisassembleKeys) => {
    try {
      setSelectedItem(key)
      setLoading(true)

      if (key !== values.chargerDisassembleKey) {
        await execute({
          data: {
            chargerDisassembleKey: key,
          },
        })
      }

      handleNext({ ...values, chargerDisassembleKey: key })
    } catch {
      setLoading(false)
      return console.error('Failed to save disassembly data')
    }
  }

  return (
    <StContainer>
      <OnboardingStepTitle>
        {t('onboarding.charger-disassemble.title')}
      </OnboardingStepTitle>

      <SelectBoxes
        items={items}
        onSelect={handleSubmit}
        selectedItem={selectedItem}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  height: 100%;
`
