// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { useAuthMemberAxios } from '../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../api/urls'
import { downloadBlob } from '../lib/downloadBlob'

import type { AxiosResponse } from 'axios'

export const useDownloadZohoFile = (fileId?: string, forUserId?: string) => {
  // -- State --
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<Blob>()

  // -- Hooks --
  const { t } = useTranslation()

  // -- Data --
  const [, executeGetFile] = useAuthMemberAxios<Blob>(
    {},
    {
      manual: true,
      cache: true,
    }
  )

  // -- Effects --
  // If file id is set, preload file and make preview image available
  useEffect(() => {
    if (fileId) {
      downloadFileData(fileId, forUserId)
    }
  }, [fileId])

  // -- Functions --
  const downloadFileData = async (fileId: string, forUserId?: string) => {
    const response = await executeGetFile({
      url: `${Urls.ZohoPhoto}/File/${fileId}`,
      method: 'GET',
      responseType: 'blob',
      params: { forUserId },
    })

    setFile(response.data)

    return response
  }

  const download = async (
    fileName: string,
    fileId?: string,
    download: boolean = true,
    forUserId?: string
  ) => {
    setLoading(true)

    try {
      let response: AxiosResponse<any> | undefined = undefined

      if (!file && !fileId) {
        console.error('No file or file id provided')
      } else if (!file && fileId) {
        response = await downloadFileData(fileId, forUserId)
      }

      // Save file
      downloadBlob(file ?? response?.data, fileName, '.pdf', download)
    } catch {
      toast.error(t('error.downloadFile'))
    }

    setLoading(false)
  }

  return {
    download,
    loading,
    file,
  }
}
