import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { Dialog } from '../../general/Dialog'
import { InfoButton } from '../../general/InfoButton'
import { Spinner } from '../../general/Spinner'
import {
  BodyLargeSemiBold,
  BodyMediumRegular,
  BodyMediumSemiBoldCss,
  H3,
} from '../../typography'

import { ImagePreview } from './ImagePreview'
import { getFileErrorMessage } from './lib/getFileErrorMessage'

export type MultiFileValues = {
  zohoId: string
  file?: File | undefined
}

type Examples = {
  title: string
  subtitle: string
  pictures: string[]
}

type MultipleFileInputProps = {
  values?: MultiFileValues[]
  label?: string
  isLoading?: boolean
  error?: string
  onFileChange: (files: File[]) => void
  onDelete: (zohoId: string) => void
  example?: Examples
}

export const MultipleFileInput = ({
  values,
  onFileChange,
  onDelete,
  label,
  isLoading,
  error,
  example,
}: MultipleFileInputProps) => {
  // -- Hooks --
  const { t } = useTranslation()

  const hasValues = !!values?.length

  // -- Handlers --
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return
    }

    if (acceptedFiles) {
      onFileChange(
        acceptedFiles.map((file) => {
          const fileName = `${Date.now().toString()}.${file.name
            .split('.')
            .at(-1)}`

          // Add filetype to name
          return new File([file], fileName, {
            type: file.type,
          })
        })
      )
    } else {
      alert('Only images (JPEG/PNG) and PDF files are allowed.')
    }
  }, [])

  // -- Drop logic --
  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg'],
      },
      disabled: hasValues && values.length >= 5,
      onDrop,
      maxFiles: 5 - (values?.length || 0),
      maxSize: 10000000, // 10MB
    })

  const hasError = fileRejections.length > 0 || !!error
  const errorMessage = hasError
    ? error || getFileErrorMessage(fileRejections[0].errors[0].code)
    : ''

  // -- Render --
  return (
    <div>
      {label ? (
        <StLabelContainer>
          <StLabel>{label}</StLabel>
          {example ? (
            <Dialog
              primaryButtonText={t('input.upload.info.close')}
              trigger={<InfoButton />}
            >
              <H3>{example.title}</H3>
              <StSubtitle>{example.subtitle}</StSubtitle>
              <BodyLargeSemiBold>
                {t('input.upload.info.examples')}
              </BodyLargeSemiBold>
              <StExampleContainer>
                {example.pictures.map((image) => (
                  <StImage key={image} src={image} alt="" />
                ))}
              </StExampleContainer>
            </Dialog>
          ) : null}
        </StLabelContainer>
      ) : null}

      <StDropzone
        {...getRootProps()}
        $hasValue={hasValues}
        $hover={isDragActive}
        $loading={isLoading}
        $error={hasError}
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <StFlexContainer>
            <Spinner />
            <StText>{t('input.upload.loading')}</StText>
          </StFlexContainer>
        ) : hasError ? (
          <StFlexContainer $error={hasError}>
            <FontAwesomeIcon icon={['fasr', 'triangle-exclamation']} />
            <StText>{t(errorMessage)}</StText>
            <StErrorCTA>
              <Trans
                t={t}
                i18nKey={'input.upload.placeholder.short'}
                components={{
                  u: <u />,
                }}
              />
            </StErrorCTA>
          </StFlexContainer>
        ) : hasValues ? (
          <StFlexContainer>
            {values.map((value) => (
              <ImagePreview
                key={value.zohoId}
                value={value}
                onClick={(id) => onDelete(id)}
              />
            ))}
            {values.length >= 5 ? null : (
              <StOpenText onClick={open}>
                {t('onboarding.desktop-documents.open-text')}
              </StOpenText>
            )}
          </StFlexContainer>
        ) : (
          <BodyMediumRegular onClick={open}>
            <Trans
              t={t}
              i18nKey={'input.upload.placeholder'}
              components={{
                u: <u />,
              }}
            />
          </BodyMediumRegular>
        )}
      </StDropzone>
    </div>
  )
}

const StDropzone = styled.div<{
  $hasValue: boolean
  $hover: boolean
  $loading?: boolean
  $error?: boolean
}>`
  cursor: pointer;
  display: flex;

  flex-direction: column;
  justify-content: center;
  height: ${({ theme }) => theme.UI.SpacingPx.Space13};
  border: 1px dashed ${({ theme }) => theme.theme.colors['nonary-2']};
  border-width: ${({ $hasValue, $error }) => ($hasValue || $error ? 0 : 1)};

  background-color: ${({ theme, $hover, $loading, $error }) =>
    $loading
      ? theme.theme.colors['primary-3']
      : $error
      ? theme.theme.colors['senary-4']
      : $hover
      ? theme.theme.colors.white
      : theme.theme.colors['nonary-9']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  padding: ${({ theme, $hasValue }) =>
    $hasValue
      ? `${theme.UI.SpacingPx.Space2}`
      : `${theme.UI.SpacingPx.Space3} ${theme.UI.SpacingPx.Space4}`};
`

const StLabel = styled.label`
  ${BodyMediumSemiBoldCss}
  display: block;
`

const StFlexContainer = styled.div<{ $error?: boolean }>`
  height: 100%;

  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  color: ${({ $error, theme }) =>
    $error ? theme.components.input.error : theme.theme.colors.black};
`

const StText = styled(BodyMediumRegular)`
  overflow: hidden;
  white-space: nowrap;
  display: inline;
  text-overflow: ellipsis;
`

const StErrorCTA = styled(StText)`
  color: ${({ theme }) => theme.theme.colors.black};
  margin-left: auto;
`

const StLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StSubtitle = styled(BodyMediumRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StImage = styled.img`
  width: 210px;
  aspect-ratio: 1/1;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
const StExampleContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  overflow-x: auto;

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StOpenText = styled(BodyMediumRegular)`
  cursor: pointer;
  text-decoration: underline;
`
