import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import { PdfPreview } from '../../../components/general/PdfPreview'
import { BodyMediumRegular, H5 } from '../../../components/typography'
import { useDownloadZohoFile } from '../../../core/hooks/useDownloadZohoFile'
import { useQuote } from '../../../quotes/hooks/useQuote'

import type { UserWithContractAndCards } from 'types'

type QuoteCardProps = {
  employee: UserWithContractAndCards
}

export function QuoteCard({ employee }: QuoteCardProps) {
  // -- Data --
  const { quote } = useQuote(employee.members[0].id)
  const {
    download: quoteDownload,
    loading: quoteLoading,
    file,
  } = useDownloadZohoFile(quote?.Quote[0].File_Id__s, employee.id)
  const { t } = useTranslation()
  const { trackEvent } = useTracking()

  // -- Handlers --
  const handleDownload = (openInWindow: boolean) => {
    const fileName = t('admin.employee-detail.quote.title', {
      fullName: employee.fullName,
    }).replaceAll(/\s/g, '_')

    trackEvent(EventType.Click, 'download_quote')
    quoteDownload(fileName, undefined, !openInWindow)
  }

  // If for some reason the quote is not available, we don't render the card
  if (!quote) return null

  return (
    <StCard>
      <StCardTitle>{t('admin.employee-detail.quote-card.title')}</StCardTitle>
      <StCardContent>
        <PdfPreview file={file} />
        <div>
          <StButtonLabel>
            {t('admin.employee-detail.quote-card.quote-charger')}
          </StButtonLabel>
          <ButtonTertiary
            icon={['fasr', 'eye']}
            iconAlignment="left"
            compact
            onClick={() => handleDownload(true)}
            loading={quoteLoading}
            size="md"
          >
            {t('admin.employee-detail.quote.view')}
          </ButtonTertiary>
        </div>
      </StCardContent>
    </StCard>
  )
}

const StCard = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StCardTitle = styled(H5)`
  margin-top: 0;
`

const StCardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space8};

  align-items: center;
`

const StButtonLabel = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
