import { useCallback } from 'react'
import ContentLoader from 'react-content-loader'
import { styled, css } from 'styled-components'

import empty from '../../components/assets/empty_list.svg'
import {
  BodyExtraSmallRegularCss,
  BodySmallRegularCss,
  H5,
} from '../../components/typography'
import { useGetDateFnsLocale } from '../../core/hooks/useGetDateFnsLocale'
import { onHover } from '../../core/lib/styleHelpers'
import { useDataTable } from '../hooks/useDataTable'
import { PaginationType } from '../providers/DataTableProvider'
import { columnValue } from '../utils/columnValue'

import type { BaseRow, Column } from './DataTable'

type DataListProps<T> = {
  columns: Column<T>[]
  groupOn?: Column<T>
  onRowClick?: (dataRow: T) => void
  emptyTitle: string
  emptyFiltersTitle: string
}

export const DataList = <
  T extends BaseRow,
  Statistics extends Record<string, unknown> = Record<string, unknown>
>({
  columns,
  groupOn,
  onRowClick,
  emptyTitle,
  emptyFiltersTitle,
}: DataListProps<T>) => {
  // Hooks
  const {
    filterValues,
    tableData,
    intersectionRef,
    initialLoading,
    paginationType,
    amount,
  } = useDataTable<Statistics>()
  const dateFnsLocale = useGetDateFnsLocale()

  const isInPreviousGroup = useCallback(
    (currentRow: T, index: number, dataRows: T[], groupOn: Column<T>) => {
      if (index === 0) {
        return false
      }
      const currentValue = columnValue(groupOn, currentRow, dateFnsLocale)
      const previousValue = columnValue(
        groupOn,
        dataRows[index - 1],
        dateFnsLocale
      )
      return currentValue === previousValue
    },
    [dateFnsLocale]
  )

  // Render loading state
  if (
    initialLoading &&
    (paginationType !== PaginationType.Infinite || initialLoading)
  ) {
    return (
      <StDataListContainer $group={groupOn != null}>
        <StDataListItem $group={groupOn != null}>
          {Array.from({ length: amount }).map((_, index) => {
            return (
              <StDataListCard key={'list_loader_' + index}>
                {columns.map((column) => (
                  <ContentLoader
                    key={'list_loader_' + column.key}
                    speed={2}
                    width={65}
                    height={20}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="4" ry="4" width="100%" height="20" />
                  </ContentLoader>
                ))}
              </StDataListCard>
            )
          })}
        </StDataListItem>
      </StDataListContainer>
    )
  }

  const data = tableData?.data as T[]

  // Render empty state
  if (!data || data.length === 0) {
    return (
      <StEmptyContainer>
        <img src={empty} alt="empty state" />
        <StEmptyContent>
          <H5>{filterValues == null ? emptyFiltersTitle : emptyTitle}</H5>
        </StEmptyContent>
      </StEmptyContainer>
    )
  }

  // Render component
  return (
    <>
      <StDataListContainer $group={groupOn != null}>
        {data.map((dataRow, index, data) => (
          <StDataListItem key={dataRow.id} $group={groupOn != null}>
            {groupOn != null &&
              !isInPreviousGroup(dataRow, index, data, groupOn) && (
                <StDataListGroupTitle>
                  {columnValue(groupOn, dataRow, dateFnsLocale)}
                </StDataListGroupTitle>
              )}
            <StDataListCard
              $clickable={!!onRowClick}
              onClick={() => (onRowClick ? onRowClick(dataRow) : null)}
            >
              {columns.map((column) => {
                return column.component ? (
                  <column.component key={column.key}>
                    {column.prepend}
                    {columnValue(column, dataRow, dateFnsLocale)}
                    {column.append}
                  </column.component>
                ) : (
                  <StDataListCardItem key={column.key}>
                    {column.prepend}
                    {columnValue(column, dataRow, dateFnsLocale)}
                    {column.append}
                  </StDataListCardItem>
                )
              })}
            </StDataListCard>
          </StDataListItem>
        ))}
      </StDataListContainer>
      {paginationType === PaginationType.Infinite && (
        <StIntersect ref={intersectionRef} />
      )}
    </>
  )
}

const StDataListGroupTitle = styled.p`
  ${BodySmallRegularCss};
  color: ${({ theme }) => theme.theme.colors['nonary-2']};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StDataListContainer = styled.div<{ $group: boolean }>`
  display: flex;
  flex-direction: column;

  gap: ${({ theme, $group }) =>
    $group ? theme.UI.SpacingPx.Space2 : theme.UI.SpacingPx.Space3};
`

const StDataListItem = styled.div<{ $group: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  &:first-of-type {
    ${StDataListGroupTitle} {
      margin-top: 0;
    }
  }
`

const StDataListCard = styled.div<{ $clickable?: boolean }>`
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space5};

  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: auto;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2} 0;

  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};

  :nth-child(even) {
    text-align: right;
    margin-left: auto;
  }

  ${({ $clickable }) =>
    onHover(
      css`
        border: 1px solid
          ${({ theme }) =>
            $clickable
              ? theme.theme.border.black
              : theme.theme.colors['nonary-7']};
      `,
      false
    )}
`

const StDataListCardItem = styled.p`
  ${BodyExtraSmallRegularCss}
`

const StIntersect = styled.div`
  height: 1px;
  position: absolute;
  bottom: 40vh;
`

const StEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space10} ${theme.UI.SpacingPx.Space7}`};
`

const StEmptyContent = styled.div`
  text-align: center;
  > * {
    margin: 0;
  }
`
