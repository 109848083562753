import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  forwardRef,
  type ButtonHTMLAttributes,
  type PropsWithChildren,
} from 'react'
import { styled } from 'styled-components'

import { Spinner } from '../general/Spinner'
import { BodyMediumMediumCss, BodySmallMediumCss } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren & {
    icon?: IconProp
    iconAlignment?: 'left' | 'right'
    compact?: boolean
    disabled?: boolean
    loading?: boolean
    size?: 'sm' | 'md' | 'lg'
    spread?: boolean
  }

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      children,
      icon,
      compact,
      iconAlignment = 'right',
      disabled = false,
      loading = false,
      size = 'lg',
      spread = false,
      ...props
    },
    ref
  ) {
    return (
      <StButton
        ref={ref}
        {...props}
        $compact={compact}
        $size={size}
        $spread={spread}
        disabled={disabled || loading}
      >
        {icon && iconAlignment === 'left' ? (
          loading ? (
            <Spinner secondary size={'sm'} />
          ) : (
            <FontAwesomeIcon icon={icon} />
          )
        ) : null}
        {children}
        {icon && iconAlignment === 'right' ? (
          loading ? (
            <Spinner secondary size={'sm'} />
          ) : (
            <FontAwesomeIcon icon={icon} />
          )
        ) : null}
        {!icon && loading && <Spinner secondary size={'sm'} />}
      </StButton>
    )
  }
)

const StButton = styled.button<{
  $compact?: boolean
  $size: 'sm' | 'md' | 'lg'
  $spread?: boolean
}>`
  ${({ $size }) => ($size === 'lg' ? BodyMediumMediumCss : BodySmallMediumCss)}

  border-radius: 4px;
  border: none;
  padding: ${({ theme, $size }) =>
    $size === 'lg'
      ? `0 ${theme.UI.SpacingPx.Space5}`
      : $size === 'md'
      ? `0 ${theme.UI.SpacingPx.Space4}`
      : `0 14px`};
  cursor: pointer;

  width: ${({ $compact }) => ($compact ? 'fit-content' : '100%')};
  height: ${({ theme, $size }) =>
    $size === 'lg'
      ? theme.UI.SpacingPx.Space13
      : $size === 'md'
      ? theme.UI.SpacingPx.Space11
      : theme.UI.SpacingPx.Space10};

  display: flex;
  align-items: center;
  justify-content: ${({ $spread }) => ($spread ? 'space-between' : 'center')};
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  text-align: center;

  &:disabled {
    pointer-events: none;
  }
`
