import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import * as Sentry from '@sentry/react'
import i18next from 'i18next'
import 'mapbox-gl/dist/mapbox-gl.css'
import { StrictMode, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { RemoveScroll } from 'react-remove-scroll'
import { RouterProvider } from 'react-router-dom'
import { RecoilRoot, useRecoilValue } from 'recoil'
import { ThemeProvider } from 'styled-components'

import { SplashScreenProvider } from './modules/authentication/providers/SplashScreenProvider'
import { useSafeAreaInsets } from './modules/core/hooks/useSafeAreaInsets'
import { initFontAwesomeLibrary } from './modules/core/icons/initFontAwesomeLibrary'
import { disableScrollAtom } from './modules/core/recoil/atoms'
import { SplashScreen } from './modules/core/screens/SplashScreen'
import { initTranslations } from './modules/translations/utils/initTranslations'
import { router } from './routing/router'
import { theme } from './theme'
import './theme/fonts/f37jagger/jagger-font.css'
import './theme/fonts/inter/inter-font.css'
import { GlobalStyles } from './theme/styles/GlobalStyles'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENV,
  enabled: import.meta.env.VITE_ENV === 'production',
})

initFontAwesomeLibrary()
initTranslations()

if (Capacitor.isNativePlatform()) {
  // Handle deeplinks via push notifications
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    ({ notification }) => {
      if (notification.data?.url) {
        router.navigate(notification.data.url)
      }

      if (notification.data?.aps?.url) {
        router.navigate(notification.data?.aps?.url)
      }
    }
  )
}

const App = () => {
  // -- Hooks --
  const { top, bottom } = useSafeAreaInsets()
  const disableScroll = useRecoilValue(disableScrollAtom)

  useEffect(() => {
    const initialUrl = window.location.pathname + window.location.search

    if (!['/', '/login', '/login-error'].includes(initialUrl)) {
      window.sessionStorage.setItem('initialUrl', initialUrl)
    }
  }, [])

  // -- Data --
  const isAndroid = Capacitor.getPlatform() === 'android'

  // -- Render --
  return (
    <RemoveScroll enabled={disableScroll}>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme}>
          <SplashScreenProvider>
            <GlobalStyles
              $topInset={top}
              $bottomInset={isAndroid ? 0 : bottom}
              $native={Capacitor.isNativePlatform()}
            />
            <RouterProvider router={router} />
            <SplashScreen />
          </SplashScreenProvider>
        </ThemeProvider>
      </I18nextProvider>
    </RemoveScroll>
  )
}

ReactDOM.createRoot(document.querySelector('#root') as HTMLElement).render(
  <StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </StrictMode>
)
