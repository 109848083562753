import { useAuthMemberAxios } from '../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../api/urls'
import { useUser } from '../../user/hooks/useUser'

import type { HcpStatusType } from '../../employer/employees/components/EmployeesDataTable'

export const useUpdateStatus = () => {
  // -- Hooks --
  const { refetch: refetchUser } = useUser()

  // -- Data --
  const [{ loading }, update] = useAuthMemberAxios(
    {
      url: Urls.Status,
      method: 'POST',
    },
    { manual: true }
  )

  // -- Functions --
  const updateStatus = async (status: HcpStatusType) => {
    const response = await update({ data: { status } })

    if (!(response.status === 200 || response.status === 201)) {
      throw new Error('Failed to update status')
    }

    // Refetch the user to get the updated data globally
    await refetchUser()

    return response.data
  }

  return { updateStatus, loading }
}
