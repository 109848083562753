import { useEffect, useMemo } from 'react'
import ContentLoader from 'react-content-loader'
import { useRecoilState } from 'recoil'
import { styled } from 'styled-components'

import { useAxios } from '../../api/hooks/useAxios'
import { Urls } from '../../api/urls'
import { zohoImageAtom } from '../../core/recoil/atoms'

import { ZohoModule } from './ZohoImage'

type ZohoTokenImageProps = {
  id: string
  token: string
}

export const ZohoTokenImage = ({ id, token }: ZohoTokenImageProps) => {
  const [zohoImage, setZohoImage] = useRecoilState(zohoImageAtom)

  const [, executeLoadImage] = useAxios(
    {
      url: Urls.ZohoPhoto + `/token/${ZohoModule.File}/${id}/`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        ['x-onboarding-token']: token,
      },
    },
    {
      manual: true,
      cache: true,
    }
  )

  const loadImage = async () => {
    const image = await executeLoadImage()

    setZohoImage((previous) => ({
      ...previous,
      [ZohoModule.File]: {
        ...previous[ZohoModule.File],
        [id]: image.data,
      },
    }))
  }

  useEffect(() => {
    loadImage()
  }, [])

  const image = useMemo(() => {
    const imageStream = zohoImage[ZohoModule.File]?.[id]

    if (!imageStream) {
      return null
    }

    return URL.createObjectURL(imageStream)
  }, [id, JSON.stringify(zohoImage[ZohoModule.File]?.[id])])

  return image ? (
    <StImage src={image} />
  ) : (
    <StLoader speed={2} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
    </StLoader>
  )
}

const StImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const StLoader = styled(ContentLoader)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
