import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { Dialog } from '../../../../components/general/Dialog'
import { ZohoImage, ZohoModule } from '../../../../components/image/ZohoImage'
import { ZohoTokenImage } from '../../../../components/image/ZohoTokenImage'
import { H4 } from '../../../../components/typography'
import { takePicture } from '../../lib/takePicture'

import { MobileChip } from './MobileChip'

type MobileUploadSuccessProps = {
  imageIds: string[]
  currentStep: number
  totalSteps: number
  title: string
  onSubmit: () => void
  onDelete: (id: string) => void
  onAddImage?: (Files: File[]) => Promise<void>
  token?: string
}

export const MobileUploadSuccess = ({
  currentStep,
  totalSteps,
  imageIds,
  title,
  onSubmit,
  onAddImage,
  onDelete,
  token,
}: MobileUploadSuccessProps) => {
  // -- State --
  const [idToDelete, setIdToDelete] = useState<string | null>(null)

  // -- Hooks --
  const { t } = useTranslation()

  // -- Handlers --
  const handleTakePicture = async () => {
    if (onAddImage) {
      const file = await takePicture(
        t('onboarding.mobile-documents.add-picture')
      )

      // If file is larger than 5MB, throw an error
      if (file.size > 5000000) {
        return toast.error(t('onboarding.mobile-images.size-error'))
      }

      await onAddImage([file])
    }
  }

  const handleDeleteImage = () => {
    if (idToDelete) {
      onDelete(idToDelete)
      setIdToDelete(null)
    }
  }
  // -- Render --
  return (
    <>
      <StContainer>
        <MobileChip currentStep={currentStep} totalSteps={totalSteps} success />
        <StH4>{title}</StH4>
        {imageIds ? (
          <StFlexContainer>
            {imageIds.map((imageId) => (
              <StPreviewContainer key={imageId}>
                <StFilePreview>
                  {token ? (
                    <ZohoTokenImage id={imageId} token={token} />
                  ) : (
                    <ZohoImage id={imageId} module={ZohoModule.File} />
                  )}
                </StFilePreview>

                <StButton onClick={() => setIdToDelete(imageId)}>
                  <FontAwesomeIcon icon={['fasr', 'trash']} fontSize={14} />
                </StButton>
              </StPreviewContainer>
            ))}
          </StFlexContainer>
        ) : null}

        <FloatingButtonContainer>
          {onAddImage ? (
            <StButtonTertiary
              onClick={handleTakePicture}
              icon={['fass', 'plus']}
              iconAlignment="left"
            >
              {t('onboarding.mobile-documents.add-picture')}
            </StButtonTertiary>
          ) : null}
          <ButtonPrimary onClick={onSubmit}>
            {t('onboarding.mobile-documents.submit')}
          </ButtonPrimary>
        </FloatingButtonContainer>
      </StContainer>
      <Dialog
        title={t('onboarding.mobile-documents.delete.title')}
        primaryButtonText={t('onboarding.mobile-documents.delete.delete')}
        secondaryButtonText={t('onboarding.mobile-documents.delete.cancel')}
        onClickPrimaryButton={handleDeleteImage}
        onClickSecondaryButton={() => setIdToDelete(null)}
        onClickCloseButton={() => setIdToDelete(null)}
        open={!!idToDelete}
      />
    </>
  )
}

const StContainer = styled.div`
  padding-bottom: ${({ theme }) =>
    `calc(var(--inset-bottom, ${theme.UI.SpacingPx.Space6}) + 128px)`};
`

const StH4 = styled(H4)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StFlexContainer = styled.div<{ $error?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  color: ${({ $error, theme }) =>
    $error ? theme.theme.colors.error : theme.theme.colors.black};
`

const StPreviewContainer = styled.div`
  position: relative;
`

const StButton = styled.button`
  cursor: pointer;

  border: none;
  border-radius: 9999px;

  position: absolute;
  top: ${({ theme }) => theme.UI.SpacingPx.Space4};
  right: ${({ theme }) => theme.UI.SpacingPx.Space4};

  background-color: ${({ theme }) => theme.theme.colors.white};
  color: ${({ theme }) => theme.theme.colors.black};

  width: ${({ theme }) => theme.UI.SpacingPx.Space8};
  height: ${({ theme }) => theme.UI.SpacingPx.Space8};

  display: flex;
  justify-content: center;
  align-items: center;
`

const StFilePreview = styled.div`
  height: 100%;
  width: 100%;

  aspect-ratio: 1 / 1;

  object-fit: cover;
  border-radius: 6px;
  overflow: hidden;
`

const StButtonTertiary = styled(ButtonTertiary)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
