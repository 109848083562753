import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { MultipleFileInput } from '../../../../components/form/file-input/MultipleFileInput'
import { BodyMediumRegular, H6 } from '../../../../components/typography'
import example1 from '../../assets/examples/example1.png'
import example2 from '../../assets/examples/example2.png'
import example3 from '../../assets/examples/example3.png'
import { QrCodeSkeletonLoader } from '../../assets/QrCode.skeleton'
import { FloatingContainerButton } from '../../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { useImagePolling } from '../../hooks/useImagePolling'
import { useMultiImageUpload } from '../../hooks/useMultiImageUpload'
import { useOnboarding } from '../../hooks/useOnboarding'

export const UploadDesktopStep = () => {
  // -- Hooks --
  const { handleNext, values } = useOnboarding()
  const { t } = useTranslation()
  useImagePolling()

  // -- Data --
  const [{ data: onboardingToken }] = useAuthMemberAxios({
    url: Urls.Onboardingtoken,
  })

  const {
    images: electricalBoxImages,
    loading: electricalBoxLoading,
    handleAddImage: handleChangeElectricalBox,
    handleRemoveImage: handleRemoveElectricalBox,
  } = useMultiImageUpload('electricalBox', 'electricalBoxImageIds')

  const {
    images: fuseBoxImages,
    loading: fuseBoxLoading,
    handleAddImage: handleChangeFuseBox,
    handleRemoveImage: handleRemoveFuseBox,
  } = useMultiImageUpload('fuseBox', 'fuseBoxImageIds')

  const {
    images: electricityMeterImages,
    loading: electricityMeterLoading,
    handleAddImage: handleChangeElectricityMeter,
    handleRemoveImage: handleRemoveElectricityMeter,
  } = useMultiImageUpload('electricityMeter', 'electricityMeterImageIds')

  const {
    images: chargerLocationImages,
    loading: chargerLocationLoading,
    handleAddImage: handleChangeChargerLocation,
    handleRemoveImage: handleRemoveChargerLocation,
  } = useMultiImageUpload('chargerLocationImage', 'chargerLocationImageIds')

  const {
    images: extraImages,
    loading: extraImagesLoading,
    handleAddImage: handleAddExtraImage,
    handleRemoveImage: handleRemoveExtraImage,
  } = useMultiImageUpload('extraImages', 'extraImageIds')

  // -- Handlers --
  const handleSubmit = () => {
    handleNext(values)
  }

  return (
    <div>
      <OnboardingStepTitle>
        {t('onboarding.desktop-documents.title')}
      </OnboardingStepTitle>
      <StContainer>
        <StSectionContainer>
          <StFlexContainer>
            <div>
              <StH6>{t('onboarding.desktop-documents.qr.title')}</StH6>
              <BodyMediumRegular>
                {t('onboarding.desktop-documents.qr.subtitle')}
              </BodyMediumRegular>
            </div>
            {onboardingToken ? (
              <StQR
                value={`${window.location.origin}/image-upload/${onboardingToken}`}
              />
            ) : (
              <QrCodeSkeletonLoader />
            )}
          </StFlexContainer>
        </StSectionContainer>

        <StSectionContainer>
          <StH6>{t('onboarding.desktop-documents.upload.title')}</StH6>
          <StInputContainer>
            <MultipleFileInput
              values={electricalBoxImages}
              onFileChange={handleChangeElectricalBox}
              onDelete={handleRemoveElectricalBox}
              isLoading={electricalBoxLoading}
              label={t('onboarding.desktop-documents.input-1.label')}
              example={{
                title: t('onboarding.desktop-documents.input-1.example.title'),
                subtitle: t(
                  'onboarding.desktop-documents.input-1.example.subtitle'
                ),
                pictures: [example1, example2, example3],
              }}
            />

            <MultipleFileInput
              values={fuseBoxImages}
              onFileChange={handleChangeFuseBox}
              onDelete={handleRemoveFuseBox}
              isLoading={fuseBoxLoading}
              label={t('onboarding.desktop-documents.input-2.label')}
              example={{
                title: t('onboarding.desktop-documents.input-2.example.title'),
                subtitle: t(
                  'onboarding.desktop-documents.input-2.example.subtitle'
                ),
                pictures: [example1, example2, example3],
              }}
            />

            <MultipleFileInput
              values={electricityMeterImages}
              onFileChange={handleChangeElectricityMeter}
              onDelete={handleRemoveElectricityMeter}
              isLoading={electricityMeterLoading}
              label={t('onboarding.desktop-documents.input-3.label')}
              example={{
                title: t('onboarding.desktop-documents.input-3.example.title'),
                subtitle: t(
                  'onboarding.desktop-documents.input-3.example.subtitle'
                ),
                pictures: [example1, example2, example3],
              }}
            />

            <MultipleFileInput
              values={chargerLocationImages}
              onFileChange={handleChangeChargerLocation}
              onDelete={handleRemoveChargerLocation}
              isLoading={chargerLocationLoading}
              label={t('onboarding.desktop-documents.input-4.label')}
              example={{
                title: t('onboarding.desktop-documents.input-4.example.title'),
                subtitle: t(
                  'onboarding.desktop-documents.input-4.example.subtitle'
                ),
                pictures: [example1, example2, example3],
              }}
            />

            <MultipleFileInput
              values={extraImages}
              onFileChange={handleAddExtraImage}
              onDelete={handleRemoveExtraImage}
              isLoading={extraImagesLoading}
              label={t('onboarding.desktop-documents.input-5.label')}
            />
          </StInputContainer>
        </StSectionContainer>
      </StContainer>
      <FloatingContainerButton
        onClick={handleSubmit}
        title={t('onboarding.desktop-documents.submit')}
        disabled={
          !(
            values.electricalBoxImageIds?.length &&
            values.fuseBoxImageIds?.length &&
            values.electricityMeterImageIds?.length &&
            values.chargerLocationImageIds?.length
          )
        }
      />
    </div>
  )
}

const StContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space20};

  padding-bottom: ${({ theme }) =>
    `calc(var(--sticky-button-container-height) + ${theme.UI.SpacingPx.Space6})`};
`

const StSectionContainer = styled.div`
  min-width: 0;
`

const StFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  div {
    flex: 1;
  }
`

const StH6 = styled(H6)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StQR = styled(QRCodeSVG)`
  width: ${({ theme }) => theme.UI.SpacingPx.Space30};
  height: ${({ theme }) => theme.UI.SpacingPx.Space30};
`

const StInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`
