import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { EmployeeRoutes } from '../../../../routing/routes'
import { useAuthentication } from '../../../authentication/hooks/useAuthentication'
import { DropdownMenu } from '../../../components/general/DropdownMenu'
import { useUser } from '../../../user/hooks/useUser'

import type { DropdownMenuGroup } from '../../../components/general/DropdownMenu'

export const EmployeeProfileMenu = () => {
  const { logout } = useAuthentication()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useUser()

  const menuGroups: DropdownMenuGroup[] = [
    {
      key: 'profile',
      items: [
        {
          name: t('employee.profile.personal-details.title'),
          onClick: () => navigate(EmployeeRoutes.PersonalDetails),
          icon: ['fasr', 'user'],
        },
        {
          name: t('employee.profile.charger.title'),
          onClick: () => navigate(EmployeeRoutes.ChargingStation),
          icon: ['fasr', 'charging-station'],
          show: () =>
            user.addresses.some((address) => address.devices?.length > 0),
        },
        {
          name: t('employee.profile.charge-card.title'),
          onClick: () => navigate(EmployeeRoutes.ChargeCard),
          icon: ['fasr', 'credit-card-blank'],
          show: () =>
            user.members.some((member) => member.mspCards?.length > 0),
        },
        {
          name: t('employee.profile.settings.title'),
          onClick: () => navigate(EmployeeRoutes.Settings),
          icon: ['fasr', 'cog'],
        },
        {
          name: t('employee.profile.privacy.title'),
          onClick: () => navigate(EmployeeRoutes.Privacy),
          icon: ['fasr', 'lock'],
        },
      ],
    },
    {
      key: 'actions',
      items: [
        {
          name: t('employee.profile.logout'),
          onClick: () => logout(),
          icon: ['fasr', 'arrow-right-from-bracket'],
        },
      ],
    },
  ]

  return (
    <DropdownMenu groups={menuGroups}>
      <StTrigger>
        <FontAwesomeIcon
          data-state="closed"
          icon={['fasr', 'circle-user']}
          size="lg"
        />
        <FontAwesomeIcon
          data-state="open"
          icon={['fass', 'circle-user']}
          size="lg"
        />
      </StTrigger>
    </DropdownMenu>
  )
}

const StTrigger = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  display: grid;
  place-items: center;

  align-self: center;

  svg[data-state='open'] {
    display: none;
  }

  svg[data-state='closed'] {
    display: block;
  }

  &[data-state='open'] {
    svg[data-state='open'] {
      display: block;
    }

    svg[data-state='closed'] {
      display: none;
    }
  }
`
