import { getDay, isWithinInterval } from 'date-fns'

import { convertStringToDateTime } from '../../../api/lib/ConvertStringToDateTime'

import type { OpeningTime } from 'types'

export const chargingPointIsOpen = (openingTimes: OpeningTime[] | null) => {
  // If no opening times are present, the location is considered to be always open
  if (!openingTimes) return true

  const openingTime = openingTimes?.find(
    (item) => item.weekDay === getDay(new Date())
  )

  return openingTime
    ? isWithinInterval(new Date(), {
        start: convertStringToDateTime(openingTime.periodBegin),
        end: convertStringToDateTime(openingTime.periodEnd),
      })
    : false
}
