import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { PersonalDataEdit } from '../../../components/profile/PersonalDataEdit'
import { useUser } from '../../../user/hooks/useUser'

export const EmployerPersonalDataEdit = () => {
  // -- State --
  const [editable, setEditable] = useState<boolean>(false)

  // -- Hooks --
  const { t } = useTranslation()
  const { user, updateUser, updateLoading } = useUser()

  // -- Render --
  return (
    <StContainer>
      <PersonalDataEdit
        user={user}
        updateUser={updateUser}
        disabled={updateLoading}
        title={t('profile.personal-data.title')}
        editable={editable}
        setEditable={setEditable}
      />
    </StContainer>
  )
}

const StContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6} 0;
`
