import { styled } from 'styled-components'

import emptyImage from '../assets/empty.svg'
import { BodyMediumRegular, H5 } from '../typography'

type EmptyProps = {
  title?: string
  description?: string
}

export const Empty = ({ title, description }: EmptyProps) => {
  return (
    <StEmptyContainer>
      <img src={emptyImage} alt="empty state" />
      <StEmptyHeader>
        <StEmptyTitle>{title}</StEmptyTitle>
        <BodyMediumRegular>{description}</BodyMediumRegular>
      </StEmptyHeader>
    </StEmptyContainer>
  )
}

const StEmptyContainer = styled.div`
  display: grid;
  place-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  margin: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space15} ${theme.UI.SpacingPx.Space7}`};
`
const StEmptyHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StEmptyTitle = styled(H5)`
  margin: 0;
`
