import { PostHogProvider } from 'posthog-js/react'
import { type PropsWithChildren } from 'react'

type AnalyticsProviderProps = PropsWithChildren

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  autocapture: false,
  disable_persistence: true,
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      {children}
    </PostHogProvider>
  )
}
