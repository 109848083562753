import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { EventType, useTracking } from '../../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { Dialog } from '../../../../components/general/Dialog'
import { HcpStatusType } from '../../../../employer/employees/components/EmployeesDataTable'
import { useUpdateStatus } from '../../../../member/hooks/useUpdateStatus'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { SelectBoxes } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

export enum PrivateTerrainKeys {
  Yes = 'Yes',
  Permission = 'Permission Needed',
  No = 'No',
}

export const PrivateTerrainStep = () => {
  const {
    handleNext,
    setLoading,
    values,
    items: { privateTerrainItems },
  } = useOnboarding()

  // -- State --
  const [selectedItem, setSelectedItem] = useState(values.privateTerrainKey)
  const [open, setOpen] = useState(false)

  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )
  const { updateStatus } = useUpdateStatus()

  // -- Handlers --
  const handleSelect = (key: PrivateTerrainKeys) => {
    // Open the dialog if "No" is selected
    if (key === PrivateTerrainKeys.No) {
      setOpen(true)
      trackEvent(EventType.Open, 'open_rejection_modal')
    } else {
      handleSubmit(key)
    }
  }

  const handleSubmit = async (key: PrivateTerrainKeys) => {
    try {
      setSelectedItem(key)
      setLoading(true)

      if (key !== values.privateTerrainKey) {
        await execute({
          data: {
            privateTerrainKey: key,
          },
        })

        if (key === PrivateTerrainKeys.No) {
          await updateStatus(HcpStatusType.OnboardingRejected)
        }
      }

      handleNext(
        { ...values, privateTerrainKey: key },
        key === PrivateTerrainKeys.No
      )
    } catch {
      setLoading(false)
      return console.error('Failed to save charger location')
    }
  }

  // -- Render --
  return (
    <>
      <StContainer>
        <OnboardingStepTitle>
          {t('onboarding.private-terrain.title')}
        </OnboardingStepTitle>

        <SelectBoxes
          items={privateTerrainItems}
          onSelect={handleSelect}
          selectedItem={selectedItem}
        />
      </StContainer>

      <Dialog
        title={t('onboarding.private-terrain.dialog.title')}
        description={t('onboarding.private-terrain.dialog.description')}
        open={open}
        primaryButtonText={t('onboarding.private-terrain.dialog.confirm')}
        secondaryButtonText={t('onboarding.private-terrain.dialog.cancel')}
        onClickSecondaryButton={() => setOpen(false)}
        onClickCloseButton={() => {
          trackEvent(EventType.Close, 'close_rejection_modal')
          setOpen(false)
        }}
        onClickPrimaryButton={() => {
          trackEvent(EventType.Click, 'confirm_rejection')
          handleSubmit(PrivateTerrainKeys.No)
          setOpen(false)
        }}
      />
    </>
  )
}

const StContainer = styled.div`
  height: 100%;
`
