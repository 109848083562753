import { Trans, useTranslation } from 'react-i18next'

import { EmployeeRoutes } from '../../../routing/routes'

import { ErrorSvg } from './assets/ErrorSvg'
import { FullScreenError } from './FullScreenError'

export const ErrorScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()

  // -- Handlers --
  const refresh = () => {
    location.replace(EmployeeRoutes.Root)
  }

  // -- Render --
  return (
    <FullScreenError
      onClick={refresh}
      title={
        <Trans
          t={t}
          i18nKey="error-screen.title"
          components={{
            br: <br />,
          }}
        />
      }
      subTitle={t('error-screen.subtitle')}
      buttonText={t('error-screen.button')}
      svg={<ErrorSvg />}
    />
  )
}
