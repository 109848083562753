import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css, styled } from 'styled-components'

import { onHover } from '../../core/lib/styleHelpers'
import { BodyMediumRegularCss, BodySmallRegularCss } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ButtonHTMLAttributes, PropsWithChildren } from 'react'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren & {
    icon?: IconProp
    size?: 'sm' | 'md' | 'lg'
    border?: boolean
  }

export const ButtonQuaternary = ({
  children,
  icon,
  size = 'lg',
  border = true,
  ...props
}: ButtonProps) => {
  return (
    <StButton {...props} $size={size}>
      <StButtonText $withBorder={border}>{children}</StButtonText>
      {icon ? <StFontAwesomeIcon icon={icon} /> : null}
    </StButton>
  )
}

const StButton = styled.button<{ $size: 'sm' | 'md' | 'lg' }>`
  ${({ $size }) =>
    $size === 'lg' ? BodyMediumRegularCss : BodySmallRegularCss}

  height: fit-content;

  border: none;
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => theme.components.buttons.quaternary['default-txt']};

  padding: 0;

  ${onHover(css`
    color: ${({ theme }) =>
      theme.components.buttons.quaternary['hover-stroke']};
    border-color: ${({ theme }) =>
      theme.components.buttons.quaternary['hover-stroke']};
  `)}
`

const StButtonText = styled.span<{ $withBorder: boolean }>`
  line-height: calc(150% + 6px);
  padding-bottom: ${({ theme, $withBorder }) =>
    $withBorder ? theme.UI.SpacingPx.Space1 : '0px'};
  border-bottom: ${({ theme, $withBorder }) =>
    $withBorder
      ? `1px solid ${theme.components.buttons.quaternary['default-stroke']}`
      : 'none'};
`

const StFontAwesomeIcon = styled(FontAwesomeIcon)`
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
