import { Form, Formik } from 'formik'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import * as Yup from 'yup'

import { EmployeeRoutes } from '../../../../routing/routes'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { Select } from '../../../components/form/Select'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { ContentContainer } from '../../../components/page-layout'
import { BodyMediumRegular } from '../../../components/typography'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'

import type { SelectOption } from '../../../components/form/Select'
import type { MspCard, $Enums } from 'database'

type BlockChargeCardFormValues = {
  reason?: $Enums.MspCardStatus
}

export const BlockChargeCardScreen = () => {
  // -- Hooks --
  const { currentChargeCard, currentMember } = useMember()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { refetch } = useUser()
  const { trackEvent } = useTracking()

  // -- State --
  const [loading, setLoading] = useState(false)

  // -- Data --
  const [, execute] = useAuthMemberAxios<MspCard>(
    {
      url: Urls.blockChargeCard,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required(
      t('charge-card.detail.activation.form.error.required')
    ),
  })

  const blockChargeCardReasons: SelectOption[] = [
    {
      key: 'Lost',
      label: t('charge-card.block.reason.lost'),
    },
    {
      key: 'Stolen',
      label: t('charge-card.block.reason.stolen'),
    },
    {
      key: 'Blocked',
      label: t('charge-card.block.reason.other'),
    },
  ]

  // -- Handlers --
  const handleSubmit = async (values: BlockChargeCardFormValues) => {
    if (!currentChargeCard) {
      return console.error('No active charge card')
    }

    if (!values.reason) {
      return console.error('No reason selected')
    }

    try {
      setLoading(true)

      await execute({
        data: {
          mspUid: currentChargeCard.mspUid,
          status: values.reason,
        },
      })

      refetch()
      trackEvent(EventType.Submit, 'block_msp_card', {
        reason: values.reason,
      })
      toast.success(t('charge-card.block.success'))
      navigate(EmployeeRoutes.ChargeCard)
    } catch {
      setLoading(false)
      toast.error(t('charge-card.block.error'))

      return console.error('Failed to block msp card')
    }
  }

  // -- Render --
  if (!currentChargeCard || currentChargeCard.mspCardStatus !== 'Active') {
    navigate(EmployeeRoutes.Profile)
  }

  return (
    <ContentContainer>
      <DetailPageHeader
        onBack={() => navigate(EmployeeRoutes.ChargeCard)}
        title={t('charge-card.block.title')}
        topTitle={t('charge-card.block.topTitle')}
      />
      <Formik
        initialValues={{ reason: undefined } as BlockChargeCardFormValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, values }) => (
          <StBlockChargeCardForm>
            <BodyMediumRegular>
              {currentMember.manualMspHandover
                ? t('charge-card.block.form.title.manual-handover')
                : t('charge-card.block.form.title')}
            </BodyMediumRegular>
            <Select
              label={t('charge-card.block.reason.label')}
              placeholder={t('charge-card.block.reason.placeholder')}
              value={values.reason}
              onChange={(value) =>
                setFieldValue(
                  'reason',
                  value as $Enums.MspCardStatus | undefined
                )
              }
              options={blockChargeCardReasons}
            />
            <StSubmitButton disabled={loading || !values.reason} type="submit">
              {t('charge-card.block.submit')}
            </StSubmitButton>
          </StBlockChargeCardForm>
        )}
      </Formik>
    </ContentContainer>
  )
}

const StBlockChargeCardForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};

  max-width: 800px;
`

const StSubmitButton = styled(ButtonPrimary)`
  align-self: flex-start;
  width: auto;
`
