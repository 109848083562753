import { styled } from 'styled-components'

import { BodyLargeSemiBold } from '../../../components/typography'

import type { PropsWithChildren } from 'react'

export type HomeScreenElementProps = PropsWithChildren & {
  title?: string
}

export const HomeScreenElement = ({
  title,
  children,
}: HomeScreenElementProps) => {
  // -- Render component --
  return (
    <StHomeScreenElement>
      {title && <BodyLargeSemiBold>{title}</BodyLargeSemiBold>}
      {children}
    </StHomeScreenElement>
  )
}

const StHomeScreenElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
