import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import {
  BodyLargeMedium,
  BodyMediumRegular,
  H5,
} from '../../../components/typography'

export function PairedDevicesCard({
  ocppId,
  dongleMacAddress,
}: PairedDevicesCardProps) {
  const { t } = useTranslation()

  return (
    <StCard>
      <StCardTitle>
        {t('admin.employee-detail.card.paired-devices.title')}
      </StCardTitle>
      <StCardContent>
        <div>
          <StLabel>
            {t(
              'admin.employee-detail.card.paired-devices.hcp-ace-number.label'
            )}
          </StLabel>
          <BodyLargeMedium>{ocppId ?? '-'}</BodyLargeMedium>
        </div>
        <div>
          <StLabel>
            {t(
              'admin.employee-detail.card.paired-devices.dongle-mac-address.label'
            )}
          </StLabel>
          <BodyLargeMedium>{dongleMacAddress ?? '-'}</BodyLargeMedium>
        </div>
      </StCardContent>
    </StCard>
  )
}
type PairedDevicesCardProps = {
  dongleMacAddress: string | null
  ocppId: string | null
}

const StCard = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StCardTitle = styled(H5)`
  margin-top: 0;
`

const StCardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space8};
`

const StLabel = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
