import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { EventType, useTracking } from '../../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { Dialog } from '../../../../components/general/Dialog'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { SelectBoxes } from '../../components/SelectBoxes'
import { useOnboarding } from '../../hooks/useOnboarding'

export enum WallMaterialKeys {
  Ideal = 'Ideal',
  NotIdeal = 'Not Ideal',
}

export const WallMaterialStep = () => {
  const {
    handleNext,
    setLoading,
    values,
    items: { wallMaterialItems },
  } = useOnboarding()

  // -- State --
  const [selectedItem, setSelectedItem] = useState(
    values.installationMaterialKey
  )
  const [open, setOpen] = useState(false)

  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Handlers --
  const handleSelect = (key: WallMaterialKeys) => {
    // Open the dialog if "NotIdeal" is selected
    if (key === WallMaterialKeys.NotIdeal) {
      setOpen(true)
      trackEvent(EventType.Open, 'open_presite_visit_modal')
    } else {
      handleSubmit(key)
    }
  }

  const handleSubmit = async (key: WallMaterialKeys) => {
    try {
      setSelectedItem(key)
      setLoading(true)

      if (key !== values.installationMaterialKey) {
        await execute({
          data: {
            installationMaterialKey: key,
          },
        })
      }

      handleNext({ ...values, installationMaterialKey: key })
    } catch {
      setLoading(false)
      return console.error('Failed to save installation material')
    }
  }

  return (
    <>
      <StContainer>
        <OnboardingStepTitle>
          {t('onboarding.installation-material.title')}
        </OnboardingStepTitle>

        <SelectBoxes
          items={wallMaterialItems}
          onSelect={handleSelect}
          selectedItem={selectedItem}
        />
      </StContainer>
      <Dialog
        title={t('onboarding.installation-material.dialog.title')}
        description={t('onboarding.installation-material.dialog.description')}
        primaryButtonText={t('onboarding.installation-material.dialog.confirm')}
        secondaryButtonText={t(
          'onboarding.installation-material.dialog.cancel'
        )}
        open={open}
        onClickPrimaryButton={() => {
          trackEvent(EventType.Click, 'confirm_site_visit')
          handleSubmit(WallMaterialKeys.NotIdeal)
          setOpen(false)
        }}
        onClickSecondaryButton={() => setOpen(false)}
        onClickCloseButton={() => {
          setOpen(false)
          trackEvent(EventType.Close, 'close_presite_visit_modal')
        }}
      />
    </>
  )
}

const StContainer = styled.div`
  height: 100%;
`
