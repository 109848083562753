import { useContext } from 'react'

import { hemsIntroductionContext } from '../components/introduction/HemsIntroduction'

export const useHemsIntroduction = () => {
  const context = useContext(hemsIntroductionContext)

  if (!context) {
    throw new Error(
      'useHemsIntroduction must be used within a HemsIntroductionProvider'
    )
  }

  return context
}
