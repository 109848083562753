import {
  addDays,
  addHours,
  differenceInDays,
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isBefore,
  isToday,
  set,
  setHours,
  setMinutes,
  startOfDay,
} from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodyLargeMediumCss } from '../../../../components/typography'
import { SelectedWheelSlide, Wheel } from '../../../../components/Wheel'
import { useGetDateFnsLocale } from '../../../../core/hooks/useGetDateFnsLocale'

function getInitialDateIndex(value: Date) {
  const difference = differenceInDays(startOfDay(value), startOfDay(new Date()))

  return difference < 0 ? 0 : difference
}

type AutopilotDateTimePickerProps = {
  onChange: (newDate: Date) => void
  value: Date
}

const bufferHours = 8

// FIXME: there is a weird bug when the day is changed and afterwards the hour/minute is passed.
// The day gets reverted to the initial day
export function AutopilotDateTimePicker({
  value,
  onChange,
}: AutopilotDateTimePickerProps) {
  const { t } = useTranslation()
  const { locale } = useGetDateFnsLocale()

  // State to store the internal representation of the date
  const [internalDate, setInternalDate] = useState(new Date(value))

  // The earliest date that can be selected is n hours from now
  const includeToday = getHours(new Date()) < 24 - bufferHours

  const currentDate = new Date()
  const currentHours = getHours(currentDate)
  const currentMinutes = getMinutes(currentDate)
  const earliestSelectableDate = useMemo(() => addHours(new Date(), 8), [])
  const earliestSelectableHours = getHours(earliestSelectableDate)
  const earliestSelectableMinutes = getMinutes(earliestSelectableDate)

  const hourLength =
    isToday(internalDate) ||
    getInitialDateIndex(internalDate) === (includeToday ? 0 : 1)
      ? 24 -
        (includeToday
          ? Math.max(currentHours, earliestSelectableHours)
          : earliestSelectableHours)
      : 24

  const minuteLength =
    (isToday(internalDate) ||
      getInitialDateIndex(internalDate) === (includeToday ? 0 : 1)) &&
    getHours(internalDate) ===
      (includeToday
        ? Math.max(currentHours, earliestSelectableHours)
        : earliestSelectableHours)
      ? 60 -
        (includeToday
          ? Math.max(currentMinutes, earliestSelectableMinutes)
          : earliestSelectableMinutes)
      : 60

  // Effect to update internalDate when the value prop changes
  useEffect(() => {
    const valueDate = new Date(value)

    if (isBefore(valueDate, earliestSelectableDate)) {
      onChange(earliestSelectableDate)
      setInternalDate(earliestSelectableDate)
      return
    }

    setInternalDate(valueDate)
  }, [value, earliestSelectableDate, onChange])

  const handleDateChange = (
    wheelIndex: number,
    type: 'day' | 'hour' | 'minute'
  ) => {
    let newDate = internalDate

    switch (type) {
      case 'day': {
        const selectedDate = addDays(
          new Date(),
          includeToday ? wheelIndex : wheelIndex + 1
        )

        newDate = set(internalDate, {
          year: getYear(selectedDate),
          month: getMonth(selectedDate),
          date: getDate(selectedDate),
        })

        setInternalDate((previousDate) => {
          const selectedDate = addDays(
            new Date(),
            includeToday ? wheelIndex : wheelIndex + 1
          )

          newDate = set(previousDate, {
            year: getYear(selectedDate),
            month: getMonth(selectedDate),
            date: getDate(selectedDate),
          })

          // if the new date is before earliestSelectableDate, set it to earliestSelectableDate
          if (isBefore(newDate, earliestSelectableDate)) {
            onChange(earliestSelectableDate)
            return earliestSelectableDate
          }

          onChange(newDate)
          return newDate
        })

        return
      }

      case 'hour': {
        setInternalDate((previousDate) => {
          const newDate = setHours(
            previousDate,
            earliestSelectableHours + wheelIndex
          )

          // if the new date is before earliestSelectableDate, set it to earliestSelectableDate
          if (isBefore(newDate, earliestSelectableDate)) {
            onChange(earliestSelectableDate)
            return earliestSelectableDate
          }

          onChange(newDate)
          return newDate
        })

        return
      }

      case 'minute': {
        setInternalDate((previousDate) => {
          const newDate = setMinutes(
            previousDate,
            getHours(previousDate) === earliestSelectableHours
              ? earliestSelectableMinutes + wheelIndex
              : wheelIndex
          )

          // if the new date is before earliestSelectableDate, set it to earliestSelectableDate
          if (isBefore(newDate, earliestSelectableDate)) {
            onChange(earliestSelectableDate)
            return earliestSelectableDate
          }

          onChange(newDate)
          return newDate
        })

        return
      }
    }
  }

  return (
    <StContainer>
      <SelectedWheelSlide />
      <StDateWheel
        length={14}
        width={140}
        perspective="right"
        formatValue={(relativeWheelIndex) => {
          if (relativeWheelIndex === 0 && includeToday) {
            return t('employee.hems.charge-mode.autopilot.today')
          }

          if (
            (relativeWheelIndex === 1 && includeToday) ||
            (relativeWheelIndex === 0 && !includeToday)
          ) {
            return t('employee.hems.charge-mode.autopilot.tomorrow')
          }

          return format(
            addDays(new Date(), relativeWheelIndex + (includeToday ? 2 : 1)),
            'd MMM',
            {
              locale,
            }
          )
        }}
        initialIndex={getInitialDateIndex(internalDate)}
        onChange={(wheelIndex) => {
          handleDateChange(wheelIndex, 'day')
        }}
      />

      <StHourMinuteWheelContainer>
        {hourLength === 24 ? (
          <StHourWheel
            key={`hours-${hourLength}`}
            loop
            length={24}
            width={23}
            perspective="right"
            initialIndex={getHours(internalDate)}
            formatValue={(relative) => `${relative}`.padStart(2, '0')}
            onChange={(wheelIndex) => {
              handleDateChange(wheelIndex, 'hour')
            }}
          />
        ) : (
          <StHourWheel
            key={`hours-${hourLength}`}
            loop={false}
            length={hourLength}
            width={23}
            perspective="right"
            initialIndex={
              isBefore(internalDate, earliestSelectableDate)
                ? 0
                : getHours(internalDate) - earliestSelectableHours
            }
            formatValue={(relative) => {
              const startingHour = earliestSelectableHours

              if (startingHour === 0) {
                return `${relative}`.padStart(2, '0')
              }

              return `${(startingHour + relative) % 24}`.padStart(2, '0')
            }}
            onChange={(wheelIndex) => {
              handleDateChange(wheelIndex, 'hour')
            }}
          />
        )}

        <StHourMinuteWheelColon>:</StHourMinuteWheelColon>

        {minuteLength === 60 ? (
          <StMinuteWheel
            key={`minutes-${minuteLength}`}
            loop
            length={60}
            width={23}
            perspective="left"
            initialIndex={getMinutes(internalDate)}
            formatValue={(relative) => `${relative}`.padStart(2, '0')}
            onChange={(wheelIndex) => {
              handleDateChange(wheelIndex, 'minute')
            }}
          />
        ) : (
          <StMinuteWheel
            key={`minutes-${minuteLength}`}
            loop={false}
            length={minuteLength}
            width={23}
            perspective="left"
            initialIndex={getMinutes(internalDate) - earliestSelectableMinutes}
            formatValue={(relative) => {
              const startingMinute = earliestSelectableMinutes

              if (startingMinute === 0) {
                return `${relative}`.padStart(2, '0')
              }

              return `${(startingMinute + relative) % 60}`.padStart(2, '0')
            }}
            onChange={(wheelIndex) => {
              handleDateChange(wheelIndex, 'minute')
            }}
          />
        )}
      </StHourMinuteWheelContainer>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space7};
`

const StDateWheel = styled(Wheel)`
  width: 140px;
  height: 200px;
  flex: 1;
`

const StHourMinuteWheelContainer = styled.div`
  flex: 1;
  height: 200px;
  position: relative;
  display: flex;
`

const StHourMinuteWheelColon = styled.span`
  ${BodyLargeMediumCss}

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const StMinuteWheel = styled(Wheel)`
  flex: 1;
`

const StHourWheel = styled(Wheel)`
  flex: 1;
`
