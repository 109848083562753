// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/named
import { isAxiosError, type AxiosError, type AxiosResponse } from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { merge } from 'ts-deepmerge'

import { axios } from '../lib/axios'
import { configToObject } from '../lib/axiosHelpers'

import type { Options, RefetchFunction, UseAxiosResult } from './types'
import type { ConfigWithCache } from 'axios-cache-interceptor'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export const useAxios = <TResponse = any, TBody = any, TError = any>(
  config: Optional<ConfigWithCache<TBody>, 'cache'> | string,
  options?: Options
): UseAxiosResult<TResponse, TBody, TError> => {
  const configMemo = useMemo(() => config, [JSON.stringify(config)])

  const [loading, setLoading] = useState(!options?.manual)
  const [error, setError] = useState<AxiosError<TError, TBody>>()
  const [data, setData] = useState<TResponse>()
  const [response, setResponse] = useState<AxiosResponse>()

  const request: RefetchFunction<any, any> = async (refetchConfig) => {
    setLoading(true)

    try {
      const configWithCache = configToObject(config)

      // Make caching opt-in
      if (!options?.cache) {
        configWithCache.cache = false
      }

      const combinedConfig = merge(
        configWithCache,
        configToObject(refetchConfig)
      )

      const axiosResponse = await axios({
        ...combinedConfig,
        headers: {
          ...combinedConfig.headers,
          // Header to bypass localTunnel security
          'Bypass-Tunnel-Reminder': 'Emiel is cool',
        },
      })

      setData(axiosResponse.data)
      setResponse(axiosResponse)
      setLoading(false)

      return axiosResponse
    } catch (error) {
      if (isAxiosError(error)) {
        setError(error)
      }
      setLoading(false)

      throw error
    }
  }

  useEffect(() => {
    if (!options?.manual) {
      request()
    }
  }, [configMemo])

  return [{ data, error, loading, response }, request]
}
