import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { EmployeeRoutes } from '../../../../routing/routes'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import {
  BodySmallRegular,
  BodySmallSemiBold,
} from '../../../components/typography'
import { useMember } from '../../../member/hooks/useMember'

export type CardContents =
  | {
      title: string
      description: string
      buttonText?: string
      onClick?: () => void
    }
  | undefined

export const MspStatusCard = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentMember } = useMember()
  const { currentChargeCard } = useMember()

  const calculateMspCardContents = (): CardContents => {
    const mspStatus = currentMember?.mspStatus

    switch (mspStatus) {
      case 'Sent': {
        if (currentMember.manualMspHandover) {
          return {
            title: currentChargeCard
              ? t('employee.home.msp.manual-handover.reactivation.title')
              : t('employee.home.msp.manual-handover.activation.title'),
            description: currentChargeCard
              ? t('employee.home.msp.manual-handover.reactivation.description')
              : t('employee.home.msp.manual-handover.activation.description'),
            buttonText: currentChargeCard
              ? t('employee.home.msp.manual-handover.reactivation.button')
              : t('employee.home.msp.manual-handover.activation.button'),
            onClick: () => navigate(EmployeeRoutes.ChargeCard),
          }
        }

        return {
          title: currentChargeCard
            ? t('employee.home.msp.reactivation.title')
            : t('employee.home.msp.activation.title'),
          description: currentChargeCard
            ? t('employee.home.msp.reactivation.description')
            : t('employee.home.msp.activation.description'),
          buttonText: currentChargeCard
            ? t('employee.home.msp.reactivation.button')
            : t('employee.home.msp.activation.button'),
          onClick: () => navigate(EmployeeRoutes.ChargeCard),
        }
      }

      case 'Requested':
      case 'Exported': {
        return {
          title: t(`employee.home.msp.Requested.title`),
          description: t(`employee.home.msp.Requested.description`),
        }
      }

      default: {
        break
      }
    }
  }

  const mspCardContents = calculateMspCardContents()

  if (!mspCardContents) {
    return null
  }

  return (
    <StMspStatusCard>
      <BodySmallSemiBold>{mspCardContents.title}</BodySmallSemiBold>
      <>
        <BodySmallRegular>{mspCardContents.description}</BodySmallRegular>
        {mspCardContents.buttonText ? (
          <ButtonPrimary compact onClick={mspCardContents.onClick} size="md">
            {mspCardContents.buttonText}
          </ButtonPrimary>
        ) : null}
      </>
    </StMspStatusCard>
  )
}

export const StMspStatusCard = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  p {
    margin-bottom: 2px;
  }

  button {
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`
