import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { Urls } from '../../../api/urls'
import emptyTable from '../../../components/assets/empty.svg'
import emptyList from '../../../components/assets/empty_list.svg'
import { H5, BodyMediumRegular } from '../../../components/typography'
import { SortKeys } from '../../../dataTable/components/DataTableHeader'
import {
  DataTableProvider,
  PaginationType,
} from '../../../dataTable/providers/DataTableProvider'

import { UsageDataListHcp } from './UsageDataListHcp'
import { UsageDataListMsp } from './UsageDataListMsp'
import { UsageDataTableHcp } from './UsageDataTableHcp'
import { UsageDataTableMsp } from './UsageDataTableMsp'

type UsageContentProps = {
  type?: 'msp' | 'hcp'
}

export const UsageContent = ({ type }: UsageContentProps) => {
  // Hooks
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { t } = useTranslation()

  // Render MSP data
  if (type === 'msp') {
    return (
      <DataTableProvider
        paginationType={PaginationType.Infinite}
        url={Urls.employeeMspChargingSessions}
        defaultSort="start"
        defaultSortDirection={SortKeys.DESCENDING}
      >
        {isDesktop ? <UsageDataTableMsp /> : <UsageDataListMsp />}
      </DataTableProvider>
    )
  }

  // Render HCP data
  if (type === 'hcp') {
    return (
      <DataTableProvider
        paginationType={PaginationType.Infinite}
        url={Urls.employeeHcpChargingSessions}
        defaultSort="start"
        defaultSortDirection={SortKeys.DESCENDING}
      >
        {isDesktop ? <UsageDataTableHcp /> : <UsageDataListHcp />}
      </DataTableProvider>
    )
  }

  // Render empty state
  return (
    <StEmptyContainer>
      {isDesktop ? (
        <img src={emptyTable} alt="empty state" />
      ) : (
        <img src={emptyList} alt="empty state" />
      )}
      <StEmptyContent>
        <H5>{t('employee.chargingSessions.list.empty-title')}</H5>
        {isDesktop && (
          <BodyMediumRegular>
            {t('employee.chargingSessions.table.empty-description')}
          </BodyMediumRegular>
        )}
      </StEmptyContent>
    </StEmptyContainer>
  )
}

const StEmptyContainer = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space2};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space10} ${theme.UI.SpacingPx.Space7}`};
`

const StEmptyContent = styled.div`
  text-align: center;
  > * {
    margin: 0;
  }
`
