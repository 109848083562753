import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export enum HemsConnectionLostSteps {
  TroubleshootQuestion = 'troubleshoot-question',
  ConnectionSelection = 'connection-selection',

  // Wifi-flow
  Reset = 'reset',
  Bluetooth = 'bluetooth',
  DongleButton = 'dongle-button',
  DongleConnection = 'dongle-connection',
  WifiNetworkSelection = 'network-selection',
  WifiPassword = 'wifi-password',
  WifiProvisioning = 'wifi-provisioning',

  // UTP-flow
  UtpConnection = 'utp-connection',
  UtpProvisioning = 'utp-provisioning',

  ProvisioningSuccess = 'provisioning-success',
}

type HemsOnboardingConfigOption = {
  next: (alernativeRoute?: boolean) => HemsConnectionLostSteps
  previous: () => HemsConnectionLostSteps
}

const HemsOnboardingConfig: Record<
  HemsConnectionLostSteps,
  HemsOnboardingConfigOption
> = {
  [HemsConnectionLostSteps.TroubleshootQuestion]: {
    next: () => HemsConnectionLostSteps.ConnectionSelection,
    previous: () => HemsConnectionLostSteps.Bluetooth,
  },
  [HemsConnectionLostSteps.ConnectionSelection]: {
    next: (alternative) =>
      alternative
        ? HemsConnectionLostSteps.UtpConnection
        : HemsConnectionLostSteps.Bluetooth,
    previous: () => HemsConnectionLostSteps.TroubleshootQuestion,
  },
  [HemsConnectionLostSteps.Reset]: {
    next: () => HemsConnectionLostSteps.Bluetooth,
    previous: () => HemsConnectionLostSteps.ConnectionSelection,
  },
  [HemsConnectionLostSteps.Bluetooth]: {
    next: () => HemsConnectionLostSteps.DongleButton,
    previous: () => HemsConnectionLostSteps.Reset,
  },
  [HemsConnectionLostSteps.DongleButton]: {
    next: () => HemsConnectionLostSteps.DongleConnection,
    previous: () => HemsConnectionLostSteps.Bluetooth,
  },
  [HemsConnectionLostSteps.DongleConnection]: {
    next: () => HemsConnectionLostSteps.WifiNetworkSelection,
    previous: () => HemsConnectionLostSteps.DongleButton,
  },
  [HemsConnectionLostSteps.WifiNetworkSelection]: {
    next: (unsecureNetWork) =>
      unsecureNetWork
        ? HemsConnectionLostSteps.WifiProvisioning
        : HemsConnectionLostSteps.WifiPassword,
    previous: () => HemsConnectionLostSteps.ConnectionSelection,
  },
  [HemsConnectionLostSteps.WifiPassword]: {
    next: () => HemsConnectionLostSteps.WifiProvisioning,
    previous: () => HemsConnectionLostSteps.WifiNetworkSelection,
  },
  [HemsConnectionLostSteps.WifiProvisioning]: {
    next: () => HemsConnectionLostSteps.ProvisioningSuccess,
    previous: () => HemsConnectionLostSteps.WifiPassword,
  },
  [HemsConnectionLostSteps.UtpConnection]: {
    next: () => HemsConnectionLostSteps.UtpProvisioning,
    previous: () => HemsConnectionLostSteps.ConnectionSelection,
  },
  [HemsConnectionLostSteps.UtpProvisioning]: {
    next: () => HemsConnectionLostSteps.ProvisioningSuccess,
    previous: () => HemsConnectionLostSteps.UtpConnection,
  },
  [HemsConnectionLostSteps.ProvisioningSuccess]: {
    next: () => HemsConnectionLostSteps.ProvisioningSuccess,
    previous: () => HemsConnectionLostSteps.ProvisioningSuccess,
  },
}

const headerStates: Record<
  HemsConnectionLostSteps,
  {
    cantGoBack?: boolean
    cantCancel?: boolean
  }
> = {
  [HemsConnectionLostSteps.TroubleshootQuestion]: { cantGoBack: true },
  [HemsConnectionLostSteps.ConnectionSelection]: {},
  [HemsConnectionLostSteps.Reset]: {},
  [HemsConnectionLostSteps.Bluetooth]: {},
  [HemsConnectionLostSteps.DongleButton]: {},
  [HemsConnectionLostSteps.DongleConnection]: {},
  [HemsConnectionLostSteps.WifiNetworkSelection]: {},
  [HemsConnectionLostSteps.WifiPassword]: {},
  [HemsConnectionLostSteps.WifiProvisioning]: {},
  [HemsConnectionLostSteps.UtpConnection]: {},
  [HemsConnectionLostSteps.UtpProvisioning]: {},
  [HemsConnectionLostSteps.ProvisioningSuccess]: { cantGoBack: true },
}

export const useHemsConnectionLostState = () => {
  // -- State --
  const [formState, setFormState] = useSearchParams()

  // -- Vars --
  const currentStep =
    (formState.get('step') as HemsConnectionLostSteps) ||
    HemsConnectionLostSteps.ConnectionSelection

  useEffect(() => {
    // When opening the flow, always start from the beginning
    setFormState({ step: HemsConnectionLostSteps.ConnectionSelection })
  }, [])

  // -- Handlers --
  const handleNext = (alernativeRoute?: boolean) => {
    const nextStep = HemsOnboardingConfig[currentStep].next(alernativeRoute)

    setFormState({ step: nextStep })
  }

  const handlePrevious = () => {
    const previousStep = HemsOnboardingConfig[currentStep].previous()

    setFormState({ step: previousStep })
  }

  const setStep = (step: string) => {
    setFormState({ step })
  }

  return {
    currentStep,
    handleNext,
    handlePrevious,
    headerState: headerStates[currentStep],
    setStep,
  }
}
