import { styled } from 'styled-components'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { InviteScreenSteps } from '../../invite/components/InviteScreenSteps'
import { useOnboarding } from '../hooks/useOnboarding'
import { OnboardingSteps } from '../hooks/useOnboardingState'
import { useStatusCheck } from '../hooks/useStatusCheck'

import { BankDetailsStep } from './account/BankDetailsStep'
import { PersonalDataStep } from './account/PersonalDataStep'
import { VerifyEmailStep } from './account/VerifyEmailStep'
import { OnboardingOverviewStep } from './OnboardingOverviewStep'
import { CableDigWorksStep } from './preferences/CableDigWorksStep'
import { CableDistanceStep } from './preferences/CableDistanceStep'
import { CableRouteStep } from './preferences/CableRouteStep'
import { ChargerDisassembleStep } from './preferences/ChargerDisassembleStep'
import { ChargerLocationInputStep } from './preferences/ChargerLocationInputStep'
import { ChargerLocationStep } from './preferences/ChargerLocationStep'
import { ConnectionTypeStep } from './preferences/ConnectionTypeStep'
import { DigitalMeterEnvironmentStep } from './preferences/DigitalMeterEnvironmentStep'
import { DigitalMeterStep } from './preferences/DigitalMeterStep'
import { ExtraCommentStep } from './preferences/ExtraCommentStep'
import { InstallationMethodStep } from './preferences/InstallationMethodStep'
import { PrivateTerrainStep } from './preferences/PrivateTerrainStep'
import { ProductSelectionStep } from './preferences/ProductSelectionStep'
import { RejectedStep } from './preferences/RejectedStep'
import { UploadDesktopStep } from './preferences/UploadDesktopStep'
import { WallMaterialStep } from './preferences/WallMaterialStep'
import { ChargerLocationImageStep } from './upload/ChargerLocationImageStep'
import { ContinueInTheAppStep } from './upload/ContinueInTheAppStep'
import { ElectricityBoxImageStep } from './upload/ElectricityBoxImageStep'
import { ElectricityMeterImageStep } from './upload/ElectricityMeterImageStep'
import { ExtraImagesStep } from './upload/ExtraImagesStep'
import { FuseBoxImageStep } from './upload/FuseBoxImageStep'

import type { CurrentStep } from '../../../components/navigation/Wizard'
import type { ReactNode } from 'react'

const onboardingStepComponents: Record<OnboardingSteps, ReactNode> = {
  [OnboardingSteps.Welcome]: null,
  [OnboardingSteps.Intercom]: null,
  [OnboardingSteps.CloseExplanation]: null,
  [OnboardingSteps.VerifyEmail]: <VerifyEmailStep />,
  [OnboardingSteps.PersonalData]: <PersonalDataStep />,
  [OnboardingSteps.BankDetails]: <BankDetailsStep />,
  [OnboardingSteps.ChargerLocation]: <ChargerLocationStep />,
  [OnboardingSteps.ChargerLocationInput]: <ChargerLocationInputStep />,
  [OnboardingSteps.PrivateTerrain]: <PrivateTerrainStep />,
  [OnboardingSteps.Rejected]: <RejectedStep />,
  [OnboardingSteps.InstallationMethod]: <InstallationMethodStep />,
  [OnboardingSteps.WallMaterial]: <WallMaterialStep />,
  [OnboardingSteps.ProductSelection]: <ProductSelectionStep />,
  [OnboardingSteps.DigitalMeter]: <DigitalMeterStep />,
  [OnboardingSteps.DigitalMeterEnvironment]: <DigitalMeterEnvironmentStep />,
  [OnboardingSteps.ConnectionType]: <ConnectionTypeStep />,
  [OnboardingSteps.CableDistance]: <CableDistanceStep />,
  [OnboardingSteps.CableDigWork]: <CableDigWorksStep />,
  [OnboardingSteps.UploadDesktop]: <UploadDesktopStep />,
  [OnboardingSteps.CableRoute]: <CableRouteStep />,
  [OnboardingSteps.ChargerDisassemble]: <ChargerDisassembleStep />,
  [OnboardingSteps.ExtraComment]: <ExtraCommentStep />,
  [OnboardingSteps.Overview]: <OnboardingOverviewStep />,
  // Mobile upload
  [OnboardingSteps.ElectricityBoxImage]: <ElectricityBoxImageStep />,
  [OnboardingSteps.FuseBoxImage]: <FuseBoxImageStep />,
  [OnboardingSteps.ElectricityMeterImage]: <ElectricityMeterImageStep />,
  [OnboardingSteps.ChargerLocationImage]: <ChargerLocationImageStep />,
  [OnboardingSteps.ExtraImages]: <ExtraImagesStep />,
  [OnboardingSteps.ContinueInTheApp]: <ContinueInTheAppStep />,
}

type RenderOnboardingStepProps = {
  currentStep: OnboardingSteps
  currentStepState: CurrentStep
}

export const RenderOnboardingStep = ({
  currentStep,
  currentStepState,
}: RenderOnboardingStepProps) => {
  // -- Hooks --
  useStatusCheck(currentStep)
  const { handleNext, values, simplifiedFlow } = useOnboarding()

  if (
    [
      OnboardingSteps.Welcome,
      OnboardingSteps.Intercom,
      OnboardingSteps.CloseExplanation,
    ].includes(currentStep)
  ) {
    return (
      <InviteScreenSteps
        currentStep={currentStepState}
        handleNext={() => handleNext(values)}
        simplifiedFlow={simplifiedFlow}
      />
    )
  }

  return (
    <StOnboardingContentContainer>
      {onboardingStepComponents[currentStep]}
    </StOnboardingContentContainer>
  )
}

export const StOnboardingContentContainer = styled.div`
  flex: 1;

  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  margin: 0 auto;

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media ${breakpoints.desktop} {
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space7};
  }
`
