import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'

import { ChargeLocationDetail } from './ChargeLocationDetail'

import type {
  ChargingLocation,
  OptimileEvsesChargingPointResponse,
} from 'types'

type ChargeLocationDetailProps = {
  detailInfo: ChargingLocation
  onClose: () => void
}

export const AuthenticatedChargeLocationDetail = ({
  detailInfo,
  onClose,
}: ChargeLocationDetailProps) => {
  // -- Data --
  const [{ data, loading }] =
    useAuthMemberAxios<OptimileEvsesChargingPointResponse>({
      url: replaceParametersInPath(Urls.chargingLocationDetail, {
        id: detailInfo.id,
      }),
    })

  return (
    <ChargeLocationDetail
      detailInfo={detailInfo}
      data={data}
      loading={loading}
      onClose={onClose}
    />
  )
}
