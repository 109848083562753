import { Capacitor } from '@capacitor/core'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonSecondary } from '../../../components/button/ButtonSecondary'
import { BodySmallMedium } from '../../../components/typography'
import { useGeoLocation } from '../../../location/hooks/useGeoLocation'

import type { MapRef } from 'react-map-gl'

const shouldFlyToAnimate = !Capacitor.isNativePlatform()

type MapActionsProps = {
  map: MapRef | null
}

export const MapActions = ({ map }: MapActionsProps) => {
  // -- Hooks --
  const { location, locationPermissionGranted } = useGeoLocation()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { t } = useTranslation()

  // -- Handlers --
  const handleZoomIn = () => {
    if (map) {
      map.flyTo({
        zoom: map.getZoom() + 1,
        animate: shouldFlyToAnimate,
      })
    }
  }

  const handleZoomOut = () => {
    if (map) {
      map.flyTo({
        zoom: map.getZoom() - 1,
        animate: shouldFlyToAnimate,
      })
    }
  }

  const handleCurrentLocation = () => {
    if (map) {
      map.flyTo({
        center: [location.longitude, location.latitude],
        zoom: 15,
        speed: 2,
        animate: shouldFlyToAnimate,
      })
    }
  }

  // - Render --
  return (
    <StContainer>
      <StActionContainer>
        {isDesktop && (
          <>
            <ButtonSecondary
              size="sm"
              onClick={handleZoomOut}
              icon={['fass', 'minus']}
            />
            <ButtonSecondary
              size="sm"
              onClick={handleZoomIn}
              icon={['fass', 'plus']}
            />
          </>
        )}

        {locationPermissionGranted && (
          <ButtonSecondary
            size="sm"
            icon={['fass', 'location-crosshairs']}
            iconAlignment="left"
            onClick={handleCurrentLocation}
          >
            <BodySmallMedium>{t('map.actions.my-location')}</BodySmallMedium>
          </ButtonSecondary>
        )}
      </StActionContainer>
      <StActionContainer>
        {
          // Possible future actions for the right side of the map
        }
      </StActionContainer>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  position: absolute;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space9};

  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  left: 50%;
  transform: translateX(-50%);

  background-color: transparent;
`

const StActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  align-items: flex-end;

  white-space: nowrap;
`
