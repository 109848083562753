import { css, styled } from 'styled-components'

import { onHover } from '../../core/lib/styleHelpers'

import { Button } from './Button'

export const ButtonPrimary = styled(Button)`
  background-color: ${({ theme }) =>
    theme.components.buttons.primary['default-bg']};
  color: ${({ theme }) => theme.components.buttons.primary['default-text']};

  ${onHover(css`
    background-color: ${({ theme }) =>
      theme.components.buttons.primary['hover-bg']};
  `)}

  &:disabled {
    background-color: ${({ theme }) =>
      theme.components.buttons.primary['disabled-bg']};
    color: ${({ theme }) => theme.components.buttons.primary['disabled-text']};
  }
`
