import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { BodySmallRegular, H4 } from '../../../../components/typography'
import { useHemsFlow } from '../hooks/useHemsFlow'

type HemsInfoStepProps = {
  title?: string
  description?: string
  visual: string
  nextString?: string
}

export const HemsInfoStep = ({
  title,
  description,
  visual,
  nextString,
}: HemsInfoStepProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const { flowNavigation } = useHemsFlow()

  // -- Render --
  return (
    <div>
      <StImage src={visual} alt={title} />
      {(title || description) && (
        <StTextContainer>
          {title && <StTitle>{title}</StTitle>}
          {description && <BodySmallRegular>{description}</BodySmallRegular>}
        </StTextContainer>
      )}

      <FloatingButtonContainer>
        <ButtonPrimary onClick={() => flowNavigation.next()}>
          {nextString ?? t('employee.hems.onboarding.next')}
        </ButtonPrimary>
      </FloatingButtonContainer>
    </div>
  )
}

const StTextContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  /* Compensate for floating button */
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space31};

  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space7} 0;
  }
`

const StTitle = styled(H4)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StImage = styled.img`
  width: 100%;
  height: 300px;

  object-fit: cover;
`
