import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { FloatingContainerButton } from '../../onboarding/components/FloatingContainerButton'
import { CloseExplanationSvg } from '../assets/CloseExplanationSvg'
import { IntercomExplanationSvg } from '../assets/IntercomExplanationSvg'

import { InviteWelcomeContent } from './invite-content/InviteWelcomeContent'
import { InviteExplanationCard } from './InviteExplanationCard'

import type { CurrentStep } from '../../../components/navigation/Wizard'

type InviteScreenStepsProps = {
  currentStep: CurrentStep
  handleNext: () => void
  simplifiedFlow: boolean
}

export const InviteScreenSteps = ({
  currentStep,
  handleNext,
  simplifiedFlow,
}: InviteScreenStepsProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Vars --
  const renderInviteWelcome = currentStep.subStep?.step === 1
  const renderIntercomExplanation = currentStep.subStep?.step === 2
  const renderCloseExplanation =
    !simplifiedFlow &&
    (currentStep.subStep?.step === 3 ||
      (currentStep.subStep?.step === 2 && isDesktop))

  // -- Render --
  return (
    <StContentWrapper $withPadding={!renderInviteWelcome}>
      {/* Step 1 */}
      {renderInviteWelcome && (
        <InviteWelcomeContent
          handleNext={handleNext}
          simplifiedFlow={simplifiedFlow}
        />
      )}

      {/* Step 2 */}
      {renderIntercomExplanation && (
        <InviteExplanationCard
          title={t('introduction.intercom-card.title')}
          content={t('introduction.intercom-card.content')}
          image={<IntercomExplanationSvg />}
        />
      )}

      {/* Step 3 */}
      {renderCloseExplanation && (
        <InviteExplanationCard
          title={t('introduction.close-card.title')}
          content={t('introduction.close-card.content')}
          image={<CloseExplanationSvg />}
        />
      )}

      {currentStep.subStep?.step === 1 && isDesktop ? null : (
        <FloatingContainerButton
          title={t('onboarding.account.submit')}
          onClick={handleNext}
        />
      )}
    </StContentWrapper>
  )
}

const StContentWrapper = styled.div<{ $withPadding: boolean }>`
  flex: 1;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

  @media ${breakpoints.tablet} {
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space18};

    width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
    max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

    padding-bottom: ${({ theme, $withPadding }) =>
      $withPadding
        ? `calc(${theme.UI.SpacingPx.Space6} + var(--sticky-button-container-height))`
        : 0};
  }
`
