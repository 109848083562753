import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import {
  EmployeeRoutes,
  UnauthenticatedRoutes,
} from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { useAuthentication } from '../../../authentication/hooks/useAuthentication'
import { PageHeader } from '../../../components/general/PageHeader'
import { ContentContainer } from '../../../components/page-layout'
import { useUser } from '../../../user/hooks/useUser'
import { ProfileMenuItem } from '../components/ProfileMenuItem'

export const EmployeeProfileScreen = () => {
  // -- Hooks --
  const { logout } = useAuthentication()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useUser()

  // -- Render --
  return (
    <ContentContainer>
      <StHeaderContainer>
        <PageHeader>{t('employee.profile.title')}</PageHeader>
      </StHeaderContainer>

      <StFlexContainer>
        <ProfileMenuItem
          icon={['fasr', 'user']}
          title={t('employee.profile.personal-details.title')}
          onClick={() => navigate(EmployeeRoutes.PersonalDetails)}
        />
        <ProfileMenuItem
          icon={['fasr', 'euro-sign']}
          title={t('employee.navigation.refunds')}
          onClick={() => navigate(EmployeeRoutes.Refunds)}
        />
        {user.addresses.some((address) => address.devices?.length > 0) && (
          <ProfileMenuItem
            icon={['fasr', 'charging-station']}
            title={t('employee.profile.charger.title')}
            onClick={() => navigate(EmployeeRoutes.ChargingStation)}
          />
        )}
        {user.members.some((member) => member.mspCards?.length > 0) && (
          <ProfileMenuItem
            icon={['fasr', 'credit-card-blank']}
            title={t('employee.profile.charge-card.title')}
            onClick={() => navigate(EmployeeRoutes.ChargeCard)}
          />
        )}
        <ProfileMenuItem
          icon={['fasr', 'cog']}
          title={t('employee.profile.settings.title')}
          onClick={() => navigate(EmployeeRoutes.Settings)}
        />
        <ProfileMenuItem
          icon={['fasr', 'lock']}
          title={t('employee.profile.privacy.title')}
          onClick={() => navigate(EmployeeRoutes.Privacy)}
        />
        <ProfileMenuItem
          icon={['fasr', 'arrow-right-from-bracket']}
          title={t('employee.profile.logout')}
          onClick={logout}
        />
        {/* Don't show this option in production */}
        {(import.meta.env.VITE_ENV === 'development' ||
          import.meta.env.VITE_ENV === 'local' ||
          import.meta.env.VITE_ENV === 'staging') && (
          <ProfileMenuItem
            icon={['fasr', 'bugs']}
            title="Debug screen"
            onClick={() => navigate(UnauthenticatedRoutes.Debug)}
          />
        )}
      </StFlexContainer>
    </ContentContainer>
  )
}

const StHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${breakpoints.desktop} {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`

const StFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`
