import * as RadixMenubar from '@radix-ui/react-menubar'
import { styled } from 'styled-components'

import { ButtonTertiary } from '../button/ButtonTertiary'
import { BodyMediumRegularCss } from '../typography'

export type MenubarItems = {
  name: string
  onClick: () => void
}[]

type MenubarProps = {
  title: string
  options: MenubarItems
  size?: 'sm' | 'md' | 'lg'
}

export const Menubar = ({ options, title, size = 'lg' }: MenubarProps) => {
  return (
    <RadixMenubar.Root>
      <RadixMenubar.Menu>
        <RadixMenubar.Trigger asChild>
          <ButtonTertiary size={size} icon={['fasr', 'chevron-down']}>
            {title}
          </ButtonTertiary>
        </RadixMenubar.Trigger>

        <RadixMenubar.Portal>
          <StContent align="end">
            {options.map((option) => (
              <StItem key={option.name} onSelect={option.onClick}>
                {option.name}
              </StItem>
            ))}
          </StContent>
        </RadixMenubar.Portal>
      </RadixMenubar.Menu>
    </RadixMenubar.Root>
  )
}

const StContent = styled(RadixMenubar.Content)`
  background-color: ${({ theme }) => theme.theme.colors.white};

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};

  ${BodyMediumRegularCss}

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space2};

  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StItem = styled(RadixMenubar.Item)`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space2};

  border-radius: 2px;

  &:hover {
    background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
    outline: none;
  }
`
