import { differenceInDays } from 'date-fns'

import { QUOTE_REMINDER_MAX_DAYS } from '../../core/utils/status'
import { HcpStatusType } from '../../employer/employees/components/EmployeesDataTable'

import type { HcpStatus } from 'database'

/**
 * Determines if the status of a quote is considered "urgent".
 *
 * A quote is urgent if:
 * 1. No one has approved the quote.
 * 2. The employer has not approved the quote.
 * 3. The employer has approved but the employee has not approved after the maximum reminder period.
 *
 * @param hcpStatus - The status object of the home charger.
 * @returns - Returns true if the quote status is considered urgent, otherwise false.
 */
export function isUrgent(hcpStatus: HcpStatus): boolean {
  const { status, updatedAt } = hcpStatus

  const noneHaveApproved = status === HcpStatusType.QuotationPendingApproval
  const employerHasNotApproved =
    status === HcpStatusType.QuotationApprovedByEmployee
  const employerHasApproved =
    status === HcpStatusType.QuotationApprovedByEmployer
  const employeeIsOverdue =
    differenceInDays(new Date(), new Date(updatedAt)) > QUOTE_REMINDER_MAX_DAYS

  return (
    noneHaveApproved ||
    employerHasNotApproved ||
    (employerHasApproved && employeeIsOverdue)
  )
}
