import * as RadixTabs from '@radix-ui/react-tabs'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { BodyMediumMediumCss, BodyMediumSemiBoldCss } from '../typography'

import type { ReactNode } from 'react'

type TabsProps = {
  defaultValue: string
  items: {
    content: ReactNode
    value: string
    title: string
  }[]
  setValue?: (value: string) => void
  value?: string
  stretch?: boolean
  hideContent?: boolean
  className?: string
}

export const Tabs = ({
  defaultValue,
  items,
  setValue,
  value,
  stretch,
  hideContent,
  className,
}: TabsProps) => {
  // -- Hooks --
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- Render --
  return (
    <StTabsContainer
      defaultValue={defaultValue}
      $isDesktop={isDesktop}
      $hideContent={hideContent}
      value={value}
      onValueChange={setValue}
      className={className}
    >
      <StTabsList $stretch={stretch}>
        {items.map((item) => (
          <StTabsTrigger key={item.value} value={item.value} $stretch={stretch}>
            {item.title}
          </StTabsTrigger>
        ))}
      </StTabsList>

      {items.map((item) => (
        <RadixTabs.Content key={item.value} value={item.value}>
          {!hideContent && item.content}
        </RadixTabs.Content>
      ))}
    </StTabsContainer>
  )
}

const StTabsContainer = styled(RadixTabs.Root)<{
  $isDesktop?: boolean
  $hideContent?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme, $isDesktop, $hideContent }) =>
    $isDesktop || $hideContent ? 0 : theme.UI.SpacingPx.Space5};

  margin-top: ${({ theme, $isDesktop }) =>
    $isDesktop ? theme.UI.SpacingPx.Space6 : 0};
`

const StTabsList = styled(RadixTabs.List)<{ $stretch?: boolean }>`
  display: flex;
  flex-direction: row;

  width: ${({ $stretch }) => ($stretch ? '100%' : 'auto')};

  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space1};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  @media ${breakpoints.desktop} {
    align-self: flex-start;
  }
`

const StTabsTrigger = styled(RadixTabs.Trigger)<{ $stretch?: boolean }>`
  ${BodyMediumMediumCss}

  filter: opacity(0.5);

  color: ${({ theme }) => theme.theme.text.body['gray-dark']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space2} 14px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  width: 50%;

  flex-grow: ${({ $stretch }) => ($stretch ? 1 : 0)};
  flex-basis: ${({ $stretch }) => ($stretch ? 0 : 'auto')};

  &[data-state='active'] {
    ${BodyMediumSemiBoldCss}

    filter: opacity(1);

    color: ${({ theme }) => theme.theme.text.body.black};
    background-color: white;
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  }

  @media ${breakpoints.desktop} {
    width: initial;
  }
`
