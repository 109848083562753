import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css, styled } from 'styled-components'

import { onHover } from '../../core/lib/styleHelpers'
import { BodyMediumMediumCss, BodySmallMediumCss } from '../typography'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ButtonHTMLAttributes, PropsWithChildren } from 'react'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren & {
    icon?: IconProp
    size?: 'sm' | 'md' | 'lg'
  }

export const ButtonQuinary = ({
  children,
  icon,
  size = 'lg',
  ...props
}: ButtonProps) => {
  return (
    <StButton {...props} $size={size}>
      {children}
      {icon ? <StFontAwesomeIcon icon={icon} /> : null}
    </StButton>
  )
}

const StButton = styled.button<{ $size: 'sm' | 'md' | 'lg' }>`
  ${({ $size }) => ($size === 'lg' ? BodyMediumMediumCss : BodySmallMediumCss)}
  height: fit-content;

  border: none;
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => theme.components.buttons.quaternary['default-txt']};

  padding: 0;

  ${onHover(css`
    color: ${({ theme }) =>
      theme.components.buttons.quaternary['hover-stroke']};
  `)}
`

const StFontAwesomeIcon = styled(FontAwesomeIcon)`
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
