import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { styled, useTheme } from 'styled-components'
import { match } from 'ts-pattern'

import {
  BodyExtraSmallRegular,
  BodySmallRegular,
  BodySmallSemiBold,
} from '../../../components/typography'
import { formatDecimals } from '../../../core/utils/number'
import { UserSettingsMode } from '../../hems/types/enums'

import {
  StCardRow,
  StChargModeContainer,
  StChargeModeSymbol,
  StChip,
  StDuration,
  StGradientCard,
  StTime,
  formatTime,
  useChargeModes,
  useDistanceToNowFormatter,
} from './ChargingSessionCard'

export function PausedByChargerChargingSessionCard({
  customerName,
  mode,
  kWh,
  startTime,
}: PausedByChargerChargingSessionCardProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'employee.home.charging-session.card',
  })
  const { theme } = useTheme()

  const chargeModes = useChargeModes()
  const chargeMode = chargeModes.find(
    (chargeMode) => chargeMode.value === mode
  )!

  const formatDistanceToNow = useDistanceToNowFormatter()

  const reason = match(mode)
    .with(UserSettingsMode.cap, () =>
      t('paused-by-charger.mode.standard.chip.description')
    )
    .with(UserSettingsMode.solar, () =>
      t('paused-by-charger.mode.sun.chip.description')
    )
    .with(UserSettingsMode.autopilot, () =>
      t('paused-by-charger.mode.autopilot.chip.description')
    )
    .otherwise(() => t('paused.chip.description'))

  return (
    <StGradientCard>
      <StCardRow>
        <BodySmallSemiBold>{customerName}</BodySmallSemiBold>
      </StCardRow>
      <StCardRow>
        <StTime>
          <BodySmallRegular>
            {t('time', { time: formatTime(startTime) })}
          </BodySmallRegular>
          <StDuration>
            <FontAwesomeIcon icon={['fasr', 'clock']} fontSize={12} />
            <BodySmallRegular>
              {formatDistanceToNow(startTime)}
            </BodySmallRegular>
          </StDuration>
        </StTime>
        <BodySmallRegular>
          {t('kWh', { kWh: formatDecimals(kWh) })}
        </BodySmallRegular>
      </StCardRow>
      <StCardRow>
        <StChip>
          <FontAwesomeIcon
            color={theme.text.body['gray-mid']}
            icon={['fass', 'bolt-slash']}
            fontSize={12}
          />{' '}
          <StGreyBodySmallRegular>{t('paused.chip')}</StGreyBodySmallRegular>
        </StChip>
        <StChargModeContainer>
          <StChargeModeSymbol $color={chargeMode.color}>
            <FontAwesomeIcon fontSize={14} icon={chargeMode.icon} />
          </StChargeModeSymbol>
          <BodySmallRegular>{chargeMode.label}</BodySmallRegular>
        </StChargModeContainer>
      </StCardRow>
      <StCardRow>
        <StBodyExtraSmallRegular>{reason}</StBodyExtraSmallRegular>
      </StCardRow>
    </StGradientCard>
  )
}
type PausedByChargerChargingSessionCardProps = {
  customerName: string
  kWh: number
  startTime: Date
  mode: UserSettingsMode
}

const StGreyBodySmallRegular = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-dark']};
`

const StBodyExtraSmallRegular = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
