import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import {
  BodyExtraSmallRegular,
  BodySmallRegular,
} from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { capitalizeFirstLetter } from '../../../core/lib/capitalizeFirstLetter'
import { getDayName } from '../../../core/lib/getDayName'

import type { OpeningTimes } from 'types'

type OpeningHoursProps = {
  openingTimes: OpeningTimes
}

type ContentText = {
  weekDays: number[]
  openingTimes: {
    periodBegin: string
    periodEnd: string
  }[]
}

export const OpeningHours = ({ openingTimes }: OpeningHoursProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const { locale } = useGetDateFnsLocale()

  // -- Vars --
  const content = useMemo(() => {
    if (!openingTimes) {
      return (
        <BodyExtraSmallRegular>
          {t('map.detail.always-open')}
        </BodyExtraSmallRegular>
      )
    }

    if (openingTimes.length === 0) {
      return (
        <BodySmallRegular>{t('map.detail.always-closed')}</BodySmallRegular>
      )
    }

    const groupedOpeningTimes: ContentText[] = openingTimes
      .reduce<ContentText[]>((accumulator, item) => {
        const index = accumulator.findIndex(
          (group) => group.weekDays[0] === item.weekDay
        )

        if (index === -1) {
          accumulator.push({
            weekDays: [item.weekDay],
            openingTimes: [
              { periodBegin: item.periodBegin, periodEnd: item.periodEnd },
            ],
          })
        } else {
          accumulator[index].openingTimes.push({
            periodBegin: item.periodBegin,
            periodEnd: item.periodEnd,
          })
        }

        return accumulator
      }, [])
      .reduce<ContentText[]>((accumulator, item) => {
        if (
          accumulator.at(-1)?.openingTimes.toString() ===
          item.openingTimes.toString()
        ) {
          accumulator.at(-1)?.weekDays.push(item.weekDays[0])
        } else {
          accumulator.push({
            weekDays: item.weekDays,
            openingTimes: item.openingTimes,
          })
        }

        return accumulator
      }, [])

    return groupedOpeningTimes.flatMap((item) => {
      if (item.weekDays.length === 7) {
        return (
          <StFlexContainer key="every-day">
            <BodyExtraSmallRegular>
              {t('map.detail.every-day')}
            </BodyExtraSmallRegular>
            <BodyExtraSmallRegular>
              {item.openingTimes
                .map((time) => `${time.periodBegin} - ${time.periodEnd}`)
                .join(', ')}
            </BodyExtraSmallRegular>
          </StFlexContainer>
        )
      }

      if (item.weekDays.length < 2) {
        return item.weekDays.map((day) => {
          return (
            <StFlexContainer key={day}>
              <BodyExtraSmallRegular>
                {capitalizeFirstLetter(
                  getDayName(day, { locale, format: 'eeee' })
                )}
              </BodyExtraSmallRegular>
              <BodyExtraSmallRegular>
                {item.openingTimes
                  .map((time) => `${time.periodBegin} - ${time.periodEnd}`)
                  .join(', ')}
              </BodyExtraSmallRegular>
            </StFlexContainer>
          )
        })
      }

      return (
        <StFlexContainer key={item.weekDays.toString()}>
          <BodyExtraSmallRegular>
            {capitalizeFirstLetter(getDayName(item.weekDays?.[0], { locale }))}{' '}
            -{' '}
            {capitalizeFirstLetter(
              getDayName(item.weekDays?.[item.weekDays.length - 1], { locale })
            )}
          </BodyExtraSmallRegular>
          <BodyExtraSmallRegular>
            {item.openingTimes
              .map((time) => `${time.periodBegin} - ${time.periodEnd}`)
              .join(', ')}
          </BodyExtraSmallRegular>
        </StFlexContainer>
      )
    })
  }, [openingTimes])

  // - Render --
  return <StContainer>{content}</StContainer>
}

const StContainer = styled.div`
  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

const StFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
