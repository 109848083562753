export enum ElectricityContract {
  fixed = 'fixed',
  variable = 'variable',
  dynamic = 'dynamic',
}

export enum UserSettingsMode {
  standard = 'standard',
  solar = 'solar',
  cap = 'cap',
  autopilot = 'autopilot',
}
