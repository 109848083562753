import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { EmployerRoutes } from '../../../../routing/routes'
import { ButtonTertiary } from '../../../components/button/ButtonTertiary'
import { H4 } from '../../../components/typography'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { formatIntervalInDuration } from '../../../translations/utils/date'
import { ChargingSessionTypeLabel } from '../../usage/components/ChargingSessionTypeLabel'
import { ChargingSessionType } from '../../usage/types/types'
import { formatPowerConsumption } from '../../utils/statistics'
import travelImage from '../assets/travel.svg'

import type { Column } from '../../../dataTable/components/DataTable'
import type { ChargingSession } from 'database'

export const RecentUsageDataTable = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const navigate = useNavigate()

  // -- Memo's
  const columns = useMemo<Column<ChargingSession>[]>(
    () => [
      {
        key: 'start',
        label: t('employer.chargingSessions.date'),
        type: ColumnType.DATE,
        sortable: false,
      },
      {
        key: 'mspCard.member.user.fullName',
        label: t('employer.chargingSessions.employee'),
        type: ColumnType.STRING,
        sortable: false,
      },
      {
        key: 'kwh',
        label: t('employer.chargingSessions.charged'),
        type: ColumnType.STRING,
        sortable: false,
        transformData: (data: ChargingSession) => {
          return formatPowerConsumption(data.kwh ?? 0)
        },
      },
      {
        key: 'location',
        label: t('employer.chargingSessions.location'),
        type: ColumnType.STRING,
        transformData: (data: ChargingSession) => {
          return <ChargingSessionTypeLabel session={data} />
        },
        sortable: false,
      },
      {
        key: 'duration',
        label: t('employer.chargingSessions.duration'),
        type: ColumnType.STRING,
        transformData: (data: ChargingSession) => {
          if (!data.start || !data.end) {
            return '–'
          }

          const result =
            formatIntervalInDuration(
              new Date(data.start),
              new Date(data.end),
              t
            ) ?? '-'

          return result === '' ? null : result
        },
        sortable: false,
      },
      {
        key: 'hcpPrice',
        label: t('employer.chargingSessions.cost-exclude-vat'),
        prepend: '€',
        type: ColumnType.NUMBER,
        decimal: 2,
        sortable: false,
        transformData: (chargingSession: ChargingSession) => {
          return chargingSession.type === ChargingSessionType.HOME
            ? chargingSession.hcpPrice
            : chargingSession.mspPrice
        },
      },
      {
        key: 'vat',
        type: ColumnType.NUMBER,
        label: t('employer.chargingSessions.vat'),
        sortable: true,
        transformData: (chargingSession: ChargingSession) => {
          return chargingSession.type === ChargingSessionType.HOME
            ? t('employer.chargingSessions.vat-not-applicable')
            : `${chargingSession.vat}%`
        },
      },
    ],
    [t]
  )

  // -- Render component --
  return (
    <div>
      <StHeader>
        <H4>{t('employer.home.recent-usage')}</H4>
        <ButtonTertiary
          onClick={() => {
            navigate(EmployerRoutes.Usage)
          }}
          compact
          icon={['fasr', 'arrow-right']}
          size="sm"
        >
          {t('employer.home.all-usage')}
        </ButtonTertiary>
      </StHeader>
      <DataTable<ChargingSession>
        columns={columns}
        emptyFiltersTitle={t(
          'employer.chargingSessions.table.empty-filters-title'
        )}
        emptyTitle={t('employer.chargingSessions.table.empty-title')}
        emptyFiltersDescription={t(
          'employer.chargingSessions.table.empty-filters-description'
        )}
        emptyDescription={t(
          'employer.chargingSessions.table.empty-description'
        )}
        emptyImage={travelImage}
      />
    </div>
  )
}

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
