// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-duplicates */

import { enUS, fr, nl } from 'date-fns/locale'
import { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'

export const useGetDateFnsLocale = () => {
  const { i18n } = useTranslation()

  switch (i18n.language) {
    case 'en': {
      return { locale: enUS }
    }
    case 'fr': {
      return { locale: fr }
    }
    case 'nl': {
      return { locale: nl }
    }
    default: {
      return { locale: enUS }
    }
  }
}

export const registerDatePickerLocales = () => {
  registerLocale('en', enUS)
  registerLocale('fr', fr)
  registerLocale('nl', nl)
}
