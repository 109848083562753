export const IntercomLogo = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5315 17.9655L14.3781 17.9076H14.2142H3.33235C2.5626 17.9076 1.9 17.261 1.9 16.4152V2.39173C1.9 1.5468 2.56244 0.9 3.33235 0.9H17.6663C18.4365 0.9 19.0993 1.54715 19.0993 2.39173V14.4697V14.4698L19.0999 19.695C18.8855 19.6136 18.6493 19.5239 18.3968 19.4281C17.2735 19.0018 15.8263 18.4534 14.5315 17.9655Z"
        stroke="black"
        strokeWidth="1.8"
      />
      <path
        d="M5.02072 12.082L5.02062 12.0821C4.78886 12.3591 4.82087 12.7739 5.0907 13.0119L5.0907 13.0119L5.09156 13.0127C5.18442 13.0933 7.06419 14.7056 10.4976 14.7056C13.9324 14.7056 15.8129 13.0936 15.9055 13.012L15.9056 13.0119C16.1754 12.7739 16.2074 12.3591 15.9757 12.0821C15.7429 11.8038 15.331 11.7712 15.0591 12.0103L15.1581 12.1229L15.0598 12.0097L15.0598 12.0097C15.0586 12.0107 13.4691 13.3835 10.4976 13.3835C7.59715 13.3835 5.98578 12.0507 5.93458 12.0078C5.66173 11.7705 5.25279 11.8052 5.02072 12.082Z"
        fill="black"
        stroke="black"
        strokeWidth="0.3"
      />
    </svg>
  )
}
