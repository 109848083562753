import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import {
  BodyMediumSemiBold,
  BodySmallRegular,
} from '../../../components/typography'

import { StCard } from './ChargingSessionCard'

export const UnpluggedChargingSessionCard = () => {
  const { t } = useTranslation()

  return (
    <StUnpluggedChargingSessionCard>
      <StIconContainer>
        <FontAwesomeIcon icon={['fasr', 'charging-station']} fontSize={20} />
      </StIconContainer>
      <StCardBody>
        <BodyMediumSemiBold>
          {t('employee.home.charging-session.card.unplugged.title')}
        </BodyMediumSemiBold>
        <StBodySmallRegular>
          {t('employee.home.charging-session.card.unplugged.description')}
        </StBodySmallRegular>
      </StCardBody>
    </StUnpluggedChargingSessionCard>
  )
}

const StUnpluggedChargingSessionCard = styled(StCard)`
  flex-direction: row;

  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-10']};
  background-color: ${({ theme }) => theme.theme.colors['nonary-10']};
`

const StIconContainer = styled.div`
  width: 56.65px;
  height: 56.65px;

  background-color: ${({ theme }) => theme.theme.colors.white};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space3};
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const StCardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const StBodySmallRegular = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
