import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { css, styled } from 'styled-components'
import { useDebounce } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { FilterTypes } from '../../../components/filters/Filters'
import { MultiSelect } from '../../../components/form/MultiSelect'
import { SearchInput } from '../../../components/form/SearchInput'
import { Select } from '../../../components/form/Select'
import { Toggle } from '../../../components/form/Toggle'

import type { Filter } from '../../../components/filters/Filters'

type FilterListProps = {
  filters?: Filter[]
  filterValues: Record<string, unknown>
  onFiltersChange: (filterKey: string, value: unknown) => void
  onClearFilters: () => void
  withSearch?: boolean
  loading?: boolean
  stickyPosition?: number
}

export const Filters = ({
  filters,
  filterValues,
  onFiltersChange,
  onClearFilters,
  withSearch,
  loading = false,
  stickyPosition,
}: FilterListProps) => {
  const { t } = useTranslation()

  const hasFilters = Object.keys(filterValues).some((key) => {
    // Only check for filters that are in the filter list
    // Additional filters can be set but shouldn't be influenced by the clear filter button
    if (!filters?.some((filter) => filter.key === key)) {
      return false
    }

    const value = filterValues[key]

    let hasNullValues = false
    let isEmpty = !value

    if (Array.isArray(value)) {
      hasNullValues = value.includes('NULL')
      isEmpty = value.length === 0
    }

    return !isEmpty && !hasNullValues
  })

  const [searchValue, setSearchValue] = useState<string | undefined>(
    filterValues.search as string
  )
  const debouncedSearchValue = useDebounce<string | undefined>(searchValue, 300)

  useEffect(() => {
    if (debouncedSearchValue !== filterValues.search) {
      onFiltersChange('search', searchValue)
    }
  }, [debouncedSearchValue, filterValues.search, onFiltersChange, searchValue])

  // -- Render --
  if (!filters || filters.length === 0) {
    return null
  }

  if (loading) {
    return (
      <StContainer $spread={withSearch}>
        {withSearch && (
          <div key="loader_search">
            <ContentLoader
              speed={2}
              height={52}
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              style={{ width: '100%' }}
            >
              <rect x="0" y="0" rx="4" ry="4" width="100%" height="52" />
            </ContentLoader>
          </div>
        )}
        {filters &&
          filters?.length > 0 &&
          filters.map((filter) => (
            <ContentLoader
              key={`loader_${filter.key}`}
              speed={2}
              width={189}
              height={52}
              viewBox="0 0 189 52"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="4" ry="4" width="188" height="52" />
            </ContentLoader>
          ))}
      </StContainer>
    )
  }

  return (
    <StContainer $spread={withSearch} $stickyPosition={stickyPosition}>
      {withSearch && (
        <SearchInput
          key="search"
          name="search"
          value={searchValue ?? ''}
          onChange={(value: string) => setSearchValue(value)}
        />
      )}

      {hasFilters && withSearch ? (
        <ButtonPrimary
          onClick={onClearFilters}
          compact
          icon={['fasr', 'xmark']}
        >
          {t('filters.clear')}
        </ButtonPrimary>
      ) : null}

      {filters && filters?.length > 0
        ? filters.map((filter) => {
            if (filter.type === FilterTypes.Option && filter.options) {
              return (
                <Select
                  key={filter.key}
                  options={filter.options}
                  label={filter.label}
                  placeholder={filter.placeholder}
                  value={filterValues[filter.key] as string | undefined}
                  onChange={(value: unknown) => {
                    onFiltersChange(filter.key, value)
                  }}
                  disabled={filter.disabled}
                />
              )
            } else if (filter.type === FilterTypes.Multi && filter.options) {
              return (
                <MultiSelect
                  key={filter.key}
                  options={filter.options}
                  label={filter.label}
                  placeholder={filter.placeholder}
                  value={filterValues[filter.key] as string[]}
                  onChange={(value: unknown) => {
                    onFiltersChange(filter.key, value)
                  }}
                  disabled={filter.disabled}
                />
              )
            } else if (filter.type === FilterTypes.Toggle) {
              return (
                <Toggle
                  key={filter.key}
                  icon={['fasr', 'circle-exclamation']}
                  active={!!filterValues[filter.key]}
                  onToggle={(active) => {
                    onFiltersChange(filter.key, active)
                  }}
                />
              )
            } else {
              null
            }
          })
        : null}
      {hasFilters && !withSearch ? (
        <StButton onClick={onClearFilters} compact icon={['fasr', 'xmark']}>
          {t('filters.clear')}
        </StButton>
      ) : null}
    </StContainer>
  )
}

const StContainer = styled.div<{ $spread?: boolean; $stickyPosition?: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  background-color: white;

  ${({ $stickyPosition }) =>
    $stickyPosition &&
    css`
      position: sticky;
      top: ${$stickyPosition}px;
      padding-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
      // We need a z-index higher than the table header
      z-index: 10;
    `}

  > * {
    flex-shrink: 0;

    &:first-child {
      flex-basis: 0;
      flex-grow: ${({ $spread }) => ($spread ? 1 : 0)};
    }
  }

  @media ${breakpoints.desktop} {
    flex-wrap: nowrap;
  }
`

const StButton = styled(ButtonPrimary)`
  padding-top: 26px;
  padding-bottom: 26px;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
`
