import { Urls } from '../../../api/urls'
import { SortKeys } from '../../../dataTable/components/DataTableHeader'
import {
  DataTableProvider,
  PaginationType,
} from '../../../dataTable/providers/DataTableProvider'
import { EmployeesDataTable } from '../components/EmployeesDataTable'

export const EmployeesScreen = () => {
  return (
    <DataTableProvider
      paginationType={PaginationType.Infinite}
      url={Urls.fetchEmployees}
      defaultSort="fullName"
      defaultSortDirection={SortKeys.ASCENDING}
    >
      <EmployeesDataTable />
    </DataTableProvider>
  )
}
