import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonSecondary } from '../../../components/button/ButtonSecondary'
import { FilterTypes } from '../../../components/filters/Filters'
import { DateInput } from '../../../components/form/date-input/DateInput'
import { StatisticCard } from '../../../components/general/StatisticCard'
import { ContentContainer } from '../../../components/page-layout'
import { H4 } from '../../../components/typography'
import { downloadBlob } from '../../../core/lib/downloadBlob'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'
import { formatIntervalInDuration } from '../../../translations/utils/date'
import { formatPowerConsumption } from '../../utils/statistics'
import { ChargingSessionTypeLabel } from '../components/ChargingSessionTypeLabel'
import {
  ChargingSessionType,
  type ChargingSessionStatistics,
} from '../types/types'

import { UsageFractions } from './UsageFractions'

import type { Filter } from '../../../components/filters/Filters'
import type { DateRange } from '../../../components/form/date-input/DateInput'
import type { Column } from '../../../dataTable/components/DataTable'
import type { ChargingSession } from 'database'

export const UsageDataTable = () => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()

  const { statistics, filterValues, setFilterValues } =
    useDataTable<ChargingSessionStatistics>()

  // State
  const [exportLoading, setExportLoading] = useState(false)

  // -- Vars --
  const columns = useMemo<Column<ChargingSession>[]>(
    () => [
      {
        key: 'start',
        label: t('employer.chargingSessions.date'),
        type: ColumnType.DATE,
        sortable: true,
      },
      {
        key: 'mspCard.member.user.fullName',
        label: t('employer.chargingSessions.employee'),
        type: ColumnType.STRING,
        sortable: true,
      },
      {
        key: 'kwh',
        label: t('employer.chargingSessions.charged'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (data: ChargingSession) => {
          return formatPowerConsumption(data.kwh ?? 0)
        },
      },
      {
        key: 'location',
        label: t('employer.chargingSessions.location'),
        type: ColumnType.STRING,
        transformData: (data: ChargingSession) => {
          return <ChargingSessionTypeLabel session={data} />
        },
        sortable: true,
      },
      {
        key: 'duration',
        label: t('employer.chargingSessions.duration'),
        type: ColumnType.STRING,
        transformData: (data: ChargingSession) => {
          if (!data.start || !data.end) {
            return '–'
          }

          const result =
            formatIntervalInDuration(
              new Date(data.start),
              new Date(data.end),
              t
            ) ?? '-'

          return result === '' ? null : result
        },
        sortable: true,
      },
      {
        key: 'mspPrice',
        label: t('employer.chargingSessions.cost-exclude-vat'),
        prepend: '€',
        type: ColumnType.NUMBER,
        decimal: 2,
        sortable: true,
        transformData: (chargingSession: ChargingSession) => {
          return chargingSession.type === ChargingSessionType.HOME
            ? chargingSession.hcpPrice
            : chargingSession.mspPrice
        },
      },
      {
        key: 'vat',
        type: ColumnType.NUMBER,
        label: t('employer.chargingSessions.vat'),
        sortable: true,
        transformData: (chargingSession: ChargingSession) => {
          return chargingSession.type === ChargingSessionType.HOME
            ? t('employer.chargingSessions.vat-not-applicable')
            : `${chargingSession.vat}%`
        },
      },
    ],
    [t]
  )

  const filters = useMemo<Filter[]>(
    () => [
      {
        key: 'chargingSessionType',
        placeholder: t('employer.chargingSessions.location'),
        type: FilterTypes.Multi,
        options: Object.values(ChargingSessionType).map((type) => {
          return {
            key: type,
            label: t(`employer.chargingSessions.type.${type}`),
          }
        }),
      },
    ],
    [t]
  )

  // -- Data --
  const [, exportSessions] = useAuthMemberAxios(
    {
      url: Urls.employerChargingSessionsExport,
      method: 'POST',
      params: {
        filters: {
          ...filterValues,
        },
      },
      responseType: 'blob',
    },
    { manual: true }
  )

  const handleExportSessions = async () => {
    try {
      trackEvent(EventType.Click, 'export_overview')
      setExportLoading(true)
      const response = await exportSessions()

      // Save file
      downloadBlob(
        response.data,
        t('employer.chargingSessions.title').toLocaleLowerCase() +
          format(new Date(), '_dd_MM_yyyy-HH_mm'),
        '.csv'
      )
    } catch {
      toast.error(t('employer.chargingSessions.export.error'))
    }

    setExportLoading(false)
  }

  return (
    <ContentContainer>
      <StContainer>
        <StHeader>
          <StTitle>{t('employer.chargingSessions.title')}</StTitle>
          <StPageActions>
            <DateInput
              onChange={(value) => {
                setFilterValues((previousFilters) => ({
                  ...previousFilters,
                  dateFrom: (value as DateRange).start,
                  dateTo: (value as DateRange).end,
                }))
              }}
              shortCutType="month"
              range={true}
              value={{
                start: filterValues.dateFrom as Date | null,
                end: filterValues.dateTo as Date | null,
              }}
              size="sm"
            />
            <ButtonSecondary
              icon={['fass', 'arrow-down-to-line']}
              iconAlignment="left"
              loading={exportLoading}
              onClick={handleExportSessions}
              size="sm"
            >
              {t('employer.chargingSessions.export')}
            </ButtonSecondary>
          </StPageActions>
        </StHeader>
        {statistics && (
          <StStatistics>
            <StatisticCard
              title={t('employer.chargingSessions.stats.usage')}
              subTitle={t(
                'employer.chargingSessions.stats.for-selected-period'
              )}
              appendValue=" kWh"
              value={statistics.totalUsage ?? 0}
            />
            <StatisticCard
              title={t('employer.chargingSessions.stats.charging-behaviour')}
              subTitle={
                statistics.totalUsage
                  ? t('employer.chargingSessions.stats.for-selected-period')
                  : t(
                      'employer.chargingSessions.stats.no-data-for-selected-period'
                    )
              }
            >
              <UsageFractions statistics={statistics} />
            </StatisticCard>
            <StatisticCard
              title={t('employer.chargingSessions.stats.cost')}
              subTitle={t(
                'employer.chargingSessions.stats.for-selected-period'
              )}
              prependValue="€"
              value={statistics.totalCost ?? 0}
            />
          </StStatistics>
        )}
        <DataTable<ChargingSession>
          columns={columns}
          filters={filters}
          emptyFiltersTitle={t(
            'employer.chargingSessions.table.empty-filters-title'
          )}
          emptyTitle={t('employer.chargingSessions.table.empty-title')}
          emptyFiltersDescription={t(
            'employer.chargingSessions.table.empty-filters-description'
          )}
          emptyDescription={t(
            'employer.chargingSessions.table.empty-description'
          )}
        />
      </StContainer>
    </ContentContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: 70px;
`

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StTitle = styled(H4)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StStatistics = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  > * {
    flex: 1 0 0;
  }
`

const StPageActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
