import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { FilterTypes } from '../../../../components/filters/Filters'
import { H3 } from '../../../../components/typography'
import { Filters } from '../../components/Filters'
import { ProductList } from '../../components/products/ProductList'
import { ProductLoader } from '../../components/products/ProductsLoader'
import { useOnboarding } from '../../hooks/useOnboarding'

import { InstallationMethodKeys } from './InstallationMethodStep'

import type { Filter } from '../../../../components/filters/Filters'
import type { Product } from 'types'

export const ProductSelectionStep = () => {
  const {
    handleNext,
    values,
    setLoading,
    products: { data: productsData, loading: loadingProducts },
    items: { manufacturers },
  } = useOnboarding()
  const { t } = useTranslation()

  // -- Data --

  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.OnboardingData,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  const [filterValues, setFilterValues] = useState<Record<string, unknown>>({
    mountingOptions: values.installationMethodKey,
  })

  const filteredProducts = useMemo(() => {
    if (!productsData) {
      return []
    }

    return productsData.filter((product: Product) => {
      const filterKeys = Object.keys(filterValues)

      return filterKeys.every((key) => {
        const filterValue = filterValues[key]

        if (!filterValue) {
          return true
        }

        switch (key) {
          case 'mountingOptions': {
            return product.mountingOptions?.some(
              (option) => option.type === filterValue
            )
          }
          // Keeping this commented out for now, as this may be used in the future
          // case 'connectionMethods': {
          //   return product.connectionMethods?.some(
          //     (connectionMethod: ConnectionMethod) =>
          //       connectionMethod.id === filterValue
          //   )
          // }
          case 'manufacturer': {
            return product.manufacturer === filterValue
          }
          default: {
            return true
          }
        }
      })
    })
  }, [productsData, filterValues])

  const filters: Filter[] = [
    {
      key: 'mountingOptions',
      placeholder: t('onboarding.product-selection-overview.all-options'),
      type: FilterTypes.Option,
      options: [
        {
          key: 'NULL',
          label: t('onboarding.product-selection-overview.all-options'),
        },
        {
          key: 'Wall',
          label: t('onboarding.product-selection-overview.wall-mount'),
        },
        {
          key: 'Pole',
          label: t('onboarding.product-selection-overview.pole-mount'),
        },
      ],
    },
    {
      key: 'manufacturer',
      placeholder: t('onboarding.product-selection-overview.all-brands'),
      type: FilterTypes.Option,
      options: [
        {
          key: 'NULL',
          label: t('onboarding.product-selection-overview.all-brands'),
        },
        ...(manufacturers?.map((manufacturer: string) => ({
          key: manufacturer,
          label: manufacturer,
        })) ?? []),
      ],
    },
    // Keeping this commented out for now, as this may be used in the future
    // {
    //   key: 'connectionMethods',
    //   placeholder: t('onboarding.product-selection-overview.all-connections'),
    //   type: FilterTypes.Option,
    //   options: [
    //     {
    //       key: 'NULL',
    //       label: t('onboarding.product-selection-overview.all-connections'),
    //     },
    //     ...(connectionMethods?.map((connectionMethod: ConnectionMethod) => {
    //       return {
    //         key: connectionMethod.id,
    //         label: getConnectionMethodName(
    //           connectionMethod,
    //           user.language
    //         ).toLowerCase(),
    //       }
    //     }) ?? []),
    //   ],
    // },
  ]

  // -- Handlers --
  const handleSelect = async (
    productId: string,
    colorId: string,
    connectionMethodId: string,
    mountingOptionId: string,
    mountingOption: string
  ) => {
    try {
      setLoading(true)

      await execute({
        data: {
          installationMethodKey: mountingOption,
          product: productId,
          color: colorId,
          connectionMethod: connectionMethodId,
          mountingOption: mountingOptionId,
          installationMaterialKey:
            mountingOption === InstallationMethodKeys.Pole
              ? null
              : values.installationMaterialKey,
        },
      })

      handleNext(
        {
          ...values,
          installationMethodKey: mountingOption,
          product: productId,
          color: colorId,
          connectionMethod: connectionMethodId,
          mountingOption: mountingOptionId,
          installationMaterialKey:
            mountingOption === InstallationMethodKeys.Pole
              ? null
              : values.installationMaterialKey,
        },
        mountingOption === InstallationMethodKeys.Wall
      )
    } catch {
      setLoading(false)
      return console.error('Failed to save charger location')
    }
  }

  return loadingProducts ? (
    <ProductLoader />
  ) : (
    <StContainer>
      <StTitle>{t('onboarding.product-selection-overview.title')}</StTitle>
      <Filters
        filters={filters}
        filterValues={filterValues}
        onFiltersChange={(filterKey, value) =>
          setFilterValues(() => ({
            ...filterValues,
            [filterKey]: value,
          }))
        }
        onClearFilters={() => {
          setFilterValues({})
        }}
      />
      <ProductList products={filteredProducts} onSelect={handleSelect} />
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StTitle = styled(H3)`
  margin-bottom: 34px;
`
