import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { ButtonPrimary } from '../../../../components/button/ButtonPrimary'
import { ButtonTertiary } from '../../../../components/button/ButtonTertiary'
import { FloatingButtonContainer } from '../../../../components/button/FloatingButtonContainer'
import { BodySmallRegular, H4 } from '../../../../components/typography'
import dongle from '../assets/dongle-front.png'
import { useHemsFlow } from '../hooks/useHemsFlow'

export const ProvisioningSuccessStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { flowNavigation } = useHemsFlow()

  // -- Render --
  return (
    <>
      <StTextContainer>
        <H4>
          {t(`employee.hems.dongle-onboarding.utp-provisioning.SUCCESS.title`)}
        </H4>
        <BodySmallRegular>
          {t(
            `employee.hems.dongle-onboarding.utp-provisioning.SUCCESS.description`
          )}
        </BodySmallRegular>
      </StTextContainer>

      <StImage src={dongle} alt="dongle" />

      <StIconContainer>
        <FontAwesomeIcon icon={['fasr', 'check']} />
      </StIconContainer>

      <FloatingButtonContainer>
        <ButtonTertiary
          style={{ marginBottom: '12px' }}
          onClick={() => flowNavigation.close()}
        >
          {t(
            'employee.hems.dongle-onboarding.wifi-provisioning.SUCCESS.later-button'
          )}
        </ButtonTertiary>
        <ButtonPrimary onClick={() => flowNavigation.next()}>
          {t(
            'employee.hems.dongle-onboarding.wifi-provisioning.SUCCESS.next-button'
          )}
        </ButtonPrimary>
      </FloatingButtonContainer>
    </>
  )
}

const StTextContainer = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  h4 {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StImage = styled.img`
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: -1;

  width: 100%;
  max-height: 50%;

  object-fit: contain;
`

const StIconContainer = styled.div<{ $error?: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: ${({ theme, $error }) =>
    $error ? theme.components.input.error : theme.theme.colors['primary-1']};
  color: ${({ theme, $error }) =>
    $error ? theme.theme.colors.white : theme.theme.colors.black};
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space8};

  height: 70px;
  width: 70px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
`
