import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { EmployeeRoutes } from '../../../../routing/routes'
import { breakpoints } from '../../../../theme/layout/breakpoints'
import { DetailPageHeader } from '../../../components/general/DetailPageHeader'
import { ContentContainer } from '../../../components/page-layout'
import {
  BodyExtraSmallRegular,
  BodyMediumRegular,
} from '../../../components/typography'
import { useAppInfo } from '../../../core/hooks/useAppInfo'
import { capitalizeFirstLetter } from '../../../core/lib/capitalizeFirstLetter'

export const EmployeePrivacyScreen = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const appInfo = useAppInfo()

  // -- Vars --
  const environment = import.meta.env.VITE_ENV

  // -- Render --
  return (
    <ContentContainer>
      <DetailPageHeader
        title={t('employee.profile.privacy.title')}
        topTitle={t('employee.profile.title')}
        onBack={isDesktop ? undefined : () => navigate(EmployeeRoutes.Profile)}
      />

      <StLink href={t('profile.privacy.item1.link')} target="_blank">
        <BodyMediumRegular>
          {t('profile.privacy.item1.title')}
        </BodyMediumRegular>
        <FontAwesomeIcon icon={['fasr', 'arrow-up-right-from-square']} />
      </StLink>

      <StLink href={t('profile.privacy.item2.link')} target="_blank">
        <BodyMediumRegular>
          {t('profile.privacy.item2.title')}
        </BodyMediumRegular>
        <FontAwesomeIcon icon={['fasr', 'arrow-up-right-from-square']} />
      </StLink>

      {appInfo && (
        <StVersion>
          {environment !== 'production' &&
            `${capitalizeFirstLetter(environment)} | build: ${
              appInfo.build
            } | `}
          v{appInfo.version}
        </StVersion>
      )}
    </ContentContainer>
  )
}

const StLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space5} 0;

  text-decoration: none;
  color: ${({ theme }) => theme.theme.text.body.black};

  @media ${breakpoints.desktop} {
    width: 50%;
  }
`

const StVersion = styled(BodyExtraSmallRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space4};
  color: ${({ theme }) => theme.theme.colors['nonary-3']};
`
