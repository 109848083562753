import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Listbox } from '@headlessui/react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css, styled } from 'styled-components'
import { useOnClickOutside } from 'usehooks-ts'

import {
  BodyLargeSemiBoldCss,
  BodyMediumRegular,
  BodyMediumRegularCss,
  BodyMediumSemiBoldCss,
  BodySmallRegularCss,
} from '../typography'

export type MultiSelectOption = {
  key?: string
  label: string
}

type MultiSelectParameters = {
  value?: string[]
  options: MultiSelectOption[]
  onChange: (value?: string[]) => void
  onBlur?: () => void
  label?: string
  placeholder?: string
  disabled?: boolean
  withEmptyValue?: boolean
  size?: 'sm' | 'md' | 'lg'
}

export const MultiSelect = ({
  options,
  value,
  onChange,
  label,
  placeholder,
  disabled,
  withEmptyValue = true,
  size = 'lg',
}: MultiSelectParameters) => {
  // -- State --
  const [open, setOpen] = useState(false)

  const inputRef = useRef<HTMLButtonElement | null>(null)
  const containerRef = useRef(null)
  const { t } = useTranslation()

  const innerOptions = [
    ...(withEmptyValue ? [{ key: 'NULL', label: t('filters.show-all') }] : []),
    ...options,
  ]

  const valueAmount = value?.filter((value) => value !== 'NULL').length ?? 0

  useOnClickOutside(containerRef, () => setOpen(false))
  const { refs, floatingStyles } = useFloating({
    open: open,
    onOpenChange: setOpen,
    placement: 'bottom-start',
    strategy: 'absolute',
    middleware: [
      offset(10),
      flip({
        fallbackPlacements: ['bottom-end'],
      }),
    ],
    whileElementsMounted(referenceElement, floatingElement, update) {
      return autoUpdate(referenceElement, floatingElement, update, {
        animationFrame: true,
      })
    },
  })

  // -- Render --
  return (
    <StContainer ref={containerRef}>
      <Listbox
        value={value ?? (withEmptyValue ? ['NULL'] : [])}
        onChange={(selectedValue) => {
          // If the user selected the `NULL` value, we want to clear the selection and show that value as active
          // If the value only contains the null value, we want to clear the null selection and only keep the real options
          if (
            selectedValue.includes('NULL') &&
            JSON.stringify(value) !== JSON.stringify(['NULL']) &&
            !!value
          ) {
            onChange(undefined)
            return
          }

          onChange(selectedValue.filter((value) => value !== 'NULL'))
        }}
        multiple
        disabled={disabled}
      >
        <div ref={refs.setReference}>
          {label ? <StLabel>{label}</StLabel> : null}
          <StInput
            ref={inputRef}
            $hasValue={valueAmount > 0}
            onClick={() => setOpen((previous) => !previous)}
            $size={size}
          >
            {valueAmount ? <StCount>{valueAmount}</StCount> : null}
            <BodyMediumRegular>{placeholder}</BodyMediumRegular>
            <FontAwesomeIcon icon={['fasr', 'chevron-down']} />
          </StInput>
        </div>

        {open && (
          <div ref={refs.setFloating} style={floatingStyles}>
            <StDropDownContent
              static
              ref={(ref) => {
                if (!ref) return

                // Prevent touch event passing trough the select and selecting items behind it
                ref.addEventListener('touchstart', (event) => {
                  event.preventDefault()
                })
              }}
              $width={inputRef.current?.offsetWidth}
            >
              {innerOptions.map((option, index) => (
                <StSelectItemContainer
                  key={option.key}
                  $standalone={index === 0 && withEmptyValue}
                >
                  <StSelectItem value={option.key}>
                    <StCheckbox>
                      <FontAwesomeIcon icon={['fasr', 'check']} />
                    </StCheckbox>
                    <BodyMediumRegular>{option.label}</BodyMediumRegular>
                  </StSelectItem>
                </StSelectItemContainer>
              ))}
            </StDropDownContent>
          </div>
        )}
      </Listbox>
    </StContainer>
  )
}

const StContainer = styled.div`
  position: relative;
`

const StLabel = styled.label`
  ${BodyLargeSemiBoldCss}
  display: block;

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StInput = styled(Listbox.Button)<{
  $hasValue?: boolean
  $size: 'lg' | 'md' | 'sm'
}>`
  ${({ $size }) =>
    $size === 'lg' ? BodyMediumRegularCss : BodySmallRegularCss}
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  cursor: pointer;

  width: 100%;
  position: relative;

  border: 1px solid
    ${({ $hasValue, theme }) =>
      $hasValue ? 'black' : theme.components.input.border};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};

  padding: ${({ theme, $size }) =>
    $size === 'lg'
      ? `0 ${theme.UI.SpacingPx.Space5}`
      : $size === 'md'
      ? `0 ${theme.UI.SpacingPx.Space4}`
      : `0 14px`};

  height: ${({ theme, $size }) =>
    $size === 'lg'
      ? theme.UI.SpacingPx.Space13
      : $size === 'md'
      ? theme.UI.SpacingPx.Space11
      : theme.UI.SpacingPx.Space10};

  background-color: ${({ theme }) => theme.components.input.bg};
  color: ${({ theme }) => theme.components.input['input-text']};

  ${BodyMediumRegularCss}

  &:focus-visible {
    box-shadow: 0px 0px 0px 2px
      ${({ theme }) => theme.theme.colors['primary-0']};
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }

  &:hover,
  &[data-headlessui-state*='open'] {
    border-color: ${({ theme }) => theme.theme.colors['primary-0']};
  }

  &[data-disabled] {
    background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
    color: ${({ theme }) => theme.theme.text.body['gray-mid']};
    border-color: ${({ theme }) => theme.theme.colors['nonary-9']};
    cursor: not-allowed;

    &:hover {
      border-color: ${({ theme }) => theme.theme.colors['nonary-9']};
    }
  }
`

const StCount = styled.span`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.theme.colors['primary-0']};

  ${BodyMediumSemiBoldCss}

  display: grid;
  place-items: center;
`

const StDropDownContent = styled(Listbox.Options)<{ $width?: number }>`
  background-color: ${({ theme }) => theme.theme.colors.white};
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
  min-width: ${({ $width }) => $width || 200}px;

  box-shadow: 10px 10px 20px 0px rgba(102, 102, 102, 0.14);

  padding: ${({ theme }) => theme.UI.SpacingPx.Space2} 0;

  z-index: 500;
`

const StCheckbox = styled.div`
  display: grid;
  place-items: center;

  border: 1px solid ${({ theme }) => theme.components.checkbox.default};
  border-radius: 6px;

  width: 24px;
  height: 24px;
  aspect-ratio: 1 / 1;

  color: transparent;
`

const StSelectItemContainer = styled.div<{ $standalone?: boolean }>`
  ${({ $standalone }) =>
    $standalone &&
    css`
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid
        ${({ theme }) => theme.theme.colors['nonary-7']};

        &{StSelectItem}{
          margin: 0;
        }
    `}
`

const StSelectItem = styled(Listbox.Option)<{ $standalone?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  cursor: pointer;
  outline-offset: -1px;
  outline-color: ${({ theme }) => theme.theme.colors['primary-0']};
  padding: ${({ theme }) => `${theme.UI.SpacingPx.Space2} 10px`};

  margin: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space3}`};
  border-radius: 2px;

  &:hover,
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  }

  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space1};
  }

  &[data-headlessui-state*='selected'] {
    > p {
      ${BodyMediumSemiBoldCss}
    }

    ${StCheckbox} {
      color: ${({ theme }) => theme.theme.colors.white};
      background-color: ${({ theme }) => theme.theme.colors['primary-0']};
      border-color: ${({ theme }) => theme.theme.colors['primary-0']};
    }
  }
`
