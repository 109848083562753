import {
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useDebounceCallback, useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { useAuthAxios } from '../../../api/hooks/useAuthAxios'
import { Urls } from '../../../api/urls'
import { DateInput } from '../../../components/form/date-input/DateInput'
import { InfoDialog } from '../../../components/general/InfoDialog'
import { StWidthContainer } from '../../../components/page-layout'
import { Tabs } from '../../../components/Tabs/Tabs'
import { BodyMediumRegular, H6 } from '../../../components/typography'
import { useUser } from '../../../user/hooks/useUser'

import { UsageDetailsCharger } from './UsageDetailsCharger'
import { UsageDetailsHome } from './UsageDetailsHome'

import type { DateRange } from '../../../components/form/date-input/DateInput'
import type { UsageDetails as UsageDetailsResponse } from 'types'

export enum DateRangeMode {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export function UsageDetails() {
  const { t } = useTranslation()
  const { user } = useUser()
  const isDesktop = useMediaQuery(breakpoints.desktop)

  // -- State --
  const [innerLoading, setInnerLoading] = useState(false)
  const [dateRangeMode, setDateRangeMode] = useState<DateRangeMode>(
    DateRangeMode.DAY
  )

  const [dateRange, setDateRange] = useState<{ start: Date; end: Date }>({
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  })

  // -- Data --
  const [{ data: usageDetails, loading: isLoadingDetails }, execute] =
    useAuthAxios<UsageDetailsResponse>(
      {
        url: Urls.fetchUsageDetails,
      },
      {
        manual: true,
      }
    )

  // -- Handlers --
  const fetchUsageDetails = (dateRange: { start: Date; end: Date }) => {
    // Remove inner loading state
    setInnerLoading(false)

    execute({
      params: {
        start: dateRange.start,
        end: dateRange.end,
        rangeMode: dateRangeMode,
      },
    })
  }

  const debouncedFetchUsageDetails = useDebounceCallback(fetchUsageDetails, 300)

  // Fetch details on mount
  useEffect(() => {
    fetchUsageDetails(dateRange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDateRangeChange = (range: { start: Date; end: Date }) => {
    setDateRange((previousRange) => {
      if (
        previousRange.start.getTime() === range.start.getTime() &&
        previousRange.end.getTime() === range.end.getTime()
      ) {
        setInnerLoading(false)
        return previousRange
      }

      // Fetch new data
      debouncedFetchUsageDetails(range)

      return range
    })
  }

  const handleDateChange = useCallback(
    (value: Date | DateRange) => {
      const date = value as Date
      setInnerLoading(true)

      switch (dateRangeMode) {
        case DateRangeMode.DAY: {
          handleDateRangeChange({
            start: startOfDay(date),
            end: endOfDay(date),
          })

          break
        }
        case DateRangeMode.MONTH: {
          handleDateRangeChange({
            start: startOfMonth(date),
            end: endOfMonth(date),
          })

          break
        }
        case DateRangeMode.YEAR: {
          handleDateRangeChange({
            start: startOfYear(date),
            end: endOfYear(date),
          })

          break
        }
        // No default
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRangeMode]
  )

  useEffect(() => {
    handleDateChange(new Date())
  }, [dateRangeMode, handleDateChange])

  return (
    <StUsageDetailsContainer>
      <StStickyContainer>
        <StWidthContainer>
          <StUsageHeader>
            <Tabs
              defaultValue="day"
              stretch
              hideContent
              items={[
                {
                  title: t('employee.hems.usage.details.range.day'),
                  value: DateRangeMode.DAY,
                  content: null,
                },
                {
                  title: t('employee.hems.usage.details.range.month'),
                  value: DateRangeMode.MONTH,
                  content: null,
                },
                {
                  title: t('employee.hems.usage.details.range.year'),
                  value: DateRangeMode.YEAR,
                  content: null,
                },
              ]}
              setValue={(value) => {
                setInnerLoading(true)
                setDateRangeMode(value as DateRangeMode)
              }}
            />
            <DateInput
              withNavigation
              onChange={handleDateChange}
              value={dateRange.start}
              maxDate={new Date()}
              minDate={
                user.addresses[0].scoptOnboarded
                  ? new Date(user.addresses[0].scoptOnboarded)
                  : undefined
              }
              mode={
                dateRangeMode === DateRangeMode.YEAR
                  ? 'year'
                  : dateRangeMode === DateRangeMode.MONTH
                  ? 'month'
                  : 'day'
              }
              formatString={
                dateRangeMode === DateRangeMode.YEAR
                  ? 'yyyy'
                  : dateRangeMode === DateRangeMode.MONTH
                  ? 'MMMM yyyy'
                  : 'dd MMMM yyyy'
              }
              placement="bottom"
            />
          </StUsageHeader>
        </StWidthContainer>
      </StStickyContainer>

      {innerLoading || isLoadingDetails ? (
        <StPositionWrapper>
          <StLoaderContainer>
            {isDesktop ? (
              <UsageDetailsLoaderDesktop />
            ) : (
              <UsageDetailsLoaderMobile />
            )}
          </StLoaderContainer>
        </StPositionWrapper>
      ) : (
        <StPositionWrapper>
          <StChartsContainer>
            <StChartWrapper>
              <StChartHeader>
                <H6>{t('employee.hems.usage-details.home-usage')} </H6>
                <InfoDialog
                  title={t('employee.hems.usage-details.home-usage.info.title')}
                >
                  <StInfoContent>
                    <BodyMediumRegular>
                      {t(
                        'employee.hems.usage-details.home-usage.info.description-1'
                      )}
                    </BodyMediumRegular>
                    <BodyMediumRegular>
                      {t(
                        'employee.hems.usage-details.home-usage.info.description-2'
                      )}
                    </BodyMediumRegular>
                    <BodyMediumRegular>
                      {t(
                        'employee.hems.usage-details.home-usage.info.description-3'
                      )}
                    </BodyMediumRegular>
                  </StInfoContent>
                </InfoDialog>
              </StChartHeader>
              <UsageDetailsHome
                dateRange={dateRange}
                dateRangeMode={dateRangeMode}
                usageDetails={usageDetails}
              />
            </StChartWrapper>
            <StChartWrapper>
              <StChartHeader>
                <H6>{t('employee.hems.usage-details.charger-usage')} </H6>
                <InfoDialog
                  title={t(
                    'employee.hems.usage-details.charger-usage.info.title'
                  )}
                >
                  <StInfoContent>
                    <BodyMediumRegular>
                      {t(
                        'employee.hems.usage-details.charger-usage.info.description-1'
                      )}
                    </BodyMediumRegular>
                    <BodyMediumRegular>
                      {t(
                        'employee.hems.usage-details.charger-usage.info.description-2'
                      )}
                    </BodyMediumRegular>
                    <BodyMediumRegular>
                      {t(
                        'employee.hems.usage-details.charger-usage.info.description-3'
                      )}
                    </BodyMediumRegular>
                  </StInfoContent>
                </InfoDialog>
              </StChartHeader>
              <UsageDetailsCharger
                dateRange={dateRange}
                dateRangeMode={dateRangeMode}
                usageDetails={usageDetails}
              />
            </StChartWrapper>
          </StChartsContainer>
        </StPositionWrapper>
      )}
    </StUsageDetailsContainer>
  )
}

const StUsageDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  background-color: ${({ theme }) => theme.theme.colors.white};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};

  @media ${breakpoints.desktop} {
    padding: 0;

    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space7};
    padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};

    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StStickyContainer = styled.div`
  position: fixed;
  top: calc(var(--inset-top, 0px) + var(--mobile-page-header-height, 0px));
  left: 0;
  right: 0;

  z-index: 1;
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};

  background-color: ${({ theme }) => theme.theme.colors.white};
  border-bottom-left-radius: ${({ theme }) => theme.UI.SpacingPx.Space5};
  border-bottom-right-radius: ${({ theme }) => theme.UI.SpacingPx.Space5};

  @media ${breakpoints.desktop} {
    top: ${({ theme }) =>
      `calc(var(--inset-top, 0px) + var(--navigation-height) + var(--page-header-height) + ${theme.UI.SpacingPx.Space10})`};
    padding-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StUsageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  @media ${breakpoints.desktop} {
    flex-direction: row;
    align-items: center;

    & > * {
      flex: 1;
      margin-top: 0;
    }
  }
`

const StPositionWrapper = styled.div`
  margin-top: 204px;

  @media ${breakpoints.desktop} {
    width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
    max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

    margin: 0 auto;

    margin-top: 155px;
  }

  @media ${breakpoints.desktopXl} {
    width: 100%;
  }
`

const StLoaderContainer = styled(StWidthContainer)`
  @media ${breakpoints.desktop} {
    max-width: 100%;
    width: 100%;
  }
`

const StChartsContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

  background-color: ${({ theme }) => theme.theme.colors['nonary-8']};

  @media ${breakpoints.desktop} {
    gap: 0;
    background-color: white;
  }
`

const StChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space5};

  &:first-of-type {
    padding-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  background-color: ${({ theme }) => theme.theme.colors.white};

  @media ${breakpoints.desktop} {
    border-radius: 0;

    padding: 0;
    padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StChartHeader = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  align-items: center;

  & > * {
    margin: 0;
  }
`

export const StChartContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.desktop} {
    flex-direction: row-reverse;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

    min-height: 250px;

    &:last-child {
      flex-grow: 1;
    }
  }
`

export const StChartInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  @media ${breakpoints.desktop} {
    flex-direction: column;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
    min-width: 200px;
  }
`

const StInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const UsageDetailsLoaderDesktop = () => (
  <ContentLoader
    speed={2}
    width="100%"
    viewBox="0 0 1250 638"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0%" y="0" rx="8" ry="8" width="12.4%" height="24" />
    <rect x="0%" y="34" rx="8" ry="8" width="100%" height="265" />
    <rect x="0%" y="339" rx="8" ry="8" width="12.4%" height="24" />
    <rect x="0%" y="373" rx="8" ry="8" width="100%" height="265" />
  </ContentLoader>
)

const UsageDetailsLoaderMobile = () => (
  <ContentLoader
    speed={2}
    width={'100%'}
    viewBox="0 0 343 943"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0%" y="0" rx="8" ry="8" width="45.19%" height="24" />
    <rect x="0%" y="34" rx="8" ry="8" width="99.71%" height="267" />
    <rect x="0%" y="325" rx="8" ry="8" width="45.19%" height="24" />
    <rect x="0%" y="359" rx="8" ry="8" width="99.71%" height="261" />
  </ContentLoader>
)
