import ContentLoader from 'react-content-loader'
import { Document, Thumbnail, pdfjs } from 'react-pdf'
import { styled } from 'styled-components'

import { breakpoints } from '../../../theme/layout/breakpoints'

type PdfPreviewProps = {
  file?: string | Blob
  small?: boolean
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

export const PdfPreview = ({ file, small = false }: PdfPreviewProps) => {
  const loader = (
    <StLoader
      speed={2}
      width={small ? 150 : 187}
      height={small ? 138 : 204}
      viewBox="0 0 150 138"
      backgroundColor="#fff"
      foregroundColor="#f6f6f6"
    >
      <rect x="0" y="0" width={small ? '150' : '187'} height="280" />
    </StLoader>
  )

  return (
    <StContainer $small={small}>
      {file ? (
        <Document className={'document'} file={file} loading={loader}>
          <Thumbnail
            className={'thumbnail'}
            pageIndex={0}
            width={small ? 150 : 187}
          />
        </Document>
      ) : (
        loader
      )}
      {file && <StOverlay />}
    </StContainer>
  )
}

const StLoader = styled(ContentLoader)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StContainer = styled.div<{ $small: boolean }>`
  width: 100%;
  height: 138px;
  overflow: hidden;

  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  background-color: ${({ theme }) => theme.theme.colors['nonary-9']};
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space9};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space5};

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  pointer-events: none;

  @media ${breakpoints.desktop} {
    width: ${({ $small }) => ($small ? '230px' : '300px')};
    height: ${({ $small }) => ($small ? '138px' : '204px')};
  }

  .document {
    box-shadow: 0px 16px 40px 0px rgba(174, 176, 185, 0.15);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: ${({ theme }) => theme.UI.SpacingPx.Space5};

    ${StLoader} {
      top: 0;
    }
  }
`

const StOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;

  width: 150px;
  background: linear-gradient(180deg, #ffffff00 95%, #f9f9f9 100%);

  left: 50%;
  transform: translateX(-50%);
`
