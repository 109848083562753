import {
  EspProvisioning,
  ESPSecurity,
  ESPTransport,
} from '@general-galactic/capacitor-esp-idf-provisioning'

import type {
  ESPDevice,
  ESPNetwork,
} from '@general-galactic/capacitor-esp-idf-provisioning'

// Example return value from a real dongle
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
type EspDongle = {
  name: 'PROV_P1_DONGLE'
  advertisementData: {
    kCBAdvDataRxPrimaryPHY: 1
    kCBAdvDataRxSecondaryPHY: 0
    kCBAdvDataIsConnectable: 1
    kCBAdvDataLocalName: 'PROV_P1_DONGLE'
    // The mac address will be stored here
    kCBAdvDataServiceUUIDs: [string]
  }
}

export const searchDevices = async (
  retries: number = 5
): Promise<ESPDevice[]> => {
  try {
    const devices = await EspProvisioning.searchESPDevices({
      devicePrefix: 'PROV_P1_',
      transport: ESPTransport.ble,
      security: ESPSecurity.secure,
    })

    if (!devices.devices || devices.devices.length === 0) {
      if (retries > 0) {
        console.info('No devices found, retrying...')
        return searchDevices(retries - 1)
      } else {
        return []
      }
    }

    return devices.devices
  } catch (error) {
    console.error('Error searching for devices', error)
    return []
  }
}

export const connectToDevice = async (
  deviceName: string,
  retries: number = 5
): Promise<boolean> => {
  try {
    const { connected } = await EspProvisioning.connect({
      deviceName: deviceName,
      proofOfPossession: 'abcd1234',
    })

    // It looks like the function keeps running until connection is successful, connected will never be false
    if (!connected && retries > 0) {
      console.info('Failed to connect to device, retrying...')
      return connectToDevice(deviceName, retries - 1)
    }

    return connected
  } catch (error) {
    console.error('error connectiong', error)
    return false
  }
}

export const findWifiNetworks = async (
  deviceName: string,
  retries: number = 5
): Promise<ESPNetwork[]> => {
  try {
    const { networks } = await EspProvisioning.scanWifiList({
      deviceName: deviceName,
    })

    if (!networks || networks.length === 0) {
      if (retries > 0) {
        console.info('No networks found, retrying...')
        return findWifiNetworks(deviceName, retries - 1)
      } else {
        return []
      }
    }

    return networks
  } catch (error) {
    if (retries > 0) {
      console.info('No networks found, retrying...')
      return findWifiNetworks(deviceName, retries - 1)
    } else {
      console.error('Error fetching networks', error)
      return []
    }
  }
}

export const connectToNetwork = async (
  deviceName: string,
  ssid: string,
  password?: string
): Promise<boolean> => {
  try {
    const { success } = await EspProvisioning.provision({
      deviceName: deviceName,
      ssid: ssid,
      passPhrase: password,
    })

    return success
  } catch (error) {
    console.error('Error fetching networks', error)
    return false
  }
}
